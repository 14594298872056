import React from 'react';
// import { ErrorApi } from 'webapi'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    // console.log(error)
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // ErrorApi.error(error, errorInfo);
    // console.log(error)
    // console.log(errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>予期せぬエラーが発生しました。ブラウザを閉じて再ログインをお願い致します。</h1>;
    }

    return this.props.children; 
  }
}