import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { PivotAction } from 'ducks/Pivot';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { CHART_TYPE } from 'constants/pivot';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';
import { MigrationApi } from 'webapi'
import { LogoutApi } from 'webapi';
import { Step0 } from './contents/Step0';
import { Step1 } from './contents/Step1';
import { Step2 } from './contents/Step2';
import { StepP } from './contents/StepP';
import { Step3 } from './contents/Step3';
import { Step4 } from './contents/Step4';
import { TITLE } from 'constants/title';
import { LoginAction } from 'ducks/Login';
import { persistStore } from 'redux-persist'
import { configureStore, persistor, store } from 'store'

export const MigrationPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const pivot = useSelector(state => state.Pivot);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);
  const loginAction = bindActionCreators(LoginAction, dispatch);
  let history = useHistory();

  const [step, setStep] = React.useState('S')
  const [errorList, setErrorList] = React.useState([])
  const [isReload, setIsReload] = React.useState(false)
  const [uploadFile, setUploadFile] = React.useState(null)
  const [readCount, setReadCount] = React.useState(0)
  const [resultCount, setResultCount] = React.useState(0)

  React.useEffect(() => {
    if (login.office && login.migration > '0') {
      // console.log(login.migration)
      setStep(login.migration==='1'?'S':login.migration)
      if (login.migration === '4') {
        // 件数取得
        const dto = {
          loginCd: login.loginCd,
          officeId: login.mntOffice?login.mntOffice:login.officeId,
        }
        MigrationApi.getCount(dto)
        .then(res => {
          setErrorList(res.payload.messageList)
          if (res.payload.messageList.length === 0) {
            setReadCount(res.payload.readCount)
            setResultCount(res.payload.resultCount)
          }
        })
        .catch(() =>{
          setErrorList(MESSAGE.migration)
        })
        .finally(() => {
        })
        
      }
    }
  }, [login.office, login.mntOffice, login.migration])

  const initPopup = (form) => {
    props.setPopup(form)
    if (login.office && login.migration > '0') {
      // console.log(login.migration)
      setStep(login.migration==='1'?'S':login.migration)
    }
  }

  const execStep0 = () => {
    // console.log('StepS->1')
    setStep('1')
  }
  const execStep1 = () => {
    // console.log('Step1->2')
    const dto = {
      loginCd: login.loginCd,
      officeId: login.mntOffice?login.mntOffice:login.officeId,
      dataTransferFlagKey: 'data_transfer_flag',
      dataTransferFlagName: 'データ移行フラグ',
      dataTransferFlagVal: '1'
    }
    MigrationApi.stepone(dto)
    .then(res => {
      setErrorList(res.payload.messageList)
      if (res.payload.messageList.length === 0) setStep('2')
    })
    .catch(() =>{
      setErrorList(MESSAGE.migration)
    })
    .finally(() => {
    })
  }
  const execStep2 = () => {
    // console.log('Step2->P')
    const dto = {
      loginCd: login.loginCd,
      officeId: login.mntOffice?login.mntOffice:login.officeId,
      dataTransferFlagKey: 'data_transfer_flag',
      dataTransferFlagName: 'データ移行フラグ',
      dataTransferFlagVal: '1'
    }
    const formData = new FormData();
    formData.append('dto', new Blob(
      [JSON.stringify(dto)], { type: 'application/json' }
    ));
    if (!uploadFile || uploadFile.length===0) {
      return
    }
    formData.append('migrationUploadFile', uploadFile[0]);
    setStep('P')
    setErrorList([])
    MigrationApi.steptwo(formData)
    .then(res => {
      setErrorList(res.payload.messageList)
      if (res.payload.messageList.length === 0) {
        setReadCount(res.payload.readCount)
        setResultCount(res.payload.resultCount)
        setStep('4')
      } else {
        setStep('2')
      }
    })
    .catch((error) =>{
      if (error.message === 'Network Error') {
        setErrorList(MESSAGE.migrationClosed)
        setIsReload(true)
      } else {
        setErrorList(MESSAGE.migration)
        setIsReload(false)
      }
      setStep('2')
    })
    .finally(() => {
      setUploadFile(null)
    })
  }
  // const execStepP = () => {
  //   console.log('StepP->3')
  //   setStep('3')
  // }
  const execStep3 = () => {
    // console.log('Step3->4')
    const dto = {
      loginCd: login.loginCd,
      officeId: login.mntOffice?login.mntOffice:login.officeId,
      dataTransferFlagKey: 'data_transfer_flag',
      dataTransferFlagName: 'データ移行フラグ',
      dataTransferFlagVal: '1'
    }
    MigrationApi.stepthree(dto)
    .then(res => {
      setErrorList(res.payload.messageList)
      if (res.payload.messageList.length === 0) {
        setReadCount(res.payload.readCount)
        setResultCount(res.payload.resultCount)
        setStep('F')
      }
    })
    .catch(() =>{
      setErrorList(MESSAGE.migration)
    })
    .finally(() => {
    })
  }
  const execStep4 = () => {
    // console.log('Step4->finish.')
    setStep(0)
    LoginAction.setInputLogin({migration: '0'})
    props.popup.dialogResult = 'wj-hide-ok'
    props.popup.hide()
    history.replace(TITLE.main.link, {init: true})
  }

  const logout = () => {
    // alert().fire({
    //   icon: MESSAGE.confirmLogout().icon,
    //   title: MESSAGE.confirmLogout().text
    // })
    // .then((result) => {
    //   if (result.isConfirmed) {
        LogoutApi.logout()
        .then(res => {
        }).catch(err => {
        }).finally(() => {
          appAction.clearSession()
          persistor.purge()
          loginAction.setInputLogin({
            loginCd: '',
            office: '',
            authCode: '',
            mbrCd: '',
            showConfirm: false,
            forceLogin: false,
            needReLogin: false,
          })
          appAction.setInputApp({
            isHeaderShow: false,
            isFooterShow: false,
          })
          props.popup.dialogResult = 'wj-hide-ok'
          props.popup.hide()
          history.replace(TITLE.main.link, {init: true})
          window.close()
        })
    //   }
    // })
  }

  const execReload = () => {
    setStep('P')
    setErrorList([])
    setTimeout(() => {
      const dto = {
        loginCd: login.loginCd,
        officeId: login.mntOffice?login.mntOffice:login.officeId,
      }
      MigrationApi.getCount(dto)
      .then(res => {
        setStep(res.payload.dataTransferFlag)
        setErrorList(res.payload.messageList)
        if (res.payload.messageList.length === 0) {
          if (res.payload.dataTransferFlag === '4') {
            setReadCount(res.payload.readCount)
            setResultCount(res.payload.resultCount)
            setIsReload(false)
          } else {
            setErrorList(MESSAGE.migrationClosed)
            setIsReload(true)
          }
        }
      })
      .catch((error) =>{
        if (error.message === 'Network Error') {
          setErrorList(MESSAGE.migrationClosed)
          setIsReload(true)
        } else {
          setErrorList(MESSAGE.migration)
          setIsReload(false)
        }
        setStep('2')
      })
      .finally(() => {
      })
    }, app.reloadTime)
  }

  const genContents = {
    'S': () => {return <Step0 step={step} action={execStep0} />},
    '1': () => {return <Step1 step={step} action={execStep1} errorList={errorList} cancel={logout}/>},
    '2': () => {return <Step2 step={step} action={execStep2} errorList={errorList} files={uploadFile} setFiles={setUploadFile} isReload={isReload} execReload={execReload}/>},
    '3': () => {return <StepP step={step} errorList={errorList} />},
    'P': () => {return <StepP step={step} errorList={errorList} />},
    '4': () => {return <Step3 step={step} action={execStep3} errorList={errorList} readCount={readCount} resultCount={resultCount}/>},
    'F': () => {return <Step4 step={step} action={execStep4} />},
    '0': () => {return <div />},
  }

  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id='migration-popup'
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
        hiding={(p, e) => {
          if (p.dialogResult !== 'wj-hide-ok') e.cancel = true
        }}
      >
        {genContents[step]()}
      </wjInput.Popup>
    </React.Fragment>
  )
})