import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { StatusPopup } from './StatusPopup';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert, alert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE, TKT_STATUS, PAX_TYPE, FARE_TYPE, CRS, TICKET_TYPE } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'

export const TicketEditHeader = () => {

  const app = useSelector(state => state.App);
  const tktEdit = useSelector(state => state.TktEdit);

  const history = useHistory();
  const location = useLocation();
  const { tktno } = useParams();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  const [popup, setPopup] = React.useState(null)

  const tktTktnoSnFromElm = useRef(null);

  const open = (tktno) => {
    if (tktno.length < 13) {
      alert().fire({
        icon: MESSAGE.tktNoFind().icon,
        title: MESSAGE.tktNoFind().text,
        showCancelButton: false,
      })
    }
    const al = tktno.substr(0, 3)
    const sn = tktno.substr(3, 10)
    const mode = '3'
    TicketApi.detailSearch({
      formMode: mode,
      airlineCode: al,
      sn: sn,
    }).then(res => {
      if (res.payload.code === '000') {
        if (!res.payload.ticketDetailSearchResultListDto || res.payload.ticketDetailSearchResultListDto.length < 1) {
          alert().fire({
            icon: MESSAGE.tktNoFind().icon,
            title: MESSAGE.tktNoFind().text,
            showCancelButton: false,
          })
        } else {
          const tktItem = res.payload.ticketDetailSearchResultListDto[0].tktDataListDto
          // console.log(tktItem)
          const alno = tktItem.tktParentTktno?tktItem.tktParentTktno.substr(0, 3):tktItem.tktTktnoAl
          const snno = tktItem.tktParentTktno?tktItem.tktParentTktno.substr(3):tktItem.tktTktnoSn
          let tktNewList = localStorage.getItem('tktNewList')?JSON.parse(localStorage.getItem('tktNewList')):[{name:'', value:'', mode: ''}]
          tktNewList = _.filter(tktNewList, item => item.value !== alno + snno)
          tktNewList.push({
            name: alno + '-' + snno,
            value: alno + snno,
            mode: mode,
          })
          // console.log(tktNewList)
          localStorage.setItem('tktNewList', JSON.stringify(tktNewList))
          window.open(`${TITLE.ticketEdit.mainLink}/${alno + snno}`, '_tktEdit_' + alno + snno)
        }
      } else if (res.payload.code !== '300') {
        alert().fire({
          icon: MESSAGE.tktNoFind().icon,
          title: MESSAGE.tktNoFind().text,
          showCancelButton: false,
        })
      }
    }).catch(err => {
    }).finally(() => {})
  }

  return (
    <React.Fragment>
      <StatusPopup 
        popup={popup}
        setPopup={setPopup}
      />
      <div className='d-flex width1280'>
        <div className='d-flex flex-column'>
          <div className='ml-2'>
            <label className='form-label label-required sm-3'>TKT NO.</label>
          </div>
          <div className='d-flex flex-row'>
            <Common.Text
              attrs={{...tktEdit.tktTktnoAl}}
              onChange={(e) => {
                if (e.tktTktnoAl.text.length == 3) tktTktnoSnFromElm.current.focus()
              }}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-4 mx-2',
              }}
            />
            <Common.Text
              ref={tktTktnoSnFromElm}
              attrs={{...tktEdit.tktTktnoSnFrom}}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-10 mr-0',
              }}
            />
          </div>
        </div>
        <div className='ft-margin'>～</div>
        <Common.Text
          attrs={{...tktEdit.tktTktnoSnTo}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-4 mr-0',
          }}
        />
        <div className='d-flex flex-column'>
          <div
            className="ml-5"
            onClick={() => {open(tktEdit.tktConjData.text)}}
          >
            <label className='tooltip-text label mb-0'>関連航空券番号(ICW)</label>
          </div>
          <Common.Text
            attrs={{...tktEdit.tktConjData}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-16 mx-5',
            }}
          />
        </div>
        <Common.DateTime
          attrs={{...tktEdit.tktIsuDate}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-max-content mr-2',
          }}
        />
        <Common.DateTime
          attrs={{...tktEdit.tktDepDate}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-max-content mr-2',
          }}
        />
        <Common.DateTime
          attrs={{...tktEdit.tktIntDepDate}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-max-content mr-5',
          }}
        />
        <Common.Select
          attrs={{...tktEdit.tktStatus}}
          group={{
            className: 'width-12 mr-0',
          }}
          minLength={1}
          list={TKT_STATUS}
          showDropDownButton={false}
          className='ticket-status'
          onChange={(e) => tktEditAction.changeInputTktEdit(e)}
        />
        <Button
          className='button-gray mt-4 mr-2 width-max-content py-1 px-2 height-max-content'
          disabled={app.isProcessing}
          onClick={() => {
            popup.show(true, (sender) => {
              // if (sender.dialogResult && _.isObject(sender.dialogResult)) {
              //   console.log(sender.dialogResult)
              // } else {
              //   console.log('cancel')
              // }
            })
          }}
        >
          詳細
        </Button>
          <Common.DateTime
            attrs={{...tktEdit.tktRefundVoidDate}}
            onBlur={(e) => null}
            group={{
              className: 'width-max-content mr-0',
            }}
          />
        {/* {!tktEdit.tktVoidDate.text && tktEdit.tktRefDate.text &&
          <Common.DateTime
            attrs={{...tktEdit.tktRefundDate}}
            onBlur={(e) => null}
            group={{
              className: 'width-max-content mr-0',
            }}
          />
        }
        {!(!tktEdit.tktVoidDate.text && tktEdit.tktRefDate.text) &&
          <Common.DateTime
            attrs={{...tktEdit.tktVoidDate}}
            onBlur={(e) => null}
            group={{
              className: 'width-max-content mr-0',
            }}
          />
        } */}
      </div>
      <div className='d-flex align-items-start width1280'>
        <Common.Text
          attrs={{...tktEdit.tktPaxName}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'pax-width ml-2 mr-5',
          }}
        />
        <Common.Select
          attrs={{...tktEdit.tktPaxType}}
          group={{
            className: 'width-10 mr-1',
          }}
          minLength={1}
          list={PAX_TYPE}
          onChange={(e) => tktEditAction.changeInputTktEdit(e)}
        />
        <Common.Text
          attrs={{...tktEdit.tktPtc}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-5-5 mr-5',
          }}
        />
        <Common.Select
          attrs={{...tktEdit.tktFareType}}
          group={{
            className: 'width-5-8 mr-1',
          }}
          minLength={1}
          list={FARE_TYPE}
          onChange={(e) => tktEditAction.changeInputTktEdit(e)}
        />
        <Common.Select
          attrs={{...tktEdit.tktCrs}}
          group={{
            className: 'width-14 mr-1',
          }}
          minLength={1}
          list={CRS}
          onChange={(e) => tktEditAction.changeInputTktEdit(e)}
        />
        <Common.Text
          attrs={{...tktEdit.tktPnr}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'pnr-width mr-1',
          }}
        />
        <Common.Text
          attrs={{...tktEdit.tktIata}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-8 mr-5',
          }}
        />
        <Common.Select
          attrs={{...tktEdit.tktTktType}}
          group={{
            className: 'width-5-10 mr-2',
          }}
          minLength={1}
          list={TICKET_TYPE}
          onChange={(e) => tktEditAction.changeInputTktEdit(e)}
        />
        <div className='d-flex flex-column align-self-start mt-1 ml-2 mr-4'>
          <Form.Label sm="3" className=''>
            {tktEdit.tktNdcFlg.label}
          </Form.Label>
          <Common.Check
            attrs={{...tktEdit.tktNdcFlg, label:''}}
            onChange={(e) => tktEditAction.changeInputTktEdit(e)}
          />
        </div>
        <Common.Text
          attrs={{...tktEdit.tktOrderId}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'min-width-5-17 width-5-17 ml-1 mr-0',
          }}
        />
      </div>
    </React.Fragment>
  )
}