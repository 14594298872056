import { handleActions, createAction } from 'redux-actions'
import { onChangeInput, initState } from 'lib'
import _ from 'lodash';

// const
export const TIMER = {
  CHANGE_INPUT_TIMER : 'CHANGE_INPUT_TIMER',
  SET_INPUT_TIMER : 'SET_INPUT_TIMER',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
};

// action
export const TimerAction = {
  changeInputTimer: createAction(TIMER.CHANGE_INPUT_TIMER, (args) => (args)),
  setInputTimer: createAction(TIMER.SET_INPUT_TIMER, (args) => (args)),
  initSession: createAction(TIMER.INIT_SESSION),
  clearSession: createAction(TIMER.CLEAR_SESSION),
}

// initialState
const initialState = {
  // --
  label: [
    'CUSTOMER(アカウントコード:受注コード(R))',
    '処理日',
    'ピリオド',
  ],
  timerReponseDto: [],
  tmrValidFromText:{
    id: 'tmrValidFromText',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date1-lg',
    format: 'yyyy/MM/dd',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tmrValidToText:{
    id: 'tmrValidToText',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date1-lg',
    format: 'yyyy/MM/dd',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tmrInterval:{
    id: 'tmrInterval',
    label: '実行間隔',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: true,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    original: 'S',
    value: 'S',
    text: 'S',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tmrIntervalOnce:{
    id: 'tmrIntervalOnce',
    label: '',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'datetime1',
    format: 'yyyy/MM/dd HH:mm',
    timeStep: '5',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tmrIntervalDaily:{
    id: 'tmrIntervalDaily',
    label: '',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: true,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'time',
    format: 'HH:mm',
    timeStep: '5',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tmrIntervalWeekly:{
    id: 'tmrIntervalWeekly',
    label: '',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: true,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tmrIntervalWeeklyTime:{
    id: 'tmrIntervalWeeklyTime',
    label: '',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: true,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'time',
    format: 'HH:mm',
    timeStep: '5',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tmrIntervalMonthly:{
    id: 'tmrIntervalMonthly',
    label: '',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: true,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tmrIntervalMonthlyTime:{
    id: 'tmrIntervalMonthlyTime',
    label: '',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: true,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'time',
    format: 'HH:mm',
    timeStep: '5',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tmrIntervalTimeHour:{
    id: 'tmrIntervalTimeHour',
    label: '',
    suffix: '時間',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: true,
    minLength: 0,
    maxLength: 2,
    min: 0,
    max: 23,
    width: '100%',
    margin: '10px',
    enter: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tmrIntervalTimeMin:{
    id: 'tmrIntervalTimeMin',
    label: '',
    suffix: '分',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: true,
    minLength: 0,
    maxLength: 2,
    min: 0,
    max: 59,
    width: '100%',
    margin: '10px',
    enter: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tmrOutTo:{
    id: 'tmrOutTo',
    label: '抽出開始日時',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'datetime1',
    format: 'yyyy/MM/dd HH:mm',
    timeStep: '5',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
// 
  release: {
    id: 'release',
    label: <span><span className='material-icons-outlined md'>auto_delete</span>解除</span>,
    disabled: false,
  },
  edit: {
    id: 'edit',
    label: <span><span className='material-icons-outlined md'>edit</span>編集</span>,
    disabled: false,
  },
  pause: {
    id: 'pause',
    label: <span><span className='material-icons-outlined md'>pause</span>一時停止</span>,
    disabled: false,
  },
  resume: {
    id: 'resume',
    label: <span><span className='material-icons-outlined md'>play_circle_outline</span>再開</span>,
    disabled: false,
  },

  cancel: {
    id: 'cancel',
    label: <span><span className='material-icons-outlined md'>highlight_off</span>キャンセル</span>,
    disabled: false,
  },
  setting: {
    id: 'setting',
    label: <span><span className='material-icons-outlined md'>file_upload</span>設定</span>,
    disabled: false,
  },
  before: {
    id: 'before',
    label: <span><span className='material-icons-outlined md'>history</span>前回エラーデータ</span>,
    disabled: false,
  },
  csv: {
    id: 'csv',
    label: <span><span className='material-icons-outlined md'>file_download</span>CSV出力</span>,
    disabled: false,
  },
}
// reducer
export const TimerReducer = handleActions({
  [TimerAction.changeInputTimer]: (state, action) => (onChangeInput(state, action.payload)),
  [TimerAction.setInputTimer]: (state, action) => ({...state, ...action.payload,}),
  [TimerAction.initSession]: (state, action) => (initState(initialState)),
  [TimerAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)
