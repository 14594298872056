import { axiosInstanceForLambda } from 'http/index'

export const LambdaApi = {
  get : (uri, login) => {
    const instance = axiosInstanceForLambda;
    return instance.get(uri, {
      headers: {
        'authorization': login.authorization,
        'x-uripro-oid': login.mntOffice?login.mntOffice:login.office,
      }
    }).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  post : (uri, data, login) => {
    const instance = axiosInstanceForLambda;
    return instance.post(uri, data, {
      headers: {
        'authorization': login.authorization,
        'x-uripro-oid': login.mntOffice?login.mntOffice:login.office,
      }
    }).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
}
