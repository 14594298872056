import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';

export const AccPane = (props) => {

  const app = useSelector(state => state.App);
  const tktEdit = useSelector(state => state.TktEdit);
  
  const dispatch = useDispatch();
  const masterAction = bindActionCreators(MasterAction, dispatch);
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  return (
    <React.Fragment>
      <div className='d-flex' id='edit-account1-pane'>
        <Common.SingleSelect
          attrs={{...tktEdit.tkt7lOdrCd, delay: 10}}
          group={{
            className: 'width-20 mr-2',
            // textwidth: 'min-width-18',
            // textwidth: 'min-width-25',
            textvisible: true,
            textsuffixwidth: 'max-width-16',
          }}
          maxSelectedItems={1}
          minLength={1}
          selectedValuePath='orderCd'
          displayName="namej"
          displayMemberPath={'listName'}
          list={app.orderList}
          searchMemberPath={'orderCd'}
          // selectedItems={selectedOrders}
          // setSelectedItems={setSelectedOrders}
          template={'<table><tr>' +
                    '<td class="width-13" title="コード">{orderCd}</td>' +
                    '<td class="" title="名称">{namej}</td>' +
                    '</tr></table>'}
          // initData={initDataOrder}
          // setComponent={setComponent}
          addingData={{cd:'マスタ編集', orderCd: 'マスタ編集', namej: ''}}
          onBlur={(e) => {
            tktEditAction.changeInputTktEdit(e)
          }}
          mPopup={{
            isCode: true,
            type: 'order',
          }}
          isEditable={true}
        />
        <Common.SingleSelect
          attrs={{...tktEdit.tkt7lSecCd, delay: 10}}
          group={{
            className: 'width-13 ml-1 mr-2',
            // textwidth: 'min-width-13',
            // textwidth: 'min-width-20',
            textvisible: true,
            textsuffixwidth: 'max-width-13',
          }}
          maxSelectedItems={1}
          minLength={1}
          selectedValuePath='sectionCd'
          displayName="namej"
          displayMemberPath={'listName'}
          list={app.sectionList}
          searchMemberPath={'sectionCd'}
          // selectedItems={selectedSections}
          // setSelectedItems={setSelectedSections}
          template={'<table><tr>' +
                    '<td class="width-13" title="コード">{sectionCd}</td>' +
                    '<td class="" title="名称">{namej}</td>' +
                    '</tr></table>'}
          addingData={{cd:'マスタ編集', sectionCd: 'マスタ編集', namej: ''}}
          onBlur={(e) => {
            tktEditAction.changeInputTktEdit(e)
          }}
          mPopup={{
            isCode: true,
            type: 'section',
          }}
          isEditable={true}
        />
        <Common.SingleSelect
          attrs={{...tktEdit.tkt7lMbrCd, delay: 10}}
          group={{
            className: 'width-13 ml-1 mr-4',
            // textwidth: 'min-width-13',
            // textwidth: 'min-width-20',
            textvisible: true,
            textsuffixwidth: 'max-width-13',
          }}
          maxSelectedItems={1}
          minLength={1}
          selectedValuePath='memberCd'
          displayName="mbrNamej"
          displayMemberPath={'listName'}
          list={app.memberList}
          searchMemberPath={'memberCd'}
          // selectedItems={selectedMembers}
          // setSelectedItems={setSelectedMembers}
          template={'<table><tr>' +
                    '<td class="width-13" title="コード">{memberCd}</td>' +
                    '<td class="" title="名称">{mbrNamej}</td>' +
                    '</tr></table>'}
          addingData={{mbrCd:'マスタ編集', memberCd: 'マスタ編集', mbrNamej: ''}}
          onBlur={(e) => {
            tktEditAction.changeInputTktEdit(e)
          }}
          mPopup={{
            isCode: false,
            type: 'member',
          }}
          isEditable={true}
        />
        <Common.SingleSelect
          attrs={{...tktEdit.tkt7lComCd, delay: 10}}
          group={{
            className: 'width-5-17 mr-1',
            // textwidth: 'min-width-16',
            // textwidth: 'min-width-25',
            textvisible: true,
            textsuffixwidth: 'max-width-16',
          }}
          maxSelectedItems={1}
          minLength={1}
          selectedValuePath='companyCd'
          displayName="comNamej"
          displayMemberPath={'listName'}
          list={app.companyList}
          searchMemberPath={'companyCd'}
          // selectedItems={selectedCompanys}
          // setSelectedItems={setSelectedCompanys}
          template={'<table><tr>' +
                    '<td class="width-13" title="コード">{companyCd}</td>' +
                    '<td class="" title="名称">{comNamej}</td>' +
                    '</tr></table>'}
          addingData={{comCd:'マスタ編集', companyCd: 'マスタ編集', comNamej: ''}}
          onBlur={(e) => {
            tktEditAction.changeInputTktEdit(e)
          }}
          mPopup={{
            isCode: false,
            type: 'company',
          }}
          isEditable={true}
        />
        <Common.SingleSelect
          attrs={{...tktEdit.tkt7lAreaCd, delay: 10}}
          group={{
            className: 'width-5-10 mr-2 ml-1',
            // textwidth: 'min-width-5-10',
            // textwidth: 'min-width-25',
            textvisible: true,
            textsuffixwidth: 'max-width-5-10',
          }}
          maxSelectedItems={1}
          minLength={1}
          selectedValuePath='areaCd'
          displayName="namej"
          displayMemberPath={'listName'}
          list={app.areaList}
          searchMemberPath={'areaCd'}
          // selectedItems={selectedAreas}
          // setSelectedItems={setSelectedAreas}
          template={'<table><tr>' +
                    '<td class="width-13" title="コード">{areaCd}</td>' +
                    '<td class="" title="名称">{namej}</td>' +
                    '</tr></table>'}
          addingData={{cd:'マスタ編集', areaCd: 'マスタ編集', namej: ''}}
          onBlur={(e) => {
            tktEditAction.changeInputTktEdit(e)
          }}
          mPopup={{
            isCode: true,
            type: 'area',
          }}
          isEditable={true}
        />
        <Common.SingleSelect
          attrs={{...tktEdit.tkt7lFreeCd, delay: 10}}
          group={{
            className: 'width-17 mr-0',
            // textwidth: 'min-width-13',
            // textwidth: 'min-width-25',
            textvisible: true,
            textsuffixwidth: 'max-width-13',
          }}
          maxSelectedItems={1}
          minLength={1}
          selectedValuePath='freeCd'
          displayName="namej"
          displayMemberPath={'listName'}
          list={app.freeList}
          searchMemberPath={'freeCd'}
          // selectedItems={selectedFrees}
          // setSelectedItems={setSelectedFrees}
          onBlur={(e) => {
            tktEditAction.changeInputTktEdit(e)
          }}
          template={'<table><tr>' +
                    '<td class="width-13" title="コード">{freeCd}</td>' +
                    '<td class="" title="名称">{namej}</td>' +
                    '</tr></table>'}
          addingData={{cd:'マスタ編集', freeCd: 'マスタ編集', namej: ''}}
          mPopup={{
            isCode: true,
            type: 'free',
          }}
          isEditable={true}
        />
      </div>
      <div className='d-flex' id='edit-account2-pane'>
        <Common.SingleSelect
          attrs={{...tktEdit.tkt7lPayCd, delay: 10}}
          group={{
            className: 'width-9 mr-2',
            // textwidth: 'min-width-9',
            textvisible: true,
            textsuffixwidth: 'max-width-9',
          }}
          maxSelectedItems={1}
          minLength={1}
          selectedValuePath='payCd'
          displayName="namej"
          displayMemberPath={'listName'}
          list={app.payList}
          searchMemberPath={'payCd'}
          // selectedItems={selectedPays}
          // setSelectedItems={setSelectedPays}
          onBlur={(e) => {
            tktEditAction.changeInputTktEdit(e)
          }}
          template={'<table><tr>' +
                    '<td class="width-13" title="コード">{payCd}</td>' +
                    '<td class="" title="名称">{namej}</td>' +
                    '</tr></table>'}
          addingData={{cd:'マスタ編集', payCd: 'マスタ編集', namej: ''}}
          mPopup={{
            isCode: true,
            type: 'pay',
          }}
          isEditable={true}
        />
        <Common.SingleSelect
          attrs={{...tktEdit.tkt7lTourCd, delay: 10}}
          group={{
            className: 'width-24 ml-1 mr-2',
            // textwidth: 'min-width-20',
            textvisible: true,
            textsuffixwidth: 'max-width-20',
          }}
          maxSelectedItems={1}
          minLength={1}
          selectedValuePath='tourCd'
          displayName="namej"
          displayMemberPath={'listName'}
          list={app.tourList}
          searchMemberPath={'tourCd'}
          // selectedItems={selectedTours}
          // setSelectedItems={setSelectedTours}
          onBlur={(e) => {
            tktEditAction.changeInputTktEdit(e)
          }}
          template={'<table><tr>' +
                    '<td class="width-13" title="コード">{tourCd}</td>' +
                    '<td class="" title="名称">{namej}</td>' +
                    '</tr></table>'}
          addingData={{cd:'マスタ編集', tourCd: 'マスタ編集', namej: ''}}
          mPopup={{
            isCode: true,
            type: 'tour',
          }}
          isEditable={true}
        />
        <Common.SingleSelect
          attrs={{...tktEdit.tkt7lKb1Cd, delay: 10}}
          group={{
            className: 'width-5-14 ml-1 mr-2',
            // textwidth: 'min-width-13',
            textvisible: true,
            textsuffixwidth: 'max-width-13',
          }}
          maxSelectedItems={1}
          minLength={1}
          selectedValuePath='kb1Cd'
          displayName="namej"
          displayMemberPath={'listName'}
          list={app.kb1List}
          searchMemberPath={'kb1Cd'}
          // selectedItems={selectedKb1s}
          // setSelectedItems={setSelectedKb1s}
          template={'<table><tr>' +
                    '<td class="width-13" title="コード">{kb1Cd}</td>' +
                    '<td class="" title="名称">{namej}</td>' +
                    '</tr></table>'}
          addingData={{cd:'マスタ編集', kb1Cd: 'マスタ編集', namej: ''}}
          onBlur={(e) => {
            tktEditAction.changeInputTktEdit(e)
          }}
          mPopup={{
            isCode: true,
            type: 'kb1',
          }}
          isEditable={true}
        />
        <Common.SingleSelect
          attrs={{...tktEdit.tkt7lKb2Cd, delay: 10}}
          group={{
            className: 'width-5-14 mr-4',
            textwidth: 'min-width-13',
            textvisible: true,
            textsuffixwidth: 'max-width-13',
          }}
          maxSelectedItems={1}
          minLength={1}
          selectedValuePath='kb2Cd'
          displayName="namej"
          displayMemberPath={'listName'}
          list={app.kb2List}
          searchMemberPath={'kb2Cd'}
          // selectedItems={selectedKb2s}
          // setSelectedItems={setSelectedKb2s}
          template={'<table><tr>' +
                    '<td class="width-13" title="コード">{kb2Cd}</td>' +
                    '<td class="" title="名称">{namej}</td>' +
                    '</tr></table>'}
          addingData={{cd:'マスタ編集', kb2Cd: 'マスタ編集', namej: ''}}
          onBlur={(e) => {
            tktEditAction.changeInputTktEdit(e)
          }}
          mPopup={{
            isCode: true,
            type: 'kb2',
          }}
          isEditable={true}
        />
        <Common.SingleSelect
          attrs={{...tktEdit.tkt7lEoCd, delay: 10}}
          group={{
            className: 'width-14 ml-3 mr-0',
            textwidth: 'min-width-13',
            textvisible: true,
            textsuffixwidth: 'max-width-13',
          }}
          maxSelectedItems={1}
          minLength={1}
          selectedValuePath='eoCd'
          displayName="namej"
          displayMemberPath={'listName'}
          list={app.eoList}
          searchMemberPath={'eoCd'}
          // selectedItems={selectedEos}
          // setSelectedItems={setSelectedEos}
          template={'<table><tr>' +
                    '<td class="width-13" title="コード">{eoCd}</td>' +
                    '<td class="" title="名称">{namej}</td>' +
                    '</tr></table>'}
          addingData={{cd:'マスタ編集', eoCd: 'マスタ編集', namej: ''}}
          onBlur={(e) => {
            tktEditAction.changeInputTktEdit(e)
          }}
          mPopup={{
            isCode: true,
            type: 'eo',
          }}
          isEditable={true}
        />
      </div>
    </React.Fragment>
  )
}