import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, Prompt } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import { PrismAction } from 'ducks/Prism';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { TITLE } from 'constants/title';
import _ from 'lodash';
const ReactSwal = withReactContent(Swal)

export const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  uiState,
  subState,
}) => {
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const target = useSelector(state => state[uiState]);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);
  const prismAction = bindActionCreators(PrismAction, dispatch);
  const location = useLocation();

  const handleBlockedNavigation = (nextLocation, action) => {
    const current = document.activeElement
    let ui = subState?{...target[subState]}:{...target}

    // 特殊
    if (subState === 'travel') {
      ui = {
        ...target,
        ...target.biz,
        ...target.sys,
      }
    } else if (subState === 'maint') {
      ui = {...target.data}
    }
    
    if (ui[current.id] && !_.isNil(ui[current.id].original)) {
      ui[current.id].value = current.value
      ui[current.id].dirty = (ui[current.id].original !== current.value)?'dirty':'';
    }
    let dirtyElement = _.filter(ui, (v,k)=>{
      return v && v.dirty
    })

    _.forEach(ui, (v, k) => {
      if (k.endsWith('List')) {
        dirtyElement = dirtyElement.concat(
          _.filter(v, (r, i) => r.isEdit || r.isAddingNew)
        )
      }
    })

    if (!confirmedNavigation && shouldBlockNavigation(nextLocation) && dirtyElement.length>0) {// && 
        // !(location.pathname === nextLocation.pathname && _.isEqual(location.state, nextLocation.state))) {
      // console.log(dirtyElement)
      setLastLocation(nextLocation);
      ReactSwal.fire({
        title: '',
        text: '編集中の内容はクリアされます。よろしいですか？',
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false,
        focusCancel: true,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'OK'
      })
      .then((result) => {
        if (result.isConfirmed) {
          setConfirmedNavigation(true);
        }
      });
      return false;
    } else {
      if (!lastLocation) {
        if (nextLocation.state && nextLocation.state.init) {
          appAction.setMasterData({isInitData: false})
          appAction.setMessage('')
          if (nextLocation.pathname.startsWith(TITLE.ticketEdit.mainLink)) tktEditAction.clearTktEdit()
          if (nextLocation.pathname.startsWith(TITLE.prism.link)) prismAction.clearPrism()
          appAction.initSession()
          // console.log('-----------------------------------')
          // console.log(nextLocation.pathname)
          // console.log('-----------------------------------')
        }
      }
      setLastLocation(null)
      setConfirmedNavigation(false)
      return true;
    }
  };
  
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      if (lastLocation.state && lastLocation.state.init) {
        appAction.setMasterData({isInitData: false})
        appAction.setMessage('')
        if (lastLocation.pathname.startsWith(TITLE.ticketEdit.mainLink)) tktEditAction.clearTktEdit()
        if (lastLocation.pathname.startsWith(TITLE.prism.link)) prismAction.clearPrism()
        // console.log('-----------------------------------')
        // console.log(lastLocation.pathname)
        // console.log('-----------------------------------')
      }
      // console.log('navi')
      navigate(lastLocation.pathname, lastLocation.state);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <React.Fragment>
      <Prompt when={when} message={handleBlockedNavigation} />
    </React.Fragment>
  );
};