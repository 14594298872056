import _ from 'lodash';

export const clear = (init, state) => {
  const obj = {}
  _.forEach(state, (v,k) => {
    if (v && v.value !== undefined) obj[k] = {
      ...init[k],
      original: v.original,
      value: v.original,
      text: v.original,
    }
  })
  return {...state, ...obj}
}
