import { handleActions, createAction } from 'redux-actions'
import { onChangeInput, initState, clear } from 'lib'
import _ from 'lodash';

// const
export const MASTER = {
  CHANGE_INPUT_MASTER : 'CHANGE_INPUT_MASTER',
  CHANGE_INPUT_COM_DETAIL : 'CHANGE_INPUT_COM_DETAIL',
  SET_INPUT_MASTER : 'SET_INPUT_MASTER',
  CLEAR_INPUT_MASTER : 'CLEAR_INPUT_MASTER',
  CLEAR_INPUT_COM_DETAIL : 'CLEAR_INPUT_COM_DETAIL',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
};

// action
export const MasterAction = {
  changeInputMaster: createAction(MASTER.CHANGE_INPUT_MASTER, (args) => (args)),
  changeInputComDetail: createAction(MASTER.CHANGE_INPUT_COM_DETAIL, (args) => (args)),
  setInputMaster: createAction(MASTER.SET_INPUT_MASTER, (args) => (args)),
  clearInputMaster: createAction(MASTER.CLEAR_INPUT_MASTER),
  clearInputComDetail: createAction(MASTER.CLEAR_INPUT_COM_DETAIL),
  initSession: createAction(MASTER.INIT_SESSION),
  clearSession: createAction(MASTER.CLEAR_SESSION),
}

// initialState
const initialState = {
  
  delete: {
    id: 'delete',
    label: <span><span className='material-icons-outlined md'>delete_forever</span>削除</span>,
    disabled: false,
  },
  ins: {
    id: 'ins',
    label: <span><span className='material-icons-outlined md'>add_circle_outline</span>挿入</span>,
    disabled: false,
  },
  copy: {
    id: 'copy',
    label: <span><span className='material-icons-outlined md'>copy</span>複写</span>,
    disabled: false,
  },
  csv: {
    id: 'csv',
    label: <span><span className='material-icons-outlined md'>file_download</span>CSV出力</span>,
    disabled: false,
  },
  upload: {
    id: 'upload',
    label: <span><span className='material-icons-outlined md'>file_upload</span>CSV入力</span>,
    disabled: false,
  },
  save: {
    id: 'save',
    label: <span><span className='material-icons-outlined md'>file_upload</span>登録</span>,
    disabled: false,
  },
  copyToOther: {
    id: 'copyToOther',
    label: <span><span className='material-icons-outlined md'>copy</span>他AIRLINEへ複写</span>,
    disabled: false,
  },
  companyDetail: {
    comCd:{
      id: 'comCd',
      label: '法人コード',
      placeholder: '',
      required: true,
      disabled: false,
      minLength: 0,
      maxLength: 10,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['halfWidth'],
      stringcase: 'upper',
    },
    comKbn:{
      id: 'comKbn',
      label: '法人区分',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 0,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    comNamej:{
      id: 'comNamej',
      label: '法人名',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 40,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    comNamee:{
      id: 'comNamee',
      label: '法人名(英字)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 40,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['halfWidth'],
      stringcase: '',
    },
    comSecNamej:{
      id: 'comSecNamej',
      label: '部署名',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 30,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    comSecNamee:{
      id: 'comSecNamee',
      label: '部署名(英字)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 30,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['halfWidth'],
      stringcase: '',
    },
    comTel:{
      id: 'comTel',
      label: '電話番号',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 14,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['halfWidth'],
      stringcase: '',
    },
    comFax:{
      id: 'comFax',
      label: 'FAX番号',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 14,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['halfWidth'],
      stringcase: '',
    },
    comPostno:{
      id: 'comPostno',
      label: '郵便番号',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 10,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['halfWidth'],
      stringcase: '',
    },
    comAdddivj:{
      id: 'comAdddivj',
      label: '住所(都道府県)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 8,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    comAdd1j:{
      id: 'comAdd1j',
      label: '住所1',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 46,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    comAdd2j:{
      id: 'comAdd2j',
      label: '住所2',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 46,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    comAdd1e:{
      id: 'comAdd1e',
      label: '住所1(英字)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 50,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['halfWidth'],
      stringcase: '',
    },
    comAdd2e:{
      id: 'comAdd2e',
      label: '住所2(英字)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 50,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['halfWidth'],
      stringcase: '',
    },
    comCommtax:{
      id: 'comCommtax',
      label: '消費税対応',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 0,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    commhKbn:{
      id: 'commhKbn',
      label: '',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 0,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    commhRate:{
      id: 'commhRate',
      label: '_none',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 10,
      min: 0.00,
      max: 999.99,
      format: 'n2',
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    commhSum:{
      id: 'commhSum',
      label: '_none',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 10,
      min: 0,
      max: 99999999,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
  }

//   sizeHorizontal: ['100%', '1'],
//   sizeVertical: ['20%', '1'],
//   condition: {
//     id: 'condition',
//     label: '',
//     label: '',
//     required: true,
//     disabled: false,
//     original: '',
//     value: '',
//     text: '',
//     dirty: '',
//     error: false,
//     helpertext: '',
//   },
//   checkedItems: [],
//   searchUnit: {
//     id: 'searchUnit',
//     label: '',
//     prefix: '表示単位',
//     required: false,
//     disabled: false,
//     original: '',
//     value: '',
//     text: '',
//     dirty: '',
//     error: false,
//     helpertext: '',
//   },
//   clear: {
//     id: 'clear',
//     label: <span><span className='material-icons-outlined sm'>delete_forever</span>検索条件全クリア</span>,
//     disabled: false,
//   },
//   edit: {
//     id: 'edit',
//     label: <span><span className='material-icons-outlined sm'>cloud_upload</span>検索条件パターン登録</span>,
//     disabled: false,
//   },
//   search: {
//     id: 'search',
//     label: <span><span className='material-icons-outlined sm'>search</span>発券データ検索</span>,
//     disabled: false,
//   },

// // 
//   dispCheckedItems: [],
//   tktTktnoSn:{
//     id: 'tktTktnoSn',
//     label: 'tktTktnoSn',
//     placeholder: '',
//     required: false,
//     disabled: false,
//     minLength: 0,
//     maxLength: -1,
//     width: '100%',
//     margin: '10px',
//     enter: '',
//     type: '',
//     format: '',
//     original: '',
//     value: '',
//     text: '',
//     dirty: '',
//     isInvalid: false,
//     helpertext: '',
//     validtype: [],
//     stringcase: '',
//   },

}
// reducer
export const MasterReducer = handleActions({
  [MasterAction.changeInputMaster]: (state, action) => (onChangeInput(state, action.payload)),
  [MasterAction.changeInputComDetail]: (state, action) => ({...state, companyDetail: onChangeInput(state.companyDetail, action.payload)}),
  [MasterAction.setInputMaster]: (state, action) => ({...state, ...action.payload,}),
  [MasterAction.clearInputMaster]: (state, action) => (clear(initialState, state)),
  [MasterAction.clearInputComDetail]: (state, action) => ({...state, companyDetail: clear(initialState.companyDetail, state.companyDetail)}),
  [MasterAction.initSession]: (state, action) => (initState(initialState)),
  [MasterAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)
