import { handleActions, createAction } from 'redux-actions'
import { onChangeInput, initState } from 'lib'
import _ from 'lodash';

// const
export const PROCESS = {
  CHANGE_INPUT_PROCESS : 'CHANGE_INPUT_PROCESS',
  SET_INPUT_PROCESS : 'SET_INPUT_PROCESS',
  ADD_PROCESS_ITEM : 'ADD_PROCESS_ITEM',
  REMOVE_PROCESS_ITEM : 'REMOVE_PROCESS_ITEM',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
};

// action
export const ProcessAction = {
  changeInputProcess: createAction(PROCESS.CHANGE_INPUT_PROCESS, (args) => (args)),
  setInputProcess: createAction(PROCESS.SET_INPUT_PROCESS, (args) => (args)),
  addProcessItem: createAction(PROCESS.ADD_PROCESS_ITEM, (args) => (args)),
  removeProcessItem: createAction(PROCESS.REMOVE_PROCESS_ITEM, (args) => (args)),
  initSession: createAction(PROCESS.INIT_SESSION),
  clearSession: createAction(PROCESS.CLEAR_SESSION),
}

// initialState
const initialState = {
  // --
  processingDateTimeFrom:{
    id: 'processingDateTimeFrom',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date1',
    format: 'yyyy/MM/dd',
    mask: '9999/99/99',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  processingDateTimeTo:{
    id: 'processingDateTimeTo',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date1',
    format: 'yyyy/MM/dd',
    mask: '9999/99/99',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  processType1:{
    id: 'processType1',
    label: '処理区分',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  processKind:{
    id: 'processKind',
    label: 'オンライン/タイマー',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  processingResult:{
    id: 'processingResult',
    label: '処理結果',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },

  clear: {
    id: 'clear',
    label: <span>クリア</span>,
    disabled: false,
  },
  csv: {
    id: 'csv',
    label: <span><span className='material-icons-outlined md'>file_download</span>CSV出力</span>,
    disabled: false,
  },
}
// reducer
export const ProcessReducer = handleActions({
  [ProcessAction.changeInputProcess]: (state, action) => (onChangeInput(state, action.payload)),
  [ProcessAction.setInputProcess]: (state, action) => ({...state, ...action.payload,}),
  [ProcessAction.addProcessItem]: (state, action) => ({
    ...state,
    checkedItems: _.concat(state.checkedItems, action.payload)
  }),
  [ProcessAction.removeProcessItem]: (state, action) => {
    const obj = Object.assign({}, state)
    _.map(state, (v,k) => {
      if (k.startsWith(action.payload[0].key + '_')) delete obj[k]
    })
    return ({
    ...obj,
    checkedItems: _.reject(state.checkedItems, {header: action.payload[0].header})//keyに変更
  })},
  [ProcessAction.initSession]: (state, action) => (initState(initialState)),
  [ProcessAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)
