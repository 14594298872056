import { axiosInstance } from 'http/index'

export const TimerApi = {
  init: (data) => {
    const instance = axiosInstance;
    return instance.post('/timer/init', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  status: (data) => {
    const instance = axiosInstance;
    return instance.post('/timer/status', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  regist: (data) => {
    const instance = axiosInstance;
    return instance.post('/timer/regist', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  update: (data) => {
    const instance = axiosInstance;
    return instance.post('/timer/update', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  outputFile: (data) => {
    const instance = axiosInstance;
    return instance.post('/timer/file', data, {responseType: 'blob'}).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },

}
