import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  Navbar,
  Nav,
  NavDropdown,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
} from 'react-bootstrap';

import _ from 'lodash';
import { EXTERNAL_URL, REFRESH_INTERVAL, GET_STATUS_URL, SALT } from 'constants/index';
// import './styles/contents.scss'
import logo001 from 'images/logo001.png';
import amadeus from 'images/main_amadeuslogo.png';
import { LambdaApi, TimerApi } from 'webapi';
// import md5 from 'js-md-5';
import { convert } from "lib";
import forge from 'node-forge';
import { LoginAction } from 'ducks/Login';
import { apply } from 'redux-saga/effects';

export const FooterComponent = (props) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  // const history = useHistory();
  // const location = useLocation();
  const dispatch = useDispatch();
  const loginAction = bindActionCreators(LoginAction, dispatch);

  const [cliStatus, setCliStatus] = React.useState('')
  const [tmrStatus, setTmrStatus] = React.useState('')
  const [authorization, setAuthorization] = React.useState('')
  const [intervalId, setIntervalId] = React.useState(null)

  React.useEffect(() => {
    let id = null
    if (login.office && login.member) {
      // console.log(login.office)
      // console.log(login.mntOffice)
      // console.log(login.authorization)
      setAuth()
      if (login.authorization && login.migration === '0') {
        // console.log('-----refresh start-----')
        getStatus()
        if (!intervalId) {
          id = setInterval(() => {
            // console.log('-----interval start-----')
            // setAuth()
            getStatus()
          }, REFRESH_INTERVAL)
          setIntervalId(id)
        }
      }
    } else {
      // console.log('-----refresh finish-----')
      // console.log(login.office)
      // console.log(login.mntOffice)
      intervalId && clearInterval(intervalId)
      setIntervalId(null)
    }

  }, [login.office, login.mntOffice, login.authorization, login.member, login.migration, app.gatewayBaseUri])

  const setAuth = () => {
    let md = forge.md.md5.create();
    let authDate = convert.toDateString(new Date(), 'yyyyMMdd')
    // console.log(login.office + SALT + authDate)
    // console.log(login.mntOffice + SALT + authDate)
    md.update((login.mntOffice?login.mntOffice:login.office) + SALT + authDate)
    // console.log(md.digest().toHex())
    loginAction.setInputLogin({
      authorization: `Basic ${md.digest().toHex()}`
    })
  }
  const getStatus = () => {
    if (app.gatewayBaseUri) {
      LambdaApi.get(app.gatewayBaseUri + GET_STATUS_URL, login)
      .then(res => {
        // console.log(res)
        setCliStatus(res.payload.status)
      }).catch(err => {
        setCliStatus('')
      }).finally(() => {
      })
      TimerApi.status({})
      .then(res => {
        // console.log(res)
        const errList = res.payload && _.filter(res.payload.timerReponseDto, r => r.tmrStatus==='エラー有'||r.tmrStatus==='10')
        setTmrStatus(errList.length>0?'error':'')
      }).catch(err => {
        setTmrStatus('')
      }).finally(() => {
      })
    }
  }

  const getDispStatus = () => {
    if (cliStatus==='Running') {
      return {
        text: 'Plusクライアント',
        icon: 'check_circle',
        color: 'ok',
      }
    } else if (cliStatus==='Stopped') {
      return {
        text: 'Plusクライアント',
        icon: 'block',
        color: 'stop',
      }
    } else {
      return {
        text: 'Plusクライアント',
        icon: 'report_problem',
        color: 'error',
      }
    }
  }

  return props.isFooterShow?(
    <React.Fragment>
      <Navbar fixed='bottom' className='footer'>
        {/* <Navbar.Collapse>
          <Col sm={3}> */}
            {tmrStatus==='error' &&
              <InputGroup size="sm" className="footer-alert">
                <InputGroup.Text id="footer-alert">
                  <span className={`material-icons-outlined stop`}>block</span>
                </InputGroup.Text>
                <FormControl
                  className= "footer-alert-text"
                  aria-label="Small"
                  aria-describedby="footer-alert"
                  value='タイマーエラー'
                  disabled
                />
              </InputGroup>
            }
            {login.authCli==='1' &&
              <InputGroup size="sm" className="footer-alert">
                <InputGroup.Text id="footer-alert">
                  <span className={`material-icons-outlined ${getDispStatus().color}`}>{getDispStatus().icon}</span>
                </InputGroup.Text>
                <FormControl
                  className= "footer-alert-text"
                  aria-label="Small"
                  aria-describedby="footer-alert"
                  value={`${getDispStatus().text}`}
                  disabled
                />
              </InputGroup>
            }
            <Nav.Link className= "footer-link" href={EXTERNAL_URL.manual.url} target={EXTERNAL_URL.manual.target}>
              <span className='material-icons'>help_outline</span>
              <span>マニュアル</span>
            </Nav.Link>
            <img src={logo001} alt='logo' height='20px' className='mr-1'/>
            <img src={amadeus} alt='logo' height='20px' className='mr-5'/>
      </Navbar>
    </React.Fragment>
  ):(<div />)
}

FooterComponent.defaultProps = {};
