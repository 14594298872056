import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { ProcessAction } from 'ducks/Process';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjGridNom from '@grapecity/wijmo.grid';
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass, DateTime } from "@grapecity/wijmo";
import { FlexGridDetail } from '@grapecity/wijmo.react.grid.detail';
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import { Selector } from "@grapecity/wijmo.grid.selector";
import { TITLE } from 'constants/title';
import { MESSAGE, HELPER_MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { PRINT_MESSAGE, ACCOUNT_CODE_TYPE, OUTPUT_TYPE, OUTPUT_LOGO, OUTPUT_REPORT } from 'constants/report';
import { LIST_ITEMS, PROCESS_KIND, PROCESS_TYPE, PROCESS_RESULT } from 'constants/process';
import { MasterAction } from 'ducks/Master';
import { ProcessApi, TicketApi, ReportApi, PrismApi } from 'webapi'

export const ProcessComponent = (props) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const process = useSelector(state => state.Process);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const processAction = bindActionCreators(ProcessAction, dispatch);

  const [grid, setGrid] = React.useState(null)
  const [detailGrid, setDetailGrid] = React.useState(null)
  const [selector, setSelector] = React.useState(null)
  const [selectedItems, setSelectedItems] = React.useState(null)
  const [dataList, setDataList] = React.useState([])
  const [isMenu, setIsMenu] = React.useState(false)

  const initGrid = (g) => {
    setGrid(g)
    g.selectionMode = 3
  }
  
  const initDetailGrid = (g, ctx) => {
    setDetailGrid(g)
    g.selectionMode = 3
    setTimeout(() => {
      grid.autoSizeRow(ctx.row.index + 1)
    },50)
  }

  React.useEffect(() => {
    setIsMenu((location.pathname === TITLE.process.link))
    appAction.showProcessing({isProcessing: true})
    appAction.setMessage('')

    ProcessApi.init({field: '1'})
    .then(res => {
      const list = []
      const detailList = []
      _.forEach(res.payload.bizProcessingResultDtoList,(r, i) => {
        const dateTime = new Date(r.processingDateTime)
        let dispAuth = true
        // switch (r.processType1) {
        //   case '0': dispAuth = login.auth030 === '1'; break;
        //   case '1': dispAuth = login.auth040 === '1'; break;
        //   case '2': dispAuth = login.auth050 === '1'; break;
        //   case '3': dispAuth = login.auth120 === '1'; break;
        //   case '5': dispAuth = login.auth070 === '1'; break;
        //   case '7': dispAuth = login.auth110 === '1'; break;
        // }
        dispAuth && list.push({
          ...r,
          processingDateTime: dateTime,
        })
      })
      _.forEach(res.payload.bizProcessingResultDetailDtoList,(r, i) => {
        const dateTime = new Date(r.cmnUpdate)
        detailList.push({
          ...r,
          processingDateTime: dateTime,
        })
      })
      
      const caller = (location.state && location.state.caller) ?location.state.caller: ''
      const processKind = caller==='menu'?-1:caller==='alert'?-1:1
      const processingResult = -1
      const savedProcessedSeq = sessionStorage.getItem('processedSeq')?_.toNumber(sessionStorage.getItem('processedSeq')):-1
      const processedSeq = caller==='menu'?-1:caller==='alert'?savedProcessedSeq:-1
      
      const orderedList = _.filter(_.orderBy(list, ['processingSeq'], ['desc']), r => {return r.processingSeq > processedSeq})
      // console.log(orderedList)
      // setDataList(orderedList)
      // 最新のSEQを保持
      if (location.pathname === TITLE.process.link) {
        // console.log(orderedList)
        if (orderedList.length > 0) sessionStorage.setItem('processedSeq', orderedList[0].processingSeq)
      }

      processAction.setInputProcess({
        list: orderedList,
        detailList: _.orderBy(detailList, ['processingSeq', 'seq'], ['desc', 'asc']),
        processKind: {
          ...process.processKind,
          original: processKind,
          value: processKind,
          text: processKind,
        },
        processingResult: {
          ...process.processingResult,
          original: processingResult,
          value: processingResult,
          text: processingResult,
        },
      })
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }, [location.pathname, location.key, login.auth030, login.auth040, login.auth050, login.auth070, login.auth120])

  
  const formatItem = (g, e) => {
    const item = e.getRow().dataItem
    const bind = e.getColumn().binding

    // console.log(item)
    // console.log(bind)
    if (item && (bind === 'processKind' || bind === 'processingResult')) {
      toggleClass(e.cell, 'process-cell-success', item[bind]=='0');
      toggleClass(e.cell, 'process-cell-failure', item[bind]=='1');
    }
    
  }

  const getDetailList = (ctx) => {
    // console.log(ctx)
    return _.filter(process.detailList, r => r.processingSeq === ctx.item.processingSeq)
  }
  
  React.useEffect(() => {
    if (process.list && process.list.length > 0) {
      let list = _.concat([], process.list)
      let dt = DateTime
      let procDt = new Date(process.processingDateTimeTo.text);
      const dateTo = dt.addDays(procDt, 1)

      if (process.processingDateTimeFrom.text) {
        // console.log(process.processingDateTimeFrom.text)
        list = list.filter(r => r.processingDateTime >= process.processingDateTimeFrom.text)
      }
      if (process.processingDateTimeTo.text) {
        // console.log(process.processingDateTimeTo.text)
        list = list.filter(r => r.processingDateTime <= dateTo)
      }
      if (process.processType1.text && process.processType1.text > -1) {
        // console.log(process.processType1.text)
        list = list.filter(r => r.processType1 == process.processType1.text)
      }
      if (process.processKind.text && process.processKind.text > -1) {
        // console.log(process.processKind.text)
        list = list.filter(r => r.processKind == process.processKind.text)
      }
      if (process.processingResult.text && process.processingResult.text > -1) {
        // console.log(process.processingResult.text)
        list = list.filter(r => r.processingResult == process.processingResult.text)
      }
      setDataList(_.orderBy(list, ['processingSeq'], ['desc']),)
      if (process.processingDateTimeFrom.text && process.processingDateTimeTo.text && 
        (process.processingDateTimeFrom.text.getTime() > process.processingDateTimeTo.text.getTime())) {
          processAction.setInputProcess({
            processingDateTimeFrom: {
              ...process.processingDateTimeFrom,
              isInvalid: true,
              helpertext: HELPER_MESSAGE.magnitude,
            },
            processingDateTimeTo: {
              ...process.processingDateTimeTo,
              isInvalid: true,
              helpertext: HELPER_MESSAGE.magnitude,
            }
          })
      } else {
        processAction.setInputProcess({
          processingDateTimeFrom: {
            ...process.processingDateTimeFrom,
            isInvalid: false,
            helpertext: '',
          },
          processingDateTimeTo: {
            ...process.processingDateTimeTo,
            isInvalid: false,
            helpertext: '',
          }
        })
      }
    }
  }, [process.list, process.processingDateTimeFrom.text, process.processingDateTimeTo.text, process.processType1.text, process.processKind.text, process.processingResult.text])

  const execDownload = (item) => {
    // console.log(item)
    // 出力
    if (item.processType1 !== '0' && item.s3FilePath) {
      // console.log(item.s3FilePath)
      let apiName = null
      let type = 'application/octet-stream'
      switch (item.processType1) {
        case '1':
          apiName = TicketApi.outputFile
          type = 'application/octet-stream'
          break
        case '2':
          apiName = TicketApi.excelFile
          type = 'application/octet-stream'
          break
        case '3':
          apiName = PrismApi.outputFile
          type = (item.processType2 === '0')?'application/pdf' :'application/octet-stream'
          break
        case '5':
          apiName = ReportApi.outputFile
          type = (item.processType2 === '0')?'application/pdf' :'application/octet-stream'
          break
        default: return false
      }
      // ファイルダウンロード
      // console.log(apiName)
      // console.log(item.s3FilePath)
      // console.log(item.s3FilePath.split(','))
      _.forEach(item.s3FilePath.split(','), (filename, i) => {
        const dto = {
          processingResultDto: {
            processingSeq: item.processingSeq,
            processType2: item.processType2,
          },
          downloadFileName: filename,
          responseType: 'blob',
        }
        apiName(dto).then((res) => {
          if (res.payload.messageList) {
            appAction.setMessage({message: res.payload.messageList,})
          } else {
            appAction.setMessage('')
            const blob = new Blob([res.payload], {type: type})
            if (window.navigator.msSaveOrOpenBlob) {
              // for IE,Edge
              window.navigator.msSaveOrOpenBlob(blob, filename);
            } else {
              // for chrome, firefox
              // console.log(type)
              // console.log(res.payload)
              const url = URL.createObjectURL(blob);
              const linkEl = document.createElement('a');
              linkEl.href = url;
              linkEl.setAttribute('download', filename);
              document.body.appendChild(linkEl);
              linkEl.click();
              URL.revokeObjectURL(url);
              linkEl.parentNode.removeChild(linkEl);
            }
          }
        })
      })
    }
  }

  const condClear = () => {
    processAction.setInputProcess({
      processingDateTimeFrom: {
        ...process.processingDateTimeFrom,
        text: null,
        isInvalid: false,
        helpertext: '',
      },
      processingDateTimeTo: {
        ...process.processingDateTimeTo,
        text: null,
        isInvalid: false,
        helpertext: '',
      },
      processType1: {
        ...process.processType1,
        text: '',
      },
      processKind: {
        ...process.processKind,
        text: '',
      },
      processingResult: {
        ...process.processingResult,
        text: '',
      }
    })
  }

  const download = () => {
    const fileType = 'txt/csv;charset=Shift_JIS';
    const rng = new wjGridNom.CellRange(0, 0, grid.rows.length - 1, grid.columns.length - 1)
    const csv = grid.getClipString(rng, true, true);
    const blob = new Blob([String.fromCharCode(0xFEFF), csv], { type: 'text/csv' });
    const fileName = 'list.csv'
    if (navigator.msSaveBlob) { // IE 
      navigator.msSaveBlob(blob, fileName);
    } 
    else {
        var e = document.createElement('a');
        e.setAttribute('href', URL.createObjectURL(blob));
        e.setAttribute('download', fileName);
        e.style.display = 'none';
        document.body.appendChild(e);
        e.click();
        document.body.removeChild(e);
    }

  }

  const rowHasDetailFn = (row) => {
    return row.dataItem.processingResult === 1
  }

  return (
    <React.Fragment>
      <Container fluid className='main'>
        {isMenu &&
          <Title
            title={TITLE.process.name}
            icon={TITLE.process.icon}
          />
        }
        <Card
          root={isMenu?'max-button2':'max-button-process'}
          size='lg'
          hideClose
          header={null}
          subheader={null}
          body={
            <React.Fragment>
              <Card
                root='max'
                size='md'
                hideClose
                subheader={null}
                body={
                  <React.Fragment>
                    <div className='d-inline-flex align-items-end mb-2'>
                      <div className='d-flex flex-column'>
                      <div className='width-max-content'><label sm="3" className='form-label mb-0'>処理日時</label></div>
                        <div className='d-flex flex-row'>
                          <Common.DateTime
                            attrs={{...process.processingDateTimeFrom}}
                            onBlur={(e) => {
                              processAction.changeInputProcess(e)
                              if (!process.processingDateTimeTo.text) {
                                processAction.changeInputProcess(
                                  convert.getTarget(process.processingDateTimeTo, 'text', e.processingDateTimeFrom.text)
                                )
                              }
                            }}
                            group={{
                              className: 'width-max-content mr-3',
                            }}
                          />
                          <div className='mt-2 mr-3'>～</div>
                          <Common.DateTime
                            attrs={{...process.processingDateTimeTo}}
                            onBlur={(e) => processAction.changeInputProcess(e)}
                            group={{
                              className: 'width-max-content mr-2',
                            }}
                          />
                        </div>
                      </div>
                      <Common.Select
                        attrs={{...process.processType1}}
                        group={{
                          className: 'width-20 mr-2',
                        }}
                        minLength={1}
                        list={PROCESS_TYPE}
                        onChange={(e) => processAction.changeInputProcess(e)}
                      />
                      <Common.Select
                        attrs={{...process.processKind}}
                        group={{
                          className: 'width-16 mr-2',
                        }}
                        minLength={1}
                        list={PROCESS_KIND}
                        onChange={(e) => processAction.changeInputProcess(e)}
                      />
                      <div className='ml-2'>
                        <Common.Select
                          // btnSize='sm'
                          attrs={{...process.processingResult}}
                          // className='mr-5'
                          group={{
                            className: 'width-14 mr-0',
                          }}
                          list={PROCESS_RESULT}
                          onChange={(e) => processAction.changeInputProcess(e)}
                          // onChange={(e) => processAction.changeInputProcess(
                          //   convert.getTarget(process.processingResult, 'text', e.processingResult.text))}
                        />
                      </div>
                      <Button
                        {...process.clear}
                        className={`button-gray btn-sm ml-5 width-max-content`}
                        onClick={(e) => condClear()}
                        disabled={app.isProcessing}
                      >
                        {process.clear.label}
                      </Button>
                    </div>
                    <div id='process-list-body' className={`process-list-body ${isMenu?'menu':''}`} >
                      <wjGrid.FlexGrid
                        deferResizing={true}
                        // itemsSource={props.search.data}LIST_ITEMS
                        itemsSource={dataList}
                        allowSorting={'None'}
                        allowResizing={3}
                        allowDragging={false}
                        showMarquee={true}
                        frozenColumns={1}
                        initialized={(s) => initGrid(s)}
                        formatItem={(g, e) => formatItem(g, e)}
                        autoRowHeights={true}
                        autoSizeMode={3}
                        // sortingColumn={(g, e) => {console.log(popup); console.log(popupData); console.log(e)}}
                      >
                        {LIST_ITEMS.list.map((r, i) => {
                          return (
                            <wjGrid.FlexGridColumn
                              key={i}
                              binding={r.id}
                              header={r.header}
                              dataType={r.dataType}
                              format={r.format}
                              isReadOnly={r.isReadOnly}
                              isRequired={r.isRequired}
                              maxLength={r.maxLength}
                              width={r.width}
                              minWidth={r.minWidth}
                              maxWidth={r.maxWidth}
                              allowSorting={r.allowSorting}
                              align={r.align}
                              dataMap={r.dataMap}
                              template= {(ctx) => <div>ダウンロード</div>}
                              // cellTemplate= {r.id==='download'?(ctx) => {console.log(ctx)
                              //   return (ctx.item.s3FilePath)?<div>ダウンロード</div>:''
                              // }:null}
                            >
                              {r.id === 'download' &&
                                <wjGrid.FlexGridCellTemplate cellType="Cell" template={cell =>
                                    <React.Fragment>
                                      {cell.item.s3FilePath &&
                                      <div>
                                        <span className='link-text' onClick={(e)=>execDownload(cell.item)}>
                                          <span className='material-icons-outlined md'>file_download</span>ダウンロード
                                        </span>
                                      </div>
                                      }
                                    </React.Fragment>
                                  }
                                />
                              }
                            </wjGrid.FlexGridColumn>
                          )})
                        }
                        <FlexGridDetail
                          detailVisibilityMode={'ExpandMulti'}
                          // rowHasDetail={(row) => rowHasDetailFn(row)}
                          // initialized={(s) => initDetailGrid(s)}
                          template={ctx =>
                            <wjGrid.FlexGrid
                              deferResizing={true}
                              // itemsSource={props.search.data}LIST_ITEMS
                              itemsSource={getDetailList(ctx)}
                              allowSorting={'None'}
                              allowDragging={false}
                              showMarquee={true}
                              frozenColumns={1}
                              initialized={(s) => initDetailGrid(s, ctx)}
                              autoRowHeights={true}
                            >
                              {LIST_ITEMS.detail.map((r,i) => {
                                return (
                                  <wjGrid.FlexGridColumn
                                    key={i}
                                    binding={r.id}
                                    header={r.header}
                                    dataType={r.dataType}
                                    format={r.format}
                                    isReadOnly={r.isReadOnly}
                                    isRequired={r.isRequired}
                                    maxLength={r.maxLength}
                                    width={r.width}
                                    minWidth={r.minWidth}
                                    maxWidth={r.maxWidth}
                                    allowSorting={r.allowSorting}
                                    align={r.align}
                                    dataMap={r.dataMap}
                                    multiLine={true}
                                  />
                                )})
                              }
                            </wjGrid.FlexGrid>
                          }
                        />
                      </wjGrid.FlexGrid>
                    </div>
                  </React.Fragment>
                }
              />
            </React.Fragment>
          }
        />
        <Card 
          size='lg'
          root='button'
          hideClose
          header={null}
          subheader={null}
          body={
            <div className='card-buttom-button'>
              <Button
                {...process.csv}
                className={`button-gray ml-auto mr-5 width-max-content`}
                onClick={(e) => download()}
                disabled={app.isProcessing}
              >
                {process.csv.label}
              </Button>
              {/* <Button
                {...process.print}
                className='button-gray ml-auto width-max-content'
                disabled={app.isProcessing}
                // onClick={() => execDownload('print')}
              >
                {process.print.label}
              </Button>
              <Button
                {...process.download}
                className='button-gray ml-3 width-max-content'
                disabled={app.isProcessing}
                // onClick={() => execDownload('download')}
              >
                {process.download.label}
              </Button> */}
            </div>
          }
        />
      </Container>
    </React.Fragment>
  )
}