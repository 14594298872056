import * as React from 'react';
import * as ReactDOM from 'react-dom';
//
import { Form, InputGroup} from 'react-bootstrap';
import * as wijmo from '@grapecity/wijmo';
import * as wjInput from '@grapecity/wijmo.react.input';
import _ from 'lodash';
//
export const Number = React.forwardRef((props, ref) => {

  const {attrs} = props;

  const _initialize = (ctrl) => {
    // console.log(ctrl.inputElement)
    ctrl.inputElement.id = attrs.id;
  }
  
  return (
    <React.Fragment>
      <Form.Group
        controlId={attrs.id}
        {...props.group}
        className={`form-group ${props.group.className} ${attrs.visible===false?'d-none':''}`}
      >
        <Form.Label
          sm="3"
          className={`${attrs.label==='_none' && 'd-none'} ${!attrs.labelclass?'':attrs.labelclass}`}
          {...props.label}
        >
          {attrs.label}
        </Form.Label>
        <InputGroup
          // {...props.group}
          className='flex-column'
        >
          <div className='d-flex flex-row flex-nowrap'>
            <wjInput.InputNumber
              className={`${props.group.textwidth?props.group.textwidth:'non-class'} ${(attrs.helpertext && attrs.isInvalid)?'is-invalid':'is-valid'}`}
              isRequired={attrs.isRequired===true?true:false}
              isInvalid={attrs.isInvalid}
              step={attrs.step}
              min={attrs.min}
              max={attrs.max}
              maxLength={attrs.maxLength}
              required={attrs.required}
              isDisabled={attrs.disabled}
              // className='is-invalid'
              value={_.isNumber(attrs.text)?attrs.text:null}
              initialized={(control) => _initialize(control)}
              format={attrs.format}
              valueChanged={(e) => {
                if (props.valueChanged) {
                  const target = {id: attrs.id}
                  target[attrs.id] = {
                    ...attrs,
                    text: e.value,
                  }
                  props.valueChanged(target)
                }
                wijmo.toggleClass(e.hostElement, 'minus-number', e.value && _.isNumber(e.value) && _.toNumber(e.value) < 0);
              }}
              lostFocus={(e) => {
                if (props.onBlur) {
                  const target = {id: attrs.id}
                  target[attrs.id] = {
                    ...attrs,
                    text: e.value,
                  }
                  props.onBlur(target)
                }
              }}
              ref={ref}
            />
            <InputGroup.Text
              sm="3"
              className={`${!attrs.suffix && 'd-none'} text-suffix`}
              {...props.suffix}
            >
              {attrs.suffix}
            </InputGroup.Text>
          </div>
          <Form.Control.Feedback className={attrs.isInvalid?'d-block':'d-none'} type="invalid">
            {attrs.helpertext}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </React.Fragment>
  )

})

