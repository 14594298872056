import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { TITLE } from 'constants/title';
import { 
  Col,
  Row,
  Container,
  Form,
  Button,
  Badge,
  ProgressBar,
  Table,
  Toast,
} from 'react-bootstrap';
import { entryCheck } from "lib";
import { convert } from "lib";
import _ from 'lodash';
import logo001 from 'images/logo001.png';
import amadeus from 'images/main_amadeuslogo.png';
import { MAIN_MENU } from 'constants/menu';
import { LIST_ITEMS, TODAY_STATUS, INFO_BADGE1, INFO_BADGE2, INFO_BADGE3 } from 'constants/main';
import { LambdaApi, MainApi, TicketApi } from 'webapi';
import { GET_STATUS_URL, GET_HISTORY_URL, GET_COUNT_URL, GET_PERIOD_URL, SALT } from 'constants/index';
import forge from 'node-forge';
import { LoginAction } from 'ducks/Login';
import * as wijmo from '@grapecity/wijmo';
import { format } from '@grapecity/wijmo';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass, DateTime } from "@grapecity/wijmo";
import * as wjChart from '@grapecity/wijmo.react.chart';
import { dataMapper } from 'lib';
import { Responsive, WidthProvider } from 'react-grid-layout';

const ResponsiveGridLayout = WidthProvider(Responsive);

export const MainComponent = (props) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const loginAction = bindActionCreators(LoginAction, dispatch);
  
  const [sumGrid, setSumGrid] = React.useState(null)
  const [rcvGrid, setRcvGrid] = React.useState(null)
  const [initial, setInitial] = React.useState(true)
  const [initialLayout, setInitialLayout] = React.useState(true)
  const [layouts, setLayouts] = React.useState({lg: [
    {w: 1, h: 18, x: 0, y:   0, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'news', name: 'URI-Proに関するお知らせ',},
    {w: 1, h: 19, x: 1, y:   0, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'summary', name: 'ピリオド別 発券サマリ',},
    {w: 1, h: 18, x: 2, y:  0, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'error', name: '発券データ受信エラー状況',},
    {w: 1, h: 24, x: 0, y:  2, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'data', name: '日別の発券データ',},
  ]});

  React.useEffect(() => {
    // console.log(login.office)
    // console.log(login.mntOffice)
    // console.log(login)
    // console.log(app.gatewayBaseUri)
    if (login.office && login.migration === '0' && login.member && login.authorization && login.authCode==='000' && app.gatewayBaseUri) {
      
      LambdaApi.post(app.gatewayBaseUri + GET_HISTORY_URL, {daysPast: 7}, login)
      .then(res => {
        // console.log(res)
        let todayStatus = 'noError'
        let receiveErrorList = []
        if (res.payload) {
          todayStatus = res.payload.todayStatus
          _.forEach(res.payload.errorLists, (r, i) => {
            receiveErrorList.push({
              ...r,
              tktNo: r.tktNo.length>4?r.tktNo.substring(0,3) + '-' + r.tktNo.substring(3):r.tktNo,
            })
          })
          appAction.setInputApp({receiveErrorList: receiveErrorList, todayStatus: todayStatus})
        }
      }).catch(err => {
      }).finally(() => {
      })
      LambdaApi.post(app.gatewayBaseUri + GET_COUNT_URL, {daycount: 9}, login)
      .then(res => {
        // console.log(res)
        let dayList = []
        _.forEach(res.payload && res.payload.ticketingSummaryList, (r, i) => {
          let yyyy = null
          let mm = null
          let dd = null
          if ((r.yyyymmdd.length === 8)) {
            yyyy = _.toNumber(r.yyyymmdd.substring(0, 4))
            mm = _.toNumber(r.yyyymmdd.substring(4, 6))
            dd = _.toNumber(r.yyyymmdd.substring(6))
          }
          dayList.push({
            ...r,
            mmdd: `${mm}/${dd}`,
            issueCount: _.toNumber(r.issueCount),
            refundCount: _.toNumber(r.refundCount),
            voidCount: _.toNumber(r.voidCount),
          })
        })
        // console.log(dayList)
        appAction.setInputApp({dayList: dayList})
      }).catch(err => {
      }).finally(() => {
      })

      LambdaApi.get(app.gatewayBaseUri + GET_PERIOD_URL, login)
      .then(res => {
        // console.log(res)
        let summaryList = []
        // let pYyyy = null
        // let pMm = null
        _.forEach(res.payload && res.payload.ticketingSummaryList, (r, i) => {
          const yyyy = (r.yyyymm.length === 6)?_.toNumber(r.yyyymm.substring(0, 4)): null
          const mm = (r.yyyymm.length === 6)?_.toNumber(r.yyyymm.substring(4))-1: null
          summaryList.push({
            ...r,
            // yyyymm: (yyyy && mm)?wijmo.DateTime.newDate(yyyy, mm, 1):null,
            yyyymm: wijmo.DateTime.newDate(yyyy, mm, 1),
            // yyyymm: (yyyy && mm && (pYyyy !== yyyy || pMm !== mm))?wijmo.DateTime.newDate(yyyy, mm, 1):null,
            // voidCount: r.voidCount<=5?5:r.voidCount,
            voidFreeCount: _.floor(r.issueCount * 0.05),
          })
          // pYyyy = yyyy
          // pMm = mm
        })
        appAction.setInputApp({summaryList: summaryList})
      }).catch(err => {
      }).finally(() => {
      })

      MainApi.showInfo()
      .then(res => {
        // console.log(res)
        let infoList = []
        _.forEach(res.payload && res.payload.list, (r, i) => {
          // const yyyy = (r.yyyymm.length === 6)?_.toNumber(r.yyyymm.substring(0, 4)): null
          // const mm = (r.yyyymm.length === 6)?_.toNumber(r.yyyymm.substring(4))-1: null
          infoList.push({
            ...r,
            // yyyymm: wijmo.DateTime.newDate(yyyy, mm, 1),
            // voidFreeCount: _.floor(r.issueCount * 0.05),
          })
        })
        appAction.setInputApp({infoList: infoList})
      }).catch(err => {
      }).finally(() => {
      })

      MainApi.tktCount()
      .then(res => {
        if (res.payload) {
          appAction.setInputApp({
            tktCount: res.payload.tktCount?_.toNumber(res.payload.tktCount):0,
            tktCountLimit: res.payload.tktCountLimit?_.toNumber(res.payload.tktCountLimit):0,
            lastTktRcvDate: res.payload.lastTktRcvDate?convert.toDateTimeString(res.payload.lastTktRcvDate, 'yyyy/MM/dd HH:mm'):'',
          })
        }
      }).catch(err => {
      }).finally(() => {
      })
    }
  }, [login.office, login.mntOffice, login.member, login.authorization, login.migration, app.gatewayBaseUri, login.authCode])

  React.useEffect(() => {
    appAction.showProcessing({isProcessing: false})

    const locaLayouts = localStorage.getItem('dashbordLayouts')
    if (locaLayouts) {
      setLayouts(JSON.parse(locaLayouts))
    }
    setInitial(false)
  }, [])

  const initSumGrid = (g) => {
    setSumGrid(g)
    g.select(0, 0);
    g.columnHeaders.rows.defaultSize = 60;
  }

  const sumFormatItem = (g, e) => {
    const item = e.getRow().dataItem
    const bind = e.getColumn().binding
    _.forEach(g.rowHeaders.rows, (r, i) => {
      toggleClass(e.cell, 'wj-wrap', g.columnHeaders === e.panel);
    })
    if (item) {
      if (bind === 'voidCount' || bind === 'voidFreeCount'){
        toggleClass(e.cell, 'warn-void', item.voidCount !== 0 && item.voidCount >= (item.voidFreeCount * 0.8) && item.voidCount < item.voidFreeCount);
        toggleClass(e.cell, 'alert-void', item.voidCount !== 0 && item.voidCount >= item.voidFreeCount);
      }
      if (bind === 'yyyymm') {
        toggleClass(e.cell, 'text-indent-200p', (e.row-1) >= 0 && e.getRow().dataItem[bind].valueOf() === g.rows[e.row-1].dataItem[bind].valueOf());
        
      }
    }
  }

  const initRcvGrid = (g) => {
    setRcvGrid(g)
    g.select(0, 0);
    // g.columnHeaders.rows.defaultSize = 60;
  }

  return (
    <React.Fragment>
      <Card
        root='max'
        body={
          <React.Fragment>
            <div className={`tkt-cnt-error ${app.tktCountLimit && app.tktCount > app.tktCountLimit?'':'d-none'}`}>
              <div>利用可能な発券データ登録数を超過しました。</div>
              <div>データメンテナンスで発券データを削除するか、契約内容の見直しを行ってください。</div>
            </div>
            <div className={`tkt-cnt-error ${app.tktCountLimit && (app.tktCount <= app.tktCountLimit) && (app.tktCount / app.tktCountLimit) >= app.ticketLimit?'':'d-none'}`}>
              <div>登録件数が上限値に近づいてきています。</div>
              <div>契約を更新するか、発券データを削除してください。</div>
            </div>
            <div className='d-flex justify-content-center align-items-center my-4'>
              <div className='d-flex justify-content-center align-items-baseline width-p-33'/>
              <div className='d-flex justify-content-center align-items-baseline width-p-33'>
                <img className='mr-2' src={logo001} alt="logo" height="55px"/>
                <img src={amadeus} alt="logo" height="25px"/>
              </div>
              <div className='d-flex justify-content-center align-items-baseline width-p-33 flex-row'>
                <div className='d-flex flex-column'>
                <div className='text-center info-tkt-count'>　</div>
                  <div className='width-11 ml-5'>
                    <label sm="3" className='label-color m-0 text-right'>現在の登録状況</label>
                  </div>
                </div>
                <div className='info-tkt-count-progress d-flex flex-column'>
                  <div className='text-center info-tkt-count'>{format('{count:n0}', {count: app.tktCount})}</div>
                  <div>
                    <label sm="3" className='label-color m-0 text-right width-p-5'>0</label>
                    <div className='d-inline-block m-0 width-p-75'>
                      <ProgressBar now={app.tktCountLimit?app.tktCount / app.tktCountLimit * 100:0} />
                    </div>
                    <label sm="3" className='label-color m-0 text-left width-p-15'>{format('{count:n0}', {count: app.tktCountLimit})}</label>
                    
                  </div>
                  <div className='text-center'>最終受信日時：{app.lastTktRcvDate}</div>
                </div>
              </div>
            </div>
            <div className='d-flex mx-4 mb-5'>
              {MAIN_MENU.map((menu, i) => {
                return(
                  <div key={i} className='d-flex flex-column ml-5 width-p-20'>
                    {menu.map(r => {
                      if ((r.link!== TITLE.prism.link) ||
                          (r.link=== TITLE.prism.link) && app.termProList && _.find(app.termProList, pro => pro.proItemId==='function_prism').proItemValue==='1') {
                        return (
                          <Button
                            key={r.name}
                            className='button-menu mt-1'
                            disabled={login[`${r.auth}`] && login[`${r.auth}`] !== '1'}
                            onClick={() => {
                              if (r.link === TITLE.ticketEdit.mainLink + '/_new') {
                                TicketApi.detailAuth()
                                .then(res => {
                                  if (res.payload === 'auth') {
                                    const thisTime = _.toString((new Date()).getTime()).substring(4)
                                    let tktNewList = localStorage.getItem('tktNewList')?JSON.parse(localStorage.getItem('tktNewList')):[{name:'', value:'', mode: ''}]
                                    tktNewList.push({
                                      name: '未登録',
                                      value: '_new' + thisTime,
                                      mode: '0',
                                    })
                                    sessionStorage.setItem('authCode', login.authCode)
                                    sessionStorage.setItem('loginCd', login.loginCd)
                                    sessionStorage.setItem('mbrCd', login.mbrCd)
                                    sessionStorage.setItem('office', login.office)
                                    sessionStorage.setItem('mntOffice', login.mntOffice)
                                    localStorage.setItem('tktNewList', JSON.stringify(tktNewList))
                                    window.open(`${TITLE.ticketEdit.mainLink}/_new${thisTime}`, '_tktEdit_new' + thisTime)
                                  }
                                })
                                .catch(() =>{})
                                .finally(() => {})
                              } else {
                                history.push(r.link, {...r.params, init: true})
                              }
                            }}
                          >
                            <div className='menu-icon'><span className='material-icons-outlined md'>{r.icon.name}</span></div>
                            <div className='menu-text'>{r.name}</div>
                            <div className='menu-arrow'><span className='material-icons-outlined'>arrow_right</span></div>
                          </Button>
                        )
                      } else return
                    })}
                  </div>
                )
              })}
            </div>
          </React.Fragment>
        }
      />
      <Card
        root='max-panel'
        bodyClass={`panel-card-body ${app.tktCountLimit && app.tktCount > app.tktCountLimit?'error':''}`}
        body={
            <ResponsiveGridLayout
              key="main"
              className="layout"
              layouts={layouts}
              breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
              cols={{ lg: 3, md: 1, sm: 1, xs: 1, xxs: 1 }}
              rowHeight={10}
              containerPadding={[0, 0]}
              // draggableHandle='.toast-header'
              margin={[3, 10]}
              allowOverlap={false}
              onLayoutChange={(_, layouts) => {
                if (initial) return
                if (initialLayout) return setInitialLayout(false)
                if (!initial && !initialLayout) {
                  localStorage.setItem('dashbordLayouts', JSON.stringify(layouts))
                  setLayouts(layouts)
                }
              }}
            >
              <div key="news">
                <Card
                  header='URI-Proに関するお知らせ'
                  hideClose
                  body={
                    <div className='main-panel-list'>
                      {app.infoList && app.infoList.map( (r, i) => {
                        const kind1 = dataMapper(INFO_BADGE1, 'value', 'name', r.infoKind)
                        const kind1c = dataMapper(INFO_BADGE1, 'value', 'cName', r.infoKind)
                        const kind2 = dataMapper(INFO_BADGE2, 'value', 'name', r.infoKind2)
                        const kind2c = dataMapper(INFO_BADGE2, 'value', 'cName', r.infoKind2)
                        const isNew = Math.floor((new Date() - new Date(r.startDate)) / (1000 * 60 * 60 * 24)) < 7
                        const kind3 = dataMapper(INFO_BADGE3, 'value', 'name', isNew)        
                        return(i<4 &&
                        <React.Fragment key={i} >
                          <div key={'badge' + i}>
                            <label sm="3" className='label-color m-2'>{r.infoDate.replaceAll('-','.')}</label>
                            <Badge pill className={`badge-color ${kind1c}`}>
                              {kind1}
                            </Badge>{' '}
                            <Badge pill className={`badge-color ${kind2c} ${kind2&&kind2!='　'?'':'d-none'}`}>
                              {kind2}
                            </Badge>{' '}
                            <Badge pill className={`badge-color text-danger ${kind3&&kind3!='　'?'':'d-none'}`}>
                              {kind3}
                            </Badge>{' '}
                          </div>
                          {
                            r.infoUrl &&
                            <div key={'title' + i} className='info-title-box' onClick={(e)=>{window.open(r.infoUrl, `uri_info_${r.id}`)}}>
                              <div className='info-title all'>
                                {r.infoSubject}
                              </div>
                              <div className={`material-icons info-caret`}>
                                arrow_right
                              </div>
                            </div>
                          }
                          {
                            !r.infoUrl &&
                            <div key={'title' + i} className='info-title-box no-url'>
                              <div className='info-title all no-url'>
                                {r.infoSubject}
                              </div>
                            </div>
                          }
                        </React.Fragment>
                      )})}
                      <div
                        className='old-info'
                        onClick={(e)=>{history.push(TITLE.info.link, {init: true})}}
                      >
                        <div className='d-inline-block mt-1 ml-auto'>過去のお知らせを見る</div>
                        <div className={`material-icons info-caret`}>arrow_right</div>
                      </div>
                    </div>
                  }
                />
              </div>
              <div key="summary">
                <Card
                  header='ピリオド別 発券サマリ'
                  subheader='※NDCの件数は含まれていません。'
                  hideClose
                  body={
                    <div className='main-panel-list'>
                      <wjGrid.FlexGrid
                        deferResizing={true}
                        // itemsSource={props.search.data}LIST_ITEMS
                        itemsSource={app.summaryList}
                        allowSorting={'None'}
                        allowResizing={'Both'}
                        showMarquee={true}
                        initialized={(s) => initSumGrid(s)}
                        formatItem={(g, e) => sumFormatItem(g, e)}
                        allowDragging={false}
                        // sortingColumn={(g, e) => {console.log(popup); console.log(popupData); console.log(e)}}
                      >
                        {LIST_ITEMS.sum.map((r, i) => {
                          return (
                            <wjGrid.FlexGridColumn
                              key={i}
                              binding={r.id}
                              header={r.header}
                              dataType={r.dataType}
                              format={r.format}
                              isReadOnly={r.isReadOnly}
                              isRequired={r.isRequired}
                              maxLength={r.maxLength}
                              width={r.width}
                              minWidth={r.minWidth}
                              maxWidth={r.maxWidth}
                              allowSorting={r.allowSorting}
                              align={r.align}
                              dataMap={r.dataMap}
                            >
                            </wjGrid.FlexGridColumn>
                          )})
                        }
                      </wjGrid.FlexGrid>
                    </div>
                  }
                />
              </div>
              <div key="error">
                <Card
                  header='発券データ受信エラー状況'
                  hideClose
                  body={
                    <div className='main-panel-list'>
                      <Badge pill variant={dataMapper(TODAY_STATUS, 'value', 'cName', app.todayStatus)}>
                        {dataMapper(TODAY_STATUS, 'value', 'name', app.todayStatus)}
                      </Badge>{' '}
                      <div className={`sub ${app.receiveErrorList && app.receiveErrorList.length>0?'':'d-none'}`}>
                        <wjGrid.FlexGrid
                          deferResizing={true}
                          // itemsSource={props.search.data}LIST_ITEMS
                          itemsSource={app.receiveErrorList}
                          allowSorting={'None'}
                          allowDragging={false}
                          allowResizing={'Both'}
                          showMarquee={true}
                          initialized={(s) => initRcvGrid(s)}
                          // formatItem={(g, e) => rcvFormatItem(g, e)}
                          // sortingColumn={(g, e) => {console.log(popup); console.log(popupData); console.log(e)}}
                        >
                          {LIST_ITEMS.rcv.map((r, i) => {
                            return (
                              <wjGrid.FlexGridColumn
                                key={i}
                                binding={r.id}
                                header={r.header}
                                dataType={r.dataType}
                                format={r.format}
                                isReadOnly={r.isReadOnly}
                                isRequired={r.isRequired}
                                maxLength={r.maxLength}
                                width={r.width}
                                minWidth={r.minWidth}
                                maxWidth={r.maxWidth}
                                allowSorting={r.allowSorting}
                                align={r.align}
                                dataMap={r.dataMap}
                              >
                              </wjGrid.FlexGridColumn>
                            )})
                          }
                        </wjGrid.FlexGrid>
                      </div>
                    </div>
                  }
                />
              </div>
              <div key="data">
                <Card
                  header='日別の発券データ'
                  hideClose
                  body={
                    <div className=''>
                      <wjChart.FlexChart
                        header='日別の発券データ'
                        chartType='Column'
                        bindingX='mmdd'
                        stacking='Stacked'
                        itemsSource={app.dayList}
                        palette={['rgba(0,94,184,1)', 'rgba(255,132,45,1)', 'rgba(120,120,117,1)']}
                      >
                        <wjChart.FlexChartSeries name='通常' binding='issueCount'></wjChart.FlexChartSeries>
                        <wjChart.FlexChartSeries name='VOID' binding='voidCount'></wjChart.FlexChartSeries>
                        <wjChart.FlexChartSeries name='REFUND' binding='refundCount'></wjChart.FlexChartSeries>
                      </wjChart.FlexChart>
                    </div>
                  }
                />
              </div>
          </ResponsiveGridLayout>
        }
      />
      <Common.RouteLeavingGuard
        when={true}
        shouldBlockNavigation={()=>false}
      />
    </React.Fragment>
  )
}

