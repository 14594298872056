import * as React from 'react';
import * as ReactDOM from 'react-dom';
//
import { Form, InputGroup } from 'react-bootstrap';
import * as wijmo from '@grapecity/wijmo';
import * as wjInput from '@grapecity/wijmo.react.input';
import { convert } from 'lib'
//
export const Time = React.forwardRef((props, ref) => {

  const {attrs, range} = props;
// console.log(attrs)
  return (
    <React.Fragment>
      <div
        {...props.group}
        className={`${props.group.className} ${attrs.visible===false?'d-none':''}`}
      >
        <Form.Label
          sm="3"
          className={attrs.label==='_none' && 'd-none'}
          {...props.label}
          htmlFor={attrs.id}
        >
          {attrs.label}
        </Form.Label>
        <InputGroup>
          <wjInput.InputTime
            value={attrs.text?attrs.text:null}
            isInvalid={attrs.isInvalid}
            format={attrs.format}
            mask={attrs.mask}
            min={attrs.min?attrs.min:null}
            max={attrs.max?attrs.max:null}
            step={attrs.type.startsWith('time')?5:0}
            isEditable={true}
            id={attrs.id}
            className={`type-${attrs.type}`}
            required={attrs.required}
            isRequired={attrs.isRequired}
            isDisabled={attrs.disabled}
            valueChanged={(e) => {
              props.onBlur(convert.getTarget(attrs, 'text', e.value))
            }}
          >
          </wjInput.InputTime>
          <Form.Control.Feedback type="invalid">
            {attrs.helpertext}
          </Form.Control.Feedback>
        </InputGroup>
      </div>
    </React.Fragment>
  )

})

