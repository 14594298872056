import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from 'components';
import * as Common from 'components/common';

import { Button } from 'react-bootstrap';
import _ from 'lodash';
import { RADIOS, CHECKS } from 'constants/search';
import { entryCheck, convert } from "lib";
// import '../styles/conditionCard.scss'

export const CardTextBlankComponent = (props) => {
  const [selItems, setSelItems] = React.useState([])
  const [selValPath, setSelValPath] = React.useState('')
  const [dispMemPath, setDispMemPath] = React.useState('')
  const [srchMemPath, setSrchMemPath] = React.useState('')
  const [dataList, setDataList] = React.useState([])

  // 初期化
  React.useEffect(() => {
    clear(true)
    setTimeout(() => {
      _.forEach(props.search, (item, key) => {
        if (key === props.condition.key + '_array') {
          if (_.isArray(item.text)) setSelItems(item.text)
        }
      })
    })
    // 
    switch (props.condition.key) {
      default:
        setSelValPath('text')
        setDispMemPath('text')
        setDataList([])
        setSrchMemPath('text')
        break;
    }
  }, [props.condition.key])

  // clear
  const clear = (isKeep) => {
    const obj = {}
    if (!isKeep) setSelItems([])
    if (!props.items[props.items.length-1].value || !isKeep) {
      obj[props.items[props.items.length-1].id] = {
        ...props.items[props.items.length-1],
        original: RADIOS.blank[1].value,
        value: RADIOS.blank[1].value,
        text: RADIOS.blank[1].value,
      }
    }
    Object.keys(obj).length && props.setInput({...obj})
  }

  React.useEffect(() => {
    _.map(props.items, (r, i) => {
      if (r.id.endsWith('array')) {
        const obj = convert.getTarget(r, 'text', selItems)
        delete obj.id
        props.setInput(obj)
      }
    })
  }, [selItems])

  const getBody = (choiceType) => {
    let getItem = null;
    switch (choiceType) {
      case RADIOS.blank[0].value: 
        getItem = null;
        break;
      case RADIOS.blank[1].value:
        getItem = _.map(props.items, (r, i) => {
          if (i >= props.items.length-1) return
          if (r.id.endsWith('array')) {
            return (
              <React.Fragment key={i}>
                <Common.MultiSelect
                  key={i}
                  attrs={r}
                  onBlur={(e) => props.onBlur(e)}
                  group={{
                    className: 'width-max-content mr-2 mb-1',
                    textwidth: 'min-width-30',
                    textsuffixwidth: 'max-width-25',
                    inputwidth: '150px'
                  }}
                  minLength={1}
                  selectedValuePath={selValPath}
                  displayMemberPath={dispMemPath}
                  list={dataList}
                  searchMemberPath={srchMemPath}
                  selectedItems={selItems}
                  setSelectedItems={setSelItems}
                  // template={getTemp()}
                  isEditable={true}
                  isWildCard={props.condition.isWildCard}
                />
              </React.Fragment>
            )
          } else return
        })
    }
    return (
      <React.Fragment>
        <div className='card-condition-item'>
          <Common.RadioButton
            btnSize='sm'
            className='min-width-25 width-25 mr-3'
            attrs={props.items[props.items.length-1]}
            radios={RADIOS.blank}
            onChange={(e) => props.onBlur(e)}
          />
          {getItem}
        </div>
        <div className='d-inline-block mb-1'>
          <Button
            id='clear'
            className='button-gray small width-max-content'
            onClick={(e) => clear()}
          >
            クリア
          </Button>
        </div>
      </React.Fragment>
    )
  }
  
  return (
    <React.Fragment>
      <Card 
        size={props.cardSize}
        header={props.condition.header}
        subheader={props.items[props.items.length-1].text===RADIOS.blank[1].value?props.condition.subheader:null}
        body={getBody(props.items[props.items.length-1].text)}
        condition={props.condition}
        onClose={props.onClose}
      />
    </React.Fragment>
  )
}
