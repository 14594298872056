import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { TITLE } from 'constants/title';
import { 
  Col,
  Row,
  Container,
  Form,
  Button,
  Badge,
  ProgressBar,
  Table,
  Toast,
} from 'react-bootstrap';
import { entryCheck } from "lib";
import { convert } from "lib";
import _ from 'lodash';
import logo001 from 'images/logo001.png';
import amadeus from 'images/main_amadeuslogo.png';
import { MAIN_MENU } from 'constants/menu';
import { LIST_ITEMS, TODAY_STATUS, INFO_BADGE1, INFO_BADGE2, INFO_BADGE3 } from 'constants/main';
import { LambdaApi, MainApi } from 'webapi';
import { GET_STATUS_URL, GET_HISTORY_URL, GET_COUNT_URL, GET_PERIOD_URL, SALT } from 'constants/index';
import forge from 'node-forge';
import { LoginAction } from 'ducks/Login';
import * as wijmo from '@grapecity/wijmo';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass, DateTime } from "@grapecity/wijmo";
import * as wjChart from '@grapecity/wijmo.react.chart';
import { dataMapper } from 'lib';

export const InfoComponent = (props) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const loginAction = bindActionCreators(LoginAction, dispatch);
  
  const [sumGrid, setSumGrid] = React.useState(null)
  const [rcvGrid, setRcvGrid] = React.useState(null)

  React.useEffect(() => {
    MainApi.info()
    .then(res => {
      // console.log(res)
      let infoList = []
      _.forEach(res.payload && res.payload.list, (r, i) => {
        // const yyyy = (r.yyyymm.length === 6)?_.toNumber(r.yyyymm.substring(0, 4)): null
        // const mm = (r.yyyymm.length === 6)?_.toNumber(r.yyyymm.substring(4))-1: null
        infoList.push({
          ...r,
          // yyyymm: wijmo.DateTime.newDate(yyyy, mm, 1),
          // voidFreeCount: _.floor(r.issueCount * 0.05),
        })
      })
      appAction.setInputApp({infoList: infoList})
    }).catch(err => {
    }).finally(() => {
    })
  }, [])

  return (
    <React.Fragment>
      <Container fluid>
        <Title title={TITLE.info.name} icon={TITLE.info.icon} />
        <Card
          root='max-button'
          size='lg'
          header='URI-Proに関するお知らせ'
          hideClose
          body={
            <div className='info-main-list'>
              {app.infoList && app.infoList.map( (r, i) => {
                const kind1 = dataMapper(INFO_BADGE1, 'value', 'name', r.infoKind)
                const kind1c = dataMapper(INFO_BADGE1, 'value', 'cName', r.infoKind)
                const kind2 = dataMapper(INFO_BADGE2, 'value', 'name', r.infoKind2)
                const kind2c = dataMapper(INFO_BADGE2, 'value', 'cName', r.infoKind2)
                const isNew = Math.floor((new Date() - new Date(r.startDate)) / (1000 * 60 * 60 * 24)) < 7
                const kind3 = dataMapper(INFO_BADGE3, 'value', 'name', isNew)
                return(
                <React.Fragment key={i} >
                  <div key={'badge' + i}>
                    <label sm="3" className='label-color m-2'>{r.infoDate.replaceAll('-','.')}</label>
                    <Badge pill className={`badge-color ${kind1c}`}>
                      {kind1}
                    </Badge>{' '}
                    <Badge pill className={`badge-color ${kind2c} ${kind2&&kind2!='　'?'':'d-none'}`}>
                      {kind2}
                    </Badge>{' '}
                    <Badge pill className={`badge-color text-danger ${kind3&&kind3!='　'?'':'d-none'}`}>
                      {kind3}
                    </Badge>{' '}
                  </div>
                  {
                    r.infoUrl &&
                    <div key={'title' + i} className='info-title-box' onClick={(e)=>{window.open(r.infoUrl, `uri_info_${r.id}`)}}>
                      <div className='info-title all'>
                        {r.infoSubject}
                      </div>
                      <div className={`material-icons info-caret`}>
                        arrow_right
                      </div>
                    </div>
                  }
                  {
                    !r.infoUrl &&
                    <div key={'title' + i} className='info-title-box no-url'>
                      <div className='info-title all no-url'>
                        {r.infoSubject}
                      </div>
                    </div>
                  }
                </React.Fragment>
              )})}
            </div>
          }
        />
      </Container>
      <Common.RouteLeavingGuard
        when={true}
        shouldBlockNavigation={()=>false}
      />
    </React.Fragment>
  )
}

