import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';

export const CustPane = (props) => {

  const tktEdit = useSelector(state => state.TktEdit);
  
  const dispatch = useDispatch();
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  return (
    <React.Fragment>
      <div className='d-flex'>
        <Common.Number
          attrs={{
            ...tktEdit.tktTokuiClm,
            labelclass: 'width-max-content'
          }}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-10 mr-2',
            textwidth: 'width-p-100'
          }}
        />
      </div>
      <div className='d-flex'>
        <Common.Number
          attrs={{...tktEdit.tktTkiTokuiRate}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-7 mr-5',
            textwidth: 'min-width-5-5 width-5-5'
          }}
        />
        <div className='width-1'/>
        <Common.Number
          attrs={{...tktEdit.tktTkiKg}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-10 ml-3 mr-4',
            textwidth: 'width-p-100'
          }}
        />
        <Common.Number
          attrs={{...tktEdit.tktTkiShz}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-10 mr-2',
            textwidth: 'width-p-100'
          }}
        />
      </div>
      <div className='d-flex'>
        <Common.Number
          attrs={{
            ...tktEdit.tktTkiSeikyuKg,
            labelclass: 'width-max-content'
          }}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-10 mr-4',
            textwidth: 'width-p-100'
          }}
        />
        <Common.Number
          attrs={{...tktEdit.tktTkiJstsr}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-10 ml-3 mr-0',
            textwidth: 'width-p-100'
          }}
        />
      </div>
    </React.Fragment>
  )
}