import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert, alert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';

export const ReissuePane = (props) => {

  const login = useSelector(state => state.Login);
  const tktEdit = useSelector(state => state.TktEdit);
  
  const dispatch = useDispatch();
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  const open = (tktno) => {
    if (tktno.length < 13) {
      alert().fire({
        icon: MESSAGE.tktNoFind().icon,
        title: MESSAGE.tktNoFind().text,
        showCancelButton: false,
      })
    }
    const al = tktno.substr(0, 3)
    const sn = tktno.substr(3, 10)
    const mode = '3'
    TicketApi.detailSearch({
      formMode: mode,
      airlineCode: al,
      sn: sn,
    }).then(res => {
      if (res.payload.code === '000') {
        if (!res.payload.ticketDetailSearchResultListDto || res.payload.ticketDetailSearchResultListDto.length < 1) {
          alert().fire({
            icon: MESSAGE.tktNoFind().icon,
            title: MESSAGE.tktNoFind().text,
            showCancelButton: false,
          })
        } else {
          const tktItem = res.payload.ticketDetailSearchResultListDto[0].tktDataListDto
          // console.log(tktItem)
          const alno = tktItem.tktParentTktno?tktItem.tktParentTktno.substr(0, 3):tktItem.tktTktnoAl
          const snno = tktItem.tktParentTktno?tktItem.tktParentTktno.substr(3):tktItem.tktTktnoSn
          let tktNewList = localStorage.getItem('tktNewList')?JSON.parse(localStorage.getItem('tktNewList')):[{name:'', value:'', mode: ''}]
          tktNewList = _.filter(tktNewList, item => item.value !== alno + snno)
          tktNewList.push({
            name: alno + '-' + snno,
            value: alno + snno,
            mode: mode,
          })
          // console.log(tktNewList)
          localStorage.setItem('tktNewList', JSON.stringify(tktNewList))
          window.open(`${TITLE.ticketEdit.mainLink}/${alno + snno}`, '_tktEdit_' + alno + snno)
        }
      } else if (res.payload.code !== '300') {
        alert().fire({
          icon: MESSAGE.tktNoFind().icon,
          title: MESSAGE.tktNoFind().text,
          showCancelButton: false,
        })
      }
    }).catch(err => {
    }).finally(() => {})
  }

  return (
    <React.Fragment>
      <div className='d-flex flex-column'>
        <div
          className={`tooltip-text ${tktEdit.tktOrgIsu.visible?'visible':'invisible'}`}
          title='REISSU元のTKTの詳細画面を表示します。'
          onClick={() => {open(tktEdit.tktOrgIsu.text)}}
        >ORIGINAL ISSUE</div>
        <Common.Text
          attrs={{...tktEdit.tktOrgIsu}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-47 mr-0',
          }}
        />
      </div>
      <div className='d-flex flex-column'>
        <div
          className={`tooltip-text ${tktEdit.tktIsuEx.visible?'visible':'invisible'}`}
          title='REISSU元のTKTの詳細画面を表示します。'
          onClick={() => {open(tktEdit.tktIsuEx.text)}}
        >ISSUED IN EXCHANGE</div>
        <Common.Text
          attrs={{...tktEdit.tktIsuEx}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-47 mr-0',
          }}
        />
      </div>
    </React.Fragment>
  )
}