import React from 'react';
import { Calendar } from '@grapecity/wijmo.react.input';
import reactDom from 'react-dom';
import { 
  ButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import _ from 'lodash';

export const RadioButton = React.forwardRef((props, ref) => {

  const {attrs} = props;

  const genRender = () => {
    const render = [];
    _.forEach(props.radios, (radio, idx) => {
      render.push(
        <ToggleButton
          key={idx}
          id={`${attrs.id}-${idx}`}
          className={`radiobtn-lightblue ${props.btnSize} ${attrs.className}`}
          type="radio"
          name={attrs.id}
          value={radio.value}
          checked={attrs.text === radio.value}
          active={attrs.text === radio.value}
          disabled={attrs.disabled}
          onChange={(e) => {
            const target = {id: attrs.id}
            target[attrs.id] = {
              ...attrs,
              text: e.target.value,
            }
            props.onChange(target)
          }}
        >
          {radio.name}
        </ToggleButton>
      )
    })
    return render;
  }

  return (
    <React.Fragment>
      <ButtonGroup
        className={`${props.className} ${attrs.visible===false?'d-none':''}`}
      >
        {genRender()}
      </ButtonGroup>
    </React.Fragment>
  )
})
