import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { ReportAction } from 'ducks/Report';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Badge,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert, getCondition, dataMapper, createDispSearch } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { SEARCH, COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { PRINT_MESSAGE, ACCOUNT_CODE_TYPE, OUTPUT_TYPE, OUTPUT_LOGO, OUTPUT_REPORT, OUTPUT_FLAG } from 'constants/report';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { ReportApi, TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';
import { TktEditAction } from 'ducks/TktEdit';

export const ReportComponent = (props) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const report = useSelector(state => state.Report);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const reportAction = bindActionCreators(ReportAction, dispatch);
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  const [procPopup, setProcPopup] = React.useState(null)
  const [grid, setGrid] = React.useState(null)
  const [thisNo, setThisNo] = React.useState('')
  const [selectedTkt, setSelectedTkt] = React.useState('')

  React.useEffect(() => {
    if (thisNo) {
      const al = thisNo.substring(0, 3)
      const sn = thisNo.substring(3)
      const searchJson ={
        tktTktNo: {
          formList: [sn],
          radioType: "1",
          dataTypeRadioType: "1"
        },
        tktTktNoAl: {
          formList: [al],
          radioType: "0",
          clusterRadioType: "0"
        },
      }
      const dto = {
        processId: 'B0201',
        searchOption: '0',
        searchString: '[\"tktTktNoAl\",\"tktTktNo\"]',
        ...searchJson,
        viewOption: "0",
      }
      // 検索処理実行
      TicketApi.findSearch(dto)
      .then(res => {
        if (res.payload.code === '000') {
          reportAction.setInputReport({
            searchJson: searchJson,
            searchSql: res.payload.searchSql,
          })
        }
      })
      .catch(() => {})
      .finally(() => {})
    }
  }, [thisNo])

  React.useEffect(() => {

    if (location.state && location.state.caller && location.state.caller ==='search') {
      if (report.tktDetailItemDto) {
        appAction.showProcessing({isProcessing: false})
        return
      }
    }

    if (props.isModal && !props.isOpen) return
    appAction.showProcessing({isProcessing: true})
    appAction.setMessage('')

    if (location.state && location.state.al) {
      setThisNo(location.state.al + location.state.sn)
    }

    ReportApi.init({
      // execType: '0',
      isTimerSetting: props.isModal,
      isTimerAccess: props.isModal,
    })
    .then(res => {
      appAction.setBatchResult({processingSeq: ''})
      const obj = {}

      _.forEach(report, (o, id) => {
        _.forEach(res.payload.fixedFormIniDto, (v, k) => {
          if (id === k) {
            obj[id] = {
              ...report[id],
              isInvalid: false,
              helpertext: '',
              original: v,
              value: v,
              text: v,
            }
          }
        })
      })

      obj.tktDetailItemDto = res.payload.tktDetailItemDto

      obj.ticketDetailSummaryFileNamePullDown = []
      _.forEach(res.payload.ticketDetailSummaryFileNamePullDown, (v, k) => {
        obj.ticketDetailSummaryFileNamePullDown.push({name: v, value: k})
      })
      obj.ticketDetailFileNamePullDown = []
      _.forEach(res.payload.ticketDetailFileNamePullDown, (v, k) => {
        obj.ticketDetailFileNamePullDown.push({name: v, value: k})
      })
      obj.customerPrintFileNamePullDown = []
      _.forEach(res.payload.customerPrintFileNamePullDown, (v, k) => {
        obj.customerPrintFileNamePullDown.push({name: v, value: k})
      })
      obj.agentCouponFileNamelPullDown = []
      _.forEach(res.payload.agentCouponFileNamelPullDown, (v, k) => {
        obj.agentCouponFileNamelPullDown.push({name: v, value: k})
      })
      obj.cccfFileNamePullDown = []
      _.forEach(res.payload.cccfFileNamePullDown, (v, k) => {
        obj.cccfFileNamePullDown.push({name: v, value: k})
      })
      obj.refundNoticeFileNamePullDown = []
      _.forEach(res.payload.refundNoticeFileNamePullDown, (v, k) => {
        obj.refundNoticeFileNamePullDown.push({name: v, value: k})
      })

      obj.searchJson = {}
      if (res.payload.outSearchDto && res.payload.outSearchDto.tktOutsrcSearch) {
        const searchJson = JSON.parse(res.payload.outSearchDto.tktOutsrcSearch)
        // AND OR
        obj.condition = searchJson.condition
        // 
        obj.searchJson = searchJson.conditionJson
      }
      if (location.state && location.state.caller && report.searchSql) {
        obj.searchSql = report.searchSql
        obj.searchJson = report.searchJson
        obj.condition = report.condition
      } else {
        obj.searchSql = res.payload.outSearchDto && res.payload.outSearchDto.tktOutsrcSql
      }
      reportAction.setInputReport(obj)
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }, [props.isOpen])

  const execDownload = (action) => {

    if (!report.searchSql) {
      appAction.setMessage({message: [{id: MESSAGE.noSearch().id, message: MESSAGE.noSearch().text, type: MESSAGE.noSearch().icon}]})
      return
    }
    const outputFlag = ['ticketdetailFlag', 'customerprintOutputFlag', 'agentcouponOutputFlag', 'cccfOutputFlag', 'refundnoticeOutputFlag']
    let isOutput = false
    let isDetailOutput = true
    _.forEach(outputFlag, (r, i) => {
      if (report[r].text === '1') {
        if (i === 0) {
          if (report.ticketdetailOutputFlag.text === '1' || report.ticketdetailSummaryOutputFlag.text === '1') {
            isOutput = true
          } else {
            isDetailOutput = false
          }
        } else if (i === 3) {
          if (report.cccfAgentcopyOutputFlag.text === '1' || report.cccfCustomerprintOutputFlag.text === '1') {
            isOutput = true
          }
        } else {
          isOutput = true
        }
      }
    })
    if (!isDetailOutput) {
      appAction.setMessage({message: [{id: MESSAGE.detailOutputNoSelect().id, message: MESSAGE.detailOutputNoSelect().text, type: MESSAGE.detailOutputNoSelect().icon}]})
      return
    }
    if (!isOutput) {
      appAction.setMessage({message: [{id: MESSAGE.outputNoSelect().id, message: MESSAGE.outputNoSelect().text, type: MESSAGE.outputNoSelect().icon}]})
      return
    }

    let tktDetailItemDto = {}
    _.forEach(report.tktDetailItemDto, (v, k) => {
      _.forEach(report.dispCheckedItems, (r, i) => {
        // prefixが違う
        if (k.startsWith('dtl')) tktDetailItemDto[k] = (k === r.id)?'1':'0'
      })
    })
    const tktOutsrcSearch = {
      condition: report.condition,
      // conditionJson: getCondition(report)
      conditionJson: report.searchJson
    }
    const dto = {
      execType: '0',
      isTimerSetting: false,
      outSearchDto: {
        tktOutsrcSql: report.searchSql,
        tktOutsrcSearch: JSON.stringify(tktOutsrcSearch),
      },
      tktDetailItemDto: tktDetailItemDto,
      fixedFormIniDto: {
        // tktOutFormat: report.tktOutFormat.text,
        // tktOutFileName: report.tktOutFileName.text,
        // tktOutPccdiv: report.tktOutPccdiv.text,
        // tktOutPex: report.tktOutPex.text,
        // tktOutGrossNetSame: report.tktOutGrossNetSame.text,
        // // tktOutsrcIniMbrcd: login.loginCd,
        // cmnMbrcd: login.loginCd,
    }}
    _.forEach(report, (v, k) => {
      if (k.endsWith('Flag')) dto.fixedFormIniDto[k] = v.text
      if (k.endsWith('Filename')) dto.fixedFormIniDto[k] = v.text
      if (k.endsWith('Type')) dto.fixedFormIniDto[k] = v.text
      if (k.endsWith('Title')) dto.fixedFormIniDto[k] = v.text
      if (k.endsWith('Remarks')) dto.fixedFormIniDto[k] = v.text
      if (k.endsWith('Logo')) dto.fixedFormIniDto[k] = v.text
    })

    ReportApi[action](dto).then((res) => {
      if (res.payload.code === '000') {
        appAction.setBatchResult({processingSeq: res.payload.processingSeq})
      
        // setIfnFiles(null)
        procPopup.show(true, (sender) => {
          // if (document.getElementById(['uploadFile'])) document.getElementById(['uploadFile']).value = '';
          if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
            // console.log('ok')
            // setFail(0)
            // setSkip(0)
          } else {
            // setFileInvalid(false)
            // setFail(0)
            // setSkip(0)
            // console.log('cancel')
          }
        })
      } else {
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  const execSetting = () => {
    const outputFlag = ['ticketdetailFlag', 'customerprintOutputFlag', 'agentcouponOutputFlag', 'cccfOutputFlag', 'refundnoticeOutputFlag']
    let isOutput = false
    let isDetailOutput = true
    _.forEach(outputFlag, (r, i) => {
      if (report[r].text === '1') {
        if (i === 0) {
          if (report.ticketdetailOutputFlag.text === '1' || report.ticketdetailSummaryOutputFlag.text === '1') {
            isOutput = true
          } else {
            isDetailOutput = false
          }
        } else if (i === 3) {
          if (report.cccfAgentcopyOutputFlag.text === '1' || report.cccfCustomerprintOutputFlag.text === '1') {
            isOutput = true
          }
        } else {
          isOutput = true
        }
      }
    })
    if (!isDetailOutput) {
      appAction.setMessage({message: [{id: MESSAGE.detailOutputNoSelect().id, message: MESSAGE.detailOutputNoSelect().text, type: MESSAGE.detailOutputNoSelect().icon}]})
      return
    }
    if (!isOutput) {
      appAction.setMessage({message: [{id: MESSAGE.outputNoSelect().id, message: MESSAGE.outputNoSelect().text, type: MESSAGE.outputNoSelect().icon}]})
      return
    }

    let entry = {}
    let hasError = false
    entry.localOutputFilename = entryCheck(report, convert.getTarget(report.localOutputFilename, 'text', report.localOutputFilename.text))[`localOutputFilename`]
    hasError = hasError || entry.localOutputFilename.isInvalid
    entry.s3OutputFilename = entryCheck(report, convert.getTarget(report.s3OutputFilename, 'text', report.s3OutputFilename.text))[`s3OutputFilename`]
    hasError = hasError || entry.s3OutputFilename.isInvalid
    reportAction.setInputReport(entry)
    if (hasError) return

    let tktDetailItemDto = {}
    _.forEach(report.tktDetailItemDto, (v, k) => {
      _.forEach(report.dispCheckedItems, (r, i) => {
        // prefixが違う
        if (k.startsWith('dtl')) tktDetailItemDto[k] = (k === r.id)?'1':'0'
      })
    })
    const dto = {
      // execType: '0',
      isTimerSetting: true,
      // outSearchDto: {
      //   tktOutsrcSql: report.searchSql,
      // },
      tktDetailItemDto: tktDetailItemDto,
      fixedFormIniDto: {
        outputFlag: report.outputFlag.text,
        printerName: report.printerName.text,
        localOutputFlag: report.localOutputFlag.text,
        localOutputFilename: report.localOutputFilename.text,
        s3OutputFlag: report.s3OutputFlag.text,
        s3OutputFilename: report.s3OutputFilename.text,
        cmnMbrcd: login.loginCd,
    }}
    _.forEach(report, (v, k) => {
      if (k.endsWith('Flag')) dto.fixedFormIniDto[k] = v.text
      if (k.endsWith('Filename')) dto.fixedFormIniDto[k] = v.text
      if (k.endsWith('Type')) dto.fixedFormIniDto[k] = v.text
      if (k.endsWith('Title')) dto.fixedFormIniDto[k] = v.text
      if (k.endsWith('Remarks')) dto.fixedFormIniDto[k] = v.text
      if (k.endsWith('Logo')) dto.fixedFormIniDto[k] = v.text
    })

    ReportApi.edit(dto).then((res) => {
      if (res.payload.code === '000') {
        appAction.setMessage({message: res.payload.messageList})
        props.onSuccess()
      } else {
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  // チケット明細
  React.useEffect(() => {
    if (!app.isProcessing) {
      reportAction.setInputReport({
        ticketdetailSummaryOutputFlag: {
          ...report.ticketdetailSummaryOutputFlag,
          text: report.ticketdetailFlag.text === '0'?'0':report.ticketdetailSummaryOutputFlag.text,
          disabled: report.ticketdetailFlag.text === '0'
        },
        ticketdetailOutputFlag: {
          ...report.ticketdetailOutputFlag,
          text: report.ticketdetailFlag.text === '0'?'0':report.ticketdetailOutputFlag.text,
          disabled: report.ticketdetailFlag.text === '0'
        }
      })
    }
  }, [app.isProcessing, report.ticketdetailFlag.text])
  // チケット明細サマリ出力
  React.useEffect(() => {
    reportAction.setInputReport({
      ticketdetailSummaryFilename: {
        ...report.ticketdetailSummaryFilename,
        disabled: report.ticketdetailSummaryOutputFlag.text === '0'
      },
      ticketdetailSummaryAccountcodeType: {
        ...report.ticketdetailSummaryAccountcodeType,
        disabled: report.ticketdetailSummaryOutputFlag.text === '0'
      }
    })
  }, [report.ticketdetailSummaryOutputFlag.text])
  // チケット明細出力
  React.useEffect(() => {
    reportAction.setInputReport({
      ticketdetailOutputFilename: {
        ...report.ticketdetailOutputFilename,
        disabled: report.ticketdetailOutputFlag.text === '0'
      },
      ticketdetailOutputType: {
        ...report.ticketdetailOutputType,
        disabled: report.ticketdetailOutputFlag.text === '0'
      },
      ticketdetailOutputRemarks: {
        ...report.ticketdetailOutputRemarks,
        disabled: report.ticketdetailOutputFlag.text === '0'
      },
    })
  }, [report.ticketdetailOutputFlag.text])
  // お客様印刷
  React.useEffect(() => {
    reportAction.setInputReport({
      customerprintOutputTitle: {
        ...report.customerprintOutputTitle,
        disabled: report.customerprintOutputFlag.text === '0'
      },
      customerprintOutputRemarks: {
        ...report.customerprintOutputRemarks,
        disabled: report.customerprintOutputFlag.text === '0'
      },
      customerprintOutputLogo: {
        ...report.customerprintOutputLogo,
        disabled: report.customerprintOutputFlag.text === '0'
      },
      customerprintOutputFilename: {
        ...report.customerprintOutputFilename,
        disabled: report.customerprintOutputFlag.text === '0'
      },
    })
  }, [report.customerprintOutputFlag.text])
  // AGENT COUPON
  React.useEffect(() => {
    reportAction.setInputReport({
      agentcouponOutputFilename: {
        ...report.agentcouponOutputFilename,
        disabled: report.agentcouponOutputFlag.text === '0'
      },
      agentcouponOutputType: {
        ...report.agentcouponOutputType,
        disabled: report.agentcouponOutputFlag.text === '0'
      },
    })
  }, [report.agentcouponOutputFlag.text])
  // CCCF
  React.useEffect(() => {
    reportAction.setInputReport({
      cccfOutputFilename: {
        ...report.cccfOutputFilename,
        disabled: report.cccfOutputFlag.text === '0'
      },
      cccfOutputType: {
        ...report.cccfOutputType,
        disabled: report.cccfOutputFlag.text === '0'
      },
      cccfAgentcopyOutputFlag: {
        ...report.cccfAgentcopyOutputFlag,
        disabled: report.cccfOutputFlag.text === '0'
      },
      cccfCustomerprintOutputFlag: {
        ...report.cccfCustomerprintOutputFlag,
        disabled: report.cccfOutputFlag.text === '0'
      },
      cccfOutputLogo: {
        ...report.cccfOutputLogo,
        disabled: report.cccfOutputFlag.text === '0'
      },
    })
  }, [report.cccfOutputFlag.text])
  // REFUND NOTICE
  React.useEffect(() => {
    reportAction.setInputReport({
      refundnoticeOutputFilename: {
        ...report.refundnoticeOutputFilename,
        disabled: report.refundnoticeOutputFlag.text === '0'
      },
    })
  }, [report.refundnoticeOutputFlag.text])

  // 
  React.useEffect(() => {
    reportAction.setInputReport({
      localOutputFlag: {
        ...report.localOutputFlag,
        text: report.outputFlag.text !== '2'?'0':report.localOutputFlag.text
      },
      s3OutputFlag: {
        ...report.s3OutputFlag,
        text: report.outputFlag.text !== '2'?'0':report.s3OutputFlag.text
      },
    })
  }, [report.outputFlag.text])

  React.useEffect(() => {
    if (report.cccfOutputType.text === '1') {
      reportAction.setInputReport({
        cccfOutputLogo: {
          ...report.cccfOutputLogo,
          disabled: true,
          text: report.cccfOutputLogo.text = '0'
        },
      })
    } else {
      reportAction.setInputReport({
        cccfOutputLogo: {
          ...report.cccfOutputLogo,
          disabled: false,
        },
      })
    }
  }, [report.cccfOutputType.text])

  return (
    <React.Fragment>
      <Container fluid className='main'>
        <Title
          title={TITLE.report.name}
          icon={TITLE.report.icon}
          button={
            <Button
              {...report.search}
              className={`button-blue ml-auto ${props.isModal?'d-none':(location.state && location.state.caller==='tktEdit')?'d-none':''}`}
              onClick={(e) => history.push(TITLE.reportSearch.link, {func: 'report'})}
            >
              {report.search.label}
            </Button>
          }
        />
        <div className={`tktListSellector ${(location.state && location.state.caller==='tktEdit')?'':'d-none'}`}>
          <Common.Select
            attrs={{
              id: '_tktList',
              label: '_none',
              text: thisNo,
              dirty: '',
              disabled: window.name !== '_tktEditList'
            }}
            group={{
              className: 'width-16 mr-0',
            }}
            // className='abcd'
            minLength={1}
            isEditable={false}
            isRequired={true}
            list={app.tktList}
            onChange={(e) => {
              const tktTktno = e._tktList.text
              const selectedTktItem = _.find(app.tktList, r => r.value === tktTktno)
              if (selectedTktItem && selectedTktItem.value) {
                setThisNo(tktTktno)
                setSelectedTkt(selectedTktItem)
              }
            }}
          />
        </div>
        <div>
          <ProcessingPopup 
            popup={procPopup}
            setPopup={setProcPopup}
            header={PRINT_MESSAGE.header}
            processingTitle={PRINT_MESSAGE.processingTitle}
            processingMessage1={PRINT_MESSAGE.processingMessage1}
            resultTitle={PRINT_MESSAGE.resultTitle}
            resultMessage1={PRINT_MESSAGE.resultMessage1}
          />
        </div>
        {!props.isModal && 
          <Card
            root='condition'
            size='md'
            hideClose
            header='データ検索条件'
            subheader={null}
            bodyClass='height-5'
            body={
              <React.Fragment>
                {report.condition && report.condition.text &&
                  <Badge className='badge-color search mr-2'>{`(${dataMapper(RADIOS.condition, 'value', 'name', report.condition.text)}) 検索条件 : `}</Badge>
                }
                {createDispSearch(report.searchJson).map((r, i) =>
                  <Badge key={i} className='badge-color search mr-2'>{r}</Badge>
                )}
              </React.Fragment>
            }
          />
        }
        <Card
          root={props.isModal?'max-button2':window.name.startsWith('_tktEdit')?'max-button-report':'max-button3'}
          size='lg'
          hideClose
          header={null}
          subheader={null}
          body={
            <React.Fragment>
              {props.isModal && (login.auth072==='1' || login.authFtp==='1') &&
                <Card
                  root='max'
                  size='md'
                  hideClose
                  header={'出力設定'}
                  subheader={null}
                  body={
                    <React.Fragment>
                      {login.auth072==='1' &&
                      <div className='ml-2 mb-4' id='report-output'>
                        <div className='width-p-100'>
                          <Form.Check
                            label={OUTPUT_FLAG[0].name}
                            type='radio'
                            id={`report.outputFlag.id_0`}
                            name={report.outputFlag.id}
                            checked={report.outputFlag.text === OUTPUT_FLAG[0].value}
                            disabled={report.outputFlag.disabled}
                            onChange={(e) => {
                              const target = {id: report.outputFlag.id}
                              target[report.outputFlag.id] = {
                                ...report.outputFlag,
                                text: OUTPUT_FLAG[0].value,
                              }
                              reportAction.changeInputReport(target)
                            }}
                          />
                        </div>
                        <label sm="3" className='label-color mb-0 mr-3 text-right width-11'>
                          {report.label[5]}
                        </label>
                        <Common.Text
                          attrs={{...report.printerName}}
                          onBlur={(e) => reportAction.changeInputReport(e)}
                          group={{
                            className: 'width-50 mr-0 mb-0',
                          }}
                        />
                      </div>
                      }
                      <div className='ml-2 mb-3' id='report-output'>
                        <div className='width-p-100'>
                          <Form.Check
                            label={OUTPUT_FLAG[1].name}
                            type='radio'
                            id={`report.outputFlag.id_1`}
                            name={report.outputFlag.id}
                            checked={report.outputFlag.text === OUTPUT_FLAG[1].value}
                            disabled={report.outputFlag.disabled}
                            onChange={(e) => {
                              const target = {id: report.outputFlag.id}
                              target[report.outputFlag.id] = {
                                ...report.outputFlag,
                                text: OUTPUT_FLAG[1].value,
                              }
                              reportAction.changeInputReport(target)
                            }}
                          />
                        </div>
                        <div className='d-block'>
                          {login.auth072==='1' &&
                          <div>
                            <div className='d-block'>
                              <label sm="3" className='label-color mb-0 mr-3 text-right width-14'>
                                {report.label[6]}
                              </label>
                              <div className='d-inline-block width-16'>
                                <Common.Check
                                  attrs={{
                                    ...report.localOutputFlag,
                                    disabled: report.outputFlag.text !== '2'
                                  }}
                                  onChange={(e) => reportAction.changeInputReport(e)}
                                />
                              </div>
                              <div className='d-block'>
                                <label sm="3" className='label-color mb-0 mr-3 text-right width-5-28'>
                                  {report.label[7]}
                                </label>
                                <Common.Text
                                  attrs={{
                                    ...report.localOutputFilename,
                                    disabled: report.outputFlag.text !== '2'
                                  }}
                                  onBlur={(e) => reportAction.changeInputReport(e)}
                                  group={{
                                    className: 'width-50 mr-0 mb-2',
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          }
                        </div>
                        {login.authFtp==='1' &&
                        <div>
                          <div className='d-block'>
                            <label sm="3" className='label-color mb-0 mr-3 text-right width-14'>
                              {report.label[6]}
                            </label>
                            <div className='d-inline-block width-16'>
                              <Common.Check
                                attrs={{
                                  ...report.s3OutputFlag,
                                  disabled: report.outputFlag.text !== '2'
                                }}
                                onChange={(e) => reportAction.changeInputReport(e)}
                              />
                            </div>
                            <div className='d-block'>
                              <label sm="3" className='label-color mb-0 mr-3 text-right width-5-28'>
                                {report.label[7]}
                              </label>
                              <Common.Text
                                attrs={{
                                  ...report.s3OutputFilename,
                                  disabled: report.outputFlag.text !== '2'
                                }}
                                onBlur={(e) => reportAction.changeInputReport(e)}
                                group={{
                                  className: 'width-50 mr-0 mb-0',
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        }
                      </div>
                    </React.Fragment>
                  }
                />
              }
              <Card
                root='max'
                size='md'
                hideClose
                bodyClass={'d-flex'}
                header={
                  <div>
                    <Common.Check
                      attrs={{...report.ticketdetailFlag}}
                      onChange={(e) => reportAction.changeInputReport(e)}
                    />
                  </div>
                }
                subheader={null}
                body={
                  <React.Fragment>
                    <div className = 'width-p-40 m-0 d-inline-block'>
                      <div className='ml-3 mb-3'>
                        <Common.Check
                          attrs={{...report.ticketdetailSummaryOutputFlag}}
                          onChange={(e) => reportAction.changeInputReport(e)}
                        />
                      </div>
                      <div className='ml-0 mb-3'>
                        <label sm="3" className='label-color mb-0 mr-3 text-right width-19'>
                          {report.label[0]}
                        </label>
                        <Common.Select
                          attrs={{...report.ticketdetailSummaryAccountcodeType}}
                          group={{
                            className: 'width-27',
                            textwidth: 'width-27 mr-0',
                          }}
                          minLength={1}
                          list={ACCOUNT_CODE_TYPE}
                          onChange={(e) => reportAction.changeInputReport(e)}
                        />
                      </div>
                      <div className='ml-0 mb-3'>
                        <label sm="3" className='label-color mb-0 mr-3 text-right width-19'>
                          {report.label[1]}
                        </label>
                        <Common.Select
                          attrs={{...report.ticketdetailSummaryFilename}}
                          group={{
                            className: 'width-27',
                            textwidth: 'width-27 mr-0',
                          }}
                          minLength={1}
                          list={report.ticketDetailSummaryFileNamePullDown}
                          onChange={(e) => reportAction.changeInputReport(e)}
                        />
                      </div>
                    </div>
                    
                    <div className = 'width-p-60 m-0 d-inline-block'>
                      <div className = 'm-0 d-inline-flex'>
                        <div className = 'm-0 mr-2 d-inline-block'>
                          <div className='ml-5 mb-3'>
                            <Common.Check
                              attrs={{...report.ticketdetailOutputFlag}}
                              onChange={(e) => reportAction.changeInputReport(e)}
                            />
                          </div>
                          <div className='ml-5 mb-3'>
                            <Common.Radio
                              btnSize='sm'
                              attrs={{...report.ticketdetailOutputType}}
                              // className='mr-5'
                              group={{
                                className: 'width-30 mr-0',
                              }}
                              radios={OUTPUT_TYPE}
                              onChange={(e) => reportAction.changeInputReport(e)}
                            />
                          </div>
                        </div>
                        <div className = 'm-0'>
                          <Common.Text
                            attrs={{...report.ticketdetailOutputRemarks, as: 'textarea'}}
                            onBlur={(e) => reportAction.changeInputReport(e)}
                            group={{
                              className: 'width-40 mr-0 mb-3',
                            }}
                            control={{
                              rows: 2
                            }}
                          />
                        </div>
                      </div>
                      <div className='ml-5 mb-3'>
                        <label sm="3" className='label-color mb-0 mr-3 text-left width-13'>
                          {report.label[1]}
                        </label>
                        <Common.Select
                          attrs={{...report.ticketdetailOutputFilename}}
                          group={{
                            className: 'width-32',
                            textwidth: 'width-32 mr-0',
                          }}
                          minLength={1}
                          list={report.ticketDetailFileNamePullDown}
                          onChange={(e) => reportAction.changeInputReport(e)}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                }
              />
              <Card
                root='max'
                size='md'
                hideClose
                bodyClass={'d-flex'}
                header={
                  <div>
                    <Common.Check
                      attrs={{...report.customerprintOutputFlag}}
                      onChange={(e) => reportAction.changeInputReport(e)}
                    />
                  </div>
                }
                subheader={null}
                body={
                  <React.Fragment>
                    <div className = 'width-p-50 m-0 d-inline-block'>
                      <div className='ml-5 mb-3'>
                        <Common.Text
                          attrs={{...report.customerprintOutputTitle}}
                          onBlur={(e) => reportAction.changeInputReport(e)}
                          group={{
                            className: 'width-p-100 mr-0 mb-0',
                          }}
                        />
                      </div>
                      <div className='ml-5 mb-3'>
                        <label sm="3" className='label-color mb-0 mr-3 text-right width-13'>
                          {report.label[2]}
                        </label>
                        <Common.Radio
                          btnSize='sm'
                          attrs={{...report.customerprintOutputLogo}}
                          // className='mr-5'
                          group={{
                            className: 'width-30 mr-0',
                          }}
                          radios={OUTPUT_LOGO}
                          onChange={(e) => reportAction.changeInputReport(e)}
                        />
                      </div>
                      <div className='ml-5 mb-3'>
                        <label sm="3" className='label-color mb-0 mr-3 text-right width-13'>
                          {report.label[1]}
                        </label>
                        <Common.Select
                          attrs={{...report.customerprintOutputFilename}}
                          group={{
                            className: 'width-37 mr-2',
                            textwidth: 'width-37',
                          }}
                          minLength={1}
                          list={report.customerPrintFileNamePullDown}
                          onChange={(e) => reportAction.changeInputReport(e)}
                        />
                      </div>
                    </div>
                    
                    <div className = 'width-p-50 m-0 d-inline-block'>
                      <div className='ml-5 mb-3'>
                        <Common.Text
                          attrs={{...report.customerprintOutputRemarks, as: 'textarea'}}
                          onBlur={(e) => reportAction.changeInputReport(e)}
                          group={{
                            className: 'width-p-100 mr-0 mb-3',
                          }}
                          control={{
                            rows: 2
                          }}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                }
              />
              <Card
                root='max'
                size='md'
                hideClose
                bodyClass={'d-flex'}
                header={
                  <div>
                    <Common.Check
                      attrs={{...report.agentcouponOutputFlag}}
                      onChange={(e) => reportAction.changeInputReport(e)}
                    />
                  </div>
                }
                subheader={<span>※ Amadeus発券分のみ</span>}
                body={
                  <React.Fragment>
                    <div className = 'width-p-50 m-0 d-inline-block'>
                      <div className='ml-5 mb-3'>
                        <label sm="3" className='label-color mb-0 mr-3 text-right width-13'>
                          {report.label[1]}
                        </label>
                        <Common.Select
                          attrs={{...report.agentcouponOutputFilename}}
                          group={{
                            className: 'width-37 mr-2',
                            textwidth: 'width-37',
                          }}
                          minLength={1}
                          list={report.agentCouponFileNamelPullDown}
                          onChange={(e) => reportAction.changeInputReport(e)}
                        />
                      </div>
                      <div className='ml-5 mb-3'>
                        <label sm="3" className='label-color mb-0 mr-3 text-right width-13'>
                          {report.label[3]}
                        </label>
                        <Common.Radio
                          btnSize='sm'
                          attrs={{...report.agentcouponOutputType}}
                          // className='mr-5'
                          group={{
                            className: 'width-30 mr-0',
                          }}
                          radios={OUTPUT_REPORT}
                          onChange={(e) => reportAction.changeInputReport(e)}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                }
              />
              <Card
                root='max'
                size='md'
                hideClose
                header={
                  <div>
                    <Common.Check
                      attrs={{...report.cccfOutputFlag}}
                      onChange={(e) => reportAction.changeInputReport(e)}
                    />
                  </div>
                }
                subheader={<span>※ Amadeus発券分のみ</span>}
                body={
                  <React.Fragment>
                    <div className='d-block ml-5 mb-3'>
                      <label sm="3" className='label-color mb-0 mr-3 text-right width-13'>
                        {report.label[1]}
                      </label>
                      <Common.Select
                        attrs={{...report.cccfOutputFilename}}
                        group={{
                          className: 'width-37 mr-2',
                          textwidth: 'width-37',
                        }}
                        minLength={1}
                        list={report.cccfFileNamePullDown}
                        onChange={(e) => reportAction.changeInputReport(e)}
                      />
                    </div>
                    <div className='d-flex'>
                      <div className = 'width-p-50 m-0 d-inline-block'>
                        <div className='ml-5 mb-3'>
                          <label sm="3" className='label-color mb-0 mr-3 text-right width-13'>
                            {report.label[3]}
                          </label>
                          <Common.Radio
                            btnSize='sm'
                            attrs={{...report.cccfOutputType}}
                            // className='mr-5'
                            group={{
                              className: 'width-30 mr-0',
                            }}
                            radios={OUTPUT_REPORT}
                            onChange={(e) => reportAction.changeInputReport(e)}
                          />
                        </div>
                        <div className='ml-5 mb-3'>
                          <label sm="3" className='label-color mb-0 mr-3 text-right width-13'>
                            {report.label[2]}
                          </label>
                          <Common.Radio
                            btnSize='sm'
                            attrs={{...report.cccfOutputLogo}}
                            // className='mr-5'
                            group={{
                              className: 'width-30 mr-0',
                            }}
                            radios={OUTPUT_LOGO}
                            onChange={(e) => reportAction.changeInputReport(e)}
                          />
                        </div>
                      </div>
                    <div className = 'width-p-50 m-0 d-inline-block'>
                      <div className='ml-1 mb-3'>
                        <Common.Check
                          attrs={{...report.cccfAgentcopyOutputFlag}}
                          onChange={(e) => reportAction.changeInputReport(e)}
                        />
                        <Common.Check
                          attrs={{...report.cccfCustomerprintOutputFlag}}
                          onChange={(e) => reportAction.changeInputReport(e)}
                        />
                      </div>
                    </div>
                    </div>
                    
                  </React.Fragment>
                }
              />
              <Card
                root='max'
                size='md'
                hideClose
                header={
                  <div>
                    <Common.Check
                      attrs={{...report.refundnoticeOutputFlag}}
                      onChange={(e) => reportAction.changeInputReport(e)}
                    />
                  </div>
                }
                subheader={<span>※ Amadeus発券分のみ</span>}
                body={
                  <React.Fragment>
                    <div className='ml-5 mb-3'>
                      <label sm="3" className='label-color mb-0 mr-3 text-right width-13'>
                        {report.label[1]}
                      </label>
                      <Common.Select
                        attrs={{...report.refundnoticeOutputFilename}}
                        group={{
                          className: 'width-37 mr-2',
                          textwidth: 'width-37',
                        }}
                        minLength={1}
                        list={report.refundNoticeFileNamePullDown}
                        onChange={(e) => reportAction.changeInputReport(e)}
                      />
                    </div>
                  </React.Fragment>
                }
              />
            </React.Fragment>
          }
        />
        <Card 
          size='lg'
          root='button'
          hideClose
          header={null}
          subheader={null}
          body={
            <div className='card-buttom-button'>
              {!props.isModal &&
                <React.Fragment>
                <Button
                  {...report.back}
                  className={`button-gray mr-auto width-max-content ${(location.state && location.state.caller==='tktEdit')?'':'d-none'}`}
                  disabled={app.isProcessing}
                  onClick={() => {
                    tktEditAction.clearTktEdit()
                    setTimeout(() => {
                      history.push(`${TITLE.ticketEdit.mainLink}/${thisNo}`, {init: true})
                    })
                  }}
                >
                  {report.back.label}
                </Button>
                  <Button
                    {...report.print}
                    className='button-gray ml-auto width-max-content'
                    disabled={app.isProcessing}
                    onClick={() => execDownload('print')}
                  >
                    {report.print.label}
                  </Button>
                  <Button
                    {...report.download}
                    className='button-gray ml-3 width-max-content'
                    disabled={app.isProcessing}
                    onClick={() => execDownload('download')}
                  >
                    {report.download.label}
                  </Button>
                </React.Fragment>
              }
              {props.isModal &&
                <React.Fragment>
                  <Button
                    {...report.cancel}
                    className='button-gray mr-auto width-max-content'
                    disabled={app.isProcessing}
                    onClick={() => props.onClose()}
                  >
                    {report.cancel.label}
                  </Button>
                  <Button
                    {...report.setting}
                    className='button-blue ml-auto width-max-content'
                    disabled={app.isProcessing}
                    onClick={() => execSetting()}
                  >
                    {report.setting.label}
                  </Button>
                </React.Fragment>
              }
            </div>
          }
        />
      </Container>
    </React.Fragment>
  )
}