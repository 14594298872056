import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { LIST_ITEMS, PDT_FARE_ID, TBL_TYPE, TKTKBN_MAP } from 'constants/master';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';

export const PatternNamePopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  const [pat, setPat] = React.useState('')

  const initPopup = (form) => {
    props.setPopup(form)
  }

  React.useEffect(() => {
    const patternList = _.filter(props.search.patternList, r => r.tktSrchptId == props.popupData)
    const patternName = (patternList.length > 0)?patternList[0].tktSrchptName:''
    setPat((patternList.length > 0)?patternList[0].tktSrchptPtn:'')

    props.onBlur(convert.getTarget(props.search.patternName, 'text', patternName))
  }, [props.popupData])

  const action = (type) => {
    let entry = {};
    let searchJson = []
    
    entry = entryCheck(props.search, convert.getTarget(props.search.patternName, 'text', props.search.patternName.text))
    props.setInput(entry)
    if (entry.patternName.isInvalid) return
    // 
    appAction.showProcessing({isProcessing: true})

    _.forEach(props.search.checkedItems, (v, k) => {
      searchJson.push(v.key)
    })
    
    if (type === 'insert') {
      props.searchApi.findCheckPattern({
        // patternId: props.popupData,
        // tktSrchptObjectKbn: '2',
        tktSrchptPrivForm: props.tktSrchptPrivForm,
        tktSrchptName: entry.patternName.text,
        // searchString: JSON.stringify(_.uniq(searchJson)),
      })
      .then(res => {
        if (res.payload.exist) {
          alert().fire({
            icon: MESSAGE.confirmPatternUpdate().icon,
            title: MESSAGE.confirmPatternUpdate().text
          })
          .then((result) => {
            if (result.isConfirmed) {
              updateApi(entry, JSON.stringify(_.uniq(searchJson)), res.payload.patternIdList[0])
            } else {
              appAction.showProcessing({isProcessing: false})
            }
          })
        } else {
          updateApi(entry, JSON.stringify(_.uniq(searchJson)), props.popupData)
        }
      })
      .catch(() =>{})
      .finally(() => {
      })
    } else {
      updateApi(entry, pat, props.popupData)
    }
  }

  const updateApi = (entry, searchString, id) => {
    props.searchApi.findRegistPattern({
      patternId: id,
      tktSrchptObjectKbn: '1',
      tktSrchptPrivForm: props.tktSrchptPrivForm,
      tktSrchptName: entry.patternName.text,
      searchString: searchString,
    })
    .then(res => {
      // console.log(res.payload)
      appAction.setMessage({message: res.payload.messageList})
      if (res.payload.code === '000') {
        props.setInput({
          // recentlyCondition: getPattern(res.payload.patternMap, '最近使った検索条件'),
          // checkedItemsSetting: getPattern(res.payload.patternMap, '前回検索条件'),
          patternList: getPatternList(res.payload.patternList),
          patternMap: res.payload.patternMap,
        })
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
      entry.patternName.isInvalid = false
      entry.patternName.text = ''
      entry.patternName.value = ''
      props.setInput(entry)
      props.popup.hide()
    })
  }

  const delPattern = () => {
    alert().fire({
      icon: MESSAGE.confirmDelete('検索条件パターン').icon,
      title: MESSAGE.confirmDelete('検索条件パターン').text
    })
    .then((result) => {
      if (result.isConfirmed) {
        props.searchApi.findDeletePattern({
          tktSrchptId: props.popupData,
          // tktSrchptObjectKbn: '2',
          tktSrchptPrivForm: props.tktSrchptPrivForm,
          // tktSrchptName: entry.patternName.text,
          // searchString: searchString,
          tktSrchptPrivForm: props.tktSrchptPrivForm,
        })
        .then(res => {
          // console.log(res.payload)
          appAction.setMessage({message: res.payload.messageList})
          if (res.payload.code === '000') {
            props.setInput({
              // recentlyCondition: getPattern(res.payload.patternMap, '最近使った検索条件'),
              // checkedItemsSetting: getPattern(res.payload.patternMap, '前回検索条件'),
              patternList: getPatternList(res.payload.patternList),
              patternMap: res.payload.patternMap,
            })
          }
        })
        .catch(() =>{})
        .finally(() => {
          appAction.showProcessing({isProcessing: false})
          props.popup.hide()
        })
      } else {
        appAction.showProcessing({isProcessing: false})
      }
    })
  }
  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
      >
        <Card 
          root='popup'
          size='lg'
          hideClose
          header={props.header}
          headerClass='wj-dialog-header'
          subheader={props.subheader}
          // body={props.body}
          body={
            <React.Fragment>
              <Common.Text
                attrs={{...props.search.patternName}}
                onBlur={(e) => props.onBlur(e)}
                group={{
                  className: 'width-50 mr-2'
                }}
              />
            </React.Fragment>
          }
        />
        <Card 
          size='md'
          root={`popup-button ${props.size}`}
          // body={props.button}
          body={
            <React.Fragment>
              <Button className='button-gray mr-auto wj-hide-cancel'>
                <span className='material-icons-outlined md mr-2'>cancel</span>
                キャンセル
              </Button>
              {props.popupData && 
                <Button
                  className='button-warn ml-auto mr-auto'
                  onClick={() => delPattern()}
                  disabled={app.isProcessing}
                >
                  <span className='material-icons-outlined md mr-2'>file_download</span>
                  削除
                </Button>
              }
              <Button
                className='button-blue ml-auto'
                onClick={() => action(props.popupData?'update':'insert')}
                disabled={app.isProcessing}
              >
                <span className='material-icons-outlined md mr-2'>file_download</span>
                {props.popupData?'名称変更':'登録'}
              </Button>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})