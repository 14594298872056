import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from 'components';
import * as Common from 'components/common';

import { Button } from 'react-bootstrap';
import _ from 'lodash';
import { RADIOS, CHECKS } from 'constants/search';
// import '../styles/conditionCard.scss'

export const CardRadioComponent = (props) => {
  // 初期化
  React.useEffect(() => {
    clear('1')
  }, [props.condition.key])

  // clear
  const clear = (value) => {
    const obj = {}
    const radioIndex = props.condition.key==='tktMonthStepoverFlg'?1:0
    if (!props.items[0].value || value==='0') {
      obj[props.items[0].id] = {
        ...props.items[0],
        original: RADIOS[props.condition.key][radioIndex].value,
        value: RADIOS[props.condition.key][radioIndex].value,
        text: RADIOS[props.condition.key][radioIndex].value,
      }
    }
    Object.keys(obj).length && props.setInput({...obj})
  }

  const getBody = () => {
    return (
      <React.Fragment>
        <div className='card-condition-item wrap'>
          <Common.Radio
            attrs={props.items[0]}
            radios={RADIOS[props.condition.key]}
            onChange={(e) => props.onBlur(e)}
          />
        </div>
        <div className='d-inline-block mb-1'>
          <Button
            id='clear'
            className='button-gray small width-max-content'
            onClick={(e) => clear('0')}
          >
            クリア
          </Button>
        </div>
      </React.Fragment>
    )
  }
  
  return (
    <React.Fragment>
      <Card
        size={props.cardSize}
        header={props.condition.header}
        subheader={props.condition.subheader}
        body={getBody()}
        condition={props.condition}
        onClose={props.onClose}
      />
    </React.Fragment>
  )
}

CardRadioComponent.defaultProps = {};
