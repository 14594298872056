import { axiosInstance, axiosInstanceNoerr } from 'http/index'

export const SystemApi = {
  all: (uri, data) => {
    const instance = axiosInstance;
    return instance.post(uri, data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  search: (type, data) => {
    const instance = axiosInstance;
    return instance.post(`/system/${type}/search`, data?data:{}).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  image: () => {
    const instance = axiosInstanceNoerr;
    return instance.get(`/system/biz/image`).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  edit: (type, data) => {
    const instance = axiosInstance;
    return instance.post(`/system/${type}/edit`, data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  download: (data) => {
    const instance = axiosInstance;
    return instance.post(`/system/travel/download`, data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  cldelete: (data) => {
    const instance = axiosInstance;
    return instance.post(`/system/travel/cldelete`, data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  upload: (data) => {
    const instance = axiosInstance;
    return instance.post(`/system/biz/upload`, data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
}
