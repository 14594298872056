import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { PivotAction } from 'ducks/Pivot';
import * as Common from 'components/common';
import { Title, SearchList, Condition, SearchResult } from 'components';
import { 
  Col,
  Row,
  Container,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, makeCondition } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { TITLE } from 'constants/title';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import {TicketApi } from 'webapi'

export const PivotSearchComponent = () => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const pivot = useSelector(state => state.Pivot);

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const pivotAction = bindActionCreators(PivotAction, dispatch);

  const [init, setInit] = React.useState(false)

  React.useEffect(() => {
    setInit(false)
    const dto = {
      tktOutsrcPcsId: 'B0702',
      tktOutsrcObjectKbn: '3',
      tktOutsrcObjectId: login.loginCd,
    }
    appAction.showProcessing({isProcessing: true})
    TicketApi.getOutSearch(dto)
    .then(res => {
      appAction.setMessage('')
      const searchJson = res.payload.tktOutsrcSearch?JSON.parse(res.payload.tktOutsrcSearch):{}
      pivotAction.setInputPivot({
        searchSql: res.payload.searchSql,
        searchJson: searchJson.conditionJson,
        condition: searchJson.condition?searchJson.condition:pivot.condition,
        // size1:['15%', '1'],
      })
      if (res.payload.code === '000') {
        // props.setInput({sizeHorizontal:['0%', '1']})
      } else {
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(() =>{})
    .finally(() => {
      setInit(true)
      appAction.showProcessing({isProcessing: false})
      // pivotAction.setInputPivot({searching: false})
    })
  }, [])

  return (
    init &&
    <React.Fragment>
      <Container fluid className='main'>
        <Title title={TITLE.pivotSearch.name} icon={TITLE.pivotSearch.icon}/>
        <SplitPane split="horizontal" className='split-pane-top'
          onChange={(size) => {pivotAction.setInputPivot({sizeHorizontal: size})}}>
          <SplitPane split="vertical" initialSize={pivot.sizeHorizontal[0]}
            onChange={(size) => {pivotAction.setInputPivot({sizeVertical: size})}}>
            <Pane initialSize={pivot.sizeVertical[0]}>
              <SearchList
                title={TITLE.pivotSearch.searchList}
                tktSrchptPrivForm='B0701'
                search={pivot}
                onBlur={pivotAction.changeInputPivot}
                setInput={pivotAction.setInputPivot}
                remove={pivotAction.removePivotItem}
                add={pivotAction.addPivotItem}
                searchApi={TicketApi}
                checkedItems={pivot.checkedItems}
                checkedItemsSetting={pivot.checkedItemsSetting}
                checkedItemsChanged={(e) => {
                  if (e.selectedItem) {
                    // if (_.findIndex(e.checkedItems, e.selectedItem) < 0) {
                    if (_.findIndex(e.checkedItems, o => o.key === e.selectedItem.key) < 0) {
                      pivotAction.removePivotItem([e.selectedItem])
                    } else {
                      const selectedItem = {
                        key: e.selectedItem.key,
                        type: e.selectedItem.type,
                      }
                      pivotAction.setInputPivot(makeCondition(e.selectedItem, pivot))
                      pivotAction.addPivotItem([e.selectedItem])
                      // focus
                      let element = null
                      setTimeout(() => {
                        if (selectedItem.type==='check') {
                          element = document.getElementById(
                            selectedItem.key + '_' +
                            CHECKS[selectedItem.key][0].id);
                        // } else if (e.selectedItem.type==='radio') {
                        //   element = document.getElementById(e.selectedItem.key + -0);
                        } else {
                          element = document.getElementById(selectedItem.key + '_array');
                          element = element?element:document.getElementById(selectedItem.key + '_0');
                          element = element?element:document.getElementById(selectedItem.key + '_from');
                        }
                        //
                        // console.log(element)
                        element && element.focus();
                      });
                    }
                  }
                  pivotAction.setInputPivot({listChanged: false})
                }}
              />
            </Pane>
            <Pane>
              <Condition
                search={pivot}
                onBlur={pivotAction.changeInputPivot}
                setInput={pivotAction.setInputPivot}
                title={TITLE.pivotSearch.condition}
                onClose={pivotAction.removePivotItem}
                // searchApi={TicketApi.findSearch}
                searchApi={TicketApi}
                tktSrchptPrivForm='B0701'
              />
            </Pane>
          </SplitPane>
          <Pane>
            <SearchResult
              search={pivot}
              onBlur={pivotAction.changeInputPivot}
              setInput={pivotAction.setInputPivot}
              title={TITLE.pivotSearch.result}
              searchApi={TicketApi}
              tktSrchptPrivForm='B0701'
            />
          </Pane>
        </SplitPane>
      </Container>
      <Common.RouteLeavingGuard
        when={true}
        shouldBlockNavigation={()=>false}
      />
    </React.Fragment>
  )
}