import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { PivotAction } from 'ducks/Pivot';
import * as Common from 'components/common';
//
import {
  DateTime,
  toggleClass,
} from '@grapecity/wijmo';
import '@grapecity/wijmo.touch'; // support drag/drop on touch devices
import * as Olap from '@grapecity/wijmo.react.olap';
import * as wjcOlap from '@grapecity/wijmo.olap';
import * as wjChart from '@grapecity/wijmo.chart';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as gridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as gridPdf from '@grapecity/wijmo.grid.pdf';
import * as wPdf from '@grapecity/wijmo.pdf';
import { TabPanel, Tab, TreeView } from '@grapecity/wijmo.react.nav';
import {
  Container,
  Row,
  Col,
  Badge,
  Button,
  Accordion,
  Card as BootCard,
} from 'react-bootstrap';
import { Title } from 'components';
import { PivotDisplay } from './PivotDisplay'
import { Responsive, WidthProvider } from 'react-grid-layout';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { TITLE } from 'constants/title';
import { Card } from 'components';
import { SummaryApi } from 'webapi'
import { alert, entryCheck, convert, getCondition, dataMapper, createDispSearch } from "lib";
import { FIELDS, NSB_OPTION, BI_MAP, CHART_TYPE, TKT_STATUS_CODE, BI_INTEGER, BI_INTEGER_NO } from 'constants/pivot';
import { SEARCH, COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { MESSAGE } from 'constants/message';
import { TKT_STATUS } from 'constants/ticket';
import { FLT_IND_BYTE_TKT } from 'constants/searchResult';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import _, { clone } from 'lodash';
import ipaexg from 'fonts/ipaexg.ttf'
import { PatternNamePopup } from './modal/PatternNamePopup';

const ResponsiveGridLayout = WidthProvider(Responsive);

export const PivotComponent = (props) => {

  const app = useSelector(state => state.App);
  const tktSearch = useSelector(state => state.TktSearch);
  const pivot = useSelector(state => state.Pivot);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const pivotAction = bindActionCreators(PivotAction, dispatch);

  const [panel, setPanel] = React.useState(null)
  const [grid, setGrid] = React.useState(null)
  const [chart, setChart] = React.useState(null)
  const [fields, setFields] = React.useState([])
  const [selectedTab, setSelectedTab] = React.useState(0)
  const [collectionAction, setCollectionAction] = React.useState(null)
  const [reportSelSize, setReportSelSize] = React.useState('60%')
  const [popup, setPopup] = React.useState(null)
  const [popupData, setPopupData] = React.useState(null)
  const [selReport, setSelReport] = React.useState(null)
  const [treeView, setTreeView] = React.useState(null)
  const [nonPivotItem, setNonPivotItem] = React.useState(true)
  const [selectedChange, setSelectedChange] = React.useState(null)
  const [changeView, setChangeView] = React.useState(null)
  const [newDef, setNewDef] = React.useState(null)
  const [initialCheck, setInitialCheck] = React.useState(true)
  const [transitionCheck, setTransitionCheck] = React.useState(false)

  React.useEffect(() => {
    getData()
    
    const panelTemplate = `
      <div id="panel-item-list" >
        <div class="field-list-label">
          <label wj-part="g-flds"></label>
        </div>
        <div class="field-list pad">
          <div id="panel-item-list-inner" wj-part="d-fields"></div>
        </div>
      </div>
      <div id="panel-section-list">
        <div>
          <label wj-part="g-drag"></label>
        </div>
        <div id="panel-section-grid">
          <div>
            <label>
              <span class="wj-glyph wj-glyph-filter"></span>
              <span wj-part="g-flt"></span>
            </label>
            <div wj-part="d-filters"></div>
          </div>
          <div>
            <label>
              <span class="wj-glyph">⫴</span>
              <span wj-part="g-cols"></span>
            </label>
            <div wj-part="d-cols"></div>
          </div>
          <div>
            <label>
              <span class="wj-glyph">≡</span>
              <span wj-part="g-rows"></span>
            </label>
            <div wj-part="d-rows"></div>
          </div>
          <div>
            <label>
              <span class="wj-glyph">Σ</span>
              <span wj-part="g-vals"></span>
            </label>
            <div wj-part="d-vals"></div>
          </div>
        </div>
        <div id="panel-defer">
          <div wj-part="d-prog" class="progress-bar"></div>
            <input wj-part="chk-defer" type="checkbox">
            <span wj-part="g-defer">Defer Updates</span>
            <button wj-part="btn-update" class="wj-btn wj-state-disabled" type="button" disabled/>
          </div>
      </div>`;
      
    wjcOlap.PivotPanel.controlTemplate = panelTemplate
    // console.log(wjcOlap.PivotFieldEditor.controlTemplate)
    wjcOlap.PivotFieldEditor.controlTemplate = `
      <div id="custom-field-editor">
        <div class="wj-dialog-header" tabindex="-1">
          <span wj-part="g-dlg"></span>
          <span wj-part="sp-bnd"></span>
        </div>
        <div class="wj-dialog-body">
          <table style="table-layout:fixed">
            <tr>
              <td wj-part="g-hdr"></td>
              <td><div wj-part="div-hdr" readonly></div></td>
            </tr>
            <tr class="wj-separator">
              <td wj-part="g-agg"></td>
              <td><div wj-part="div-agg"></div></td>
            </tr>
            <tr class="wj-separator">
              <td wj-part="g-shw"></td>
              <td><div wj-part="div-shw"></div></td>
            </tr>
            <tr>
              <td wj-part="g-wfl"></td>
              <td><div wj-part="div-wfl"></div></td>
            </tr>
            <tr>
              <td wj-part="g-srt"></td>
              <td><div wj-part="div-srt"></div></td>
            </tr>
            <tr class="wj-separator">
              <td wj-part="g-flt"></td>
              <td>
                <div>
                  <button wj-part="btn-flt-edt" class="wj-btn"></button>
                  &nbsp;&nbsp;
                  <button wj-part="btn-flt-clr" class="wj-btn"></button>
                </div>
              </td>
            </tr>
            <tr class="wj-separator">
              <td wj-part="g-fmt"></td>
              <td><div wj-part="div-fmt"></div></td>
            </tr>
            <tr>
              <td wj-part="g-smp"></td>
              <td><div wj-part="div-smp" readonly tabindex="-1"></div></td>
            </tr>
          </table>
        </div>
        <div class="wj-dialog-footer">
          <button wj-part="btn-apply" class="wj-btn wj-hide"></button>
          &nbsp;&nbsp;
          <button wj-part="btn-cancel" class="wj-btn wj-hide"></button>
        </div>
      </div>`

    setPanel(
      new wjcOlap.PivotEngine({
        fields: FIELDS,
        showZeros: true,
        viewDefinitionChanged: (s) => {
          setChangeView(s.viewDefinition)
        }
      })
    )
    
  }, [])

  React.useEffect(() => {
    if (changeView) {
      if (!pivot.viewDefinition.text) {
        pivotAction.setInputPivot({
          viewDefinition: {
            ...pivot.viewDefinition,
            original: changeView,
            value: changeView,
            text: changeView,
            dirty: '',
          }
        })
      } else {
        pivotAction.changeInputPivot(convert.getTarget(pivot.viewDefinition, 'text', changeView))
      }
      setChangeView(null)
    }
  }, [changeView])


  // React.useEffect(() => {
  //   console.log(fields)
  //   if (fields && fields.length > 0)
  //   panel.fields = fields
  // }, [fields])

  React.useEffect(() => {
    // console.log(panel)
    if (panel) {
      panel.autoGenerateFields = false
      // console.log(pivot.dataSource)
      panel.itemsSource = pivot.dataSource
      panel.valueFields.length = 0
      panel.rowFields.length = 0
      setNewDef(panel.viewDefinition)
    }
  },[panel, pivot.dataSource])

  const getData = () => {
    appAction.showProcessing({isProcessing: true})
    appAction.setProcessingMessage({processingMessage: 'データ抽出中．．．'})
    const tktOutsrcSearch = {
      condition: pivot.condition,
      conditionJson: pivot.searchJson?pivot.searchJson:getCondition(pivot)
    }
    SummaryApi.init({
      searchSql: pivot.searchSql,
      tktOutsrcSearch: pivot.searchJson?JSON.stringify(tktOutsrcSearch):'',
    })
    .then(res => {
      // console.log(res)
      appAction.setMessage('')
      let reportList = []
      let defaultReportList = []
      let customReportList = []

      _.forEach(res.payload.bizReportInfoDtoList, (r, i) => {
        if (r.reportType === '0') {
          defaultReportList.push({...r, isheader: false})
        } else {
          customReportList.push({...r, isheader: false})
        }
      })
      if (customReportList.length > 0) {
        reportList.push({
          isheader: true,
          reportTitle: 'カスタムレポート',
          items: _.orderBy(customReportList, 'id', 'desc')
        })
      }
      if (defaultReportList.length > 0) {
        reportList.push({
          isheader: true,
          reportTitle: 'URI-Proオリジナルレポート',
          items: _.orderBy(defaultReportList, 'id', 'desc')
        })
      }
      
      const searchJson = res.payload.tktOutsrcSearch?JSON.parse(res.payload.tktOutsrcSearch):{}
      if (location.state && location.state.caller === 'search' && pivot.reportList && pivot.reportList.length > 0) {
        pivotAction.setInputPivot({
          dataSource:getDataSource(res.payload.dataSourceDtoList),
          // reportList: reportList,
          searchSql: res.payload.searchSql,
          searchJson: searchJson.conditionJson,
          condition: searchJson.condition?searchJson.condition:pivot.condition,
        })
        setTimeout(() => {
          if (location.state.report) {
            setSelReport(location.state.report)
          }
        })
      } else {
        pivotAction.setInputPivot({
          dataSource:getDataSource(res.payload.dataSourceDtoList),
          reportList: reportList,
          searchSql: res.payload.searchSql,
          searchJson: searchJson.conditionJson,
          condition: searchJson.condition?searchJson.condition:pivot.condition,
          // size1:['15%', '1'],
        })
      }
      if (res.payload.code === '000') {
        // props.setInput({sizeHorizontal:['0%', '1']})
      } else {
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
      appAction.setProcessingMessage({processingMessage: ''})
      // pivotAction.setInputPivot({searching: false})
    })

    // const data = pivot.data
    // console.log(data)
    // return data;
  }

  const getDataSource = (data) => {
    let dataSource = []
    let tmp = {}
    // console.log(data)
    _.forEach(data, (r, i) => {
      // console.log(r)
      _.forEach(r, (item, key) => {
        if (key === 'tktCommRate' || key === 'tktTkiTokuiRate') {
          tmp[key] = !item?0 :_.toNumber(item) / 10000
        }
        // Responseを数値で！
        _.forEach(FIELDS, (top) => {
          _.forEach(top.subFields, (sec) => {
            _.forEach(sec.subFields, (thi) => {
              if (thi.binding === key) {
                if (top.header === '集計項目') {
                  // console.log(_.toNumber(item && item!=='null'?item:'0'))
                    // console.log(_.toNumber(item))
                    tmp[key] = (item==='null' || item==="" || _.isNaN(_.toNumber(item)))?item:_.toNumber(item && item!=='null'?item:'　')
                    // if (key !== 'tktNsbItno' || key !== 'tktNsbItnoFiveDigit') {
                    //   tmp[key + 'ChildTktTop'] = tmp[key]
                    // }
                } else if (_.indexOf(BI_INTEGER, key) > -1) {
                  tmp[key] = _.toNumber(item && item!=='null'?item:'　')
                } else if (_.indexOf(BI_INTEGER_NO, key) > -1) {
                  tmp[key] = item?_.toNumber(item):'　'
                } else {
                  tmp[key] = item && item!=='null'?item:''
                }
              }
              _.forEach(thi.subFields, (fth) => {
                if (fth.binding === key) {
                  if (key === 'fltBoardTime' || key === 'fltArrTime') {
                    tmp[key] = item?convert.hhmm2date(item, 'HHmm'):'　'
                  } else if (fth.dataType === 'Date') {
                    tmp[key] = item?new Date(item):'　'
                  }
                  if (key === 'tktCaExdate') {
                    tmp[key] = item.length>3?item.substring(0,2) + '/' + item.substring(2,4):item
                  }
                }
              })
            })
          })
        })
      })
      // console.log(r)
      // console.log(tmp)
      // console.log({
      //   ...r,
      //   ...tmp,
      // })
      dataSource.push({
        ...r,
        ...tmp,
      })
    })
    return dataSource
  }

  const initializePivotChart = (pivotChart) => {
    // console.log(pivotChart)
    // pivotChart.engine.fields.getField('金額').format = 'c0';
    // pivotChart.engine.fields.getField('金額1').format = 'c0';
    // pivotChart.engine.fields.getField('金額2').format = 'c0';
    pivotChart.chartType = 'Column';
    // pivotChart.flexChart.series.forEach((r,i) => {
    //   console.log(r)
    //   console.log(i)
    // })
    // console.log(pivotChart.flexChart.series)
    setChart(pivotChart)
    // this.pivotChart.flexChart.series[1].graphType = wjChart.ChartType.Line;
    // document.querySelector("#inputShowChartTitle").checked = true;
  }
  
  const initializePivotGrid = (grid) => {
    // console.log(grid)
    const columns = grid.columns.length;
    // console.log(grid)
    // grid.frozenColumns = 0
    // grid.allowPinning = 1
    // grid.columns.splice(columns-1, 0, new wjGrid.Column())
    // grid.columnHeaders.setCellData(columns-1, 0, '追加')
    // grid.columns.splice(0, 0, new wjGrid.Column());
    // grid.columnHeaders.setCellData(0, 0, '支出合計');
    //     grid.setCellData(0, 1, 3333);
    // console.log(grid)
    setGrid(grid)
  }
  
  const loadedGrid = (grid) => {
    // grid.columns.splice(0, 0, new wjGrid.Column());
    // grid.columnHeaders.setCellData(0, 0, '支出合計');
    // console.log(grid.getCellData(1, 'tktTktnoAl'))
    grid.rows.forEach((r,i) => {
      // console.log(r)
      // console.log(grid)
      // console.log(i)
      
    })
    // grid.setCellData(0, 0, 3333);
  }

  const setSelected = (e) => {
    if (document.querySelector('.accordion-button')) {
      if (e === 'open') {
        document.querySelector('.accordion-button').classList.add('show')
        document.querySelector('.accordion-button').classList.remove('hide')
      } else {
        document.querySelector('.accordion-button').classList.add('hide')
        document.querySelector('.accordion-button').classList.remove('show')
      }
    }
  }

  React.useEffect(() => {
    getGraphHeight()
  }, [pivot.sizeGraph])

  const getGraphHeight = () => {
    const firstView = _.toNumber(_.replace(pivot.sizeGraph[0], 'px', ''))
    const accordionClass = document.querySelector('#bi-option').parentNode.className
    const cardHeight = (accordionClass.indexOf('show') < 0)?0:160
    const gridNode = document.querySelector('#pivot-box-grid')
    const graphNode = document.querySelector('#pivot-box-graph')
    // console.log(document.querySelector('#bi-option'))
    // console.log(accordionClass)
    // console.log(cardHeight)
    const panelNodes = document.querySelectorAll('.wj-pivotpanel #panel-section-grid .wj-flexgrid')
    // console.log(firstView)
    // console.log(panelNodes)
    if (pivot.sizeGraph[0] && _.isNumber(firstView)) {
      _.forEach(panelNodes, r => {
        r.style.height = `calc(${firstView}px - 100px)`
      })
      if (gridNode) gridNode.style.height = `calc(100vh - 470px - ${1 * firstView}px - ${cardHeight}px)`
      if (graphNode) graphNode.style.height = `calc(100vh - 470px - ${1 * firstView}px)`
      return (100 - firstView) +'vh'
    } else {
      _.forEach(panelNodes, r => {
        r.style.height = `7.75rem`
      })
      // console.log('false!!!')
      if (gridNode) gridNode.style.height = `calc(100vh - 670px - ${cardHeight}px)`
      if (graphNode) graphNode.style.height = `calc(100vh - 670px)`
      return '100vh'
    }
  }

  const settingFields = () => {
    // mapperによる変換
    panel.fields.getField('TKTステータス').getValue = (item)=> {
      return dataMapper(TKT_STATUS, 'value', 'name', item.tktStatus)
    }
    panel.fields.getField('フライト区間種別').getValue = (item)=> {
      return dataMapper(FLT_IND_BYTE_TKT, 'value', 'label', item.fltIndByteTkt)
    }
    // 集計をNumberへ
    _.forEach(panel.fields, (top) => {
      _.forEach(top.subFields, (sec) => {
        _.forEach(sec.subFields, (thi) => {
          // if (top.header === '集計項目') {
          //   panel.fields.getField(thi.header).getValue = (item)=> {
          //     // console.log()
          //     return _.toNumber(item[thi.binding])
          //   }
          //   panel.fields.getField(thi.header).dataType = 'Number'
          //   panel.fields.getField(thi.header).aggregate = 'Sum'
          //   // console.log(panel.fields.getField(thi.header))
          // }
          if (thi.header === 'COMM RATE') {
            // panel.fields.getField(thi.header).dataType = 'Number'
            // panel.fields.getField(thi.header).aggregate = 'Sum'
            panel.fields.getField(thi.header).getValue = (item)=> {
              return item?_.toNumber(item[thi.binding]/10000):0
            }
          }
          // if (thi.header === '得意先%') {
          //   panel.fields.getField(thi.header).dataType = 'Number'
          //   panel.fields.getField(thi.header).aggregate = 'Sum'
          //   // panel.fields.getField(thi.header).getValue = (item)=> {
          //   //   return item?_.toNumber(item[thi.binding]/10000):0
          //   // }
          // }
          // if (thi.header === 'SHARE') {
          //   panel.fields.getField(thi.header).dataType = 'Number'
          //   panel.fields.getField(thi.header).aggregate = 'Sum'
          //   panel.fields.getField(thi.header).showAs = 'PctGrand'
          // }
          if (thi.header === 'NVA' || thi.header === 'NVB') {
            panel.fields.getField(thi.header).getValue = (item)=> {
              return item?convert.toDate(item[thi.binding]):'　'
            }
          }
          if (thi.header === '搭乗時間' || thi.header === '到着時間') {
            panel.fields.getField(thi.header).getValue = (item)=> {
              return item?convert.hhmm2date(item[thi.binding]):'　'
            }
          }
          _.forEach(thi.subFields, (fth) => {
            // console.log(panel.fields.getField(thi.header))
            if (fth.dataType === 'Date') {
              // panel.fields.getField(fth.header).dataType = 'Date'
              panel.fields.getField(fth.header).getValue = (item)=> {
                return item?new Date(item[fth.binding]):'　'
              }
            }
          })
        })
      })
    })
    // 
    panel.valueFields.collectionChanged.addHandler((h, e) => {
      setCollectionAction(e)
    })
  }

  React.useEffect(() => {
    if (panel) {//console.log(pivot.dataSource)
      settingFields()
      // NSB額
      panel.fields.getField('NSB額').getValue = (item)=> {
        const isVoid = (item.tktStatus === '2' || item.tktStatus === '3')
        // VOID
        let retVal = ''
        if (isVoid && pivot.voidPrintFlg.text === '1') {
          retVal = item.tktNsbVoid
         // TOP
        } else if (pivot.cnjtktAmountPringFlg.text === '1') {
          retVal = item.tktNsbChildTktTop
        } else {
          retVal = item.tktNsb
        }
        return retVal
      }
      // panel.fields.getField('NSB額(IT-NO)').getValue = (item)=> {
      //   const isVoid = (item.tktStatus === '2' || item.tktStatus === '3')
      //   // VOID
      //   let retVal = ''
      //   if (isVoid && pivot.voidPrintFlg.text === '1') {
      //     retVal = item.tktNsbVoid
      //    // TOP
      //   } else if (pivot.cnjtktAmountPringFlg.text === '1') {
      //     retVal = item.tktNsbItnoChildTktTop
      //   } else {
      //     retVal = item.tktNsbItno
      //   }
      //   return retVal
      // }
      // panel.fields.getField('NSB額(*IT-NO先頭5桁*)').getValue = (item)=> {
      //   const isVoid = (item.tktStatus === '2' || item.tktStatus === '3')
      //   // VOID
      //   let retVal = ''
      //   if (isVoid && pivot.voidPrintFlg.text === '1') {
      //     retVal = item.tktNsbVoid
      //    // TOP
      //   } else if (pivot.cnjtktAmountPringFlg.text === '1') {
      //     retVal = item.tktNsbItnoFiveDigitChildTktTop
      //   } else {
      //     retVal = item.tktNsbItnoFiveDigit
      //   }
      //   return retVal
      // }
      // 運賃種別
      panel.fields.getField('運賃種別').getValue = (item)=> {
        return (pivot.faretypeConversionFlg.text === '1' && item.tktFareType === 'PEX')?item.tktFareTypePexToOth:item.tktFareType
      }
      panel.fields.getField('運賃種別コード').getValue = (item)=> {
        return (pivot.faretypeConversionFlg.text === '1' && item.tktFareType === 'PEX')?item.tktFareCdPexToOth:item.tktFareCd
      }
      // // 運賃種別(FLT)
      // panel.fields.getField('運賃種別(FLT)').getValue = (item)=> {
      //   return (pivot.faretypeConversionFlg.text === '1' && item.fltFareType === 'PEX')?item.fltFareTypePexToOth:item.fltFareType
      // }
      // panel.fields.getField('運賃種別コード(FLT)').getValue = (item)=> {
      //   return (pivot.faretypeConversionFlg.text === '1' && item.tktFareType === 'PEX')?item.tktFareCdPexToOth:item.tktFareCd
      // }
      // SHARE
      panel.fields.getField('SHARE').getValue = (item)=> {
        const isVoid = (item.tktStatus === '2' || item.tktStatus === '3')
        return  (isVoid && pivot.voidPrintFlg.text === '1')?item.shareVoid:
                  (pivot.cnjtktAmountPringFlg.text === '1')?
                    (pivot.taxIncludeFlg.text === '1')?item.shareTaxIncludeChildTktTop:item.shareChildTktTop
                  : (pivot.taxIncludeFlg.text === '1')?item.shareTaxInclude:item.tktShare
      }
      // サポート額
      panel.fields.getField('サポート額').getValue = (item)=> {
        const isVoid = (item.tktStatus === '2' || item.tktStatus === '3')
        return  (isVoid && pivot.voidPrintFlg.text === '1')?item.fltSupportFeeVoid:
                  (pivot.productionJudgeFlg.text === '1')?item.fltSupportFeeProductionjudge:item.fltSupportFee
      }

      // VOID券の金額を0で表示 + 先頭の子TKTデータに表示
      _.forEach(BI_MAP, (r, i) => {
        panel.fields.getField(r.key).getValue = (item)=> {
          const isVoid = (item.tktStatus === '2' || item.tktStatus === '3')
          // console.log(isVoid)
          // console.log(pivot.voidPrintFlg.text)
          // console.log(pivot.cnjtktAmountPringFlg.text)
          // console.log(item)
          // console.log(r.tktTop)
          // console.log(item[r.tktTop])
          // console.log((isVoid && pivot.voidPrintFlg.text === '1')?item[r.void]
          // : (pivot.cnjtktAmountPringFlg.text === '1')?item[r.tktTop]:item[r.id])
          return  (isVoid && pivot.voidPrintFlg.text === '1')?item[r.void]
                  : (pivot.cnjtktAmountPringFlg.text === '1')?item[r.tktTop]:item[r.id]
        }
      })
      
      panel.refresh()
    }
  }, [panel, pivot.viewDefinition, pivot.dataSource, pivot.nsbFlg.text, pivot.nsbOption.text, pivot.faretypeConversionFlg.text, pivot.taxIncludeFlg.text, pivot.productionJudgeFlg.text, pivot.voidPrintFlg.text, pivot.cnjtktAmountPringFlg.text])

  React.useEffect(() => {
    if (panel) {
      pivotAction.setInputPivot({
        displayRowSubtotalFlg: {
          ...pivot.displayRowSubtotalFlg,
          disabled: (pivot.displayRowTotalFlg.text === '0'),
          text: (pivot.displayRowTotalFlg.text === '0')?'0':pivot.displayRowSubtotalFlg.text
        },
        displayColumnSubtotalFlg: {
          ...pivot.displayColumnSubtotalFlg,
          disabled: (pivot.displayColumnTotalFlg.text === '0'),
          text: (pivot.displayColumnTotalFlg.text === '0')?'0':pivot.displayColumnSubtotalFlg.text
        }
      })
      panel.showRowTotals = (pivot.displayRowSubtotalFlg.text === '1')?'Subtotals':(pivot.displayRowTotalFlg.text === '1')?'GrandTotals':'None'
      panel.showColumnTotals = (pivot.displayColumnSubtotalFlg.text === '1')?'Subtotals':(pivot.displayColumnTotalFlg.text === '1')?'GrandTotals':'None'
      // console.log(panel.showRowTotals)
      // console.log(panel.showColumnTotals)
      panel.refresh()
    }
  }, [panel, pivot.viewDefinition, pivot.dataSource, pivot.displayRowTotalFlg.text, pivot.displayRowSubtotalFlg.text, pivot.displayColumnTotalFlg.text, pivot.displayColumnSubtotalFlg.text])

  React.useEffect(() => {
    if (panel) {
      let cloneList = []
      let deleteList = []
      
      _.forEach(panel.valueFields, (field, index) => {
        if (pivot.displayComponentRatioFlg.text === '1') {
          if (!field.header.startsWith('構成比率') && !field.header.startsWith('前年比率')) {
            const { isExist, clone } = changeRatio('ratio', field)
            if (!isExist) panel.fields.push(clone)
            cloneList.push({pos: index+1, field: clone})
          }
        } else {
          if (field.header.startsWith('構成比率')) {
            panel.fields.remove(field)
            deleteList.push(field)
          }
        }
      })
      _.forEachRight(cloneList, r => {
        panel.valueFields.insert(r.pos , r.field)
      })
      _.forEach(deleteList, r => {
        panel.valueFields.remove(r)
      })
      // console.log(panel)
    }
  }, [panel, pivot.dataSource, pivot.displayComponentRatioFlg.text])

  // React.useEffect(() => {
  //   if (panel) {
  //     let cloneList = []
  //     let deleteList = []

  //     _.forEach(panel.valueFields, (field, index) => {
  //       if (pivot.displayYoyFlg.text === '1') {
  //         // console.log(field)
  //         if (!field.header.startsWith('構成比率') && !field.header.startsWith('前年比率')) {
  //           const { isExist, clone } = changeRatio('comp', field)
  //           if (!isExist) panel.fields.push(clone)
  //           const addIndex = _.find(panel.valueFields, ['header', '構成比率' + field.header])?1:0
  //           cloneList.push({pos: index + 1 + addIndex, field: clone})
  //         }
  //       } else {
  //         if (field.header.startsWith('前年比率')) {
  //           panel.fields.remove(field)
  //           deleteList.push(field)
  //         }
  //       }
  //     })
  //     _.forEachRight(cloneList, r => {
  //       panel.valueFields.insert(r.pos , r.field)
  //     })
  //     _.forEach(deleteList, r => {
  //       panel.valueFields.remove(r)
  //     })
  //     // console.log(panel)
  //   }
  // }, [panel, pivot.dataSource, pivot.displayYoyFlg.text])

  // 編集判定
  React.useEffect(() => {
    if (!panel) return
    if (initialCheck) return setInitialCheck(false)
    if (isDirtyCheck()) setTransitionCheck(true)
  }, [pivot.viewDefinition])

  const changeRatio = (type, field) => {
    let clone = {}
    let isExist = false
    if (type === 'ratio') {
      // console.log(field.header)
      if (_.find(panel.fields, ['header', '構成比率' + field.header])) {
        clone = _.find(panel.fields, ['header', '構成比率' + field.header])
        isExist = true
      } else {
        clone = panel.fields.getField(field.header)._clone()
        clone.showAs = 'PctGrand'
        clone.format = 'p2'
        clone.header = '構成比率' + field.header
      }

    } else {
      if (_.find(panel.fields, ['header', '前年比率' + field.header])) {
        clone = _.find(panel.fields, ['header', '前年比率' + field.header])
        isExist = true
      } else {
        clone = panel.fields.getField(field.header)._clone()
        clone.showAs = 'DiffRowPct'
        clone.format = 'p2'
        clone.header = '前年比率' + field.header
      }
    }
    return {isExist: isExist, clone: clone}
  }

  React.useEffect(() => {
    if (collectionAction && collectionAction.item) {
      let index = collectionAction.index

      if (pivot.displayComponentRatioFlg.text === '1') {
        if (!collectionAction.item.header.startsWith('構成比率')
          && !collectionAction.item.header.startsWith('前年比率')
          && collectionAction.action === 0) {
          const { isExist, clone } = changeRatio('ratio', collectionAction.item)
          if (!isExist) panel.fields.push(clone)
          panel.valueFields.insert(index + 1 , clone)
          index++
        }
      }
      // if (pivot.displayYoyFlg.text === '1') {
      //   if (!collectionAction.item.header.startsWith('構成比率')
      //     && !collectionAction.item.header.startsWith('前年比率')
      //     && collectionAction.action === 0) {
      //     const { isExist, clone } = changeRatio('comp', collectionAction.item)
      //     if (!isExist) panel.fields.push(clone)
      //     panel.valueFields.insert(index + 1 , clone)
      //   }
      // }
      setCollectionAction(null)
    }
  }, [collectionAction])

  React.useEffect(() => {
    if (chart && pivot.graphType.text) {
      chart.chartType = pivot.graphType.text
      // console.log(chart)
      if (chart.flexPie) {
        chart.flexPie.chartsPerLine=1
        chart.flexPie.dataLabel.content = "{value}";
      }
      if (chart.flexChart) {
        chart.flexChart.dataLabel.content = "{value}";
      }
    }
  }, [chart, pivot.graphType.text])

  const execNew = () => {
    alert().fire({
      title: '',
      text: '選択されている状態がクリアされます。続行しますか？',
      icon: 'warning',
      allowOutsideClick: false,
      allowEscapeKey: false,
      focusCancel: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'OK'
    })
    .then((result) => {
      if (result.isConfirmed) {
        newAction()
        setTransitionCheck(false)
      }
    });
  }

  const newAction = () => {
    panel.valueFields.clear()
    panel.rowFields.clear()
    panel.columnFields.clear()
    panel.filterFields.clear()
    setReportSelSize('10%')
    setSelReport(null)
    panel.refresh()
    pivotAction.setInputPivot({
      reportTitle: {...pivot.reportTitle, original:'', value: '', text: '', dirty: ''},
      nsbFlg: {...pivot.nsbFlg, original:'0', value: '0', text: '0', dirty: ''},
      nsbOption: {...pivot.nsbOption, original:'0', value: '0', text: '0', dirty: ''},
      faretypeConversionFlg: {...pivot.faretypeConversionFlg, original:'0', value: '0', text: '0', dirty: ''},
      taxIncludeFlg: {...pivot.taxIncludeFlg, original:'0', value: '0', text: '0', dirty: ''},
      productionJudgeFlg: {...pivot.productionJudgeFlg, original:'0', value: '0', text: '0', dirty: ''},
      voidPrintFlg: {...pivot.voidPrintFlg, original:'0', value: '0', text: '0', dirty: ''},
      cnjtktAmountPringFlg: {...pivot.cnjtktAmountPringFlg, original:'0', value: '0', text: '0', dirty: ''},
      displayRowTotalFlg: {...pivot.displayRowTotalFlg, original:'0', value: '0', text: '0', dirty: ''},
      displayRowSubtotalFlg: {...pivot.displayRowSubtotalFlg, original:'0', value: '0', text: '0', dirty: ''},
      displayColumnTotalFlg: {...pivot.displayColumnTotalFlg, original:'0', value: '0', text: '0', dirty: ''},
      displayColumnSubtotalFlg: {...pivot.displayColumnSubtotalFlg, original:'0', value: '0', text: '0', dirty: ''},
      displayComponentRatioFlg: {...pivot.displayComponentRatioFlg, original:'0', value: '0', text: '0', dirty: ''},
      displayYoyFlg: {...pivot.displayYoyFlg, original:'0', value: '0', text: '0', dirty: ''},
    })
    setTimeout(() => {
      setNewDef(panel.viewDefinition)
    })
  }

  const execEdit = () => {
    
    setPopupData({
      reportTabType: selectedTab,
      viewDefinition: panel.viewDefinition,
      selReport: selReport,
      setSelReport: setSelReport,
      action: selReport?'update':'new'
    })
    popup.show(true, (sender) => {
      // if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
      //   console.log('ok')
      // } else {
      //   console.log('cancel')
      // }
      setPopupData(null)
    })
  }

  const execCopy = () => {
    // if (!isDirtyCheck()) {
      copyAction()
    // } else {
    //   alert().fire({
    //     title: '',
    //     text: '編集中の内容はクリアされます。よろしいですか？',
    //     icon: 'warning',
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     focusCancel: true,
    //     showCancelButton: true,
    //     reverseButtons: true,
    //     confirmButtonText: 'OK'
    //   })
    //   .then((result) => {
    //     if (result.isConfirmed) {
    //       copyAction()
    //     }
    //   });
    // }
  }

  const copyAction = () => {
    // 別名保存
    setPopupData({
      reportTabType: selectedTab,
      viewDefinition: panel.viewDefinition,
      selReport: selReport,
      setSelReport: setSelReport,
      action: 'copy'
    })
    popup.show(true, (sender) => {
      // if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
      //   console.log('ok')
      // } else {
      //   console.log('cancel')
      // }
      setPopupData(null)
    })
  }

  const execDelete = () => {
    // console.log(selReport)
    alert().fire({
      icon: MESSAGE.confirmDelete().icon,
      title: MESSAGE.confirmDelete(selReport.reportTitle).text
    })
    .then((result) => {
      if (result.isConfirmed) {
        SummaryApi.delete({
          id: selReport.id
        })
        .then(res => {
          // console.log(res.payload)
          appAction.setMessage('')
          if (res.payload.code === '000') {
            
            appAction.setMessage({message: res.payload.messageList})
            let reportList = []
            let defaultReportList = []
            let customReportList = []
      
            _.forEach(res.payload.bizReportInfoDtoList, (r, i) => {
              if (r.reportType === '0') {
                defaultReportList.push({...r, isheader: false})
              } else {
                customReportList.push({...r, isheader: false})
              }
            })
            if (customReportList.length > 0) {
              reportList.push({
                isheader: true,
                reportTitle: 'カスタムレポート',
                items: _.orderBy(customReportList, 'id', 'desc')
              })
            }
            if (defaultReportList.length > 0) {
              reportList.push({
                isheader: true,
                reportTitle: 'URI-Proオリジナルレポート',
                items: _.orderBy(defaultReportList, 'id', 'desc')
              })
            }
            pivotAction.setInputPivot({
              reportList: reportList,
              // size1:['15%', '1'],
            })
            setSelReport(null)
            panel.valueFields.clear()
            panel.rowFields.clear()
            panel.columnFields.clear()
            panel.filterFields.clear()
            panel.refresh()
            pivotAction.setInputPivot({
              reportTitle: {...pivot.reportTitle, original:'', value: '', text: '', dirty: ''},
              nsbFlg: {...pivot.nsbFlg, original:'0', value: '0', text: '0', dirty: ''},
              nsbOption: {...pivot.nsbOption, original:'0', value: '0', text: '0', dirty: ''},
              faretypeConversionFlg: {...pivot.faretypeConversionFlg, original:'0', value: '0', text: '0', dirty: ''},
              taxIncludeFlg: {...pivot.taxIncludeFlg, original:'0', value: '0', text: '0', dirty: ''},
              productionJudgeFlg: {...pivot.productionJudgeFlg, original:'0', value: '0', text: '0', dirty: ''},
              voidPrintFlg: {...pivot.voidPrintFlg, original:'0', value: '0', text: '0', dirty: ''},
              cnjtktAmountPringFlg: {...pivot.cnjtktAmountPringFlg, original:'0', value: '0', text: '0', dirty: ''},
              displayRowTotalFlg: {...pivot.displayRowTotalFlg, original:'0', value: '0', text: '0', dirty: ''},
              displayRowSubtotalFlg: {...pivot.displayRowSubtotalFlg, original:'0', value: '0', text: '0', dirty: ''},
              displayColumnTotalFlg: {...pivot.displayColumnTotalFlg, original:'0', value: '0', text: '0', dirty: ''},
              displayColumnSubtotalFlg: {...pivot.displayColumnSubtotalFlg, original:'0', value: '0', text: '0', dirty: ''},
              displayComponentRatioFlg: {...pivot.displayComponentRatioFlg, original:'0', value: '0', text: '0', dirty: ''},
              displayYoyFlg: {...pivot.displayYoyFlg, original:'0', value: '0', text: '0', dirty: ''},
            })
            setTimeout(() => {
              setNewDef(panel.viewDefinition)
            })
            setTransitionCheck(false)
          } else {
            appAction.setMessage({message: res.payload.messageList})
          }
        })
        .catch(() =>{})
        .finally(() => {
          appAction.showProcessing({isProcessing: false})
        })
      }
    })
  }

  const execPrint = () => {
    if (selectedTab === 0) {
      const now = convert.toDateTimeString(new Date, "yyyy-MM-dd HH:mm:ss")
      const reportName = selReport?selReport.reportTitle:'PivotGrid'
      gridPdf.FlexGridPdfConverter.export(grid, reportName + '.pdf', {
        embeddedFonts: [{ source: ipaexg, name: 'ipaexg' }],
        maxPages: 10,
        scaleMode: gridPdf.ScaleMode.PageWidth,
        documentOptions: {
          compress: true,
          header: { declarative: {
            text: `\t${reportName}\t${now}`,
            font: new wPdf.PdfFont('ipaexg', 10, 'normal', 'bold')
          } },
          footer: { declarative: { text: '\t&[Page] / &[Pages]' } },
          info: { author: 'GrapeCity', title: 'PivotGrid' },
          pageSettings: {
            size: wPdf.PdfPageSize.A4,
            // 縦：Portrait(0),横：Landscape(1)
            layout: wPdf.PdfPageOrientation.Landscape,
          },
        },
        formatItem: (args) => {
          if (args.panel.cellType == 2) {
            args.style.textAlign = 'center';
          }
        },
        styles: {
          cellStyle: { font: { family: 'ipaexg' }, backgroundColor: '#ffffff', borderColor: '#c6c6c6' },
          altCellStyle: { backgroundColor: '#f9f9f9' },
          groupCellStyle: { backgroundColor: '#dddddd' },
          headerCellStyle: { backgroundColor: '#eaeaea' }
        }
      });
    } else {
      chart.saveImageToFile('chart.png');
    }
  }

  const execExcel = () => {
    // create book with current view
    let book = gridXlsx.FlexGridXlsxConverter.saveAsync(grid, {
      includeColumnHeaders: true,
      includeRowHeaders: true
    });
    const reportName = selReport?selReport.reportTitle:'PivotGrid'
    book.sheets[0].name = reportName;
    // save the book
    // console.log(selReport)
    book.saveAsync(reportName + '.xlsx');
  }

  React.useEffect(() => {
    if (treeView && pivot.reportList) {
      setTimeout(() => {
        treeView.collapseToLevel(1);
      }, 500)
    }
  }, [treeView, pivot.reportList])

  React.useEffect(() => {
    if (selectedChange && selectedChange.selectedItem) {
      // if (selReport){
        if (!isDirtyCheck() || selReport && selectedChange.selectedItem.id === selReport.id) {
          selectedReportChange(selectedChange)
        } else {
          alert().fire({
            title: '',
            text: '編集中の内容はクリアされます。よろしいですか？',
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusCancel: true,
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: 'OK'
          })
          .then((result) => {
            if (result.isConfirmed) {
              selectedReportChange(selectedChange)
              setTransitionCheck(false)
            } else {
              treeView.selectedNode = treeView.getNode(selReport)
              // setSelReport(selReport)
            }
          });
          setSelectedChange(null)
        }
      // } else {
      //   selectedReportChange(selectedChange)
      // }
    } else {
      setSelectedChange(null)
    }
  }, [selectedChange])

  const selectedReportChange = (e) => {
    setSelReport(e.selectedItem)
    setSelectedChange(null)
  }

  const isDirtyCheck = () => {
    const dirtyArray = ['graphType', 'nsbFlg', 'nsbOption', 'faretypeConversionFlg', 'taxIncludeFlg', 
                        'productionJudgeFlg', 'voidPrintFlg', 'cnjtktAmountPringFlg', 
                        'displayRowTotalFlg', 'displayRowSubtotalFlg', 'displayColumnTotalFlg', 'displayColumnSubtotalFlg', 
                        'displayComponentRatioFlg', 'displayYoyFlg']
    let isDirty = false
    _.forEach(dirtyArray, (r, i) => {
      isDirty = isDirty || pivot[r].dirty!==''
    })
    let isDefChange = false
    if (selReport) {
      isDefChange = (_.replace(panel.viewDefinition, /\s/g, '') !== _.replace(selReport.viewDefinition, /\s/g, ''))
      console.log(_.replace(panel.viewDefinition, /\s/g, ''))
      console.log(_.replace(selReport.viewDefinition, /\s/g, ''))
    } else {
      isDefChange = (_.replace(panel.viewDefinition, /\s/g, '') !== _.replace(newDef, /\s/g, ''))
      console.log(_.replace(panel.viewDefinition, /\s/g, ''))
      console.log(_.replace(newDef, /\s/g, ''))
    }
    console.log(isDefChange)
    console.log(isDirty)
    return ( isDefChange || isDirty)
  }

  React.useEffect(() => {
    if (selReport && !selReport.isheader) {
      // console.log(selReport)

      setSelectedTab(_.toNumber(selReport.reportTabType))
      panel.viewDefinition = selReport.viewDefinition
      // console.log(panel.valueFields)

      const nsbFlg = selReport.nsbPrintType!=='0'?'1':'0'
      const nsbOption = nsbFlg==='1'?selReport.nsbPrintType:'1'
      const obj = {}
      // console.log(nsbFlg)
      // console.log(nsbOption)
      _.forEach(selReport, (v, k) => {
        if (pivot[k]) {
          obj[k] = {
            ...pivot[k],
            original: v,
            value: v,
            text: v,
          }
          if (k === 'graphType') {
            obj.graphType = {
              ...pivot.graphType,
              original: CHART_TYPE[selReport.graphType].value,
              value: CHART_TYPE[selReport.graphType].value,
              text: CHART_TYPE[selReport.graphType].value,
            }
          }
        }
        if (k === 'nsbPrintType') {
          obj.nsbFlg = {
            ...pivot.nsbFlg,
            original: nsbFlg,
            value: nsbFlg,
            text: nsbFlg,
          }
          obj.nsbOption = {
            ...pivot.nsbOption,
            disabled: nsbFlg==='0',
            original: nsbOption,
            value: nsbOption,
            text: nsbOption,
          }
        }
      })
      obj.viewDefinition = {
        ...pivot.viewDefinition,
        original: panel.viewDefinition,
        value: panel.viewDefinition,
        text: panel.viewDefinition,
        dirty: '',
      }
      // console.log(obj)
      pivotAction.setInputPivot({...obj})
    }
  }, [selReport])

  const updatedView = (p, e) => {
    setNonPivotItem(
          p.filterFields.length < 1
      &&  p.columnFields.length < 1
      &&  p.rowFields.length < 1
      &&  p.valueFields.length < 1
    )
  }

  const gridFormatItem = (g, e) => {
    const item = e.getRow().dataItem
    const bind = e.getColumn().binding
    let tktserial = -1
    let fltserial = -1
    _.forEach(g.engine.rowFields, (r, i) => {
      if (r.binding === 'serialNum') {
        tktserial = i;
      }
      if (r.binding === 'fltTktnoSn') {
        fltserial = i
      }
    })
    _.forEach(g.rowHeaders.rows, (r, i) => {
      if (tktserial > -1) {
        g.rowHeaders.setCellData(i, tktserial, i + 1)
      }
      if (fltserial > -1) {
        g.rowHeaders.setCellData(i, fltserial, i + 1)
      }
    })
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Title
          title={TITLE.pivot.name}
          icon={TITLE.pivot.icon} 
          button={
          <Button
            {...pivot.search}
            className='button-blue ml-auto'
            onClick={(e) => {
              history.push(TITLE.pivotSearch.link, {func: 'pivot', report: selReport})
            }}
          >
            {pivot.search.label}
          </Button>
          }
        />
        <PatternNamePopup 
          popup={popup}
          setPopup={setPopup}
          popupData={popupData}
          setPopupData={setPopupData}
          header='新規レポート登録'
          transitionCheck={() => setTransitionCheck(false)}
        />
        <Card
          root='condition'
          size='md'
          hideClose
          header='データ検索条件'
          subheader={null}
          bodyClass='height-5'
          body={
            <React.Fragment>
              {pivot.condition && pivot.condition.text &&
                <Badge className='badge-color search mr-2'>{`(${dataMapper(RADIOS.condition, 'value', 'name', pivot.condition.text)}) 検索条件 : `}</Badge>
              }
              {createDispSearch(pivot.searchJson).map((r, i) =>
                <Badge key={i} className='badge-color search mr-2'>{r}</Badge>
              )}
            </React.Fragment>
          }
        />
        <Card
          root='max-button-pivot'
          header={null}
          // bodyClass={'vh-75'}
          body={
            <SplitPane split="vertical" className='split-pane-top-bi'>
              <SplitPane split="horizontal" initialSize='20%'
                onChange={(size) => {setReportSelSize(size[0])}}
              >
                <Pane initialSize={reportSelSize}>
                  <Card
                    root='pivot'
                    size='md'
                    hideClose
                    header={null}
                    subheader={null}
                    body={
                      <div className='report-select-tree'>
                        <TreeView
                          className='accordion'
                          autoCollapse={false}
                          collapseOnClick={true}
                          expandOnClick={true}
                          itemsSource={pivot.reportList}
                          displayMemberPath="reportTitle"
                          childItemsPath="items"
                          isContentHtml={true}
                          isAnimated={false}
                          initialized={(control) => setTreeView(control)}
                          selectedItem={selReport}
                          selectedItemChanged={(s, e) => {
                            setSelectedChange(s)
                          }}
                        />
                      </div>
                    }
                  />
                </Pane>
                <Pane className='scroll-x-hidden scroll-y-hidden'>
                  {panel &&
                    <Olap.PivotPanel
                      key="list"
                      id="sample-panel1"
                      itemsSource={panel}
                    >
                    </Olap.PivotPanel>
                  }
                </Pane>
              </SplitPane>
              <Pane>
                <Card
                  root='pivot-main'
                  header={null}
                  body={
                    <SplitPane split="horizontal" onChange={(size) => {pivotAction.setInputPivot({sizeGraph: size})}}>
                      <Pane initialSize={pivot.sizeGraph[0]}>
                        {/* <div key="panel"> */}
                          {panel &&
                            <Olap.PivotPanel
                              id="sample-panel2"
                              key="panel"
                              itemsSource={panel}
                              updatedView={(p, e) => updatedView(p, e)}
                            >
                            </Olap.PivotPanel>
                          }
                        {/* </div> */}
                      </Pane>
                      <Pane>
                        <Card
                          root='max-button'
                          hideClose
                          body={
                            <React.Fragment>
                              <TabPanel
                                className="m-1"
                                selectedIndex={selectedTab}
                                selectedIndexChanged={(e) => {setSelectedTab(e.selectedIndex)}}
                              >
                                <Tab>
                                  <a>表</a>
                                  <div id='grid-tab'>
                                    <Accordion onSelect={
                                      (e) => {
                                        setSelected(e)
                                        setTimeout(() => getGraphHeight(), 50)
                                      }}
                                    >
                                      <BootCard>
                                        <Accordion.Toggle as={Card.Header} className='accordion-button hide' eventKey='open'>
                                        <div className='d-inline-flex align-items-center'>
                                          <span className='material-icons-outlined lg mr-2 right'>arrow_right</span>
                                          <span className='material-icons-outlined lg mr-2 down'>arrow_drop_down</span>
                                          <span>表示オプション</span>
                                        </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey='open'>
                                          <BootCard.Body id='bi-option'>
                                            <PivotDisplay />
                                          </BootCard.Body>
                                        </Accordion.Collapse>
                                      </BootCard>
                                    </Accordion>
                                    <div id="pivot-box-grid" key="grid">
                                      <Olap.PivotGrid
                                        itemsSource={panel}
                                        showRowFieldSort={true}
                                        allowSorting={2}
                                        // SelectionMode={0}
                                        // showMarquee={false}
                                        initialized={(s) => initializePivotGrid(s)}
                                        loadedRows={(s) => loadedGrid(s)}
                                        formatItem={(g, e) => gridFormatItem(g, e)}
                                      />
                                    </div>
                                  </div>
                                </Tab>
                                <Tab>
                                  <a>グラフ</a>
                                  <div>
                                    <Common.Select
                                      attrs={{...pivot.graphType}}
                                      group={{
                                        className: 'width-30 mr-2',
                                      }}
                                      minLength={1}
                                      list={CHART_TYPE}
                                      onChange={(e) => pivotAction.changeInputPivot(e)}
                                    />
                                    <div id="pivot-box-graph" key="graph">
                                      <Olap.PivotChart
                                        showTitle={true}
                                        itemsSource={panel}
                                        initialized={(s) => initializePivotChart(s)}
                                        showLegend="Auto"
                                      >
                                      </Olap.PivotChart>
                                    </div>
                                  </div>
                                </Tab>
                              </TabPanel>
                            </React.Fragment>
                          }
                        />
                      </Pane>
                    </SplitPane>
                  }
                />
              </Pane>
            </SplitPane>
          }
        />
        <Card
          root='button'
          size='lg'
          hideClose
          body={
            <div className='card-buttom-button'>
              <Button
                {...pivot.new}
                className='button-gray ml-auto width-max-content'
                disabled={app.isProcessing}
                onClick={() => execNew()}
              >
                {pivot.new.label}
              </Button>
              <Button
                {...pivot.edit}
                className='button-gray ml-2 width-max-content'
                disabled={app.isProcessing || (selReport && selReport.reportType==='0') || nonPivotItem}
                onClick={() => execEdit()}
              >
                {pivot.edit.label}
              </Button>
              <Button
                {...pivot.copy}
                className='button-gray ml-2 width-max-content'
                disabled={app.isProcessing || !selReport || nonPivotItem}
                onClick={() => execCopy()}
              >
                {pivot.copy.label}
              </Button>
              <Button
                {...pivot.delete}
                className='button-gray ml-2 mr-5 width-max-content'
                disabled={app.isProcessing || !selReport || (selReport && selReport.reportType==='0') || nonPivotItem}
                onClick={() => execDelete()}
              >
                {pivot.delete.label}
              </Button>
              <Button
                {...pivot.print}
                className='button-gray ml-5 width-max-content'
                disabled={app.isProcessing || nonPivotItem}
                onClick={() => execPrint()}
              >
                {pivot.print.label}
              </Button>
              <Button
                {...pivot.excel}
                className='button-gray ml-2 width-max-content'
                disabled={app.isProcessing || selectedTab!==0 || nonPivotItem}
                onClick={() => execExcel()}
              >
                {pivot.excel.label}
              </Button>
            </div>}
        />
      </Container>
      <Common.RouteLeavingGuard
        when={true}
        navigate={(path, state) => history.push(path, state)}
        shouldBlockNavigation={(location) => {
          if (!transitionCheck) return
          return (!location.state || !(location.state && location.state.nonRouteLeavingGuard))
        }}
        uiState='Pivot'
      /> 
    </React.Fragment>
  )
    
}
