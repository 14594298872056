import { handleActions, createAction } from 'redux-actions'
import { onChangeInput, initState } from 'lib'
import _ from 'lodash';

// const
export const TKT_EXPORT = {
  CHANGE_INPUT_TKT_EXPORT : 'CHANGE_INPUT_TKT_EXPORT',
  SET_INPUT_TKT_EXPORT : 'SET_INPUT_TKT_EXPORT',
  ADD_TKT_EXPORT_ITEM : 'ADD_TKT_EXPORT_ITEM',
  REMOVE_TKT_EXPORT_ITEM : 'REMOVE_TKT_EXPORT_ITEM',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
};

// action
export const TktExportAction = {
  changeInputTktExport: createAction(TKT_EXPORT.CHANGE_INPUT_TKT_EXPORT, (args) => (args)),
  setInputTktExport: createAction(TKT_EXPORT.SET_INPUT_TKT_EXPORT, (args) => (args)),
  addTktExportItem: createAction(TKT_EXPORT.ADD_TKT_EXPORT_ITEM, (args) => (args)),
  removeTktExportItem: createAction(TKT_EXPORT.REMOVE_TKT_EXPORT_ITEM, (args) => (args)),
  initSession: createAction(TKT_EXPORT.INIT_SESSION),
  clearSession: createAction(TKT_EXPORT.CLEAR_SESSION),
}

// initialState
const initialState = {
  // 検索
  sizeHorizontal: ['100%', '1'],
  sizeVertical: ['20%', '1'],
  sizeGraph: ['200px', '1'],
  condition: {
    id: 'condition',
    label: '',
    required: true,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  checkedItems: [
    
  ],
  checkedItemsSetting: [
  ],
  recentlyCondition: [],
  conditionPattern: [],
  patternList: [],
  patternName: {
    id: 'patternName',
    label: '名称',
    required: true,
    disabled: false,
    minLength: 0,
    maxLength: 30,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  searchUnit: {
    id: 'searchUnit',
    label: '',
    prefix: '表示単位',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  condClear: {
    id: 'clear',
    label: <span><span className='material-icons-outlined md'>delete_forever</span>検索条件全クリア</span>,
    disabled: false,
  },
  condEdit: {
    id: 'edit',
    label: <span><span className='material-icons-outlined md'>cloud_upload</span>検索条件パターン登録</span>,
    disabled: false,
  },
  search: {
    id: 'search',
    label: <span><span className='material-icons-outlined md'>search</span>発券データ検索</span>,
    disabled: false,
  },
  // 一覧表示
  dispCheckedItems: [
    {
      id: 'tktTktnoAl',
      header: 'TKT NO.（AIRLINEコード）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktTktnoSn',
      header: 'TKT NO.（S/N）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktPaxName',
      header: 'PAX NAME',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktStatus',
      header: 'TKTステータス',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktIsuDate',
      header: '発券日',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktDepDate',
      header: '出発日',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktDetailIssuedFlg',
      header: 'チケット明細発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
    },
  ],
  bulk: {},
  exec: {
    id: 'exec',
    label: <span><span className='material-icons-outlined md'>done</span>設定</span>,
    disabled: false,
  },
  research: {
    id: 'research',
    label: <span><span className='material-icons-outlined md'>search</span>再検索</span>,
    disabled: false,
  },
  // --
  label: [
    '出力フォーマット',
    '出力ファイル名',
    'ファイル分割',
    '運賃種別PEXのデータ',
    'GROSSとNETが同額の場合',
    '出力ファイル方式',
    'ファイル出力パス',
    'ファイル重複時',
    'ファイル出力パス',
    '券種EMDのデータ'
  ],
  tktOutFormat: {
    id: 'tktOutFormat',
    label: '_none',
    required: false,
    disabled: false,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktOutFileName: {
    id: 'tktOutFileName',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['filename'],
    stringcase: '',
  },
  tktOutPccdiv: {
    id: 'tktOutPccdiv',
    label: '予約OFFICE ID/PCCごとにファイル分割する',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktOutPex: {
    id: 'tktOutPex',
    label: '_none',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktOutGrossNetSame: {
    id: 'tktOutGrossNetSame',
    label: '_none',
    required: false,
    disabled: false,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktOutClientSyncFalg: {
    id: 'tktOutClientSyncFalg',
    label: 'URI-Pro Plus',
    required: false,
    disabled: false,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktOutClientPath: {
    id: 'tktOutClientPath',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['filepath'],
    stringcase: '',
  },
  tktOutClientOutFile: {
    id: 'tktOutClientOutFile',
    label: '_none',
    required: false,
    disabled: false,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktOutClientOutFilePattern: {
    id: 'tktOutClientOutFilePattern',
    label: '_none',
    required: false,
    disabled: false,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktOutS3Flag: {
    id: 'tktOutS3Flag',
    label: 'SFTP転送',
    required: false,
    disabled: false,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktOutS3Path: {
    id: 'tktOutS3Path',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['filepath'],
    stringcase: '',
  },
  tktOutS3OutFile: {
    id: 'tktOutS3OutFile',
    label: '_none',
    required: false,
    disabled: false,
    original: '2',
    value: '2',
    text: '2',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktOutS3OutFilePattern: {
    id: 'tktOutS3OutFilePattern',
    label: '_none',
    required: false,
    disabled: false,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktEmdMpdFlag: {
    id: 'tktEmdMpdFlag',
    label: '_none',
    required: false,
    disabled: false,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  cancel: {
    id: 'cancel',
    label: <span><span className='material-icons-outlined md'>cancel</span>キャンセル</span>,
    disabled: false,
  },
  setting: {
    id: 'setting',
    label: <span><span className='material-icons-outlined md'>file_upload</span>設定</span>,
    disabled: false,
  },
  download: {
    id: 'download',
    label: <span><span className='material-icons-outlined md'>file_download</span>発券データ出力実行</span>,
    disabled: false,
  },
  before: {
    id: 'before',
    label: <span><span className='material-icons-outlined md'>history</span>前回エラーデータ</span>,
    disabled: false,
  },
  csv: {
    id: 'csv',
    label: <span><span className='material-icons-outlined md'>file_download</span>CSV出力</span>,
    disabled: false,
  },
}
// reducer
export const TktExportReducer = handleActions({
  [TktExportAction.changeInputTktExport]: (state, action) => (onChangeInput(state, action.payload)),
  [TktExportAction.setInputTktExport]: (state, action) => ({...state, ...action.payload,}),
  [TktExportAction.addTktExportItem]: (state, action) => ({
    ...state,
    checkedItems: _.concat(state.checkedItems, action.payload)
  }),
  [TktExportAction.removeTktExportItem]: (state, action) => {
    const obj = Object.assign({}, state)
    _.map(state, (v,k) => {
      if (k.startsWith(action.payload[0].key + '_')) delete obj[k]
    })
    return ({
    ...obj,
    checkedItems: _.reject(state.checkedItems, {header: action.payload[0].header})//keyに変更
  })},
  [TktExportAction.initSession]: (state, action) => (initState(initialState)),
  [TktExportAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)
