import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { TreeView } from '@grapecity/wijmo.react.nav';
import _ from 'lodash';
import { TITLE } from 'constants/title';
import { MASTER } from 'constants/master';
import { Card } from 'components';
import { FilePopup } from './modal/FilePopup'
import { DownloadPopup } from './modal/DownloadPopup'
import { Button, Form } from 'react-bootstrap';
import { MasterApi } from 'webapi'

export const MasterListComponent = (props) => {

  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  const [treeView, setTreeView] = React.useState(null)
  React.useEffect(() => {
    if (treeView && MASTER) {
      const array = (_.filter(MASTER, {key: master.type}).length > 0)?MASTER:MASTER[0].items
      treeView.selectedItem = _.filter(array, {key: master.type})[0]
    }
  }, [treeView, master.type])

  const [selectedItem, setSelectedItem] = React.useState(null)

  const selectedItemChanged = (t, e) => {
    setSelectedItem(t.selectedItem)
  }

  React.useEffect(() => {
    if (selectedItem && master.type) {
      if (selectedItem.key !== master.type) {
        const array = (_.filter(MASTER, {key: master.type}).length > 0)?MASTER:MASTER[0].items
        if (treeView) treeView.selectedItem = _.filter(array, {key: master.type})[0]
        history.push(`${TITLE.master.mainLink}/${selectedItem.key}`)
      }
    }
  }, [selectedItem])

  const execCsv = (isCode, type) => {
    appAction.showProcessing({isProcessing: true})
    MasterApi.download(isCode, type, {
      requestCodeName: type.toUpperCase(),
      downloadFileName: `${master.type}.csv`,
    }).then((res) => {
      if (res.payload.messageList) {
        appAction.setMessage({message: res.payload.messageList,})
      } else {
        const bom = (type === 'decode' || type === 'production' || type === 'fare')?'':String.fromCharCode(0xFEFF)
        const blob = new Blob([bom, res.payload], {type: 'text/csv'})
        if (window.navigator.msSaveOrOpenBlob) {
          // for IE,Edge
          window.navigator.msSaveOrOpenBlob(blob, `${type}.csv`);
        } else {
          // for chrome, firefox
          const url = URL.createObjectURL(blob);
          const linkEl = document.createElement('a');
          linkEl.href = url;
          linkEl.setAttribute('download', `${type}.csv`);
          document.body.appendChild(linkEl);
          linkEl.click();
          URL.revokeObjectURL(url);
          linkEl.parentNode.removeChild(linkEl);
        }
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  return (
    <React.Fragment>
      <Card
        root='max-button'
        size='md'
        hideClose
        header={TITLE.master.mainList.header}
        subheader={TITLE.master.mainList.subheader}
        body={
          <TreeView
            autoCollapse={false}
            collapseOnClick={true}
            expandOnClick={true}
            expandOnLoad={true}
            itemsSource={MASTER}
            displayMemberPath="header"
            childItemsPath="items"
            isContentHtml={true}
            isAnimated={false}
            initialized={(control) => setTreeView(control)}
            selectedItemChanged={(t, e) => selectedItemChanged(t, e)}
          />
        }
      />
      <Card 
        size='lg'
        root='button'
        hideClose
        header={null}
        subheader={null}
        body={
          <React.Fragment>
            {master.type !== 'production' &&
            <div className='card-buttom-button'>
              <Button
                {...master.csv}
                className='button-gray mt-auto mr-auto'
                onClick={() => execCsv(master.isCode, master.type)}
                disabled={master.csv.disabled || master.type==='main' || app.isProcessing || props.disabled}
              >
                {master.csv.label}
              </Button>
              <FilePopup
                id='file-popup'
                size='md'
                header='ファイル指定'
                subheader=''
                disabled={master.type==='main' || app.isProcessing || props.disabled}
                body={null}
              />
            </div>
            }
            {master.type === 'production' &&
            <div className='card-buttom-button'>
              <DownloadPopup
                id='download-popup'
                size='md'
                className='button-gray mt-auto mr-auto'
                header='ファイル出力'
                subheader=''
                disabled={master.csv.disabled || master.type==='main' || app.isProcessing || props.disabled}
                body={null}
                onClick={(e) => []}
                alCd=''
                range='3'
              />
              <FilePopup
                id='file-popup'
                size='md'
                header='ファイル指定'
                subheader=''
                disabled={master.type==='main' || app.isProcessing || props.disabled}
                alCd=''
                body={null}
              />
            </div>
            }
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}

