import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';

export const CalcPane = (props) => {

  const tktEdit = useSelector(state => state.TktEdit);
  
  const dispatch = useDispatch();
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);
  const cardNumElm = useRef(null);

  React.useEffect(() => {
    // NR-ID = N
    //   AND 2A NET額 ≠ "" AND 2A NET額 ≠ 0
    //     ->  VC + CAR ≠ ""
    //       ->  VC + CAR
    //     ->  VC + CAR = ""
    //       ->  TOUR CODE
    //   AND 2A NET額 = "" OR 2A NET額 = 0
    //     ->  VC + CAR
	  // 上記以外
    //  ->  TOUR CODE
    if (tktEdit.tktNrid.text && tktEdit.tktNrid.text === 'N') {
      if (tktEdit.tkt2aNet.text && tktEdit.tkt2aNet.text !== 0) {
        if (tktEdit.tktVc.text + tktEdit.tktCar.text) {
          tktEdit.tktItno.text = tktEdit.tktVc.text + tktEdit.tktCar.text
        } else {
          tktEdit.tktItno.text = tktEdit.tktTourCd.text
        }
      } else {
        tktEdit.tktItno.text = tktEdit.tktVc.text + tktEdit.tktCar.text
      }
    } else {
      tktEdit.tktItno.text = tktEdit.tktTourCd.text
    }
  }, [tktEdit.tkt2aNet.text, tktEdit.tktNrid.text, tktEdit.tktVc.text, tktEdit.tktCar.text, tktEdit.tktTourCd.text])

  return (
    <React.Fragment>
      <div className='d-flex'>
        <div className='width-5-9 mr-2'>
          <Common.Number
            attrs={{...tktEdit.tktGsCash}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-5-9 mr-2',
              textwidth: 'width-5-9'
            }}
          />
        </div>
        <div className='width-5-9 mr-2'>
          <Common.Number
            attrs={{...tktEdit.tktGsCredit}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-5-9 mr-2',
              textwidth: 'width-5-9'
            }}
          />
        </div>
        <div className='width-19 mr-2'>
          <div className='d-flex flex-column'>
            <div className='ml-2'>
              <label className={`form-label sm-3 ${tktEdit.tktCreditCom.visible?'visible':'invisible'}`}>
                CARD No.
              </label>
            </div>
            <div className='d-flex flex-row'>
              <Common.Text
                attrs={{...tktEdit.tktCreditCom}}
                onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
                group={{
                  className: 'width-4 mr-0',
                }}
              />
              <Common.Text
                attrs={{
                  ...tktEdit.tktCardNum,
                  visible: tktEdit.tktCreditCom.visible,
                }}
                onBlur={(e) => {
                  e.tktCardNum.text = convert.cardNoMasking(e.tktCardNum.text)
                  tktEditAction.changeInputTktEdit(e)
                  return e.tktCardNum.text
                }}
                isBlurChange = {true}
                group={{
                  className: 'width-15 mr-0',
                }}
                ref={cardNumElm}
              />
            </div>
          </div>
        </div>
        <div className='width-5-9 mr-0'>
          <Common.Number
            attrs={{...tktEdit.tktCommRate}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-7 mr-0',
              textwidth: 'min-width-5-5 width-5-5'
            }}
          />
        </div>
      </div>
      <div className='d-flex'>
          <Common.Number
            attrs={{...tktEdit.tktTaxCash}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-5-9 mr-2',
              textwidth: 'width-5-9'
            }}
          />
          <Common.Number
            attrs={{...tktEdit.tktTaxCredit}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-5-9 mr-2',
              textwidth: 'width-5-9'
            }}
          />
          <div className='width-5-9 mr-2'/>
          <div className='width-9 mr-2'/>
          <Common.Number
            attrs={{...tktEdit.tktCommP}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-5-9 ml-0 mr-0',
              textwidth: 'width-5-9'
            }}
          />
      </div>
      <div className='d-flex'>
          <Common.Number
            attrs={{...tktEdit.tktSrcPrice}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-5-9 mr-2',
              textwidth: 'width-5-9'
            }}
          />
          <Common.Number
            attrs={{...tktEdit.tktSalesAmt}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-5-9 mr-2',
              textwidth: 'width-5-9'
            }}
          />
          <Common.Number
            attrs={{...tktEdit.tktBspP}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-5-9 mr-2',
              textwidth: 'width-5-9'
            }}
          />
          <div className='width-9 mr-2'/>
          <Common.Number
            attrs={{...tktEdit.tktCommAmtCost}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-5-9 ml-0 mr-0',
              textwidth: 'width-5-9'
            }}
          />
      </div>
      <div className='d-flex'>
          <Common.Number
            attrs={{...tktEdit.tktNsb}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-5-9 mr-2',
              textwidth: 'width-5-9'
            }}
          />
          <Common.Number
            attrs={{...tktEdit.tkt2aNet}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-5-9 mr-2',
              textwidth: 'width-5-9'
            }}
          />
          <Common.Number
            attrs={{...tktEdit.tktBspTaxin}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-5-9 mr-2',
              textwidth: 'width-5-9'
            }}
          />
          <div className='width-9 mr-2'/>
          <Common.Number
            attrs={{...tktEdit.tktCommTaxin}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-5-9 mr-0',
              textwidth: 'width-5-9'
            }}
          />
      </div>
      <div className='d-flex'>
          <Common.Text
            attrs={{...tktEdit.tktNrid}}
            onBlur={(e) => {tktEditAction.changeInputTktEdit(e)}}
            group={{
              className: 'min-width-3 width-3 mr-2',
            }}
          />
          <Common.Text
            attrs={{...tktEdit.tktItno}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'min-width-17 width-17 mr-2',
            }}
          />
          <Common.Text
            attrs={{...tktEdit.tktVc}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'min-width-8 width-8 mr-1',
            }}
          />
          <Common.Text
            attrs={{
              ...tktEdit.tktCar,
              visible: tktEdit.tktVc.visible
            }}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'min-width-12 width-12 mr-2',
            }}
          />
          <Common.Text
            attrs={{...tktEdit.tktTourCd}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'min-width-17 width-17 mr-0',
            }}
          />
      </div>
    </React.Fragment>
  )
}