import _ from 'lodash';

export const MASTER = [
  {
    header: '<span class="material-icons-outlined sm mr-1">folder</span>アカウントコード共通',
    key: 'main',
    items: [
      {header: '受注コード(R)', subheader: '', key: 'order'},
      {header: '方面コード(D)', subheader: '', key: 'area'},
      {header: 'セクションコード(S)', subheader: '', key: 'section'},
      {header: 'FREEコード(F)', subheader: '', key: 'free'},
      {header: 'ツアーコード(T)', subheader: '', key: 'tour'},
      {header: 'KBコード1(K)', subheader: '', key: 'kb1'},
      {header: 'KBコード2(B)', subheader: '', key: 'kb2'},
      {header: '入金コード(M)', subheader: '', key: 'pay'},
      {header: 'E/Oコード(E)', subheader: '', key: 'eo'},
    ]
  },
  {header: '法人コード(C)', subheader: '', key: 'company'},
  {header: '担当者コード(A)', subheader: '', key: 'member'},
  {header: 'PRODUCTION', subheader: '', key: 'production'},
  {header: 'DECODE', subheader: '', key: 'decode'},
  {header: '運賃種別判定', subheader: '', key: 'fare'},
];

export const EMPTY_SEARCH = <div key='empty' className='empty-message'>左のテーブル一覧から対象テーブルを選択してください</div>

export const LIST_ITEMS = {
  account: [
    {
      id: '',
      header: '',
      isReadOnly: true,
      isRequired: false,
      width: 33,
      allowResizing: false,
      allowSorting: false,
      align: 'center',
    },
    {
      id: 'cd',
      header: 'コード',
      format: null,
      isReadOnly: false,
      isEditable: false,
      isKey: true,
      isRequired: false,
      orderMaxLength: 12,
      areaMaxLength: 3,
      sectionMaxLength: 6,
      freeMaxLength: 10,
      tourMaxLength: 15,
      kb1MaxLength: 7,
      kb2MaxLength: 7,
      payMaxLength: 1,
      eoMaxLength: 7,
      width: '*',
      minWidth: 50,
      allowSorting: true,
      allowResizing: false,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['required','halfWidth','uniqueGrid'],
    },
    {
      id: 'namej',
      header: '名称',
      format: null,
      isReadOnly: false,
      isRequired: false,
      orderMaxLength: 20,
      areaMaxLength: 16,
      sectionMaxLength: 16,
      freeMaxLength: 20,
      tourMaxLength: 20,
      kb1MaxLength: 20,
      kb2MaxLength: 20,
      payMaxLength: 20,
      eoMaxLength: 20,
      width: '2*',
      minWidth: 50,
      allowSorting: true,
      allowResizing: false,
      bulkEdit: false,
      validtype: [],
    },
  ],
  company: [
    {
      id: '',
      header: '',
      isReadOnly: true,
      isRequired: false,
      width: 33,
      allowResizing: false,
      allowSorting: false,
      align: 'center',
      isHeader: true,
    },
    {
      id: 'comCd',
      header: '法人コード',
      format: null,
      isReadOnly: false,
      isEditable: false,
      isKey: true,
      maxLength: 10,
      width: 110,
      minWidth: 110,
      allowSorting: true,
      allowResizing: false,
      stringcase: 'upper',
      validtype: ['required','halfWidth','uniqueGrid'],
      isHeader: true,
    },
    {
      id: 'comNamej',
      header: '法人名',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 40,
      width: '3*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: true,
    },
    {
      id: 'comSecNamej',
      header: '部署名',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 30,
      width: '3*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: true,
    },
    {
      id: 'comTel',
      header: '電話番号',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 14,
      width: 130,
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: ['halfWidth'],
      isHeader: true,
    },
    {
      id: 'comKbn',
      header: '法人区分',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 1,
      width: 110,
      minWidth: 110,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: true,
    },
    {
      id: 'comCommtax',
      header: '消費税対応区分',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 1,
      width: 90,
      minWidth: 90,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: true,
    },
    {
      id: 'detailButton',
      header: '詳細',
      format: null,
      isReadOnly: true,
      maxLength: 10,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      align: 'center',
      isHeader: true,
    },
    {
      id: 'comNamee',
      header: '',
      format: null,
      isReadOnly: false,
      maxLength: 1,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: false,
    },
    {
      id: 'comSecNamee',
      header: '',
      format: null,
      isReadOnly: false,
      maxLength: 1,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: false,
    },
    {
      id: 'comPostno',
      header: '',
      format: null,
      isReadOnly: false,
      maxLength: 1,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: false,
    },
    {
      id: 'comAdddivj',
      header: '',
      format: null,
      isReadOnly: false,
      maxLength: 1,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: false,
    },
    {
      id: 'comAdd1j',
      header: '',
      format: null,
      isReadOnly: false,
      maxLength: 1,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: false,
    },
    {
      id: 'comAdd2j',
      header: '',
      format: null,
      isReadOnly: false,
      maxLength: 1,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: false,
    },
    {
      id: 'comAdd1e',
      header: '',
      format: null,
      isReadOnly: false,
      maxLength: 1,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: false,
    },
    {
      id: 'comAdd2e',
      header: '',
      format: null,
      isReadOnly: false,
      maxLength: 1,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: false,
    },
    {
      id: 'comFax',
      header: '',
      format: null,
      isReadOnly: false,
      maxLength: 1,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: false,
    },
  ],
  commH: [
    {
      id: 'commhComCd',
      header: '法人コード',
      format: null,
      isReadOnly: true,
      maxLength: 10,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: true,
    },
    {
      id: 'commhCls',
      header: '',
      format: null,
      isReadOnly: true,
      maxLength: 10,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: true,
    },
    {
      id: 'commhKbn',
      header: '',
      format: null,
      isReadOnly: true,
      maxLength: 10,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: true,
    },
    {
      id: 'commhRate',
      header: '',
      format: null,
      isReadOnly: true,
      maxLength: 10,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: true,
    },
    {
      id: 'commhSum',
      header: '',
      format: null,
      isReadOnly: true,
      maxLength: 10,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: true,
    },
  ],
  commB: [
    {
      id: '',
      header: '',
      isReadOnly: true,
      isRequired: false,
      width: 33,
      allowResizing: false,
      allowSorting: false,
      align: 'center',
      isHeader: true,
    },
    {
      id: 'commbComCd',
      header: '法人コード',
      format: null,
      isReadOnly: true,
      maxLength: 10,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: false,
    },
    {
      id: 'commbCls',
      header: '',
      format: null,
      isReadOnly: true,
      maxLength: 10,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: false,
    },
    {
      id: 'commbSeq',
      header: '',
      format: null,
      isReadOnly: true,
      maxLength: 10,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: false,
    },
    {
      id: 'commbCommRate',
      header: 'COMM率',
      dataType: 'Number',
      number: true,
      format: 'n1',
      isReadOnly: false,
      isRequired: false,
      maxLength: 4,
      width: '*',
      minWidth: 10,
      min: 0,
      max: 99.9,
      allowSorting: true,
      allowResizing: false,
      validtype: ['magnitude'],
      isMagLarge: true,
      isHeader: true,
    },
    {
      id: 'commbCustRate',
      header: '得意先%',
      dataType: 'Number',
      format: 'n2',
      isReadOnly: false,
      isRequired: false,
      maxLength: 5,
      width: '*',
      minWidth: 10,
      min: 0,
      max: 99.99,
      allowSorting: true,
      allowResizing: false,
      validtype: ['magnitude'],
      isMagSmall: true,
      isHeader: true,
    },
    {
      id: 'commbOwnRate',
      header: '自社%',
      dataType: 'Number',
      format: 'n2',
      isReadOnly: true,
      maxLength: 5,
      width: '*',
      minWidth: 10,
      min: 0,
      max: 99.99,
      allowSorting: true,
      allowResizing: false,
      validtype: [],
      isHeader: true,
    },
  ],
  member: [
    {
      id: '',
      header: '',
      isReadOnly: true,
      isRequired: false,
      width: 33,
      allowResizing: false,
      allowSorting: false,
      align: 'center',
      isHeader: true,
    },
    {
      id: 'mbrCd',
      header: '担当者コード',
      format: null,
      isReadOnly: false,
      // isEditable: false,
      isKey: true,
      maxLength: 6,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      stringcase: 'upper',
      validtype: ['required','halfWidth','uniqueGrid'],
      isHeader: true,
    },
    {
      id: 'mbrNamej',
      header: '担当者名',
      format: null,
      isRequired: false,
      isReadOnly: false,
      maxLength: 20,
      width: '2*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      isHeader: true,
    },
    {
      id: 'mbrNamee',
      header: '担当者名(英字)',
      format: null,
      isRequired: false,
      isReadOnly: false,
      maxLength: 10,
      width: '2*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      stringcase: '',
      validtype: ['halfWidth'],
      isHeader: true,
    },
    {
      id: 'mbrSecCd1',
      header: '部署コード',
      format: null,
      isRequired: false,
      isReadOnly: false,
      maxLength: 20,
      width: '*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      isHeader: true,
    },
    {
      id: 'mbrRemarks',
      header: '備考',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 20,
      width: '2*',
      minWidth: 10,
      allowSorting: true,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      isHeader: true,
    },
    {
      id: 'authButton',
      header: '権限',
      format: null,
      isReadOnly: false,
      maxLength: 10,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      align: 'center',
      isHeader: true,
    },
    {
      id: 'mbrCom01',
      header: '発券データ一覧CSV出力',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      isDetail: true,
    },
    {
      id: 'mbrCom02',
      header: '発券データ出力',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      isDetail: true,
    },
    {
      id: 'mbrCom03',
      header: 'EXCELデータ出力',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      isDetail: true,
    },
    {
      id: 'mbrCom04',
      header: 'URI-Pro BI',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      isDetail: true,
    },
    {
      id: 'mbrCom08',
      header: 'テーブルメンテナンス(担当者)',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      isDetail: true,
    },
    {
      id: 'mbrCom09',
      header: 'テーブルメンテナンス(その他)',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      isDetail: true,
    },
    {
      id: 'mbrCom10',
      header: 'タイマー設定',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      isDetail: true,
    },
    {
      id: 'mbrCom11',
      header: 'システムメンテナンス',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      isDetail: true,
    },
    {
      id: 'mbrCom14',
      header: 'PRISMデータ出力',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      isDetail: true,
    },
  ],
  production: [
    {
      id: '',
      header: '',
      isReadOnly: true,
      isRequired: false,
      width: 33,
      allowResizing: false,
      allowSorting: false,
      align: 'center',
      tblType1: true,
      tblType2: true,
      tblType3: true,
      tblType4: true,
    },
    {
      id: 'pdtFltNo',
      header: 'FLT NO.',
      format: null,
      isReadOnly: false,
      maxLength: 5,
      width: '2*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: 'upper',
      validtype: ['required','num'],
      tblType1: true,
      tblType3: true,
      void: true,
    },
    {
      id: 'pdtCityFrom',
      header: '区間 FROM',
      format: null,
      isReadOnly: false,
      isEitherReq: true,
      maxLength: 3,
      width: '3*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: 'upper',
      validtype: ['eitherReqGrid','alphanum'],
      tblType2: true,
      tblType4: true,
      void: true,
    },
    {
      id: 'pdtCityTo',
      header: '区間 TO',
      format: null,
      isReadOnly: false,
      isEitherReq: true,
      maxLength: 3,
      width: '3*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: 'upper',
      validtype: ['eitherReqGrid', 'alphanum'],
      tblType2: true,
      tblType4: true,
      void: true,
    },
    {
      id: 'pdtKb',
      header: 'KB金額',
      format: null,
      dataType: 'Number',
      number: true,
      isReadOnly: false,
      isRequired: false,
      maxLength: 10,
      min: 0,
      max: 99999999,
      width: '3*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      void: true,
    },
    {
      id: 'pdtTktKbn',
      header: '券種',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: null,
      width: '3*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      tblType3: true,
      tblType4: true,
      void: true,
    },
    {
      id: 'pdtClass',
      header: 'クラス',
      format: null,
      isReadOnly: false,
      maxLength: 1,
      width: '2*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: 'upper',
      validtype: ['alpha'],
      tblType1: true,
      tblType2: true,
      void: true,
    },
    {
      id: 'pdtDateFrom',
      header: '適用期間FROM',
      format: 'MM/dd',
      dataType: 'String',
      date: true,
      isReadOnly: false,
      maxLength: null,
      width: '3*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      void: true,
    },
    {
      id: 'pdtDateTo',
      header: '適用期間TO',
      format: 'MM/dd',
      dataType: 'String',
      date: true,
      isReadOnly: false,
      maxLength: null,
      width: '3*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      void: true,
    },
    {
      id: 'pdtPaxAdult',
      header: 'AD',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      void: true,
    },
    {
      id: 'pdtPaxChild',
      header: 'CH',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      void: true,
    },
    {
      id: 'pdtPaxInfant',
      header: 'IN',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      void: true,
    },
    {
      id: 'pdtWeekMon',
      header: '月',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      void: true,
    },
    {
      id: 'pdtWeekTue',
      header: '火',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      void: true,
    },
    {
      id: 'pdtWeekWed',
      header: '水',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      void: true,
    },
    {
      id: 'pdtWeekThu',
      header: '木',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      void: true,
    },
    {
      id: 'pdtWeekFri',
      header: '金',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      void: true,
    },
    {
      id: 'pdtWeekSat',
      header: '土',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      void: true,
    },
    {
      id: 'pdtWeekSun',
      header: '日',
      format: null,
      dataType: 'Boolean',
      checkbox: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      tblType1: true,
      tblType2: true,
      void: true,
    },
  ],
  decode: [
    {
      id: '',
      header: '',
      isReadOnly: true,
      isRequired: false,
      width: 33,
      allowResizing: false,
      allowSorting: false,
      align: 'center',
    },
    {
      id: 'dcdAlnCd',
      header: 'AIRLINEコード',
      format: null,
      isReadOnly: false,
      isEditable: false,
      isKey: true,
      isRequired: false,
      maxLength: 3,
      width: '2*',
      minWidth: 50,
      allowSorting: true,
      allowResizing: false,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['required','halfWidth','uniqueGrid'],
    },
    {
      id: 'dcdValidDate',
      header: '適用開始日',
      format: 'yyyy/MM/dd',
      dataType: 'Date',
      date: true,
      isReadOnly: false,
      isEditable: false,
      isKey: true,
      isRequired: false,
      maxLength: null,
      width: '3*',
      minWidth: 50,
      allowSorting: true,
      allowResizing: false,
      bulkEdit: false,
      validtype: ['required', 'uniqueGrid'],
    },
    {
      id: 'dcdCngCd0',
      header: '0',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['required'],
    },
    {
      id: 'dcdCngCd1',
      header: '1',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['required'],
    },
    {
      id: 'dcdCngCd2',
      header: '2',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['required'],
    },
    {
      id: 'dcdCngCd3',
      header: '3',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['required'],
    },
    {
      id: 'dcdCngCd4',
      header: '4',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['required'],
    },
    {
      id: 'dcdCngCd5',
      header: '5',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['required'],
    },
    {
      id: 'dcdCngCd6',
      header: '6',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['required'],
    },
    {
      id: 'dcdCngCd7',
      header: '7',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['required'],
    },
    {
      id: 'dcdCngCd8',
      header: '8',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['required'],
    },
    {
      id: 'dcdCngCd9',
      header: '9',
      format: null,
      isReadOnly: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['required'],
    },
  ],
  fare: [
    {
      id: '',
      header: '',
      isReadOnly: true,
      isRequired: false,
      width: 33,
      allowResizing: false,
      allowSorting: false,
      align: 'center',
    },
    {
      id: 'fjgRegNo',
      header: '登録番号',
      dataType: 'String',
      format: null,
      isReadOnly: false,
      isKey: true,
      maxLength: 4,
      min: 0,
      max: 9999,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: 'upper',
      validtype: ['required','num','uniqueGrid'],
    },
    {
      id: 'fjgSpcMet',
      header: '指定方法',
      format: null,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '2*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: ['required'],
    },
    {
      id: 'fjgChkStr',
      header: 'チェック文字',
      format: null,
      isReadOnly: false,
      maxLength: 15,
      width: '3*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: 'upper',
      validtype: ['required','halfWidth'],
    },
    {
      id: 'fjgStartDgt',
      header: '開始桁',
      dataType: 'Number',
      number: true,
      format: 'd',
      isRequired: false,
      isReadOnly: false,
      // isEitherReq: true,
      maxLength: 2,
      min: 1,
      max: 15,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: ['num'],
    },
    {
      id: 'fjgEndDgt',
      header: '終了桁',
      dataType: 'Number',
      number: true,
      format: 'd',
      isRequired: false,
      isReadOnly: true,
      // isEitherReq: true,
      maxLength: 2,
      min: 1,
      max: 15,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: ['num'],
    },
    {
      id: 'fjgAplFrtype',
      header: '運賃種別',
      format: null,
      isRequired: false,
      isReadOnly: false,
      maxLength: 3,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: ['required'],
    },
    {
      id: 'fjgFromDate',
      header: '適用期間FROM',
      format: 'yyyy/MM/dd',
      dataType: 'Date',
      date: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '2*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'fjgToDate',
      header: '適用期間TO',
      format: 'yyyy/MM/dd',
      dataType: 'Date',
      date: true,
      isRequired: false,
      isReadOnly: false,
      maxLength: null,
      width: '2*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
    },
  ],
}
export const DECODE_MAP = [
  {id: 'A', name: 'A'},
  {id: 'E', name: 'E'},
  {id: 'G', name: 'G'},
  {id: 'H', name: 'H'},
  {id: 'L', name: 'L'},
  {id: 'P', name: 'P'},
  {id: 'S', name: 'S'},
  {id: 'T', name: 'T'},
  {id: 'V', name: 'V'},
  {id: 'X', name: 'X'},
]
export const PDT_FARE_ID = [
  {name: 'NOM', value: 'NOM'},
  {name: 'PEX', value: 'PEX'},
  {name: 'GIT', value: 'GIT'},
  {name: 'IIT', value: 'IIT'},
  {name: 'OTH', value: 'OTH'},
  {name: 'CHT', value: 'CHT'},
]
export const TBL_TYPE = [
  {name: '抽出区間', value: '2'},
  {name: '抽出FLT NO.', value: '1'},
  {name: '除外区間', value: '4'},
  {name: '除外FLT NO.', value: '3'},
]
export const TKTKBN_MAP = [
  {id: '', name: '無指定'},
  {id: '0', name: '他社券'},
  {id: '1', name: '自社券'},
]
export const SPC_MAP = [
  {id: '0', name: '完全一致'},
  {id: '1', name: '部分一致'},
  {id: '2', name: '位置・文字'},
]
export const FARE_MAP = [
  {id: 'NOM', name: 'NOM'},
  {id: 'PEX', name: 'PEX'},
  {id: 'GIT', name: 'GIT'},
  {id: 'IIT', name: 'IIT'},
  {id: 'OTH', name: 'OTH'},
  {id: 'CHT', name: 'CHT'},
]
export const AUTH_MAP = [
  {id: 'mbrCom01', name: '発券データ一覧CSV出力', selected: false},
  {id: 'mbrCom02', name: '発券データ出力', selected: false},
  {id: 'mbrCom03', name: 'EXCELデータ出力', selected: false},
  {id: 'mbrCom04', name: 'URI-Pro BI', selected: false},
  {id: 'mbrCom08', name: 'テーブルメンテナンス(担当者)', selected: false},
  {id: 'mbrCom09', name: 'テーブルメンテナンス(その他)', selected: false},
  {id: 'mbrCom10', name: 'タイマー設定', selected: false},
  {id: 'mbrCom11', name: 'システムメンテナンス', selected: false},
  {id: 'mbrCom14', name: 'PRISMデータ出力', selected: false},
]
export const COM_KBN_MAP = [
  {value: '0', name: '一般'},
  {value: '1', name: '旅行会社'},
]
export const COM_TAX_MAP = [
  {value: '1', name: 'する'},
  {value: '0', name: 'しない'},
]
export const COMMH_KBN_MAP = [
  {value: '1', name: '定率'},
  {value: '2', name: '定額'},
  {value: '0', name: 'COMM率リンク'},
]
export const COMMH_CLS_MAP = [
  {value: '0', name: 'NOM'},
  {value: '4', name: 'PEX'},
  {value: '1', name: 'GIT/IIT'},
  {value: '2', name: 'OTHER'},
  {value: '3', name: 'CHT'},
]