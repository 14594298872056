import _ from 'lodash';

export const PRINT_MESSAGE = {
  header: '固定帳票出力',
  processingTitle:'固定帳票出力中',
  processingMessage1: '出力結果は、画面を閉じても後で確認が可能です',
  processingMessage2: '',
  resultTitle: '固定帳票出力の処理が完了しました',
  resultMessage1: '',
  resultMessage2: '',
}
export const ACCOUNT_CODE_TYPE = [
  {name: '　', value: '0'},
  {name: '受注コード(R)', value: '1'},
  {name: '法人コード(C)', value: '2'},
  {name: 'FREEコード(F)', value: '3'},
  {name: '入金コード(M)', value: '4'},
  {name: 'ツアーコード(T)', value: '5'},
  {name: 'E/Oコード(E)', value: '6'},
  {name: '方面コード(D)', value: '7'},
  {name: 'セクションコード(S)', value: '8'},
  {name: '担当者コード(A)', value: '9'},
  {name: 'KBコード1(K)', value: '10'},
  {name: 'KBコード2(B)', value: '11'},
]
export const OUTPUT_TYPE = [
  {name: '表示項目で出力', value: '0'},
  {name: '全項目で出力', value: '1'},
]
export const OUTPUT_LOGO = [
  {name: 'ロゴ出力する', value: '1'},
  {name: 'ロゴ出力しない', value: '0'},
]
export const OUTPUT_REPORT = [
  {name: '帳票形式', value: '0'},
  {name: 'テキスト形式', value: '1'},
]
export const OUTPUT_FLAG = [
  {name: '印刷(URI-Pro Plus)', value: '1'},
  {name: 'ファイル出力', value: '2'},
]
