import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';

import * as Common from 'components/common';
import { Card } from 'components';
import {
  OverlayTrigger,
  Tooltip,
  Col,
  Row,
  Container,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import { MultiRow } from '@grapecity/wijmo.react.grid.multirow';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { Selector } from "@grapecity/wijmo.grid.selector";
import { CollectionView, setSelectionRange } from "@grapecity/wijmo";
import { CellRange, DataMap } from "@grapecity/wijmo.grid";
import { InputDate, InputMask, ComboBox, AutoComplete, InputColor, InputNumber } from '@grapecity/wijmo.input';
import { TabPanel, Tab } from '@grapecity/wijmo.react.nav';
import { convert, entryCheck, alert, validation } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { LIST_ITEMS, PDT_FARE_ID, TBL_TYPE, TKTKBN_MAP } from 'constants/master';
import { MasterApi } from 'webapi'
import { DownloadPopup } from './modal/DownloadPopup'

export const ProductionSearchComponent = (props) => {
  
  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  const [itemChanged, setItemChanged] = React.useState(false)
  const [grid, setGrid] = React.useState(null)
  const [selector, setSelector] = React.useState(null)
  const [selectedItems, setSelectedItems] = React.useState(null)
  const [action, setAction] = React.useState('')
  const [rowHeaderWidth, setRowHeaderWidth] = React.useState(0)
  const [colWidth, setColWidth] = React.useState([])
  const [selectedAls, setSelectedAls] = React.useState([])
  const [fareId, setFareId] = React.useState('NOM')
  const [tblType, setTblType] = React.useState('2')
  const [addRow, setAddRow] = React.useState(-1)

  const getEditable = () => {
    return selectedAls.length > 0 && !props.disabled;
  }

  React.useEffect(() => {
    appAction.setMessage('')
    if (selectedAls.length > 0) 
      getData(master.isCode, master.type, selectedAls[0].alnCd2)
    else {
      const obj = {}
      obj.selDate = null
      _.forEach(PDT_FARE_ID, (fare, j) => {
        obj[fare.value] = {}
        _.forEach(TBL_TYPE, (tbl, k) => {
          obj[fare.value][`tbl${tbl.value}`] = []
        })
      })
      appAction.setMessage('')
      masterAction.setInputMaster({...obj})
    }
  }, [selectedAls])

  const itemFormatter = (panel, r, c, cell) => {
    // 列ヘッダは中央
    if (panel.cellType == 2) {
      cell.style.textAlign = 'center';
    }
  }
  
  const getData = (isCode, type, alnCd2) => {
    appAction.showProcessing({isProcessing: true})
    appAction.setMessage('')

    MasterApi.search(isCode, type, {requestCodeName: type.toUpperCase(), search:{pdtAlnCd: alnCd2}})
    .then(res => {

      const obj = {}
      appAction.setMessage({message: res.payload.messageList})
      _.forEach(master, (v, k) => {
        if (k.endsWith('List')) obj[k] = []
      })

      if (res.payload.code === '000' || res.payload.code === '100') {
        obj.selDate = res.payload.selDate
        let dataList = [].concat(res.payload.mstProductionList)
        _.forEach(dataList,(r, i) => {
          _.forEach(_.filter(LIST_ITEMS.production, 'date'), (record, index) => {
            r[record.id] = convert.toDateString(r[record.id], 'MM/dd')
          })
          _.forEach(_.filter(LIST_ITEMS.production, 'checkbox'), (record, index) => {
            r[record.id] = r[record.id] === '1'
          })
        })
        _.forEach(PDT_FARE_ID, (fare, j) => {
          obj[fare.value] = {}
          _.forEach(TBL_TYPE, (tbl, k) => {
            obj[fare.value][`tbl${tbl.value}`] = [].concat(
              _.filter(dataList, (data, index) => {
                return data.pdtFareId === fare.value && data.pdtTblType === tbl.value
              })
            )
          })
        })
      }
      // console.log(obj)
      masterAction.setInputMaster({...obj})
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  React.useEffect(() => {
    appAction.setMessage('')
    const obj = {}
    _.forEach(master, (v, k) => {
      if (k.endsWith('List')) obj[k] = []
    })
    masterAction.setInputMaster({...obj})
    if (grid) {
      grid.select(-1, -1)
      setTimeout(() => {
        (grid.rows.length > 0)?grid.select(0, 0):grid.select(-1, 0)
      })
    }
  }, [master.type])

  // React.useEffect(() => {
  //   grid && grid.deferUpdate(() => {
  //     if (grid && grid.collectionView) grid.collectionView.filters.clear()
  //     _.forEach(filters, (v, k) => {
  //       grid.collectionView.filters.push((item) => {
  //         return v?item[k] === v: true;
  //       });
  //     })
  //     _.forEach(grid && grid.rows, r => {
  //       if (r.dataItem) r.isSelected = (r.dataItem.isSelected===true)
  //     })
  //   })
  //   masterAction.setInputMaster({listChange: true})
  // }, [filters])

  const execDelete = (isCode, type) => {
    let list = []
    _.forEach(PDT_FARE_ID, (fare, j) => {
      _.forEach(TBL_TYPE, (tbl, k) => {
        list = list.concat(master[fare.value][`tbl${tbl.value}`])
      })
    })
    // console.log(list)
    const editList = []
    
    //新規行は強制的に選択状態
    _.forEach(grid.rows, (r, i) => {
      if (r.dataItem && r.dataItem.isAddingNew) {
        r.isSelected = true
        // r.dataItem.isSelected = true
      }
    })
    grid.refresh()

    const selectList = list.filter(r => r.isSelected)
    _.forEach(list.filter(r => r.isSelected), (r, i) =>{
      if (!r.isAddingNew) {
        editList.push({
          ...r,
        })
      }
    })
    if (selectList.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return 
    }
    alert().fire({
      icon: MESSAGE.confirmDeleteList().icon,
      title: MESSAGE.confirmDeleteList(selectList.length).text
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (editList.length > 0) {
          _.forEach(editList, (r, i) => {
            editList[i] = {...r, ...r.original, appCode: 'delete',}
            _.forEach(_.filter(LIST_ITEMS.production, 'date'), (record, index) => {
              if (r[record.id])
                editList[i][record.id] = convert.toDateString(new Date(`2000/${r[record.id]}`), 'yyyy-MM-dd')
            })
            _.forEach(_.filter(LIST_ITEMS.production, 'checkbox'), (record, index) => {
              editList[i][record.id] = r[record.id]?'1':'0'
            })
            _.forEach(_.filter(LIST_ITEMS.production, 'number'), (record, index) => {
              editList[i][record.id] = r[record.id]?r[record.id]:null
            })
          })
          appAction.showProcessing({isProcessing: true})
          MasterApi.edit(isCode, type, {
            requestCodeName: type.toUpperCase(),
            isRequestSearch: true,
            search: {pdtAlnCd: selectedAls[0].alnCd2},
            requestList: editList,
            selDate: master.selDate,
          }, 'edit')
          .then((res) => {
            appAction.setMessage({message: res.payload.messageList})
            if (res.payload.code === '000') {
              const obj = {}
              obj.selDate = res.payload.selDate
              let dataList = [].concat(res.payload.mstProductionList)
              _.forEach(dataList,(r, i) => {
                _.forEach(_.filter(LIST_ITEMS.production, 'date'), (record, index) => {
                  r[record.id] = convert.toDateString(r[record.id], 'MM/dd')
                })
                _.forEach(_.filter(LIST_ITEMS.production, 'checkbox'), (record, index) => {
                  r[record.id] = r[record.id] === '1'
                })
              })
              _.forEach(PDT_FARE_ID, (fare, j) => {
                obj[fare.value] = {}
                _.forEach(TBL_TYPE, (tbl, k) => {
                  obj[fare.value][`tbl${tbl.value}`] = [].concat(
                    _.filter(dataList, (data, index) => {
                      return data.pdtFareId === fare.value && data.pdtTblType === tbl.value
                    })
                  )
                })
              })
              masterAction.setInputMaster({...obj})
            }
          })
          .catch(() =>{})
          .finally(() => {
            appAction.showProcessing({isProcessing: false})
          })
        } else {
          getData(master.isCode, master.type, selectedAls[0].alnCd2)
        }
      }
    });
  }
  
  const execSave = (isCode, type) => {
    let list = []
    _.forEach(PDT_FARE_ID, (fare, j) => {
      _.forEach(TBL_TYPE, (tbl, k) => {
        list = list.concat(master[fare.value][`tbl${tbl.value}`])
      })
    })
    // console.log(list)
    const err = _.filter(list, (r) => r.hasError)

    const editList = []
    _.forEach(list, (r) => {
      if (r.isAddingNew) editList.push({...r, appCode: 'insert'})
      if (!r.isAddingNew) editList.push({...r, appCode: 'update'})
    })
    _.forEach(editList, (r, i) => {
      r['pdtSeq'] = i + 1;
      _.forEach(_.filter(LIST_ITEMS.production, 'date'), (record, index) => {
        if (r[record.id])
          r[record.id] = convert.toDateString(new Date(`2000/${r[record.id]}`), 'yyyy-MM-dd')
      })
      _.forEach(_.filter(LIST_ITEMS.production, 'checkbox'), (record, index) => {
        r[record.id] = r[record.id]?'1':'0'
      })
      _.forEach(_.filter(LIST_ITEMS.production, 'number'), (record, index) => {
        r[record.id] = r[record.id]?r[record.id]:null
      })
    })

    if (err.length > 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.hasError().id,
        message: MESSAGE.hasError().text,
        type: MESSAGE.hasError().icon,
      }]})
      return
    }
    if (editList.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.noTarget().id,
        message: MESSAGE.noTarget().text,
        type: MESSAGE.noTarget().icon,
      }]})
      return
    }

    appAction.showProcessing({isProcessing: true})
    MasterApi.edit(isCode, type, {
      requestCodeName: type.toUpperCase(),
      isUpdateIgnore: true,
      isRequestSearch: true,
      search: {pdtAlnCd: selectedAls[0].alnCd2},
      requestList: editList,
      selDate: master.selDate,
    }, 'edit')
    .then((res) => {
      appAction.setMessage({message: res.payload.messageList})
      if (res.payload.code === '000') {
        const obj = {}
        obj.selDate = res.payload.selDate
        let dataList = [].concat(res.payload.mstProductionList)
        _.forEach(dataList,(r, i) => {
          _.forEach(_.filter(LIST_ITEMS.production, 'date'), (record, index) => {
            r[record.id] = convert.toDateString(r[record.id], 'MM/dd')
          })
          _.forEach(_.filter(LIST_ITEMS.production, 'checkbox'), (record, index) => {
            r[record.id] = r[record.id] === '1'
          })
        })
        _.forEach(PDT_FARE_ID, (fare, j) => {
          obj[fare.value] = {}
          _.forEach(TBL_TYPE, (tbl, k) => {
            obj[fare.value][`tbl${tbl.value}`] = [].concat(
              _.filter(dataList, (data, index) => {
                return data.pdtFareId === fare.value && data.pdtTblType === tbl.value
              })
            )
          })
        })
        masterAction.setInputMaster({...obj})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  const execCsv = () => {
    let list = []
    _.forEach(PDT_FARE_ID, (fare, j) => {
      _.forEach(TBL_TYPE, (tbl, k) => {
        list = list.concat(master[fare.value][`tbl${tbl.value}`])
      })
    })
    // console.log(list)
    const csv = []
    _.forEach(list.filter(r => r.isSelected), (r, i) =>{
      csv.push({
        ...r,
      })
    })
    const upd = _.filter(list, (r, i) => r.isAddingNew || r.isEdit)
    // if (csv.length < 1) {
    //   appAction.setMessage({message: [{
    //     id: MESSAGE.targetNoSelect().id,
    //     message: MESSAGE.targetNoSelect().text,
    //     type: MESSAGE.targetNoSelect().icon,
    //   }]})
    //   return
    // }
    if (upd.length > 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.editedSelection().id,
        message: MESSAGE.editedSelection().text,
        type: MESSAGE.editedSelection().icon,
      }]})
      return false
    }
    return csv
  }

  const execCopy = () =>{
    let list = []
    _.forEach(PDT_FARE_ID, (fare, j) => {
      _.forEach(TBL_TYPE, (tbl, k) => {
        list = list.concat(master[fare.value][`tbl${tbl.value}`])
      })
    })
    // console.log(list)
    const targetList = _.filter(list.filter(r => r.isSelected), r => {
      return r.pdtTblType === tblType && r.pdtFareId === fareId
    })
    let target = null
    if (targetList.length === 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return
    } else if (targetList.length === 1) {
      target = targetList[0]
    } else {
      appAction.setMessage({message: [{
        id: MESSAGE.targetMultiSelect().id,
        message: MESSAGE.targetMultiSelect().text,
        type: MESSAGE.targetMultiSelect().icon,
      }]})
      return
    }
    const newRow = {
      ...target,
      isAddingNew: true,
    }
    grid.collectionView.addNew(newRow)
    setTimeout(() => {
      _.forEach(grid.rows, (r, i) => {
        if (r.dataItem) {
          r.dataItem.isSelected = false
        }
      })
      if (tblType === '2' || tblType === '4') {
        grid.startEditing(false, null, 2)
      } else {
        grid.startEditing(false, null, 1)
      }
    })
  }

  const execInsert = () =>{
    const newRow = {
      isAddingNew: true,
      pdtTblType: tblType,
      pdtFareId: fareId,
      pdtAlnCd: selectedAls[0].alnCd2,
      pdtPaxAdult: '1',
    }
    const addIndex = _.findIndex(grid.collectionView.sourceCollection, grid.collectionView.currentItem)
    const rowIndex = grid.selection.row
    const colIndex = grid.selection.col
    grid.collectionView.sourceCollection.splice(addIndex, 0, newRow)
    grid.collectionView.refresh()
    setTimeout(() => {
      grid.select(rowIndex, colIndex)
      grid.startEditing(false, rowIndex, colIndex)
    })
  }

  const getColumn = () => {
    // const column = _.filter(LIST_ITEMS.production, `tblType${tblType}`)
    const column = LIST_ITEMS.production
    _.forEach(column, (r, i) => {
      r.visible = r[`tblType${tblType}`] === true
      r.void = !(r[`tblType${tblType}`] === true)
      switch (r.id) {
        case 'pdtKb':
          // r.editor = new InputNumber(document.createElement('div'), {
          //   min: r.min,
          //   max: r.max,
          //   gotFocus: (s, e) => {
          //     const length = s.text.length
          //     setTimeout(() => {
          //       setSelectionRange(s.inputElement, length)
          //     })
          //   }
          // });
          break;
        case 'pdtDateFrom':
        case 'pdtDateTo':
          r.editor = new InputMask(document.createElement('div'), {
            mask: '00/00',
            gotFocus: (s, e) => {
              let start = 1, end = 1
              if (!isNaN((new Date(`2000/${s.value}`)).getTime()) || s.value === '__/__') {
                start = 0
                end = 5
              }
              setTimeout(() => {
                setSelectionRange(s.inputElement, start, end)
              })
            }
          });
          break;
        case 'pdtTktKbn':
          r.dataMap = new DataMap(TKTKBN_MAP, 'id', 'name')
          r.editor = new AutoComplete(document.createElement('div'), {
            itemsSource: TKTKBN_MAP,
            isEditable: false,
            minLength: 1,
            delay: 10,
            selectedValuePath: 'id',
            displayMemberPath: 'name'
          })
          break;
        default:
          break;
      }
    })
    return column
  }

  const gridEditEnded = (g, e) => {
    _.forEach(g.rows[e.row].dataItem, (v, k) => {
      if (v === g.getCellData(e.row, e.col) && k.startsWith('pdtDate')) {
        const array = v && v.split('/')
        if (!array || array.length !== 2 || isNaN((new Date(`2000/${array[0]}/${array[1]}`)).getTime())) {
          g.rows[e.row].dataItem[k] = null
        }

      }
    })
  }

  React.useEffect(() => {
    if (addRow > -1) {
      grid.collectionView.currentItem.pdtTblType = tblType
      grid.collectionView.currentItem.pdtFareId = fareId
      grid.collectionView.currentItem.pdtAlnCd = selectedAls[0].alnCd2
      grid.collectionView.currentItem.pdtPaxAdult = '1'
      setAddRow(-1)
    }
  }, [addRow])

  // React.useEffect(() => {
  //   setFilters({
  //     pdtTblType: tblType,
  //     pdtFareId: fareId,
  //   })
  // }, [fareId, tblType])

  return (
    <React.Fragment>
      <Card
        root='max-button'
        size='lg'
        hideClose={!props.isModal}
        onClose={props.isModal?props.onClose:null}
        header={TITLE.master[`${master.type}`].header}
        headerClass={props.isModal?'modal-header':''}
        subheader={TITLE.master[`${master.type}`].subheader}
        body={
          <React.Fragment>
            <div className='list-header production'>
              <div className='d-inline-flex align-items-end'>
                <Common.MultiSelect
                  attrs={{
                    id: 'pdtAlnCd',
                    label: '航空会社',
                    type: 'search',
                    helpertext: '',
                    maxLength: 3,
                    minLength: 1,
                    delay: 10,
                  }}
                  group={{
                    style: {
                      'width': 'auto',
                    },
                    className: 'mx-0 ml-3',
                  }}
                  maxSelectedItems={1}
                  minLength={1}
                  selectedValuePath='alnCd2'
                  displayMemberPath={'listName'}
                  displayName="alnNamej"
                  list={app.alList}
                  searchMemberPath={'alnCd2,alnCdn'}
                  setSelectedItems={setSelectedAls}
                  template={'<table><tr>' +
                            '<td class="width-5" title="コード 2桁">{alnCd2}</td>' +
                            '<td class="width-5" title="コード 数字">{alnCdn}</td>' +
                            '<td class="" title="名称">{alnNamej}</td>' +
                            '</tr></table>'}
                  isEditable={false}
                />
                <div className={`ml-3 mb-1 ${getEditable()?'':'d-none'}`}>
                  <span className='list-count ml-3'>
                    {master[fareId] && master[fareId][`tbl${tblType}`]?master[fareId][`tbl${tblType}`].length:0}
                  </span>
                  <span>件 抽出しました</span>
                </div>
              </div>
              {/* <OverlayTrigger
                placement='right'
                delay={{ show: 0, hide: 400 }}
                overlay={<Tooltip id='refresh-tooltip'>表示内容を破棄し、データを再取得します</Tooltip>}
              >
                <button
                  className='refresh-button wj-btn-glyph'
                  title='データを再取得します'
                  style={{width: rowHeaderWidth}}
                  onClick={(e) => getData(master.type)}
                >
                  <span className="material-icons-outlined">refresh</span>
                </button>
              </OverlayTrigger> */}
              <div className='ml-1 mt-4'>
                <Common.RadioButton
                  btnSize='sm'
                  attrs={{
                    id: 'pdtFareId',
                    text: fareId,
                    value: fareId,
                    disabled: !getEditable(),
                  }}
                  className='mr-5'
                  radios={PDT_FARE_ID}
                  onChange={(e) => setFareId(e.pdtFareId.text)}
                />
                {/* <div className='card-condition-item wrap'> */}
                  <Common.Radio
                    attrs={{
                      id: 'pdtTblType',
                      text: tblType,
                      value: tblType,
                      disabled: !getEditable(),
                    }}
                    radios={TBL_TYPE}
                    onChange={(e) => setTblType(e.pdtTblType.text)}
                  />
                {/* </div> */}
              </div>
            </div>
            <div className='master-list-body production' >
              <Common.Grid
                thisGrid={grid}
                setGrid={setGrid}
                colDef={getColumn()}
                dataList={master[fareId] && master[fareId][`tbl${tblType}`]}
                setRowHeaderWidth={setRowHeaderWidth}
                setColWidth={setColWidth}
                grid={{
                  isReadOnly: !getEditable(),
                  allowAddNew: true,
                  // allowDragging: 'Rows',
                  editEnded: (g, e) => {gridEditEnded(g, e)},
                  rowAdded: (g, e) => {setAddRow(e.row)},
                  itemFormatter: (panel, r, c, cell) => itemFormatter(panel, r, c, cell)
                }}
                setInput={masterAction.setInputMaster}
              />
            </div>
          </React.Fragment>
        }
      />
      <Card 
        size='lg'
        root='button'
        hideClose
        header={null}
        subheader={null}
        body={
          <div className='card-buttom-button'>
            <Button
              {...master.delete}
              className='button-warn mt-auto mr-auto'
              onClick={() => execDelete(master.isCode, master.type)}
              disabled={master.delete.disabled || !getEditable() || app.isProcessing}
            >
              {master.delete.label}
            </Button>
            <Button
              {...master.ins}
              className='button-gray mt-auto ml-auto mr-5'
              onClick={() => execInsert()}
              disabled={master.ins.disabled || !getEditable() || master.editing || app.isProcessing}
            >
              {master.ins.label}
            </Button>
            <Button
              {...master.copy}
              className='button-gray mt-auto mr-auto'
              onClick={() => execCopy()}
              disabled={master.copy.disabled || !getEditable() || master.editing || app.isProcessing}
            >
              {master.copy.label}
            </Button>
            {/* <Button
              {...master.csv}
              className='button-gray mt-auto ml-auto'
              onClick={() => execCsv(master.isCode, master.type)}
              disabled={master.csv.disabled || !getEditable()}
            >
              {master.csv.label}
            </Button> */}
            <DownloadPopup
              id='download-popup'
              size='md'
              // className='button-gray mt-auto ml-auto'
              header='ファイル出力'
              subheader=''
              disabled={master.csv.disabled || !getEditable() || app.isProcessing}
              body={null}
              onClick={(e) => execCsv()}
              alCd={getEditable() && selectedAls[0].alnCd2}
            />
            <Button
              {...master.save}
              className='button-blue mt-auto ml-5'
              onClick={() => execSave(master.isCode, master.type)}
              disabled={master.editing || !getEditable() || app.isProcessing}
            >
              {master.save.label}
            </Button>
          </div>
        }
      />
    </React.Fragment>
  )
}