import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { ObservableArray } from "@grapecity/wijmo";
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import { TicketApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { DETAIL_SETTINGS, INIT_DETAIL_SETTINGS } from 'constants/ticket';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';

export const SettingPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const tktEdit = useSelector(state => state.TktEdit);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  const [list ,setList] = React.useState({
    calc: {},
    fare: {},
    acc: {},
    flight: {},
    tax: {},
    card: {},
    refund: {},
    reissue: {},
    cust: {},
    other: {},
    emd: {},
    add: {},
  })

  const [listbox, setListbox] = React.useState({})

  const initPopup = (form) => {
    props.setPopup(form)
  }

  React.useEffect(() => {
    // console.log(props.popupData)
    if (props.popupData) {
      let obj = {}
      _.forEach(tktEdit.detailSettings, (v, k) => {
        obj[k] = {
          selected: _.filter(tktEdit.editPane, r => r.i===k)[0].isShow?'1':'0',
          items: [],
          dto: v.dto,
        }
        _.forEach(v.items, (r, i) => {
          if (r.name) obj[k].items.push({...r})
        })
      })
      // console.log(obj)
      // if (props.popupData === 'init'){
      // } else {
      //   setList(obj)
      // }
      
      if (props.popupData === 'init') {
        obj = {}
        _.forEach(INIT_DETAIL_SETTINGS, (cVal, category) => {
          obj[category] = {...cVal}
          obj[category].items = []
          _.forEach(cVal.items, (r, i) => {
            // console.log(r)
            obj[category].items.push({
              id: r.id,
              name: r.name,
              odto: r.odto,
              idto: r.idto,
              selected: r.selected
            })
          })
        })
        props.setPopupData('')
        setList(obj)
      } else {
        setList(obj)
      }
    }
  }, [props.popupData])

  const initialized = (s, id) => {
    listbox[id] = s
  }

  const action = (type) => {
    
    appAction.showProcessing({isProcessing: true})
    const dto = {
      tktFareAndCalculateDispItemDto: {},
      tktFareDispItemDto: {},
      accountCodeDispItemDto: {},
      cardDataDispItemDto: {},
      refundDispItemDto: {},
      reissueDispItemDto: {},
      customerDataDispItemDto: {},
      otherDataDispItemDto: {},
      emdDataDispItemDto: {},
    }
    _.forEach(list, (v, k) => {
      dto[v.dto] = v.selected
      _.forEach(v.items, (item, key) => {
        switch (k)  {
          case 'calc': dto.tktFareAndCalculateDispItemDto[item.odto] = item.selected?'1':'0'; break;
          case 'fare': dto.tktFareDispItemDto[item.odto] = item.selected?'1':'0'; break;
          case 'acc': dto.accountCodeDispItemDto[item.odto] = item.selected?'1':'0'; break;
          case 'card': dto.cardDataDispItemDto[item.odto] = item.selected?'1':'0'; break;
          case 'refund': dto.refundDispItemDto[item.odto] = item.selected?'1':'0'; break;
          case 'reissue': dto.reissueDispItemDto[item.odto] = item.selected?'1':'0'; break;
          case 'cust': dto.customerDataDispItemDto[item.odto] = item.selected?'1':'0'; break;
          case 'other': dto.otherDataDispItemDto[item.odto] = item.selected?'1':'0'; break;
          case 'emd': dto.emdDataDispItemDto[item.odto] = item.selected?'1':'0'; break;
        }
      })
      // dto.
    })
    // console.log(dto)
    appAction.setMessage('')
    TicketApi.detailRegistItem({registDispItemDto: dto})
    .then(res => {
      // console.log(res.payload)
      appAction.setMessage(res.payload.messageList)
      if (res.payload.code === '000') {
        // 表示設定
        const obj = {}
        obj.detailSettings = {}
        obj.editPane = []
        _.forEach(DETAIL_SETTINGS, (set, k) => {
          _.forEach(res.payload.displayItemSettingDto.bizTktDetailItemDto, (data, key) => {
            if (key === set.dto) {
              obj.detailSettings[k] = {...set, selected: data}
              const editpane = _.filter(tktEdit.editPane, r => r.i === k)[0]
              obj.editPane.push({
                ...editpane,
                isShow: data==='1',
              })
              
              obj[`${k}DispFlg`] = {
                ...tktEdit[`${k}DispFlg`],
                original: data,
                value: data,
                text: data,
              }
            }
            _.forEach(set.items, (r, i) => {
              if (key === r.idto) {
                obj[r.id] = {
                  ...tktEdit[r.id],
                  visible: data==='1'
                }
                obj.detailSettings[k].items[i] = {
                  ...obj.detailSettings[k].items[i],
                  selected: data==='1',
                }
              }
            })
          })
        })
        // console.log(obj)
        tktEditAction.setInputTktEdit(obj)
        props.popup.dialogResult = obj
        props.popup.hide()

      } else {

      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  // const updateApi = (entry, searchString, id) => {
  //   props.searchApi.findRegistPattern({
  //     patternId: id,
  //     tktSrchptObjectKbn: '2',
  //     tktSrchptPrivForm: props.tktSrchptPrivForm,
  //     tktSrchptName: entry.patternName.text,
  //     searchString: searchString,
  //   })
  //   .then(res => {
  //     console.log(res.payload)
  //     appAction.setMessage('')
  //     if (res.payload.code === '000') {
  //       props.setInput({
  //         // recentlyCondition: getPattern(res.payload.patternMap, '最近使った検索条件'),
  //         // checkedItemsSetting: getPattern(res.payload.patternMap, '前回検索条件'),
  //         patternList: getPatternList(res.payload.patternList),
  //         patternMap: res.payload.patternMap,
  //       })
  //     }
  //   })
  //   .catch(() =>{})
  //  .finally(() => {
  //     appAction.showProcessing({isProcessing: false})
  //     entry.patternName.isInvalid = false
  //     entry.patternName.text = ''
  //     entry.patternName.value = ''
  //     props.setInput(entry)
  //     props.popup.hide()
  //   })
  // }

  // const delPattern = () => {
  //   props.searchApi.findDeletePattern({
  //     tktSrchptId: props.popupData,
  //     // tktSrchptObjectKbn: '2',
  //     tktSrchptPrivForm: props.tktSrchptPrivForm,
  //     // tktSrchptName: entry.patternName.text,
  //     // searchString: searchString,
  //     tktSrchptPrivForm: props.tktSrchptPrivForm,
  //   })
  //   .then(res => {
  //     console.log(res.payload)
  //     appAction.setMessage('')
  //     if (res.payload.code === '000') {
  //       props.setInput({
  //         // recentlyCondition: getPattern(res.payload.patternMap, '最近使った検索条件'),
  //         // checkedItemsSetting: getPattern(res.payload.patternMap, '前回検索条件'),
  //         patternList: getPatternList(res.payload.patternList),
  //         patternMap: res.payload.patternMap,
  //       })
  //     }
  //   })
  //   .catch(() =>{})
  //  .finally(() => {
  //     appAction.showProcessing({isProcessing: false})
  //     props.popup.hide()
  //   })
  // }
  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
      >
        <Card 
          root='popup w-75vw'
          size='lg'
          hideClose
          header={props.header}
          headerClass='wj-dialog-header'
          bodyClass='vh-75'
          subheader={props.subheader}
          // body={props.body}
          body={
            <React.Fragment>
              {_.map(tktEdit.detailSettings, (v, k) => {
                return (
                  <div id='detail-setting' key={k} className={`d-inline-block align-top ${v.className}`}>
                    <Card 
                      size='md'
                      root='max'
                      // bodyClass='height-20'
                      header={
                        <Common.Check
                          // key={calc}
                          attrs={{
                            id: v.id,
                            label: v.label,
                            text: list[k].selected,
                          }}
                          onChange={(e) => {//console.log(e)
                            const obj = {}
                            obj[k] = {
                              ...list[k],
                              selected: e[v.id].text,
                            }
                            setList({...list, ...obj,})
                          }}
                        />}
                        subheader={list[k].items && list[k].items.length > 0 &&
                          <div className='ml-5'>
                            <div
                              className="tooltip-text d-inline-block" 
                              onClick={() => {
                                _.filter(listbox, (s, id) => id===k)[0].checkedItems = list[k].items
                              }}
                            >全選択</div>
                            <div className="d-inline-block mx-2">/</div>
                            <div
                              className="tooltip-text d-inline-block"
                              onClick={() => {
                                _.filter(listbox, (s, id) => id===k)[0].checkedItems = []
                              }}
                            >全解除</div>
                          </div>
                        }
                      hideClose={true}
                      body={
                        <wjInput.ListBox
                          displayMemberPath="name"
                          checkedMemberPath="selected"
                          // checkedItemsChanged={(e) => {console.log(e.checkedItems)}}
                          itemsSource={list[k].items}
                          initialized={(s) => {initialized(s, k)}}
                          className='width-p-100'
                        />
                      }
                    />
                  </div>
                )
              })}
            </React.Fragment>
          }
        />
        <Card 
          size='md'
          root={`popup-button ${props.size}`}
          // body={props.button}
          body={
            <React.Fragment>
              <Button
                className='button-gray ml-auto mr-3'
                onClick={() => {
                  props.setPopupData('')
                  // console.log(tktEdit.detailSettings)
                  // console.log(list)
                  props.setPopupData('init')
                }}
                disabled={app.isProcessing}
              >
                {/* <span className='material-icons-outlined md mr-2'>file_download</span> */}
                初期値に戻す
              </Button>
              <Button className='button-gray wj-hide-cancel mr-3'>
                <span className='material-icons-outlined md mr-2'>cancel</span>
                キャンセル
              </Button>
              <Button
                className='button-gray'
                onClick={() => action()}
                disabled={app.isProcessing}
              >
                <span className='material-icons-outlined md mr-2'>file_upload</span>
                設定
              </Button>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})