import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from 'components';
import * as Common from 'components/common';

import { Button } from 'react-bootstrap';
import _ from 'lodash';
import { RADIOS, CHECKS } from 'constants/search';
// import '../styles/conditionCard.scss'

export const CardCheckInComponent = (props) => {

  // 初期化
  React.useEffect(() => {
    const undefArray = _.filter(props.items, r => !r.text)
    const defOn = (props.condition.key==='comKbn' || props.condition.key==='tktRefClass')
    if (props.items.length === undefArray.length) clear(defOn?'1':'0')
  }, [props.condition.key])
  
  // clear
  const clear = (value) => {
    const obj = []
    if (!props.items[props.items.length-1].value || value==='0') {
      obj[props.items[props.items.length-1].id] = {
        ...props.items[props.items.length-1],
        original: RADIOS.clude[0].value,
        value: RADIOS.clude[0].value,
        text: RADIOS.clude[0].value,
      }
    }
    _.forEach(props.items, (r, i) => {
      if (i !== props.items.length-1) {
        obj[r.id] = {
          ...r,
          original: value,
          value: value,
          text: value,
        }
      }
    })
    Object.keys(obj).length && props.setInput({...obj})
  }

  const getBody = () => {
    const getItem = _.map(props.items, (r, i) => {
      if (i >= props.items.length-1) return
      return (
        <React.Fragment key={i}>
          <Common.Check
            attrs={r}
            onChange={(e) => props.onBlur(e)}
          />
        </React.Fragment>
      )
    })
    return (
      <React.Fragment>
        <div className='card-condition-item'>
          <Common.RadioButton
            btnSize='sm'
            className='min-width-15 width-15 mr-3'
            attrs={props.items[props.items.length-1]}
            radios={RADIOS.clude}
            onChange={(e) => props.onBlur(e)}
          />
          {getItem}
        </div>
        <div className='d-inline-block mb-1'>
          <Button
            id='clear'
            className='button-gray small width-max-content'
            onClick={(e) => clear('0')}
          >
            クリア
          </Button>
        </div>
      </React.Fragment>
    )
  }
  
  return (
    <React.Fragment>
      <Card 
        size={props.cardSize}
        header={props.condition.header}
        subheader={props.condition.subheader}
        body={getBody()}
        condition={props.condition}
        onClose={props.onClose}
      />
    </React.Fragment>
  )
}
