import { handleActions, createAction } from 'redux-actions'

// const
export const LOGOUT = {
  REQUEST_LOGOUT : 'REQUEST_LOGOUT',
  RESULT_LOGOUT : 'RESULT_LOGOUT',
};

// action
export const LogoutAction = {
  requestLogout: createAction(LOGOUT.REQUEST_LOGOUT),
  resultLogout: createAction(LOGOUT.RESULT_LOGOUT, (args) => (args)),
}

// initialState
const initialState = {}
// reducer
export const LogoutReducer = handleActions({
  [LogoutAction.requestLogout]: (state, action) => {
    // console.log('logout')
    return {...state}
  },
  [LogoutAction.resultLogout]: (state, action) => ({...state}),
}, initialState)
