import _ from 'lodash';
import { MIN_DATE_TEXT, MAX_DATE_TEXT } from 'constants/index';

const minDateArray = MIN_DATE_TEXT.split('/')
const maxDateArray = MAX_DATE_TEXT.split('/')

export const HELPER_MESSAGE = {
  required: '必須です。入力してください。',
  requiredSelect: '必須です。選択してください。',
  length: ['', '桁以上', '桁以下', '桁', 'で入力してください。'],
  alpha: '英字で入力してください。',
  alphanum: '英字、数字で入力してください。',
  alphanum_sp: '英字、数字、半角ハイフン(-)、スラッシュ(/)、ピリオド(.)、スペースで入力してください。',
  alphanum_bl: '英字、数字、スペースで入力してください。',
  alphanum_bl_pd: '英字、数字、ピリオド(.)、スペースで入力してください。',
  num: '数字で入力してください。',
  num_hy: '半角数字、半角ハイフン(-)を入力してください。',
  tel: '半角数字、半角ハイフン(-)を入力してください。',
  telSearch: '半角数字、半角ハイフン(-)を入力してください。',
  zipCode: '数字3桁-数字4桁で入力してください。',
  zipCodeSearch: '数字7桁以下で入力してください。',
  date: `値が不正です。 ${MAX_DATE_TEXT} までの日付を入力してください。`,
  mmyy: '月/年で入力してください。',
  mail: 'メールアドレスの形式が正しくありません。',
  fullWidth: '全角で入力してください。',
  halfWidth: '半角英数字、半角記号で入力してください。',
  katakana: '全角カナで入力してください。',
  // password: ['入力された','に使用できない文字が含まれています。使用できるルールについてはパスワードのヘルプを参照してください。'],
  password: '半角英数字、指定された半角記号で入力してください。',
  uniqueGrid: '既に同じデータが存在します。',
  eitherReqGrid: 'いずれかを入力してください。',
  magnitude: '大小関係が正しくありません。',
  filename: 'ファイル名に使用できない文字（\\ / : * ? " < > |）が含まれています。',
  filepath: 'ファイルパスに使用できない文字（: * ? " < > |）が含まれています。',
  // accountNoSelect: {
  //   id: 'MGADM03101E',
  //   message: '削除するアカウント情報が選択されていません。',
  // },
  // terminalNoSelect: {
  //   id: 'MGADM06101E',
  //   message: '撤去する端末情報が選択されていません。',
  // },
  // requestPasswordNoSelect: {
  //   id: 'MGADM12006E',
  //   message: 'パスワード発行対象がありません。',
  // },
  // importFileNoSelect: {
  //   id: 'MGADM08100E',
  //   message: 'ファイルが指定されておりません。',
  // },
  // duplicateAffix: {
  //   id: 'MGADM04101E',
  //   message: '法人区分は前後いずれかを選択してください。',
  // },
  // authenticationError: {
  //   id: 'MGADM01001E',
  //   message: '認証情報がありません。',
  // },
  // relationCompanyDate: {
  //   id: 'MGADM04102E',
  //   message: '適用開始年月日は、適用終了年月日より前の日付を指定してください。',
  // },
  // relationStoreDate: {
  //   id: 'MGADM05102E',
  //   message: '適用開始年月日は、適用終了年月日より前の日付を指定してください。',
  // },
  // relationTerminalDate: {
  //   id: 'MGADM06102E',
  //   message: '端末有効期限(開始)は、端末有効期限(終了)より前の日付を指定してください。',
  // },
}

export const MESSAGE = {
  migration:[
    {message: 'エラーが発生しました。Amadeus社移行担当にお問い合わせください。'},
    {message: '　Amadeus Delivery担当'},
    {message: '　メールアドレスuripro.migration.japan@amadeus.com'},
    {message: '　対応時間 月～金 9:30-17:30土日祝休業'}
  ],
  migrationClosed:[
    {message: 'サーバとの接続が切れましたが処理は継続しています。'},
    {message: 'しばらくお待ちいただき、「リロード」ボタンを押下してください。'},
    {message: '処理が完了していない場合は再度当画面が表示されます。'},
    {message: '移行完了画面(STEP3)が表示されるまで「リロード」をお試しください。'}
  ],
  noTarget:() => ({
    id: '',
    text: '対象データがありません。',
    icon: 'error',
  }),
  hasError:() => ({
    id: '',
    text: '入力内容に誤りがあります。入力項目をご確認ください。',
    icon: 'error',
  }),
  targetNoSelect:() => ({
    id: '',
    text: '対象データを選択して下さい。',
    icon: 'error',
  }),
  targetMultiSelect:() => ({
    id: '',
    text: '複数選択されています。',
    icon: 'error',
  }),
  tableNoSelect:() => ({
    id: '',
    text: 'テーブルタイプを選択して下さい。',
    icon: 'error',
  }),
  noFile:() => ({
    id: '',
    text: 'ファイルを選択して下さい。',
    icon: 'error',
  }),
  confirmDeleteList:(count) => ({
    id: '',
    text: `選択している${count}件のデータを削除します。\nよろしいですか？`,
    icon: 'warning',
  }),
  confirmTktDeleteList:(count) => ({
    id: '',
    text: `選択している${count}件のデータを削除します。\nよろしいですか？\n※当処理によって削除されたデータは<span class="error">復旧できません</span>ので十分注意してください。`,
    icon: 'warning',
  }),
  multipleSelection:() => ({
    id: '',
    text: `1件のみ選択してください。`,
    icon: 'error',
  }),
  editedSelection:() => ({
    id: '',
    text: `編集中のデータがあります。先に登録を行ってください。`,
    icon: 'error',
  }),
  duplicateAirline:() => ({
    id: '',
    text: `同AIRLINE間での複写はできません。`,
    icon: 'error',
  }),
  deleteAdmin:() => ({
    id: '',
    text: `システム担当者は削除できません。`,
    icon: 'error',
  }),
  copyComm:() => ({
    id: '',
    text: `コミッション情報も複写しますか？`,
    icon: 'info',
  }),
  noCondition:() => ({
    id: '',
    text: `検索条件を入力してください。`,
    icon: 'warning',
  }),
  noSearch:() => ({
    id: '',
    text: `検索を行ってください。`,
    icon: 'error',
  }),
  noFileName:() => ({
    id: '',
    text: '出力ファイル名を入力して下さい。',
    icon: 'error',
  }),
  outputNoSelect:() => ({
    id: '',
    text: '出力対象を選択して下さい。',
    icon: 'error',
  }),
  detailOutputNoSelect:() => ({
    id: '',
    text: 'チケット明細の出力対象を選択して下さい。',
    icon: 'error',
  }),
  confirmPatternUpdate:() => ({
    id: '',
    text: `同じパターン名が既に登録されています。\n上書きしてよろしいですか？`,
    icon: 'warning',
  }),
  tktChildNumber:() => ({
    id: '',
    text: `冊内で親と子の関係が不成立です。`,
    icon: 'error',
  }),
  noOutputItem:() => ({
    id: '',
    text: `出力項目が指定されていません。`,
    icon: 'error',
  }),
  noFilePath:() => ({
    id: '',
    text: 'ファイル出力パスを入力して下さい。',
    icon: 'error',
  }),
  noFltseg:() => ({
    id: '',
    text: 'いずれかのFLTセグメントを指定してください。',
    icon: 'error',
  }),
  duplicateItem:(target) => ({
    id: '',
    text: `${target}が重複しています。`,
    icon: 'error',
  }),
  confirmUpdate:(target) => ({
    id: '',
    text: `${target}を更新します。\nよろしいですか？`,
    icon: 'question',
  }),
  confirmDelete:(target) => ({
    id: '',
    text: `${target}を削除します。\nよろしいですか？`,
    icon: 'warning',
  }),
  confirmTktDelete:(target) => ({
    id: '',
    text: `${target}を削除します。\nよろしいですか？\n※当処理によって削除されたデータは<span class="error">復旧できません</span>ので十分注意してください。`,
    icon: 'warning',
  }),
  tktNoFind:() => ({
    id: '',
    text: `該当のTKTはありません。`,
    icon: 'error',
  }),
  duplicateTicket:() => ({
    id: '',
    text: '入力されたTKTNOは既に登録されています',
    icon: 'error',
  }),
  changeFile:() => ({
    id: '',
    text: 'ファイルを再度選択して下さい。',
    icon: 'error',
  }),
  dispLimit:() => ({
    id: '',
    text: '検索結果件数はシステムで設定された表示件数を超過しました。\n続けて表示しますか？',
    icon: 'warning',
  }),
  isuVoidError:() => ({
    id: '',
    text: '発券日がVOID処理日より未来に設定されています。',
    icon: 'error',
  }),
  noSelectError:(label) => ({
    id: '',
    text: label + 'を指定してください。',
    icon: 'error',
  }),
  magnitudeError:(label) => ({
    id: '',
    text: label + 'の大小関係が正しくありません。',
    icon: 'error',
  }),
  invalidRangeError:(label) => ({
    id: '',
    text: '有効期間外です。',
    icon: 'error',
  }),
  dateRangeError:(label, range) => ({
    id: '',
    text: label + 'は' + range + '以内で指定してください。',
    icon: 'error',
  }),
  confirmTimerRelease:() => ({
    id: '',
    text: '指定しているタイマー設定を解除します。\nよろしいですか？',
    icon: 'question',
  }),
  confirmTimerPause:() => ({
    id: '',
    text: '指定しているタイマー設定を一時停止します。\nよろしいですか？',
    icon: 'question',
  }),
  confirmTimerResume:() => ({
    id: '',
    text: '再開後、すぐに実行しますか？\n\n[Yes] すぐに最初の実行を行います\n[No] スケジュールに従って開始します\n[キャンセル] 再開を中止します\n',
    icon: 'question',
  }),
  confirmTimerSetting:() => ({
    id: '',
    text: '設定変更と共にタイマーを再開しますか？',
    icon: 'question',
  }),
  confirmTimerSettingForMaint:() => ({
    id: '',
    text: '設定変更と共にタイマーを再開しますか？\n※指定した条件で、該当する発券データを削除します。\n　当処理によって削除されたデータは<span class="error">復旧できません</span>ので十分注意してください。',
    icon: 'question',
  }),
  errorMaxItem:() => ({
    id: '',
    text: '指定された項目が多いです。最大選択可能項目数は256個です。',
    icon: 'warning',
  }),
  confirmLogout:() => ({
    id: '',
    text: '業務を終了します。編集中の内容はクリアされます。\n別タブで操作中のURI-Proも併せて終了となりますので、該当のタブは手動で閉じてください。',
    icon: 'question',
  }),
  confirmIsMonthlyProcessing:() => ({
    id: '',
    text: '月跨ぎ情報が更新されました。\n既に登録されている発券データに対して月跨ぎフラグの再設定を行いますか？\n（OKを選択した場合、データの更新に時間がかかる場合があります。）',
    icon: 'question',
  }),
  confirmFareUpdate:() => ({
    id: '',
    text: `同じ登録番号が既に登録されています。\n上書きしてよろしいですか？`,
    icon: 'warning',
  }),
  notExistsTicket:(tktno) => ({
    id: '',
    text: `選択された発券データ(${tktno})は存在しません。`,
    icon: 'error',
  }),
  confirmOpenTicket:(tktno) => ({
    id: '',
    text: `${tktno} TKT分の画面を起動しようとしています。\nこのまま、処理を継続してよろしいですか？`,
    icon: 'question',
  }),
  pastDateTime:() => ({
    id: '',
    text: `実行日時が過去日時に設定されています。`,
    icon: 'error',
  }),
  outRangeTimerDate:(month) => ({
    id: '',
    text: `設定可能な抽出開始日時を超えています。過去${month}ヶ月以内を指定してください。`,
    icon: 'error',
  }),
  tktEditValidate:(errorMessages) => ({
    id: '',
    text: errorMessages,
    icon: 'error',
  }),

  // confirmCsvList:(count) => ({
  //   id: '',
  //   text: `選択している${count}件のデータを出力します。\nよろしいですか？`,
  //   icon: 'error',
  // }),
 }