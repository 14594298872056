import React from 'react';
import { Calendar } from '@grapecity/wijmo.react.input';
import reactDom from 'react-dom';
import { 
  Form,
} from 'react-bootstrap';
import _ from 'lodash';

export const Check = React.forwardRef(({...props}, ref) => {

  const {attrs} = props;

  return (
    <React.Fragment>
      <Form.Check
        inline
        // {...attrs}
        className={`${props.className} ${attrs.visible===false?'d-none':''}`}
        label={attrs.label}
        type='checkbox'
        id={attrs.id}
        disabled={attrs.disabled}
        isInvalid={attrs.isInvalid}
        checked={attrs.text==='1'}
        onChange={(e) => {
          const target = {id: attrs.id}
          target[attrs.id] = {
            ...attrs,
            text: e.target.checked?'1':'0',
          }
          props.onChange(target)
        }}
      />
    </React.Fragment>
  )
})
