import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktSearchAction } from 'ducks/TktSearch';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button,} from 'react-bootstrap';
import { Card } from 'components';
import * as Common from 'components/common';
import { TicketApi } from 'webapi'
import { RADIOS } from 'constants/search';
import { FARE_TYPE, TICKET_TYPE, PAX_TYPE, CRS, TKT_OVO_ID } from 'constants/ticket';
import { LIST_ITEMS, FLT_IND_BYTE_TKT } from 'constants/searchResult';
import _ from 'lodash';
import { convert, entryCheck, alert, validation, dataMapper } from "lib";
import { MESSAGE } from 'constants/message';

export const BulkEditPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const tktSearchAction = bindActionCreators(TktSearchAction, dispatch);

  const {popup, setPopup, popupData, setPopupData, search, grid} = props

  const [selected, setSelected] = React.useState([])
  const [element, setElement] = React.useState([])
  const [tktVoidId, setTktVoidId] = React.useState('0')
  const [tktOvoId, setTktOvoId] = React.useState('0')
  const [message, setMessage] = React.useState('')

  const initPopup = (form) => {
    setPopup(form)
  }

  // const onClick = (e) => {
  //   popup.show(true, (sender) => {
  //     if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1)
  //     console.log('ok')
  //     else console.log('cancel')
  //   })
  // }

  React.useEffect(() => {
    // console.log(popupData)
    setMessage('')
    if (popupData) {
      switch (popupData.id) {
        case 'tkt7lOdrCd': setElement({...popupData, lumpUpdateItem: '01', path: 'orderCd', list: app.orderList, element: 'M'}); break;
        case 'tkt7lSecCd': setElement({...popupData, lumpUpdateItem: '02', path: 'sectionCd', list: app.sectionList, element: 'M'}); break;
        case 'tkt7lMbrCd': setElement({...popupData, lumpUpdateItem: '03', path: 'memberCd', list: app.memberList, element: 'M'}); break;
        case 'tkt7lComCd': setElement({...popupData, lumpUpdateItem: '04', path: 'companyCd', list: app.companyList, element: 'M'}); break;
        case 'tkt7lAreaCd': setElement({...popupData, lumpUpdateItem: '05', path: 'areaCd', list: app.areaList, element: 'M'}); break;
        case 'tkt7lFreeCd': setElement({...popupData, lumpUpdateItem: '06', path: 'freeCd', list: app.freeList, element: 'M'}); break;
        case 'tkt7lPayCd': setElement({...popupData, lumpUpdateItem: '07', path: 'payCd', list: app.payList, element: 'M'}); break;
        case 'tkt7lTourCd': setElement({...popupData, lumpUpdateItem: '08', path: 'tourCd', list: app.tourList, element: 'M'}); break;
        case 'tkt7lKb1Cd': setElement({...popupData, lumpUpdateItem: '09', path: 'kb1Cd', list: app.kb1List, element: 'M'}); break;
        case 'tkt7lKb2Cd': setElement({...popupData, lumpUpdateItem: '10', path: 'kb2Cd', list: app.kb2List, element: 'M'}); break;
        case 'tkt7lEoCd': setElement({...popupData, lumpUpdateItem: '11', path: 'eoCd', list: app.eoList, element: 'M'}); break;
        case 'tktFareType': setElement({...popupData, lumpUpdateItem: '12', path: '', list: FARE_TYPE, element: 'S'}); break;
        case 'tktTktType': setElement({...popupData, lumpUpdateItem: '13', path: '', list: TICKET_TYPE, element: 'S'}); break;
        case 'tktPaxType': setElement({...popupData, lumpUpdateItem: '14', path: '', list: PAX_TYPE, element: 'S'}); break;
        case 'tktCrs': setElement({...popupData, lumpUpdateItem: '17', path: '', list: CRS, element: 'S'}); break;
        case 'tktVoidId': 
        case 'tktOvoId': 
        case 'tktVoidDate': 
          setElement({...popupData, lumpUpdateItem: '15', path: '', list: [], element: 'D'}); 
          setTktVoidId('0')
          setTktOvoId('0')
          break;
        case 'tktTokuiClm': setElement({...popupData, lumpUpdateItem: '16', path: '', list: [], element: 'N'}); break;
        case 'tktDetailIssuedFlg': setElement({...popupData, lumpUpdateItem: '18', path: '', list: [], element: 'C'}); break;
        case 'tktCustomerOutputFlg': setElement({...popupData, lumpUpdateItem: '19', path: '', list: [], element: 'C'}); break;
        case 'tktAgentCouponOutputFlg': setElement({...popupData, lumpUpdateItem: '20', path: '', list: [], element: 'C'}); break;
        case 'tktCccfOutputFlg': setElement({...popupData, lumpUpdateItem: '21', path: '', list: [], element: 'C'}); break;
        case 'tktRefundNoticeOutputFlg': setElement({...popupData, lumpUpdateItem: '22', path: '', list: [], element: 'C'}); break;
      }
    }
  }, [popupData])

  const execUpdate = () => {
    const dto = {}
    dto.ticketLumpUpdateItemDto = []
    _.forEach(grid.rows.filter(r => r.isSelected), (r, i) => {
      dto.ticketLumpUpdateItemDto.push({
        // tktnoAl: (search.searchUnit.text === '0')?r.dataItem.tktTktnoAl:r.dataItem.fltTktnoAl,
        // tktnoSn: (search.searchUnit.text === '0')?r.dataItem.tktTktnoSn:r.dataItem.fltTktnoSn,
        tktnoAl: r.dataItem.tktTktnoAl,
        tktnoSn: r.dataItem.tktTktnoSn,
      })
    })
    dto.lumpUpdateItem = element.lumpUpdateItem
    if (element.path) {
      dto.inputContents = (selected && selected.length > 0)?selected[0][element.path]:''
    } else {
      dto.inputContents = props.search.bulk[element.id].text
    }
    if (element.element === 'D') {
      dto.tktVoidId = tktVoidId === '1'
      dto.tktOvoId = tktOvoId === '1'
      dto.inputContents = props.search.bulk.tktVoidDate.text?convert.toDateString(props.search.bulk.tktVoidDate.text, 'yyyyMMdd'):null
      // 入力チェック
      if (dto.tktVoidId && !dto.inputContents) {
        setMessage(MESSAGE.noSelectError('VOID処理日').text)
        return
      }
    }

    // if (element.element === 'C') {
    //   dto[element.id] = element.text === '1'
    // }

    appAction.showProcessing({isProcessing: true})
    TicketApi.findLumpUpdate(dto).then((res) => {
      appAction.setMessage({message: res.payload.messageList,})
      if (res.payload.code === '000') {
        let type = props.search.searchUnit && props.search.searchUnit.text==='0'?'ticket':'flight'
        let resultList = res.payload.ticketLumpUpdateReGetItemDtoList
        _.forEach(resultList, (rec, i) => {
          _.forEach(rec.bstTktDataTDto, (v, k) => {
            const _find = _.find(LIST_ITEMS[type], { 'id': k})
            if (_find && _find.dataType === 'Date') {
              if (_find.format !== 'HH:mm') {
                resultList[i].bstTktDataTDto[k] = v?new Date(v):''
              }
              if (_find.format === 'HH:mm') {
                resultList[i].bstTktDataTDto[k] = convert.time2string(convert.timeString2HHmmDate(v))
              }
            }
            if (_find && _find.id === 'tktCaApramt') {
              resultList[i].bstTktDataTDto[k] = v?_.toNumber(v):''
            }
            if (_find && _find.id.startsWith('supportSum')) {
              resultList[i].bstTktDataTDto[k] = v?_.toNumber(v):''
            }
            if (_find && _find.id.startsWith('fltIndByteTkt')) {
              resultList[i].bstTktDataTDto[k] = v?dataMapper(FLT_IND_BYTE_TKT, 'value', 'label', v):''
            }
            if (_find && _find.id.startsWith('tktBookingPcc')) {
              resultList[i].bstTktDataTDto[k] = rec.bstTktDataTDto.tktReserveOfficeid?rec.bstTktDataTDto.tktReserveOfficeid:v
            }
            if (_find && _find.id.startsWith('tktTktPcc')) {
              resultList[i].bstTktDataTDto[k] = rec.bstTktDataTDto.tktTicketingOfficeid?rec.bstTktDataTDto.tktTicketingOfficeid:v
            }
          })
          _.forEach(search.data, r => {
            if (r.tktTktnoAl === rec.bstTktDataTDto.tktTktnoAl && r.tktTktnoSn === rec.bstTktDataTDto.tktTktnoSn) {
              _.forEach(r, (v, k) => {
                r[k] = _.isUndefined(rec.bstTktDataTDto[k])?r[k]:rec.bstTktDataTDto[k]
              })
            }
          })
          //
          _.forEach(rec.bstFltDataTDto, (r, i) => {
          })
        })
        grid.refresh()
        // console.log(search.data)
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
      setPopupData(null)
      setElement([])
      setSelected([])
      popup.hide('wj-hide-ok')
    })
  }

  React.useEffect(() => {
    if (tktOvoId === '1') setTktVoidId('1')
  }, [tktOvoId])

  React.useEffect(() => {
    if (tktVoidId === '0') setTktOvoId('0')
  }, [tktVoidId])

  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        removeOnHide={false}
      >
        <Card 
          root='popup vw-50vw '
          size='md'
          hideClose
          header={props.header}
          headerClass='wj-dialog-header'
          subheader={<div className='message'><span className='error'>{message}</span></div>}
          body={
            <React.Fragment>
              {element && element.element==='M' &&
                <Common.MultiSelect
                  attrs={{...props.search.bulk[element.id], delay: 10}}
                  group={{
                    className: 'width-max-content mr-2',
                    textwidth: 'min-width-18',
                    // textwidth: 'min-width-25',
                    textsuffixwidth: 'max-width-12',
                  }}
                  maxSelectedItems={1}
                  minLength={1}
                  selectedValuePath={element.path}
                  displayName="namej"
                  displayMemberPath={'listName'}
                  list={element.list}
                  searchMemberPath={element.path}
                  selectedItems={selected}
                  setSelectedItems={setSelected}
                  template={'<table><tr>' +
                            '<td class="width-13" title="コード">{cd}</td>' +
                            '<td class="" title="名称">{namej}</td>' +
                            '</tr></table>'}
                  isEditable={true}
                />
              }
              {element && element.element==='S' &&
                <Common.Select
                  attrs={{...props.search.bulk[element.id]}}
                  group={{
                    className: 'width-30 mr-2',
                    textwidth: 'width-30',
                  }}
                  minLength={1}
                  list={element.list}
                  onChange={(e) => {tktSearchAction.changeInputTktSearchBulk(e)}}
                />
              }
              {element && element.element==='D' &&
                <React.Fragment>
                  <div className='d-flex flex-row align-items-center width-50'>
                    <Common.Check
                      attrs={{
                        id: 'tktVoidId',
                        label: 'VOID',
                        text: tktVoidId,
                      }}
                      onChange={(e) => {setTktVoidId(e.tktVoidId.text)}}
                    />
                    <Common.Check
                      attrs={{
                        id: 'tktOvoId',
                        label: 'オンライン VOID',
                        text: tktOvoId,
                      }}
                      onChange={(e) => {setTktOvoId(e.tktOvoId.text)}}
                    />
                    <Common.DateTime
                      attrs={{
                        ...props.search.bulk.tktVoidDate,
                        isRequired: tktVoidId==='1',
                        disabled: tktVoidId!=='1',
                      }}
                      onBlur={(e) => tktSearchAction.changeInputTktSearchBulk(e)}
                      group={{
                        className: 'width-max-content ml-5 mr-0',
                      }}
                    />
                    {/* <div className='pane1 mt-4 mb-3'>
                      <Common.Check
                        attrs={{
                          id: 'tktVoidId',
                          label: 'VOID',
                          text: tktVoidId,
                        }}
                        onChange={(e) => {setTktVoidId(e.tktVoidId.text)}}
                      />
                    </div>
                    <div className='d-flex flex-column pane2 flex-grow-1'>
                      {tktVoidId === '1' &&
                        <React.Fragment>
                          <div className='mt-1'><label className='form-label'>VOID種別</label></div>
                          <Common.RadioButton
                            btnSize='sm'
                            attrs={{
                              id: 'tktOvoId',
                              label: 'オンライン VOID',
                              text: tktOvoId,
                            }}
                            className=''
                            radios={TKT_OVO_ID}
                            onChange={(e) => {setTktOvoId(e.tktOvoId.text)}}
                          />
                        </React.Fragment>
                      }
                    </div>
                    <div className='pane3 ml-5'>
                      {tktVoidId === '1' &&
                        <Common.DateTime
                          attrs={{
                            ...props.search.bulk.tktVoidDate,
                            isRequired: tktVoidId==='1'
                          }}
                          onBlur={(e) => tktSearchAction.changeInputTktSearchBulk(e)}
                          group={{
                            className: 'width-max-content ml-3 mr-0',
                          }}
                        />
                      }
                    </div> */}
                  </div>
                </React.Fragment>
              }
              {element && element.element==='N' &&
                <Common.Number
                  attrs={{...props.search.bulk[element.id]}}
                  onBlur={(e) => {tktSearchAction.changeInputTktSearchBulk(e)}}
                  group={{
                    className: 'width-16 ml-4 mr-0 mb-1',
                    textwidth: 'width-10'
                  }}
                />
              }
              {element && element.element==='C' &&
                <Common.Radio
                  attrs={{...props.search.bulk[element.id], label: '_none'}}
                  radios={RADIOS[element.id]}
                  onChange={(e) => tktSearchAction.changeInputTktSearchBulk(e)}
                />
              }
            </React.Fragment>
            }
          
        />
        <Card 
          size='md'
          root={`popup-button ${props.size}`}
          body={
            <React.Fragment>
              <Button
                className='button-gray mr-auto'
                onClick={(e) => {
                  setPopupData(null)
                  setElement([])
                  setSelected([])
                  popup.hide('wj-hide-cancel')
                }}
              >
                キャンセル
              </Button>
              <Button
                className='button-blue ml-auto'
                onClick={(e) => execUpdate()}
                disabled={app.isProcessing}
              >
                実行
              </Button>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})