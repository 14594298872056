import { handleActions, createAction } from 'redux-actions'
import { onChangeInput, initState } from 'lib'
import _ from 'lodash';

// const
export const TKT_EDIT = {
  CHANGE_INPUT_TKT_EDIT : 'CHANGE_INPUT_TKT_EDIT',
  SET_INPUT_TKT_EDIT : 'SET_INPUT_TKT_EDIT',
  CLEAR_TKT_EDIT : 'CLEAR_TKT_EDIT',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
};

// action
export const TktEditAction = {
  changeInputTktEdit: createAction(TKT_EDIT.CHANGE_INPUT_TKT_EDIT, (args) => (args)),
  setInputTktEdit: createAction(TKT_EDIT.SET_INPUT_TKT_EDIT, (args) => (args)),
  clearTktEdit: createAction(TKT_EDIT.CLEAR_TKT_EDIT),
  initSession: createAction(TKT_EDIT.INIT_SESSION),
  clearSession: createAction(TKT_EDIT.CLEAR_SESSION),
}

// initialState
const initialState = {
  // formMode: '0',
  // ヘッダ
  tktTktnoAl:{
    id: 'tktTktnoAl',
    label: '_none',
    placeholder: '',
    required: true,
    disabled: false,
    minLength: 3,
    maxLength: 3,
    width: '100%',
    margin: '10px',
    // enter: '#search',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['num'],
    stringcase: '',
  },
  tktTktnoSnFrom:{
    id: 'tktTktnoSnFrom',
    label: '_none',
    placeholder: '',
    required: true,
    disabled: false,
    minLength: 10,
    maxLength: 10,
    width: '100%',
    margin: '10px',
    // enter: '#search',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['num'],
    stringcase: '',
  },
  tktTktnoSnTo:{
    id: 'tktTktnoSnTo',
    label: '',
    // prefix: '～',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 3,
    maxLength: 3,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['num'],
    stringcase: '',
  },
  tktConjData:{
    id: 'tktConjData',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 17,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['num_hy'],
    stringcase: '',
  },
  tktIsuDate:{
    id: 'tktIsuDate',
    label: '発券日',
    placeholder: '',
    required: true,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date1',
    format: 'yyyy/MM/dd',
    mask: '9999/99/99',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktDepDate:{
    id: 'tktDepDate',
    label: '出発日',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date1',
    format: 'yyyy/MM/dd',
    mask: '9999/99/99',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktIntDepDate:{
    id: 'tktIntDepDate',
    label: '国際線出発日',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date1',
    format: 'yyyy/MM/dd',
    mask: '9999/99/99',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktStatus:{
    id: 'tktStatus',
    label: 'TKTステータス',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktVoidId:{
    id: 'tktVoidId',
    label: 'VOID',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktOvoId:{
    id: 'tktOvoId',
    label: 'VOID種別',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktVoidDate:{
    id: 'tktVoidDate',
    label: 'VOID処理日',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date1',
    format: 'yyyy/MM/dd',
    mask: '9999/99/99',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktRefId:{
    id: 'tktRefId',
    label: 'REFUND',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktAtRefid:{
    id: 'tktAtRefid',
    label: 'REFUND種別',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktRefDate:{
    id: 'tktRefDate',
    label: 'REFUND処理日',
    placeholder: '',
    required: false,
    disabled: false,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date1',
    format: 'yyyy/MM/dd',
    mask: '9999/99/99',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktReissueId:{
    id: 'tktReissueId',
    label: 'REISSUE',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktPaxName:{
    id: 'tktPaxName',
    label: 'PAX NAME',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 68,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktPaxType:{
    id: 'tktPaxType',
    label: 'PAX TYPE',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktPtc:{
    id: 'tktPtc',
    label: 'PTC',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 3,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  tktFareType:{
    id: 'tktFareType',
    label: '運賃種別',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktCrs:{
    id: 'tktCrs',
    label: 'GDS区分',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktPnr:{
    id: 'tktPnr',
    label: 'PNR',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 6,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktIata:{
    id: 'tktIata',
    label: 'IATA',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 7,
    maxLength: 7,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  tktTktType:{
    id: 'tktTktType',
    label: '券種',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktNdcFlg: {
    id: 'tktNdcFlg',
    label: 'NDC',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktOrderId:{
    id: 'tktOrderId',
    label: 'ORDER ID',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 20,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktRefundVoidDate:{
    id: 'tktRefundVoidDate',
    label: 'REFUND/VOID処理日',
    placeholder: '',
    required: false,
    disabled: true,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date1',
    format: 'yyyy/MM/dd',
    mask: '9999/99/99',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  //
  calcDispFlg: {
    id: 'calcDispFlg',
    label: '運賃・精算',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  fareDispFlg: {
    id: 'fareDispFlg',
    label: 'FARE',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  accDispFlg: {
    id: 'accDispFlg',
    label: 'アカウントコード',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  flightDispFlg: {
    id: 'flightDispFlg',
    label: 'フライト情報',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  taxDispFlg: {
    id: 'taxDispFlg',
    label: 'TAX',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  cardDispFlg: {
    id: 'cardDispFlg',
    label: 'CARD情報',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  refundDispFlg: {
    id: 'refundDispFlg',
    label: 'REFUND',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  reissueDispFlg: {
    id: 'reissueDispFlg',
    label: 'REISSUE',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  custDispFlg: {
    id: 'custDispFlg',
    label: '得意先情報',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  otherDispFlg: {
    id: 'otherDispFlg',
    label: 'その他情報',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  emdDispFlg: {
    id: 'emdDispFlg',
    label: 'EMD情報',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  addDispFlg: {
    id: 'addDispFlg',
    label: '各種追加料金',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  // 運賃・精算
  tktGsCash:{
    id: 'tktGsCash',
    label: 'GROSS CASH',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktGsCredit:{
    id: 'tktGsCredit',
    label: 'GROSS CREDIT',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktCreditCom:{
    id: 'tktCreditCom',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 2,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'calc',
    name: 'CARD No.',
  },
  tktCardNum:{
    id: 'tktCardNum',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 17,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'calc',
    name: 'CARD No.',
  },
  tktCommRate:{
    id: 'tktCommRate',
    label: 'COMM RATE',
    suffix: '%',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 6,
    format: 'n2',
    min: 0,
    max: 99.99,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktTaxCash:{
    id: 'tktTaxCash',
    label: 'TAX CASH',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktTaxCredit:{
    id: 'tktTaxCredit',
    label: 'TAX CREDIT',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktCommP:{
    id: 'tktCommP',
    label: 'COMM額',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 11,
    min: 0,
    max: 999999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktSrcPrice:{
    id: 'tktSrcPrice',
    label: 'TKT原価',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 11,
    min: 0,
    max: 999999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktSalesAmt:{
    id: 'tktSalesAmt',
    label: 'SALES AMOUNT',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 11,
    min: 0,
    max: 999999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktNsb:{
    id: 'tktNsb',
    label: 'NSB額',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 11,
    min: 0,
    max: 999999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktCommAmtCost:{
    id: 'tktCommAmtCost',
    label: 'COMM額消費税',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 11,
    min: 0,
    max: 999999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktBspP:{
    id: 'tktBspP',
    label: 'BSP請求額',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 11,
    min: 0,
    max: 999999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tkt2aNet:{
    id: 'tkt2aNet',
    label: '2A NET額',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktBspTaxin:{
    id: 'tktBspTaxin',
    label: 'BSP請求額(税込)',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 11,
    min: 0,
    max: 999999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktCommTaxin:{
    id: 'tktCommTaxin',
    label: 'COMM額(税込)',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 11,
    min: 0,
    max: 999999999,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktNrid:{
    id: 'tktNrid',
    label: 'NR-ID',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'calc',
  },
  tktItno:{
    id: 'tktItno',
    label: 'IT-NO',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 15,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'calc',
  },
  tktVc:{
    id: 'tktVc',
    label: 'VC',
    suffix: '/',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 5,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'calc',
  },
  tktCar:{
    id: 'tktCar',
    label: 'CAR',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 9,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'calc',
  },
  tktTourCd:{
    id: 'tktTourCd',
    label: 'TOUR CODE',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 15,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'calc',
  },
  // tkt7kb:{
  //   id: 'tkt7kb',
  //   label: '7KB',
  //   placeholder: '',
  //   required: false,
  //   disabled: false,
  //   minLength: 0,
  //   maxLength: 8,
  //   width: '100px',
  //   margin: '10px',
  //   enter: '',
  //   type: '',
  //   format: '',
  //   original: '',
  //   value: '',
  //   text: '',
  //   dirty: '',
  //   isInvalid: false,
  //   helpertext: '',
  //   validtype: [],
  //   stringcase: '',
  // },
  // tkt7rCd:{
  //   id: 'tkt7rCd',
  //   label: '7R**',
  //   placeholder: '',
  //   required: false,
  //   disabled: false,
  //   minLength: 0,
  //   maxLength: 2,
  //   width: '100px',
  //   margin: '10px',
  //   enter: '',
  //   type: '',
  //   format: '',
  //   original: '',
  //   value: '',
  //   text: '',
  //   dirty: '',
  //   isInvalid: false,
  //   helpertext: '',
  //   validtype: [],
  //   stringcase: '',
  // },
  // FARE
  tktFareCalForm:{
    id: 'tktFareCalForm',
    label: '運賃計算形態',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 1,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'fare',
  },
  tktFareCalMode:{
    id: 'tktFareCalMode',
    label: 'FCMI',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 1,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'fare',
  },
  tktFareCalPricingInd:{
    id: 'tktFareCalPricingInd',
    label: 'FCPI',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 1,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'fare',
  },
  tktFareCalculation:{
    id: 'tktFareCalculation',
    label: 'FARE CALCULATION',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 253,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'fare',
  },
  tktFare:{
    id: 'tktFare',
    label: 'FARE',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 12,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'fare',
  },
  tktTotal:{
    id: 'tktTotal',
    label: 'TOTAL',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 12,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'fare',
  },
  tktEqFapd:{
    id: 'tktEqFapd',
    label: 'EQUIV FARE PAID',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 12,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'fare',
  },
  tktEndRest:{
    id: 'tktEndRest',
    label: 'ENDORSEMENTS/RESTRICTIONS',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 147,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'fare',
  },
  tktFormPay:{
    id: 'tktFormPay',
    label: 'FORM OF PAYMENT',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 100,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'fare',
  },
  // アカウントコード
  tkt7lOdrCd:{
    id: 'tkt7lOdrCd',
    label: '受注コード(R)',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 12,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'acc',
  },
  tkt7lSecCd:{
    id: 'tkt7lSecCd',
    label: 'セクションコード(S)',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 6,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'acc',
  },
  tkt7lMbrCd:{
    id: 'tkt7lMbrCd',
    label: '担当者コード(A)',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 6,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'acc',
  },
  tkt7lComCd:{
    id: 'tkt7lComCd',
    label: '法人コード(C)',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'acc',
  },
  tkt7lAreaCd:{
    id: 'tkt7lAreaCd',
    label: '方面コード(D)',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 3,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'acc',
  },
  tkt7lFreeCd:{
    id: 'tkt7lFreeCd',
    label: 'FREEコード(F)',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'acc',
  },
  tkt7lPayCd:{
    id: 'tkt7lPayCd',
    label: '入金コード(M)',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'acc',
  },
  tkt7lTourCd:{
    id: 'tkt7lTourCd',
    label: 'ツアーコード(T)',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 15,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'acc',
  },
  tkt7lKb1Cd:{
    id: 'tkt7lKb1Cd',
    label: 'KBコード1(K)',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 7,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'acc',
  },
  tkt7lKb2Cd:{
    id: 'tkt7lKb2Cd',
    label: 'KBコード2(B)',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 7,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'acc',
  },
  tkt7lEoCd:{
    id: 'tkt7lEoCd',
    label: 'E/Oコード(E)',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 7,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'acc',
  },
  // フライト情報
    tktKbFlag:{
      id: 'tktKbFlag',
      label: '',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 0,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: ' ',
      value: ' ',
      text: ' ',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
  // TAX
  // CARD情報
  tktCaExdate:{
    id: 'tktCaExdate',
    label: 'CARD有効期限',
    placeholder: '',
    required: false,
    disabled: false,
    mask: '00/00',
    minLength: 0,
    maxLength: 5,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['mmyy'],
    stringcase: '',
    category: 'card',
  },
  // txtDvCnt:{
  //   id: 'txtDvCnt',
  //   label: '分割回数',
  //   placeholder: '',
  //   required: false,
  //   disabled: false,
  //   minLength: 0,
  //   maxLength: 2,
  //   width: '100%',
  //   margin: '10px',
  //   enter: '',
  //   type: '',
  //   format: '',
  //   original: '',
  //   value: '',
  //   text: '',
  //   dirty: '',
  //   isInvalid: false,
  //   helpertext: '',
  //   validtype: [],
  //   stringcase: '',
  // },
  tktCaMem:{
    id: 'tktCaMem',
    label: 'CARD会員名',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 26,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'card',
  },
  tktCaAprid:{
    id: 'tktCaAprid',
    label: '承認カードID',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 2,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'card',
  },
  tktCaAprno:{
    id: 'tktCaAprno',
    label: 'CARD承認番号',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 7,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'card',
  },
  tktCaApramt:{
    id: 'tktCaApramt',
    label: 'CARD承認金額',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 11,
    min: 0,
    max: 999999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'number',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'card',
  },
  tktFuncCd:{
    id: 'tktFuncCd',
    label: '機能分類コード',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 2,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'card',
  },
  // tktPaingNo:{
  //   id: 'tktPaingNo',
  //   label: '立替金番号',
  //   placeholder: '',
  //   required: false,
  //   disabled: false,
  //   minLength: 0,
  //   maxLength: 14,
  //   width: '100%',
  //   margin: '10px',
  //   enter: '',
  //   type: '',
  //   format: '',
  //   original: '',
  //   value: '',
  //   text: '',
  //   dirty: '',
  //   isInvalid: false,
  //   helpertext: '',
  //   validtype: [],
  //   stringcase: '',
  // },
  tktRmks1:{
    id: 'tktRmks1',
    label: 'CARD REMARKS1',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'card',
  },
  tktRmks2:{
    id: 'tktRmks2',
    label: 'CARD REMARKS2',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'card',
  },
  tktRmks3:{
    id: 'tktRmks3',
    label: 'CARD REMARKS3',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'card',
  },
  // REFUND
  // tktRefClass:{
  //   id: 'tktRefClass',
  //   label: 'REFUND種別',
  //   placeholder: '',
  //   required: false,
  //   disabled: false,
  //   minLength: 0,
  //   maxLength: 1,
  //   width: '100%',
  //   margin: '10px',
  //   enter: '',
  //   type: '',
  //   format: '',
  //   original: '',
  //   value: '',
  //   text: '',
  //   dirty: '',
  //   isInvalid: false,
  //   helpertext: '',
  //   validtype: [],
  //   stringcase: '',
  // },
  tktRefType:{
    id: 'tktRefType',
    label: 'REFUND区分',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: ' ',
    format: '',
    original: ' ',
    value: '',
    text: ' ',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'refund',
  },
  tktGrsFarePd:{
    id: 'tktGrsFarePd',
    label: 'GROSS FARE PAID',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'refund',
  },
  tktFrUsed:{
    id: 'tktFrUsed',
    label: 'GROSS FARE USED',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'refund',
  },
  tktGrsFareRef:{
    id: 'tktGrsFareRef',
    label: 'GROSS FARE REFUND',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'refund',
  },
  tktTaxRef:{
    id: 'tktTaxRef',
    label: 'TAX REFUND',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'refund',
  },
  tktLesCntPen:{
    id: 'tktLesCntPen',
    label: 'CANCEL PENALTY',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'refund',
  },
  tktTotalAmt:{
    id: 'tktTotalAmt',
    label: 'TOTAL AMOUNT',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'refund',
  },
  tktNetFareRef:{
    id: 'tktNetFareRef',
    label: 'NET FARE REFUND',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'refund',
  },
  tktNetFare:{
    id: 'tktNetFare',
    label: 'NET FARE ADJUSTMENT',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'refund',
  },
  tktTotalAmountNfa:{
    id: 'tktTotalAmountNfa',
    label: 'TOTAL AMOUNT(NFA)',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'refund',
  },
  tktWaiverCode:{
    id: 'tktWaiverCode',
    label: 'WAIVER CODE',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 14,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'refund',
  },
  tktSac:{
    id: 'tktSac',
    label: 'SAC',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 15,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'refund',
  },
  tktRefMemo:{
    id: 'tktRefMemo',
    label: 'REFUND MEMO',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 25,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'refund',
  },
  tktBalancePayable:{
    id: 'tktBalancePayable',
    label: 'BALANCE PAYABLE',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'refund',
  },
  tktRefPfc:{
    id: 'tktRefPfc',
    label: 'REFUND PFC(XF) DATA',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 32,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum_bl_pd'],
    stringcase: 'upper',
    category: 'refund',
  },
  refundCommAmount:{
    id: 'refundCommAmount',
    label: 'COMM額',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 11,
    min: 0,
    max: 999999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'refund',
  },
  refundCommTax:{
    id: 'refundCommTax',
    label: 'COMM額消費税',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 11,
    min: 0,
    max: 999999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'refund',
  },
  // REISSUE
  tktOrgIsu:{
    id: 'tktOrgIsu',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 37,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'reissue',
    name: 'ORIGINAL ISSUE'
  },
  tktIsuEx:{
    id: 'tktIsuEx',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 37,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'reissue',
    name: 'ISSUED IN EXCHANGE'
  },
  // その他情報
  tktMemo:{
    id: 'tktMemo',
    label: 'MEMO',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 250,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: 'upper',
    category: 'other',
  },
  tktRcvDate:{
    id: 'tktRcvDate',
    label: '受信日',
    placeholder: '',
    required: false,
    disabled: true,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'datetime1',
    format: 'yyyy/MM/dd HH:mm',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'other',
  },
  tktRstDate:{
    id: 'tktRstDate',
    label: '登録日',
    placeholder: '',
    required: false,
    disabled: true,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'datetime1',
    format: 'yyyy/MM/dd HH:mm',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'other',
  },
  tktRstchrCd:{
    id: 'tktRstchrCd',
    label: '',
    placeholder: '',
    required: false,
    disabled: true,
    isRequired: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'other',
  },
  tktBookingPcc:{
    id: 'tktBookingPcc',
    label: '予約OFFICE ID/PCC',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 9,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
    category: 'other',
  },
  tktTktPcc:{
    id: 'tktTktPcc',
    label: '発券OFFICE ID/PCC',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 9,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
    category: 'other',
  },
  tktDetailIssuedFlg:{
    id: 'tktDetailIssuedFlg',
    label: 'チケット明細出力',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'other',
  },
  tktCustomerOutputFlg:{
    id: 'tktCustomerOutputFlg',
    label: 'お客様用出力',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'other',
  },
  tktAgentCouponOutputFlg:{
    id: 'tktAgentCouponOutputFlg',
    label: 'AGENT COUPON出力',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'other',
  },
  tktCccfOutputFlg:{
    id: 'tktCccfOutputFlg',
    label: 'CCCF出力',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'other',
  },
  tktRefundNoticeOutputFlg:{
    id: 'tktRefundNoticeOutputFlg',
    label: 'REFUND NOTICE出力',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'other',
  },
  // 得意先情報
  tktTokuiClm:{
    id: 'tktTokuiClm',
    label: '得意先請求金額(入力)',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'cust',
  },
  tktTkiTokuiRate:{
    id: 'tktTkiTokuiRate',
    label: '得意先%',
    placeholder: '',
    suffix: '%',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 6,
    format: 'n2',
    min: 0,
    max: 99.99,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'cust',
  },
  tktTkiKg:{
    id: 'tktTkiKg',
    label: '得意先金額',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'cust',
  },
  tktTkiShz:{
    id: 'tktTkiShz',
    label: '得意先消費税',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'cust',
  },
  tktTkiSeikyuKg:{
    id: 'tktTkiSeikyuKg',
    label: '得意先請求金額(計算値)',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'cust',
  },
  tktTkiJstsr:{
    id: 'tktTkiJstsr',
    label: '自社手数料',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 10,
    min: 0,
    max: 99999999,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
    category: 'cust',
  },
  // EMD情報
  tktReasonIssuanceCode:{
    id: 'tktReasonIssuanceCode',
    label: 'RFIC',
    labelTooltip: 'REASON FOR ISSUEANCE CODE',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
    category: 'emd',
  },
  tktReasonIssuanceDesc:{
    id: 'tktReasonIssuanceDesc',
    label: 'REASON FOR ISSUEANCE DESCRIPTION',
    labelTooltip: 'REASON FOR ISSUEANCE DESCRIPTION',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 60,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: 'upper',
    category: 'emd',
  },
  tktEmdRmks:{
    id: 'tktEmdRmks',
    label: 'EMD TKT REMARKS',
    labelTooltip: 'EMD TICKET REMARKS',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 70,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
    category: 'emd',
  },
  // 各種追加料金
  // 表示制御用
  refCommRate:{
    id: 'refCommRate',
    visible:true,
  },
  tktNoCoupon:{
    id: 'tktNoCoupon',
    visible:true,
  },
  tktRefTax:{
    id: 'tktRefTax',
    visible:true,
  },
  tktEmdCpnData:{
    id: 'tktEmdCpnData',
    visible:true,
  },

  search: {
    id: 'search',
    label: '',
    disabled: false,
  },
  setting: {
    id: 'setting',
    label: <span><span className='material-icons-outlined md'>settings</span>表示項目設定</span>,
    disabled: false,
    // isbutton: true,
  },
  menu: {
    id: 'menu',
    label: <span>処理メニュー</span>,
    disabled: false,
    submenu: [
      {
        id: 'menu4',
        label: <span>固定帳票印刷</span>,
        disabled: false,
      }
    ]
  },
  new: {
    id: 'new',
    label: <span><span className='material-icons-outlined md'>add_circle_outline</span>新規</span>,
    disabled: false,
  },
  copy: {
    id: 'copy',
    label: <span><span className='material-icons-outlined md'>content_copy</span>複製</span>,
    disabled: false,
  },
  del: {
    id: 'del',
    label: <span><span className='material-icons-outlined md'>delete</span>削除</span>,
    disabled: false,
  },
  edit: {
    id: 'edit',
    label: <span><span className='material-icons-outlined md'>file_upload</span>発券データ登録</span>,
    disabled: false,
  },
  editPane: [
    {w: 1, h: 19, x: 0, y:   0, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'calc', name: '運賃・精算',},
    {w: 1, h: 19, x: 1, y:   0, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'fare', name: 'FARE',},
    {w: 2, h: 13, x: 0, y:  19, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'acc', name: 'アカウントコード',},
    {w: 2, h: 18, x: 0, y:  32, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'flight', name: 'フライト情報',},
    {w: 1, h: 17, x: 0, y:  50, static: false, isDraggable: true, isResizable: false, isShow:false, i: 'tax', name: 'TAX',},
    {w: 1, h: 13, x: 1, y:  50, static: false, isDraggable: true, isResizable: false, isShow:false, i: 'card', name: 'CARD情報',},
    {w: 1, h: 38, x: 0, y:  67, static: false, isDraggable: true, isResizable: false, isShow:false, i: 'refund', name: 'REFUND',},
    {w: 1, h: 10, x: 1, y:  63, static: false, isDraggable: true, isResizable: false, isShow:false, i: 'reissue', name: 'REISSUE',},
    {w: 1, h: 13, x: 0, y: 105, static: false, isDraggable: true, isResizable: false, isShow:false, i: 'cust', name: '得意先情報',},
    {w: 1, h: 22, x: 1, y:  73, static: false, isDraggable: true, isResizable: false, isShow:false, i: 'other', name: 'その他情報',},
    {w: 2, h: 21, x: 0, y: 118, static: false, isDraggable: true, isResizable: false, isShow:false, i: 'emd', name: 'EMD情報',},
    {w: 1, h: 14, x: 0, y: 139, static: false, isDraggable: true, isResizable: false, isShow:false, i: 'add', name: '各種追加料金',},
  ],
}
// reducer
export const TktEditReducer = handleActions({
  [TktEditAction.changeInputTktEdit]: (state, action) => (onChangeInput(state, action.payload)),
  [TktEditAction.setInputTktEdit]: (state, action) => ({...state, ...action.payload,}),
  [TktEditAction.clearTktEdit]: (state, action) => (initState(initialState)),
  [TktEditAction.initSession]: (state, action) => (initState(initialState)),
  [TktEditAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)
