import React from 'react';
import {
  Toast,
} from 'react-bootstrap';
import _ from 'lodash';

export const CardComponent = (props) => {
  return (
    <React.Fragment>
      <Toast className={`card-root ${props.root}`} onClose={(e)=>props.onClose([props.condition])}>
        <Toast.Header closeLabel='' closeButton={!props.hideClose} className={`${!props.header && 'd-none'} ${props.headerClass}`}>
          <div className='card-header-image'/>
          <div className={`card-header-text-${props.size}`}>{props.header}</div>
          <div className={`card-header-subtext ${props.subheaderClass}`}>{props.subheader}</div>
        </Toast.Header>
        <Toast.Body className={`card-body ${props.bodyClass}`}>
          {/* <div className='card-error-message'>{props.errorMessage}</div> */}
          {props.body}
        </Toast.Body>
      </Toast>
    </React.Fragment>
  )
}

CardComponent.defaultProps = {};
