import { handleActions, createAction } from 'redux-actions'
import { onChangeInput, initState } from 'lib'
import _ from 'lodash';

// const
export const PRISM = {
  CHANGE_INPUT_PRISM : 'CHANGE_INPUT_PRISM',
  SET_INPUT_PRISM : 'SET_INPUT_PRISM',
  ADD_PRISM_ITEM : 'ADD_PRISM_ITEM',
  REMOVE_PRISM_ITEM : 'REMOVE_PRISM_ITEM',
  CLEAR_PRISM : 'CLEAR_PRISM',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
};

// action
export const PrismAction = {
  changeInputPrism: createAction(PRISM.CHANGE_INPUT_PRISM, (args) => (args)),
  setInputPrism: createAction(PRISM.SET_INPUT_PRISM, (args) => (args)),
  addPrismItem: createAction(PRISM.ADD_PRISM_ITEM, (args) => (args)),
  removePrismItem: createAction(PRISM.REMOVE_PRISM_ITEM, (args) => (args)),
  clearPrism: createAction(PRISM.CLEAR_PRISM),
  initSession: createAction(PRISM.INIT_SESSION),
  clearSession: createAction(PRISM.CLEAR_SESSION),
}

// initialState
const initialState = {
  // --
  label: [
    '',
    '処理日',
    'ピリオド',
  ],
  prismIata: {
    id: 'prismIata',
    label: 'IATAコード',
    placeholder: '',
    required: true,
    disabled: false,
    minLength: 7,
    maxLength: 7,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['num'],
    stringcase: '',
  },
  prismInvoice: {
    id: 'prismInvoice',
    label: 'INVOICE',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  prismCustomer: {
    id: 'prismCustomer',
    label: 'CUSTOMER',
    placeholder: '',
    required: true,
    disabled: false,
    isRequired: true,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  prismBranch: {
    id: 'prismBranch',
    label: 'Branch',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 8,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: '',
  },
  prismIso: {
    id: 'prismIso',
    label: 'ISO',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 2,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: '',
  },

  prismSpecified: {
    id: 'prismSpecified',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  prismRange: {
    id: 'prismRange',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  prismSearchCodes: {
    id: 'prismSearchCodes',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: 'upper',
  },
  customerFrom: {
    id: 'customerFrom',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: 'upper',
  },
  customerTo: {
    id: 'customerTo',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: 'upper',
  },
  prismSearchFrom: {
    id: 'prismSearchFrom',
    label: '_none',
    placeholder: '',
    required: true,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date',
    format: 'yyyy/MM/dd',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  prismSearchTo: {
    id: 'prismSearchTo',
    label: '_none',
    placeholder: '',
    required: true,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date',
    format: 'yyyy/MM/dd',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },

  download: {
    id: 'download',
    label: <span><span className='material-icons-outlined md'>file_download</span>PRISMデータ出力</span>,
    disabled: false,
  },
  before: {
    id: 'before',
    label: <span><span className='material-icons-outlined md'>history</span>前回エラーデータ</span>,
    disabled: false,
  },
  csv: {
    id: 'csv',
    label: <span><span className='material-icons-outlined md'>file_download</span>CSV出力</span>,
    disabled: false,
  },
}
// reducer
export const PrismReducer = handleActions({
  [PrismAction.changeInputPrism]: (state, action) => (onChangeInput(state, action.payload)),
  [PrismAction.setInputPrism]: (state, action) => ({...state, ...action.payload,}),
  [PrismAction.addPrismItem]: (state, action) => ({
    ...state,
    checkedItems: _.concat(state.checkedItems, action.payload)
  }),
  [PrismAction.removePrismItem]: (state, action) => {
    const obj = Object.assign({}, state)
    _.map(state, (v,k) => {
      if (k.startsWith(action.payload[0].key + '_')) delete obj[k]
    })
    return ({
    ...obj,
    checkedItems: _.reject(state.checkedItems, {header: action.payload[0].header})//keyに変更
  })},
  [PrismAction.clearPrism]: (state, action) => (initState(initialState)),
  [PrismAction.initSession]: (state, action) => (initState(initialState)),
  [PrismAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)
