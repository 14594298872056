import { handleActions, createAction } from 'redux-actions'
import { onChangeInput, initState } from 'lib'
import _ from 'lodash';

// const
export const TKT_EXCEL = {
  CHANGE_INPUT_TKT_EXCEL : 'CHANGE_INPUT_TKT_EXCEL',
  CHANGE_INPUT_TKT_EXCEL_TKT : 'CHANGE_INPUT_TKT_EXCEL_TKT',
  CHANGE_INPUT_TKT_EXCEL_FLT : 'CHANGE_INPUT_TKT_EXCEL_FLT',
  SET_INPUT_TKT_EXCEL : 'SET_INPUT_TKT_EXCEL',
  ADD_TKT_EXCEL_ITEM : 'ADD_TKT_EXCEL_ITEM',
  REMOVE_TKT_EXCEL_ITEM : 'REMOVE_TKT_EXCEL_ITEM',
  CLEAR_TKT_EXCEL : 'CLEAR_TKT_EXCEL',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
};

// action
export const TktExcelAction = {
  changeInputTktExcel: createAction(TKT_EXCEL.CHANGE_INPUT_TKT_EXCEL, (args) => (args)),
  changeInputTktExcelTkt: createAction(TKT_EXCEL.CHANGE_INPUT_TKT_EXCEL_TKT, (args) => (args)),
  changeInputTktExcelFlt: createAction(TKT_EXCEL.CHANGE_INPUT_TKT_EXCEL_FLT, (args) => (args)),
  setInputTktExcel: createAction(TKT_EXCEL.SET_INPUT_TKT_EXCEL, (args) => (args)),
  addTktExcelItem: createAction(TKT_EXCEL.ADD_TKT_EXCEL_ITEM, (args) => (args)),
  removeTktExcelItem: createAction(TKT_EXCEL.REMOVE_TKT_EXCEL_ITEM, (args) => (args)),
  clearTktExcel: createAction(TKT_EXCEL.CLEAR_TKT_EXCEL),
  initSession: createAction(TKT_EXCEL.INIT_SESSION),
  clearSession: createAction(TKT_EXCEL.CLEAR_SESSION),
}

// initialState
const initialState = {
  // 検索
  sizeHorizontal: ['100%', '1'],
  sizeVertical: ['20%', '1'],
  sizeGraph: ['200px', '1'],
  condition: {
    id: 'condition',
    label: '',
    required: true,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  checkedItems: [
    
  ],
  checkedItemsSetting: [
  ],
  recentlyCondition: [],
  conditionPattern: [],
  patternList: [],
  patternName: {
    id: 'patternName',
    label: '名称',
    required: true,
    disabled: false,
    minLength: 0,
    maxLength: 30,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  searchUnit: {
    id: 'searchUnit',
    label: '',
    prefix: '表示単位',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  condClear: {
    id: 'clear',
    label: <span><span className='material-icons-outlined md'>delete_forever</span>検索条件全クリア</span>,
    disabled: false,
  },
  condEdit: {
    id: 'edit',
    label: <span><span className='material-icons-outlined md'>cloud_upload</span>検索条件パターン登録</span>,
    disabled: false,
  },
  search: {
    id: 'search',
    label: <span><span className='material-icons-outlined md'>search</span>発券データ検索</span>,
    disabled: false,
  },
  // 一覧表示
  dispCheckedItems: [
    {
      id: 'tktTktnoAl',
      header: 'TKT NO.（AIRLINEコード）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktTktnoSn',
      header: 'TKT NO.（S/N）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktPaxName',
      header: 'PAX NAME',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktStatus',
      header: 'TKTステータス',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktIsuDate',
      header: '発券日',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktDepDate',
      header: '出発日',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktDetailIssuedFlg',
      header: 'チケット明細発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
    },
  ],
  bulk: {},
  exec: {
    id: 'exec',
    label: <span><span className='material-icons-outlined md'>done</span>設定</span>,
    disabled: false,
  },
  research: {
    id: 'research',
    label: <span><span className='material-icons-outlined md'>search</span>再検索</span>,
    disabled: false,
  },
  // --
  label: [
    '出力フォーマット',
    'ヘッダ行の出力',
    'ソート順',
    'ファイル分割',
    '出力ファイル方式',
    'ファイル出力パス',
    'ファイル重複時',
    'ファイル出力パス',
    '運賃種別PEXのデータ',
    '消費税対応',
    'PRODUCTION',
    '出力形式選択',
    'GROSSとNETが同額の場合',
    '予約/発券OFFICE ID/PCC',
    '除外データ',
    'KB額が0円のデータ',
    'FLTセグメント',
    '出力ファイル名',
    '金額合計値',
    '券種EMDのデータ'
  ],

  tkt:{
    tktXlsitemObjectId_tkt: {
      id: 'tktXlsitemObjectId_tkt',
      label: 'パターン選択',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniFormat_tkt: {
      id: 'tktXlsiniFormat_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniHeader_tkt: {
      id: 'tktXlsiniHeader_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort1_tkt: {
      id: 'tktXlsiniSort1_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort2_tkt: {
      id: 'tktXlsiniSort2_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort3_tkt: {
      id: 'tktXlsiniSort3_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort4_tkt: {
      id: 'tktXlsiniSort4_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort5_tkt: {
      id: 'tktXlsiniSort5_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort6_tkt: {
      id: 'tktXlsiniSort6_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort7_tkt: {
      id: 'tktXlsiniSort7_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort8_tkt: {
      id: 'tktXlsiniSort8_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniFile_tkt: {
      id: 'tktXlsiniFile_tkt',
      label: '_none',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 256,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['filename'],
      stringcase: '',
    },
    tktXlsiniClientSyncFlag_tkt: {
      id: 'tktXlsiniClientSyncFlag_tkt',
      label: 'URI-Pro Plus',
      required: false,
      disabled: false,
      original: '0',
      value: '0',
      text: '0',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniClientPath_tkt: {
      id: 'tktXlsiniClientPath_tkt',
      label: '_none',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 256,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['filepath'],
      stringcase: '',
    },
    tktXlsiniClientOutFile_tkt: {
      id: 'tktXlsiniClientOutFile_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '3',
      value: '3',
      text: '3',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniClientOutFilePattern_tkt: {
      id: 'tktXlsiniClientOutFilePattern_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '3',
      value: '3',
      text: '3',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniS3Flag_tkt: {
      id: 'tktXlsiniS3Flag_tkt',
      label: 'SFTP転送',
      required: false,
      disabled: false,
      original: '0',
      value: '0',
      text: '0',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniS3Path_tkt: {
      id: 'tktXlsiniS3Path_tkt',
      label: '_none',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 256,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['filepath'],
      stringcase: '',
    },
    tktXlsiniS3OutFile_tkt: {
      id: 'tktXlsiniS3OutFile_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '3',
      value: '3',
      text: '3',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniS3OutFilePattern_tkt: {
      id: 'tktXlsiniS3OutFilePattern_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '3',
      value: '3',
      text: '3',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniPccdiv_tkt: {
      id: 'tktXlsiniPccdiv_tkt',
      label: '予約OFFICE ID/PCCごとにファイル分割する',
      required: false,
      disabled: false,
      original: '0',
      value: '0',
      text: '0',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniPex_tkt: {
      id: 'tktXlsiniPex_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '0',
      value: '0',
      text: '0',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniCsmtax_tkt: {
      id: 'tktXlsiniCsmtax_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniPrd_tkt: {
      id: 'tktXlsiniPrd_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '0',
      value: '0',
      text: '0',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniPlusType_tkt: {
      id: 'tktXlsiniPlusType_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniGrossNetSame_tkt: {
      id: 'tktXlsiniGrossNetSame_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniPcc_tkt: {
      id: 'tktXlsiniPcc_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '0',
      value: '0',
      text: '0',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktEmdMpdFlag_tkt: {
      id: 'tktEmdMpdFlag_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniFileFlag_tkt: {
      id: 'tktXlsiniFileFlag_tkt',
      label: '_none',
      required: false,
      disabled: false,
      original: '0',
      value: '0',
      text: '0',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
  },

  flt:{
    tktXlsitemObjectId_flt: {
      id: 'tktXlsitemObjectId_flt',
      label: 'パターン選択',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniFormat_flt: {
      id: 'tktXlsiniFormat_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniHeader_flt: {
      id: 'tktXlsiniHeader_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort1_flt: {
      id: 'tktXlsiniSort1_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort2_flt: {
      id: 'tktXlsiniSort2_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort3_flt: {
      id: 'tktXlsiniSort3_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort4_flt: {
      id: 'tktXlsiniSort4_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort5_flt: {
      id: 'tktXlsiniSort5_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort6_flt: {
      id: 'tktXlsiniSort6_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort7_flt: {
      id: 'tktXlsiniSort7_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniSort8_flt: {
      id: 'tktXlsiniSort8_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniFile_flt: {
      id: 'tktXlsiniFile_flt',
      label: '_none',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 256,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['filename'],
      stringcase: '',
    },
    tktXlsiniPex_flt: {
      id: 'tktXlsiniPex_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '0',
      value: '0',
      text: '0',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniPrdEx_flt: {
      id: 'tktXlsiniPrdEx_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniPrdKb0_flt: {
      id: 'tktXlsiniPrdKb0_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniFltseg1_flt: {
      id: 'tktXlsiniFltseg1_flt',
      label: '1stSEG',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniFltseg2_flt: {
      id: 'tktXlsiniFltseg2_flt',
      label: '2ndSEG',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniFltseg3_flt: {
      id: 'tktXlsiniFltseg3_flt',
      label: '3rdSEG',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniFltseg4_flt: {
      id: 'tktXlsiniFltseg4_flt',
      label: '4thSEG',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniCsmtax_flt: {
      id: 'tktXlsiniCsmtax_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniPrd_flt: {
      id: 'tktXlsiniPrd_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '0',
      value: '0',
      text: '0',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    // tktXlsiniPlusType_flt: {
    //   id: 'tktXlsiniPlusType_flt',
    //   label: '_none',
    //   required: false,
    //   disabled: false,
    //   original: '1',
    //   value: '1',
    //   text: '1',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: [],
    //   stringcase: '',
    // },

    tktXlsiniGrossNetSame_flt: {
      id: 'tktXlsiniGrossNetSame_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniPcc_flt: {
      id: 'tktXlsiniPcc_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '0',
      value: '0',
      text: '0',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniTotal_flt: {
      id: 'tktXlsiniTotal_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '3',
      value: '3',
      text: '3',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktEmdMpdFlag_flt: {
      id: 'tktEmdMpdFlag_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktXlsiniFileFlag_flt: {
      id: 'tktXlsiniFileFlag_flt',
      label: '_none',
      required: false,
      disabled: false,
      original: '0',
      value: '0',
      text: '0',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
  },

  tktOutPccdiv: {
    id: 'tktOutPccdiv',
    label: '予約OFFICE ID/PCCごとにファイル分割する',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktOutPex: {
    id: 'tktOutPex',
    label: '_none',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktOutGrossNetSame: {
    id: 'tktOutGrossNetSame',
    label: '_none',
    required: false,
    disabled: false,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  
  patternName: {
    id: 'patternName',
    label: 'パターン名',
    placeholder: '',
    required: true,
    disabled: false,
    minLength: 0,
    maxLength: 40,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  cancel: {
    id: 'cancel',
    label: <span><span className='material-icons-outlined md'>cancel</span>キャンセル</span>,
    disabled: false,
  },
  setting: {
    id: 'setting',
    label: <span><span className='material-icons-outlined md'>file_upload</span>設定</span>,
    disabled: false,
  },
  choice: {
    id: 'choice',
    label: <span><span className='material-icons-outlined md'>edit_note</span>出力項目選択</span>,
    disabled: false,
  },
  outputExcel: {
    id: 'outputExcel',
    label: <span><span className='material-icons-outlined md'>file_upload</span>実行</span>,
    disabled: false,
  },
  pattern: {
    id: 'pattern',
    label: <span><span className='material-icons-outlined md'>settings</span>パターン管理</span>,
    disabled: false,
  },
  csv: {
    id: 'csv',
    label: <span><span className='material-icons-outlined md'>file_download</span>CSV出力</span>,
    disabled: false,
  },
}
// reducer
export const TktExcelReducer = handleActions({
  [TktExcelAction.changeInputTktExcel]: (state, action) => (onChangeInput(state, action.payload)),
  [TktExcelAction.changeInputTktExcelTkt]: (state, action) => ({...state, tkt: onChangeInput(state.tkt, action.payload)}),
  [TktExcelAction.changeInputTktExcelFlt]: (state, action) => ({...state, flt: onChangeInput(state.flt, action.payload)}),
  [TktExcelAction.setInputTktExcel]: (state, action) => ({...state, ...action.payload,}),
  [TktExcelAction.addTktExcelItem]: (state, action) => ({
    ...state,
    checkedItems: _.concat(state.checkedItems, action.payload)
  }),
  [TktExcelAction.removeTktExcelItem]: (state, action) => {
    const obj = Object.assign({}, state)
    _.map(state, (v,k) => {
      if (k.startsWith(action.payload[0].key + '_')) delete obj[k]
    })
    return ({
    ...obj,
    checkedItems: _.reject(state.checkedItems, {header: action.payload[0].header})//keyに変更
  })},
  [TktExcelAction.clearTktExcel]: (state, action) => (initState(initialState)),
  [TktExcelAction.initSession]: (state, action) => (initState(initialState)),
  [TktExcelAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)
