import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import * as wjc from '@grapecity/wijmo'
import { Button, Form, Nav } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { SystemAction } from 'ducks/System';
import { SystemApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import { EMPTY_SEARCH } from 'constants/master';
import { convert } from "lib";
import { MaintComponent as Maint } from 'components/System/MaintComponent';

export const MaintPopup = React.forwardRef((props, ref) => {

  const {popup, setPopup} = props

  const app = useSelector(state => state.App);
  const system = useSelector(state => state.System);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const systemAction = bindActionCreators(SystemAction, dispatch);

  // const [popup, setPopup] = React.useState(null)

  const initPopup = (form) => {
    setPopup(form)
    systemAction.setInputSystem({type: 'maint'})
  }

  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
        className='modal-timer'
        removeOnHide={false}
      >
        <Maint isModal isOpen={props.isOpen} onClose={(e) => popup.hide('cancel')} onSuccess={(e) => popup.hide('ok')}/>
      </wjInput.Popup>
    </React.Fragment>
  )
})