import * as React from 'react';
import * as ReactDOM from 'react-dom';
//
import { Form, InputGroup } from 'react-bootstrap';
import * as wijmo from '@grapecity/wijmo';
import * as wjInput from '@grapecity/wijmo.react.input';
import { convert } from 'lib'
//
export const DateTime = React.forwardRef((props, ref) => {

  const [el, setEl] = React.useState(null)
  const [isChanged, setIsChanged] = React.useState(null)
  const {attrs, range} = props;

  const getPredefinedRanges = () => {
    let dt = wijmo.DateTime, now = new Date();
    // 1P:1-7, 2P:8-15, 3P:16-23, 4P:24-99
    const p1s = dt.monthFirst(now)
    const p1e = dt.addDays(p1s, 6)
    const p2s = dt.addDays(p1e, 1)
    const p2e = dt.addDays(p2s, 7)
    const p3s = dt.addDays(p2e, 1)
    const p3e = dt.addDays(p3s, 7)
    const p4s = dt.addDays(p3e, 1)
    const p4e = dt.monthLast(now)
    return {
        // custom
        '任意': null,
        // months
        '前月': [dt.monthFirst(dt.addMonths(now, -1)), dt.monthLast(dt.addMonths(now, -1))],
        '今月': [dt.monthFirst(now), dt.monthLast(now)],
        // days
        '前日': [dt.addDays(now, -1), dt.addDays(now, -1)],
        '本日': [now, now],
        // period
        'ピリオド 1':   [p1s, p1e],
        'ピリオド 2':   [p2s, p2e],
        'ピリオド 3':   [p3s, p3e],
        'ピリオド 4':   [p4s, p4e],
        'ピリオド 1-2': [p1s, p2e],
        'ピリオド 1-3': [p1s, p3e],
        'ピリオド 2-3': [p2s, p3e],
        'ピリオド 2-4': [p2s, p4e],
        'ピリオド 3-4': [p3s, p4e],
    };
}

  const initialized = (s, e) => {
    setEl(s)
  }

  React.useEffect(() => {
    if (el) {
      wijmo.toggleClass(el.hostElement, 'is-invalid', attrs.isInvalid);
      wijmo.toggleClass(el.hostElement, 'is-valid', !attrs.isInvalid);
    }
  }, [el, attrs.isInvalid])

  React.useEffect(() => {
    if (isChanged) {
      props.onBlur(convert.getTarget(attrs, 'text', isChanged.value))
      setIsChanged(null)
    }
  }, [isChanged])

  return (
    <React.Fragment>
      <div 
        {...props.group}
        className={`${props.group.className} ${attrs.visible===false?'d-none':''}`}
      >
        <Form.Label
          sm="3"
          className={attrs.label==='_none' && 'd-none'}
          {...props.label}
          htmlFor={attrs.id}
        >
          {attrs.label}
        </Form.Label>
        <InputGroup className={`type-${attrs.type}`}>
          <wjInput.InputDateTime
            // selectionMode={range?'Range':'Day'}
            selectionMode={'Day'}
            // monthCount={range?2:1}
            monthCount={1}
            // predefinedRanges={getPredefinedRanges()}
            timeStep={attrs.type.startsWith('datetime')?5:0}
            id={attrs.id}
            min={attrs.min?attrs.min:null}
            value={attrs.text?attrs.text:null}
            // rangeEnd={range && range.text?range.text:null}
            rangeEnd={null}
            className={`type-${attrs.type}`}
            format={attrs.format}
            mask={attrs.mask}
            showDropDownButton={attrs.type.startsWith('date')}
            required={attrs.required}
            isRequired={attrs.isRequired}
            isDisabled={attrs.disabled}
            isInvalid={attrs.isInvalid}
            valueChanged={(e) => {
              // console.log(e)
              setIsChanged(e)
            }}
            // rangeEndChanged={(e) => {
            //   console.log(e)
            //   props.onBlur(convert.getTarget(range, 'text', e.rangeEnd))
            // }}
            initialized={(s, e) => initialized(s, e)}
          >
          </wjInput.InputDateTime>
          <Form.Control.Feedback type="invalid">
            {attrs.helpertext}
          </Form.Control.Feedback>
        </InputGroup>
      </div>
    </React.Fragment>
  )

})

