import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'components';
import * as Common from 'components/common';

import { Button } from 'react-bootstrap';
import _ from 'lodash';
import { RADIOS, CHECKS } from 'constants/search';
import { entryCheck, convert, validation } from "lib";
import { HELPER_MESSAGE } from 'constants/message';
// import '../styles/conditionCard.scss'

export const CardCodeRangeComponent = (props) => {

  const app = useSelector(state => state.App);
  const [selItems, setSelItems] = React.useState([])
  const [selValPath, setSelValPath] = React.useState('')
  const [dispMemPath, setDispMemPath] = React.useState('')
  const [srchMemPath, setSrchMemPath] = React.useState('')
  const [dataList, setDataList] = React.useState([])
  const [fromToCheck, setFromToCheck] = React.useState(false)
  const toElm = useRef(null);

  // 初期化
  React.useEffect(() => {
    let list = []
    let listType = ''
    switch (props.condition.key) {
      case 'tkt7LOdrCd':
        setSelValPath('orderCd')
        setDispMemPath('listName')
        setDataList(app.orderList)
        list = app.orderList
        listType = 'orderCd'
        setSrchMemPath('orderCd')
        break;
      case 'tkt7LSecCd':
        setSelValPath('sectionCd')
        setDispMemPath('listName')
        setDataList(app.sectionList)
        list = app.sectionList
        listType = 'sectionCd'
        setSrchMemPath('sectionCd')
        break;
      case 'tkt7LMbrCd':
        setSelValPath('memberCd')
        setDispMemPath('listName')
        setDataList(app.memberList)
        list = app.memberList
        listType = 'memberCd'
        setSrchMemPath('memberCd')
        break;
      case 'tkt7LAreaCd':
        setSelValPath('areaCd')
        setDispMemPath('listName')
        setDataList(app.areaList)
        list = app.areaList
        listType = 'areaCd'
        setSrchMemPath('areaCd')
        break;
      case 'tkt7LComCd':
        setSelValPath('companyCd')
        setDispMemPath('listName')
        setDataList(app.companyList)
        list = app.companyList
        listType = 'companyCd'
        setSrchMemPath('companyCd')
        break;
      case 'tkt7LPayCd':
        setSelValPath('payCd')
        setDispMemPath('listName')
        setDataList(app.payList)
        list = app.payList
        listType = 'payCd'
        setSrchMemPath('payCd')
        break;
      case 'tkt7LFreeCd':
        setSelValPath('freeCd')
        setDispMemPath('listName')
        setDataList(app.freeList)
        list = app.freeList
        listType = 'freeCd'
        setSrchMemPath('freeCd')
        break;
      case 'tkt7LTourCd':
        setSelValPath('tourCd')
        setDispMemPath('listName')
        setDataList(app.tourList)
        list = app.tourList
        listType = 'tourCd'
        setSrchMemPath('tourCd')
        break;
      case 'tkt7LKb1Cd':
        setSelValPath('kb1Cd')
        setDispMemPath('listName')
        setDataList(app.kb1List)
        list = app.kb1List
        listType = 'kb1Cd'
        setSrchMemPath('kb1Cd')
        break;
      case 'tkt7LKb2Cd':
        setSelValPath('kb2Cd')
        setDispMemPath('listName')
        setDataList(app.kb2List)
        list = app.kb2List
        listType = 'kb2Cd'
        setSrchMemPath('kb2Cd')
        break;
      case 'tkt7LEoCd':
        setSelValPath('eoCd')
        setDispMemPath('listName')
        setDataList(app.eoList)
        list = app.eoList
        listType = 'eoCd'
        setSrchMemPath('eoCd')
        break;
    }
    clear(true)
    setTimeout(() => {
      _.forEach(props.search, (item, key) => {
        if (key === props.condition.key + '_array') {
          if (_.isArray(item.text)) {
            if (_.isObject(item.text[0])) {
              setSelItems(item.text)
            } else {
              let arr = []
              _.forEach(item.text, (r, i) => {
                let find = _.find(list, data => data[listType] === r)
                if (!find) {
                  find = {}
                  find[listType] = r
                  find['listName'] = r
                }
                arr.push(find)
              })
              setSelItems(arr)
            }
          }
        }
      })
    })
  }, [props.condition.key])

  // clear
  const clear = (isKeep) => {
    const obj = {}
    if (!isKeep) setSelItems([])
    if (!props.items[props.items.length-1].value || !isKeep) {
      obj[props.items[props.items.length-1].id] = {
        ...props.items[props.items.length-1],
        original: RADIOS.clude[0].value,
        value: RADIOS.clude[0].value,
        text: RADIOS.clude[0].value,
      }
    }
    if (!props.items[props.items.length-2].value || !isKeep) {
      obj[props.items[props.items.length-2].id] = {
        ...props.items[props.items.length-2],
        original: RADIOS.choiceBlank[2].value,
        value: RADIOS.choiceBlank[2].value,
        text: RADIOS.choiceBlank[2].value,
      }
    }
    !isKeep && _.forEach(props.items, (r, i) => {
      if (i < props.items.length-2 && !r.id.endsWith('_array')) {
        obj[r.id] = {
          ...r,
          original: '',
          value: '',
          text: '',
        }
      }
    })
    Object.keys(obj).length && props.setInput({...obj})
  }

  React.useEffect(() => {
    _.map(props.items, (r, i) => {
      if (r.id.endsWith('array')) {
        const obj = convert.getTarget(r, 'text', selItems)
        delete obj.id
        props.setInput(obj)
      }
    })
  }, [selItems])

  const getTemp = () => {
    switch (props.condition.key) {
      case 'tkt7LOdrCd':
      case 'tkt7LSecCd':
      case 'tkt7LAreaCd':
      case 'tkt7LPayCd':
      case 'tkt7LFreeCd':
      case 'tkt7LTourCd':
      case 'tkt7LKb1Cd':
      case 'tkt7LKb2Cd':
      case 'tkt7LEoCd':
        return '<table><tr>' +
          '<td class="width-5" title="コード">{cd}</td>' +
          '<td class="" title="名称">{namej}</td>' +
          '</tr></table>'
      case 'tkt7LMbrCd':
        return '<table><tr>' +
          '<td class="width-5" title="コード">{mbrCd}</td>' +
          '<td class="" title="名称">{mbrNamej}</td>' +
          '</tr></table>'
      case 'tkt7LComCd':
        return '<table><tr>' +
          '<td class="width-5" title="コード">{comCd}</td>' +
          '<td class="" title="名称">{comNamej}</td>' +
          '</tr></table>'
    }
  }

  React.useEffect(() => {
    if (fromToCheck) {
      const fromAry = _.filter(props.items, (r, i) => _.endsWith(r.id, 'from'))
      const toAry = _.filter(props.items, (r, i) => _.endsWith(r.id, 'to'))
      const from = (fromAry && fromAry.length > 0)? fromAry[0]:{}
      const to = (toAry && toAry.length > 0)? toAry[0]:{}
      let isOk = true
      // console.log(from.text)
      // console.log(to.text)
      if (!_.isNil(from.text) && from.text !== '' && (_.isNil(to.text) || to.text === '')) {
        props.onBlur(convert.getTarget(to, 'text', from.text))
      }
      if (!_.isNil(from.text) && !_.isNil(to.text) && from.text !== '' && to.text !== '') {
        // if (!_.isNaN(_.toNumber(from.text)) && !_.isNaN(_.toNumber(to.text))) {
        //   if (_.toNumber(from.text) > _.toNumber(to.text)) isOk = false
        // } else {
          if (from.text > to.text) isOk = false
      //   }
      }
      // console.log(isOk)
      let obj = {}
      if (!isOk) {
        obj[to.id] = {
          ...to,
          value: '',
          text: '',
        }
      }
      props.setInput(obj)
      setFromToCheck(false)
    }
  }, [fromToCheck])

  const getBody = (choiceType) => {
    let getItem = null;
    switch (choiceType) {
      case RADIOS.choiceBlank[0].value: 
        getItem = null;
        break;
      case RADIOS.choiceBlank[1].value:
        getItem = _.map(props.items, (r, i) => {
          if (_.endsWith(r.id, 'from'))
            return (
              <React.Fragment key={i}>
                <Common.Text
                  key={i}
                  attrs={r}
                  onBlur={(e) => {
                    // props.onBlur(e)
                    if (r.validtype.length > 0 && !validation[r.validtype](e[r.id].text)) {
                      e[r.id].text = ''
                    }
                    props.onBlur(e)
                    setTimeout(() => {
                      setFromToCheck(true)
                    })
                  }}
                  group={{
                    ...props.group,
                    className: 'width-10 mr-2 mb-1'
                  }}
                  onChange={(e) => {
                    if (e[r.id].text.length == r.maxLength) toElm.current.focus()
                  }}
                />
                <div className='text-center pt-2 mr-2'>～</div>
              </React.Fragment>
            )
          else if (_.endsWith(r.id, 'to'))
            return (
              <React.Fragment key={i}>
                <Common.Text
                  ref={toElm}
                  key={i}
                  attrs={r}
                  onBlur={(e) => {
                    // props.onBlur(e)
                    if (r.validtype.length > 0 && !validation[r.validtype](e[r.id].text)) {
                      e[r.id].text = ''
                    }
                    props.onBlur(e)
                    setTimeout(() => {
                      e[r.id].text && setFromToCheck(true)
                    })
                  }}
                  group={{
                    ...props.group,
                    className: 'width-10 mr-2'
                  }}
                />
              </React.Fragment>
            )
          else return
        })
        break;
      case RADIOS.choiceBlank[2].value:
        getItem = _.map(props.items, (r, i) => {
          if (r.id.endsWith('array')) {
            return (
              <React.Fragment key={i}>
                <Common.MultiSelect
                  key={i}
                  onBlur={(e) => props.onBlur(e)}
                  attrs={r}
                  group={{
                    className: 'width-max-content mr-2 mb-1',
                    textwidth: 'min-width-13',
                    textsuffixwidth: 'max-width-8',
                    inputwidth: '150px'
                  }}
                  // maxSelectedItems={10}
                  minLength={1}
                  selectedValuePath={selValPath}
                  displayMemberPath={dispMemPath}
                  list={dataList}
                  searchMemberPath={srchMemPath}
                  selectedItems={selItems}
                  setSelectedItems={setSelItems}
                  template={getTemp()}
                  isEditable={true}
                  isWildCard={props.condition.isWildCard}
                />
              </React.Fragment>
            )
          } else return
        })
        break;
    }

    return (
      <React.Fragment>
        <div className='card-condition-item'>
          <Common.RadioButton
            btnSize='at'
            attrs={props.items[props.items.length-2]}
            radios={RADIOS.choiceBlank}
            className='width-28 min-width-28 mr-3'
            onChange={(e) => props.onBlur(e)}
          />
          {/* <div className='inner'> */}
            {getItem}
          {/* </div> */}
        </div>
        <div className='d-inline-block mb-1'>
          <Button
            id='clear'
            className='button-gray small width-max-content'
            onClick={(e) => clear()}
          >
            クリア
          </Button>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Card 
        size={props.cardSize}
        header={
          <React.Fragment>
            <span>{props.condition.header}</span>
            <Common.RadioButton
              btnSize='sm'
              attrs={props.items[props.items.length-1]}
              radios={RADIOS.clude}
              onChange={(e) => props.onBlur(e)}
              className='width-12 min-width-12 mr-3'
            />
          </React.Fragment>
        }
        subheader={props.items[props.items.length-2].text===RADIOS.choiceBlank[2].value?props.condition.subheader:null}
        body={getBody(props.items[props.items.length-2].text)}
        condition={props.condition}
        onClose={props.onClose}
      />
    </React.Fragment>
  )
}

CardCodeRangeComponent.defaultProps = {};
