import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert, validation } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';

export const CardPane = (props) => {

  const tktEdit = useSelector(state => state.TktEdit);
  
  const dispatch = useDispatch();
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  return (
    <React.Fragment>
      <div className='d-flex'>
        <Common.Mask
          attrs={{
            ...tktEdit.tktCaExdate,
            labelclass: 'width-max-content'
          }}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-5-5 mr-4',
          }}
        />
        <Common.Text
          attrs={{...tktEdit.tktCaMem}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-34 ml-2',
          }}
        />
      </div>
      <div className='d-flex'>
        <Common.Text
          attrs={{
            ...tktEdit.tktCaAprid,
            labelclass: 'width-max-content'
          }}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-5-3 mr-5',
          }}
        />
        <Common.Text
          attrs={{...tktEdit.tktCaAprno}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-5-10 ml-2 mr-3',
          }}
        />
        <Common.Number
          attrs={{...tktEdit.tktCaApramt}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-10 mr-0',
            textwidth: 'width-p-100 mr-0'
          }}
        />
      </div>
      <div className='d-flex'>
        <Common.Text
          attrs={{
            ...tktEdit.tktFuncCd,
            labelclass: 'width-max-content'
          }}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-5-3 mr-5',
          }}
        />
        <Common.Text
          attrs={{...tktEdit.tktRmks1}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-14 ml-2 mr-2',
          }}
        />
        <Common.Text
          attrs={{...tktEdit.tktRmks2}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-14 mr-2',
          }}
        />
        <Common.Text
          attrs={{...tktEdit.tktRmks3}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-14 mr-0',
          }}
        />
      </div>
    </React.Fragment>
  )
}