import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass, setSelectionRange } from "@grapecity/wijmo";
import { InputDate, InputTime, ComboBox, AutoComplete, MultiAutoComplete, InputColor, InputNumber } from '@grapecity/wijmo.input';
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE, LIST_ITEMS, TKT_KB_FLAG_MAP } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';

export const TaxPane = (props) => {

  const app = useSelector(state => state.App);
  const tktEdit = useSelector(state => state.TktEdit);
  
  const dispatch = useDispatch();
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  const [grid, setGrid] = React.useState(null)

  const getColumn = () => {
    const column = [].concat(LIST_ITEMS.tax)
    _.forEach(column, (r, i) => {
      switch (r.id) {
        case 'taxTaxAmt':
          // r.editor = new InputNumber(document.createElement('div'), {
          //   min: r.min,
          //   max: r.max,
          //   gotFocus: (s, e) => {
          //     const length = s.text.length
          //     setTimeout(() => {
          //       setSelectionRange(s.inputElement, length)
          //     })
          //   }
          // });
          break;
      }
    })
    return column
  }

  const itemFormatter = (panel, r, c, cell) => {
    // 列ヘッダは中央
    if (panel.cellType == 2) {
      cell.style.textAlign = 'center';
    }
  }

  const gridFormatItem = (g, e) => {
    const item = e.getRow().dataItem
    const bind = e.getColumn().binding
    _.forEach(g.rowHeaders.rows, (r, i) => {
      // g.rowHeaders.setCellData(i, 0, i + 1)
      toggleClass(e.cell, 'wj-wrap', g.columnHeaders === e.panel);
    })
    if (['taxTaxAmt', 'taxTaxInd'].includes(g.columns[e.col].binding)) {
      if (g.getCellData(e.row, e.col) < 0 && e.cell.className.indexOf('header') < 0) {
        e.cell.style.color = 'Red'
      } else e.cell.style.color = 'Black';
    }
  }

  return (
    <React.Fragment>
      <div className='tax-list-body' >
        <Common.Grid
          thisGrid={grid}
          setGrid={setGrid}
          colDef={getColumn()}
          // masterType={master.type}
          dataList={tktEdit.bstTktTaxListDto}
          // setRowHeaderWidth={setRowHeaderWidth}
          // setColWidth={setColWidth}
          noSelector={true}
          grid={{
            isReadOnly: app.tktEditFormMode==='3',
            allowAddNew: false,
            // frozenColumns: 1,
            imeEnabled: false,
            gridFormatItem: (g, e) => gridFormatItem(g, e),
            // colHeaderDefHeight: 52,
            itemFormatter: (panel, r, c, cell) => itemFormatter(panel, r, c, cell),

          }}
          setInput={tktEditAction.setInputTktEdit}
        />
      </div>
    </React.Fragment>
  )
}