import { handleActions, createAction } from 'redux-actions'
import { onChangeInput, initState } from 'lib'
import { TOOL_TIP } from 'constants/search';
import _ from 'lodash';

// const
export const TKT_SEARCH = {
  CHANGE_INPUT_TKT_SEARCH : 'CHANGE_INPUT_TKT_SEARCH',
  CHANGE_INPUT_TKT_SEARCH_BULK : 'CHANGE_INPUT_TKT_SEARCH_BULK',
  SET_INPUT_TKT_SEARCH : 'SET_INPUT_TKT_SEARCH',
  ADD_TKT_SEARCH_ITEM : 'ADD_TKT_SEARCH_ITEM',
  REMOVE_TKT_SEARCH_ITEM : 'REMOVE_TKT_SEARCH_ITEM',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
};

// action
export const TktSearchAction = {
  changeInputTktSearch: createAction(TKT_SEARCH.CHANGE_INPUT_TKT_SEARCH, (args) => (args)),
  changeInputTktSearchBulk: createAction(TKT_SEARCH.CHANGE_INPUT_TKT_SEARCH_BULK, (args) => (args)),
  setInputTktSearch: createAction(TKT_SEARCH.SET_INPUT_TKT_SEARCH, (args) => (args)),
  addTktSearchItem: createAction(TKT_SEARCH.ADD_TKT_SEARCH_ITEM, (args) => (args)),
  removeTktSearchItem: createAction(TKT_SEARCH.REMOVE_TKT_SEARCH_ITEM, (args) => (args)),
  initSession: createAction(TKT_SEARCH.INIT_SESSION),
  clearSession: createAction(TKT_SEARCH.CLEAR_SESSION),
}

// initialState
const initialState = {
  sizeHorizontal: ['100%', '1'],
  sizeVertical: ['20%', '1'],
  condition: {
    id: 'condition',
    label: '',
    required: true,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  checkedItems: [
    
  ],
  checkedItemsSetting: [],
  recentlyCondition: [],
  conditionPattern: [],
  patternList: [],
  patternName: {
    id: 'patternName',
    label: '名称',
    required: true,
    disabled: false,
    minLength: 0,
    maxLength: 30,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  searchUnit: {
    id: 'searchUnit',
    label: '',
    prefix: '表示単位',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  condClear: {
    id: 'clear',
    label: <span><span className='material-icons-outlined md'>delete_forever</span>検索条件全クリア</span>,
    disabled: false,
  },
  condEdit: {
    id: 'edit',
    label: <span><span className='material-icons-outlined md'>cloud_upload</span>検索条件パターン登録</span>,
    disabled: false,
  },
  search: {
    id: 'search',
    label: <span><span className='material-icons-outlined md'>search</span>発券データ検索</span>,
    disabled: false,
  },

// 一覧表示
  dispCheckedItems: [
    {
      id: 'tktTktnoAl',
      header: 'TKT NO.（AIRLINEコード）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktTktnoSn',
      header: 'TKT NO.（S/N）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktPaxName',
      header: 'PAX NAME',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktStatus',
      header: 'TKTステータス',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktIsuDate',
      header: '発券日',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktDepDate',
      header: '出発日',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktDetailIssuedFlg',
      header: 'チケット明細発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
    },
  ],
  bulk: {
    // アカウントコード
    tkt7lOdrCd:{
      id: 'tkt7lOdrCd',
      label: '受注コード(R)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 12,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: 'upper',
    },
    tkt7lSecCd:{
      id: 'tkt7lSecCd',
      label: 'セクションコード(S)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 6,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: 'upper',
    },
    tkt7lMbrCd:{
      id: 'tkt7lMbrCd',
      label: '担当者コード(A)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 6,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: 'upper',
    },
    tkt7lComCd:{
      id: 'tkt7lComCd',
      label: '法人コード(C)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 10,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: 'upper',
    },
    tkt7lAreaCd:{
      id: 'tkt7lAreaCd',
      label: '方面コード(D)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 3,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: 'upper',
    },
    tkt7lFreeCd:{
      id: 'tkt7lFreeCd',
      label: 'FREEコード(F)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 10,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: 'upper',
    },
    tkt7lPayCd:{
      id: 'tkt7lPayCd',
      label: '入金コード(M)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 1,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: 'upper',
    },
    tkt7lTourCd:{
      id: 'tkt7lTourCd',
      label: 'ツアーコード(T)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 15,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: 'upper',
    },
    tkt7lKb1Cd:{
      id: 'tkt7lKb1Cd',
      label: 'KBコード1(K)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 7,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: 'upper',
    },
    tkt7lKb2Cd:{
      id: 'tkt7lKb2Cd',
      label: 'KBコード2(B)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 7,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: 'upper',
    },
    tkt7lEoCd:{
      id: 'tkt7lEoCd',
      label: 'E/Oコード(E)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 7,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: 'upper',
    },
    tktFareType:{
      id: 'tktFareType',
      label: '運賃種別',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: -1,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: 'NOM',
      value: 'NOM',
      text: 'NOM',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktTktType:{
      id: 'tktTktType',
      label: '券種',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: -1,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '6',
      value: '6',
      text: '6',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktPaxType:{
      id: 'tktPaxType',
      label: 'PAX TYPE',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: -1,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: ' ',
      value: ' ',
      text: ' ',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktVoidId:{
      id: 'tktVoidId',
      label: 'VOID ID',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: -1,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: ' ',
      value: ' ',
      text: ' ',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktOvoId:{
      id: 'tktOvoId',
      label: 'オンラインVOID ID',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: -1,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: ' ',
      value: ' ',
      text: ' ',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktVoidDate:{
      id: 'tktVoidDate',
      label: 'VOID処理日',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: -1,
      width: '100%',
      margin: '10px',
      enter: '',
      type: 'date1',
      format: 'yyyy/MM/dd',
      mask: '9999/99/99',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktTokuiClm:{
      id: 'tktTokuiClm',
      label: '得意先請求金額(入力)',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 10,
      min: 0,
      max: 99999999,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktCrs:{
      id: 'tktCrs',
      label: 'GDS区分',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: -1,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '5',
      value: '5',
      text: '5',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktDetailIssuedFlg:{
      id: 'tktDetailIssuedFlg',
      label: 'チケット明細発行済フラグ',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 1,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktCustomerOutputFlg:{
      id: 'tktCustomerOutputFlg',
      label: 'お客様用発行済フラグ',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 1,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktAgentCouponOutputFlg:{
      id: 'tktAgentCouponOutputFlg',
      label: 'Agent Coupon発行済フラグ',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 1,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktCccfOutputFlg:{
      id: 'tktCccfOutputFlg',
      label: 'CCCF発行済フラグ',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 1,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    tktRefundNoticeOutputFlg:{
      id: 'tktRefundNoticeOutputFlg',
      label: 'REFUND NOTICE発行済フラグ',
      placeholder: '',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 1,
      width: '100%',
      margin: '10px',
      enter: '',
      type: '',
      format: '',
      original: '1',
      value: '1',
      text: '1',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
  },
  windowOpen: {
    id: 'windowOpen',
    label: '',
    required: false,
    disabled: false,
    original: 'O',
    value: 'O',
    text: 'O',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  delete: {
    id: 'search',
    label: <span><span className='material-icons-outlined md'>delete</span>削除</span>,
    disabled: false,
  },
  display: {
    id: 'display',
    label: <span><span className='material-icons-outlined md'>visibility</span>参照</span>,
    disabled: false,
  },
  edit: {
    id: 'edit',
    label: <span><span className='material-icons-outlined md'>edit_note</span>編集</span>,
    disabled: false,
  },
  new: {
    id: 'new',
    label: <span><span className='material-icons-outlined md'>add_circle_outline</span>新規</span>,
    disabled: false,
  },
  copy: {
    id: 'copy',
    label: <span><span className='material-icons-outlined md'>content_copy</span>複製</span>,
    disabled: false,
  },
  csv: {
    id: 'csv',
    label: <span><span className='material-icons-outlined md'>file_download</span>CSV出力</span>,
    disabled: false,
  },
  menu: {
    id: 'menu',
    label: <span>処理メニュー</span>,
    disabled: false,
    submenu: [
      {
        id: 'menu1',
        label: <span>発券データ出力</span>,
        disabled: false,
      },
      {
        id: 'menu2',
        label: <span>EXCELデータ出力</span>,
        disabled: false,
      },
      {
        id: 'menu3',
        label: <span>URI-Pro BI</span>,
        disabled: false,
      },
      {
        id: 'menu4',
        label: <span>固定帳票印刷</span>,
        disabled: false,
      }
    ]
  },
  research: {
    id: 'research',
    label: <span><span className='material-icons-outlined md'>search</span>再検索</span>,
    disabled: false,
  },

}
// reducer
export const TktSearchReducer = handleActions({
  [TktSearchAction.changeInputTktSearch]: (state, action) => (onChangeInput(state, action.payload)),
  [TktSearchAction.changeInputTktSearchBulk]: (state, action) => ({...state, bulk: onChangeInput(state.bulk, action.payload)}),
  [TktSearchAction.setInputTktSearch]: (state, action) => ({...state, ...action.payload,}),
  [TktSearchAction.addTktSearchItem]: (state, action) => ({
    ...state,
    checkedItems: _.concat(state.checkedItems, action.payload)
  }),
  [TktSearchAction.removeTktSearchItem]: (state, action) => {
    const obj = Object.assign({}, state)
    _.map(state, (v,k) => {
      if (k.startsWith(action.payload[0].key + '_')) delete obj[k]
    })
    return ({
    ...obj,
    checkedItems: _.reject(state.checkedItems, {header: action.payload[0].header})//keyに変更
  })},
  [TktSearchAction.initSession]: (state, action) => (initState(initialState)),
  [TktSearchAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)
