import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass, setSelectionRange } from "@grapecity/wijmo";
import { InputDate, InputTime, ComboBox, AutoComplete, MultiAutoComplete, InputColor, InputNumber } from '@grapecity/wijmo.input';
import { CellRange, DataMap } from "@grapecity/wijmo.grid";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE, LIST_ITEMS, TKT_KB_FLAG_MAP, XO_MAP } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';

export const FlightPane = (props) => {

  const app = useSelector(state => state.App);
  const tktEdit = useSelector(state => state.TktEdit);
  
  const dispatch = useDispatch();
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  const [grid, setGrid] = React.useState(null)
  const [data, setData] = React.useState([])

  const gridFormatItem = (g, e) => {
    const item = e.getRow().dataItem
    const bind = e.getColumn().binding
    _.forEach(g.rowHeaders.rows, (r, i) => {
      // g.rowHeaders.setCellData(i, 0, i + 1)
      toggleClass(e.cell, 'wj-wrap', g.columnHeaders === e.panel);
    })
    if (item) {
      if (bind === 'fltTktno') {
        toggleClass(e.cell, 'text-indent-200p', (e.row-1) >= 0 && e.getRow().dataItem[bind] === g.rows[e.row-1].dataItem[bind]);
      }
    }
  }

  const getColumn = () => {
    const column = [].concat(LIST_ITEMS.flight)
    _.forEach(column, (r, i) => {
      switch (r.id) {
        // case 'dcdAlnCd':
        //   r.dataMap = app.alList && new DataMap(app.alList, 'alnCd2', 'alnCd2')
        //   r.editor = new AutoComplete(document.createElement('div'), {
        //     headerPath: 'alnCd2',
        //     itemsSource: app.alList,
        //     isEditable: false,
        //     minLength: 1,
        //     delay: 10,
        //     selectedValuePath: 'alnCd2',
        //     displayMemberPath: 'listName',
        //     searchMemberPath: 'alnCd2,alnCdn',
        //     isEditable : false,
        //     formatItem: (c, e) => alnFormatItem(c, e),
        //   })
        //   break;
        case 'fltStopoverCode':
          r.dataMap = new DataMap(XO_MAP, 'id', 'name')
          r.editor = new AutoComplete(document.createElement('div'), {
            itemsSource: XO_MAP,
            isEditable: false,
            minLength: 1,
            delay: 10,
            selectedValuePath: 'id',
            displayMemberPath: 'name',
            showDropDownButton: false,
          })
          break;
        case 'fltBoardDate':
        case 'fltArrDate':
        case 'fltNvb':
        case 'fltNva':
          r.editor = new InputDate(document.createElement('div'), {
            format: 'yyyy/MM/dd',
            mask: '0000/00/00',
            isRequired: false,
            autoExpandSelection: false,
            showDropDownButton: false,
            clickAction: 'Open',
            gotFocus: (s, e) => {
              let start = 1, end = 1
              if (!isNaN((new Date(s.text)).getTime()) || s.value === '____/__/__') {
                start = 0
                end = 10
              }
              setSelectionRange(s.inputElement, start, end)
            }
          });
          break;
        case 'fltBoardTime':
        case 'fltArrTime':
          r.editor = new InputTime(document.createElement('div'), {
            format: 'HH:mm',
            mask: '00:00',
            isRequired: false,
            autoExpandSelection: false,
            showDropDownButton: false,
            gotFocus: (s, e) => {
              let start = 1, end = 1
              if (!isNaN((new Date(s.text)).getTime()) || s.value === '__:__') {
                start = 0
                end = 5
              }
              setSelectionRange(s.inputElement, start, end)
            }
          });
          break;
        case 'fltKb1':
          r.isReadOnly = tktEdit.tktKbFlag.text !== '1'
          break;
        // default:
        //   r.dataMap = new DataMap(DECODE_MAP, 'id', 'name')
        //   r.editor = new AutoComplete(document.createElement('div'), {
        //     itemsSource: DECODE_MAP,
        //     isEditable: false,
        //     minLength: 1,
        //     delay: 10,
        //     selectedValuePath: 'id',
        //     displayMemberPath: 'name'
        //   })
      }
    })
    return column
  }

  // サポート額取得
  React.useEffect(() => {
    if (app.isProcessing || props.gridEditing) return 
      if (tktEdit.tktKbFlag.text !== '1') {
        // console.log('サポート額取得')
        // const flight = grid && grid.collectionView.currentItem
        _.forEach(grid && grid.collectionView.items, (r, i) => {
          if (r.fltCpnNo || r.fltAirlineCd || r.fltFltNum || r.fltClass || r.fltFrom
            || r.fltTo || r.fltFareBasis || r.fltBoardDate || r.fltBoardTime || r.fltArrDate
            || r.fltArrTime || r.fltNvb || r.fltNva || r.fltBagAlw) {
            const dto = {
              tktTktnoAl: r.fltTktnoAl,
              fltAirlineCd_1: r.fltAirlineCd,
              fltFltNum_1: r.fltFltNum,
              fltFareType_1: tktEdit.tktFareType.text,
              fltBoardDate_1: r.fltBoardDate?convert.toDateString(r.fltBoardDate):null,
              fltFrom_1: r.fltFrom,
              fltTo_1: r.fltTo,
              fltClass_1: r.fltClass,
              fltPaxType_1: tktEdit.tktPaxType.text,
              // fltKb1_1: ,
            }
            TicketApi.calcFltProductKbAMT(dto)
            .then(res => {
              if (res.payload.code === '000') {
                // console.log(res.payload.kbAmount_1)
                r.fltKb1 = res.payload.kbAmount_1
              }
            })
            .catch(err => {
              r.fltKb1 = 0
            })
            .finally(() => {
              grid.refresh()
            })
          } else {
            r.fltKb1 = null
          }
        })
      }
  // ロード時に計算しないのであれば、app.processingは外す
  }, [app.isProcessing, props.gridEditing, tktEdit.tktKbFlag.text, tktEdit.tktPaxType.text, tktEdit.tktFareType.text, grid && grid.collectionView.items])

  React.useEffect(() => {
    if (tktEdit.tktKbFlag.text === '1') {
      _.forEach(grid && grid.collectionView.items, (r, i) => {
        if (r.fltCpnNo || r.fltAirlineCd || r.fltFltNum || r.fltClass || r.fltFrom
          || r.fltTo || r.fltFareBasis || r.fltBoardDate || r.fltBoardTime || r.fltArrDate
          || r.fltArrTime || r.fltNvb || r.fltNva || r.fltBagAlw) {
            // console.log('サポート額クリア')
          r.fltKb1 = 0
        }
      })
    }
  }, [tktEdit.tktKbFlag.text])

  const itemFormatter = (panel, r, c, cell) => {
    // 列ヘッダは中央
    if (panel.cellType == 2) {
      cell.style.textAlign = 'center';
    }
  }

  return (
    <React.Fragment>
      <div>
        <Common.Radio
          className='d-flex flex-row align-top justify-content-end'
          attrs={tktEdit.tktKbFlag}
          radios={TKT_KB_FLAG_MAP}
          onChange={(e) => {
            tktEditAction.changeInputTktEdit(e)
          }}
        />
      </div>
      <div className='flight-list-body' >
        <Common.Grid
          thisGrid={grid}
          setGrid={setGrid}
          colDef={getColumn()}
          // masterType={master.type}
          dataList={tktEdit.fltDataListDto}
          // setRowHeaderWidth={setRowHeaderWidth}
          // setColWidth={setColWidth}
          noSelector={true}
          defaultHeaderSize={60}
          grid={{
            isReadOnly: app.tktEditFormMode==='3',
            allowAddNew: false,
            // frozenColumns: 1,
            imeEnabled: false,
            headersVisibility: 'Column',
            autoSizingColumn: true,
            // autoRowHeights: true,
            gridFormatItem: (g, e) => gridFormatItem(g, e),
            colHeaderDefHeight: 92,
            itemFormatter: (panel, r, c, cell) => itemFormatter(panel, r, c, cell),
            gridEditing: props.gridEditing ,
            setGridEditing: props.setGridEditing,
          }}
          setInput={tktEditAction.setInputTktEdit}
        />
      </div>
    </React.Fragment>
  )
}