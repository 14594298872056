import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { PivotAction } from 'ducks/Pivot';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { CHART_TYPE } from 'constants/pivot';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';
import { SummaryApi } from 'webapi'

export const PatternNamePopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const pivot = useSelector(state => state.Pivot);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);
  const pivotAction = bindActionCreators(PivotAction, dispatch);

  const [pat, setPat] = React.useState('')

  const initPopup = (form) => {
    props.setPopup(form)
  }

  React.useEffect(() => {
    if (props.popupData && props.popupData.selReport && props.popupData.selReport.reportTitle) {
      pivotAction.setInputPivot({
        reportTitle: {
          ...pivot.reportTitle,
          original: props.popupData.selReport.reportTitle,
          value: props.popupData.selReport.reportTitle,
          text: props.popupData.selReport.reportTitle,
        }
      })
    }
  }, [props.popupData])

  const action = () => {
    let entry = {};
    
    entry = entryCheck(pivot, convert.getTarget(pivot.reportTitle, 'text', pivot.reportTitle.text))
    pivotAction.setInputPivot(entry)
    if (entry.reportTitle.isInvalid) return
    // 
    appAction.showProcessing({isProcessing: true})

    const dto = {
      reportTitle: pivot.reportTitle.text,
      reportType: '1',
      reportTabType: props.popupData.reportTabType,
      viewDefinition: props.popupData.viewDefinition,
      nsbPrintType: pivot.nsbFlg.text==='1'?pivot.nsbOption.text:pivot.nsbFlg.text,
      faretypeConversionFlg: pivot.faretypeConversionFlg.text,
      taxIncludeFlg: pivot.taxIncludeFlg.text,
      productionJudgeFlg: pivot.productionJudgeFlg.text,
      voidPrintFlg: pivot.voidPrintFlg.text,
      cnjtktAmountPringFlg: pivot.cnjtktAmountPringFlg.text,
      displayRowSubtotalFlg: pivot.displayRowSubtotalFlg.text,
      displayRowTotalFlg: pivot.displayRowTotalFlg.text,
      displayColumnSubtotalFlg: pivot.displayColumnSubtotalFlg.text,
      displayColumnTotalFlg: pivot.displayColumnTotalFlg.text,
      displayComponentRatioFlg: pivot.displayComponentRatioFlg.text,
      displayYoyFlg: pivot.displayYoyFlg.text,
      graphType: _.findIndex(CHART_TYPE, ['value', pivot.graphType.text]),
    }
    if (props.popupData.action==='update') {
      dto.id = props.popupData.selReport.id
    }
    SummaryApi.save(dto)
    .then(res => {
      // console.log(res.payload)
      appAction.setMessage('')
      if (res.payload.code === '000') {
        let reportList = []
        let defaultReportList = []
        let customReportList = []
  
        _.forEach(res.payload.bizReportInfoDtoList, (r, i) => {
          if (r.reportType === '0') {
            defaultReportList.push({...r, isheader: false})
          } else {
            customReportList.push({...r, isheader: false})
          }
        })
        if (customReportList.length > 0) {
          reportList.push({
            isheader: true,
            reportTitle: 'カスタムレポート',
            items: _.orderBy(customReportList, 'id', 'desc')
          })
        }
        if (defaultReportList.length > 0) {
          reportList.push({
            isheader: true,
            reportTitle: 'URI-Proオリジナルレポート',
            items: _.orderBy(defaultReportList, 'id', 'desc')
          })
        }
        pivotAction.setInputPivot({
          reportList: reportList,
          // size1:['15%', '1'],
        })
        let id = -1
        // if (props.popupData && props.popupData.selReport){
        //   id = props.popupData.selReport.id
        // } else {
          id = res.payload.bizReportInfoDtoList[res.payload.bizReportInfoDtoList.length -1 ].id
        // }
        // console.log(id)
        // console.log(_.find(customReportList, r => r.id === id))
        props.popupData.setSelReport(_.find(customReportList, r => r.id === id))
        // props.setInput({
        //   // recentlyCondition: getPattern(res.payload.patternMap, '最近使った検索条件'),
        //   // checkedItemsSetting: getPattern(res.payload.patternMap, '前回検索条件'),
        //   patternList: getPatternList(res.payload.patternList),
        //   patternMap: res.payload.patternMap,
        // })

        if (props.transitionCheck) props.transitionCheck()
      } else {
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
      popupClose()
    })
  }

  const popupClose = () => {
    let obj = {
      reportTitle: {
        ...pivot.reportTitle,
        isInvalid: false,
        text: '',
        value: '',
        dirty: '',
      }}
    pivotAction.setInputPivot(obj)
    props.popup.hide()
  }

  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
      >
        <Card 
          root='popup'
          size='lg'
          hideClose
          header={props.header}
          headerClass='wj-dialog-header'
          subheader={props.subheader}
          // body={props.body}
          body={
            <React.Fragment>
              <Common.Text
                attrs={{
                  ...pivot.reportTitle,
                  disabled: props.popupData && props.popupData.action === 'update'
                }}
                onBlur={(e) => pivotAction.changeInputPivot(e)}
                group={{
                  className: 'width-50 mr-2'
                }}
              />
            </React.Fragment>
          }
        />
        <Card 
          size='md'
          root={`popup-button ${props.size}`}
          // body={props.button}
          body={
            <React.Fragment>
              <Button className='button-gray mr-auto' onClick={() => popupClose()}>
                <span className='material-icons-outlined md mr-2'>cancel</span>
                キャンセル
              </Button>
              <Button
                className='button-blue ml-auto'
                onClick={() => action()}
                disabled={app.isProcessing}
              >
                <span className='material-icons-outlined md'>file_upload</span>登録
              </Button>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})