import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert, calc } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';

export const FarePane = (props) => {

  const tktEdit = useSelector(state => state.TktEdit);
  
  const dispatch = useDispatch();
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);
  const formPayElm = useRef(null);

  // React.useEffect(() => {
  //   console.log(calc.getTaxRate(new Date()))
  // }, [tktEdit.tktIsuDate.text])

  return (
    <React.Fragment>
      <div className='d-flex'>
        <Common.Text
          attrs={{...tktEdit.tktFareCalMode}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'min-width-3 width-3 mr-2',
          }}
        />
        <Common.Text
          attrs={{...tktEdit.tktFareCalPricingInd}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'min-width-3 width-3 mr-5',
          }}
        />
        <Common.Text
          attrs={{...tktEdit.tktFare}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'min-width-5-10 width-5-10 mr-2',
          }}
        />
        <Common.Text
          attrs={{...tktEdit.tktEqFapd}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'min-width-5-10 width-5-10 mr-2',
          }}
        />
        <Common.Text
          attrs={{...tktEdit.tktTotal}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'min-width-5-10 width-5-10 mr-0',
          }}
        />
      </div>
      <div className='d-flex'>
        <Common.Text
          attrs={{...tktEdit.tktFareCalculation, as: 'textarea'}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'w-100 mr-0',
          }}
          control={{
            rows: 3
          }}
        />
      </div>
      <div className='d-flex'>
        <Common.Text
          attrs={{...tktEdit.tktEndRest, as: 'textarea'}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'w-100 mr-0',
          }}
          control={{
            rows: 2
          }}
        />
      </div>
      <div className='d-flex'>
        <Common.Text
          attrs={{...tktEdit.tktFormPay, as: 'textarea'}}
          onBlur={(e) => {
            e.tktFormPay.text = convert.cardNoMasking(e.tktFormPay.text, 100, true)
            tktEditAction.changeInputTktEdit(e)
            return e.tktFormPay.text
          }}
          isBlurChange = {true}
          group={{
            className: 'w-100 mr-0',
          }}
          control={{
            rows: 2
          }}
          ref={formPayElm}
        />
      </div>
    </React.Fragment>
  )
}