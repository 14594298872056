import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { TktExcelAction } from 'ducks/TktExcel';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { LIST_ITEMS, PDT_FARE_ID, TBL_TYPE, TKTKBN_MAP } from 'constants/master';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';
import { TicketApi } from 'webapi'

export const PatternNamePopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const tktExcel = useSelector(state => state.TktExcel);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);
  const tktExcelAction = bindActionCreators(TktExcelAction, dispatch);

  const [pat, setPat] = React.useState('')

  const {tab} = props;

  const initPopup = (form) => {
    props.setPopup(form)
  }

  React.useEffect(() => {
    // console.log(props.popupData)
    const selectedPattern = tab==='tkt'
      ?_.find(tktExcel.tktPatternList, r => r.tktXlsitemObjectId === props.popupData)
      :_.find(tktExcel.fltPatternList, r => r.tktXlsitemObjectId === props.popupData)
    tktExcelAction.changeInputTktExcel(convert.getTarget(tktExcel.patternName, 'text', selectedPattern?selectedPattern.name:''))
  }, [props.popupData])

  const action = (type) => {//console.log(type);console.log(tktExcel)
    let entry = {};
    
    entry = entryCheck(tktExcel, convert.getTarget(tktExcel.patternName, 'text', tktExcel.patternName.text))
    tktExcelAction.setInputTktExcel(entry)
    if (entry.patternName.isInvalid) return
    // 
    const seqNo = type==='INSERT'||tktExcel.patternName.dirty?convert.toDateTimeString(new Date(), 'yyyyMMddHHmmsssss'):props.popupData
    update('INSERT', seqNo, entry)
  }

  const execDelete = () => {
    const seqNo = props.popupData
    const dto = {
      isTimerAccess: false,
      isTimerSetting: false,
      patternAppCode: 'DELETE_CONFIRM',
      selectedPatternSeqNo: seqNo,
      selectedTab: tab.toUpperCase(),
    }
    alert().fire({
      icon: MESSAGE.confirmDelete().icon,
      title: MESSAGE.confirmDelete('パターン').text
    })
    .then((result) => {
      if (result.isConfirmed) {
        appAction.showProcessing({isProcessing: true})
        TicketApi.excelEdit(dto)
        .then(res => {
          // console.log(res.payload)
          appAction.setMessage({message: res.payload.messageList})
          if (res.payload.code === '000') {
            let obj = {}
            obj.tktPatternList = [{value:'', name:'　'}]
            _.forEach(res.payload.tktPatternList, r => {
              obj.tktPatternList.push({
                ...r,
                value: r.tktXlsitemObjectId,
                name: r.tktXlsiniPtName,
              })
            })
            obj.fltPatternList = [{value:'', name:'　'}]
            _.forEach(res.payload.fltPatternList, r => {
              obj.fltPatternList.push({
                ...r,
                value: r.tktXlsitemObjectId,
                name: r.tktXlsiniPtName,
              })
            })
            // console.log(obj)
            tktExcelAction.setInputTktExcel(obj)
            // setTimeout(() => {
            //   const id = {}
            //   id[tab] = {...tktExcel[tab]}
            //   id[tab][`tktXlsitemObjectId_${tab}`] = {
            //     ...tktExcel[tab][`tktXlsitemObjectId_${tab}`],
            //     original: seqNo,
            //     value: seqNo,
            //     text: seqNo,
            //   }
            //   tktExcelAction.setInputTktExcel(id)
            // })
          }
        })
        .catch(() =>{})
        .finally(() => {
          appAction.showProcessing({isProcessing: false})
          // entry.patternName.isInvalid = false
          // tktExcelAction.setInputTktExcel(entry)
          props.popup.hide()
        })
      } else {
      }
    })
  }

const update = (type, seqNo, entry) => {

  let sortArray = []
  _.forEach([...Array(8).keys()], i => {
    const sortItem = tktExcel[tab][`tktXlsiniSort${++i}_${tab}`].text
    if (sortItem) {
      sortArray.push(sortItem)
    }
  })
  if (sortArray.length !== _.uniq(sortArray).length) {
    const errorItem = 'ソート順の指定項目'
    appAction.setMessage({message: [{id: MESSAGE.duplicateItem(errorItem).id, message: MESSAGE.duplicateItem(errorItem).text, type: MESSAGE.duplicateItem(errorItem).icon}]})
    return
  }

  appAction.showProcessing({isProcessing: true})
  let list = []
  _.forEach(tktExcel[`${tab}SelectedList`], (r, i) => {
    list.push({
      tktXlsitemItem: r.itemFrmItemId,
      tktXlsitemSort: i + 1,
    })
  })
  const dto = {
    isTimerAccess: false,
    isTimerSetting: false,
    patternAppCode: type,
    selectedPatternSeqNo: seqNo,
    registerPatternName: tktExcel.patternName.text,
    outSearchDto: {
      tktOutsrcSql: tktExcel.searchSql,
      // tktOutsrcSearch: txtExport.,
      tktOutsrcIniMbrcd: login.loginCd,
      cmnMbrcd: login.loginCd,
    },
    selectedTab: tab.toUpperCase(),
  }
  dto[`${tab}IniDto`] = {
    tktXlsiniFormat: tktExcel[tab][`tktXlsiniFormat_${tab}`].text,
    tktXlsiniHeader: tktExcel[tab][`tktXlsiniHeader_${tab}`].text,
    tktXlsiniSort1: tktExcel[tab][`tktXlsiniSort1_${tab}`].text,
    tktXlsiniSort2: tktExcel[tab][`tktXlsiniSort2_${tab}`].text,
    tktXlsiniSort3: tktExcel[tab][`tktXlsiniSort3_${tab}`].text,
    tktXlsiniSort4: tktExcel[tab][`tktXlsiniSort4_${tab}`].text,
    tktXlsiniSort5: tktExcel[tab][`tktXlsiniSort5_${tab}`].text,
    tktXlsiniSort6: tktExcel[tab][`tktXlsiniSort6_${tab}`].text,
    tktXlsiniSort7: tktExcel[tab][`tktXlsiniSort7_${tab}`].text,
    tktXlsiniSort8: tktExcel[tab][`tktXlsiniSort8_${tab}`].text,
    tktXlsiniFile: tktExcel[tab][`tktXlsiniFile_${tab}`].text,
    tktXlsiniPex: tktExcel[tab][`tktXlsiniPex_${tab}`].text,
    tktXlsiniCsmtax: tktExcel[tab][`tktXlsiniCsmtax_${tab}`].text,
    tktXlsiniPrd: tktExcel[tab][`tktXlsiniPrd_${tab}`].text,
    tktXlsiniGrossNetSame: tktExcel[tab][`tktXlsiniGrossNetSame_${tab}`].text,
    tktXlsiniPcc: tktExcel[tab][`tktXlsiniPcc_${tab}`].text,
    tktEmdMpdFlag: tktExcel[tab][`tktEmdMpdFlag_${tab}`].text,
    tktXlsiniFileFlag: tktExcel[tab][`tktXlsiniFileFlag_${tab}`].text,
    tktXlsiniMbrcd: login.loginCd,
    cmnMbrcd: login.loginCd,
  }
  if (tab === 'tkt') {
    dto.tktIniDto.tktXlsiniPlusType = tktExcel.tkt.tktXlsiniPlusType_tkt.text
    dto.tktIniDto.tktXlsiniClientSyncFlag = tktExcel.tkt.tktXlsiniClientSyncFlag_tkt.text
    dto.tktIniDto.tktXlsiniClientPath = tktExcel.tkt.tktXlsiniClientPath_tkt.text
    dto.tktIniDto.tktXlsiniClientOutFile = tktExcel.tkt.tktXlsiniClientOutFile_tkt.text==='3'
      ?tktExcel.tkt.tktXlsiniClientOutFilePattern_tkt.text
      :tktExcel.tkt.tktXlsiniClientOutFile_tkt.text
    dto.tktIniDto.tktXlsiniS3Flag = tktExcel.tkt.tktXlsiniS3Flag_tkt.text
    dto.tktIniDto.tktXlsiniS3Path = tktExcel.tkt.tktXlsiniS3Path_tkt.text
    dto.tktIniDto.tktXlsiniS3OutFile = tktExcel.tkt.tktXlsiniS3OutFile_tkt.text==='3'
      ?tktExcel.tkt.tktXlsiniS3OutFilePattern_tkt.text
      :tktExcel.tkt.tktXlsiniS3OutFile_tkt.text
    dto.tktIniDto.tktXlsiniPccdiv = tktExcel.tkt.tktXlsiniPccdiv_tkt.text
  } else {
    dto.fltIniDto.tktXlsiniPrdEx = tktExcel.flt.tktXlsiniPrdEx_flt.text
    dto.fltIniDto.tktXlsiniPrdKb0 = tktExcel.flt.tktXlsiniPrdKb0_flt.text
    dto.fltIniDto.tktXlsiniFltseg = tktExcel.flt.tktXlsiniFltseg1_flt.text
    + tktExcel.flt.tktXlsiniFltseg2_flt.text
    + tktExcel.flt.tktXlsiniFltseg3_flt.text
    + tktExcel.flt.tktXlsiniFltseg4_flt.text
    dto.fltIniDto.tktXlsiniTotal = tktExcel.flt.tktXlsiniTotal_flt.text
  }
  
  dto[`${tab}OutItemList`] = list
  // dto[`${tab}SelectedList`] = tktExcel[`${tab}SelectedList`]


  TicketApi.excelEdit(dto)
  .then(res => {
    // console.log(res.payload)
    if (res.payload.exist) {
      alert().fire({
        icon: MESSAGE.confirmPatternUpdate().icon,
        title: MESSAGE.confirmPatternUpdate().text
      })
      .then((result) => {
        if (result.isConfirmed) {
          update('UPDATE', res.payload.selectedPatternSeqNo, entry)
        } else {
          appAction.showProcessing({isProcessing: false})
        }
      })
    } else {
      appAction.setMessage({message: res.payload.messageList})
      if (res.payload.code === '000') {
        let obj = {}
        obj.tktPatternList = [{value:'', name:'　'}]
        _.forEach(res.payload.tktPatternList, r => {
          obj.tktPatternList.push({
            ...r,
            value: r.tktXlsitemObjectId,
            name: r.tktXlsiniPtName,
          })
        })
        obj.fltPatternList = [{value:'', name:'　'}]
        _.forEach(res.payload.fltPatternList, r => {
          obj.fltPatternList.push({
            ...r,
            value: r.tktXlsitemObjectId,
            name: r.tktXlsiniPtName,
          })
        })
        // console.log(obj)
        tktExcelAction.setInputTktExcel(obj)
        setTimeout(() => {
          const id = {}
          id[tab] = {...tktExcel[tab]}
          id[tab][`tktXlsitemObjectId_${tab}`] = {
            ...tktExcel[tab][`tktXlsitemObjectId_${tab}`],
            original: seqNo,
            value: seqNo,
            text: seqNo,
          }
          tktExcelAction.setInputTktExcel(id)
        })
      } else {
      }
    }
  })
  .catch(() =>{})
  .finally(() => {
    appAction.showProcessing({isProcessing: false})
    entry.patternName.isInvalid = false
    tktExcelAction.setInputTktExcel(entry)
    props.popup.hide()
  })
}
  
  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
      >
        <Card 
          root='popup'
          size='lg'
          hideClose
          header={props.header}
          headerClass='wj-dialog-header'
          subheader={props.subheader}
          // body={props.body}
          body={
            <React.Fragment>
              <Common.Text
                attrs={{
                  ...tktExcel.patternName,
                  // disabled: props.popupData
                }}
                onBlur={(e) => tktExcelAction.changeInputTktExcel(e)}
                group={{
                  className: 'width-50 mr-2'
                }}
              />
            </React.Fragment>
          }
        />
        <Card 
          size='md'
          root={`popup-button ${props.size}`}
          // body={props.button}
          body={
            <React.Fragment>
              <Button className='button-gray mr-auto wj-hide-cancel'>
                <span className='material-icons-outlined md mr-2'>cancel</span>
                キャンセル
              </Button>
              <Button
                className='button-warn ml-auto mr-auto'
                onClick={() => execDelete()}
                disabled={app.isProcessing || !props.popupData}
              >
                <span className='material-icons-outlined md mr-2'>file_download</span>
                削除
              </Button>
              <Button
                className='button-blue ml-auto'
                onClick={() => action(props.popupData?'UPDATE':'INSERT')}
                disabled={app.isProcessing}
              >
                <span className='material-icons-outlined md mr-2'>file_upload</span>
                登録
              </Button>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})