import _ from 'lodash';

export const TITLE = {
  main: {name: 'URI-Pro', link: '/'},
  ticketSearch: {
    name: '発券データ検索', link: '/tkt/search',
    icon: {name: 'manage_search', style: ''},
    searchList: {header: '検索項目', subheader: ''},
    condition: {header: '検索条件', subheader: ''},
    result: {header: '検索結果一覧', subheader: ''},
  },
  ticketEdit: {
    name: '発券データ詳細', mainLink: '/tkt/edit', link: '/tkt/edit/:tktno',
    icon: {name: 'edit_note', style: '-outlined'},
  },
  ticketImport: {
    name: '発券データ入力', link: '/tkt/import',
    icon: {name: 'file_upload', style: '-outlined'},
  },
  ticketExport: {
    name: '発券データ出力', link: '/tkt/export',
    icon: {name: 'file_download', style: '-outlined'},
    specify: {header: '出力指定', subheader: ''},
    option: {header: '出力オプション', subheader: ''},
  },
  ticketExportSearch: {
    name: '発券データ出力', link: '/tkt/export/search',
    icon: {name: 'manage_search', style: ''},
    searchList: {header: '検索項目', subheader: ''},
    condition: {header: '検索条件', subheader: ''},
    result: {header: '検索結果一覧', subheader: ''},
  },
  ticketExcel: {
    name: 'EXCELデータ出力', link: '/tkt/excel',
    icon: {name: 'assignment_returned', style: '-outlined'},
    item: {header: '出力項目', subheader: ''},
    setting: {header: '出力設定', subheader: ''},
    sort: {header: 'ソート', subheader: ''},
    filename: {header: '出力ファイル名', subheader: ''},
    specify: {header: '出力指定', subheader: ''},
    option: {header: '出力オプション', subheader: ''},
  },
  ticketExcelSearch: {
    name: 'EXCELデータ出力', link: '/tkt/excel/search',
    icon: {name: 'assignment_returned', style: '-outlined'},
    searchList: {header: '検索項目', subheader: ''},
    condition: {header: '検索条件', subheader: ''},
    result: {header: '検索結果一覧', subheader: ''},
  },
  pivot: {
    name: 'URI-Pro BI', link: '/pivot',
    icon: {name: 'assessment', style: '-outlined'},
  },
  pivotSearch: {
    name: 'URI-Pro BI', link: '/pivot/search',
    icon: {name: 'manage_search', style: ''},
    searchList: {header: '検索項目', subheader: ''},
    condition: {header: '検索条件', subheader: ''},
    result: {header: '検索結果一覧', subheader: ''},
  },
  report: {
    name: '固定帳票出力', link: '/report',
    icon: {name: 'source', style: '-outlined'},
  },
  reportSearch: {
    name: '固定帳票出力', link: '/report/search',
    icon: {name: 'manage_search', style: ''},
    searchList: {header: '検索項目', subheader: ''},
    condition: {header: '検索条件', subheader: ''},
    result: {header: '検索結果一覧', subheader: ''},
  },
  prism: {
    name: 'PRISMデータ出力', link: '/prism',
    icon: {name: 'cloud_download', style: '-outlined'},
    code: {header: 'コード設定', subheader: ''},
    condition: {header: 'データ抽出条件', subheader: ''},
  },
  timer: {
    name: 'タイマー設定', link: '/timer',
    icon: {name: 'timer', style: '-outlined'},
  },
  process: {
    name: '処理結果', link: '/process',
    icon: {name: 'timer', style: '-outlined'},
  },
  master: {
    name: 'テーブルメンテナンス', mainLink: '/master', link: '/master/:type',
    icon: {name: 'settings', style: '-outlined',},
    main: {},
    mainCond: {},
    mainList: {header: 'テーブル一覧', subheader: ''},
    mainEdit: {},
    order: {header: '受注コード(R)'},
    orderCond: {header: '検索条件'},
    orderList: {header: '受注コード一覧(R)'},
    orderEdit: {header: ' 新規・編集'},
    area: {header: '方面コード(D)'},
    areaCond: {header: '検索条件'},
    areaList: {header: '方面コード一覧(D)'},
    areaEdit: {header: ' 新規・編集'},
    section: {header: 'セクションコード(S)'},
    sectionCond: {header: '検索条件'},
    sectionList: {header: 'セクションコード一覧(S)'},
    sectionEdit: {header: ' 新規・編集'},
    free: {header: 'FREEコード(F)'},
    freeCond: {header: '検索条件'},
    freeList: {header: 'FREEコード一覧(F)'},
    freeEdit: {header: ' 新規・編集'},
    tour: {header: 'ツアーコード(T)'},
    tourCond: {header: '検索条件'},
    tourList: {header: 'ツアーコード一覧(T)'},
    tourEdit: {header: ' 新規・編集'},
    kb1: {header: 'KBコード1(K)'},
    kb1Cond: {header: '検索条件'},
    kb1List: {header: 'KBコード1一覧(K)'},
    kb1Edit: {header: ' 新規・編集'},
    kb2: {header: 'KBコード2(B)'},
    kb2Cond: {header: '検索条件'},
    kb2List: {header: 'KBコード2一覧(B)'},
    kb2Edit: {header: ' 新規・編集'},
    pay: {header: '入金コード(M)'},
    payCond: {header: '検索条件'},
    payList: {header: '入金コード一覧(M)'},
    payEdit: {header: ' 新規・編集'},
    eo: {header: 'E/Oコード(E)'},
    eoCond: {header: '検索条件'},
    eoList: {header: 'E/Oコード一覧(E)'},
    eoEdit: {header: ' 新規・編集'},
    company: {header: '法人コード(C)'},
    companyCond: {header: '検索条件'},
    companyList: {header: '法人コード一覧(C)'},
    companyEdit: {header: ' 新規・編集'},
    member: {header: '担当者コード(A)'},
    memberCond: {header: '検索条件'},
    memberList: {header: '担当者コード一覧(A)'},
    memberEdit: {header: ' 新規・編集'},
    production: {header: 'PRODUCTION'},
    productionCond: {header: '検索条件'},
    productionList: {header: 'PRODUCTION一覧'},
    productionEdit: {header: ' 新規・編集'},
    decode: {header: 'DECODE'},
    decodeCond: {header: '検索条件'},
    decodeList: {header: 'DECODE一覧'},
    decodeEdit: {header: ' 新規・編集'},
    fare: {header: '運賃種別判定'},
    fareCond: {header: '検索条件'},
    fareList: {header: '運賃種別判定一覧'},
    fareEdit: {header: ' 新規・編集'},
  },
  system: {
    name: 'システムメンテナンス', mainLink: '/system', link: '/system/:type',
    icon: {name: 'settings', style: '-outlined',},
    type: [
      {name: 'travel', header: '旅行会社情報・システム設定'},
      {name: 'maint', header: 'データメンテナンス'},
    ],
    biz: '基本業務設定',
    sys: 'システム設定',
    log: '操作ログ',
    usr: 'ユーザID管理',
    data: 'データメンテナンス設定',
  },
  process: {
    name: '処理結果',
    link: '/process',
    icon: {name: 'timer', style: '-outlined',},
  },
  mntLogin: {name: '保守員ログイン', link: '/mnt/login'},
  mntTool: {name: '保守ツール', link: '/mnt/tool'},
  info: {
    name: 'お知らせ',
    link: '/info',
    icon: {name: 'notifications', style: '-outlined',},
  },
  logout: {name: '業務終了', link: '/logout'},
  error: {
    name: '　', link: '/error',
    icon: {name: 'report_problem', style: '-outlined',},
  },
}