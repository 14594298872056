import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { PivotAction } from 'ducks/Pivot';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { CHART_TYPE } from 'constants/pivot';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';
import { SummaryApi } from 'webapi'

export const Step = React.forwardRef((props, ref) => {

  return (
    <div className='d-flex justify-content-center py-2 px-5'>
      <div>
        <div className={`step-circle ${props.step==='1'?'active':''} rounded-circle`}>STEP1</div>
      </div>
      <div>
        <div className={`step-arrow`}>
          <div className='material-icons-outlined lg'>more_horiz</div>
          <div className='material-icons-outlined lg minus'>more_horiz</div>
          <div className='material-icons-outlined xxlg arrow'>arrow_right</div>
        </div>
      </div>
      <div>
        <div className={`step-circle ${props.step==='2'?'active':''} rounded-circle`}>STEP2</div>
      </div>
      <div>
      <div className={`step-arrow`}>
          <div className='material-icons-outlined lg'>more_horiz</div>
          <div className='material-icons-outlined lg minus'>more_horiz</div>
          <div className='material-icons-outlined xxlg arrow'>arrow_right</div>
        </div>
      </div>
      <div>
        <div className={`step-circle ${props.step==='3'?'active':''} rounded-circle`}>STEP3</div>
      </div>
        <div className={`step-arrow`}>
          <div className='material-icons-outlined lg'>more_horiz</div>
          <div className='material-icons-outlined lg minus'>more_horiz</div>
          <div className='material-icons-outlined xxlg arrow'>arrow_right</div>
        </div>
      <div>
        <div className={`step-circle ${props.step==='4'?'active':''} rounded-circle`}>完了</div>
      </div>
    </div>
  )
})