import _ from 'lodash';
import { convert } from "lib";

export const getCondition = (search) => {

  const condArray = ['_array', '_condition', '_choice', '_choiceBlank', '_blank', '_from', '_to', '', '_chdate', '_number', '_period1', '_period2', '_period3', '_period4']
  let condition = {}
  let cond = null

  if (!search || !search.checkedItems) return {}

  _.forEach(search.checkedItems, (item) => {
    cond = search[item.key + condArray[0]]
    if (cond && cond.text.length > 0) {
      if (!condition[item.key]) condition[item.key] = {}
      condition[item.key].formList = []
      _.forEach(cond.text, (r) => {
        if (r.orderCd) condition[item.key].formList.push(r.orderCd)
        if (r.sectionCd) condition[item.key].formList.push(r.sectionCd)
        if (r.memberCd) condition[item.key].formList.push(r.memberCd)
        if (r.areaCd) condition[item.key].formList.push(r.areaCd)
        if (r.companyCd) condition[item.key].formList.push(r.companyCd)
        if (r.payCd) condition[item.key].formList.push(r.payCd)
        if (r.freeCd) condition[item.key].formList.push(r.freeCd)
        if (r.tourCd) condition[item.key].formList.push(r.tourCd)
        if (r.kb1Cd) condition[item.key].formList.push(r.kb1Cd)
        if (r.kb2Cd) condition[item.key].formList.push(r.kb2Cd)
        if (r.eoCd) condition[item.key].formList.push(r.eoCd)
        if (r.cityCd) condition[item.key].formList.push(r.cityCd)
        
        if (item.key === 'fltAirlineCd') {
          condition[item.key].formList.push(r.alnCd2)
        } else {
          if (r.alnCdn) condition[item.key].formList.push(r.alnCdn)
          if (!r.alnCdn && r.alnCd2) condition[item.key].formList.push(r.alnCd2)
        }
        if (r.text) {
          if (item.key === 'tktCommRate') {
            condition[item.key].formList.push(('00000' + ((r.text?_.toNumber(r.text):0) * 100)).substr(-5))
          } else if (item.key === 'tktCaExDate') {
            // condition[item.key].formList.push(convert.mmyy2dateString(r.text, '01',  'yyyy-MM-dd'))
            condition[item.key].formList.push(r.text.replace('/', ''))
          } else {
            condition[item.key].formList.push(r.text)
          }
        }
      })
      condition[item.key].isChecked = true
    }
    // from/date from
    cond = search[item.key + condArray[5]]
    if (cond && cond.text !== '' && !_.isNil(cond.text)) {
      if (!condition[item.key]) condition[item.key] = {}
      if (item.key === 'tktCaExDate') {
        condition[item.key].dateFrom = convert.mmyy2dateString(cond.text, '01',  'yyyy-MM-dd')
      } else if (_.isDate(cond.text)){
        condition[item.key].dateDayFrom = convert.toDateTimeString(cond.text, 'yyyy-MM-dd')
        if (item.type === 'datetime') {
          condition[item.key].dateTimeFrom = convert.toDateTimeString(cond.text, 'HH:mm:00')
        }
      } else {
        if (item.key === 'tktCommRate') {
          condition[item.key].from = ('00000' + ((cond.text?cond.text:0) * 100)).substr(-5)
        } else {
          condition[item.key].strFrom = cond.text
        }
      }
      if (condition[item.key].strFrom || condition[item.key].dateDayFrom || condition[item.key].dateTimeFrom || condition[item.key].dateFrom || condition[item.key].from) {
        condition[item.key].isChecked = true
      }
    }
    // to/date to
    cond = search[item.key + condArray[6]]
    if (cond && cond.text !== '' && !_.isNil(cond.text)) {
      if (!condition[item.key]) condition[item.key] = {}
      if (item.key === 'tktCaExDate') {
        condition[item.key].dateTo = convert.mmyy2dateString(cond.text, '99', 'yyyy-MM-dd')
      } else if (_.isDate(cond.text)){
        condition[item.key].dateDayTo = convert.toDateTimeString(cond.text, 'yyyy-MM-dd')
        if (item.type === 'datetime') {
          condition[item.key].dateTimeTo = convert.toDateTimeString(cond.text, 'HH:mm:59')
        }
      } else {
        if (item.key === 'tktCommRate') {
          condition[item.key].to = ('00000' + ((cond.text?cond.text:0) * 100)).substr(-5)
        } else {
          condition[item.key].strTo = cond.text
        }
      }
      if (condition[item.key].strTo || condition[item.key].dateDayTo || condition[item.key].dateTimeTo || condition[item.key].dateTo || condition[item.key].to) {
        condition[item.key].isChecked = true
      }
    }
    // 日付関連
    cond = search[item.key + condArray[8]]
    if (cond && cond.text) {
      if (condition[item.key]) {
        condition[item.key].chdate = cond.text
        if (cond.text != '0') {
          if (condition[item.key].dateDayFrom) {
            condition[item.key].dateDayFrom = ''
          }
          if (condition[item.key].dateDayTo) {
            condition[item.key].dateDayTo = ''
          }
          if (condition[item.key].dateTimeFrom) {
            condition[item.key].dateTimeFrom = ''
          }
          if (condition[item.key].dateTimeTo) {
            condition[item.key].dateTimeTo = ''
          }
        }
      }
    }
    cond = search[item.key + condArray[9]]
    if (cond && !_.isNil(cond.text)) {
      if (condition[item.key]) {
        condition[item.key].number = cond.text
      }
    }
    cond = search[item.key + condArray[10]]
    if (cond && !_.isNil(cond.text)) {
      if (condition[item.key]) {
        condition[item.key].period1 = cond.text
      }
    }
    cond = search[item.key + condArray[11]]
    if (cond && !_.isNil(cond.text)) {
      if (condition[item.key]) {
        condition[item.key].period2 = cond.text
      }
    }
    cond = search[item.key + condArray[12]]
    if (cond && !_.isNil(cond.text)) {
      if (condition[item.key]) {
        condition[item.key].period3 = cond.text
      }
    }
    cond = search[item.key + condArray[13]]
    if (cond && !_.isNil(cond.text)) {
      if (condition[item.key]) {
        condition[item.key].period4 = cond.text
      }
    }
    // フラグ類
    _.forEach(search, (v, k) => {
      if (k.startsWith(item.key) && k.split('_').length > 1) {
        const name = k.split('_')[1]
        if (!_.includes(condArray, '_' + name)) {
          if (!condition[item.key]) condition[item.key] = {}
          condition[item.key][name] = v.text === '1'
          condition[item.key].isChecked = condition[item.key].isChecked || (v.text === '1')
          // console.log(condition[item.key][name])
        }
      }
    })
    // 
    cond = search[item.key]
    if (cond && !_.isNil(cond.text)) {
      if (!condition[item.key]) condition[item.key] = {}
      condition[item.key].radioType = cond.text
      condition[item.key].isChecked = true
    }
    // 範囲:0,複数選択:1,ブランク:2
    cond = search[item.key + condArray[3]]
    if (cond && !_.isNil(cond.text)) {
      if (condition[item.key] || cond.text === '2') {
        // console.log(cond)
        if (!condition[item.key]) condition[item.key] = {}
        condition[item.key].dataTypeRadioType = cond.text
        condition[item.key].isChecked = true
        // // TODO ※必要？　非表示項目の削除
        // if (cond.text === '0' || cond.text === '2') {
        //   delete condition[item.key].formList
        // }
        // if (cond.text === '1' || cond.text === '2') {
        //   delete condition[item.key].dateDayFrom
        //   delete condition[item.key].dateTimeFrom
        //   delete condition[item.key].dateDayTo
        //   delete condition[item.key].dateTimeTo
        // }
      }
    }
    // 複数選択:0,ブランク:1
    cond = search[item.key + condArray[4]]
    if (cond && !_.isNil(cond.text)) {
      if (condition[item.key] || cond.text === '1') {
        if (!condition[item.key]) condition[item.key] = {}
        // console.log(cond)
        condition[item.key].radioType = cond.text
        condition[item.key].dataTypeRadioType = cond.text
        condition[item.key].isChecked = true
      }
    }
    // 指定:0,除外:1
    cond = search[item.key + condArray[1]]
    // console.log(cond)
    // console.log(condition[item.key])
    if (cond && !_.isNil(cond.text)) {
      // if (!condition[item.key]) condition[item.key] = {}
      if (condition[item.key]) {
        condition[item.key].radioType = cond.text
      }
      if (condition[item.key]
        || (search[item.key + condArray[3]] && search[item.key + condArray[3]].text === '2')
        || (search[item.key + condArray[4]] && search[item.key + condArray[4]].text === '1'))
      {
        if (!condition[item.key]) condition[item.key] = {}
        condition[item.key].clusterRadioType = cond.text
      }
    }
    // 範囲:0,複数選択:1
    cond = search[item.key + condArray[2]]
    if (cond && !_.isNil(cond.text)) {
      if (condition[item.key]) {
        // console.log(cond)
        condition[item.key].dataTypeRadioType = cond.text
        condition[item.key].radioType = cond.text
      }
    }
  })
  _.forEach(condition, (v, k) => {
    if (!v.isChecked) delete condition[k]
    delete v.isChecked
    if (_.isEmpty(v)) delete condition[k]
    if (k==='tktVoidId' && v.radioType === '1') {
      if (!v.manualVoidFlg && !v.onlineVoidFlg) delete condition[k]
    }
    if (k==='tktRefId' && v.radioType === '1') {
      if (!v.autoRefundFlg && !v.manualRefundFlg) delete condition[k]
    }
  })
// console.log(condition)
  return condition
}