import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';

export const OtherPane = (props) => {

  const tktEdit = useSelector(state => state.TktEdit);
  
  const dispatch = useDispatch();
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  return (
    <React.Fragment>
      <div className='d-flex'>
        <Common.Text
          attrs={{...tktEdit.tktMemo, as: 'textarea'}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-p-100 mr-0',
          }}
          control={{
            rows: 5
          }}
        />
      </div>
      <div className='d-flex'>
        <Common.DateTime
          attrs={{...tktEdit.tktRcvDate}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-max-content mr-2',
          }}
        />
        <Common.Text
          attrs={{...tktEdit.tktBookingPcc}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-13 mr-0',
          }}
        />
      </div>
      <div className='d-flex'>
        <Common.DateTime
          attrs={{...tktEdit.tktRstDate}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-max-content mr-2',
          }}
        />
        <Common.Text
          attrs={{...tktEdit.tktTktPcc}}
          onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
          group={{
            className: 'width-13 mr-0',
          }}
        />
      </div>
      <div className={`d-flex flex-column mt-4 ${tktEdit.tktDetailIssuedFlg.visible?'visible':'invisible'}`}>
        <div><label className='form-label lg'>固定帳票出力状況</label></div>
        <div className='flex-row print-flags'>
          <Common.Check
            attrs={{...tktEdit.tktDetailIssuedFlg}}
            onChange={(e) => tktEditAction.changeInputTktEdit(e)}
          />
          <Common.Check
            attrs={{...tktEdit.tktCustomerOutputFlg}}
            onChange={(e) => tktEditAction.changeInputTktEdit(e)}
          />
          <Common.Check
            attrs={{...tktEdit.tktAgentCouponOutputFlg}}
            onChange={(e) => tktEditAction.changeInputTktEdit(e)}
          />
          <Common.Check
            attrs={{...tktEdit.tktCccfOutputFlg}}
            onChange={(e) => tktEditAction.changeInputTktEdit(e)}
          />
          <Common.Check
            attrs={{...tktEdit.tktRefundNoticeOutputFlg}}
            onChange={(e) => tktEditAction.changeInputTktEdit(e)}
          />
        </div>
      </div>
    </React.Fragment>
  )
}