import { axiosInstance } from 'http/index'

export const MasterApi = {
  all: (data) => {
    const instance = axiosInstance;
    return instance.post('/master/search', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  search: (isCode, type, data) => {
    const instance = axiosInstance;
    const codeUri = isCode? 'code/': ''
    return instance.post(`/master/${codeUri}${type}/search`, data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  edit: (isCode, type, data, feature) => {
    const instance = axiosInstance;
    const codeUri = isCode? 'code/': ''
    const featureUri = feature?feature:'edit'
    return instance.post(`/master/${codeUri}${type}/${featureUri}`, data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  download: (isCode, type, data) => {
    const instance = axiosInstance;
    const codeUri = isCode? 'code/': ''
    return instance.post(`/master/${codeUri}${type}/download`, data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  upload: (isCode, type, data) => {
    const instance = axiosInstance;
    const codeUri = isCode? 'code/': ''
    return instance.post(`/master/${codeUri}${type}/upload`, data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
}
