import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import * as Common from 'components/common';
import { Title, Card } from 'components';
import { Container } from 'react-bootstrap';
import _ from 'lodash';
import { TITLE } from 'constants/title';

export const ErrorComponent = () => {

  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  
  const history = useHistory();
  const location = useLocation();
  const { type } = useParams();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  const [message, setMessage] = React.useState([])
  const [isReload, setIsReload] = React.useState(false)

  React.useEffect(() => {
    let wjPopup = document.querySelectorAll('.wj-popup-backdrop')
    _.forEach(wjPopup, r => {
      r.classList.add('d-none')
      setIsReload(true)
    })
    wjPopup = document.querySelectorAll('.wj-popup')
    _.forEach(wjPopup, r => {
      r.classList.add('d-none')
      setIsReload(true)
    })
    appAction.showProcessing({isProcessing: false})
    if (location.state && location.state.message) {
      setMessage(location.state.message)
    } else {
      setMessage([])
    }
    if (isReload) {
      window.location.reload()
    }
  }, [])

  return (
    <React.Fragment>
      <Container fluid className='main'>
        <Title title={TITLE.error.name} icon={TITLE.error.icon}/>
          <div className='mt-5 ml-5 error-title fw-bold text-danger'>
            {message.length > 0 && message[0].id ==='SYSM0001E' &&
              <span>処理を継続できません。認証エラーもしくはタイムアウトの可能性があります。再ログインをお試しください。</span>
            }
            {message.length === 0 || message[0].id !=='SYSM0001E' &&
              <span>処理を継続できません。カスタマーサポートにお問い合わせください。</span>
            }
          </div>
        {_.map(message, (r, i) => {
          return <div key={i} className='mt-5 ml-5 error-text'><span className='mx-2'>{r.id}</span><span>{r.message}</span></div>
        })}
      </Container>
      <Common.RouteLeavingGuard
        when={true}
        shouldBlockNavigation={()=>false}
      />
    </React.Fragment>
  )
}