import { axiosInstance } from 'http/index'

export const PrismApi = {
  init: (data) => {
    const instance = axiosInstance;
    return instance.post('/prism/init', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  download: (data) => {
    const instance = axiosInstance;
    return instance.post('/prism/download', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  outputFile: (data) => {
    const instance = axiosInstance;
    return instance.post('/prism/file', data, {responseType: 'blob'}).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },

}
