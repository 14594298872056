import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Common from 'components/common';
import { Card } from 'components';
import { ItemPopup } from './modal/ItemPopup';
import { BulkEditPopup } from './modal/BulkEditPopup';
import { ContextMenu } from './modal/ContextMenu';
import * as CondCard from './card';
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjGridNom from '@grapecity/wijmo.grid';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { Selector } from "@grapecity/wijmo.grid.selector";
import * as wjInput from '@grapecity/wijmo.react.input';
import { saveFile, toggleClass, Tooltip, PopupPosition } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { RADIOS } from 'constants/search';
import { TKT_STATUS, MAX_WINDOW } from 'constants/ticket';
import { MESSAGE } from 'constants/message';
import { LIST_ITEMS } from 'constants/searchResult';
import { dataMapper, entryCheck, getCondition, alert } from "lib";
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { TktExportAction } from 'ducks/TktExport';
import { TktExcelAction } from 'ducks/TktExcel';
import { ReportAction } from 'ducks/Report';
import { PivotAction } from 'ducks/Pivot';
import { TicketApi } from 'webapi'

export const SearchResultComponent = (props) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  let history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const tktExportAction = bindActionCreators(TktExportAction, dispatch);
  const tktExcelAction = bindActionCreators(TktExcelAction, dispatch);
  const reportAction = bindActionCreators(ReportAction, dispatch);
  const pivotAction = bindActionCreators(PivotAction, dispatch);

  const [grid, setGrid] = React.useState(null)
  const [selector, setSelector] = React.useState(null)
  const [selectedItems, setSelectedItems] = React.useState(null)
  const [nextLoc, setNextLoc] = React.useState('')
  const [nextState, setNextState] = React.useState({})
  
  const [popup, setPopup] = React.useState(null)
  const [popupData, setPopupData] = React.useState(null)

  const [editWindow, setEditWindow] = React.useState(null)

  const [researchVisible, setResearchVisible] = React.useState(false)
  
  // 初期化
  React.useEffect(() => {
    if (grid) {
      grid.select(-1, -1)
    }
  }, [grid, props.search.searching])

  React.useEffect(() => {
    if (grid && grid.selectedItems.length > 0 && grid.selection.row < 0) {
      let index = -1
      _.forEach(grid.rows, (r, i) => {
        if (r.isSelected) {
          index = i
        }
      })
      grid.select(index, 0)
    }
  }, [grid && grid.selectedItems.length])

  React.useEffect(() => {
    getViewHeight()
  }, [props.search.sizeHorizontal])

  const getViewHeight = () => {
    const firstView = _.toNumber(_.replace(props.search.sizeHorizontal[0], '%', ''))
    const node = document.querySelector('#search-list-body')
    if (props.search.sizeHorizontal[0] && _.isNumber(firstView)) {
      setResearchVisible(firstView < 20)
      if (node) node.style.height = `calc(${100 - firstView}vh - 320px + ${1.25 * firstView}px)`
      return (100 - firstView) +'vh'
    } else {
      setResearchVisible(true)
      if (node) node.style.height = 'calc(100vh - 320px)'
      return '100vh'
    }
  }

  const initGrid = (g) => {
    setGrid(g)
    if (location.pathname.startsWith('/tkt/search')) {
      g.addEventListener(g.hostElement, 'click', e => {
        let ht = g.hitTest(e)
        if (ht.cellType === 1) ht.getRow().isSelected = !ht.getRow().isSelected
        g.collectionView.refresh()
      })
      setSelector(new Selector(g, {
        itemChecked: (s, e) => {
          setSelectedItems(g.rows.filter(r => r.isSelected))
        }
      }))
    }
    g.selectionMode = 1
  }

  React.useEffect(() => {
    setNextState({})
    if (location.pathname.startsWith('/pivot')) {
      setNextLoc(TITLE.pivot.link)
      const report = (location.state && location.state.report)?location.state.report:null
      setNextState({caller: 'search', report: report})
    }
    if (location.pathname.startsWith('/tkt/export')) {
      setNextLoc(TITLE.ticketExport.link)
      setNextState({caller: 'search'})
    }
    if (location.pathname.startsWith('/tkt/excel')) {
      setNextLoc(TITLE.ticketExcel.link)
      setNextState({caller: 'search'})
    }
    if (location.pathname.startsWith('/report')) {
      setNextLoc(TITLE.report.link)
      setNextState({caller: 'search'})
    }
  }, [location.pathname])

  const download = () => {
    // 選択された行
    const selectedList = grid.rows.filter(r => r.isSelected).map(r => r.dataItem)
    
    if (grid.rows.filter(r => r.isSelected).length <= 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return
    }
    // console.log(selectedList)
    // 
    const fileType = 'txt/csv;charset=Shift_JIS';
    // const rng = new wjGridNom.CellRange(0, 0, grid.rows.length - 1, grid.columns.length - 1)
    const opt = wjGridNom.ClipStringOptions.CSV | wjGridNom.ClipStringOptions.QuoteAll;
    const csv = grid.getClipString(null, opt, true);
    const fileName = 'list.csv'
    saveFile(csv, fileName, fileType);
    // if (navigator.msSaveBlob) { // IE 
    //     navigator.msSaveBlob(new Blob([csv], {
    //     type: fileType
    //     }), fileName);
    // } 
    // else {
    //     var e = document.createElement('a');
    //     e.setAttribute('href', 'data:' + fileType + ',' + encodeURIComponent(csv));
    //     e.setAttribute('download', fileName);
    //     e.style.display = 'none';
    //     document.body.appendChild(e);
    //     e.click();
    //     document.body.removeChild(e);
    // }
  }

  const windowOpen = async (mode) => {
    // 選択された行を保存し、先頭行を開く
    const thisTime = _.toString((new Date()).getTime()).substring(4)
    let selected = grid.rows.filter(r => r.isSelected).map(r => r.dataItem)
    // console.log(selected)
    let currentData = ''
    if (mode === '0') {
      currentData = {tktTktnoAl:'_new', tktTktnoSn: thisTime}
    // } else if (mode === '2') {
    //   selected = [grid.collectionView.currentItem]
    //   currentData = grid.collectionView.currentItem
    } else if (selected.length > 0) {
      currentData = selected[0]
    } else {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return
      // selected.push(grid.collectionView.currentItem)
      // currentData = grid.collectionView.currentItem
    }
    // 保存
    let tktList = [{name:'', value:'', mode: ''}]
    
    const mList = []

    sessionStorage.setItem('authCode', login.authCode)
    sessionStorage.setItem('loginCd', login.loginCd)
    sessionStorage.setItem('mbrCd', login.mbrCd)
    sessionStorage.setItem('office', login.office)
    sessionStorage.setItem('mntOffice', login.mntOffice)
    // I:同一タブ、その他：別タブ
    if (props.search.windowOpen.text !== 'I') {
      // console.log('別タブ')

      if (selected.length >= MAX_WINDOW) {
        alert().fire({
          icon: MESSAGE.confirmOpenTicket(selected.length).icon,
          title: MESSAGE.confirmOpenTicket(selected.length).text,
        })
        .then((result) => {
          if (result.isConfirmed) {
            openEachWindow(mode, selected)
          }
        })
      } else {
        openEachWindow(mode, selected)
      }
    } else {
      await TicketApi.detailAuth()
      .then(async res => {
        if (res.payload === 'auth') {
          // console.log('同一タブ')
          // ウィンドウの存在判定
          if (editWindow && editWindow.name === '_tktEditList') {
            tktList = localStorage.getItem('tktList')?JSON.parse(localStorage.getItem('tktList')):[{name:'', value:'', mode: ''}]
          }
          if (mode === '0') {
            tktList.push({
              name: '未登録',
              value: '_new' + thisTime,
              mode: mode,
            })
          } else if (mode === '2') {
            let count = 0
            let currentTktno = ''
            _.forEach(selected, (r, i) => {
              const alno = r.tktParentTktno?r.tktParentTktno.substr(0, 3):r.tktTktnoAl
              const snno = r.tktParentTktno?r.tktParentTktno.substr(3):r.tktTktnoSn
              const find = _.find(tktList, (tkt) => {return tkt.value === alno + snno})
              TicketApi.detailSearch({
                formMode: mode,
                airlineCode: alno,
                sn: snno,
              })
              .then(res => {
                if (res.payload.code === '000') {
                  const thisTm = _.toString((new Date()).getTime()).substring(4)
                  tktList.push({
                    name: '未登録',
                    value: alno + snno + thisTm,
                    mode: mode,
                  })
                  if (!currentTktno) {
                    currentTktno = alno + snno + thisTm
                  }
                } else if (res.payload.code === '100') {
                  mList.push({
                    id: MESSAGE.notExistsTicket(alno+'-'+snno).id,
                    message: MESSAGE.notExistsTicket(alno+'-'+snno).text,
                    type: MESSAGE.notExistsTicket(alno+'-'+snno).icon
                  })
                }
              })
              .catch(() =>{})
              .finally(() => {
                count++
                if (count === selected.length) {
                  appAction.setMessage({message: mList})
                  localStorage.setItem('tktList', JSON.stringify(tktList))
                  setEditWindow(window.open(`${TITLE.ticketEdit.mainLink}/${currentTktno}`, '_tktEditList'))
                }
              })
            })
          } else {
            let count = 0
            let currentTktno = ''
            for (const r of selected) {
              const alno = r.tktParentTktno?r.tktParentTktno.substr(0, 3):r.tktTktnoAl
              const snno = r.tktParentTktno?r.tktParentTktno.substr(3):r.tktTktnoSn
              await TicketApi.detailSearch({
                formMode: mode,
                airlineCode: alno,
                sn: snno,
              })
              .then(res => {
                if (res.payload.code === '000') {
                  const find = _.find(tktList, (tkt) => {return tkt.value === alno + snno})
                  if (!find) {
                    tktList.push({
                      name: alno + '-' + snno,
                      value: alno + snno,
                      mode: mode,
                    })
                  }
                  if (!currentTktno) {
                    currentTktno = alno + snno
                  }
                } else if (res.payload.code === '100') {
                  mList.push({
                    id: MESSAGE.notExistsTicket(alno+'-'+snno).id,
                    message: MESSAGE.notExistsTicket(alno+'-'+snno).text,
                    type: MESSAGE.notExistsTicket(alno+'-'+snno).icon
                  })
                }
              })
              .catch(() =>{})
              .finally(() => {
                count++
                if (count === selected.length) {
                  appAction.setMessage({message: mList})
                  setEditWindow(window.open(`${TITLE.ticketEdit.mainLink}/${currentTktno}`, '_tktEditList'))
                }
              })
            }
            tktList.sort((fir, sec) => {
              const firNo = fir.name.substring(fir.name.indexOf('-')+1)
              const secNo = sec.name.substring(sec.name.indexOf('-')+1)
              if (firNo > secNo){
                return 1;
              }else if (firNo < secNo){
                return -1;
              }else{
                return 0;
              }
            })
            localStorage.setItem('tktList', JSON.stringify(tktList))
          }
        }
      })
      .catch(() =>{})
      .finally(() => {})
    }

    if (props.search.windowOpen.text === 'I') {
    } else {
    }
  }

  const openEachWindow = (mode, selected) => {
    let tktNewList = [{name:'', value:'', mode: ''}]
    const mList = []
    const thisTime = _.toString((new Date()).getTime()).substring(4)
    tktNewList = localStorage.getItem('tktNewList')?JSON.parse(localStorage.getItem('tktNewList')):[{name:'', value:'', mode: ''}]
    if (mode === '0') {
      tktNewList.push({
        name: '未登録',
        value: '_new' + thisTime,
        mode: mode,
      })
      localStorage.setItem('tktNewList', JSON.stringify(tktNewList))
      window.open(`${TITLE.ticketEdit.mainLink}/_new${thisTime}`, '_tktEdit_new' + thisTime)
    } else if (mode === '2') {
      // console.log(selected)
      _.forEach(selected, (r, i) => {
        const alno = r.tktParentTktno?r.tktParentTktno.substr(0, 3):r.tktTktnoAl
        const snno = r.tktParentTktno?r.tktParentTktno.substr(3):r.tktTktnoSn
        TicketApi.detailSearch({
          formMode: mode,
          airlineCode: alno,
          sn: snno,
        })
        .then(res => {
          if (res.payload.code === '000') {
            const thisTm = _.toString((new Date()).getTime()).substring(4)
            tktNewList.push({
              name: '未登録',
              value: alno + snno + thisTm,
              mode: mode,
            })
            localStorage.setItem('tktNewList', JSON.stringify(tktNewList))
            window.open(`${TITLE.ticketEdit.mainLink}/${alno + snno + thisTm}`, '_tktEdit_copy' + thisTm)
          } else if (res.payload.code === '100') {
            mList.push({
              id: MESSAGE.notExistsTicket(alno+'-'+snno).id,
              message: MESSAGE.notExistsTicket(alno+'-'+snno).text,
              type: MESSAGE.notExistsTicket(alno+'-'+snno).icon
            })
          }
        })
        .catch(() =>{})
        .finally(() => {
          appAction.setMessage({message: mList})
        })
      })
    } else {
      _.forEach(selected, (r, i) => {
        const alno = r.tktParentTktno?r.tktParentTktno.substr(0, 3):r.tktTktnoAl
        const snno = r.tktParentTktno?r.tktParentTktno.substr(3):r.tktTktnoSn
        TicketApi.detailSearch({
          formMode: mode,
          airlineCode: alno,
          sn: snno,
        })
        .then(res => {
          if (res.payload.code === '000') {
            tktNewList = _.filter(tktNewList, item => item.value !== alno + snno)
            tktNewList.push({
              name: alno + '-' + snno,
              value: alno + snno,
              mode: mode,
            })
            localStorage.setItem('tktNewList', JSON.stringify(tktNewList))
            window.open(`${TITLE.ticketEdit.mainLink}/${alno + snno}`, '_tktEdit_' + alno + snno)
          } else if (res.payload.code === '100') {
            mList.push({
              id: MESSAGE.notExistsTicket(alno+'-'+snno).id,
              message: MESSAGE.notExistsTicket(alno+'-'+snno).text,
              type: MESSAGE.notExistsTicket(alno+'-'+snno).icon
            })
          }
        })
        .catch(() =>{})
        .finally(() => {
          appAction.setMessage({message: mList})
        })
      })
    }
  }

  React.useEffect(() => {
    const curItems = _.filter(props.search.currentItemList, r => r.tktDispType === (props.search.searchUnit.text==='0'?'1':'2'))
    const defItems = _.filter(props.search.defaultItemList, r => r.tktDispType === (props.search.searchUnit.text==='0'?'1':'2'))
    // console.log(curItems)
    // console.log(defItems)
    if (curItems.length > 0) {
      props.setInput({
        dispCheckedItems: JSON.parse(curItems[0].tktSrchlstItem),
      })
    } else if (defItems.length > 0) {
      props.setInput({
        dispCheckedItems: JSON.parse(defItems[0].tktSrchlstItem),
      })
    }
  }, [props.search.defaultItemList, props.search.currentItemList, props.search.searchUnit.text])

  const execDeleteList = () => {
    // 選択された行
    const selectedList = grid.rows.filter(r => r.isSelected).map(r => r.dataItem)
    
    if (grid.rows.filter(r => r.isSelected).length <= 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return
    }
    // console.log(selectedList)

    alert().fire({
      icon: MESSAGE.confirmTktDeleteList().icon,
      title: MESSAGE.confirmTktDeleteList(selectedList.length).text
    })
    .then((result) => {
      if (result.isConfirmed) {

        appAction.showProcessing({isProcessing: true})
        appAction.setMessage('')

        let deleteList = []
        _.forEach(selectedList, r => {
          if (r.tktParentTktno) {
            deleteList.push({
              airlineCode: r.tktParentTktno.substring(0,3),
              sn: r.tktParentTktno.substring(3),
            })
          } else {
            deleteList.push({
              airlineCode: r.tktTktnoAl,
              sn: r.tktTktnoSn,
            })
          }
        })
        const condition = getCondition(props.search)
        // console.log(condition)
        // console.log()
        // return
        props.searchApi.findDelete({
          deleteList: _.uniqWith(deleteList, _.isEqual),
          selDate: props.search.selDate,
        })
        .then(res => {
          appAction.setMessage({message: res.payload.messageList})
          if (res.payload.code === '000') {
            props.setInput({
              keepMessage: true,
              searching: true
            })
          } else {
            appAction.showProcessing({isProcessing: false})
          }
        })
        .catch(err => {
          // setName('')
        })
        .finally(() => {
        })
      }
    })
  }

  const execProcMenu = (id) => {
    // console.log(id)
    switch (id) {
      case 'menu1':
        tktExportAction.setInputTktExport({
          searchJson: props.search.searchJson,
          searchSql: props.search.searchSql,
          condition: props.search.condition,
        })
        history.push(TITLE.ticketExport.link, {caller: 'tktSearch'})
        break;
      case 'menu2':
        tktExcelAction.setInputTktExcel({
          searchJson: props.search.searchJson,
          searchSql: props.search.searchSql,
          condition: props.search.condition,
        })
        history.push(TITLE.ticketExcel.link, {caller: 'tktSearch'})
        break;
      case 'menu3':
        pivotAction.setInputPivot({
          searchJson: props.search.searchJson,
          searchSql: props.search.searchSql,
          condition: props.search.condition,
        })
        history.push(TITLE.pivot.link, {caller: 'tktSearch'})
        break;
      case 'menu4':
        reportAction.setInputReport({
          searchJson: props.search.searchJson,
          searchSql: props.search.searchSql,
          condition: props.search.condition,
        })
        history.push(TITLE.report.link, {caller: 'tktSearch'})
        break;
    }
  }

  const itemFormatter = (panel, r, c, cell) => {
    // 列ヘッダは中央
    if (panel.cellType == 2) {
      cell.style.textAlign = 'center';
      if (props.search.dispCheckedItems) {
        const coldef = _.find(props.search.dispCheckedItems, r => r.id === panel.columns[c].binding)
        // console.log(coldef)
        if (coldef && coldef.bulkEdit && location.pathname.startsWith('/tkt/search')) {
          cell.style.color = 'deeppink';
        } else {
          cell.style.color = 'black';
        }
      }
    }
  }

  const formatItem = (g, e) => {
    const item = e.getRow().dataItem
    const bind = e.getColumn().binding

    let isMinus = (bind && (bind.startsWith('taxTaxAmt') || bind.startsWith('rtxRftaxAmt')) && item && item[bind] && _.isNumber(item[bind]) && item[bind] < 0)
    toggleClass(e.cell, 'cell-minus', isMinus);

    hdrTips.setTooltip(e.cell, '<span class="col-header">' + e.getColumn().header + '</span>');
  }

  const hdrTips = new Tooltip({
    position: PopupPosition.RightTop,
    showAtMouse: true,
    showDelay: 600,
    cssClass: 'hdr-tip'
  });

  return (
    <React.Fragment>
      {location.pathname.startsWith('/tkt/search') &&
      <BulkEditPopup
        popup={popup}
        setPopup={setPopup}
        popupData={popupData}
        setPopupData={setPopupData}
        grid={grid}
        header='一括修正'
        search={props.search}
      />
      }
      {location.pathname.startsWith('/tkt/search') &&
      <ContextMenu
        grid={grid}
        dispCheckedItems={props.search.dispCheckedItems}
        popup={popup}
        setPopupData={setPopupData}
      />
      }
      {props.search.data && props.search.data.length > 0 &&
        <Card 
          root='max-button'
          size='lg'
          hideClose
          header={props.title.header}
          subheader={props.title.subheader}
          body={
            <React.Fragment>
              <div className='list-header d-flex align-items-end'>
                <div className='flex-fill width-25'>
                  <span className='list-count ml-3'>{props.search.data.length}</span>
                  <span>件 抽出しました</span>
                </div>
                {location.pathname.startsWith('/tkt/search') &&
                <div className=''>
                  <div className='d-inline-block width-36'>
                    <div className='width-36 color-text'>※ソート順は「Ctrl＋左クリック」で解除できます。</div>
                  </div>
                  <div className='d-inline-block width-52'>
                    <div className='width-52'>
                      <span className='color-text'>
                        ※
                      </span>
                      <span className='color-red'>
                        赤字
                      </span>
                      <span className='color-text'>
                        の項目は一括修正できます。修正対象項目の列で右クリックしてください。
                      </span>
                    </div>
                  </div>
                </div>
                }
                <div className='width-10'><span className='wj-glyph-filter' />：絞り込み</div>
                <div className='width-16'>
                  <ItemPopup
                    id='list-settings'
                    listItems={props.search.searchUnit && props.search.searchUnit.text==='0'?LIST_ITEMS.ticket:LIST_ITEMS.flight}
                    button={<span><span className='material-icons-outlined settings'>settings</span>表示項目設定</span>}
                    setInput={props.setInput}
                    dispCheckedItems={props.search.dispCheckedItems}
                    search={props.search}
                    searchApi={props.searchApi}
                    tktSrchptPrivForm={props.tktSrchptPrivForm}
                  />
                </div>
              </div>
              <div id='search-list-body' className='list-body' >
                <wjGrid.FlexGrid
                  deferResizing={true}
                  itemsSource={props.search.data}
                  allowSorting={'MultiColumn'}
                  allowDragging={false}
                  showMarquee={false}
                  frozenColumns={2}
                  initialized={(s) => initGrid(s)}
                  itemFormatter={(panel, r, c, cell) => itemFormatter(panel, r, c, cell)}
                  formatItem={(g, e) => formatItem(g, e)}
                  loadingRows={() => hdrTips.dispose()}
                >
                  <FlexGridFilter />
                  {props.search.dispCheckedItems.map((r,i) => {
                    return (
                        <wjGrid.FlexGridColumn
                          key={i}
                          binding={r.id}
                          header={r.header}
                          dataType={r.dataType}
                          dataMap={
                            (r.id === 'tktStatus')?new wjGridNom.DataMap(TKT_STATUS, 'value', 'name'):null
                          }
                          format={r.format}
                          isReadOnly={r.isReadOnly}
                          isRequired={r.isRequired}
                          maxLength={r.maxLength}
                          width={r.width}
                          minWidth={r.minWidth}
                          maxWidth={r.maxWidth}
                          allowSorting={r.allowSorting}
                          align={r.align}
                        >
                        </wjGrid.FlexGridColumn>
                    )
                  })
                }
                </wjGrid.FlexGrid>
              </div>
            </React.Fragment>
          }
        />
      }
      <Card 
        size='lg'
        root='button'
        hideClose
        header={null}
        subheader={null}
        body={
          <div className='card-buttom-button'>
            {location.pathname.startsWith('/tkt/search') &&
              <React.Fragment>
                <Button
                  {...props.search.delete}
                  className='button-warn mr-5 width-max-content'
                  disabled={app.isProcessing || props.search.delete.disabled}
                  onClick={(e) => execDeleteList()}
                >
                  {props.search.delete.label}
                </Button>
                <Button
                  {...props.search.display}
                  className='button-gray mr-1 width-max-content'
                  disabled={app.isProcessing}
                  onClick={(e) => windowOpen('3')}
                >
                  {props.search.display.label}
                </Button>
                <Button
                  {...props.search.edit}
                  className='button-gray mr-1 width-max-content'
                  disabled={app.isProcessing}
                  onClick={(e) => windowOpen('1')}
                >
                  {props.search.edit.label}
                </Button>
                <Button
                  {...props.search.new}
                  className='button-gray mr-1 width-max-content'
                  disabled={app.isProcessing}
                  onClick={(e) => windowOpen('0')}
                >
                  {props.search.new.label}
                </Button>
                <Button
                  {...props.search.copy}
                  className='button-gray mr-3 width-max-content'
                  disabled={app.isProcessing}
                  onClick={(e) => windowOpen('2')}
                >
                  {props.search.copy.label}
                </Button>
                <div className='mr-auto width-max-content'>
                  <Common.RadioButton
                    btnSize='sm'
                    className='mr-5 width-25'
                    attrs={props.search.windowOpen}
                    radios={RADIOS.windowOpen}
                    onChange={props.onBlur}
                  />
                </div>
                <Button
                  {...props.search.csv}
                  className={`button-gray ml-auto mr-5 width-max-content`}
                  onClick={(e) => download()}
                  disabled={app.isProcessing || login.auth020!=='1'}
                >
                  {props.search.csv.label}
                </Button>
                <Dropdown className='mr-auto' drop='up'>
                  <Dropdown.Toggle
                    {...props.search.menu}
                    className='button-gray width-max-content'
                    disabled={app.isProcessing}
                  >
                    {props.search.menu.label}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='position-fixed'>
                    {props.search.menu.submenu.map((r, i) => {
                      let isDisabled = false
                      switch (i) {
                        case 0: isDisabled = login.auth040 !== '1'; break;
                        case 1: isDisabled = login.auth050 !== '1'; break;
                        case 2: isDisabled = login.auth060 !== '1'; break;
                        case 3: isDisabled = login.auth070 !== '1'; break;
                      }
                      return (
                        <Dropdown.Item as="button"
                          {...r}
                          disabled={isDisabled}
                          onClick={() => execProcMenu(r.id)}
                          key={i}
                        >
                          {r.label}
                        </Dropdown.Item>
                      )}
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  {...props.search.research}
                  className={`button-info width-max-content ${(researchVisible)?'':'invisible'}`}
                  onClick={(e) => props.setInput({sizeHorizontal:['70%', '1']})}
                  disabled={app.isProcessing}
                >
                  {props.search.research.label}
                </Button>
              </React.Fragment>
            }
            {!location.pathname.startsWith('/tkt/search') &&
              <React.Fragment>
                <Button
                  {...props.search.exec}
                  className='button-gray width-max-content ml-auto'
                  onClick={(e) => {
                    props.setInput({
                      searchingAndExec: true,
                      nextLoc: nextLoc,
                      nextState: nextState,
                    })
                    // history.push(nextLoc, nextState)
                  }}
                  disabled={app.isProcessing}
                >
                  {props.search.exec.label}
                </Button>
                <Button
                  {...props.search.research}
                  className={`
                    button-info
                    width-max-content
                    ml-auto
                    ${(props.search.data && props.search.data.length > 0)?'':'invisible'}
                    ${(researchVisible)?'':'invisible'}
                  `}
                  onClick={(e) => props.setInput({
                    sizeHorizontal:['70%', '1']
                  })}
                  disabled={app.isProcessing}
                >
                  {props.search.research.label}
                </Button>
              </React.Fragment>
            }
            </div>
        }
      />
    </React.Fragment>
  )
}