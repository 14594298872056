import React from 'react';
import axios from 'axios';
import { push, goBack } from 'connected-react-router';
import { store } from 'store'
import { BASE_URL } from 'constants/index';
import { TITLE } from 'constants/title';
import { alert } from "lib";
import { AppAction } from 'ducks/App';
import { LoginAction } from 'ducks/Login';
// import swal from '@sweetalert/with-react';

export const axiosInstanceForLambda = axios.create({
  // baseURL: BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
});

axiosInstanceForLambda.interceptors.request.use(request => {
  // request.headers['authorization'] = store.getState().Login.authorization
  // request.headers['x-uripro-oid'] = store.getState().Login.office
  return request
})

export const axiosInstanceMigration = axios.create({
  baseURL: BASE_URL,
  // timeout: 30000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
});

export const axiosInstanceNoerr = axios.create({
  baseURL: BASE_URL,
  // timeout: 30000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
});

export const axiosInstanceBin = axios.create({
  baseURL: BASE_URL,
  // timeout: 30000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'multipart/form-data',
    'Cache-Control': 'no-cache',
  }
});

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  // timeout: 30000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }
});
axiosInstance.interceptors.request.use(request => {
  // request.headers['X-XSRF-TOKEN'] = store.getState().Account.token;
  return request
})

axiosInstance.interceptors.response.use(
  response => {
    // console.log(response)
    let message = []
    if (response.data.code === '300') {
      if (!store.getState().Login.needReLogin) {
        store.dispatch(LoginAction.setInputLogin({needReLogin: true}))
      }
      response.data.messageList = []
      return response
      // console.log(response)
      // if (window.name.startsWith('_tktEdit')) {
      //   window.close()
      // }
      // message = [].concat(response.data.messageList)
      // store.dispatch(LoginAction.setInputLogin({
      //   loginCd: '',
      //   mbrCd: '',
      //   office: '',
      //   authCode: response.data.code,
      // }))
      // store.dispatch(AppAction.setInputApp({
      //   isHeaderShow: false,
      //   isFooterShow: false,
      // }))
      // store.dispatch(push(TITLE.error.link, {message: message, nonRouteLeavingGuard:true}))
    } else if(response.data.code === '301') {
      if (window.name.startsWith('_tktEdit')) {
        window.close()
      }
      message = [].concat(response.data.messageList)
      alert().fire({
        icon: 'error',
        title: message[0]?message[0].message + '(' + message[0].id + ')':'権限がありません。',
        showCancelButton: false,
      })
      .then(() => {
        store.dispatch(push(TITLE.main.link, {message: '', nonRouteLeavingGuard:true}))
      });
    } else {
      return response
    }
  },
  error => {
    // console.log(error)
    // return error;
    // console.log(error.response)
    const message = [error.response?{id: error.response.status, message:error.response.statusText, type: 'error'}:{id: '', message:error.message, type: 'error'}];
    const payload = {
      code: '900',
      message: message,
    }
    // store.dispatch(AccountAction.setAccount({isLoggedIn: false}))
    // store.dispatch(AccountAction.setAccount({auth: false}))
    // store.dispatch(AppAction.setMessage({message:message}))
    // store.dispatch(push(TITLE.error.link, {message: message, nonRouteLeavingGuard:true}))
    // console.log(message)
    if (error.message === 'Network Error') return error
    else store.dispatch(push(TITLE.error.link, {message: message, nonRouteLeavingGuard:true}))
    // return payload
  }
)