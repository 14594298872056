import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { TktImportAction } from 'ducks/TktImport';
import { TicketApi, ReportApi, PrismApi, ProcessApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import { LIST_ITEMS } from 'constants/process';
import { convert } from "lib";
import { Spinner } from 'react-bootstrap';
import * as Common from 'components/common';

export const ProcessingPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);

  const [result, setResult] = React.useState(null)
  const [resultDetail, setResultDetail] = React.useState([])
  const [grid, setGrid] = React.useState(null)


  const initGrid = (g) => {
    setGrid(g)
    g.selectionMode = 3
  }

  const initPopup = (form) => {
    props.setPopup(form)
  }

  // React.useEffect(() => {
  //   console.log(app.processingSeq)
  //   console.log(props.popup.isVisible)
  // }, [app.processingSeq])

  React.useEffect(() => {
    setTimeout(() => {
      const procRes = _.filter(app.processingList, r => r.processingSeq == app.processingSeq)
      // console.log(procRes)
      if (result === null && props.popup && props.popup.isVisible && procRes.length > 0) {
        // console.log('view result')
        if (procRes[0].processingResult !== null) {
          // 処理詳細取得
          appAction.showProcessing({isProcessing: false})
          ProcessApi.get(app.processingSeq)
          .then(res => {
            // console.log(res)
            if (res.payload.code === '000') {
              // console.log(res.payload.code)
              const result = res.payload.bizProcessingResultDtoList
              const resultDetail = res.payload.bizProcessingResultDetailDtoList
              // 
              // console.log(app.processingSeq)
              // console.log(sessionStorage.getItem('processedSeq')?_.toNumber(sessionStorage.getItem('processedSeq')):-1)
              if (app.processingSeq > (sessionStorage.getItem('processedSeq')?_.toNumber(sessionStorage.getItem('processedSeq')):-1)) {
                // console.log(sessionStorage.getItem('processedSeq'))
                // console.log(app.processingSeq)
                sessionStorage.setItem('processedSeq', app.processingSeq)
              }
              if (result[0].processingResult != 1){
                // 出力
                if (result[0].processType1 !== '0' && result[0].s3FilePath) {
                  // console.log(result[0].s3FilePath)
                  let apiName = null
                  let type = 'application/octet-stream'
                  switch (result[0].processType1) {
                    case '1':
                      apiName = TicketApi.outputFile
                      type = 'application/octet-stream'
                      break
                    case '2':
                      apiName = TicketApi.excelFile
                      type = 'application/octet-stream'
                      break
                    case '3':
                      apiName = PrismApi.outputFile
                      type = (result[0].processType2 === '0')?'application/pdf' :'application/octet-stream'
                      break
                    // case '4':
                    //   apiName = TicketApi.outputFile
                    //   type = (procRes[0].processType2 === '0')?'text/csv' :'application/octet-stream'
                    //   break
                    case '5':
                      apiName = ReportApi.outputFile
                      type = (result[0].processType2 === '0')?'application/pdf' :'application/octet-stream'
                      break
                    default: return
                  }
                  // ファイルダウンロード
                  // console.log(apiName)
                  // console.log(result[0].s3FilePath)
                  // console.log(result[0].s3FilePath.split(','))
                  _.forEach(result[0].s3FilePath.split(','), (filename, i) => {
                    const dto = {
                      processingResultDto: {
                        processingSeq: app.processingSeq,
                        processType2: result[0].processType2,
                      },
                      downloadFileName: filename,
                      responseType: 'blob',
                    }
                    apiName(dto).then((res) => {
                      if (res.payload.messageList) {
                        appAction.setMessage({message: res.payload.messageList,})
                      } else {
                        appAction.setMessage('')
                        const blob = new Blob([res.payload], {type: type})
                        if (window.navigator.msSaveOrOpenBlob) {
                          // for IE,Edge
                          window.navigator.msSaveOrOpenBlob(blob, filename);
                        } else {
                          // for chrome, firefox
                          // console.log(type)
                          // console.log(res.payload)
                          const url = URL.createObjectURL(blob);
                          const linkEl = document.createElement('a');
                          linkEl.href = url;
                          linkEl.setAttribute('download', filename);
                          document.body.appendChild(linkEl);
                          linkEl.click();
                          URL.revokeObjectURL(url);
                          linkEl.parentNode.removeChild(linkEl);
                        }
                      }
                    })
                  })
                } else {
                  
                }
              }
              setResult(result[0])
              setResultDetail(resultDetail)
            }
          })
          .catch(err => {})
          .finally(() => {
            appAction.showProcessing({isProcessing: false})
          })
        }
      }
    }, 500)
  }, [app.processingList])

  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
      >
        <Card 
          root='popup w-75vw'
          size='md'
          hideClose
          header={props.header}
          headerClass='wj-dialog-header master-import'
          subheader={props.subheader}
          // body={props.body}
          body={
            result===null?
              <Form className='w-100'>
                <div className='processing-title'>{props.processingTitle}</div>
                <div className="d-flex justify-content-center m-5">
                  <Spinner className='processing-circle' animation="border" />
                </div>
                <div>{props.processingMessage1}</div>
                <div>{props.processingMessage2}</div>
              </Form>
            :
              <Form className='w-100'>
                <div className='processing-title'>{props.resultTitle}</div>
                <div className="d-flex justify-content-center m-4 popup-error-text">
                  {result.message?'エラー：'+result.message:''}
                </div>
                <div className={props.resultMessage1?'':'d-none'}>{props.resultMessage1}</div>
                <div className={props.resultMessage2?'':'d-none'}>{props.resultMessage2}</div>
                <div className='process-detail-list-body' >
                  <wjGrid.FlexGrid
                    deferResizing={true}
                    itemsSource={resultDetail}
                    allowSorting={'None'}
                    allowDragging={false}
                    showMarquee={true}
                    frozenColumns={1}
                    initialized={(s) => initGrid(s)}
                    autoRowHeights={true}
                  >
                    {LIST_ITEMS.detail.map((r,i) => {
                      if (i > 0)
                      return (
                        <wjGrid.FlexGridColumn
                          key={i}
                          binding={r.id}
                          header={r.header}
                          dataType={r.dataType}
                          format={r.format}
                          isReadOnly={r.isReadOnly}
                          isRequired={r.isRequired}
                          maxLength={r.maxLength}
                          width={r.width}
                          minWidth={r.minWidth}
                          maxWidth={r.maxWidth}
                          allowSorting={r.allowSorting}
                          align={r.align}
                          dataMap={r.dataMap}
                          multiLine={true}
                        />
                      )})
                    }
                  </wjGrid.FlexGrid>
                </div>
              </Form>
          }
        />
        <Card 
          size='md'
          root={`popup-button ${props.size}`}
          // body={props.button}
          body={
            <React.Fragment>
              <Button
                className='button-gray mr-auto ml-auto'
                onClick={() => {
                  setResult(null)
                  props.popup.dialogResult = 'wj-hide-ok'
                  props.popup.hide()
                }}
              >
                <span className='material-icons-outlined md mr-2'>remove_circle_outline</span>
                閉じる
              </Button>
              {/* <Button
                className='button-blue ml-auto'
                // onClick={() => execUpload(master.isCode, master.type)}
                disabled={app.isProcessing}
              >
                <span className='material-icons-outlined md mr-2'>file_upload</span>
                {skip > 0?'再実行':'OK'}
              </Button> */}
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})