import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { TicketEditHeader as TicketEditHeader } from 'components/Ticket/edit/TicketEditHeader';
import { TicketEditPane as TicketEditPane } from 'components/Ticket/edit/TicketEditPane';
import { SettingPopup as SettingPopup } from 'components/Ticket/modal/SettingPopup';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Dropdown,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert, alert, dataMapper } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE, LIST_ITEMS, TKT_KB_FLAG_MAP, FORM_MODE, DETAIL_SETTINGS, FARE_CD } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { ReportAction } from 'ducks/Report';

const ResponsiveGridLayout = WidthProvider(Responsive);

export const TicketEditComponent = () => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const tktEdit = useSelector(state => state.TktEdit);
  
  const history = useHistory();
  const location = useLocation();
  const { tktno } = useParams();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);
  const reportAction = bindActionCreators(ReportAction, dispatch);

  // const [editPane, setEditPane] = React.useState([
  //   {x: 0, y:   0, w: 1, h: 19, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'calc', name: '運賃・精算',},
  //   {x: 1, y:   0, w: 1, h: 19, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'fare', name: 'FARE',},
  //   {x: 0, y:  19, w: 2, h: 11, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'acc', name: 'アカウントコード',},
  //   {x: 0, y:  30, w: 2, h: 16, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'flight', name: 'フライト情報',},
  //   {x: 0, y:  46, w: 1, h: 13, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'tax', name: 'TAX',},
  //   {x: 1, y:  46, w: 1, h: 13, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'card', name: 'CARD情報',},
  //   {x: 0, y:  59, w: 1, h: 38, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'refund', name: 'REFUND',},
  //   {x: 1, y:  59, w: 1, h: 10, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'reissue', name: 'REISSUE',},
  //   {x: 0, y:  97, w: 1, h: 13, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'cust', name: '得意先情報',},
  //   {x: 1, y:  69, w: 1, h: 19, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'other', name: 'その他情報',},
  //   {x: 0, y: 110, w: 2, h: 20, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'emd', name: 'EMD情報',},
  //   {x: 0, y: 130, w: 1, h: 15, static: false, isDraggable: true, isResizable: false, isShow:true, i: 'add', name: '各種追加料金',},
  // ])
  const [popup, setPopup] = React.useState(null)
  const [popupData, setPopupData] = React.useState(null)
  const [initLoaded, setInitLoaded] = React.useState(false)
  const [tktNoList, setTktNoList] = React.useState([])
  const [btnDisabled, setBtnDisabled] = React.useState(false)
  const [thisNo, setThisNo] = React.useState('')
  const [selectedTkt, setSelectedTkt] = React.useState('')
  const [nonDirty, setNonDirty] = React.useState(false)
  const [gridEditing, setGridEditing] = React.useState(false)

  React.useEffect(() => {
    // console.log(thisNo)
    // console.log(selectedTkt)
    const titleTktno = selectedTkt&&selectedTkt.name?selectedTkt.name:''
    document.title = titleTktno;
  }, [thisNo, selectedTkt])

  React.useEffect(() => {
    let airlineCode = ''
    let sn = ''

    // console.log('tktno:' + tktno)
    // console.log('thisNo:' + thisNo)
    // console.log(app.tktList)

    if (thisNo) {
      if (tktno !== thisNo) {
        setNonDirty(false)
        history.push(`${TITLE.ticketEdit.mainLink}/${thisNo}`, {nonRouteLeavingGuard:nonDirty, init: true})
      } else {
        // airlineCode = thisNo?thisNo.substring(0, 3):tktno.substring(0, 3)
        // sn =  thisNo?thisNo.substring(3):tktno.substring(3)
        // // if (window.name !== '_tktEditList') window.name = '_tktEdit_' + tktno
        // const selectedTktItem = _.find(app.tktList, r => r.value.substring(0, 13) === tktno)

        // console.log(selectedTktItem)
        // if (selectedTktItem) {
        //   console.log('formMode:' + selectedTktItem.mode)
        //   appAction.setInputApp({tktEditFormMode: selectedTktItem.mode})
        // }
        // console.log('setThisNo:' + airlineCode + sn)
        // setThisNo(airlineCode + sn)
        // console.log('Loading......................')
        // console.log(selectedTkt)
        if (selectedTkt) execLoad()
      }
    } else {
      let thisTime = ''
      let thisNoFromWindow = ''
      // console.log(window.name)
      if (window.name === '_tktEditList') {
        thisNoFromWindow = tktno
        // console.log('setThisNo:' + thisNoFromWindow)
      } else if (window.name.startsWith('_tktEdit_new')) {
        thisTime = window.name.replace('_tktEdit_new', '')
        thisNoFromWindow = '_new' + thisTime
        // console.log('setThisNo:' + thisNoFromWindow)
      } else if (window.name.startsWith('_tktEdit_copy')) {
        thisTime = window.name.replace('_tktEdit_copy', '')
        thisNoFromWindow = tktno
        // console.log('setThisNo:' + thisNoFromWindow)
      } else {
        thisNoFromWindow = tktno
        // console.log('setThisNo:' + thisNoFromWindow)
      }
      setThisNo(thisNoFromWindow)
    }
  }, [tktno, history.location.key, thisNo, selectedTkt])

  const execLoad = () => {
    if (!thisNo) return
    appAction.showProcessing({isProcessing: true})
    appAction.setMessage(app.resultMsg?{message:app.resultMsg}:'')
    appAction.setInputApp({resultMsg:''})
    let formMode = app.tktEditFormMode
    let airlineCode = thisNo.substring(0, 3)
    let sn = thisNo.substring(3, 13)

    TicketApi.detailSearch({
      formMode: formMode,
      airlineCode: airlineCode,
      sn: sn,
    })
    .then(res => {
      let obj = {}

      let tktTktnoAl = ''
      let tktTktnoSnFrom = ''
      let tktTktnoSnTo = ''
      obj.tktDataListDto = []
      if (res.payload.code === '000') {
        const count = res.payload.ticketDetailSearchResultListDto.length
        const tktData = res.payload.ticketDetailSearchResultListDto[0]
        const lastTktData = count>1?res.payload.ticketDetailSearchResultListDto[count-1]:tktData
        const tktTktno = tktData.tktDataListDto.tktTktnoAl + tktData.tktDataListDto.tktTktnoSn
        if ((tktData.tktDataListDto.tktTktnoAl + tktData.tktDataListDto.tktTktnoSn) !== tktno.substring(0, 13)) {
          // tktListの更新
          const newTktList = []
          _.forEach(app.tktList, (r, i) => {
            if (r.value === thisNo) {
              newTktList.push({
                value: tktTktno,
                name: tktTktno.substring(0, 3) + '-' + tktTktno.substring(3),
                mode: '1',
              })
            } else if (r.value !== tktTktno) {
              newTktList.push(r)
            }
          })
          // console.log(newTktList)
          if (window.name === '_tktEditList') {
            appAction.setInputApp({tktList: newTktList})
            localStorage.setItem('tktList', JSON.stringify(newTktList))
          } else {
            appAction.setInputApp({tktList: newTktList})
            localStorage.setItem('tktNewList', JSON.stringify(newTktList))
          }
          // console.log('history2')
          if (window.name !== '_tktEditList') {
            window.name = '_tktEdit_' + tktTktno
          }
          setThisNo(tktTktno)
          history.replace(`${TITLE.ticketEdit.mainLink}/${tktData.tktDataListDto.tktTktnoAl + tktData.tktDataListDto.tktTktnoSn}`,
            {nonRouteLeavingGuard:true, init: true, formMode: formMode})
          return
        }

        tktTktnoAl = tktData.tktDataListDto.tktTktnoAl
        tktTktnoSnFrom = tktData.tktDataListDto.tktTktnoSn
        tktTktnoSnTo = lastTktData.tktDataListDto.tktTktnoSn.substr(-3)
        if (formMode === '2') {
          // console.log(tktData.tktDataListDto.tktTktnoSn)
          obj.copyTktTktnoAl = tktData.tktDataListDto.tktTktnoAl
          obj.copyTktTktnoSnFrom = tktData.tktDataListDto.tktTktnoSn
          obj.copyTktTktnoSnTo = lastTktData.tktDataListDto.tktTktnoSn.substr(-3)
          tktTktnoSnFrom = tktData.tktDataListDto.tktTktnoSn.substring(0, 7)
          tktTktnoSnTo = ''
        }

        let flightList = []
        let emdList = []
        _.forEach(count > 0 && res.payload.ticketDetailSearchResultListDto, r => {
          let data = {}
          _.forEach(r.tktDataListDto, (v, k) => {
            if (k.startsWith('tktCpnNo')) data[k] = (v !== '0')
          })
          obj.tktDataListDto.push({
            ...r.tktDataListDto,
            ...data,
          })
          // フライト
          let flItem = {}
          _.forEach(r.fltDataListDto, (fl, i) => {
            flItem = {...fl}
            _.forEach(_.filter(LIST_ITEMS.flight, 'date'), (record, index) => {
              flItem[record.id] = convert.toDate(fl[record.id])
            })
            _.forEach(_.filter(LIST_ITEMS.flight, litem => !litem.date && litem.dataType==='Date'), (record, index) => {
              flItem[record.id] = convert.hhmm2date(fl[record.id])
            })
            // console.log(flItem)
            flightList.push(flItem)
          })
          // EMD
          let emdItem = {}
          _.forEach(r.bstEmdCpnDataListDto, (emd, i) => {
            emdItem = {...emd}
            // _.forEach(_.filter(LIST_ITEMS.flight, 'date'), (record, index) => {
            //   emdItem[record.id] = convert.toDateString(convert.yyyymmdd2string(fl[record.id], 'yyyy-MM-dd'), 'yyyy/MM/dd')
            // })
            // _.forEach(_.filter(LIST_ITEMS.flight, litem => !litem.date && litem.dataType==='Date'), (record, index) => {
            //   emdItem[record.id] = convert.hhmm2date(fl[record.id])
            // })
            // console.log(emdItem)
            emdList.push(emdItem)
          })
        })
        // console.log(flightList)
        // console.log(emdList)
        _.forEach(tktEdit, (v, k) => {
          _.forEach(tktData.tktDataListDto, (data, key) => {
            if (v && !_.isUndefined(v.value) && key === k) {
              obj[k] = {
                ...v,
                // disabled: (key === 'tktRcvDate' || key === 'tktRstDate' || key === 'tktStatus'),
                original: data,
                value: data,
                text: data,
              }
              
              if (v.type && v.type.startsWith('datetime')) {
                const dateString = convert.toDateTimeString(data, 'yyyy/MM/dd HH:mm:ss')
                obj[k] = {
                  ...v,
                  original: dateString?new Date(dateString):null,
                  value: dateString?new Date(dateString):null,
                  text: dateString?new Date(dateString):null,
                }
              } else if (v.type && v.type.startsWith('date')) {
                const dateString = convert.toDateString(data, 'yyyy/MM/dd')
                obj[k] = {
                  ...v,
                  original: dateString?new Date(dateString):null,
                  value: dateString?new Date(dateString):null,
                  text: dateString?new Date(dateString):null,
                }

                if (k === 'tktVoidDate' || (k === 'tktRefDate' && !obj.tktRefundVoidDate.text)) {
                  obj.tktRefundVoidDate = {
                    ...tktEdit.tktRefundVoidDate,
                    original: dateString?new Date(dateString):null,
                    value: dateString?new Date(dateString):null,
                    text: dateString?new Date(dateString):null,
                  }
                }

              }
              if (!_.isUndefined(v.suffix) && v.suffix === '%') {
                obj[k] = {
                  ...v,
                  original: !data?'00000':data / 100,
                  value: !data?'00000':data / 100,
                  text: !data?'00000':data / 100,
                }
              }
              if (!_.isUndefined(v.type) && v.type === 'number') {
                obj[k] = {
                  ...v,
                  original: !data?0:_.toNumber(data),
                  value: !data?0:_.toNumber(data),
                  text: !data?0:_.toNumber(data),
                }
              }
              if (k === 'tktVoidId' || k === 'tktOvoId') {
                obj[k] = {
                  ...v,
                  original: data==='V'?'1':'0',
                  value: data==='V'?'1':'0',
                  text: data==='V'?'1':'0',
                }
              }
              if (k === 'tktRefId' || k === 'tktAtRefid') {
                obj[k] = {
                  ...v,
                  original: data==='R'?'1':'0',
                  value: data==='R'?'1':'0',
                  text: data==='R'?'1':'0',
                }
              }
              if (k === 'tktReissueId') {
                obj[k] = {
                  ...v,
                  original: data==='E'?'1':'0',
                  value: data==='E'?'1':'0',
                  text: data==='E'?'1':'0',
                }
              }
              if (k === 'tktNdcFlg') {
                obj[k] = {
                  ...v,
                  original: data==='D'?'1':'0',
                  value: data==='D'?'1':'0',
                  text: data==='D'?'1':'0',
                }
              }
              if (k === 'tktDetailIssuedFlg' || k === 'tktCustomerOutputFlg' || k === 'tktAgentCouponOutputFlg'
              || k === 'tktCccfOutputFlg' || k === 'tktRefundNoticeOutputFlg') {
                obj[k] = {
                  ...v,
                  original: data==='1'?'1':'0',
                  value: data==='1'?'1':'0',
                  text: data==='1'?'1':'0',
                }
              }
            }
            if (k === 'tktBookingPcc' && key === 'tktBookingPcc' && data) {
              if (!v.original) {
                obj.tktBookingPcc = {
                  ...v,
                  original: data,
                  value: data,
                  text: data,
                }
              }
            }
            if (k === 'tktBookingPcc' && key === 'tktReserveOfficeid' && data) {
              obj.tktBookingPcc = {
                ...v,
                original: data,
                value: data,
                text: data,
              }
            }
            if (k === 'tktTktPcc' && key === 'tktTktPcc' && data) {
              if (!v.original) {
                obj.tktTktPcc = {
                  ...v,
                  original: data,
                  value: data,
                  text: data,
                }
              }
            }
            if (k === 'tktTktPcc' && key === 'tktTicketingOfficeid' && data) {
              obj.tktTktPcc = {
                ...v,
                original: data,
                value: data,
                text: data,
              }
            }
          })
        })

        obj.fltDataListDto = flightList
        obj.fltDataStListDto = tktData.fltDataStListDto
        obj.bstTktTaxListDto = tktData.bstTktTaxListDto
        obj.tktRefundTaxListDto = tktData.tktRefundTaxListDto
        obj.bstEmdCpnDataListDto = emdList
        obj.bstTktAmtItemListDto = tktData.bstTktAmtItemListDto
        obj.tktTktnoAl = {
          ...tktEdit.tktTktnoAl,
          original: tktTktnoAl,
          value: tktTktnoAl,
          text: tktTktnoAl,
        }
        obj.tktTktnoSnFrom = {
          ...tktEdit.tktTktnoSnFrom,
          original: tktTktnoSnFrom,
          value: tktTktnoSnFrom,
          text: tktTktnoSnFrom,
        }
        obj.tktTktnoSnTo = {
          ...tktEdit.tktTktnoSnTo,
          original: tktTktnoSnTo,
          value: tktTktnoSnTo,
          text: tktTktnoSnTo,
        }
        formMode = app.tktEditFormMode
      } else if (res.payload.code === '100') {
        // console.log(tktno)
        // console.log(app.tktList)
        // console.log(thisNo)
        if (!tktno || !tktno.startsWith('_new')) {
          tktTktnoAl = tktno.substr(0, 3)
          tktTktnoSnFrom = tktno.substr(3, 10)
        } else {
          tktTktnoAl = ''
          tktTktnoSnFrom = ''
        }
        obj.tktTktnoAl = {
          ...tktEdit.tktTktnoAl,
          original: tktTktnoAl,
          value: tktTktnoAl,
          text: tktTktnoAl,
        }
        obj.tktTktnoSnFrom = {
          ...tktEdit.tktTktnoSnFrom,
          original: tktTktnoSnFrom,
          value: tktTktnoSnFrom,
          text: tktTktnoSnFrom,
        }
        obj.tktIsuDate = {
          ...tktEdit.tktIsuDate,
          original: convert.toDate(convert.toDateString(new Date()).substring(0, 10)),
          value: convert.toDate(convert.toDateString(new Date()).substring(0, 10)),
          text: convert.toDate(convert.toDateString(new Date()).substring(0, 10)),
        }
        obj.tktPaxType = {
          ...tktEdit.tktPaxType,
          original: ' ',
          value: ' ',
          text: ' ',
        }
        obj.tktCrs = {
          ...tktEdit.tktCrs,
          original: '5',
          value: '5',
          text: '5',
        }
        obj.tktTktType = {
          ...tktEdit.tktTktType,
          original: '6',
          value: '6',
          text: '6',
        }
        obj.tktFareType = {
          ...tktEdit.tktFareType,
          original: 'NOM',
          value: 'NOM',
          text: 'NOM',
        }
        // obj.tktTktnoSnTo = {
        //   ...tktEdit.tktTktnoSnTo,
        //   original: tktTktnoSnTo,
        //   value: tktTktnoSnTo,
        //   text: tktTktnoSnTo,
        // }
        if (formMode === '2' || formMode === '3') {
          // console.log(res.payload.messageList)
          appAction.setMessage({message: res.payload.messageList})
          formMode = '3'
        } else {
          formMode = '0'
        }
      } else {
        appAction.setMessage({message: res.payload.messageList})
        setAllDisabled(true)
      }

      // 表示設定
      obj.detailSettings = {}
      obj.editPane = []
      if (res.payload.code === '000' || res.payload.code === '100') {
        if (!res.payload.displayItemSettingDto.bizTktDetailItemDto) {
          _.forEach(DETAIL_SETTINGS, (set, k) => {
            obj.detailSettings[k] = {...set}
            const editpane = _.filter(tktEdit.editPane, r => r.i === k)[0]
            // console.log(editpane)
            obj.editPane.push(editpane)
            obj[`${k}DispFlg`] = {
              ...tktEdit[`${k}DispFlg`],
              original: editpane.isShow?'1':'0',
              value: editpane.isShow?'1':'0',
              text: editpane.isShow?'1':'0',
            }
            _.forEach(set.items, (r, i) => {
              obj[r.id] = {
                ...tktEdit[r.id],
                ...obj[r.id],
                visible: r.selected,
              }
              obj.detailSettings[k].items[i] = {
                ...obj.detailSettings[k].items[i],
                selected: r.selected,
              }
            })
          })

        } else {
          _.forEach(DETAIL_SETTINGS, (set, k) => {
            _.forEach(res.payload.displayItemSettingDto.bizTktDetailItemDto, (data, key) => {
              if (key === set.dto) {
                obj.detailSettings[k] = {...set, selected: data}
                const editpane = _.filter(tktEdit.editPane, r => r.i === k)[0]
                obj.editPane.push({
                  ...editpane,
                  isShow: data==='1',
                })
                
                obj[`${k}DispFlg`] = {
                  ...tktEdit[`${k}DispFlg`],
                  original: data,
                  value: data,
                  text: data,
                }
              }
              _.forEach(set.items, (r, i) => {
                if (key === r.idto) {
                  obj[r.id] = {
                    ...tktEdit[r.id],
                    ...obj[r.id],
                    visible: data==='1'
                  }
                  obj.detailSettings[k].items[i] = {
                    ...obj.detailSettings[k].items[i],
                    selected: data==='1',
                  }
                }
              })
            })
          })
        }
        // console.log(obj)
        obj.selDate = res.payload.selDate
        
        // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
        // console.log(obj.editPane)
        setPane(_.filter(obj.editPane, r => r.isShow))
        tktEditAction.setInputTktEdit(obj)
        // console.log('formMode:' + formMode)
        appAction.setInputApp({tktEditFormMode: formMode})
      }

    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
      setTimeout(() => {
        let element = ''
        // let element = document.getElementById('tktGsCash');
        // element && element.focus()
        // setTimeout(() => {
          if (app.tktEditFormMode==='1') {
            element = document.getElementById('tktPaxName');
          } else {
            element = document.getElementById('tktTktnoAl');
          }
          element && element.focus()
        // })
        setInitLoaded(true)
      })
    })

  }

  const createData = (tktTktnoAl, tktTktnoSnFrom, count, copyAl, copySn, copySnTo) => {
    // console.log(tktTktnoAl)
    // console.log(tktTktnoSnFrom)
    // console.log(copySnTo)
    // console.log(count)
    // console.log(copyAl)
    // console.log(copySn)
    const obj = {
      tktDataListDto: [],
      fltDataListDto: [],
      bstTktTaxListDto: [],
      tktRefundTaxListDto: [],
      bstEmdCpnDataListDto: [],
      bstTktAmtItemListDto: [],
    }

    // 新規データ
    for (let data = 1; data <= count; data++) {
      const tktnoSn = _.padStart(tktTktnoSnFrom - 1 + data, 10, '0')
      const copyTktnoSn = _.padStart(copySn - 1 + data, 10, '0')
      let res = _.filter(tktEdit.tktDataListDto, v => v.tktTktnoAl === tktTktnoAl && v.tktTktnoSn === tktnoSn)[0]
      // console.log(res)
      
      let al = ''
      let sn = ''
      
      if (res && copySn && copySn.length === 10 && (tktTktnoAl !== copyAl || tktTktnoSnFrom !== copySn)) {
        res.tktTktno = copyAl + copyTktnoSn
        res.tktTktnoAl = copyAl
        res.tktTktnoSn = copyTktnoSn
        al = copyAl
        sn = copyTktnoSn
      } else {
        al = tktTktnoAl
        sn = tktnoSn
      }

      obj.tktDataListDto.push({
        tktTktno: al + sn,
        tktTktnoAl: al,
        tktTktnoSn: sn,
        tktCpnNo1: false,
        tktCpnNo2: false,
        tktCpnNo3: false,
        tktCpnNo4: false,
        ...res,
      })
      for (let seg = 1; seg <= 4; seg++) {
        let res1 = _.filter(tktEdit.fltDataListDto, v => v.fltTktnoAl === tktTktnoAl && v.fltTktnoSn === tktnoSn && v.fltFltSeg === seg)[0]
        if (res1 && copySn && copySn.length === 10) {
          res1.fltTktno = copyAl + copyTktnoSn
          res1.fltTktnoAl = copyAl
          res1.fltTktnoSn = copyTktnoSn
        }
        // console.log(res1)
        obj.fltDataListDto.push({
          fltFltNo: (data-1) * 4 + seg,
          fltFltSeg: seg,
          fltTktno: al + sn,
          fltTktnoAl: al,
          fltTktnoSn: sn,
          fltCpnNo: '',
          fltSrcType: '0',
          fltFareType: '',
          fltFareCd: ' ',
          fltIndByteTkt: ' ',
          fltStopoverCode: ' ',
          fltReservationStatus: '',
          ...res1,
        })
        let res2 = _.filter(tktEdit.bstEmdCpnDataListDto, v => v.emdTktnoAl === tktTktnoAl && v.emdTktnoSn === tktnoSn && v.emdCpnNo === seg)[0]
        if (res2 && copySn && copySn.length === 10) {
          res2.emdTktno = copyAl + copyTktnoSn
          res2.emdTktnoAl = copyAl
          res2.emdTktnoSn = copyTktnoSn
        }
        obj.bstEmdCpnDataListDto.push({
          emdNo: (data-1) * 4 + seg,
          emdCpnNo: seg,
          emdTktno: al + sn,
          emdTktnoAl: al,
          emdTktnoSn: sn,
          ...res2,
        })
      }
    }
    for (let tax = 1; tax <= 99; tax++) {
      let res1 = _.filter(tktEdit.bstTktTaxListDto, v => v.taxTktnoAl === tktTktnoAl && v.taxTktnoSn === tktTktnoSnFrom && v.taxTaxNo === tax)[0]
      let al = tktTktnoAl
      let sn = tktTktnoSnFrom
      if (copySn && copySn.length === 10) {
        al = copyAl
        sn = copySn
      }
      if (res1) {
        res1.taxTktno = al + sn
        res1.taxTktnoAl = al
        res1.taxTktnoSn = sn
      }

      obj.bstTktTaxListDto.push({
        taxTaxNo: tax,
        taxTktno: al + sn,
        taxTktnoAl: al,
        taxTktnoSn: sn,
        ...res1,
      })
      let res2 = _.filter(tktEdit.tktRefundTaxListDto, v => v.rtxTktnoAl === tktTktnoAl && v.rtxTktnoSn === tktTktnoSnFrom && v.rtxRftaxNo === tax)[0]
      if (res2) {
        res2.rtxTktno = al + sn
        res2.rtxTktnoAl = al
        res2.rtxTktnoSn = sn
      }
      obj.tktRefundTaxListDto.push({
        rtxRftaxNo: tax,
        taxTktno: al + sn,
        taxTktnoAl: al,
        taxTktnoSn: sn,
        ...res2,
      })
    }
    for (let add = 1; add <= 30; add++) {
      let res1 = _.filter(tktEdit.bstTktAmtItemListDto, v => v.amtTktnoAl === tktTktnoAl && v.amtTktnoSn === tktTktnoSnFrom && v.amtNo === add)[0]
      let al = tktTktnoAl
      let sn = tktTktnoSnFrom
      if (copySn && copySn.length === 10) {
        al = copyAl
        sn = copySn
      }
      if (res1) {
        res1.amtTktno = al + sn
        res1.amtTktnoAl = al
        res1.amtTktnoSn = sn
      }
      obj.bstTktAmtItemListDto.push({
        amtNo: add,
        amtTktno: al + sn,
        amtTktnoAl: al,
        amtTktnoSn: sn,
        ...res1,
      })
    }
    if (copySn && copySn.length === 10) {
      obj.copyTktTktnoAl = copyAl
      obj.copyTktTktnoSnFrom = copySn
      obj.copyTktTktnoSnTo = copySnTo
    }
    // console.log(obj)
    tktEditAction.setInputTktEdit(obj)

    // _.forEach(tktDataListDto, (r, i) => {

    // })
    // 
    // obj.tktDataListDto = res.payload.tktDataListDto
    // obj.fltDataListDto = dataList
    // obj.fltDataStListDto = res.payload.fltDataStListDto
    // obj.bstTktTaxListDto = res.payload.bstTktTaxListDto
    // obj.bstEmdCpnDataListDto = res.payload.bstEmdCpnDataListDto
    // obj.tktRefundTaxListDto = res.payload.tktRefundTaxListDto
    // obj.bstTktAmtItemListDto = res.payload.bstTktAmtItemListDto
  }

  React.useEffect(() => {
    // console.log('createData!!!!!!!!!!!!!!!!!!')
    // appAction.setMessage({message: ''})
    if (app.tktEditFormMode !== '2' && tktno !== `${tktEdit.tktTktnoAl.text}${tktEdit.tktTktnoSnFrom.text}`) {
      setAllDisabled(true)
      search()
      return
    }
    // console.log(tktEdit.checkTktno)
    if (app.tktEditFormMode === '2' && tktEdit.checkTktno !== `${tktEdit.tktTktnoAl.text}${tktEdit.tktTktnoSnFrom.text}`) {
      setAllDisabled(true)
      search()
      return
    }

    let entry = {}
    entry.tktTktnoSnTo = entryCheck(tktEdit, convert.getTarget(tktEdit.tktTktnoSnTo, 'text', tktEdit.tktTktnoSnTo.text)).tktTktnoSnTo

    if (entry.tktTktnoSnTo.isInvalid) {
      setAllDisabled(true, entry)
      setTimeout(() => {
        tktEditAction.setInputTktEdit({
          ...entry,
          disabled: false,
        })
      })
    } else {
      let diff = tktEdit.tktTktnoSnTo.text.length > 0?tktEdit.tktTktnoSnTo.text - tktEdit.tktTktnoSnFrom.text.substr(-3):0
      // console.log(diff)
      if (diff < 0 || diff > 5) {
        const message = [{id:MESSAGE.tktChildNumber().id, message:MESSAGE.tktChildNumber().text, type:MESSAGE.tktChildNumber().icon}]
        appAction.setMessage({message: message})
        setAllDisabled(true, entry)
        setTimeout(() => {
          tktEditAction.setInputTktEdit({
            ...entry,
            disabled: false,
          })
        })
      } else {
        // console.log(app.tktEditFormMode)
        setAllDisabled(tktEdit.tktTktnoSnFrom.text.length<10, entry)
        setTimeout(() => {
          if (app.tktEditFormMode !== '2') {
            createData(tktEdit.tktTktnoAl.text, tktEdit.tktTktnoSnFrom.text, diff + 1)
          } else if (_.filter(tktEdit.tktDataListDto, r => {
              return r.tktTktnoAl === tktEdit.tktTktnoAl.text && r.tktTktnoSn === tktEdit.tktTktnoSnFrom.text
            }).length > 0) {
              createData(tktEdit.tktTktnoAl.text, tktEdit.tktTktnoSnFrom.text, diff + 1)
          } else {
            // console.log(tktEdit.tktTktnoSnFrom.text)
            // console.log(tktEdit.tktTktnoSnTo.text)
            const copySnTo = tktEdit.tktTktnoSnTo.text?tktEdit.tktTktnoSnTo.text:tktEdit.tktTktnoSnFrom.text.substr(-3)
            diff = tktEdit.copyTktTktnoSnTo - tktEdit.copyTktTktnoSnFrom.substr(-3)
            createData(tktEdit.copyTktTktnoAl, tktEdit.copyTktTktnoSnFrom, diff + 1, tktEdit.tktTktnoAl.text, tktEdit.tktTktnoSnFrom.text, copySnTo)
          }
        })
      }
    }

  }, [tktEdit.tktTktnoAl.text, tktEdit.tktTktnoSnFrom.text, tktEdit.tktTktnoSnTo.text])

  // al,sn　Enterイベント
  const search = () => {
    let entry = {};
    let hasError = false;
    const tktTktno = `${tktEdit.tktTktnoAl.text}${tktEdit.tktTktnoSnFrom.text}`
    // if (initLoaded && tktno !== tktTktno) {
      // if (tktEdit.tktTktnoAl.text && tktEdit.tktTktnoSnFrom.text) {
        _.forEach(['tktTktnoAl', 'tktTktnoSnFrom'], r => {
          entry[r] = entryCheck(tktEdit, convert.getTarget(tktEdit[r], 'text', tktEdit[r].text))[r]
          hasError = hasError || entry[r].isInvalid
        })
        if (hasError) {
          setTimeout(() => {
            setAllDisabled(true, entry)
          })
        } else {
          const message = []
          tktEditAction.setInputTktEdit(entry)
          // console.log(thisNo)
          // 複製の場合は、存在チェックを行い、存在しなければOK
          if (thisNo.length > 13) {
            // 存在したらエラー
            TicketApi.detailSearch({
              formMode: '1',
              airlineCode: tktTktno.substring(0, 3),
              sn: tktTktno.substring(3, 13),
            })
            .then(res => {
              // console.log(res)
              entry = {
                tktTktnoAl: {
                  ...tktEdit.tktTktnoAl,
                  isInvalid: false,
                  helpertext: '',
                },
                tktTktnoSnFrom: {
                  ...tktEdit.tktTktnoSnFrom,
                  isInvalid: false,
                  helpertext: '',
                },
              }
              if (res.payload.code === '000') {
                // 登録済みチケット：エラー
                // console.log(tktEdit.tktTktnoSnFrom.text.substring(0, 7))
                entry.tktTktnoSnFrom = {
                  ...tktEdit.tktTktnoSnFrom,
                  isInvalid: false,
                  helpertext: '',
                  text: tktEdit.tktTktnoSnFrom.text.substring(0, 7),
                }
                setAllDisabled(true, entry)
                appAction.setMessage({message: [{id: MESSAGE.duplicateTicket().id, message: MESSAGE.duplicateTicket().text, type: MESSAGE.duplicateTicket().icon}]})
              } else if (res.payload.code === '100') {
                // console.log(tktEdit)
                const diff = tktEdit.copyTktTktnoSnTo - tktEdit.copyTktTktnoSnFrom.substr(-3)
                const snTo = _.padStart(_.toNumber(tktEdit.tktTktnoSnFrom.text) + diff, 10, '0')
                entry.tktTktnoSnTo = {
                  ...tktEdit.tktTktnoSnTo,
                  text: snTo.substr(-3),
                  isInvalid: false,
                  helpertext: '',
                }
                tktEditAction.setInputTktEdit({
                  checkTktno: tktEdit.tktTktnoAl.text + tktEdit.tktTktnoSnFrom.text
                })
                setAllDisabled(false, entry)
              } else {
                app.setMessage(res.payload.messageList)
                setAllDisabled(true, entry)
              }
            })
            .catch(() => {})
            .finally(() => {
            })
            return
          } else {
          // if (thisNo.startsWith('_new')) {
            // tktListの更新
            const newTktList = []
            _.forEach(app.tktList, (r, i) => {
              if (r.value === thisNo) {
                newTktList.push({
                  value: tktTktno,
                  name: tktTktno.substring(0, 3) + '-' + tktTktno.substring(3),
                  mode: '1',
                })
              } else if (r.value !== tktTktno) {
                newTktList.push(r)
              }
            })
            // console.log(newTktList)
            if (window.name === '_tktEditList') {
              appAction.setInputApp({tktList: newTktList})
              localStorage.setItem('tktList', JSON.stringify(newTktList))
            } else {
              appAction.setInputApp({tktList: newTktList})
              localStorage.setItem('tktNewList', JSON.stringify(newTktList))
            }
          }
          // console.log('history3')
          if (window.name !== '_tktEditList') {
            window.name = '_tktEdit_' + tktTktno
          }
          setThisNo(tktTktno)
          history.push(`${TITLE.ticketEdit.mainLink}/${tktTktno}`, {nonRouteLeavingGuard:true, init: true, formMode: app.tktEditFormMode==='3'?'3':null})
        }
      // } else {
      //   setAllDisabled(true)
      // }
    // } else {
    //   // setAllDisabled(false)
    // }
  }

  const setAllDisabled = (bool, data) => {
    // console.log(bool)
    let obj = {}
    // _.forEach(tktEdit.tktDataListDto[0], (r, i) => {
    const enabledItems = ['tktTktnoAl', 'tktTktnoSnFrom']
    const disabledItems = [
      'tktStatus',
      'tktRefundVoidDate',
      
      'tktCommP',
      'tktCommAmtCost',
      'tktCommTaxin',
      'tktSrcPrice',
      'tktSalesAmt',
      'tktNsb',
      'tktBspP',
      'tktBspTaxin',
      'tktItno',

      'tktTotalAmountNfa',
      'tktBalancePayable',
      'refundCommAmount',
      'refundCommTax',

      'tktFareCalMode',
      'tktFareCalPricingInd',

      'tktTkiTokuiRate',
      'tktTkiKg',
      'tktTkiShz',
      'tktTkiSeikyuKg',
      'tktTkiJstsr',

      'tktRcvDate',
      'tktRstDate',

      'tktReasonIssuanceDesc',
    ]
    _.forEach(tktEdit, (o, id) => {
      if (o && !_.isUndefined(o.value) && !id.endsWith('DispFlg')) {
        const isEnabled = (_.indexOf(enabledItems, id) > -1)
        const isDisabled = _.indexOf(disabledItems, id) > -1
        // console.log(id + ':' + isEnabled + ':' +isDisabled)
        const tmp = data && data[id]
        obj[id] = {
          ...o,
          ...tmp,
          disabled: app.tktEditFormMode==='3'?true:isEnabled?false:isDisabled?true:bool,
          // disabled: false
        }
        // console.log(id)
        // console.log(obj[id])
      }
    })
    // })
    // console.log(obj)
    setBtnDisabled(bool)
    tktEditAction.setInputTktEdit(obj)
  }

  const [pane, setPane] = React.useState([]);
  const [layouts, setLayouts] = React.useState({lg: pane});

  React.useEffect(() => {
    setLayouts({lg: pane})
  }, [pane])

  const genPaneFlag = () => {
    return _.map(tktEdit.editPane, (r, i) => {
      return (
        <Common.Check
          key={r.i}
          attrs={{...tktEdit[`${r.i}DispFlg`]}}
          onChange={(e) => {
            // tktEditAction.changeInputTktEdit(e)
            // _.forEach(tktEdit.editPane, (r, i) => {
            //   if (e.id.startsWith(r.i)) r.isShow = e[e.id].text==='1'?true:false
            // })
            changeDisp(e.id, e[e.id].text)
          }}
        />
      )
    })
  }
  const genEditPane = () => {
    return _.map(_.filter(pane, r => r.isShow), (r, i) => {
      return (
        <div key={r.i}>
          <Card
            root='p100 m0'
            size='lg'
            hideClose={false}
            condition={r}
            onClose={(e) => {
              // _.forEach(tktEdit.editPane, (r, i) => {
              //   if (r.i === e[0].i) r.isShow = false
              // })
              changeDisp(e[0].i + 'DispFlg', '0')
            }}
            header={r.name}
            subheader={null}
            body={
              <React.Fragment>
                <TicketEditPane pane={r.i} disabled={true} gridEditing={gridEditing} setGridEditing={setGridEditing} />
              </React.Fragment>
            }
          />
        </div>
      )
    })
  }

  const changeDisp = (id, value) => {
    // console.log(id + ':' + value)
    // console.log(id)
    // console.log(value)
    const target = {id: id}
    target[id] = {
      ...tktEdit[id],
      text: value,
    }
    tktEditAction.changeInputTktEdit(target)

    const editPane = []
    _.forEach(tktEdit.editPane, (r, i) => {
      if (id.startsWith(r.i)) {
        editPane.push({
          ...r,
          isShow: value==='1',
        })
      } else {
        editPane.push(r)
      }
    })
    // console.log(editPane)
    tktEditAction.setInputTktEdit({editPane: editPane})
    setPane(_.filter(editPane, r => r.isShow))
    // setLayouts({lg: _.filter(editPane, r => r.isShow)})
  }

  const execEdit = () => {
    
    appAction.setMessage('')

    if (app.tktEditFormMode === '3') {
      // tktListの更新
      const newTktList = []
      const tktTktnoAl = tktEdit.tktTktnoAl.text
      const tktTktnoSn = tktEdit.tktTktnoSnFrom.text
      _.forEach(app.tktList, (r, i) => {
        if (r.value === thisNo) {
          newTktList.push({
            value: tktTktnoAl + tktTktnoSn,
            name: tktTktnoAl + '-' + tktTktnoSn,
            mode: '1',
          })
        } else {
          newTktList.push(r)
        }
      })
      // console.log(newTktList)
      if (window.name === '_tktEditList') {
        appAction.setInputApp({tktList: newTktList})
        localStorage.setItem('tktList', JSON.stringify(newTktList))
      } else {
        appAction.setInputApp({tktList: newTktList})
        localStorage.setItem('tktNewList', JSON.stringify(newTktList))
      }

      const tktTktno = `${tktEdit.tktTktnoAl.text}${tktEdit.tktTktnoSnFrom.text}`
      history.push(`${TITLE.ticketEdit.mainLink}/${tktTktno}`, {nonRouteLeavingGuard:true, init: true})
    } else {
      tktEdit.tktCardNum.text = convert.cardNoMasking(tktEdit.tktCardNum.text)
      tktEdit.tktFormPay.text = convert.cardNoMasking(tktEdit.tktFormPay.text, true)
      let entry ={}
      let hasError = false
      let errorMessages = ['入力内容に誤りがあります。\n']
      _.forEach(tktEdit, (v, k) => {
        if (v && !_.isUndefined(v.value)) {
          entry = entryCheck(tktEdit, convert.getTarget(tktEdit[k], 'text', tktEdit[k].text))[k]
          hasError = hasError || entry.isInvalid
          if (entry.isInvalid) errorMessages.push(`【${DETAIL_SETTINGS[entry.category].label}】${entry.label.indexOf('none')> 0 ? entry.name : entry.label}：${entry.helpertext}\n`)
        }
      })
      tktEditAction.setInputTktEdit(entry)
      if (hasError) {
        appAction.setMessage({message: [{
          id: MESSAGE.tktEditValidate(errorMessages).id,
          message: MESSAGE.tktEditValidate(errorMessages).text,
          type: MESSAGE.tktEditValidate(errorMessages).icon,
        }]})
        return
      }

      const err = _.filter(tktEdit.fltDataListDto, (r) => r.hasError)
      if (err.length > 0) {
        appAction.setMessage({message: [{
          id: MESSAGE.hasError().id,
          message: MESSAGE.hasError().text,
          type: MESSAGE.hasError().icon,
        }]})
        return
      }
      // console.log(tktEdit.tktIsuDate.text)
      // console.log(tktEdit.tktVoidDate.text)
      if (tktEdit.tktVoidDate.text && (tktEdit.tktIsuDate.text.getTime() > tktEdit.tktVoidDate.text.getTime())) {
        appAction.setMessage({message: [{
          id: MESSAGE.isuVoidError().id,
          message: MESSAGE.isuVoidError().text,
          type: MESSAGE.isuVoidError().icon,
        }]})
        return
      }
      
      let data = {}
      _.forEach(tktEdit, (v, k) => {
        if (v && !_.isUndefined(v.value)) {
          data[k] = v.text
          if (!_.isUndefined(v.min) && !v.text) {
            data[k] = v.min
          }
          if (!_.isUndefined(v.suffix) && v.suffix === '%') {
            data[k] = !v.text?'00000':_.padStart(v.text * 100, 5, '0')
          }
          if (k === 'tktVoidId' || k === 'tktOvoId') {
            data[k] = v.text === '1'?'V':' '
          }
          if (k === 'tktRefId' || k === 'tktAtRefid') {
            data[k] = v.text === '1'?'R':' '
          }
          if (k === 'tktReissueId') {
            data[k] = v.text === '1'?'E':' '
          }
          if (k === 'tktNdcFlg') {
            data[k] = v.text === '1'?'D':' '
          }
          if (k === 'tktDetailIssuedFlg' || k === 'tktCustomerOutputFlg' || k === 'tktAgentCouponOutputFlg'
          || k === 'tktCccfOutputFlg' || k === 'tktRefundNoticeOutputFlg') {
            data[k] = v.text === '1'?'1':'0'
          }
          if (k === 'tktCaExdate') {
            data[k] = v.text.replace('/', '')
          }
          if (v.type && v.type.startsWith('datetime')) {
            data[k] = convert.toDateTimeString(v.text, "yyyy-MM-dd'T'HH:mm:ss")
          } else if (v.type && v.type.startsWith('date')) {
            data[k] = convert.toDateString(v.text, 'yyyy-MM-dd')
          }
          if (k === 'tktFareType') {
            const val = dataMapper(FARE_CD, 'name', 'value', v.text)
            data.tktFareCd = val?val:' '
          }
        }
      })
      
      // console.log(tktEdit.tktBookingPcc.text)
      if (tktEdit.tktBookingPcc.dirty) {
        if (tktEdit.tktBookingPcc.text.length> 6) {
          data.tktBookingPcc = ""
          data.tktReserveOfficeid = tktEdit.tktBookingPcc.text
        } else {
          data.tktBookingPcc = tktEdit.tktBookingPcc.text
          data.tktReserveOfficeid = ""
        }
      } else {
        data.tktBookingPcc = tktEdit.tktDataListDto[0].tktBookingPcc
        data.tktReserveOfficeid = tktEdit.tktDataListDto[0].tktReserveOfficeid
      }
      if (tktEdit.tktTktPcc.dirty) {
        if (tktEdit.tktTktPcc.text.length> 6) {
          data.tktTktPcc = ""
          data.tktTicketingOfficeid = tktEdit.tktTktPcc.text
        } else {
          data.tktTktPcc = tktEdit.tktTktPcc.text
          data.tktTicketingOfficeid = ""
        }
      } else {
        data.tktTktPcc = tktEdit.tktDataListDto[0].tktTktPcc
        data.tktTicketingOfficeid = tktEdit.tktDataListDto[0].tktTicketingOfficeid
      }

      data.tktRepInd = tktEdit.tktDataListDto[0].tktRepInd
      data.tktBuyingRate = tktEdit.tktDataListDto[0].tktBuyingRate
      data.tktPkTax = tktEdit.tktDataListDto[0].tktPkTax
      data.tktBankersRate = tktEdit.tktDataListDto[0].tktBankersRate
      data.tktEmdType = tktEdit.tktDataListDto[0].tktEmdType

      // COUPON
      const tktDataListDto = []
      _.forEach(tktEdit.tktDataListDto, (r, i) => {
        tktDataListDto.push({
          tktno: r.tktTktnoAl + r.tktTktnoSn,
          tktCpnNo1: r.tktCpnNo1?'1':'0',
          tktCpnNo2: r.tktCpnNo2?'1':'0',
          tktCpnNo3: r.tktCpnNo3?'1':'0',
          tktCpnNo4: r.tktCpnNo4?'1':'0',
        })
      })
      // フライト
      const fltDataListDto = []
      _.forEach(tktEdit.fltDataListDto, (r, i) => {
        if (r.fltCpnNo || r.fltAirlineCd || r.fltFltNum || r.fltClass || r.fltFrom
          || r.fltTo || r.fltFareBasis || r.fltBoardDate || r.fltBoardTime || r.fltArrDate
          || r.fltArrTime || r.fltNvb || r.fltNva || r.fltBagAlw || r.fltKb1) {
          fltDataListDto.push({
            ...r,
            fltBoardDate: convert.toDateString(r.fltBoardDate, 'yyyy-MM-dd'),
            fltBoardTime: convert.toDateTimeString(r.fltBoardTime, 'HHmm'),
            fltArrDate: convert.toDateString(r.fltArrDate, 'yyyy-MM-dd'),
            fltArrTime: convert.toDateTimeString(r.fltArrTime, 'HHmm'),
            fltNvb: convert.toDateString(r.fltNvb, 'yyyyMMdd'),
            fltNva: convert.toDateString(r.fltNva, 'yyyyMMdd'),
            fltKb1: data.tktKbFlag === '1'?r.fltKb1:0,
            fltStopoverCode: r.fltStopoverCode==='X'?'X':' ',
            fltIata: data.tktIata,
            fltPaxType: data.tktPaxType,
            fltFareType: data.tktFareType,
            fltFareCd: data.tktFareCd,
          })
        }
      })
      // TAX
      const bstTktTaxListDto = []
      _.forEach(tktEdit.bstTktTaxListDto, (r, i) => {
        if (r.taxTaxAmt || r.taxTaxInd) {
          bstTktTaxListDto.push(r)
        }
      })
      // Refund TAX
      const tktRefundTaxListDto = []
      _.forEach(tktEdit.tktRefundTaxListDto, (r, i) => {
        if (r.rtxRftaxAmt || r.rtxRftaxInd) {
          tktRefundTaxListDto.push(r)
        }
      })
      // EMD Coupon
      const bstEmdCpnDataListDto = []
      _.forEach(tktEdit.bstEmdCpnDataListDto, (r, i) => {
        if (r.emdReasonIssuanceSubCode || r.emdCpnFieldFeeAmt || r.emdCpnValueAmt || (r.emdConsumedIssuaranceInd && r.emdConsumedIssuaranceInd !== ' ') || r.emdCpnRmks) {
          bstEmdCpnDataListDto.push({
            ...r,
            emdCpnFieldFeeAmt: r.emdCpnFieldFeeAmt?r.emdCpnFieldFeeAmt:'',
            emdCpnValueAmt: r.emdCpnValueAmt?r.emdCpnValueAmt:'',
          })
        }
      })
      // Add
      const bstTktAmtItemListDto = []
      _.forEach(tktEdit.bstTktAmtItemListDto, (r, i) => {
        if (r.amtItem || r.amtItemAmt) {
          bstTktAmtItemListDto.push(r)
        }
      })

      appAction.showProcessing({isProcessing: true})
      TicketApi.detailRegister({
        selDate: tktEdit.selDate,
        formMode: app.tktEditFormMode,
        tktChildTktno : tktEdit.tktTktnoSnTo.text?tktEdit.tktTktnoSnTo.text:tktEdit.tktTktnoSnFrom.text.substr(-3),
        tktRegistDataDto: {
          ...data,
          tktTktnoSn: tktEdit.tktTktnoSnFrom.text,
          tktCpnNo1: tktEdit.tktDataListDto[0].tktCpnNo1?'1':'0',
          tktCpnNo2: tktEdit.tktDataListDto[0].tktCpnNo2?'1':'0',
          tktCpnNo3: tktEdit.tktDataListDto[0].tktCpnNo3?'1':'0',
          tktCpnNo4: tktEdit.tktDataListDto[0].tktCpnNo4?'1':'0',
        },
        tktCpnNoListDto: tktDataListDto,
        fltDataListDto: fltDataListDto.length > 0?fltDataListDto:null,
        tktTaxListDto: bstTktTaxListDto.length > 0?bstTktTaxListDto:null,
        bstTktRefundTaxListDto: tktRefundTaxListDto.length > 0?tktRefundTaxListDto:null,
        bstEmdCpnDataListDto: bstEmdCpnDataListDto.length > 0?bstEmdCpnDataListDto:null,
        bstTktAmtItemListDto: bstTktAmtItemListDto.length > 0?bstTktAmtItemListDto:null,
      })
      .then(res => {
        // console.log(res.payload)
        if (res.payload.code === '000') {
          if (app.tktEditFormMode === '2') {
            // tktListの更新
            const newTktList = []
            const tktTktnoAl = tktEdit.tktTktnoAl.text
            const tktTktnoSn = tktEdit.tktTktnoSnFrom.text
            _.forEach(app.tktList, (r, i) => {
              if (r.value === thisNo) {
                newTktList.push({
                  value: tktTktnoAl + tktTktnoSn,
                  name: tktTktnoAl + '-' + tktTktnoSn,
                  mode: '1',
                })
              } else {
                newTktList.push(r)
              }
            })
            // console.log(newTktList)
            if (window.name === '_tktEditList') {
              appAction.setInputApp({tktList: newTktList})
              localStorage.setItem('tktList', JSON.stringify(newTktList))
            } else {
              appAction.setInputApp({tktList: newTktList})
              localStorage.setItem('tktNewList', JSON.stringify(newTktList))
            }
          }
        
          const tktTktno = `${tktEdit.tktTktnoAl.text}${tktEdit.tktTktnoSnFrom.text}`
          // console.log('history4')
          
          appAction.setInputApp({resultMsg: res.payload.messageList})
          history.push(`${TITLE.ticketEdit.mainLink}/${tktTktno}`, {nonRouteLeavingGuard:true, init: true})
        } else {
          appAction.setMessage({message: res.payload.messageList})
        }
      })
      .catch(() =>{})
      .finally(() => {
        appAction.showProcessing({isProcessing: false})
        // setTimeout(() => {
        //   let element = document.getElementById('tktGsCash');
        //   element && element.focus()
        //   setTimeout(() => {
        //     element = document.getElementById('tktTktnoAl');
        //     element && element.focus()
        //   })
        //   setInitLoaded(true)
        // })
      })
    }
  }

  const execDelete = () => {

    alert().fire({
      icon: MESSAGE.confirmTktDelete().icon,
      title: MESSAGE.confirmTktDelete('選択中の発券データ').text
    })
    .then((result) => {
      if (result.isConfirmed) {
        appAction.showProcessing({isProcessing: true})
        const dto = {
          airlineCode: tktEdit.tktTktnoAl.text,
          sn: tktEdit.tktTktnoSnFrom.text,
          selDate: tktEdit.selDate,
        }
        TicketApi.detailDelete(dto)
        .then(res => {
          // console.log(res.payload)
          if (res.payload.code === '000') {
            const newTktList = []
            _.forEach(app.tktList, (r, i) => {
              if (r.value !== thisNo) {
                newTktList.push(r)
              }
            })
            const nonBlankList = _.filter(newTktList, r => r.name !=='')
            
            if (window.name === '_tktEditList' && nonBlankList.length !== 0) {
              setNonDirty(true)
              setThisNo(nonBlankList[0].value)
            }

            if (window.name === '_tktEditList') {
              appAction.setInputApp({tktList: newTktList})
              localStorage.setItem('tktList', JSON.stringify(newTktList))
            } else {
              appAction.setInputApp({tktList: newTktList})
              localStorage.setItem('tktNewList', JSON.stringify(newTktList))
            }
            if (window.name === '_tktEditList') {
              if (nonBlankList.length === 0) {
                appAction.setInputApp({
                  isHeaderShow: false,
                  isFooterShow: false,
                  isInitData: false,
                })
                window.close()
              }
            } else {
              appAction.setInputApp({
                isHeaderShow: false,
                isFooterShow: false,
                isInitData: false,
              })
              window.close()
            }
            // history.push(`${TITLE.ticketEdit.mainLink}/_new`, {message: res.payload.messageList, nonRouteLeavingGuard:true, init: true})
          } else {
            appAction.setMessage({message: res.payload.messageList})
          }
        })
        .catch(() =>{})
        .finally(() => {
          appAction.showProcessing({isProcessing: false})
        })
      }
    })
  }

  // React.useEffect(() => {
  //   if (app.tktEditFormMode === '3') setAllDisabled(true)
  // }, [app.tktEditFormMode])

  // COMM額、COMM消費税、COMM額（税込）、NSB額、TKT原価、BSP金額、SALES AMOUNT取得
  React.useEffect(() => {
    if (app.isProcessing) return
    const dto ={
      tktGsCash      : tktEdit.tktGsCash.text, 
      tktGsCredit    : tktEdit.tktGsCredit.text, 
      tktTaxCash     : tktEdit.tktTaxCash.text, 
      tktTaxCredit   : tktEdit.tktTaxCredit.text, 
      tktCommRate    : tktEdit.tktCommRate.text?tktEdit.tktCommRate.text*100:0, 
      tkt2aNet       : tktEdit.tkt2aNet.text, 
      tktNrid        : tktEdit.tktNrid.text, 
      tktIsuDate     : tktEdit.tktIsuDate.text?convert.toDateString(tktEdit.tktIsuDate.text):null, 
      tktTktnoAl     : tktEdit.tktTktnoAl.text, 
      tktTktnoSn     : tktEdit.tktTktnoSnFrom.text, 
      tktParentTktno : '',//tktEdit.tktParentTktno.text, 
      tktVc          : tktEdit.tktVc.text, 
      tktCar         : tktEdit.tktCar.text, 
      tktItno        : tktEdit.tktItno.text, 
      tktTourCd      : tktEdit.tktTourCd.text, 
      tktReissueId   : tktEdit.tktReissueId.text==='1'?'E':' ',
      tktRefId       : tktEdit.tktRefId.text==='1'?'R':' ',
      tktOrgIsu      : tktEdit.tktOrgIsu.text,
      tktCreditCom   : tktEdit.tktCreditCom.text, 
      tktCardNum     : tktEdit.tktCardNum.text, 
    }
    TicketApi.calcCommonAmount(dto)
    .then(res => {
      if (res.payload.code === '000') {
        const obj = {
          tktCommP: {
            ...tktEdit.tktCommP,
            text: res.payload.commAmount,
          },
          tktCommAmtCost: {
            ...tktEdit.tktCommAmtCost,
            text: res.payload.commTax,
          },
          tktCommTaxin: {
            ...tktEdit.tktCommTaxin,
            text: res.payload.commAmountTaxInclude,
          },
          tktNsb: {
            ...tktEdit.tktNsb,
            text: res.payload.nsbAmount,
          },
          tktSrcPrice: {
            ...tktEdit.tktSrcPrice,
            text: res.payload.tktCost,
          },
          tktBspP: {
            ...tktEdit.tktBspP,
            text: res.payload.bspAmount,
          },
          tktBspTaxin: {
            ...tktEdit.tktBspTaxin,
            text: res.payload.bspAmountTaxInclude,
          },
          tktSalesAmt: {
            ...tktEdit.tktSalesAmt,
            text: res.payload.salesAmount,
          }
        }
        tktEditAction.setInputTktEdit(obj)
      }
    })
    .catch(err => {})
    .finally(() => {})
  // ロード時に計算しないのであれば、app.processingは外す
  }, [app.isProcessing, tktEdit.tktGsCash.text, tktEdit.tktGsCredit.text, tktEdit.tktTaxCash.text, tktEdit.tktTaxCredit.text,
    tktEdit.tktCommRate.text, tktEdit.tkt2aNet.text, tktEdit.tktNrid.text, tktEdit.tktIsuDate.text,
    tktEdit.tktTktnoAl.text, tktEdit.tktTktnoSnFrom.text, //tktEdit.tktParentTktno.text,
    tktEdit.tktVc.text, tktEdit.tktCar.text, tktEdit.tktItno.text, tktEdit.tktTourCd.text, tktEdit.tktReissueId.text,
    tktEdit.tktRefId.text, tktEdit.tktOrgIsu.text, tktEdit.tktCreditCom.text, tktEdit.tktCardNum.text])
  // 得意先請求金額、得意先金額、得意先消費税、得意先%、自社手数料取得
  React.useEffect(() => {
    if (app.isProcessing) return
    const dto ={
      tktTokuiClm : tktEdit.tktTokuiClm.text,
      tkt7lComCd  : tktEdit.tkt7lComCd.text,
      tktFareType : tktEdit.tktFareType.text,
      tktGsCash   : tktEdit.tktGsCash.text,
      tktGsCredit : tktEdit.tktGsCredit.text,
      tktTaxCash  : tktEdit.tktTaxCash.text,
      tktTaxCredit: tktEdit.tktTaxCredit.text,
      tktCommRate : tktEdit.tktCommRate.text?tktEdit.tktCommRate.text*100:0, 
      tktIsuDate  : tktEdit.tktIsuDate.text?convert.toDateString(tktEdit.tktIsuDate.text):null,
    }
    TicketApi.calcTokui(dto)
    .then(res => {
      if (res.payload.code === '000') {
        const obj = {
          tktTkiSeikyuKg: {
            ...tktEdit.tktTkiSeikyuKg,
            text: res.payload.tokuiSeiKyuKinGaku,
          },
          tktTkiKg: {
            ...tktEdit.tktTkiKg,
            text: res.payload.tokuiKinGaKu,
          },
          tktTkiShz: {
            ...tktEdit.tktTkiShz,
            text: res.payload.tokuiSyouHiZei,
          },
          tktTkiTokuiRate: {
            ...tktEdit.tktTkiTokuiRate,
            text: res.payload.tokuiPer,
          },
          tktTkiJstsr: {
            ...tktEdit.tktTkiJstsr,
            text: res.payload.jiSyaTeiSuSyou,
          },
        }
        tktEditAction.setInputTktEdit(obj)
      }
    })
    .catch(err => {})
    .finally(() => {})
  // ロード時に計算しないのであれば、app.processingは外す
  }, [app.isProcessing, tktEdit.tktTokuiClm.text, tktEdit.tkt7lComCd.text, tktEdit.tktFareType.text,
    tktEdit.tktGsCash.text, tktEdit.tktGsCredit.text, tktEdit.tktTaxCash.text,
    tktEdit.tktTaxCredit.text, tktEdit.tktCommRate.text, tktEdit.tktIsuDate.text])

  // TOTAL AMOUNT(NFA)取得
  React.useEffect(() => {
    if (app.isProcessing) return
    const dto ={
      tktTotalAmt  : tktEdit.tktTotalAmt.text, 
      tktNetFare   : tktEdit.tktNetFare.text, 
      tktGrsFareRef: tktEdit.tktGrsFareRef.text, 
      tktCommRate  : tktEdit.tktCommRate.text?tktEdit.tktCommRate.text*100:0, 
      tktIsuDate   : tktEdit.tktIsuDate.text, 

    }
    TicketApi.calcTotalAmountNFA(dto)
    .then(res => {
      if (res.payload.code === '000') {
        const obj = {
          tktTotalAmountNfa: {
            ...tktEdit.tktTotalAmountNfa,
            text: res.payload.totalAmountNfa,
          },
          tktBalancePayable: {
            ...tktEdit.tktBalancePayable,
            text: res.payload.balancePayable,
          },
          refundCommAmount: {
            ...tktEdit.refundCommAmount,
            text: res.payload.refundCommAmount,
          },
          refundCommTax: {
            ...tktEdit.refundCommTax,
            text: res.payload.refundCommTax,
          },
        }
        tktEditAction.setInputTktEdit(obj)
      }
    })
    .catch(err => {})
    .finally(() => {})
  // ロード時に計算しないのであれば、app.processingは外す
  }, [app.isProcessing, tktEdit.tktTotalAmt.text, tktEdit.tktNetFare.text, tktEdit.tktGrsFareRef.text, tktEdit.tktCommRate.text, tktEdit.tktIsuDate.text])

  const execNew = () =>{
    TicketApi.detailAuth()
    .then(res => {
      if (res.payload === 'auth') {
        const thisTime = _.toString((new Date()).getTime()).substring(4)
        let tktNewList = localStorage.getItem('tktNewList')?JSON.parse(localStorage.getItem('tktNewList')):[{name:'', value:'', mode: ''}]
        tktNewList.push({
          name: '未登録',
          value: '_new' + thisTime,
          mode: '0',
        })
        sessionStorage.setItem('authCode', login.authCode)
        sessionStorage.setItem('loginCd', login.loginCd)
        sessionStorage.setItem('mbrCd', login.mbrCd)
        sessionStorage.setItem('office', login.office)
        sessionStorage.setItem('mntOffice', login.mntOffice)
        localStorage.setItem('tktNewList', JSON.stringify(tktNewList))
        window.open(`${TITLE.ticketEdit.mainLink}/_new${thisTime}`, '_tktEdit_new' + thisTime)
      }
    })
    .catch(() =>{})
    .finally(() => {})
  }

  const execCopy = () =>{
    TicketApi.detailAuth()
    .then(res => {
      if (res.payload === 'auth') {
        const thisTime = _.toString((new Date()).getTime()).substring(4)
        const alno = tktEdit.tktTktnoAl.text
        const snno = tktEdit.tktTktnoSnFrom.text
        let tktNewList = localStorage.getItem('tktNewList')?JSON.parse(localStorage.getItem('tktNewList')):[{name:'', value:'', mode: ''}]
        tktNewList.push({
          name: '未登録',
          value: alno + snno + thisTime,
          mode: '2',
        })
        sessionStorage.setItem('authCode', login.authCode)
        sessionStorage.setItem('loginCd', login.loginCd)
        sessionStorage.setItem('mbrCd', login.mbrCd)
        sessionStorage.setItem('office', login.office)
        sessionStorage.setItem('mntOffice', login.mntOffice)
        localStorage.setItem('tktNewList', JSON.stringify(tktNewList))
        window.open(`${TITLE.ticketEdit.mainLink}/${alno + snno + thisTime}`, '_tktEdit_copy' + thisTime)
      }
    })
    .catch(() =>{})
    .finally(() => {})
  }

  const execProcMenu = (id) => {
    switch (id) {
      case 'menu4':
        history.push(TITLE.report.link, {caller: 'tktEdit', al:tktEdit.tktTktnoAl.text, sn:tktEdit.tktTktnoSnFrom.text})
        break;
    }
  }

  return (
    <React.Fragment>
      <Container fluid className='main full'>
        <Title title={`${TITLE.ticketEdit.name} (${_.filter(FORM_MODE, r => r.value===(app.tktEditFormMode?app.tktEditFormMode:'0'))[0].name})`} icon={TITLE.ticketEdit.icon}/>
        <div className={`tktListSellector`}>
          {(app.tktEditFormMode === '1' || app.tktEditFormMode === '3') &&
          <label>{tktEdit.tktRstDate.text?'最終更新：'+convert.toDateTimeString(tktEdit.tktRstDate.text, "yyyy/MM/dd HH:mm"):''}　{tktEdit.tktRstchrCd.text?tktEdit.tktRstchrCd.text:''}</label>
          }
          <Common.Select
            attrs={{
              id: '_tktList',
              label: '_none',
              text: thisNo,
              dirty: '',
              disabled: window.name !== '_tktEditList'
            }}
            group={{
              className: 'width-16 ml-1 mr-0',
            }}
            // className='abcd'
            minLength={1}
            isEditable={false}
            isRequired={true}
            list={app.tktList}
            onChange={(e) => {
              // console.log(e)
              const tktTktno = e._tktList.text
              const selectedTktItem = _.find(app.tktList, r => r.value === tktTktno)
              // console.log(tktno)
              // console.log(tktTktno)
              // console.log(app.tktList)
              // console.log(selectedTktItem)
              if (selectedTktItem && selectedTktItem.value) {
                // console.log('formMode:' + selectedTktItem.mode)
                // sessionStorage.setItem('tktEditFormMode', selectedTktItem.mode)
                appAction.setInputApp({tktEditFormMode: selectedTktItem.mode})
                // console.log('setThisNo:' + tktTktno)
                setThisNo(tktTktno)
                setSelectedTkt(selectedTktItem)
                // console.log('history6')
                // history.push(`${TITLE.ticketEdit.mainLink}/${tktTktno}`, {init: true})
              }
            }}
          />
        </div>
        <SettingPopup 
          popup={popup}
          setPopup={setPopup}
          popupData={popupData}
          setPopupData={setPopupData}
          header={tktEdit.setting.label}
        />
        <SplitPane split="horizontal" className={`split-pane-top ${window.name.startsWith('_tktEdit')?'full':''}`}>
          <Pane initialSize='120px' minSize='120px' maxSize='120px' className='tkt-header'>
            <TicketEditHeader />
          </Pane>
          <Pane initialSize='22px' minSize='22px' maxSize='22px' className='d-flex-inline'>
            <div className='mx-2 d-inline'><Form.Label>表示パネル：</Form.Label></div>
            {genPaneFlag()}
          </Pane>
          <Pane initialSize='calc(100% -210px)' minSize='calc(100% - 210px)' maxSize='calc(100% - 210px)' className='d-flex-inline over-flow-y'>
            <ResponsiveGridLayout 
              key="main"
              className="layout"
              layouts={layouts}
              breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
              cols={{ lg: 2, md: 1, sm: 1, xs: 1, xxs: 1 }}
              rowHeight={8}
              containerPadding={[0, 0]}
              draggableHandle='.toast-header'
              // onLayoutChange={(l, ls) => {console.log(l);console.log(ls)}}
              margin={[3, 10]}
            >
              {genEditPane()}
            </ResponsiveGridLayout>
          </Pane>
          <Card
            root='button'
            size='lg'
            hideClose
            header={null}
            subheader={null}
            body={
              <div className='card-buttom-button'>
                <Button
                  {...tktEdit.search}
                  className='d-none'
                  onClick={() => search()}
                />
                <Button
                  {...tktEdit.setting}
                  className='button-gray mr-5 width-max-content'
                  disabled={app.isProcessing || btnDisabled}
                  onClick={(e) => {
                    setPopupData('open')
                    popup.show(true, (sender) => {
                      if (sender.dialogResult && _.isObject(sender.dialogResult)) {
                        // console.log(sender.dialogResult)
                        setPane(_.filter(sender.dialogResult.editPane, r => r.isShow))
                      } else {
                        // console.log('cancel')
                        // setLayouts({lg: tktEdit.editPane})
                        // setLayouts({lg: _.filter(tktEdit.editPane, r => r.isShow)})
                      }
                      setPopupData('close')
                    })
                  }}
                >
                  {tktEdit.setting.label}
                </Button>
                <Dropdown className='mr-auto' drop='up'>
                  <Dropdown.Toggle
                    {...tktEdit.menu}
                    className='button-gray width-max-content'
                    disabled={app.isProcessing || btnDisabled || tktEdit.editing}
                  >
                    {tktEdit.menu.label}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='position-fixed'>
                    {tktEdit.menu.submenu.map((r, i) => 
                      <Dropdown.Item as="button"
                        {...r}
                        disabled={login.auth070 !== '1'}
                        onClick={() => execProcMenu(r.id)}
                        key={i}
                      >
                        {r.label}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  {...tktEdit.new}
                  className='button-gray ml-auto mr-3 width-max-content'
                  disabled={app.isProcessing || btnDisabled || (app.tktEditFormMode !== '1' &&  app.tktEditFormMode !== '3') || tktEdit.editing}
                  onClick={() => execNew()}
                >
                  {tktEdit.new.label}
                </Button>
                <Button
                  {...tktEdit.copy}
                  className='button-gray mr-3 width-max-content'
                  disabled={app.isProcessing || btnDisabled || (app.tktEditFormMode !== '1' &&  app.tktEditFormMode !== '3') || tktEdit.editing}
                  onClick={() => execCopy()}
                >
                  {tktEdit.copy.label}
                </Button>
                <Button
                  {...tktEdit.del}
                  className='button-gray mr-auto width-max-content'
                  disabled={app.isProcessing || btnDisabled || (app.tktEditFormMode !== '1' &&  app.tktEditFormMode !== '3') || tktEdit.editing}
                  onClick={() => execDelete()}
                >
                  {tktEdit.del.label}
                </Button>
                <Button
                  {...tktEdit.edit}
                  className='button-blue ml-auto width-max-content'
                  disabled={app.isProcessing || btnDisabled || tktEdit.editing}
                  onClick={() => execEdit()}
                >
                  {tktEdit.edit.label}
                </Button>
              </div>
            }
          />
        </SplitPane>
      </Container>
      <Common.RouteLeavingGuard
        when={true}
        navigate={(path, state) => history.push(path, state)}
        shouldBlockNavigation={(location) => {
          return (!location.state || !(location.state && location.state.nonRouteLeavingGuard))
        }}
        uiState='TktEdit'
      /> 
    </React.Fragment>
  )
}