import _ from 'lodash';
import { SEARCH } from 'constants/search';
import { convert } from "lib";

export const getPattern = (patternMap, name) => {
  // console.log(patternMap)
  // console.log(name)
  const patternConditionJson = patternMap && patternMap[name] && JSON.parse(patternMap[name])
  let patternCondition = []
  _.forEach(patternConditionJson, (r, i) => {
    _.forEach(SEARCH[1].items, (v, k) => {
      _.forEach(v.items, val => {
        if (val.key === r) patternCondition.push({...val, pname:name})
      })
    })
  })
  return patternCondition
}