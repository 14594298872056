import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { format } from '@grapecity/wijmo';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { PivotAction } from 'ducks/Pivot';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { CHART_TYPE } from 'constants/pivot';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';
import { SummaryApi } from 'webapi'
import { Step } from './Step';

export const Step3 = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const pivot = useSelector(state => state.Pivot);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);
  const pivotAction = bindActionCreators(PivotAction, dispatch);

  const [confirmCheck, setConfirmCheck] = React.useState('0')

  return (
    <React.Fragment>
      <Card 
        root='popup'
        size='lg'
        hideClose
        header='データ移行　STEP3'
        headerClass='wj-dialog-header'
        body={
          <React.Fragment>
            <Step step="3" />
            <div className='py-2 px-5'>
              <div className='step-error d-none'></div>
              <div className='step-body mx-auto mt-4'>
                <div className='mb-3'>データ移行が完了しました。</div>
                <div className='mb-0'>移行対象データ件数：{format('{count:n0}', {count: props.readCount})}件</div>
                <div className='mb-4'>移行済データ件数　：{format('{count:n0}', {count: props.resultCount})}件</div>
                <div>URI-Pro（クラウド版）による運用を開始する場合は、以下の『移行完了合意』のチェックボックスをチェックし、</div>
                <div>『次へ』ボタンをクリックしてください。</div>
                <div className='mb-2'>Amadeusからの発券データ受信処理が再開されます。</div>
                <div className='mb-0'>データ移行結果についてお問い合わせがある場合は、以下までご連絡ください。</div>
                <div className='ml-3 mb-0'>お問い合わせ先　Amadeus Delivery担当</div>
                <div className='ml-3 mb-0'>メールアドレス <Link to='#' onClick={(e) => {
                  appAction.setInputApp({isUnloadCheck: false})
                  setTimeout(() => {
                    window.location.href='mailto:uripro.migration.japan@amadeus.com';
                    e.preventDefault();
                    appAction.setInputApp({isUnloadCheck: true})
                  })
                }}>uripro.migration.japan@amadeus.com</Link></div>
                <div className='ml-3 mb-3'>対応時間 月～金 9:30-17:30 土日祝休業</div>
                <div className='d-flex width-p-100'>
                  <Common.Check
                    attrs={{
                      id: 'confirmCheck',
                      label: '移行データの件数を確認し、データ移行完了に合意する。',
                      text: confirmCheck
                    }}
                    className='mx-auto'
                    onChange={(e) => setConfirmCheck(e.confirmCheck.text)}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        }
      />
      <Card
        size='md'
        root={`popup-button`}
        body={
          <React.Fragment>
            <Button
              className='button-blue width-20 mx-auto'
              onClick={props.action}
              disabled={confirmCheck==='0'}
            >
              <div className='d-flex flex-row align-items-center justify-content-center'>
                <div className='d-inline-block'>次へ</div>
                <div className='material-icons-outlined lg'>arrow_right</div>
              </div>
            </Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
})