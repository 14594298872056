import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Row, Col } from 'react-bootstrap';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjGridNom from '@grapecity/wijmo.grid';
import { LIST_ITEMS } from 'constants/ticket';
import { Card } from 'components';
import { MESSAGE } from 'constants/message';
import { alert } from "lib";
import _, { forEach } from 'lodash';

export const ExcelItemPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const initPopup = (form) => {
    // form.hostElement.classList.add('item-popup')
    props.setPopup(form)
  }

  const [sourceData, setSourceData] = React.useState([])
  const [targetData, setTargetData] = React.useState([])
  const [sourceGrid, setSourceGrid] = React.useState(null)
  const [targetGrid, setTargetGrid] = React.useState(null)
  const [btnDisabled, setBtnDisabled] = React.useState(false)
  const [sortBtnDisabled, setSortBtnDisabled] = React.useState(false)
  const [init, setInit] = React.useState(false)
  const [srcChg, setSrcChg] = React.useState(false)

  const showing = (e) => {
    setInit(true)
  }

  React.useEffect(() => {
    if (init) {
      setSourceData(props.listItems)
      setTargetData(props.dispCheckedItems)
      setInit(false)
    }
  }, [init])

  const initSourceGrid = (g) => {
    g.selectionMode = 'ListBox'
    // g.showMarquee = false
    setSourceGrid(g)
  }
  
  const initTargetGrid = (g) => {
    g.selectionMode = 'ListBox'
    // g.showMarquee = false
    setTargetGrid(g)
  }

  const itemsSourceChanged = (g, e) => {
    g.rowHeaders.columns.defaultSize = 50;
    let index = 0
    for (let r = 0; r < g.rowHeaders.rows.length; r++) {
      if (g.rowHeaders.rows[r].isVisible) {
        index++
        g.rowHeaders.setCellData(r, 0, index);
      }
    }
  }
  
  React.useEffect(() => {
    if (sourceData.length > 0) {
      sourceGrid.rowHeaders.columns.defaultSize = 50;
      _.forEach(sourceGrid.rows, (r, i) => {
        r.visible = (_.findIndex(targetData, r.dataItem) < 0)
      })
      setTimeout(() => {
        let sIndex = 0
        for (let r = 0; r < sourceGrid.rowHeaders.rows.length; r++) {
          if (sourceGrid.rowHeaders.rows[r].isVisible) {
            sIndex++
            sourceGrid.rowHeaders.setCellData(r, 0, sIndex);
          }
        }
      })
    }
  }, [targetData.length])


  const addItem = () => {
    setBtnDisabled(true)
    sourceGrid.beginUpdate()
    targetGrid.beginUpdate()
    const row1 = sourceGrid.selection.row
    const row2 = sourceGrid.selection.row2
    const rowIndex = row1 > row2? row1: row2
    const tgtRowIndex = targetGrid.selection.row
    let addRows = []
    _.forEach(sourceGrid.selectedRows, r => {
      if (r.isVisible) addRows.push(r.dataItem)
    })
    const tgt1 = targetData.slice(0, tgtRowIndex + 1)
    const tgt2 = targetData.slice(tgtRowIndex + 1)
    setTargetData(_.concat(tgt1, addRows, tgt2))

    //
    let cRange = null
    if (rowIndex + 1 < sourceGrid.rows.length) {
      for (let r = rowIndex + 1; r < sourceGrid.rows.length; r++) {
        if (sourceGrid.rows[r].isVisible) {
          cRange = new wjGridNom.CellRange(r, 0)
          break
        }
      }
    }
    if (!cRange) {
      for (let r = rowIndex - 1; r >= 0; r--) {
        if (sourceGrid.rows[r].isVisible) {
          cRange = new wjGridNom.CellRange(r, 0)
          break
        }
      }
    }
    if (cRange) {
      sourceGrid.selection = cRange
    }
    
    setTimeout(() => {
      targetGrid.select(tgt1.length + addRows.length - 1, 0)
      sourceGrid.endUpdate()
      targetGrid.endUpdate()
    })

    setBtnDisabled(false)
  }

  const delItem = () => {
    // console.log(targetGrid.selectedRows)
    setBtnDisabled(true)
    sourceGrid.beginUpdate()
    targetGrid.beginUpdate()
    const row1 = targetGrid.selection.row
    const row2 = targetGrid.selection.row2
    const rowIndex = row1 > row2? row2: row1
    const cRange = new wjGridNom.CellRange(rowIndex, 0)
    let rows = []
    let delItem = null
    _.forEach(targetGrid.rows, r => {
      const deletableRows = targetGrid.selectedRows
      if (!_.find(deletableRows, r)) {
        rows.push(r.dataItem)
      } else {
        delItem = r.dataItem
      }
    })
    setTargetData(rows)
    setTimeout(() => {
      const index = _.findIndex(sourceData, r => r.itemFrmItemId === delItem.itemFrmItemId)
      sourceGrid.select(index, 0)
      targetGrid.selection = cRange
      sourceGrid.endUpdate()
      targetGrid.endUpdate()
      setBtnDisabled(false)
    })
  }

  const sortItem = (type) => {
    let fromIdx = 0
    let toIdx = 0
    let isOver = false
    let selItems = targetGrid.selectedItems
    targetGrid.beginUpdate()
    if (type === 'top' || type === 'up') {
      _.forEach(targetGrid.selectedRows, (r, i) => {
        if (!isOver) {
          isOver = (r.index === 0)
          fromIdx = r.index
          toIdx = (type==='top')?0:(fromIdx - 1)<0?fromIdx:fromIdx - 1
          targetGrid.selection = new wjGridNom.CellRange(-1, -1)
          targetGrid.rows.moveElement(fromIdx, toIdx)
        }
      })
    }
    if (type === 'bottom' || type === 'down') {
      _.forEachRight(targetGrid.selectedRows, (r, i) => {
        if (!isOver) {
          isOver = (r.index === targetGrid.rows.length - 1)
          fromIdx = r.index
          toIdx = (type==='bottom')?targetGrid.rows.length - 1:(fromIdx + 1)>(targetGrid.rows.length - 1)?fromIdx:fromIdx + 1
          targetGrid.selection = new wjGridNom.CellRange(-1, -1)
          targetGrid.rows.moveElement(fromIdx, toIdx)
        }
      })
    }
    setTimeout(() => {
      let rows = []
      itemsSourceChanged(targetGrid)
      _.forEach(targetGrid.rows, r => rows.push(r.dataItem))
      setTargetData(rows)
      targetGrid.selectedItems = selItems
      targetGrid.endUpdate()
    })
  }

  const execSetting = () => {
    const obj = {}
    // if (targetData.length > EXCEL_MAX_ITEMS) {
    //   alert().fire({
    //     icon: MESSAGE.errorMaxItem().icon,
    //     title: MESSAGE.errorMaxItem().text,
    //     showCancelButton: false,
    //   })
    // } else {
      const rows = []
      _.forEach(targetGrid.rows, r => rows.push(r.dataItem))
      obj[`${props.tab}SelectedList`] = rows
      props.setInput({...obj})
      setSourceData([])
      setTargetData([])
      props.popup.hide()
    // }
  }

  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
        showing={(e) => showing(e)}
      >
        <Card
          header='EXCELデータ出力　出力項目設定'
          headerClass='wj-dialog-header'
          subheader={
            <React.Fragment>
              <span className='pt-1'>{`出力項目${targetData.length}`}</span>
              <button
                type="button"
                className="close"
                onClick={(e) => {
                  setSourceData([])
                  setTargetData([])
                  props.popup.hide()
                }}
              >
                &times;
              </button>
            </React.Fragment>
          }
          size='lg'
          root='popup w-75vw'
          hideClose
          bodyClass='px-5'
          body={
            <Row className='w-auto'>
              <Col xs={5}>
                <Card
                  header='表示項目選択'
                  size='lg'
                  // root='popup'
                  hideClose
                  bodyClass='vh-50'
                  body={
                    <div className='settings-list-body' >
                      <wjGrid.FlexGrid
                        deferResizing={true}
                        itemsSource={sourceData}
                        allowSorting={'None'}
                        allowDragging={false}
                        showMarquee={true}
                        // frozenColumns={1}
                        isReadOnly={true}
                        autoSearch={true}
                        initialized={(s) => initSourceGrid(s)}
                        // itemsSourceChanged={(g, e) => itemsSourceChanged(g, e)}
                      >
                        {LIST_ITEMS.excelSettings.map((r,i) => {
                          return (
                            <wjGrid.FlexGridColumn
                              key={i}
                              binding={r.id}
                              header={r.header}
                              dataType={r.dataType}
                              format={r.format}
                              isReadOnly={r.isReadOnly}
                              isRequired={r.isRequired}
                              maxLength={r.maxLength}
                              width={r.width}
                              minWidth={r.minWidth}
                              maxWidth={r.maxWidth}
                              allowSorting={r.allowSorting}
                              align={r.align}
                              dataMap={r.dataMap}
                            />
                          )})
                        }
                      </wjGrid.FlexGrid>
                    </div>
                  }
                />
              </Col>
              <Col xs={1} className='icon-center'>
                <div className='d-flex flex-column'>
                  <Button
                    className='button-gray mb-3'
                    disabled={btnDisabled}
                    onClick={(e) => addItem()}
                  >
                    <span className="material-icons-outlined">keyboard_arrow_right</span>
                  </Button>
                  <Button
                    className='button-gray'
                    disabled={btnDisabled}
                    onClick={(e) => delItem()}
                  >
                    <span className="material-icons-outlined">keyboard_arrow_left</span>
                  </Button>
                </div>
              </Col>
              <Col xs={6}>
                <Card
                  header='選択された出力項目'
                  size='lg'
                  subheader={<div className='settings-sub-header'>※矢印をクリックで順番変更可能</div>}
                  // root='popup'
                  hideClose
                  bodyClass='vh-50'
                  body={
                    <div className='settings-list-body' >
                      <div className='d-flex flex-row'>
                        <wjGrid.FlexGrid
                          deferResizing={true}
                          itemsSource={targetData}
                          allowSorting={'None'}
                          allowDragging={false}
                          showMarquee={true}
                          // frozenColumns={1}
                          isReadOnly={true}
                          autoSearch={true}
                          initialized={(s) => initTargetGrid(s)}
                          itemsSourceChanged={(g, e) => itemsSourceChanged(g, e)}
                          // selectionChanged={(g, e) => setSortBtnDisabled(g.selectedRows.length !== 1)}
                        >
                          {LIST_ITEMS.excelSettings.map((r,i) => {
                            return (
                              <wjGrid.FlexGridColumn
                                key={i}
                                binding={r.id}
                                header={r.header}
                                dataType={r.dataType}
                                format={r.format}
                                isReadOnly={r.isReadOnly}
                                isRequired={r.isRequired}
                                maxLength={r.maxLength}
                                width={r.width}
                                minWidth={r.minWidth}
                                maxWidth={r.maxWidth}
                                allowSorting={r.allowSorting}
                                align={r.align}
                                dataMap={r.dataMap}
                              />
                            )})
                          }
                        </wjGrid.FlexGrid>
                        <div className='d-flex flex-column'>
                          <Button
                            className='button-gray mb-1'
                            disabled={sortBtnDisabled}
                            onClick={(e) => sortItem('top')}
                          >
                            <span className="material-icons-outlined md">keyboard_double_arrow_up</span>
                          </Button>
                          <Button
                            className='button-gray mb-0'
                            disabled={sortBtnDisabled}
                            onClick={(e) => sortItem('up')}
                          >
                            <span className="material-icons-outlined md">keyboard_arrow_up</span>
                          </Button>
                          <Button
                            className='button-gray mb-1 mt-auto'
                            disabled={sortBtnDisabled}
                            onClick={(e) => sortItem('down')}
                          >
                            <span className="material-icons-outlined md">keyboard_arrow_down</span>
                          </Button>
                          <Button
                            className='button-gray mb-0'
                            disabled={sortBtnDisabled}
                            onClick={(e) => sortItem('bottom')}
                          >
                            <span className="material-icons-outlined md">keyboard_double_arrow_down</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  }
                />
              </Col>
            </Row>
          }
        />
        <Card 
          size='md'
          root={`popup-button`}
          hideClose
          body={
            <React.Fragment>
              <Button
                className='button-gray mr-auto'
                onClick={(e) => {
                  setSourceData([])
                  setTargetData([])
                  props.popup.hide()
                }}
              >
                <span className='material-icons-outlined md mr-2'>cancel</span>
                キャンセル
              </Button>
              {/* <Button className='button-gray mx-auto' onClick={(e) => initTree(e)}>
                <span className='material-icons-outlined md mr-2'>refresh</span>
                初期値に戻す
              </Button> */}
              <Button
                className='button-blue ml-auto'
                onClick={(e) => execSetting()}
                disabled={app.isProcessing}
              >
                <span className='material-icons-outlined md mr-2'>file_upload</span>
                設定
              </Button>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})