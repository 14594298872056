import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { TktImportAction } from 'ducks/TktImport';
import { TicketApi, ReportApi, PrismApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import { LIST_ITEMS } from 'constants/master';
import { convert } from "lib";
import { Spinner } from 'react-bootstrap';

export const ForceLoginPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);

  const [result, setResult] = React.useState(null)
  const [success, setSuccess] = React.useState(0)
  const [fail, setFail] = React.useState(0)
  const [skip, setSkip] = React.useState(0)
  const [failList, setFailList] = React.useState([])
  const [skipList, setSkipList] = React.useState([])

  const initPopup = (form) => {
    props.setPopup(form)
  }

  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
      >
        <Card 
          root='popup'
          size='lg'
          hideClose
          header='確認'
          headerClass='wj-dialog-header master-import'
          subheader={props.subheader}
          // body={props.body}
          body={
            <div>
              <div>
                <span>他のユーザがログインしている可能性があります。</span>
              </div>
              <div>
                <span>強制的に切り替えますか？</span>
              </div>
              <div className='d-flex mt-5 width-p-100'>
                <Button className='button-gray mr-auto wj-hide-cancel'>
                  キャンセル
                </Button>
                <Button className='button-warn ml-auto wj-hide-ok'>
                  強制的にログイン
                </Button>
              </div>
            </div>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})