import _ from 'lodash';

// core const
export const ROOT_URL = '';
export const BASE_URL = '/uri/api';
export const WS_URL = '/uri/ws';
export const AUTH_URL = '/uri/api/auth/amadeus'
// export const AUTH_URL = '/'

export const EXTERNAL_URL = {
  manual: {
    url: 'https://servicehub.amadeus.com/c/portal/view-solution/868980811/ja_JP/amadeus-uri-pro-',
    target: 'manual',
  },
  client: {
    url: 'https://www.google.com/',
    target: 'client_help',
  }
}

export const GET_STATUS_URL = '/uri-api/getstartupstatus'
export const GET_HISTORY_URL = '/uri-api/getairreceivehistory'
export const GET_COUNT_URL = '/uri-api/gettktcount'
export const GET_PERIOD_URL = '/uri-api/gettktcountperiod'

// export const REFRESH_INTERVAL = 1200000
export const REFRESH_INTERVAL = 60000
export const SALT = 'sp01'


export const CLIENT_DOWNLOAD_URL = '/download/plusclient/PlusClientAp.application'

export const MIG_DOWNLOAD_URL = '/download/URI-Pro_%E7%A7%BB%E8%A1%8C%E3%83%84%E3%83%BC%E3%83%AB.zip'
export const MIG_REFRESH_INTERVAL = 5000

export const MIN_DATE_TEXT = '1900/01/01';
export const MAX_DATE_TEXT = '2099/12/31';
const minDateArray = MIN_DATE_TEXT.split('/')
const maxDateArray = MAX_DATE_TEXT.split('/')
export const MIN_DATE = new Date(_.toInteger(minDateArray[0]), _.toInteger(minDateArray[1])-1, _.toInteger(minDateArray[2]));
export const MAX_DATE = new Date(_.toInteger(maxDateArray[0]), _.toInteger(maxDateArray[1])-1, _.toInteger(maxDateArray[2]));
