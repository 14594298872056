import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import reactDom from 'react-dom';
import { Form, InputGroup} from 'react-bootstrap';
import * as wijmo from '@grapecity/wijmo';
import { format } from '@grapecity/wijmo';
import * as wjInput from '@grapecity/wijmo.react.input';
import _ from 'lodash';
import { validation } from "lib";
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { AppAction } from 'ducks/App';
import { LoginAction } from 'ducks/Login';
import { alert, getInitData, convert } from "lib";
import { TktEditAction } from 'ducks/TktEdit';

export const SingleSelect = React.forwardRef((props, ref) => {

  const {attrs} = props;
  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const loginAction = bindActionCreators(LoginAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);
  const [text,setText] = React.useState('');
  const [itemsSource, setItemsSource] = React.useState([]);
  const [popup, setPopup] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [selItems, setSelItems] = React.useState([])
  const [isChange, setIsChange] = React.useState(null)
  const [el, setEl] = React.useState(null)

  const formatItem = (c, e) => {
    if (props.template) e.item.innerHTML = wijmo.format(props.template, e.data);
  }

  React.useEffect(() => {
  }, [props.list, props.addingData])

  React.useEffect(() => {
    let list = []
    if (props.addingData) {
      list = [props.addingData].concat(props.list)
    } else {
      list = [].concat(props.list)
    }
    const blank  = {}
    blank[props.selectedValuePath] = ''
    blank[props.displayName] = '　'
    list = [blank].concat(list)

    const obj  = {}
    obj[props.selectedValuePath] = attrs.text
    obj[props.displayName] = ''

    let finded = _.filter(list, r => {
      return r[props.selectedValuePath] && r[props.selectedValuePath].toLowerCase() === attrs.text.toLowerCase()
    })
    if (el) {
      if (finded.length > 0) {
        setItemsSource(list)
        setText(finded[0][props.displayName])
        setTimeout(() => {
          el.selectedItem = finded[0]
        })
      } else {
        setItemsSource(list.concat(obj))
        setText('')
        setTimeout(() => {
          el.selectedItem = obj
        })
      }
    }
  }, [attrs.text, props.list, props.addingData])

  const lostFocus = (e) => {
    props.onBlur(convert.getTarget(attrs, 'text', e.text))
  }

  const selectedIndexChanged = (sender) => {
    setIsChange(sender)
  }

  React.useEffect(() => {
    if (isChange) {
      if (isChange.selectedValue === 'マスタ編集') {
        isChange.selectedIndex = 0
        setOpen(true)
      }
      setIsChange(null)
    }
  }, [isChange])


  React.useEffect(() => {
    if (open) {
      masterAction.setInputMaster({isCode: props.mPopup.isCode, type: props.mPopup.type})
      popup.show(true, (sender) => {
        
        if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
          // console.log('ok')
        } else {
          // console.log('cancel')
        }
        getInitData(appAction, true, loginAction, login, app)
        setOpen(false)
        // console.log(el.inputElement)
        // if ( el && el.inputElement) {
        //   el.inputElement.removeAttribute('disabled')
        // }
        // props.setSelectedItems(props.selectedItems.filter(r => r[props.selectedValuePath]!=='*'))
        masterAction.setInputMaster({isCode: false, type: 'main'})
      })
    }
  },[open])

  return (
    <React.Fragment>
      <Form.Group
        controlId={attrs.id}
        {...props.group}
        className={`${props.group.className?props.group.className:''} form-group ${attrs.visible===false?'invisible':'visible'}`}
      >
        <Form.Label
          sm="3"
          className={
            `${attrs.required && 'label-required'} ${attrs.label==='_none' && 'd-none'}`
          }
        >
          {attrs.label}
        </Form.Label>
        <InputGroup>
          <div
            className={`${props.group.textwidth} d-inline-flex ${attrs.isInvalid?'is-invalid':''}`}
            style={{'flexWrap': 'nowrap'}}
          >
            <wjInput.AutoComplete
              {...props}
              headerPath={props.selectedValuePath}
              isInvalid={attrs.isInvalid}
              required={attrs.required}
              disabled={attrs.disabled}
              maxLength={attrs.maxLength}
              minLength={attrs.minLength}
              delay={attrs.delay}
              placeholder={attrs.placeholder}
              isDisabled={attrs.disabled}
              className={attrs.isInvalid?'is-invalid':''}
              itemsSource={itemsSource}
              // selectedItems={props.selectedItems}
              selectedIndexChanged={(c) => selectedIndexChanged(c)}
              maxItems={99999}
              clickAction={'Toggle'}
              // gotFocus={(c, e) => gotFocus(c)}
              lostFocus={(e) => {
                lostFocus(e)
              }}
              formatItem={(c, e) => formatItem(c, e)}
              template={props.template}
              isRequired={false}
              isEditable={true}
              initialized={(c) => {
                setEl(c)
                // c.inputElement.style.minWidth===props.group.inputwidth?props.group.inputwidth:''
                // c.focus()
              }}
            />
            {!props.addingData &&
              <Form.Control
                sm="3"
                className={`${!text && 'd-none'} text-suffix text-overflow-ellipsis ${props.group.textsuffixwidth}`}
                readOnly
                disabled
                value={text}
              />
            }
          </div>
          {/* <Form.Control.Feedback type="invalid"> */}
          <Form.Control.Feedback className={attrs.isInvalid?'d-block':'d-none'} type="invalid">
            {attrs.helpertext}
          </Form.Control.Feedback>
        </InputGroup>
        {props.addingData &&
        <Form.Control
          sm="3"
          className={`${(!text && !props.group.textvisible) && 'd-none'} text-suffix-multi text-overflow-ellipsis ${props.group.textsuffixwidth}`}
          readOnly
          disabled
          value={text}
        />
        }
      </Form.Group>
      {props.addingData &&
        <MasterPopup popup={popup} setPopup={setPopup} isCode={props.mPopup.isCode} type={props.mPopup.type} className='d-none'/>
      }
    </React.Fragment>
  )

    // onKeyPress={e => {
    //   if (e.key === 'Enter' && !attrs.multiline) {
    //     document.getElementById(id).blur();
    //     window.setTimeout(()=>{
    //       if (enter && !document.querySelector(enter).disabled) {
    //         document.querySelector(enter).click();
    //       } else {
    //         // node.focus()
    //       }
    //       // e.preventDefault(); 
    //       return false;
    //     })
    //   }
    // }}
    // {...Inputattrs}
    // {...attrs}
    // onChange={(e) => setValue(e)}
  //   onBlur={(e) => {
  //   }}
  // />;
})
