import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger'
import rootReducer from 'ducks'
import { ROOT_URL } from 'constants/index';
import { persistReducer, persistStore } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

export const history = createBrowserHistory({ basename: ROOT_URL })

export let store;
export let persistor;

// const persistConfig = {
  // key: 'uripro', // Storageに保存されるキー名
  // storage, // 保存先
  // whitelist: ['Account'],
// }
// const persistedReducer = persistReducer(persistConfig, rootReducer(history))

export const configureStore = () => {
  const middleware = [];
  if (process.env.NODE_ENV === `development`) {
    const logger = createLogger({
      diff:true,
      collapsed:true,
    });
    middleware.push(logger);
  }
  
  store = createStore(
    // persistedReducer,
    rootReducer(history), // root reducer with router state
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middleware,
      ),
    ),
  )
  persistor = persistStore(store)
  return (persistor, store)
}
