import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { SystemAction } from 'ducks/System';

import * as Common from 'components/common';
import { Card } from 'components';
import {
  OverlayTrigger,
  Tooltip,
  Col,
  Row,
  Container,
  Form,
  Button,
  Toast,
  Image,
} from 'react-bootstrap';
import { convert, entryCheck, alert, validation } from "lib";
import _ from 'lodash';
import * as wijmo from '@grapecity/wijmo';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjGridNom from '@grapecity/wijmo.grid';
import { CellRange, DataMap } from "@grapecity/wijmo.grid";
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import { InputDate, ComboBox, AutoComplete, MultiAutoComplete, InputColor, InputNumber } from '@grapecity/wijmo.input';

import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { FARE_JUDG, LIST_ITEMS, SYS_WAIVER_CODE_REGISTR_COL, LOG_DATE_RANGE } from 'constants/system';
import { SystemApi } from 'webapi'

export const BizComponent = (props) => {

  const app = useSelector(state => state.App);
  const system = useSelector(state => state.System);
  const login = useSelector(state => state.Login);

  const history = useHistory();
  const location = useLocation();
  const { type } = useParams();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const systemAction = bindActionCreators(SystemAction, dispatch);

  const [files, setFiles] = React.useState(null)
  const [src, setSrc] = React.useState(null)
  const [url, setUrl] = React.useState(null)
  const [grid, setGrid] = React.useState(null)
  const [dlGrid, setDlGrid] = React.useState(null)
  const [dataList, setDataList] = React.useState([])
  const [fromToCheck, setFromToCheck] = React.useState(false)

  const initDlGrid = (g) => {
    setDlGrid(g)
  }

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  const getData = (keepMessage) => {
    if (system.type!=='travel') return
    appAction.showProcessing({isProcessing: true})
    if (!keepMessage) appAction.setMessage('')

    SystemApi.search('travel')
    .then(res => {
      if (res.payload.code === '000' || res.payload.code === '100') {
        const ui = {
          biz: {
            selDate: res.payload.selDate,
          },
          sys: {
            selDate: res.payload.selDate,
          },
          usrList: res.payload.sysUserList,
          selDate: res.payload.selDate,
        };
        // ui originalへ設定
        const original = (value, def) => {
          if (def.id === 'agtIataCode' && value.length >= 3) return value.substring(3)
          if (def.date) return convert.toDateString(value, 'yyyy/MM/dd')
              ? new Date(convert.toDateString(value, 'yyyy/MM/dd'))
              : null
          if (def.mmdd) return convert.mmdd2dateString(value, 'yyyy/MM/dd')
              ? new Date(convert.mmdd2dateString(value, 'yyyy/MM/dd'))
              : null
          if (def.number) return value?_.toNumber(value):null
          if (def.checkbox) return value === '1'
          return _.isNil(value)?'':value;
        }
        _.forEach(res.payload.sysBizIniList, (r, i) => {
          const id = _.camelCase(r.bizItemId)
          if (system.biz[id]) {
            ui.biz[id] = {
              ...system.biz[id],
              original: original(r.bizItemValue, system.biz[id]),
              text: original(r.bizItemValue, system.biz[id]),
              value: original(r.bizItemValue, system.biz[id]),
              helpertext: '',
              isInvalid: false,
              dirty: '',
              bizItemName: r.bizItemName,
              bizLogoImage: r.bizLogoImage,
              bizLogoImageFile: r.bizLogoImageFile,
            }
          }
        })
        _.forEach(res.payload.sysSystemIniList, (r, i) => {
          const id = _.camelCase(r.sysItemId)
          if (system.sys[id]) {
            ui.sys[id] = {
              ...system.sys[id],
              original: original(r.sysItemValue, system.sys[id]),
              text: original(r.sysItemValue, system.sys[id]),
              value: original(r.sysItemValue, system.sys[id]),
              helpertext: '',
              isInvalid: false,
              dirty: '',
              sysItemName: r.sysItemName,
            }
          }
        })
        systemAction.setInputSystem({...ui})
        // if (ui.biz.agtLogFile.bizLogoImage) {
        //   setUrl('data:image/png;base64,' + ui.biz.agtLogFile.bizLogoImage)
        // } else {
        //   setUrl(null)
        // }
        SystemApi.image()
        .then(res => {
          if (res.payload) {
            const bytes = base64ToArrayBuffer(res.payload)
            setUrl(URL.createObjectURL(new Blob([bytes], {type: 'application/octet-stream'})))
          } else {
            setUrl(null)
          }
        })
      } else {
        // error
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  React.useEffect(() => {
    // console.log(system.type)
    // console.log(type)
    if (system.type === type) getData()
  }, [system.type])

  const execSave = () => {
    let entryBiz = {};
    let entrySys = {};
    let hasError = false;
    let bizRequestList = []
    let sysRequestList = []
    
    // user-id
    const err = _.filter(system.usrList, (r) => r.hasError)
    const editList = []
    _.forEach(system.usrList, (r) => {
      if (r.isAddingNew) editList.push({...r, appCode: 'insert'})
      if (!r.isAddingNew && r.isEdit) editList.push({...r, appCode: 'update'})
    })

    if (err.length > 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.hasError().id,
        message: MESSAGE.hasError().text,
        type: MESSAGE.hasError().icon,
      }]})
      return
    }

    // 
    const getValue = (value, def) => {
      if (def.date) return convert.toDateString(value, 'yyyy/MM/dd')
      if (def.mmdd) return convert.toDateString(value, 'MM/dd')
      if (def.number) return value?value:null
      if (def.checkbox) return value?'1':'0'
      return value;
    }
    // setMessage('')
    _.forEach(system.biz, (v, k) => {
      if (v && !_.isUndefined(v.value)) {
        entryBiz[k] = entryCheck(system.biz, convert.getTarget(v, 'text', v.text))[k]
        hasError = hasError || entryBiz[k].isInvalid
        bizRequestList.push({
          bizItemId: _.snakeCase(v.id).toUpperCase(),
          bizItemValue: entryBiz[k].value,
          bizItemName: v.bizItemName,
          bizLogoImage: (!src && !url)?null:v.bizLogoImage,
          bizLogoImageFile: v.bizLogoImageFile,
        })
      }
    })
    _.forEach(system.sys, (v, k) => {
      if (v && !_.isUndefined(v.value)) {
        entrySys[k] = entryCheck(system.sys, convert.getTarget(v, 'text', v.text))[k]
        hasError = hasError || entrySys[k].isInvalid
        sysRequestList.push({
          sysItemId: _.snakeCase(v.id).toUpperCase().replace('CUR_1', 'CUR1').replace('CUR_2', 'CUR2').replace('PREV_1', 'PREV1').replace('PREV_2', 'PREV2'),
          sysItemValue: getValue(entrySys[k].value, v),
          sysItemName: v.sysItemName,
        })
      }
    })
    systemAction.setInputSystem({biz: entryBiz, sys: entrySys})
    if (hasError) return

    const dto = {
      bizIniRequestDto: {
        selDate: system.selDate,
        requestList: bizRequestList
      },
      systemIniRequestDto: {
        selDate: system.selDate,
        requestList: sysRequestList,
        sysBaseDate1: convert.toDateString(system.sys.sysBaseDateCur1.text, 'MM/dd'),
        sysBaseDate2: convert.toDateString(system.sys.sysBaseDateCur2.text, 'MM/dd'),
      },
      userIdRequestDto: {
        selDate: system.selDate,
        requestList: editList
      },
    }

    let image = null;
    if (files && files.length > 0) {
      let reader = new FileReader;
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        image = reader.result.replace(/data:.*\/.*;base64,/, '');
        dto.bizIniRequestDto.bizLogoImageFile = image
        updateApi(dto)
      }
    } else {
      updateApi(dto)
    }

  }

  const updateApi = (dto) => {
    alert().fire({
      icon: MESSAGE.confirmUpdate().icon,
      title: MESSAGE.confirmUpdate('システム設定').text
    })
    .then((result) => {
      if (result.isConfirmed) {
        if ((system.sys.sysSwitchYear.dirty || system.sys.sysBaseDateCur1.dirty || system.sys.sysBaseDateCur2.dirty)
          && system.sys.sysSwitchYear.text && (system.sys.sysBaseDateCur1.text || system.sys.sysBaseDateCur2.text)) {
          alert().fire({
            icon: MESSAGE.confirmIsMonthlyProcessing().icon,
            title: MESSAGE.confirmIsMonthlyProcessing().text
          })
          .then((result) => {
            if (result.isConfirmed) {
              dto.systemIniRequestDto.isMonthlyProcessing = true
            } else {
              dto.systemIniRequestDto.isMonthlyProcessing = false
            }
            callUpdateApi(dto)
          })
        } else {
          callUpdateApi(dto)
        }
      }
    })
  }

  const callUpdateApi = (dto) => {
    appAction.showProcessing({isProcessing: true})
    SystemApi.edit('travel', dto)
    .then((res) => {
      appAction.setMessage({message: res.payload.messageList})
      if (res.payload.code === '000') {
        getData(true);
      } else {
        appAction.showProcessing({isProcessing: false})
      }
    })
    .catch(() =>{})
    .finally(() => {})
  }

  const execUpload = () => {
    let image = null;
    if (files && files.length > 0) {
      let reader = new FileReader;
      reader.readAsDataURL(files[0]);
      reader.onload = function() {
        const formData = new FormData();
        image = reader.result.replace(/data:.*\/.*;base64,/, '');
        formData.append('bizLogoImageFile', image);

        SystemApi.upload({bizItemValue: image})
        .then((res) => {
          // appAction.setMessage({message: res.payload.messageList})
          // if (res.payload.code === '000') {
          //   // const obj = {}
          //   // obj.selDate = res.payload.selDate
          //   // obj[`${type}List`] = res.payload.masterCodeList
          //   // masterAction.setInputMaster({...obj})
          //   getData(true);
          // } else {
          //   appAction.showProcessing({isProcessing: false})
          // }
        })
        .catch(() =>{})
        .finally(() => {})
      }
    }
  }

  const execDownload = () => {

    let now = new Date()
    let dt = wijmo.DateTime
    if (system.log.logOutputFrom.text && system.log.logOutputTo.text) {
      if (system.log.logOutputFrom.text.getTime() > system.log.logOutputTo.text.getTime()) {
        appAction.setMessage({message: [{
          id: MESSAGE.magnitudeError().id,
          message: MESSAGE.magnitudeError('対象日').text,
          type: MESSAGE.magnitudeError().icon,
        }]})
        return
      }
      let today = dt.newDate(now.getFullYear(), now.getMonth(), now.getDate())
      let todayMax = dt.newDate(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999)
      let minDate = dt.addDays(today, LOG_DATE_RANGE * -1)
      if (minDate.getTime() > system.log.logOutputFrom.text.getTime() || system.log.logOutputFrom.text.getTime() > today.getTime()
        || minDate.getTime() > system.log.logOutputTo.text.getTime() || system.log.logOutputTo.text.getTime() > todayMax.getTime()) {
        appAction.setMessage({message: [{
          id: MESSAGE.dateRangeError().id,
          message: MESSAGE.dateRangeError('対象日', '本日を含めて2週間').text,
          type: MESSAGE.dateRangeError().icon,
        }]})
        return
      }
    } else {
      appAction.setMessage({message: [{
        id: MESSAGE.noSelectError().id,
        message: MESSAGE.noSelectError('対象日', LOG_DATE_RANGE).text,
        type: MESSAGE.noSelectError().icon,
      }]})
      return
    }

    appAction.setMessage('')
    appAction.showProcessing({isProcessing: true})

    const dto = {
      from: convert.toDateString(system.log.logOutputFrom.text, 'yyyy-MM-dd'),
      to: convert.toDateString(system.log.logOutputTo.text, 'yyyy-MM-dd'),
    }
    SystemApi.download(dto)
    .then((res) => {
      appAction.setMessage({message: res.payload.messageList})
      if (res.payload.code === '000') {
        let list = []
        _.forEach(res.payload.list, r => {
          list.push({
            ...r,
            datetime: convert.toDateString(convert.toDate(_.toString(r.datetime)), 'yyyy/MM/dd HH:mm'),
            // datetime: _.toString(r.datetime),
          })
        })
        setDataList(list)
        setTimeout(() => {
          // console.log(list.length)
          const fileType = 'txt/csv;charset=Shift_JIS';
          const rng = new wjGridNom.CellRange(0, 0, dlGrid.rows.length - 1, dlGrid.columns.length - 1)
          // console.log(rng)
          const csv = dlGrid.getClipString(rng, true, true);
          // console.log('csv')
          const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
          const blob = new Blob([bom, csv], { type: 'text/csv' });
          const now = convert.toDateTimeString(new Date(), 'yyyyMMddHHmmss')
          const fileName = 'Operation_' + now + '.csv'
          // console.log(fileName)
          if (navigator.msSaveBlob) { // IE 
              navigator.msSaveBlob(blob, fileName);
              appAction.showProcessing({isProcessing: false})
          } 
          else {
              var e = document.createElement('a');
              e.setAttribute('href', URL.createObjectURL(blob));
              e.setAttribute('download', fileName);
              e.style.display = 'none';
              document.body.appendChild(e);
              e.click();
              document.body.removeChild(e);
              appAction.showProcessing({isProcessing: false})
          }
        })
      } else {
        appAction.showProcessing({isProcessing: false})
      }
    })
    .catch(() =>{
      appAction.showProcessing({isProcessing: false})
    })
    .finally(() => {
    })
  }


  const execClDelete = () => {

    alert().fire({
      icon: MESSAGE.confirmDelete().icon,
      title: MESSAGE.confirmDelete('Plusクライアント端末情報').text
    })
    .then((result) => {
      if (result.isConfirmed) {
        appAction.setMessage('')
        appAction.showProcessing({isProcessing: true})

        SystemApi.cldelete({})
        .then((res) => {
          appAction.setMessage({message: res.payload.messageList})
        })
        .catch(() =>{})
        .finally(() => {
          appAction.showProcessing({isProcessing: false})
        })
      }
    })
  }

  const getColumn = () => {
    const column = []
    const memberList = app.memberList?app.memberList:[]
    _.forEach(LIST_ITEMS.usr, (r, i) => {
      let obj = {}
      switch (r.id) {
        case 'mbrCd':
          obj.dataMap = new DataMap(memberList, 'mbrCd', 'mbrCd')
          obj.editor = new AutoComplete(document.createElement('div'), {
            headerPath: 'mbrCd',
            itemsSource: memberList,
            isRequired: true,
            isEditable: true,
            minLength: 1,
            delay: 10,
            selectedValuePath: 'mbrCd',
            displayMemberPath: 'listName',
            formatItem: (c, e) => memberFormatItem(c, e),
          })
          break;
        case 'usrDelete':
          obj.cellTemplate = CellMaker.makeButton({
              text: r.header,
              click: (e, ctx) => {
                if (!ctx.item) return
                if (login.auth110 && login.auth110!=='1') return
                // console.log(ctx)
                const dto = {
                  requestList: [{
                    appCode :'delete',
                    id: ctx.item.id,
                  }]
                }
                alert().fire({
                  icon: MESSAGE.confirmDelete().icon,
                  title: MESSAGE.confirmDelete(`ユーザID：${ctx.item.userId}`).text
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    appAction.showProcessing({isProcessing: true})
                    SystemApi.edit('user', dto)
                    .then((res) => {
                      appAction.setMessage({message: res.payload.messageList})
                      if (res.payload.code === '000') {
                        systemAction.setInputSystem({usrList: res.payload.sysUserList})
                      }
                    })
                    .catch(() =>{})
                    .finally(() => {
                      appAction.showProcessing({isProcessing: false})
                    })
                  }
                })
              }
            })
      }
      column.push({...r, ...obj})
    })
    return column
  }

  const memberFormatItem = (c, e) => {
    let template='<table><tr>' +
    '<td class="width-5" title="コード">{mbrCd}</td>' +
    '<td class="" title="名称">{mbrNamej}</td>' +
    '</tr></table>'
    e.item.innerHTML = wijmo.format(template, e.data);
  }

  const gridFormatItem = (g, e) => {
    const item = e.getRow().dataItem
    const bind = e.getColumn().binding
    if ((!login.member || login.member.mbrAuth !== '1' || (login.auth110 && login.auth110!=='1')) && e.cell.firstChild) {
       e.cell.firstChild.disabled = true
    }
  }

  React.useEffect(() => {
    if (fromToCheck) {
      const from = system.log.logOutputFrom
      const to = system.log.logOutputTo
      let isOk = true
      if (!_.isNil(from.text) && from.text !== null && !to.text) {
        let dt = wijmo.DateTime
        let copyText = new Date(from.text)
        copyText.setHours(23,59,59,999)
        // console.log(copyText)
        systemAction.changeInputLog(convert.getTarget(to, 'text', copyText))
      }
      setFromToCheck(false)
    }
  }, [fromToCheck])

  const getPrevDate = () => {
    let text = ''
    let original1 = null
    let original2 = null
    let now = new Date()
    now.setHours(0,0,0,0)
    if (system.sys.sysSwitchYear.original) {
      if (system.sys.sysSwitchYear.original.getTime() > now.getTime()) {
        original1 = system.sys.sysBaseDatePrev1.original
        original2 = system.sys.sysBaseDatePrev2.original
      } else {
        original1 = system.sys.sysBaseDateCur1.original
        original2 = system.sys.sysBaseDateCur2.original
      }
    }
    if (original1 || original2) {
      text += '（現在の基準日：'
      if (original1) {
        text += wijmo.format('{d1:M/d}', {d1: original1})
      }
      if (original1 && original2) {
        text += ', '
      }
      if (original2) {
        text += wijmo.format('{d2:M/d}', {d2: original2})
      }
      text += '）'
    }
    {wijmo.format('{d1:MM/dd}', {d1: original1})}
    {wijmo.format('{d2:MM/dd}', {d2: original2})}
    return text
  }

  return (
    <React.Fragment>
      <Card
        size='md'
        root='max-button'
        hideClose
        header={null}
        subheader={null}
        body={
          <div className='d-flex flex-row'>
            <div className='width-p-32'>
              <Card
                size='md'
                // root='max'
                hideClose
                header={TITLE.system.biz}
                subheader={null}
                body={
                  <div className='d-inline-flex flex-column width-p-100'>
                    <Common.Text
                      attrs={{...system.biz.agiNameJp}}
                      onBlur={(e) => systemAction.changeInputBiz(e)}
                      group={{
                        className: 'width-auto mr-2 mb-2'
                      }}
                    />
                    <Common.Text
                      attrs={{...system.biz.agiNameEn}}
                      onBlur={(e) => systemAction.changeInputBiz(e)}
                      group={{
                        className: 'width-auto mr-2 mb-2'
                      }}
                    />
                    <Common.Text
                      attrs={{...system.biz.agiShpNameJp}}
                      onBlur={(e) => systemAction.changeInputBiz(e)}
                      group={{
                        className: 'width-auto mr-2 mb-2'
                      }}
                    />
                    <Common.Text
                      attrs={{...system.biz.agiShpNameEn}}
                      onBlur={(e) => systemAction.changeInputBiz(e)}
                      group={{
                        className: 'width-auto mr-2 mb-2'
                      }}
                    />
                    <div className='mt-3'>
                      <div><label className=''>{system.biz.agtLogFile.label}</label></div>
                      <label className='mb-0 mr-2'>
                        <span className={`refButton ${(login.auth110 && login.auth110!=='1')?'disabled':''}`}>{system.fileRef.label}</span>
                        <input
                          id={system.fileRef.id}
                          type='file'
                          hidden
                          accept='image/*'
                          disabled={app.isProcessing || (login.auth110 && login.auth110!=='1')}
                          onChange={(e) => {
                            setFiles(e.currentTarget.files)
                            if (e.currentTarget.files.length > 0) {
                              const reader = new FileReader()
                              reader.readAsDataURL(e.currentTarget.files[0])
                              reader.onload = () => {setSrc(reader.result)}
                            }
                          }}
                        />
                      </label>
                      <label className='mb-0'>
                        <span className={`refButton ${(login.auth110 && login.auth110!=='1')?'disabled':''}`}>{system.fileDelete.label}</span>
                        <Button
                          className='d-none'
                          disabled={app.isProcessing || (login.auth110 && login.auth110!=='1')}
                          onClick={() => {
                            setFiles(null)
                            setSrc(null)
                            setUrl(null)
                            document.getElementById([system.fileRef.id]).value = '';
                          }}
                        />
                      </label>
                      <div className='d-inline ml-3'>
                        <label sm="3" className='form-label'>※PNG形式の画像ファイルが対象です。</label>
                      </div>
                      {/* <Button
                        className='ml-5 button-gray invisible'
                        disabled={app.isProcessing || (login.auth110 && login.auth110!=='1')}
                        onClick={() => {
                          execUpload()
                        }}
                      /> */}
                      <div className='border mt-2 mb-5 min-height-24 max-height-24 overflow-auto'>
                        <Image src={src?src:url} fluid />
                      </div>
                      <div className='d-block height-3' />
                    </div>
                  </div>
                }
              />
            </div>
            <div className='width-p-36'>
              <Card
                size='md'
                // root='max'
                hideClose
                header={TITLE.system.sys}
                subheader={null}
                body={
                  (login.auth110 && login.auth110!=='1')?<div></div>:
                  <div className='d-inline-flex flex-column width-p-100'>
                    <Common.Number
                      attrs={{...system.sys.searchResultsDispLimit}}
                      onBlur={(e) => systemAction.changeInputSys(e)}
                      group={{
                        className: 'width-auto mr-2 mb-3'
                      }}
                    />
                    <div className='mr-3 mb-4'>
                      <div><label sm="3" className='form-label mb-0 width-18'>運賃種別判定有無</label></div>
                      <Common.Radio
                        attrs={{...system.sys.sysFareJudg}}
                        radios={FARE_JUDG}
                        onChange={(e) => systemAction.changeInputSys(e)}
                        group={{
                          className: 'width-max-content mr-0'
                        }}
                      />
                    </div>
                    <div>
                      <label sm="3" className='form-label mb-0 width-30'>
                        月跨ぎフラグ用基準日{getPrevDate()}
                      </label>
                    </div>
                    <div className='d-inline-flex mb-3'>
                      <Common.DateTime
                        attrs={{...system.sys.sysSwitchYear}}
                        onBlur={(e) => systemAction.changeInputSys(e)}
                        group={{
                          className: 'width-max-content mr-2 d-inline'
                        }}
                      />
                      <Common.DateTime
                        attrs={{...system.sys.sysBaseDateCur1}}
                        onBlur={(e) => systemAction.changeInputSys(e)}
                        group={{
                          className: 'width-max-content mr-2'
                        }}
                      />
                      <Common.DateTime
                        attrs={{...system.sys.sysBaseDateCur2}}
                        onBlur={(e) => systemAction.changeInputSys(e)}
                        group={{
                          className: 'width-max-content mr-0'
                        }}
                      />
                    </div>
                    <div className='mr-3 mb-3'>
                      <div><label sm="3" className='form-label mb-0 width-25'>WAIVER CODEの登録先（発券データ受信時）</label></div>
                      <Common.Radio
                        attrs={{...system.sys.sysWaiverCodeRegistrCol}}
                        radios={SYS_WAIVER_CODE_REGISTR_COL}
                        onChange={(e) => systemAction.changeInputSys(e)}
                        group={{
                          className: 'width-max-content mr-0'
                        }}
                      />
                    </div>
                    <Common.Text
                      attrs={{...system.biz.agiMailAdd}}
                      onBlur={(e) => systemAction.changeInputBiz(e)}
                      group={{
                        className: 'width-auto mr-2 mb-0'
                      }}
                    />
                    <div className='mb-3'>
                      <label sm="3" className='form-label width-p-100'>※タイマー処理でエラーとなった場合に通知メールが送信されます。</label>
                    </div>
                    
                    <div><label sm="3" className='form-label mb-0 width-18'>Plusクライアント</label></div>
                    <Button
                      {...system.termDelete}
                      className='button-blue btn-sm mt-auto mr-auto mb-4'
                      onClick={() => execClDelete()}
                      disabled={app.isProcessing}
                    >
                      {system.termDelete.label}
                    </Button>
                  </div>
                }
              />
              <Card
                size='md'
                // root='max'
                hideClose
                header={TITLE.system.log}
                subheader={null}
                body={
                  (login.auth110 && login.auth110!=='1')?<div></div>:
                  <div>
                    <div className='d-inline-flex width-p-100 align-items-center mb-3'>
                      <Common.DateTime
                        attrs={{...system.log.logOutputFrom}}
                        onBlur={(e) => {
                          systemAction.changeInputLog(e)
                          setTimeout(() => {
                            e.logOutputFrom.text && setFromToCheck(true)
                          })
                        }}
                        group={{
                          className: 'width-max-content mr-2 d-inline'
                        }}
                      />
                      <div className='mt-4'>～</div>
                      <Common.DateTime
                        attrs={{...system.log.logOutputTo}}
                        onBlur={(e) => systemAction.changeInputLog(e)}
                        group={{
                          className: 'width-max-content mr-0'
                        }}
                      />
                      <div className='ml-auto mt-4'>
                        <Button
                          {...system.download}
                          className='button-blue btn-sm'
                          onClick={() => execDownload()}
                          disabled={app.isProcessing}
                        >
                          {system.download.label}
                        </Button>
                      </div>
                      <div className='d-none' >
                        <wjGrid.FlexGrid
                          itemsSource={dataList}
                          initialized={(s) => initDlGrid(s)}
                        >
                          {LIST_ITEMS.download.map((r, i) => {
                            return (
                              <wjGrid.FlexGridColumn
                                key={i}
                                binding={r.id}
                                header={r.header}
                                dataType={r.dataType}
                              />
                            )
                          })}
                        </wjGrid.FlexGrid>
                      </div>
                    </div>
                    <div className='d-block height-7'>
                      <label sm="3" className='form-label width-p-100'>※過去2週間分の操作ログが取得できます。条件に取得対象1日分をご指定ください。</label>
                      <label sm="3" className='form-label width-p-100'>※操作ログが大きい場合はダウンロードまでに時間がかかります。</label>
                    </div>
                  </div>
                }
              />
            </div>
            <div className='width-p-32'>
              <Card
                size='md'
                // root='max'
                hideClose
                header={TITLE.system.usr}
                subheader={null}
                body={
                  <div className='d-inline-flex flex-column width-p-100'>
                    <div className='user-list-body' >
                      <Common.Grid
                        thisGrid={grid}
                        setGrid={setGrid}
                        colDef={getColumn()}
                        dataList={system.usrList}
                        noSelector={true}
                        grid={{
                          isReadOnly: (!login.member || login.member.mbrAuth !== '1' || (login.auth110 && login.auth110!=='1')),
                          allowAddNew: true,
                          gridFormatItem: (g, e) => {gridFormatItem(g, e)},
                          // frozenColumns: 1,
                        }}
                        setInput={systemAction.setInputSystem}
                      />
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        }
      />
      <Card 
        size='lg'
        root='button'
        hideClose
        header={null}
        subheader={null}
        body={
          <div className='card-buttom-button'>
            <Button
              {...system.save}
              className='button-blue mt-auto ml-auto'
              onClick={() => execSave()}
              disabled={app.isProcessing || (login.auth110 && login.auth110!=='1')}
            >
              {system.save.label}
            </Button>
          </div>
        }
      />
    </React.Fragment>
  )
}