import { handleActions, createAction } from 'redux-actions'
import { onChangeInput, initState } from 'lib'
import _ from 'lodash';

// const
export const REPORT = {
  CHANGE_INPUT_REPORT : 'CHANGE_INPUT_REPORT',
  SET_INPUT_REPORT : 'SET_INPUT_REPORT',
  ADD_REPORT_ITEM : 'ADD_REPORT_ITEM',
  REMOVE_REPORT_ITEM : 'REMOVE_REPORT_ITEM',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
};

// action
export const ReportAction = {
  changeInputReport: createAction(REPORT.CHANGE_INPUT_REPORT, (args) => (args)),
  setInputReport: createAction(REPORT.SET_INPUT_REPORT, (args) => (args)),
  addReportItem: createAction(REPORT.ADD_REPORT_ITEM, (args) => (args)),
  removeReportItem: createAction(REPORT.REMOVE_REPORT_ITEM, (args) => (args)),
  initSession: createAction(REPORT.INIT_SESSION),
  clearSession: createAction(REPORT.CLEAR_SESSION),
}

// initialState
const initialState = {
  // 検索
  sizeHorizontal: ['100%', '1'],
  sizeVertical: ['20%', '1'],
  sizeGraph: ['200px', '1'],
  condition: {
    id: 'condition',
    label: '',
    required: true,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  checkedItems: [
    
  ],
  checkedItemsSetting: [
  ],
  recentlyCondition: [],
  conditionPattern: [],
  patternList: [],
  patternName: {
    id: 'patternName',
    label: '名称',
    required: true,
    disabled: false,
    minLength: 0,
    maxLength: 30,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  searchUnit: {
    id: 'searchUnit',
    label: '',
    prefix: '表示単位',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  condClear: {
    id: 'clear',
    label: <span><span className='material-icons-outlined md'>delete_forever</span>検索条件全クリア</span>,
    disabled: false,
  },
  condEdit: {
    id: 'edit',
    label: <span><span className='material-icons-outlined md'>cloud_upload</span>検索条件パターン登録</span>,
    disabled: false,
  },
  search: {
    id: 'search',
    label: <span><span className='material-icons-outlined md'>search</span>発券データ検索</span>,
    disabled: false,
  },
  // 一覧表示
  dispCheckedItems: [
    {
      id: 'tktTktnoAl',
      header: 'TKT NO.（AIRLINEコード）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktTktnoSn',
      header: 'TKT NO.（S/N）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktPaxName',
      header: 'PAX NAME',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktStatus',
      header: 'TKTステータス',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktIsuDate',
      header: '発券日',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktDepDate',
      header: '出発日',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktDetailIssuedFlg',
      header: 'チケット明細発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
    },
  ],
  bulk: {},
  exec: {
    id: 'exec',
    label: <span><span className='material-icons-outlined md'>done</span>設定</span>,
    disabled: false,
  },
  research: {
    id: 'research',
    label: <span><span className='material-icons-outlined md'>search</span>再検索</span>,
    disabled: false,
  },
  // --
  label: [
    'アカウントコード単位で出力',
    'ファイル名パターン',
    'ロゴ出力',
    '出力形式',
    '出力対象',
    'プリンタ名称',
    '出力ファイル方式',
    'ファイル出力パス'
  ],
  ticketdetailFlag: {
    id: 'ticketdetailFlag',
    label: 'チケット明細',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  ticketdetailSummaryOutputFlag: {
    id: 'ticketdetailSummaryOutputFlag',
    label: 'チケット明細サマリ出力',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  ticketdetailSummaryFilename: {
    id: 'ticketdetailSummaryFilename',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  ticketdetailSummaryAccountcodeType: {
    id: 'ticketdetailSummaryAccountcodeType',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  ticketdetailOutputFlag: {
    id: 'ticketdetailOutputFlag',
    label: 'チケット明細出力',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  ticketdetailOutputFilename: {
    id: 'ticketdetailOutputFilename',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  ticketdetailOutputType: {
    id: 'ticketdetailOutputType',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  ticketdetailOutputRemarks: {
    id: 'ticketdetailOutputRemarks',
    label: '備考',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },

  customerprintOutputFlag: {
    id: 'customerprintOutputFlag',
    label: 'お客様用印刷',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  customerprintOutputTitle: {
    id: 'customerprintOutputTitle',
    label: 'タイトル',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  customerprintOutputRemarks: {
    id: 'customerprintOutputRemarks',
    label: '備考',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  customerprintOutputLogo: {
    id: 'customerprintOutputLogo',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  customerprintOutputFilename: {
    id: 'customerprintOutputFilename',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },

  agentcouponOutputFlag: {
    id: 'agentcouponOutputFlag',
    label: 'AGENT COUPON',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  agentcouponOutputFilename: {
    id: 'agentcouponOutputFilename',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  agentcouponOutputType: {
    id: 'agentcouponOutputType',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  
  cccfOutputFlag: {
    id: 'cccfOutputFlag',
    label: 'CCCF',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  cccfOutputFilename: {
    id: 'cccfOutputFilename',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  cccfOutputType: {
    id: 'cccfOutputType',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  
  cccfAgentcopyOutputFlag: {
    id: 'cccfAgentcopyOutputFlag',
    label: 'AGENT COPY',
    required: false,
    disabled: false,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  cccfCustomerprintOutputFlag: {
    id: 'cccfCustomerprintOutputFlag',
    label: 'お客様控え',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  cccfOutputLogo: {
    id: 'cccfOutputLogo',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  
  refundnoticeOutputFlag: {
    id: 'refundnoticeOutputFlag',
    label: 'REFUND NOTICE',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  refundnoticeOutputFilename: {
    id: 'refundnoticeOutputFilename',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },

  outputFlag: {
    id: 'outputFlag',
    label: '_none',
    required: false,
    disabled: false,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  printerName: {
    id: 'printerName',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: true,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  localOutputFlag: {
    id: 'localOutputFlag',
    label: 'URI-Pro Plus',
    required: false,
    disabled: false,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  s3OutputFlag: {
    id: 's3OutputFlag',
    label: 'SFTP転送',
    required: false,
    disabled: false,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  localOutputFilename: {
    id: 'localOutputFilename',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['filepath'],
    stringcase: '',
  },
  s3OutputFilename: {
    id: 's3OutputFilename',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 256,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['filepath'],
    stringcase: '',
  },

  back: {
    id: 'back',
    label: <span>詳細画面へ戻る</span>,
    disabled: false,
  },
  cancel: {
    id: 'cancel',
    label: <span><span className='material-icons-outlined md'>cancel</span>キャンセル</span>,
    disabled: false,
  },
  setting: {
    id: 'setting',
    label: <span><span className='material-icons-outlined md'>file_upload</span>設定</span>,
    disabled: false,
  },
  print: {
    id: 'print',
    label: <span><span className='material-icons-outlined md'>print</span>プレビュー・印刷</span>,
    disabled: false,
  },
  download: {
    id: 'download',
    label: <span><span className='material-icons-outlined md'>file_download</span>ファイル出力</span>,
    disabled: false,
  },
}
// reducer
export const ReportReducer = handleActions({
  [ReportAction.changeInputReport]: (state, action) => (onChangeInput(state, action.payload)),
  [ReportAction.setInputReport]: (state, action) => ({...state, ...action.payload,}),
  [ReportAction.addReportItem]: (state, action) => ({
    ...state,
    checkedItems: _.concat(state.checkedItems, action.payload)
  }),
  [ReportAction.removeReportItem]: (state, action) => {
    const obj = Object.assign({}, state)
    _.map(state, (v,k) => {
      if (k.startsWith(action.payload[0].key + '_')) delete obj[k]
    })
    return ({
    ...obj,
    checkedItems: _.reject(state.checkedItems, {header: action.payload[0].header})//keyに変更
  })},
  [ReportAction.initSession]: (state, action) => (initState(initialState)),
  [ReportAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)
