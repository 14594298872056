import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import { LIST_ITEMS } from 'constants/master';
import { convert } from "lib";
import * as Common from 'components/common';

export const FilePopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  const [popup, setPopup] = React.useState(null)
  const [success, setSuccess] = React.useState(0)
  const [fail, setFail] = React.useState(0)
  const [skip, setSkip] = React.useState(0)
  const [failList, setFailList] = React.useState([])
  const [skipList, setSkipList] = React.useState([])

  const [files, setFiles] = React.useState(null)
  const [fileInvalid, setFileInvalid] = React.useState(false)
  const [isUpdateForce, setIsUpdateForce] = React.useState('0')

  const initPopup = (form) => {
    setPopup(form)
  }
  
  const onClick = (e) => {
    popup.show(true, (sender) => {
      if (document.getElementById(['uploadFile'])) document.getElementById(['uploadFile']).value = '';
      if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
        // console.log('ok')
        setFail(0)
        setSkip(0)
      } else {
        setFileInvalid(false)
        setFail(0)
        setSkip(0)
        // console.log('cancel')
      }
    })
  }

  const execUpload = (isCode, type) => {
    if (success!==0 || fail!==0 || skip!==0) {
      setSuccess(0)
      setFail(0)
      setSkip(0)
      popup.hide('wj-hide-ok')
      return
    }
    setFileInvalid(!files)
    if (files) {
      // console.log(files[0])
      const formData = new FormData();
      formData.append('requestCodeName', type.toUpperCase());
      formData.append('uploadFile', files[0]);
      formData.append('isUpdateIgnore', isUpdateForce==='1');
      formData.append('isRequestSearch', (master.type!=='production' && master.type!=='fare'));
      // formData.append('search', null);
      setSuccess(0)
      setFail(0)
      setSkip(0)
      appAction.showProcessing({isProcessing: true})
      MasterApi.upload(isCode, type, formData).then((res) => {
        appAction.setMessage({message: res.payload.messageList})
        setFiles(null)
        if (res.payload.code === '000') {
          const obj = {}
          obj.selDate = res.payload.selDate
          let dataList = _.concat(
            res.payload.masterCodeList,
            res.payload.mstDecodeList,
            res.payload.mstFareJudgeList,
            res.payload.mstMemberCdList,
            res.payload.mstProductionList,
            res.payload.companyCdList,
          ).filter(v => v)
          _.forEach(dataList,(r, i) => {
            if (!_.isUndefined(r['fjgRegNo'])) r['fjgRegNo'] = _.padStart(r['fjgRegNo'], 4, '0')
            _.forEach(_.filter(LIST_ITEMS[type], 'date'), (record, index) => {
              r[record.id] = convert.toDateString(r[record.id], 'yyyy/MM/dd')
                ? new Date(convert.toDateString(r[record.id], 'yyyy/MM/dd'))
                : null
            })
            _.forEach(_.filter(LIST_ITEMS[type], 'number'), (record, index) => {
              r[record.id] = r[record.id]?r[record.id]:null
            })
            _.forEach(_.filter(LIST_ITEMS[type], 'checkbox'), (record, index) => {
              r[record.id] = r[record.id] === '1'
            })
          })
          obj[`${type}List`] = dataList
          // 法人コードの場合
          if (type === 'company') {
            obj.companyCommHeaderList = res.payload.comCommhList 
            obj.companyCommBodyList = []
            _.forEach(res.payload.comCommbList, (r, i) => {
              obj.companyCommBodyList.push({
                ...r,
                commbOwnRate: r.commbCommRate - r.commbCustRate,
              })
            })
          }
          
          masterAction.setInputMaster({...obj})
          // console.log('成功：' + res.payload.successCnt)
          // console.log('失敗：' + res.payload.failCnt)
          // console.log('スキップ：' + res.payload.skipCnt)
          // console.log(files[0])
          if (res.payload.successCnt > 0) setSuccess(res.payload.successCnt)
          if (res.payload.failCnt > 0) {
            setFail(res.payload.failCnt)
            setFailList(res.payload.resultList.filter(r => r.resultCode.indexOf('ERROR') !== -1))
            // console.log(res.payload.resultList.filter(r => r.resultCode.indexOf('ERROR') !== -1))
          }
          if (res.payload.skipCnt > 0) {
            setSkip(res.payload.skipCnt)
            setSkipList(res.payload.resultList.filter(r => r.resultCode.indexOf('SKIP') !== -1))
          }
          // if (res.payload.failCnt === 0 && res.payload.skipCnt === 0) popup.hide('wj-hide-ok')
        } else {
          popup.hide('wj-hide-ok')
        }
      })
      .catch(() =>{})
      .finally(() => {
        appAction.showProcessing({isProcessing: false})
        setFiles(null)
        if (document.getElementById('uploadFile')) document.getElementById('uploadFile').value = ''
      })
    }
  }

  return (
    <React.Fragment>
      <Button
        {...master.upload}
        className='button-gray mt-auto ml-auto'
        // onClick={() => execImport(master.type)}
        onClick={(e) => onClick(e)}
        disabled={master.upload.disabled || props.disabled || app.isProcessing}
      >
        {master.upload.label}
      </Button>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
      >
        <Card 
          root='popup w-50vw'
          size='md'
          hideClose
          header={props.header}
          headerClass='wj-dialog-header master-import'
          subheader={props.subheader}
          // body={props.body}
          body={
            (fail===0 && skip===0 && success===0)?
              <Form className='w-100'>
                <Form.Group className='mr-0 mb-3'>
                  <Form.File id="uploadFile">
                    <Form.File.Input
                      isInvalid={fileInvalid}
                      accept='.csv'
                      onChange={(e) => {
                        setFiles(e.currentTarget.files)
                      }}
                    />
                    <Form.Control.Feedback type="invalid">ファイルを選択してください。</Form.Control.Feedback>
                  </Form.File>
                </Form.Group>
                <Common.Check
                  attrs={{
                    label: '重複データは上書きする',
                    id: 'isUpdateForce',
                    text: isUpdateForce,
                  }}
                  onChange={(e) => {
                    setIsUpdateForce(e.isUpdateForce.text)
                  }}
                />
              </Form>
            :<div>
              <Card
                root='popup'
                size='md'
                hideClose
                header='成功'
                subheader={`${success}件`}
                body={null}
              />
              {fail > 0 &&
                <Card
                  root='popup'
                  size='md'
                  hideClose
                  header='失敗'
                  subheader={`${fail}件`}
                  body={
                    <React.Fragment>
                      <div className='height-10'>
                        <wjGrid.FlexGrid
                          deferResizing={true}
                          itemsSource={failList}
                          showMarquee={true}
                          allowDragging={false}
                          autoRowHeights={true}
                          autoSizeMode={2}
                          allowResizing={3}
                          isReadOnly={true}
                        >
                          <wjGrid.FlexGridColumn binding='line' header='データ' width='*' wordWrap={true} />
                          <wjGrid.FlexGridColumn binding='resultMsg' header='メッセージ' width='2*' wordWrap={true} />
                        </wjGrid.FlexGrid>
                      </div>
                    </React.Fragment>
                  }
                />
              }
              {skip > 0 &&
                <Card
                  root='popup'
                  size='md'
                  hideClose
                  header='スキップ'
                  subheader={`${skip}件`}
                  body={
                    <React.Fragment>
                      <div>上書きする場合は、再実行を行ってください。</div>
                      <div className='height-10'>
                        <wjGrid.FlexGrid
                          deferResizing={true}
                          itemsSource={skipList}
                          showMarquee={true}
                          allowDragging={false}
                          autoRowHeights={true}
                          autoSizeMode={2}
                          allowResizing={3}
                          isReadOnly={true}
                        >
                          <wjGrid.FlexGridColumn binding='line' header='データ' width='*' wordWrap={true} />
                          <wjGrid.FlexGridColumn binding='resultMsg' header='メッセージ' width='2*' wordWrap={true} />
                        </wjGrid.FlexGrid>
                      </div>
                    </React.Fragment>
                  }
                />
              }
            </div>
          }
        />
        <Card 
          size='md'
          root={`popup-button ${props.size}`}
          // body={props.button}
          body={
            <React.Fragment>
              <Button className={`button-gray mr-auto wj-hide-cancel ${(success!==0 || fail!==0 || skip!==0)?'d-none':''}`}>
                <span className='material-icons-outlined md mr-2'>cancel</span>
                キャンセル
              </Button>
              <Button
                className='button-blue ml-auto'
                onClick={() => execUpload(master.isCode, master.type)}
                disabled={app.isProcessing}
              >
                <span className='material-icons-outlined md mr-2'>file_upload</span>OK
              </Button>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})