import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import * as Common from 'components/common';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { TktImportAction } from 'ducks/TktImport';
import { TicketApi, ReportApi, PrismApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import { LIST_ITEMS } from 'constants/master';
import { convert } from "lib";
import { Spinner } from 'react-bootstrap';
import { TITLE } from 'constants/title';
import { LoginAction } from 'ducks/Login';
import { LoginApi } from 'webapi';

export const MntLoginPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const loginAction = bindActionCreators(LoginAction, dispatch);
  const history = useHistory();
  const location = useLocation();

  const [officeId, setOfficeId] = React.useState(null)

  const initPopup = (form) => {
    props.setPopup(form)
  }

  const execLogin = () => {
    appAction.showProcessing({isProcessing: true})

    // console.log(login)
    LoginApi.setDatasource({ds: officeId.toLowerCase()})
    .then(res => {
      // console.log(res.payload)
      if (res.payload.code === '000') {
        appAction.setBatchResult({processingList: res.payload.bizProcessingResultDtoList})
        sessionStorage.removeItem('processedSeq')
        loginAction.setInputLogin({
          mntOffice: officeId,
        })
        setTimeout(() => {
          props.popup.hide('wj-hide-ok')
          appAction.showProcessing({isProcessing: false})
          history.push(TITLE.main.link)
        }, 1000)
      } else if (res.payload.code === '300') {
        appAction.showProcessing({isProcessing: false})
      } else {
        props.popup.hide('wj-hide-ok')
        appAction.showProcessing({isProcessing: false})
        history.push(TITLE.error.link, {message: res.payload.messageList})
      }
    })
    .catch(() =>{
      props.popup.hide('wj-hide-ok')
      appAction.showProcessing({isProcessing: false})
    })
    .finally(() => {})
  }
  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
      >
        <Card 
          root='popup'
          size='lg'
          hideClose
          header='Office ID切り替え'
          headerClass='wj-dialog-header mnt-login'
          subheader={props.subheader}
          // body={props.body}
          body={
            <div>
              <div>
                <span>Office IDを選択してください。</span>
              </div>
              <div className='mt-3 width-p-100'>
                <Common.Select
                  attrs={{
                    id: 'officeId',
                    label: 'Office ID',
                    text: officeId,
                  }}
                  group={{
                    className: 'mr-0',
                    textwidth: 'width-p-100',
                  }}
                  minLength={1}
                  list={app.officeList}
                  onChange={(e) => setOfficeId(e.officeId.text)}
                />
              </div>
              <div className='mt-5 d-flex flex-row'>
                <Button className='button-gray mr-auto wj-hide-cancel'>
                  <span className='material-icons-outlined md mr-2'>cancel</span>
                  キャンセル
                </Button>
                <Button
                  className='button-warn ml-auto'
                  onClick={() => execLogin()}
                >
                  ログイン
                </Button>
              </div>
            </div>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})