import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { LIST_ITEMS, PDT_FARE_ID, TBL_TYPE, TKTKBN_MAP } from 'constants/master';
import { convert, entryCheck, alert, validation } from "lib";

export const DownloadPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  const [popup, setPopup] = React.useState(null)
  const initPopup = (form) => {
    setPopup(form)
  }

  const [tblTp, setTblTp] = React.useState([])
  const [range, setRange] = React.useState('1')
  const [csv, setCsv] = React.useState([])

  const onClick = (e) => {
    setCsv(props.onClick())
    if (props.onClick()) {
      popup.show(true, (sender) => {
        // if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
        //   console.log('ok')
        // } else {
        //   // setFileInvalid(false)
        //   console.log('cancel')
        // }
      })
    }
  }

  const execDownload = (isCode, type) => {
    if (range === '1' && csv.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return
    }
    if (range !== '1' && tblTp.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.tableNoSelect().id,
        message: MESSAGE.tableNoSelect().text,
        type: MESSAGE.tableNoSelect().icon,
      }]})
      return
    }
    const requestCsvSet = {
      pdtAlnCd: props.alCd,
      searchKind: range,
      pdtTblTypeList: tblTp
    }
    appAction.showProcessing({isProcessing: true})
    MasterApi.download(isCode, type, {
      requestCodeName: type.toUpperCase(),
      downloadFileName: `${master.type}.csv`,
      requestList: csv,
      requestCsvSet: requestCsvSet,
    }).then((res) => {
      if (res.payload.messageList) {
        appAction.setMessage({message: res.payload.messageList,})
      } else {
        appAction.setMessage('')
        const bom = (type === 'decode' || type === 'fare')?'':String.fromCharCode(0xFEFF)
        const blob = new Blob([bom, res.payload], {type: 'text/csv'})
        if (window.navigator.msSaveOrOpenBlob) {
          // for IE,Edge
          window.navigator.msSaveOrOpenBlob(blob, `${master.type}.csv`);
        } else {
          // for chrome, firefox
          const url = URL.createObjectURL(blob);
          const linkEl = document.createElement('a');
          linkEl.href = url;
          linkEl.setAttribute('download', `${master.type}.csv`);
          document.body.appendChild(linkEl);
          linkEl.click();
          URL.revokeObjectURL(url);
          linkEl.parentNode.removeChild(linkEl);
        }
      }
      popup.hide('wj-hide-ok')
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  React.useEffect(() => {
    if (props.range) setRange(props.range)
  }, [props.range])

  return (
    <React.Fragment>
      <Button
        {...master.csv}
        className={props.className?props.className:'button-gray mt-auto ml-auto'}
        // onClick={() => execImport(master.type)}
        onClick={(e) => onClick(e)}
        disabled={master.csv.disabled || props.disabled || app.isProcessing}
      >
        {master.csv.label}
      </Button>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
      >
        <Card 
          root='popup'
          size='md'
          hideClose
          header={props.header}
          headerClass='wj-dialog-header'
          subheader={props.subheader}
          // body={props.body}
          body={
            <React.Fragment>
              {!props.range &&
                <React.Fragment>
                  <div>出力範囲</div>
                  <Common.RadioButton
                    btnSize='sm'
                    attrs={{
                      id: 'range',
                      text: range,
                      value: range,
                      // disabled: !getEditable(),
                    }}
                    className='width-100 mb-3'
                    radios={[
                      {name: '選択行のみ', value: '1'},
                      {name: '範囲指定', value: '2'},
                    ]}
                    onChange={(e) => setRange(e.range.text)}
                  />
                </React.Fragment>
              }
              <div>テーブルタイプ</div>
              {TBL_TYPE.map( (check, i) =>
                <Common.Check
                  key={i}
                  attrs={{
                    id: check.value,
                    label: check.name,
                    disabled: range==='1',
                    text: (tblTp.findIndex(r => r===check.value) < 0)?'0':'1'
                  }}
                  // radios={check.value}
                  onChange={(e) => {
                    const tbl = [].concat(tblTp)
                    if (e[e.id].text === '1') tbl.push(e.id)
                    else _.pull(tbl, e.id)
                    setTblTp(_.uniq(tbl))
                  }}
                />
              )}
            </React.Fragment>
          }
        />
        <Card 
          size='md'
          root={`popup-button ${props.size}`}
          // body={props.button}
          body={
            <React.Fragment>
              <Button className='button-gray mr-auto wj-hide-cancel'>
                <span className='material-icons-outlined md mr-2'>cancel</span>
                キャンセル
              </Button>
              <Button
                className='button-blue ml-auto'
                onClick={() => execDownload(master.isCode, master.type)}
                disabled={app.isProcessing}
              >
                <span className='material-icons-outlined md mr-2'>file_download</span>
                OK
              </Button>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})