import { useState, useCallback } from 'react';
export const useSafeState = (unmountRef, defaultValue) => {
  const [state, changeState] = useState(defaultValue);
  const wrapChangeState = useCallback(
    (v) => {
      if (!unmountRef.current) {
        changeState(v);
      }
    },
    [changeState, unmountRef]
  );

  return [state, wrapChangeState];
};