import _ from 'lodash';
import { isDate, isValid, compareAsc, compareDesc, parse } from 'date-fns';
import { MIN_DATE, MAX_DATE } from 'constants/index';
import { EmailValidator } from 'commons-validator-js';

export const validation =  {
  required: (val) => {
    return !(_.isUndefined(val) || _.isNull(val) || val.length===0);
  },
  alpha: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[a-zA-Z]+$/.test(val);
  },
  alphaWildCard: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[a-zA-Z*?]+$/.test(val);
  },
  alphanum: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[a-zA-Z0-9]+$/.test(val);
  },
  alphanumWildCard: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[a-zA-Z0-9*?]+$/.test(val);
  },
  num: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9]+$/.test(val);
  },
  numWildCard: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9*?]+$/.test(val);
  },
  tel: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9-]+$/.test(val);
  //   return _.isNull(val) || val.length===0 || /^\d{2,3}-\d{1,4}-\d{1,4}$|^\d+$/.test(val);
  },
  zipCode: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^\d{3}-\d{4}$/.test(val);
  },
  telSearch: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9-]+$/.test(val);
  //   return _.isNull(val) || val.length===0 || 
  //     /^\d{2,3}-\d{0,4}$/.test(val) || 
  //     /^\d{2,3}-\d{1,4}-\d{0,4}$|^\d+$/.test(val);
  },
  zipCodeSearch: (val) => {
    // return _.isNull(val) || val.length===0 || /^\d{1,7}$/.test(val);
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || 
      /^\d{1,3}$/.test(val) || 
      /^\d{3}-\d{0,4}$/.test(val);
  },
  date: (dt) => {
    const date = dt && !isDate(dt)?parse(dt, 'yyyy/MM/dd', new Date()):dt;
    return _.isUndefined(date) || _.isNull(date)||(date.length===0)||(isDate(date)&&isValid(date)&&compareAsc(MAX_DATE, date)>=0 && compareDesc(MIN_DATE,date)>=0);
  },
  mmyy: (val) => {
    // return _.isNull(val) || val.length===0 || /^\d{1,7}$/.test(val);
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || 
      /^(0[1-9]|1[0-2])\/[0-9]{2}$/.test(val);
  },
  mail: (val) => {
    const emailValidator = new EmailValidator();
    if (!_.isUndefined(val) && !_.isNull(val) && val.length>0) {
      const valArray = _.split(val, ',');
      let isOk = true;
      _.forEach(valArray, r => {
        // console.log(r)
        isOk = isOk && emailValidator.isValid(r)
      })
      return isOk
    } else {
      return _.isUndefined(val) || _.isNull(val) || val.length===0 || emailValidator.isValid(val);
    }
    // /^\S+@\S+$/.test(val);
  },
  fullWidth: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[^\x01-\x7E\uFF66-\uFF9F]+$/.test(val);
  },
  halfWidth: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[\x01-\x7E]+$/.test(val);
  },
  password: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9a-zA-Z*$%&+-/:=?@_|~]+$/.test(val);
  },
  alphanum_sp: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9a-zA-Z-/\.\s]+$/.test(val);
  },
  alphanum_spWildCard: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9a-zA-Z-/\.\s*?]+$/.test(val);
  },
  alphanum_bl: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9a-zA-Z\s]+$/.test(val);
  },
  alphanum_blWildCard: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9a-zA-Z*?\s]+$/.test(val);
  },
  alphanum_bl_pd: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9a-zA-Z\.\s]+$/.test(val);
  },
  num_sl: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9/]+$/.test(val);
  },
  num_slWildCard: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9*?/]+$/.test(val);
  },
  num_hy: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9-]+$/.test(val);
  },
  num_hyWildCard: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[0-9*?-]+$/.test(val);
  },
  commrate: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^([1-9][0-9]{0,1}|0)(\.[0-9]{1,2})?$/.test(val);
  },
  filename: (val) => {
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || /^[^\\\/:\*\?\"<>\|]+$/.test(val);
  },
  filepath: (val) => {
    if (_.isUndefined(val) || _.isNull(val) || val.length===0) {
      return true
    } else {
      if (/^[^\*\?\"<>\|]+$/.test(val) && !(/[\.]{2}/g).test(val)) {
        return true
      } else {
        return false
      }
    }
  },
  katakana: (val) => {
    //ひらがな
    const regexpHiragana = /[\u{3041}-\u{3093}\u{309B}-\u{309E}]/mu;
    // 漢字
    const regexpKanji = /([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)/mu;
    return _.isUndefined(val) || _.isNull(val) || val.length===0 || (/^[^\x01-\x7E\uFF66-\uFF9F]+$/.test(val) && !regexpHiragana.test(val) && !regexpKanji.test(val));
  },
  uniqueGrid: (item, list, key) => {
    let compare ={}
    _.forEach(key, (r, i) => {
      compare[r.id] = item[r.id]
    })
    return _.filter(list, compare).length < 2
  },
  eitherReqGrid: (item, list, key) => {
    let compare = _.filter(key, (r, i) => !item[r.id])
    return key.length > compare.length
  },
  magnitude: (item, list, key) => {
    return item[key[0].id] >= item[key[1].id]
  },
  alnCd2: (val) => {
    return val && val.length === 2 && /^[a-zA-Z0-9]+$/.test(val);
  },
  alnCdn: (val) => {
    return val && val.length === 3 && /^[0-9]+$/.test(val);
  },
  alnCd2WildCard: (val) => {
    return val && val.length <= 2 && /^[a-zA-Z0-9?*]+$/.test(val);
  },
  alnCdnWildCard: (val) => {
    return val && val.length <= 3 && /^[0-9?*]+$/.test(val);
  },
  cityCd: (val) => {
    return val && val.length === 3 && /^[a-zA-Z0-9]+$/.test(val);
  },
  cityCdWildCard: (val) => {
    return val && val.length <= 3 && /^[a-zA-Z0-9?*]+$/.test(val);
  },
  orderCd: (val) => {
    return val && val.length <= 12 && /^[\x01-\x7E]+$/.test(val);
  },
  sectionCd: (val) => {
    return val && val.length <= 6 && /^[\x01-\x7E]+$/.test(val);
  },
  memberCd: (val) => {
    return val && val.length <= 6 && /^[\x01-\x7E]+$/.test(val);
  },
  companyCd: (val) => {
    return val && val.length <= 10 && /^[\x01-\x7E]+$/.test(val);
  },
  areaCd: (val) => {
    return val && val.length <= 3 && /^[\x01-\x7E]+$/.test(val);
  },
  freeCd: (val) => {
    return val && val.length <= 10 && /^[\x01-\x7E]+$/.test(val);
  },
  payCd: (val) => {
    return val && val.length <= 1 && /^[\x01-\x7E]+$/.test(val);
  },
  tourCd: (val) => {
    return val && val.length <= 15 && /^[\x01-\x7E]+$/.test(val);
  },
  kb1Cd: (val) => {
    return val && val.length <= 7 && /^[\x01-\x7E]+$/.test(val);
  },
  kb2Cd: (val) => {
    return val && val.length <= 7 && /^[\x01-\x7E]+$/.test(val);
  },
  eoCd: (val) => {
    return val && val.length <= 7 && /^[\x01-\x7E]+$/.test(val);
  },
  orderCdWildCard: (val) => validation.orderCd(val),
  sectionCdWildCard: (val) => validation.sectionCd(val),
  memberCdWildCard: (val) => validation.memberCd(val),
  companyCdWildCard: (val) => validation.companyCd(val),
  areaCdWildCard: (val) => validation.areaCd(val),
  freeCdWildCard: (val) => validation.freeCd(val),
  payCdWildCard: (val) => validation.payCd(val),
  tourCdWildCard: (val) => validation.tourCd(val),
  kb1CdWildCard: (val) => validation.kb1Cd(val),
  kb2CdWildCard: (val) => validation.kb2Cd(val),
  eoCdWildCard: (val) => validation.eoCd(val),
  any: (val) => {
    return true;
  },
}
