import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { AUTH_MAP, LIST_ITEMS, PDT_FARE_ID, TBL_TYPE, TKTKBN_MAP } from 'constants/master';
import { convert, entryCheck, alert, validation } from "lib";
import { faBullseye } from '@fortawesome/free-solid-svg-icons';

export const MemberAuthPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  const {popup, setPopup} = props

  const [list ,setList] = React.useState([])

  const initPopup = (form) => {
    setPopup(form)
  }

  React.useEffect(() => {
    if (props.data) {
      const prismTermPro = _.find(app.termProList, r => r.proItemId==='function_prism')
      const authPrism = (prismTermPro && prismTermPro.proItemValue === '1')
      const data = [].concat(AUTH_MAP).filter((r, i) => {return (r.id !== 'mbrCom14') || authPrism && (r.id === 'mbrCom14')})
      _.forEach(data, (r, i) => {
        r.selected = props.data.item[r.id]
      })
      setList(data)
    }
  }, [props.data])

  const execOk = () => {
    const data = {...props.data}
    // originalを設定
    if (!data.item.isAddingNew && !data.item.original) {
      data.item.original = {}
      _.forEach(props.colDef, (r, i) => {
        data.item.original[r.id] = data.item[r.id]
      })
    }
  // 編集内容を反映
    _.forEach(list, (r, i) => {
      data.item[r.id] = r.selected
    })
    // console.log(data)
    // console.log(master.memberList)
    props.setData(data)
    popup.dialogResult = 'wj-hide-ok'
    popup.hide()
  }

  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
      >
        <Card 
          root='popup'
          size='md'
          header={`権限　${props.data && props.data.item.mbrCd}`}
          headerClass='wj-dialog-header'
          subheader={null}
          hideClose={false}
          onClose={(e) => popup.hide('cancel')}
          // body={props.body}
          body={
            <React.Fragment>
              <wjInput.ListBox
                isDisabled={!props.data || props.data.item.mbrAuth !== '0' || props.disabled}
                displayMemberPath="name"
                checkedMemberPath="selected"
                // checkedItemsChanged={(e) => {console.log(e.checkedItems)}}
                itemsSource={list}
              />
            </React.Fragment>
          }
        />
        <Card 
          size='md'
          root={`popup-button ${props.size}`}
          // body={props.button}
          body={
            <React.Fragment>
              <Button className='button-gray mr-auto wj-hide-cancel'>
                <span className='material-icons-outlined md mr-2'>cancel</span>
                キャンセル
              </Button>
              <Button
                className='button-gray ml-auto'
                onClick={() => execOk()}
                disabled={app.isProcessing || props.disabled}
              >
                <span className='material-icons-outlined md mr-2'>done</span>
                OK
              </Button>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})