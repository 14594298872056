import _ from 'lodash';
import { convert } from "lib";
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';

export const makeCondition = (cond, state) => {

  const items = {};
  const count = cond.cnt?_.toNumber(cond.cnt):0;
  let suffix = '';
  let id = ''
  let restoreValue = ''

  // 入力コンポーネントを生成
  if (cond.type.startsWith('check')) {
    for (let i=0; i<count; i++) {
      suffix = CHECKS[cond.key][i].id;
      id = `${cond.key}_${suffix}`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key][suffix]
      items[id] = {
        ...COND_ITEMS[cond.key],
        id: id,
        label: CHECKS[cond.key][i].label,
        original: state[id]?state[id].text:_.isUndefined(restoreValue)?'':restoreValue?'1':'0',
        value: state[id]?state[id].text:_.isUndefined(restoreValue)?'':restoreValue?'1':'0',
        text: state[id]?state[id].text:_.isUndefined(restoreValue)?'':restoreValue?'1':'0',
      }
    }
  } else if (cond.type.startsWith('radio')) {
    id = cond.key
    restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['radioType']
    items[cond.key] = {
      ...COND_ITEMS[cond.key],
      id: id,
      original: state[id]?state[id].text:restoreValue?restoreValue:'',
      value: state[id]?state[id].text:restoreValue?restoreValue:'',
      text: state[id]?state[id].text:restoreValue?restoreValue:'',
    }
    if (cond.type.endsWith('check')) {
      for (let i=0; i<count; i++) {
        suffix = CHECKS[cond.key][i].id;
        id = `${cond.key}_${suffix}`
        restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key][suffix]
        items[id] = {
          ...COND_ITEMS[cond.key],
          id: id,
          label: CHECKS[cond.key][i].label,
          original: state[id]?state[id].text:restoreValue?'1':'0',
          value: state[id]?state[id].text:restoreValue?'1':'0',
          text: state[id]?state[id].text:restoreValue?'1':'0',
        }
      }
    }
  } else {
    // for (let i=0; i<count; i++) {
      suffix = 'array';
      id = `${cond.key}_${suffix}`
      const formList = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['formList']
      if (cond.type.startsWith('code')) {
        restoreValue = formList
      } else if (cond.key === 'tktCommRate') {
        restoreValue = []
        _.forEach(formList, r => {
          restoreValue.push({text: (r?_.toNumber(r):0) / 100})
        })
      } else {
        restoreValue = []
        _.forEach(formList, r => {
          restoreValue.push({text: r})
        })
      }
      items[id] = {
        ...COND_ITEMS[cond.key],
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
    // }
  }

  switch (cond.type) {
    case 'code':
      id = `${cond.key}_condition`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['radioType']
      items[id] = {
        ...RADIOS.attr.radio,
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      break;
    case 'code_range':
      id = `${cond.key}_from`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['strFrom']
      items[id] = {
        ...COND_ITEMS[cond.key],
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_to`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['strTo']
      items[id] = {
        ...COND_ITEMS[cond.key],
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_choiceBlank`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['dataTypeRadioType']
      items[id] = {
        ...RADIOS.attr.radio,
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_condition`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['clusterRadioType']
      items[id] = {
        ...RADIOS.attr.radio,
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      break;
    case 'text':
      break;
    case 'text_blank':
    case 'text_multi':
      id = `${cond.key}_blank`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['radioType']
      items[id] = {
        ...RADIOS.attr.radio,
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      break;
    case 'text_in':
      id = `${cond.key}_condition`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['radioType']
      items[id] = {
        ...RADIOS.attr.radio,
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      break;
    case 'text_in_blank':
      id = `${cond.key}_blank`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['dataTypeRadioType']
      items[id] = {
        ...RADIOS.attr.radio,
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_condition`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['clusterRadioType']
      items[id] = {
        ...RADIOS.attr.radio,
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      break;
    case 'text_in_range':
      id = `${cond.key}_from`
      const from = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['from']
      restoreValue = (from?_.toNumber(from):0) / 100
      items[id] = {
        ...COND_ITEMS[cond.key],
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:null,
        value: state[id]?state[id].text:restoreValue?restoreValue:null,
        text: state[id]?state[id].text:restoreValue?restoreValue:null,
      }
      id = `${cond.key}_to`
      const to = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['to']
      restoreValue = (to?_.toNumber(to):0) / 100
      items[id] = {
        ...COND_ITEMS[cond.key],
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:null,
        value: state[id]?state[id].text:restoreValue?restoreValue:null,
        text: state[id]?state[id].text:restoreValue?restoreValue:null,
      }
      id = `${cond.key}_choice`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['dataTypeRadioType']
      items[id] = {
        ...RADIOS.attr.radio,
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_condition`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['clusterRadioType']
      items[id] = {
        ...RADIOS.attr.radio,
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      break;
    // case 'text_multi':
    //   break;
    // case 'text_range':
    //   break;
    case 'text_range':
      id = `${cond.key}_from`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['strFrom']
      items[id] = {
        ...COND_ITEMS[cond.key],
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_to`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['strTo']
      items[id] = {
        ...COND_ITEMS[cond.key],
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_choice`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['radioType']
      items[id] = {
        ...RADIOS.attr.radio,
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      break;
    case 'date':
    case 'datetime':
      id = `${cond.key}_from`
      const dateDayFrom = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['dateDayFrom']
      const dateTimeFrom = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['dateTimeFrom']
      restoreValue = dateDayFrom?dateTimeFrom?new Date(dateDayFrom + ' ' + dateTimeFrom):new Date(dateDayFrom):null
      items[id] = {
        ...COND_ITEMS[cond.key],
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:null,
        value: state[id]?state[id].text:restoreValue?restoreValue:null,
        text: state[id]?state[id].text:restoreValue?restoreValue:null,
      }
      id = `${cond.key}_to`
      const dateDayTo = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['dateDayTo']
      const dateTimeTo = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['dateTimeTo']
      restoreValue = dateDayTo?dateTimeTo?new Date(dateDayTo + ' ' + dateTimeTo):new Date(dateDayTo):null
      items[id] = {
        ...COND_ITEMS[cond.key],
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:null,
        value: state[id]?state[id].text:restoreValue?restoreValue:null,
        text: state[id]?state[id].text:restoreValue?restoreValue:null,
      }
      id = `${cond.key}_chdate`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['chdate']
      items[id] = {
        ...RADIOS.attr.radio,
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_period1`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['period1']
      items[id] = {
        ...CHECKS.attr.check,
        id: id,
        label: CHECKS.period[0].label,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_period2`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['period2']
      items[id] = {
        ...CHECKS.attr.check,
        id: id,
        label: CHECKS.period[1].label,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_period3`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['period3']
      items[id] = {
        ...CHECKS.attr.check,
        id: id,
        label: CHECKS.period[2].label,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_period4`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['period4']
      items[id] = {
        ...CHECKS.attr.check,
        id: id,
        label: CHECKS.period[3].label,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_number`
      // restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['number']
      items[id] = {
        ...NUMBER.number,
        id: id,
        original: state[id]?state[id].text:null,
        value: state[id]?state[id].text:null,
        text: state[id]?state[id].text:null,
        // original: state[id]?state[id].text:restoreValue?restoreValue:null,
        // value: state[id]?state[id].text:restoreValue?restoreValue:null,
        // text: state[id]?state[id].text:restoreValue?restoreValue:null,
      }
      break;
    case 'mmyy':
      id = `${cond.key}_from`
      const dateFrom = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['dateFrom']
      restoreValue = dateFrom?dateFrom.substring(5,7) + '/' + dateFrom.substring(2,4):''
      items[id] = {
        ...COND_ITEMS[cond.key],
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_to`
      const dateTo = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['dateTo']
      restoreValue = dateTo?dateTo.substring(5,7) + '/' + dateTo.substring(2,4):''
      items[id] = {
        ...COND_ITEMS[cond.key],
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      id = `${cond.key}_choice`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['radioType']
      items[id] = {
        ...RADIOS.attr.radio,
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      break;
    case 'check':
      break;
    case 'check_in':
      id = `${cond.key}_condition`
      restoreValue = state.searchJson && state.searchJson[cond.key] && state.searchJson[cond.key]['radioType']
      items[id] = {
        ...RADIOS.attr.radio,
        id: id,
        original: state[id]?state[id].text:restoreValue?restoreValue:'',
        value: state[id]?state[id].text:restoreValue?restoreValue:'',
        text: state[id]?state[id].text:restoreValue?restoreValue:'',
      }
      break;
    case 'radio':
      break;
    case 'radio_check':
      break;
  }
  return items;
}