import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from 'components';
import * as Common from 'components/common';

import { Button } from 'react-bootstrap';
import _ from 'lodash';
import { RADIOS, CHECKS } from 'constants/search';
import { entryCheck, convert } from "lib";
// import '../styles/conditionCard.scss'

export const CardRadioCheckComponent = (props) => {

  // 初期化
  React.useEffect(() => {
    clear('1')
  }, [props.condition.key])

  // clear
  const clear = (value) => {
    const obj = {}
    if (!props.items[0].value || value==='0') {
      obj[props.items[0].id] = {
        ...props.items[0],
        original: RADIOS[props.condition.key][0].value,
        value: RADIOS[props.condition.key][0].value,
        text: RADIOS[props.condition.key][0].value,
      }
    }
    _.forEach(props.items, (r, i) => {
      if (i !== 0) {
        obj[r.id] = {
          ...r,
          original: value,
          value: value,
          text: value,
        }
      }
    })
    Object.keys(obj).length && props.setInput({...obj})
  }

  React.useEffect(() => {
    _.map(props.items, (r, i) => {
      if (i !== 0) {
        const isDisabled = props.items[0].text === RADIOS[props.condition.key][0].value
        const obj = convert.getTarget(r, 'disabled', isDisabled)
        obj[obj.id].original = isDisabled?'0':'1'
        obj[obj.id].value = isDisabled?'0':'1'
        obj[obj.id].text = isDisabled?'0':'1'
        delete obj.id
        // console.log(obj)
        // console.log(isDisabled)
        props.setInput({
          ...obj
        })
      }
    })
  }, [props.items[0].text])

  const getBody = () => {
    const getItem = _.map(props.items, (r, i) => {
      if (i === 0) return
      return (
        <React.Fragment key={i}>
          <Common.Check
            attrs={r}
            onChange={(e) => props.onBlur(e)}
            className='mb-1'
          />
        </React.Fragment>
      )
    })
    return (
      <React.Fragment>
        <div className='card-condition-item wrap pb-2'>
          <Common.Radio
            attrs={props.items[0]}
            radios={RADIOS[props.condition.key]}
            onChange={(e) => props.onBlur(e)}
          />
          <Card
            body={getItem}
          />
        </div>
        <div className='d-inline-block mb-1'>
          <Button
            id='clear'
            className='button-gray small width-max-content'
            onClick={(e) => clear('0')}
          >
            クリア
          </Button>
        </div>
      </React.Fragment>
    )
  }
  
  return (
    <React.Fragment>
      <Card 
        size={props.cardSize}
        header={props.condition.header}
        subheader={props.condition.subheader}
        body={getBody()}
        condition={props.condition}
        onClose={props.onClose}
      />
    </React.Fragment>
  )
}
