import _ from 'lodash';

export const TODAY_STATUS = [
  {name: '稼働中', value: 'noError', cName: 'success'},
  {name: '当日エラー有', value: 'hasError', cName: 'danger'},
]

export const INFO_BADGE1 = [
  {name: 'お知らせ', value: '1', cName: 'blue'},
  {name: 'メンテナンス', value: '2', cName: 'info'},
  {name: '障害', value: '3', cName: 'yellow'},
]

export const INFO_BADGE2 = [
  {name: '緊急', value: '1', cName: 'warn'},
]

export const INFO_BADGE3 = [
  {name: 'New', value: true},
]

export const LIST_ITEMS = {
  sum: [
    {
      id: 'yyyymm',
      header: '年月',
      dataType: 'Date',
      format: 'yyyy/MM',
      isReadOnly: true,
      width: 75,
      align: 'center',
      allowSorting: false,
    },
    {
      id: 'period',
      header: 'ピリオド',
      format: null,
      isReadOnly: true,
      width: '*',
      align: 'center',
      allowSorting: false,
    },
    {
      id: 'issueCount',
      header: '発券数(ET/EMD)',
      dataType: 'Number',
      format: null,
      isReadOnly: true,
      width: '2*',
      allowSorting: false,
    },
    {
      id: 'voidCount',
      header: 'VOID件数(CANX)',
      dataType: 'Number',
      format: null,
      isReadOnly: true,
      width: '2*',
      allowSorting: false,
    },
    {
      id: 'voidFreeCount',
      header: 'VOID無料件数(発券数×5%)',
      dataType: 'Number',
      format: null,
      isReadOnly: true,
      width: '2*',
      allowSorting: false,
    },
  ],
  rcv: [
    {
      id: 'datetime',
      header: '受信日時',
      dataType: 'Date',
      format: 'yyyy/MM/dd hh:mm',
      isReadOnly: true,
      width: '*',
      allowSorting: false,
    },
    {
      id: 'tktNo',
      header: 'エラーTKT NO.',
      dataType: 'String',
      format: null,
      isReadOnly: true,
      width: '*',
      allowSorting: false,
    },
  ],
}