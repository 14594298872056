import { handleActions, createAction } from 'redux-actions'
import { onChangeInput, initState } from 'lib'

// const
export const LOGIN = {
  CHANGE_INPUT_LOGIN : 'CHANGE_INPUT_LOGIN',
  SET_INPUT_LOGIN : 'SET_INPUT_LOGIN',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
};

// action
export const LoginAction = {
  changeInputLogin: createAction(LOGIN.CHANGE_INPUT_LOGIN, (args) => (args)),
  setInputLogin: createAction(LOGIN.SET_INPUT_LOGIN, (args) => (args)),
  initSession: createAction(LOGIN.INIT_SESSION),
  clearSession: createAction(LOGIN.CLEAR_SESSION),
}

// initialState
const initialState = {
  loginCd: '',
  userId: {
    id: 'userId',
    label: 'ユーザID',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 8,
    enter: '#authRequest',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
    master: 'user',
    type: '',
    btnName: '',
    name: '',
    loading: false,
  },

  access_token: {
    id: 'access_token',
    label: 'access_token',
    required: true,
    disabled: false,
    minLength: 1,
    maxLength: -1,
    enter: '#verify',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  id_token: {
    id: 'id_token',
    label: 'id_token',
    required: true,
    disabled: false,
    minLength: 1,
    maxLength: -1,
    enter: '#verify',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  token_type: {
    id: 'token_type',
    label: 'token_type',
    required: true,
    disabled: false,
    minLength: 1,
    maxLength: -1,
    enter: '#verify',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  expires_in: {
    id: 'expires_in',
    label: 'expires_in',
    required: true,
    disabled: false,
    minLength: 1,
    maxLength: -1,
    enter: '#verify',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },

  showConfirm: false,
  forceLogin: false,
  needReLogin: false,

  verify: {
    id: 'verify',
    label: '認証',
    disabled: false,
    variant: 'primary',
    target: ['access_token','id_token','token_type','expires_in'],
  },


}
// reducer
export const LoginReducer = handleActions({
  [LoginAction.changeInputLogin]: (state, action) => (onChangeInput(state, action.payload)),
  [LoginAction.setInputLogin]: (state, action) => ({...state, ...action.payload,}),
  [LoginAction.initSession]: (state, action) => ({...state}),
  [LoginAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)
