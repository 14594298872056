import React from 'react';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import { FlexGridDetail } from '@grapecity/wijmo.react.grid.detail';
import { MultiRow } from '@grapecity/wijmo.react.grid.multirow';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { Selector } from "@grapecity/wijmo.grid.selector";
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { CellRange } from "@grapecity/wijmo.grid";
import { MESSAGE, HELPER_MESSAGE } from 'constants/message';
import { convert, entryCheck, alert, validation } from "lib";
import _ from 'lodash';
import { LIST_ITEMS } from 'constants/master';
import { InputDate, InputMask, ComboBox, AutoComplete, InputColor, InputNumber } from '@grapecity/wijmo.input';

export const Grid = React.forwardRef(({...props}, ref) => {

  const { setGrid, colDef, dataList, setRowHeaderWidth, setColWidth } = props

  const [selector, setSelector] = React.useState(null)
  const [selectedItems, setSelectedItems] = React.useState(null)
  const [data, setData] = React.useState([])

  const initGrid = (g) => {
    setGrid(g)
    // マウスクリックで選択状態にする場合
    !props.noSelector && g.addEventListener(g.hostElement, 'click', e => {
      let ht = g.hitTest(e)
      let index = g.selection.col
      let col = index > -1 ? g.columns[index] : null;
      if (ht.cellType === 3) {
        // ht.getRow().isSelected = !ht.getRow().isSelected
        g.rows[g.selection.row].isSelected = !g.rows[g.selection.row].isSelected
        setTimeout(() => {
          g.refresh()
          g.collectionView.refresh()
        })
      }
    })
    !props.noSelector && g.addEventListener(g.hostElement, 'dblclick', e => {
      let ht = g.hitTest(e)
      let index = g.selection.col
      let col = index > -1 ? g.columns[index] : null;
      // console.log(ht)
      // console.log(col)
      // let isDark = col && col.isReadOnly || colDef[index].isEditable === false
      // if (((ht.cellType === 1 && col.dataType > -1) || ht.cellType === 3) && (!ht.getColumn().editor || isDark)) {
      //   // ht.getRow().isSelected = !ht.getRow().isSelected
      //   g.rows[g.selection.row].isSelected = !g.rows[g.selection.row].isSelected
      //   g.collectionView.refresh()
      // }
      if (ht.cellType === 1 && col.dataType > -1) {
        // ht.getRow().isSelected = !ht.getRow().isSelected
        g.rows[g.selection.row].isSelected = !g.rows[g.selection.row].isSelected
        setTimeout(() => {
          g.refresh()
          g.collectionView.refresh()
        })
      }
    })
    // Selector
    !props.noSelector &&
    setSelector(new Selector(g, {
      itemChecked: (s, e) => {
        setSelectedItems(g.rows.filter(r => r.isSelected))
      }
    }))
    // g.selectionMode = 'Row'
    // console.log(g)
    if (props.grid.colHeaderDefHeight) {
      g.columnHeaders.rows.defaultSize = props.grid.colHeaderDefHeight;
    }
    // props.setInput({editing: false})
    // console.log(props.grid.gridEditing)
    // console.log(props.grid.setGridEditing)
    props.grid.setGridEditing && props.grid.setGridEditing(false)
    g.select(0, 0);

    setRowHeaderWidth && setRowHeaderWidth(g.rowHeaders.width)
    const width = []
    _.forEach(g.columns, (r,i) => {
      width.push(r.renderWidth)
    })
    setColWidth && setColWidth(width)
  }

  const initDetailGrid = (s) => {
    props.detailGrid.setDetail(s)
  }

  React.useEffect(() => {
    if (props.thisGrid && selector && props.selCol !== 'header') {
      selector.column = props.thisGrid.columns[0]
    }
  }, [selector])

  React.useEffect(() => {
    // setData(dataList)

    setData(new CollectionView(dataList, {
      newItemCreator: () => {
        const item = {isAddingNew: true}
        _.forEach(colDef, (r, i) => {
          switch (r.dataType) {
            case 'Date':
              item[r.id] = null
              break;
            case 'Number':
              item[r.id] = null
              break;
            case 'Boolean':
              item[r.id] = false
              break;
          }
        })
        return item
      },
      getError: (item, property, parsing) => {
        const error = [];
        const target = _.filter(colDef, (r) => {return r.id === property && !r.void})[0]
        const key = _.filter(colDef, 'isKey')
        const either = _.filter(colDef, 'isEitherReq')
        const mag = _.concat([], _.filter(colDef, 'isMagLarge'), _.filter(colDef, 'isMagSmall'))
        if (target && target.stringcase && target.stringcase === 'upper') item[property] = _.toUpper(item[property])
        _.forEach(target && target.validtype, (r, i) => {
          if (r === 'uniqueGrid') {
            if (!validation[r](item, dataList, key)) error.push(HELPER_MESSAGE[r])
          } else if (r === 'eitherReqGrid') {
            if (!validation[r](item, dataList, either)) error.push(HELPER_MESSAGE[r])
          } else if (r === 'magnitude') {
            if (!validation[r](item, dataList, mag)) error.push(HELPER_MESSAGE[r])
          } else {
            // if (!validation[r](item[property])) error.push(target.header + HELPER_MESSAGE[r])
            if (!validation[r](item[property])) error.push(HELPER_MESSAGE[r])
          }
        })
        if (!item.error) item.error = {}
        item.error[property] = error.length > 0
        return error.length > 0? error[0]: null;
      }
    }))
  }, [dataList])

  const updatedLayout = (g, e) => {
    setRowHeaderWidth && setRowHeaderWidth(g.rowHeaders.width)
    const width = []
    _.forEach(g.columns, (r,i) => {
      width.push(r.renderWidth)
    })
    setColWidth && setColWidth(width)

    g.autoSizeRow(0, true);
  }

  const beginningEdit = (g, e) => {
    if (colDef[e.col].isEditable === false && !e.getRow().dataItem.isAddingNew) {
      e.cancel = true
    } else {
      // props.setInput({editing: true})
      props.grid.setGridEditing && props.grid.setGridEditing(true)
      // 指定処理
      if (props.grid.beginningEdit) props.grid.beginningEdit(g, e)
    }
  }

  const rowEditStarted = (g, e) => {
    // _.forEach(g.rows[e.row].dataItem, (v, k) => {
    //   if (k.endsWith('Date') && k!=='selDate' && !v) {
    //     g.rows[e.row].dataItem[k] = new Date()
    //   }
    // })

    if (g.collectionView.isAddingNew) {
      // g.rows[e.row].isSelected = true
      g.rows[e.row].dataItem.isAddingNew = true
    } else if (g.rows[e.row].dataItem && !g.rows[e.row].dataItem.original) {
      g.rows[e.row].dataItem.original = {}
      _.forEach(colDef, (r, i) => {
        g.rows[e.row].dataItem.original[r.id] = g.rows[e.row].dataItem[r.id]
      })
    }
    // props.setInput({editing: true})
    props.grid.setGridEditing && props.grid.setGridEditing(true)
  }
  const cellEditEnded = (g, e) => {
    // 指定処理
    if (props.grid.editEnded) props.grid.editEnded(g, e)
    // 
    // const item = e.getRow().dataItem
    // const bind = e.getColumn().binding

    // 更新終了
    // props.setInput({editing: false})
    props.grid.setGridEditing && props.grid.setGridEditing(false)
  }
  const rowEditEnded = (g, e) => {
    // e.getRow().isSelected = true
    // props.setInput({editing: false})
    props.grid.setGridEditing && props.grid.setGridEditing(false)
  }

  const formatItem = (g, e) => {
    const item = e.getRow().dataItem
    const bind = e.getColumn().binding

    // 指定処理
    if (props.grid.gridFormatItem) props.grid.gridFormatItem(g, e)
    // 編集不可
    let isDark = g.columns[e.getColumn().index] && g.columns[e.getColumn().index].isReadOnly
    // let isDark = colDef[e.getColumn().index].isReadOnly
    if (item && bind && !isDark) {
      isDark = (colDef[e.getColumn().index].isEditable === false && !item.isAddingNew && !colDef[e.getColumn().index].isWhite)
    }
    toggleClass(e.cell, 'cell-readonly', isDark);
    // error、dirtyステータス更新
    if (item) {
      const hasError = _.filter(item.error, (v, k) => {return v}).length > 0
      item.hasError = hasError
    }
    let isDirty = false
    if (item && item.original) {
      _.forEach(colDef, (r, i) => {
        isDirty = isDirty || (
          item[r.id] && item.original[r.id] && 
          _.isDate(item[r.id]) && _.isDate(item.original[r.id])
            ? item.original[r.id].valueOf() !== item[r.id].valueOf()
            : item.original[r.id] !== item[r.id])
      })
      item.isEdit = isDirty
    }

    if (item && item.original && !item.isAddingNew) {
      const changed = _.isDate(item[bind]) && _.isDate(item.original[bind])
      ? item.original[bind].valueOf() !== item[bind].valueOf()
      : item.original[bind] !== item[bind]
      toggleClass(e.cell, 'cell-changed', changed);
      toggleClass(g.rowHeaders.getCellElement(e.row, 0), 'cell-changed', item.isEdit);
    } else if (item && item.isAddingNew) {
      toggleClass(e.cell, 'cell-added', true);
      toggleClass(g.rowHeaders.getCellElement(e.row, 0), 'cell-added', true);
    }
  }

  const deletingRow = (g, e) => {
    if (!g.rows[e.row].dataItem.isAddingNew) e.cancel = true
    else if (props.grid.deletingRow) props.grid.deletingRow(g, e)
  }

  const deletedRow = (g, e) => {
    // 指定処理
    if (props.grid.deletedRow) props.grid.deletedRow(g, e)

    // props.setInput({editing: false})
    props.grid.setGridEditing && props.grid.setGridEditing(false)
  }

  const pasting = (g, e) => {
    // e.cancel = true
    for (let index = e.range.row; index <= e.range.row2; index++) {
      if (!g.rows[index]) {
        g.collectionView.addNew()
      }
    }
    g.collectionView.commitNew()
    // // // 1件に限定
    // // if (e.range.row !== e.range.row2) {
    // //   e.cancel = true
    // //   alert().fire({
    // //     icon: MESSAGE.multipleSelection().icon,
    // //     title: MESSAGE.multipleSelection().text
    // //   })
    // //   .then((result) => {
    // //     if (result.isConfirmed) {
    // //       console.log('ok')
    // //     }
    // //   });
    // // }
  }

  const rowAdded = (g, e) => {
    // 指定処理
    if (props.grid.rowAdded) props.grid.rowAdded(g, e)
  }

  const scrollPositionChanged = (g, e) => {
    const rows = g.rows.filter((r) => r.dataItem && r.dataItem.isEdit)
    _.forEach(rows, (r, i) => {
      setTimeout(() => {
        if (g.rowHeaders.getCellElement(r.index, 0)) {
          toggleClass(g.rowHeaders.getCellElement(r.index, 0), 'cell-changed', true);
        }
      })
    })
  }

  const getMaxLength = (item) => {
    if (!props.masterType) return item.maxLength
    else return item[`${props.masterType}MaxLength`]
  }

  const gotFocus = (g) => {
    let index = g.selection.col
    let col = index > -1 ? g.columns[index] : null;
    let rIndex = g.selection.row
    let row = index > -1 ? g.rows[rIndex] : null;
    
    if (col && !col.isReadOnly && col.dataType != DataType.Boolean && row && row.dataItem && !row.dataItem.isAddingNew) {
        setTimeout(() => {
          g.startEditing(false); // quick mode
        }, 150); // let the grid update first
    // } else {
    //   props.setInput({editing: false})
      props.grid.setGridEditing && props.grid.setGridEditing(false)
    }
  }

  return (
    <React.Fragment>
      <wjGrid.FlexGrid
        id={props.id}
        className={props.className}
        deferResizing={true}
        // // itemsSource={master[`${master.type}List`]}
        itemsSource={data}
        allowSorting={'MultiColumn'}
        allowDelete={true}
        allowResizing={'Both'}
        // // showMarquee={true}
        // frozenColumns={1}
        bigCheckboxes={true}
        imeEnabled={true}
        keyActionTab='Cycle'
        keyActionEnter='Cycle'
        // newRowAtTop={true}
        initialized={(s) => initGrid(s)}
        starSizedColumns={(g, e) => updatedLayout(g, e)}
        cellEditEnded={(g, e) => cellEditEnded(g, e)}
        rowEditEnded={(g, e) => rowEditEnded(g, e)}
        rowAdded={(g, e) => rowAdded(g, e)}
        beginningEdit={(g, e) => beginningEdit(g, e)}
        rowEditStarted={(g, e) => rowEditStarted(g, e)}
        cellEditEnding={(g, e) => null}
        formatItem={(g, e) => formatItem(g, e)}
        deletingRow={(g, e) => deletingRow(g, e)}
        deletedRow={(g, e) => deletedRow(g, e)}
        selectionChanged={(g, e) => {
          if (e.row > -1 && e.getRow().dataItem) {
            e.getRow().dataItem.isSelected = e.getRow().isSelected
          }
          if (e.row > -1) {
            gotFocus(g)
          }
        }}
        pasting={(g, e) => pasting(g, e)}
        scrollPositionChanged={(g, e) => scrollPositionChanged(g, e)}
        autoSizedColumn={(g, e) => updatedLayout(g, e)}
        resizedColumn={(g, e) => updatedLayout(g, e)}
        allowDragging={props.grid.allowDragging?props.grid.allowDragging:false}
        // refreshing={(g, e) => updatedLayout(g, e)}
        {...props.grid}
      >
        { props.showFilter &&
          <FlexGridFilter />
        }
        { props.detailGrid &&
          <FlexGridDetail
            detailVisibilityMode={props.detailGrid.mode}
            initialized={(s) => initDetailGrid(s)}
            template={ctx => 
              <Grid
                id={props.detailGrid.id}
                noSelector={props.detailGrid.noSelector}
                thisGrid={props.detailGrid.thisGrid}
                setGrid={props.detailGrid.setGrid}
                colDef={props.detailGrid.colDef}
                dataList={props.detailGrid.dataList(ctx)}
                grid={{
                  ...props.detailGrid.grid
                  // isReadOnly: false,
                  // allowAddNew: true,
                  // editEnded: (g, e) => {gridEditEnded(g, e)}
                }}
                setInput={props.setInput}
              />}
          />
        }
        {colDef.map((r,i) => {
          return (
            <wjGrid.FlexGridColumn
              key={i}
              binding={r.id}
              wordWrap={r.wordWrap}
              header={r.header}
              name={r.name}
              dataType={r.dataType}
              dataMap={r.dataMap}
              dataMapEditor={r.dataMapEditor}
              editor={r.editor}
              format={r.format}
              isReadOnly={r.isReadOnly}
              isRequired={r.isRequired}
              maxLength={getMaxLength(r)}
              width={r.width}
              minWidth={r.minWidth}
              maxWidth={r.maxWidth}
              visible={r.visible}
              allowResizing={r.allowResizing}
              allowSorting={r.allowSorting}
              align={r.align}
              cellTemplate={r.cellTemplate}
            />
        )})}
      </wjGrid.FlexGrid>
    </React.Fragment>
  )
})
