import _ from 'lodash';

export const PRINT_MESSAGE = {
  header: 'PRISMデータ出力',
  processingTitle:'PRISMデータ出力中',
  processingMessage1: '出力結果は、画面を閉じても後で確認が可能です',
  processingMessage2: '',
  resultTitle: 'PRISMデータ出力の処理が完了しました',
  resultMessage1: '',
  resultMessage2: '',
}
export const ACCOUNT_CODE_TYPE = [
  {name: 'アカウントコード：受注コード(R)', value: '1'},
  {name: 'アカウントコード：法人コード(C)', value: '2'},
  {name: 'アカウントコード：FREEコード(F)', value: '3'},
  {name: 'アカウントコード：入金コード(M)', value: '4'},
  {name: 'アカウントコード：ツアーコード(T)', value: '5'},
  {name: 'アカウントコード：E/Oコード(E)', value: '6'},
  {name: 'アカウントコード：方面コード(D)', value: '7'},
  {name: 'アカウントコード：セクションコード(S)', value: '8'},
  {name: 'アカウントコード：担当者コード(A)', value: '9'},
  {name: 'アカウントコード：KBコード1(K)', value: '10'},
  {name: 'アカウントコード：KBコード2(B)', value: '11'},
]
export const ACCOUNT_CODE_TYPE_BLANK = [
  {name: '　', value: '0'},
].concat(ACCOUNT_CODE_TYPE)
export const SPECIFIED = [
  {name: '指定', value: '0'},
  {name: '除外', value: '1'},
  {name: 'ブランクデータ以外', value: '2'},
]
export const RANGE = [
  {name: '複数選択', value: '0'},
  {name: '範囲選択', value: '1'},
]
