import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { LoginAction } from 'ducks/Login';
import { TktImportAction } from 'ducks/TktImport';
import { LoginApi, MainApi, ProcessApi, LogoutApi } from 'webapi';
import { MESSAGE } from 'constants/message';
import { LIST_ITEMS } from 'constants/master';
import { alert, getInitData, convert } from "lib";
import { Spinner } from 'react-bootstrap';
import Iframe from 'react-iframe'
import { AUTH_URL } from 'constants/index';
import { TITLE } from 'constants/title';

export const ReLoginPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const loginAction = bindActionCreators(LoginAction, dispatch);
  const history = useHistory();
  const location = useLocation();

  const [loginCd, setLoginCd] = React.useState('')
  const [office, setOffice] = React.useState('')
  const [authCd, setAuthCd] = React.useState('')
  const [popup, setPopup] = React.useState(null)
  const [mpopup, setMpopup] = React.useState(null)

  const initPopup = (form) => {
    props.setPopup(form)
  }

  React.useEffect(() => {
    if (login.reLoginConfirm) {
      setLoginCd('')
      setOffice('')
      let _authCode = login.authCode
      let _loginCd = ''
      let _office = ''
      let _mbrCd = ''
      let timer = setInterval(() => {
        // console.log(document.getElementById('auth-frame'))
        if (document.getElementById('auth-frame')) {
          const ifdoc = document.getElementById('auth-frame').contentDocument
          const authCodeEl = ifdoc && ifdoc.getElementById('authCode')
          if (authCodeEl) {
            clearInterval(timer)
            _authCode = authCodeEl.value
            // console.log(authCodeEl.value)
            if (_authCode === '000') {
              const authLoginEl = ifdoc && ifdoc.getElementById('authLogin')
              const authOfficeEl = ifdoc && ifdoc.getElementById('authOffice')
              // console.log(authLoginEl.value)
              // console.log(authOfficeEl.value)
              _loginCd = authLoginEl.value
              _office = authOfficeEl.value
            } else {
              let authMessageEl = document.getElementById('auth-frame').contentDocument.getElementById('authMessage')
              // console.log(_authCode)
              // console.log(authMessageEl.value)
              props.popup.hide('wj-hide-ok')
              loginAction.setInputLogin({needReLogin: false})
              history.push(TITLE.error.link, {message: [{id:_authCode, message:authMessageEl.value, type:'error'}]})
            }
            setLoginCd(_loginCd)
            setOffice(_office)
            setAuthCd(_authCode)
            // loginAction.setInputLogin({
            //   authCode: _authCode,
            // })
          }
        }
      }, 300)
    }
  }, [login.reLoginConfirm])

  React.useEffect(() => {
    if (loginCd && office) {
      execLogin('0')
    }
  }, [loginCd, office])

  const execLogin = (isForce) => {
    appAction.showProcessing({isProcessing: true})
    appAction.setMessage('')
    
    let _login = ''
    let _mbr = ''
    let _authCode = ''
    LoginApi.login(({
      loginCd: loginCd,
      office: office,
      isForceLogin: isForce,
    }))
    .then(res => {
      // console.log(res.payload)
      sessionStorage.removeItem('processedSeq')
      if (res.payload.code === '000') {
        _login = res.payload.mbrLoginCd
        _mbr = res.payload.mbrCd
        _authCode = '000'
        loginAction.setInputLogin({needReLogin: false})
        if (login.loginCd.toUpperCase() !== _login.toUpperCase() || login.office.toUpperCase() !== office.toUpperCase()) {
          if (window.name.startsWith('_tktEdit')) {
            loginAction.setInputLogin({
              loginCd: '',
              mbrCd: '',
              office: '',
              authCode: '300',
              needReLogin: false,
            })
            window.close()
          } else {
            history.push(TITLE.main.link)
          }
        } else if (login.mntOffice && login.mntOffice.toUpperCase() !== office.toUpperCase()) {
          LoginApi.setDatasource({ds: login.mntOffice.toLowerCase()})
          .then(resDs => {
            // console.log(res.payload)
            if (resDs.payload.code === '000') {
              appAction.setBatchResult({processingList: resDs.payload.bizProcessingResultDtoList})
              sessionStorage.removeItem('processedSeq')
            } else {
              loginAction.setInputLogin({
                mntOffice: office,
              })
              history.push(TITLE.error.link, {message: resDs.payload.messageList})
            }
          })
          .catch(() =>{
            appAction.showProcessing({isProcessing: false})
          })
          .finally(() => {
            getInit(res)
          })
        } else {
          getInit(res)
        }
      } else if (res.payload.code === '320') {
        // 多重ログインを検知
        // console.log('多重ログイン')
        popup.show(true, (sender) => {
          // if (document.getElementById(['uploadPath'])) document.getElementById(['uploadPath']).value = '';
          if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
            // console.log('ok')
            execLogin('1')
          } else {
            // console.log('cancel')
            loginAction.setInputLogin({needReLogin: false})
            props.popup.hide('wj-hide-ok')
            history.push(TITLE.error.link, {message: res.payload.messageList})
          }
        })
        // execLogin('1')
      } else {
        loginAction.setInputLogin({needReLogin: false})
        props.popup.hide('wj-hide-ok')
        history.push(TITLE.error.link, {message: res.payload.messageList})
      }
      loginAction.setInputLogin({
        loginCd: _login,
        mbrCd: _mbr,
        office: office,
        authCode: _authCode,
      })
    })
    .catch(() =>{})
    .finally(() => {
      props.popup.hide('wj-hide-ok')
      appAction.showProcessing({isProcessing: false})
    })
  }

  const getInit = (res) => {
    LoginApi.getKey(() => {})
    .then(res => {//console.log(res)
      appAction.setInputApp({
        gatewayBaseUri: res.payload.gatewayBaseUri,
        ticketLimit: res.payload.ticketLimit?res.payload.ticketLimit:1,
        mntToolUri: res.payload.mntToolUri,
        clientKey: res.payload.key,
        reloadTime: res.payload.reloadTime?res.payload.reloadTime:10000,
      })
      getInitData(appAction, true, loginAction, login, app, location)
    })
    .catch(() =>{})
    .finally(() => {
      loginAction.setInputLogin({needReLogin: false})
      props.popup.hide('wj-hide-ok')
    })
  }

  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        isResizable={false}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
        hiding={(p, e) => {
          if (p.dialogResult !== 'wj-hide-ok') e.cancel = true
        }}
      >
        <Card 
          root='popup h-75vh w-75vw'
          size='lg'
          hideClose
          header='再認証'
          headerClass='wj-dialog-header master-import'
          subheader={props.subheader}
          // body={props.body}
          body={
            <React.Fragment>
              <div>
                <div>
                  <Iframe
                    src={AUTH_URL}
                    width='98%'
                    height='80%'
                    id='auth-frame'
                    display="initial"
                    position="absolute"
                    sandbox=''
                  />
                </div>
              </div>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})