import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktExportAction } from 'ducks/TktExport';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Badge,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert, getCondition, dataMapper, createDispSearch } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { SEARCH, COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { OUT_FORMAT, EXPORT_MESSAGE, LIST_ITEMS, OUT_PEX, OUT_GROSS_NET_SAME, 
  CLIENT_OUT_FILE, CLIENT_OUT_FILE_PATTERN, S3_OUT_FILE, S3_OUT_FILE_PATTERN, TKT_EMD_MPD_FLAG } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'

export const TicketExportComponent = (props) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const tktExport = useSelector(state => state.TktExport);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const tktExportAction = bindActionCreators(TktExportAction, dispatch);

  const [procPopup, setProcPopup] = React.useState(null)
  const [grid, setGrid] = React.useState(null)

  React.useEffect(() => {
    
    if (location.state && location.state.caller && location.state.caller ==='search') {
      if (tktExport.isLoaded) {
        appAction.showProcessing({isProcessing: false})
        return
      }
    }

    if (props.isModal && !props.isOpen) return
    // appAction.showProcessing({isProcessing: true})
    appAction.setMessage('')

    TicketApi.outputSearch({
      isTimerAccess: props.isModal,
      search:{}
    })
    .then(res => {
      appAction.setBatchResult({processingSeq: ''})
      const obj = {}
      obj.isLoaded = true
      _.forEach(tktExport, (o, id) => {
        _.forEach(res.payload.outIniDto, (v, k) => {
          if (id === k) {
            obj[id] = {
              ...tktExport[id],
              isInvalid: false,
              helpertext: '',
              original: v,
              value: v,
              text: v,
            }
            if (id === 'tktOutClientOutFile') {
              obj[id] = {
                ...tktExport[id],
                isInvalid: false,
                helpertext: '',
                original: v >= '3'?'3':v,
                value: v >= '3'?'3':v,
                text: v >= '3'?'3':v,
              }
              obj.tktOutClientOutFilePattern = {
                ...tktExport.tktOutClientOutFilePattern,
                isInvalid: false,
                helpertext: '',
                original: v,
                value: v,
                text: v,
              }
            }
            if (id === 'tktOutS3OutFile') {
              obj[id] = {
                ...tktExport[id],
                isInvalid: false,
                helpertext: '',
                original: v >= '3'?'3':v,
                value: v >= '3'?'3':v,
                text: v >= '3'?'3':v,
              }
              obj.tktOutS3OutFilePattern = {
                ...tktExport.tktOutS3OutFilePattern,
                isInvalid: false,
                helpertext: '',
                original: v,
                value: v,
                text: v,
              }
            }
          }
        })
      })

      obj.searchJson = {}
      if (res.payload.outSearchDto && res.payload.outSearchDto.tktOutsrcSearch) {
        const searchJson = JSON.parse(res.payload.outSearchDto.tktOutsrcSearch)
        // AND OR
        obj.condition = searchJson.condition
        // 
        obj.searchJson = searchJson.conditionJson
      }
      if (location.state && location.state.caller && tktExport.searchSql) {
        obj.searchSql = tktExport.searchSql
        obj.searchJson = tktExport.searchJson
        obj.condition = tktExport.condition
      } else {
        obj.searchSql = res.payload.outSearchDto && res.payload.outSearchDto.tktOutsrcSql
      }

      tktExportAction.setInputTktExport(obj)
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }, [props.isOpen])

  const execDownload = () => {

    if (!tktExport.searchSql) {
      appAction.setMessage({message: [{id: MESSAGE.noSearch().id, message: MESSAGE.noSearch().text, type: MESSAGE.noSearch().icon}]})
      return
    }
    if (!tktExport.tktOutFileName.text) {
      appAction.setMessage({message: [{id: MESSAGE.noFileName().id, message: MESSAGE.noFileName().text, type: MESSAGE.noFileName().icon}]})
      return
    }
    let entry = {}
    let hasError = false
    entry.tktOutFileName = entryCheck(tktExport, convert.getTarget(tktExport.tktOutFileName, 'text', tktExport.tktOutFileName.text))[`tktOutFileName`]
    hasError = hasError || entry.tktOutFileName.isInvalid
    tktExportAction.setInputTktExport(entry)
    if (hasError) return
    
    const tktOutsrcSearch = {
      condition: tktExport.condition,
      // conditionJson: getCondition(tktExport)
      conditionJson: tktExport.searchJson,
    }

    const dto = {
      execType: '0',
      outSearchDto: {
        tktOutsrcSql: tktExport.searchSql,
        tktOutsrcSearch: JSON.stringify(tktOutsrcSearch),
        tktOutsrcIniMbrcd: login.loginCd,
        cmnMbrcd: login.loginCd,
      },
      outIniDto: {
        tktOutFormat: tktExport.tktOutFormat.text,
        tktOutFileName: tktExport.tktOutFileName.text,
        tktOutPccdiv: tktExport.tktOutPccdiv.text,
        tktOutPex: tktExport.tktOutPex.text,
        tktOutGrossNetSame: tktExport.tktOutGrossNetSame.text,
        tktEmdMpdFlag: tktExport.tktEmdMpdFlag.text,
        // tktOutsrcIniMbrcd: login.loginCd,
        cmnMbrcd: login.loginCd,
    }}

    TicketApi.outputDownload(dto).then((res) => {
      if (res.payload.code === '000') {
        appAction.setBatchResult({processingSeq: res.payload.processingSeq})
      
        // setIfnFiles(null)
        procPopup.show(true, (sender) => {
          // if (document.getElementById(['uploadFile'])) document.getElementById(['uploadFile']).value = '';
          if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
            // console.log('ok')
            // setFail(0)
            // setSkip(0)
          } else {
            // setFileInvalid(false)
            // setFail(0)
            // setSkip(0)
            // console.log('cancel')
          }
        })
      } else {
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  const execSetting = () => {

    if (!tktExport.tktOutFileName.text) {
      appAction.setMessage({message: [{id: MESSAGE.noFileName().id, message: MESSAGE.noFileName().text, type: MESSAGE.noFileName().icon}]})
      return
    }
    if (tktExport.tktOutClientSyncFalg.text === '1' && !tktExport.tktOutClientPath.text) {
      appAction.setMessage({message: [{id: MESSAGE.noFilePath().id, message: MESSAGE.noFilePath().text, type: MESSAGE.noFilePath().icon}]})
      return
    }
    let entry = {}
    let hasError = false
    entry.tktOutFileName = entryCheck(tktExport, convert.getTarget(tktExport.tktOutFileName, 'text', tktExport.tktOutFileName.text))[`tktOutFileName`]
    hasError = hasError || entry.tktOutFileName.isInvalid
    entry.tktOutClientPath = entryCheck(tktExport, convert.getTarget(tktExport.tktOutClientPath, 'text', tktExport.tktOutClientPath.text))[`tktOutClientPath`]
    hasError = hasError || entry.tktOutClientPath.isInvalid
    entry.tktOutS3Path = entryCheck(tktExport, convert.getTarget(tktExport.tktOutS3Path, 'text', tktExport.tktOutS3Path.text))[`tktOutS3Path`]
    hasError = hasError || entry.tktOutS3Path.isInvalid
    tktExportAction.setInputTktExport(entry)
    if (hasError) return
    // if (tktExport.tktOutS3Flag.text === '1' && !tktExport.tktOutS3Path.text) {
    //   appAction.setMessage({message: [{id: MESSAGE.noFilePath().id, message: MESSAGE.noFilePath().text, type: MESSAGE.noFilePath().icon}]})
    //   return
    // }

    const dto = {
      execType: '0',
      isTimerAccess: true,
      outIniDto: {
        tktOutObjectKbn: '1',
        tktOutObjectId: login.mntOffice?login.mntOffice:login.office,
        tktOutFormat: tktExport.tktOutFormat.text,
        tktOutFileName: tktExport.tktOutFileName.text,
        tktOutPccdiv: tktExport.tktOutPccdiv.text,
        tktOutPex: tktExport.tktOutPex.text,
        tktOutGrossNetSame: tktExport.tktOutGrossNetSame.text,
        tktEmdMpdFlag: tktExport.tktEmdMpdFlag.text,
        tktOutClientSyncFalg: login.auth042==='1'?tktExport.tktOutClientSyncFalg.text:'0',
        tktOutClientPath: tktExport.tktOutClientPath.text,
        tktOutClientOutFile: tktExport.tktOutClientOutFile.text==='3'?tktExport.tktOutClientOutFilePattern.text:tktExport.tktOutClientOutFile.text,
        tktOutS3Flag: login.authFtp==='1'?tktExport.tktOutS3Flag.text:'0',
        tktOutS3Path: tktExport.tktOutS3Path.text,
        tktOutS3OutFile: tktExport.tktOutS3OutFile.text==='3'?tktExport.tktOutS3OutFilePattern.text:tktExport.tktOutS3OutFile.text,
        cmnMbrcd: login.loginCd,
    }}

    TicketApi.outputSetting(dto).then((res) => {
      if (res.payload.code === '000') {
        appAction.setMessage({message: res.payload.messageList})
        props.onSuccess()
      } else {
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }


  React.useEffect(() => {
    if (tktExport.tktOutClientOutFile.text === '3') {
      if (tktExport.tktOutClientOutFilePattern.text !== '3' && tktExport.tktOutClientOutFilePattern.text !== '4') {
        tktExportAction.setInputTktExport({
          tktOutClientOutFilePattern: {
            ...tktExport.tktOutClientOutFilePattern,
            value: '3',
            text: '3',
          }
        })
      }
    }
    if (tktExport.tktOutS3OutFile.text === '3') {
      if (tktExport.tktOutS3OutFilePattern.text !== '3' && tktExport.tktOutS3OutFilePattern.text !== '4') {
        tktExportAction.setInputTktExport({
          tktOutS3OutFilePattern: {
            ...tktExport.tktOutS3OutFilePattern,
            value: '3',
            text: '3',
          }
        })
      }
    }
  }, [tktExport.tktOutClientOutFile.text, tktExport.tktOutS3OutFile.text])

  return (
    <React.Fragment>
      <Container fluid className='main'>
        <Title
          title={TITLE.ticketExport.name}
          icon={TITLE.ticketExport.icon}
          button={
            <Button
              {...tktExport.search}
              className={`button-blue ml-auto ${props.isModal?'d-none':''}`}
              onClick={(e) => history.push(TITLE.ticketExportSearch.link, {func: 'export'})}
            >
              {tktExport.search.label}
            </Button>
          }
        />
        <div>
          <ProcessingPopup 
            popup={procPopup}
            setPopup={setProcPopup}
            header={EXPORT_MESSAGE.header}
            processingTitle={EXPORT_MESSAGE.processingTitle}
            processingMessage1={EXPORT_MESSAGE.processingMessage1}
            resultTitle={EXPORT_MESSAGE.resultTitle}
            resultMessage1={EXPORT_MESSAGE.resultMessage1}
          />
        </div>
        {!props.isModal && 
          <Card
            root='condition'
            size='md'
            hideClose
            header='データ検索条件'
            subheader={null}
            bodyClass='height-5'
            body={
              <React.Fragment>
                {tktExport.condition && tktExport.condition.text &&
                  <Badge className='badge-color search mr-2'>{`(${dataMapper(RADIOS.condition, 'value', 'name', tktExport.condition.text)}) 検索条件 : `}</Badge>
                }
                {createDispSearch(tktExport.searchJson).map((r, i) =>
                  <Badge key={i} className='badge-color search mr-2'>{r}</Badge>
                )}
              </React.Fragment>
            }
          />
        }
        <Card
          root={props.isModal?'max-button2':'max-button3'}
          size='lg'
          hideClose
          header={null}
          subheader={null}
          body={
            <React.Fragment>
              <Card
                root='max'
                size='lg'
                hideClose
                header={TITLE.ticketExport.specify.header}
                subheader={TITLE.ticketExport.specify.subheader}
                body={
                  <React.Fragment>
                    <div className='mb-3'>
                      <label sm="3" className='label-color mb-0 ml-3 text-left width-13'>
                        {tktExport.label[0]}
                      </label>
                      <Common.Radio
                        btnSize='sm'
                        attrs={{...tktExport.tktOutFormat}}
                        // className='mr-5'
                        radios={OUT_FORMAT}
                        onChange={(e) => tktExportAction.changeInputTktExport(e)}
                      />
                    </div>
                    <div className='mb-3'>
                      <label sm="3" className='label-color mb-0 ml-3 text-left width-13'>
                        {tktExport.label[1]}
                      </label>
                      <Common.Text
                        // btnSize='sm'
                        attrs={{...tktExport.tktOutFileName}}
                        onBlur={(e) => tktExportAction.changeInputTktExport(e)}
                        group={{
                          className: 'width-p-75 mr-0',
                        }}
                      />
                    </div>
                    {props.isModal &&
                    <React.Fragment>
                      <div className='mb-3'>
                        <label sm="3" className='label-color mb-0 ml-3 text-left width-13'>
                          {tktExport.label[2]}
                        </label>
                        <Common.Check
                          attrs={{...tktExport.tktOutPccdiv}}
                          onChange={(e) => tktExportAction.changeInputTktExport(e)}
                        />
                      </div>
                      {login.auth042==='1' &&
                      <div>
                        <div className='mb-3'>
                          <label sm="3" className='label-color mb-0 ml-3 text-left width-13'>
                            {tktExport.label[5]}
                          </label>
                          <Common.Check
                            attrs={{...tktExport.tktOutClientSyncFalg}}
                            onChange={(e) => tktExportAction.changeInputTktExport(e)}
                          />
                        </div>
                        <div className='mb-3'>
                          <label sm="3" className='label-color mb-0 mr-3 text-right width-30'>
                            {tktExport.label[6]}
                          </label>
                          <Common.Text
                            // btnSize='sm'
                            attrs={{...tktExport.tktOutClientPath}}
                            onBlur={(e) => tktExportAction.changeInputTktExport(e)}
                            group={{
                              className: 'width-p-50 mr-0',
                            }}
                          />
                        </div>
                        <div className='mb-3'>
                          <label sm="3" className='label-color mb-0 ml-0 mr-4 text-right width-5-28'>
                            {tktExport.label[7]}
                          </label>
                          <label sm="3" className='label-color mb-0 ml-0 mr-0 width-1'/>
                          <Common.Radio
                            btnSize='sm'
                            attrs={{...tktExport.tktOutClientOutFile}}
                            // className='mr-5'
                            radios={CLIENT_OUT_FILE}
                            onChange={(e) => tktExportAction.changeInputTktExport(e)}
                          />
                          <Common.Select
                            attrs={{...tktExport.tktOutClientOutFilePattern}}
                            group={{
                              className: 'width-20 ml-2',
                              textwidth: 'width-20',
                            }}
                            minLength={1}
                            list={CLIENT_OUT_FILE_PATTERN}
                            onChange={(e) => tktExportAction.changeInputTktExport(e)}
                          />
                        </div>
                      </div>
                      }
                      {login.authFtp==='1' &&
                      <div>
                        <div className='mb-3'>
                          <label sm="3" className='label-color mb-0 ml-3 text-left width-13'>
                            {tktExport.label[5]}
                          </label>
                          <Common.Check
                            attrs={{...tktExport.tktOutS3Flag}}
                            onChange={(e) => tktExportAction.changeInputTktExport(e)}
                          />
                        </div>
                        <div className='mb-3'>
                          <label sm="3" className='label-color mb-0 mr-3 text-right width-30'>
                            {tktExport.label[6]}
                          </label>
                          <Common.Text
                            // btnSize='sm'
                            attrs={{...tktExport.tktOutS3Path}}
                            onBlur={(e) => tktExportAction.changeInputTktExport(e)}
                            group={{
                              className: 'width-p-50 mr-0',
                            }}
                          />
                        </div>
                        <div className='mb-3'>
                          <label sm="3" className='label-color mb-0 ml-0 mr-4 text-right width-5-28'>
                            {tktExport.label[7]}
                          </label>
                          <label sm="3" className='label-color mb-0 ml-0 mr-0 width-1'/>
                          <Common.Radio
                            btnSize='sm'
                            attrs={{...tktExport.tktOutS3OutFile}}
                            // className='mr-5'
                            radios={S3_OUT_FILE}
                            onChange={(e) => tktExportAction.changeInputTktExport(e)}
                          />
                          <Common.Select
                            attrs={{...tktExport.tktOutS3OutFilePattern}}
                            group={{
                              className: 'width-20 ml-2',
                              textwidth: 'width-20',
                            }}
                            minLength={1}
                            list={S3_OUT_FILE_PATTERN}
                            onChange={(e) => tktExportAction.changeInputTktExport(e)}
                          />
                        </div>
                      </div>
                      }
                    </React.Fragment>
                    }
                  </React.Fragment>
                }
              />
              <Card
                root='max'
                size='lg'
                hideClose
                header={TITLE.ticketExport.option.header}
                subheader={TITLE.ticketExport.option.subheader}
                body={
                  <React.Fragment>
                    <div className='mb-3'>
                      <label sm="3" className='label-color mb-0 ml-3 text-left width-20'>
                        {tktExport.label[3]}
                      </label>
                      <Common.Radio
                        btnSize='sm'
                        attrs={{...tktExport.tktOutPex}}
                        // className='mr-5'
                        radios={OUT_PEX}
                        className='width-25'
                        onChange={(e) => tktExportAction.changeInputTktExport(e)}
                      />
                    </div>
                    <div className='mb-3'>
                      <label sm="3" className='label-color mb-0 ml-3 text-left width-20'>
                        {tktExport.label[4]}
                      </label>
                      <Common.Radio
                        btnSize='sm'
                        attrs={{...tktExport.tktOutGrossNetSame}}
                        // className='mr-5'
                        radios={OUT_GROSS_NET_SAME}
                        className='width-25'
                        onChange={(e) => tktExportAction.changeInputTktExport(e)}
                      />
                    </div>
                    <div className='mb-5'>
                      <label sm="3" className='label-color mb-0 ml-3 text-left width-20'>
                        {tktExport.label[9]}
                      </label>
                      <Common.Radio
                        btnSize='sm'
                        attrs={{...tktExport.tktEmdMpdFlag}}
                        // className='mr-5'
                        radios={TKT_EMD_MPD_FLAG}
                        className='width-25'
                        onChange={(e) => tktExportAction.changeInputTktExport(e)}
                      />
                    </div>
                  </React.Fragment>
                }
              />
              {/* <Card
                root='errlog'
                size='lg'
                hideClose
                header={null}
                subheader={null}
                body={
                  <React.Fragment>
                    <div className='err-list-body' >
                      <Common.Grid
                        thisGrid={grid}
                        setGrid={setGrid}
                        colDef={LIST_ITEMS.errorLog}
                        // masterType={master.type}
                        dataList={tktExport.errLogList}
                        // setRowHeaderWidth={setRowHeaderWidth}
                        // setColWidth={setColWidth}
                        noSelector={true}
                        grid={{
                          isReadOnly: false,
                          allowAddNew: false,
                          // frozenColumns: 1,
                          imeEnabled: false,
                        }}
                        setInput={tktExportAction.setInputTktExport}
                      />
                    </div>
                  </React.Fragment>
                }
              />
              <Card
                root='button'
                size='lg'
                hideClose
                header={null}
                subheader={null}
                body={
                  <div className='card-buttom-button'>
                    <Button
                      {...tktExport.before}
                      className='button-gray mr-auto width-max-content'
                      disabled={app.isProcessing}
                      onClick={() => {
                        appAction.showProcessing({isProcessing: true})
                        appAction.setMessage('')
                        // TicketApi.outputSearch({search:{}})
                        // .then(res => {
                        //   tktExportAction.setInputTktExport({errLogList: res.payload.errLogList})
                        // })
                        // .catch(() =>{})
                          .finally(() => {
                          appAction.showProcessing({isProcessing: false})
                        // })
                      }}
                    >
                      {tktExport.before.label}
                    </Button>
                    <Button
                      {...tktExport.csv}
                      className='button-gray ml-auto width-max-content'
                      disabled={app.isProcessing}
                      // onClick={() => execCsv()}
                    >
                      {tktExport.csv.label}
                    </Button>
                  </div>
                }
              /> */}
            </React.Fragment>
          }
        />
        <Card 
          size='lg'
          root='button'
          hideClose
          header={null}
          subheader={null}
          body={
            <div className='card-buttom-button'>
              {!props.isModal &&
                <Button
                  {...tktExport.download}
                  className='button-blue ml-auto width-max-content'
                  disabled={app.isProcessing}
                  onClick={() => execDownload()}
                >
                  {tktExport.download.label}
                </Button>
              }
              {props.isModal &&
                <React.Fragment>
                  <Button
                    {...tktExport.cancel}
                    className='button-gray mr-auto width-max-content'
                    disabled={app.isProcessing}
                    onClick={() => props.onClose()}
                  >
                    {tktExport.cancel.label}
                  </Button>
                  <Button
                    {...tktExport.setting}
                    className='button-blue ml-auto width-max-content'
                    disabled={app.isProcessing}
                    onClick={() => execSetting()}
                  >
                    {tktExport.setting.label}
                  </Button>
                </React.Fragment>
              }
            </div>
          }
        />
      </Container>
    </React.Fragment>
  )
}