import { axiosInstance } from 'http/index'

export const LoginApi = {
  login : (data) => {
    const instance = axiosInstance;
    return instance.post('verify', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  getKey : () => {
    const instance = axiosInstance;
    return instance.get('client/getKey').then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  setDatasource : (data) => {
    const instance = axiosInstance;
    return instance.post('ds', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
}
