import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';

import * as Common from 'components/common';
import { Card } from 'components';
import {
  OverlayTrigger,
  Tooltip,
  Col,
  Row,
  Container,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import { MultiRow } from '@grapecity/wijmo.react.grid.multirow';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { Selector } from "@grapecity/wijmo.grid.selector";
import { CollectionView, setSelectionRange } from "@grapecity/wijmo";
import * as wijmo from '@grapecity/wijmo';
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import { CellRange, DataMap } from "@grapecity/wijmo.grid";
import { InputDate, ComboBox, AutoComplete, MultiAutoComplete, InputColor, InputNumber } from '@grapecity/wijmo.input';
import { convert, entryCheck, alert, validation } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { LIST_ITEMS, COM_KBN_MAP, COM_TAX_MAP } from 'constants/master';
import { MasterApi } from 'webapi'
import { CompanyDetailPopup } from 'components/Master/modal/CompanyDetailPopup';

export const CompanySearchComponent = (props) => {
  
  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  const [itemChanged, setItemChanged] = React.useState(false)
  const [grid, setGrid] = React.useState(null)
  const [headerGrid, setHeaderGrid] = React.useState(null)
  const [bodyGrid, setBodyGrid] = React.useState(null)
  const [selector, setSelector] = React.useState(null)
  const [selectedItems, setSelectedItems] = React.useState(null)
  const [action, setAction] = React.useState('')
  const [rowHeaderWidth, setRowHeaderWidth] = React.useState(0)
  const [colWidth, setColWidth] = React.useState([])
  const [filters, setFilters] = React.useState({})
  const [addRow, setAddRow] = React.useState(-1)
  const [deleteRow, setDeleteRow] = React.useState(-1)
  const [popup, setPopup] = React.useState(null)
  const [popupData, setPopupData] = React.useState(null)

  const getData = (isCode, type) => {
    appAction.showProcessing({isProcessing: true})
    appAction.setMessage('')
    // フィルタの初期化
    const filter = {}
    _.forEach(LIST_ITEMS.company, (r, i) => filter[r.id] = '')
    setFilters(filter)

    MasterApi.search(isCode, type, {requestCodeName: type.toUpperCase(), search:{}})
    .then(res => {

      const obj = {}
      appAction.setMessage({message: res.payload.messageList})
      _.forEach(master, (v, k) => {
        if (k.endsWith('List')) obj[k] = []
      })

      if (res.payload.code === '000' || res.payload.code === '100') {
        obj.selDate = res.payload.selDate
        const dataList = [].concat(res.payload.mstCompanyCdList)
        const headerList = [].concat(res.payload.mstComCommhList)
        const bodyList = [].concat(res.payload.mstComCommbList)
        // 
        _.forEach(dataList, (r, i) => {
          r.detailButton = ''
        })
        // 
        _.forEach(bodyList, (r, i) => {
          r.commbOwnRate = r.commbCommRate - r.commbCustRate
        })
        // 
        obj[`${type}List`] = dataList
        obj[`${type}CommHeaderList`] = headerList
        obj[`${type}CommBodyList`] = bodyList
      }
      masterAction.setInputMaster({...obj})
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  React.useEffect(() => {
    appAction.setMessage('')
    getData(master.isCode, master.type)
    if (grid) {
      grid.select(-1, -1)
      setTimeout(() => {
        (grid.rows.length > 0)?grid.select(0, 0):grid.select(-1, 0)
      })
    }
  }, [master.type])

  React.useEffect(() => {
    if (grid && grid.collectionView) grid.collectionView.filters.clear()
    _.forEach(filters, (v, k) => {
      grid.collectionView.filters.push((item) => {
        if (v) {
          if (!_.isDate(item[k]))
            return item[k] && item[k].toLowerCase().indexOf(v) > -1;
          else return convert.toDateString(item[k], 'yyyy/MM/dd').indexOf(v) > -1;
        } else return true
        // return v && !_.isDate(item[k])?item[k].toLowerCase().indexOf(v) > -1: true;
      });
    })
    _.forEach(grid && grid.rows, r => {
      if (r.dataItem) r.isSelected = (r.dataItem.isSelected===true)
    })
    masterAction.setInputMaster({listChange: true})
  }, [filters])

  const execDelete = (isCode, type) => {
    const selectList = []
    const isNewSelectList = []
    
    //新規行は強制的に選択状態
    _.forEach(grid.rows, (r, i) => {
      if (r.dataItem && r.dataItem.isAddingNew) {
        r.isSelected = true
      }
    })
    grid.refresh()

    _.forEach(master[`${type}List`].filter(r => r.isSelected), (r, i) =>{
      if (!r.isAddingNew) {
        selectList.push({
          ...r,
        })
      } else isNewSelectList.push({...r})
    })
    if (isNewSelectList.length < 1 && selectList.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return 
    }
    alert().fire({
      icon: MESSAGE.confirmDeleteList().icon,
      title: MESSAGE.confirmDeleteList(master[`${type}List`].filter(r => r.isSelected).length).text
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (selectList.length > 0) {
          const deleteList = []
          _.forEach(selectList, (r, i) => {
            deleteList.push({
              appCode: 'delete',
              comCd: r.comCd,
            })
          })
          appAction.showProcessing({isProcessing: true})
          MasterApi.edit(isCode, type, {
            requestCodeName: type.toUpperCase(),
            isRequestSearch: true,
            search: {},
            companyCdrequestList: deleteList,
            selDate: master.selDate,
          })
          .then((res) => {
            appAction.setMessage({message: res.payload.messageList})
            const obj = {}
            if (res.payload.code === '000') {
              obj.selDate = res.payload.selDate
              const dataList = [].concat(res.payload.mstCompanyCdList)
              const headerList = [].concat(res.payload.mstComCommhList)
              const bodyList = [].concat(res.payload.mstComCommbList)
              // 
              _.forEach(dataList, (r, i) => {
                r.detailButton = ''
              })
              // 
              _.forEach(bodyList, (r, i) => {
                r.commbOwnRate = r.commbCommRate - r.commbCustRate
              })
              obj[`${type}List`] = dataList
              obj[`${type}CommHeaderList`] = headerList
              obj[`${type}CommBodyList`] = bodyList
              masterAction.setInputMaster({...obj})
              // フィルタの初期化
              const filter = {}
              _.forEach(LIST_ITEMS.company, (r, i) => filter[r.id] = '')
              setFilters(filter)
            }
          })
          .catch(() =>{})
          .finally(() => {
            appAction.showProcessing({isProcessing: false})
          })
        } else {
          getData(master.isCode, master.type)
        }
      }
    });
  }
  
  const execSave = (isCode, type) => {
    const err = _.filter(master[`${master.type}List`], (r) => r.hasError)
    const editList = []
    const headerEditList = []
    const bodyEditList = []
    _.forEach(master[`${master.type}List`], (r) => {
      if (r.isAddingNew) editList.push({...r, appCode: 'insert'})
      if (!r.isAddingNew && r.isEdit) editList.push({...r, appCode: 'update'})
    })

    _.forEach(editList,(r, i) => {
      _.forEach(_.filter(LIST_ITEMS.company, 'checkbox'), (record, index) => {
        r[record.id] = r[record.id]?'1':'0'
      })
      _.forEach(_.filter(master.companyCommHeaderList, h => {
        return  r.isAddingNew?h.addRow===r.addRow:h.commhComCd===r.comCd
      }), (h) => {
        headerEditList.push({...h, commhComCd: r.comCd})
      })
      _.forEach(_.filter(master.companyCommBodyList, b => {
        return  r.isAddingNew?b.addRow===r.addRow:b.commbComCd===r.comCd
      }), (b, seq) => {
        bodyEditList.push({...b, commbComCd: r.comCd, commbSeq: seq + 1})
      })
      // commHeaderが存在しない場合は追加
      if (_.filter(headerEditList, h => h.commhComCd === r.comCd).length < 5) {
        [...Array(5).keys()].forEach(i =>
          headerEditList.push({
            commhComCd: r.comCd,
            commhCls: '' + i,
            commhKbn: '0',
            commhRate: 0,
            commhSum: 0,
          })
        )
      }
    })
// console.log(master.companyCommHeaderList)
// console.log(master.companyCommBodyList)
// console.log(headerEditList)
// console.log(bodyEditList)
// return
    if (err.length > 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.hasError().id,
        message: MESSAGE.hasError().text,
        type: MESSAGE.hasError().icon,
      }]})
      return
    }
    if (editList.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.noTarget().id,
        message: MESSAGE.noTarget().text,
        type: MESSAGE.noTarget().icon,
      }]})
      return
    }

    appAction.showProcessing({isProcessing: true})
    MasterApi.edit(isCode, type, {
      requestCodeName: type.toUpperCase(),
      isUpdateIgnore: true,
      isRequestSearch: true,
      search: {},
      companyCdrequestList: editList,
      comCommhrequestList: headerEditList,
      comCommbrequestList: bodyEditList,
      selDate: master.selDate,
    })
    .then((res) => {
      appAction.setMessage({message: res.payload.messageList})
      const obj = {}
      if (res.payload.code === '000') {
        obj.selDate = res.payload.selDate
        const dataList = [].concat(res.payload.mstCompanyCdList)
        const headerList = [].concat(res.payload.mstComCommhList)
        const bodyList = [].concat(res.payload.mstComCommbList)
        // 
        _.forEach(dataList, (r, i) => {
          r.detailButton = ''
        })
        // 
        _.forEach(bodyList, (r, i) => {
          r.commbOwnRate = r.commbCommRate - r.commbCustRate
        })
        obj[`${type}List`] = dataList
        obj[`${type}CommHeaderList`] = headerList
        obj[`${type}CommBodyList`] = bodyList
        masterAction.setInputMaster({...obj})
        // フィルタの初期化
        const filter = {}
        _.forEach(LIST_ITEMS.company, (r, i) => filter[r.id] = '')
        setFilters(filter)
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  const execCsv = (isCode, type) => {
    const csv = []
    _.forEach(master[`${type}List`].filter(r => r.isSelected), (r, i) =>{
        csv.push({
          ...r,
        })
    })
    const upd = _.filter(csv, (r, i) => r.isAddingNew || r.isEdit)
    if (csv.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return
    }
    if (upd.length > 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.editedSelection().id,
        message: MESSAGE.editedSelection().text,
        type: MESSAGE.editedSelection().icon,
      }]})
      return
    }
    appAction.showProcessing({isProcessing: true})
    MasterApi.download(isCode, type, {
      requestCodeName: type.toUpperCase(),
      downloadFileName: `${master.type}.csv`,
      companyCdrequestList: csv,
    }).then((res) => {
      if (res.payload.messageList) {
        appAction.setMessage({message: res.payload.messageList,})
      } else {
        appAction.setMessage('')
        const blob = new Blob([String.fromCharCode(0xFEFF), res.payload], {type: 'text/csv'})
        if (window.navigator.msSaveOrOpenBlob) {
          // for IE,Edge
          window.navigator.msSaveOrOpenBlob(blob, `${master.type}.csv`);
        } else {
          // for chrome, firefox
          const url = URL.createObjectURL(blob);
          const linkEl = document.createElement('a');
          linkEl.href = url;
          linkEl.setAttribute('download', `${master.type}.csv`);
          document.body.appendChild(linkEl);
          linkEl.click();
          URL.revokeObjectURL(url);
          linkEl.parentNode.removeChild(linkEl);
        }
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  const execCopy = () =>{
    const targetList = _.filter(master[`${master.type}List`].filter(r => r.isSelected))
    let target = null
    if (targetList.length === 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return
    } else if (targetList.length === 1) {
      target = targetList[0]
    } else {
      appAction.setMessage({message: [{
        id: MESSAGE.targetMultiSelect().id,
        message: MESSAGE.targetMultiSelect().text,
        type: MESSAGE.targetMultiSelect().icon,
      }]})
      return
    }
    alert().fire({
      icon: MESSAGE.copyComm().icon,
      title: MESSAGE.copyComm().text,
      confirmButtonText: 'はい',
      cancelButtonText: 'いいえ',
      reverseButtons: false,
    })
    .then((result) => {
      const newRow = {
        ...target,
        isAddingNew: true,
        comCd: '',
        addRow: grid.collectionView.items.length,
      }
      if (result.isConfirmed) {
        let targetHeaderList = []
        let targetBodyList = []
        let copyHeaderList =[]
        let copyBodyList =[]
        if (target.isAddingNew) {
          targetHeaderList = master.companyCommHeaderList.filter(r => r.addRow === target.addRow)
          targetBodyList = master.companyCommBodyList.filter(r => r.addRow === target.addRow)
        } else {
          targetHeaderList = master.companyCommHeaderList.filter(r => r.commhComCd === target.comCd)
          targetBodyList = master.companyCommBodyList.filter(r => r.commbComCd === target.comCd)
        }
        _.forEach(targetHeaderList, (r, i) => {
          copyHeaderList.push({
            ...r,
            isAddingNew: true,
            commhComCd: '',
            addRow: grid.collectionView.items.length,
          })
        })
        _.forEach(targetBodyList, (r, i) => {
          copyBodyList.push({
            ...r,
            isAddingNew: true,
            commbComCd: '',
            addRow: grid.collectionView.items.length,
          })
        })
        masterAction.setInputMaster({
          companyCommHeaderList: _.concat(master.companyCommHeaderList, copyHeaderList),
          companyCommBodyList: _.concat(master.companyCommBodyList, copyBodyList),
        })
        
      }
      grid.collectionView.addNew(newRow)
      setTimeout(() => {
        _.forEach(grid.rows, (r, i) => {
          if (r.dataItem) {
            r.dataItem.isSelected = false
          }
        })
        grid.startEditing(false, null, 1)
      })
    })
  }

  const sectionFormatItem = (c, e) => {
    let template='<table><tr>' +
    '<td class="width-5" title="コード">{cd}</td>' +
    '<td class="" title="名称">{namej}</td>' +
    '</tr></table>'
    e.item.innerHTML = wijmo.format(template, e.data);
  }

  const getColumn = () => {
    const column = []
    _.forEach(LIST_ITEMS.company, (r, i) => {
      let obj = {}
      obj.visible = (r.isHeader===true)
      obj.void = !(r.isHeader===true)
      switch (r.id) {
        case 'comKbn':
          r.dataMap = new DataMap(COM_KBN_MAP, 'value', 'name')
          r.editor = new AutoComplete(document.createElement('div'), {
            itemsSource: COM_KBN_MAP,
            isEditable: false,
            minLength: 1,
            delay: 10,
            selectedValuePath: 'value',
            displayMemberPath: 'name'
          })
          break;
        case 'comCommtax':
          r.dataMap = new DataMap(COM_TAX_MAP, 'value', 'name')
          r.editor = new AutoComplete(document.createElement('div'), {
            itemsSource: COM_TAX_MAP,
            isEditable: false,
            minLength: 1,
            delay: 10,
            selectedValuePath: 'value',
            displayMemberPath: 'name'
          })
          break;
        case 'detailButton':
          obj.cellTemplate = CellMaker.makeButton({
              text: r.header,
              click: (e, ctx) => {
                if (!ctx.item) return
                if (ctx.item.hasError) return
                // console.log(ctx)
                masterAction.clearInputComDetail()
                setPopupData(ctx)
                popup.show(true, (sender) => {
                  // if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
                  //   console.log('ok')
                  // } else {
                  //   console.log('cancel')
                  // }
                })
              }
            })
      }
      column.push({...r, ...obj})
    })
    return column
  }

  const getBodyColumn = () => {
    const column = []
    _.forEach(LIST_ITEMS.company, (r, i) => {
      let obj = {}
      obj.visible = (r.isBody===true)
      obj.void = !(r.isBody===true)
      column.push({...r, ...obj})
    })
    return column
  }

  React.useEffect(() => {
    if (bodyGrid) {
      bodyGrid.columnHeaders.rows.defaultSize = 50
    }
  }, [bodyGrid])
  
  React.useEffect(() => {
    if (addRow > -1) {
      grid.collectionView.currentItem.comCd = ''
      grid.collectionView.currentItem.comKbn = '0'
      grid.collectionView.currentItem.comCommtax = '1'
      grid.collectionView.currentItem.addRow = addRow
      
      setAddRow(-1)
    }
  }, [addRow])

  React.useEffect(() => {
    // console.log(deleteRow)
    if (deleteRow > -1) {
      const headerList = master.companyCommHeaderList.filter(v => v.addRow !== deleteRow)
      const bodyList = master.companyCommBodyList.filter(v => v.addRow !== deleteRow)
      masterAction.setInputMaster({
        companyCommHeaderList: headerList,
        companyCommBodyList: bodyList,
      })
      // setDeleteRow(-1)
    }
  }, [deleteRow])

  const gridFormatItem = (g, e) => {
    const item = e.getRow().dataItem
    const bind = e.getColumn().binding
    if (e.getRow() && !item && bind === 'detailButton') {
      e.cell.firstChild.disabled = true
    }
  }

  return (
    <React.Fragment>
      <Card
        root='max-button'
        size='lg'
        hideClose={!props.isModal}
        onClose={props.isModal?props.onClose:null}
        header={TITLE.master[`${master.type}`].header}
        headerClass={props.isModal?'modal-header':''}
        subheader={TITLE.master[`${master.type}`].subheader}
        body={
          <React.Fragment>
            <div className='list-header filter'>
              <div>
                <span className='list-count ml-3'>
                  {master[`${master.type}List`]?master[`${master.type}List`].length:0}
                </span>
                <span>件 抽出しました</span>
                <span className='ml-3'>
                  {grid && grid.collectionView && _.filter(filters, (r) => r && r.length > 0).length > 0
                    ? `${grid.collectionView.totalItemCount}件表示しています`: ''}
                </span>
              </div>
              <div 
                className='d-inline-block'
                style={{width: rowHeaderWidth}} />
              {/* <OverlayTrigger
                placement='right'
                delay={{ show: 0, hide: 400 }}
                overlay={<Tooltip id='refresh-tooltip'>表示内容を破棄し、データを再取得します</Tooltip>}
              >
                <button
                  className='refresh-button wj-btn-glyph'
                  title='データを再取得します'
                  style={{width: rowHeaderWidth}}
                  onClick={(e) => getData(master.type)}
                >
                  <span className="material-icons-outlined">refresh</span>
                </button>
              </OverlayTrigger> */}
              {LIST_ITEMS.company.filter(r => r.isHeader).map((r,i) => {
                return (
                  r.id !== ''?
                    <Common.Text
                      key={i}
                      attrs={{
                        id: i,
                        label: '_none',
                        placeholder: 'search',
                        type: 'search',
                        text: filters[r.id],
                      }}
                      onBlur={(e) => null}
                      onInput={(e) => {
                        const filter = {...filters}
                        filter[r.id] = e.target.value.toLowerCase();
                        setFilters(filter)
                      }}
                      group={{
                        style: {width: colWidth[i]},
                        className: 'mx-0',
                      }}
                    />
                  :<div key={i} className='d-inline-block' style={{width: colWidth[0]}} />
                )
                })}
            </div>
            <div className='master-list-body' >
              <Common.Grid
                thisGrid={grid}
                setGrid={setGrid}
                colDef={getColumn()}
                dataList={master[`${master.type}List`]}
                setRowHeaderWidth={setRowHeaderWidth}
                setColWidth={setColWidth}
                grid={{
                  isReadOnly: props.disabled,
                  allowAddNew: !props.disabled,
                  rowAdded: (g, e) => {setAddRow(e.row)},
                  deletedRow: (g, e) => {setDeleteRow(-1)},
                  deletingRow: (g, e) => {setDeleteRow(e.row)},
                  gridFormatItem: (g, e) => {gridFormatItem(g, e)},
                }}
                setInput={masterAction.setInputMaster}
              />
            </div>
            <CompanyDetailPopup
              headerGrid={headerGrid}
              bodyGrid={bodyGrid}
              setHeaderGrid={setHeaderGrid}
              setBodyGrid={setBodyGrid}
              colDef={getColumn()}
              data={popupData}
              setData={setPopupData}
              popup={popup}
              setPopup={setPopup}
              className='d-none'
              disabled={props.disabled}
            />
          </React.Fragment>
        }
      />
      <Card 
        size='lg'
        root='button'
        hideClose
        header={null}
        subheader={null}
        body={
          <div className='card-buttom-button'>
            <Button
              {...master.delete}
              className='button-warn mt-auto mr-auto'
              onClick={() => execDelete(master.isCode, master.type)}
              disabled={master.delete.disabled || !(grid && grid.rows.length>0) || app.isProcessing || props.disabled}
            >
              {master.delete.label}
            </Button>
            <Button
              {...master.copy}
              className='button-gray mt-auto mx-auto'
              onClick={() => execCopy()}
              disabled={master.copy.disabled || !(grid && grid.rows.length>0) || master.editing || app.isProcessing || props.disabled}
            >
              {master.copy.label}
            </Button>
            <Button
              {...master.csv}
              className='button-gray mt-auto ml-auto'
              onClick={() => execCsv(master.isCode, master.type)}
              disabled={master.csv.disabled || !(grid && grid.rows.length>0) || app.isProcessing || props.disabled}
            >
              {master.csv.label}
            </Button>
            <Button
              {...master.save}
              className='button-blue mt-auto ml-5'
              onClick={() => execSave(master.isCode, master.type)}
              disabled={master.editing || app.isProcessing || props.disabled}
            >
              {master.save.label}
            </Button>
          </div>
        }
      />
    </React.Fragment>
  )
}