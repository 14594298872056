import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { PivotAction } from 'ducks/Pivot';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { CHART_TYPE } from 'constants/pivot';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';
import { SummaryApi } from 'webapi'

export const Step0 = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const pivot = useSelector(state => state.Pivot);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);
  const pivotAction = bindActionCreators(PivotAction, dispatch);

  return (
    <React.Fragment>
      <Card 
        root='popup'
        size='lg'
        hideClose
        headerClass='wj-dialog-header'
        body={
          <React.Fragment>
            <div className='step0 p-5'>
              <div className='step0-title'>
                URI-Pro（クラウド版）をご導入いただきありがとうございます
              </div>
              <div className='step0-body'>
                <div>URI-Pro（クラウド版）のご利用を開始するには、今までご利用いただいていたURI-Proからデータを移行していただく必要がございます。</div>
                <div>『次へ』ボタンをクリックして、画面の指示にしたがって操作を進めてください。</div>
              </div>
              <div className='d-flex'>
                <Button
                  className='button-blue width-20 mx-auto'
                  onClick={props.action}
                  disabled={app.isProcessing}
                >
                  <div className='d-flex flex-row align-items-center justify-content-center'>
                    <div className='d-inline-block'>次へ</div>
                    <div className='material-icons-outlined lg'>arrow_right</div>
                  </div>
                </Button>
              </div>
            </div>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
})