import { handleActions, createAction } from 'redux-actions'
import { onChangeInput, initState, clear } from 'lib'
import _ from 'lodash';

// const
export const SYSTEM = {
  CHANGE_INPUT_BIZ : 'CHANGE_INPUT_BIZ',
  CHANGE_INPUT_SYS : 'CHANGE_INPUT_SYS',
  CHANGE_INPUT_DATA : 'CHANGE_INPUT_DATA',
  CHANGE_INPUT_LOG : 'CHANGE_INPUT_LOG',
  CHANGE_INPUT_USER : 'CHANGE_INPUT_USER',
  SET_INPUT_SYSTEM : 'SET_INPUT_SYSTEM',
  CLEAR_INPUT_SYSTEM : 'CLEAR_INPUT_SYSTEM',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
};

// action
export const SystemAction = {
  changeInputBiz: createAction(SYSTEM.CHANGE_INPUT_BIZ, (args) => (args)),
  changeInputSys: createAction(SYSTEM.CHANGE_INPUT_SYS, (args) => (args)),
  changeInputData: createAction(SYSTEM.CHANGE_INPUT_DATA, (args) => (args)),
  changeInputLog: createAction(SYSTEM.CHANGE_INPUT_LOG, (args) => (args)),
  changeInputUser: createAction(SYSTEM.CHANGE_INPUT_USER, (args) => (args)),
  setInputSystem: createAction(SYSTEM.SET_INPUT_SYSTEM, (args) => (args)),
  clearInputSystem: createAction(SYSTEM.CLEAR_INPUT_SYSTEM),
  initSession: createAction(SYSTEM.INIT_SESSION),
  clearSession: createAction(SYSTEM.CLEAR_SESSION),
}

// initialState
const initialState = {
  type: 'travel',
  biz: {
    // agiAddCty: {
    //   id: 'agiAddCty',
    //   label: '都道府県',
    //   required: false,
    //   disabled: false,
    //   minLength: 0,
    //   maxLength: 4,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: [],
    //   stringcase: '',
    // },
    // agiAddEn1: {
    //   id: 'agiAddEn1',
    //   label: '住所(英字1)',
    //   required: false,
    //   disabled: false,
    //   minLength: 0,
    //   maxLength: 50,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: ['halfWidth'],
    //   stringcase: '',
    // },
    // agiAddEn2: {
    //   id: 'agiAddEn2',
    //   label: '住所(英字2)',
    //   required: false,
    //   disabled: false,
    //   minLength: 0,
    //   maxLength: 50,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: ['halfWidth'],
    //   stringcase: '',
    // },
    // agiAddJp1: {
    //   id: 'agiAddJp1',
    //   label: '住所(漢字1)',
    //   required: false,
    //   disabled: false,
    //   minLength: 0,
    //   maxLength: 25,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: [],
    //   stringcase: '',
    // },
    // agiAddJp2: {
    //   id: 'agiAddJp2',
    //   label: '住所(漢字2)',
    //   required: false,
    //   disabled: false,
    //   minLength: 0,
    //   maxLength: 25,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: [],
    //   stringcase: '',
    // },
    // agiFaxNum: {
    //   id: 'agiFaxNum',
    //   label: 'FAX番号',
    //   required: false,
    //   disabled: false,
    //   minLength: 0,
    //   maxLength: 15,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: ['tel'],
    //   stringcase: '',
    // },
    agiNameEn: {
      id: 'agiNameEn',
      label: '旅行会社名(英字)',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 50,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['halfWidth'],
      stringcase: '',
    },
    agiNameJp: {
      id: 'agiNameJp',
      label: '旅行会社名',
      required: true,
      disabled: false,
      minLength: 0,
      maxLength: 25,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    agiShpNameEn: {
      id: 'agiShpNameEn',
      label: '店舗名(英字)',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 25,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['halfWidth'],
      stringcase: '',
    },
    agiShpNameJp: {
      id: 'agiShpNameJp',
      label: '店舗名',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 15,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    // agiTelNum: {
    //   id: 'agiTelNum',
    //   label: '電話番号',
    //   required: true,
    //   disabled: false,
    //   minLength: 0,
    //   maxLength: 15,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: ['tel'],
    //   stringcase: '',
    // },
    // agiZipCode: {
    //   id: 'agiZipCode',
    //   label: '郵便番号',
    //   required: false,
    //   disabled: false,
    //   mask: '000-0000',
    //   minLength: 0,
    //   maxLength: 8,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: ['zipCode'],
    //   stringcase: '',
    // },
    // agtIataCode: {
    //   id: 'agtIataCode',
    //   label: 'IATAコード',
    //   prefix: '163',
    //   suffix: '-',
    //   required: false,
    //   disabled: false,
    //   minLength: 0,
    //   maxLength: 4,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: [],
    //   stringcase: '',
    // },
    // agtIataCd: {
    //   id: 'agtIataCd',
    //   label: '',
    //   required: false,
    //   disabled: false,
    //   minLength: 0,
    //   maxLength: 1,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: [],
    //   stringcase: '',
    // },
    agtLogFile: {
      id: 'agtLogFile',
      label: 'ロゴファイル',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: -1,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    // sysIataFlg: {
    //   id: 'sysIataFlg',
    //   label: '',
    //   required: false,
    //   disabled: false,
    //   minLength: 0,
    //   maxLength: -1,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: [],
    //   stringcase: '',
    // },
    // sysPrintNam: {
    //   id: 'sysPrintNam',
    //   label: '',
    //   required: false,
    //   disabled: false,
    //   minLength: 0,
    //   maxLength: -1,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: [],
    //   stringcase: '',
    // },
    // sysTktFormat: {
    //   id: 'sysTktFormat',
    //   label: '',
    //   required: false,
    //   disabled: false,
    //   minLength: 0,
    //   maxLength: -1,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: [],
    //   stringcase: '',
    // },
    // sysTktRegcnt: {
    //   id: 'sysTktRegcnt',
    //   label: '',
    //   required: false,
    //   disabled: false,
    //   minLength: 0,
    //   maxLength: -1,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: [],
    //   stringcase: '',
    // },
    agiMailAdd: {
      id: 'agiMailAdd',
      label: 'システム通知用メールアドレス（カンマ区切り）',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 1024,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: ['mail'],
      stringcase: '',
    },
  },
  sys: {
    searchResultsDispLimit: {
      id: 'searchResultsDispLimit',
      label: '検索結果表示件数',
      required: false,
      disabled: false,
      type: 'number',
      number: true,
      min: 1,
      max: 100000,
      minLength: 0,
      maxLength: 7,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    sysSwitchYear: {
      id: 'sysSwitchYear',
      label: '適用開始日(搭乗日)',
      required: false,
      disabled: false,
      date: true,
      isRequired: false,
      type: 'date1',
      format: 'yyyy/MM/dd',
      mask: '9999/99/99',
      minLength: 0,
      maxLength: 10,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    sysBaseDateCur1: {
      id: 'sysBaseDateCur1',
      label: '基準日(1)',
      required: false,
      disabled: false,
      mmdd: true,
      isRequired: false,
      type: 'mmdd',
      format: 'MM/dd',
      mask: '99/99',
      minLength: 0,
      maxLength: 10,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    sysBaseDateCur2: {
      id: 'sysBaseDateCur2',
      label: '基準日(2)',
      required: false,
      disabled: false,
      mmdd: true,
      isRequired: false,
      type: 'mmdd',
      format: 'MM/dd',
      mask: '99/99',
      minLength: 0,
      maxLength: 10,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    sysBaseDatePrev1: {
      id: 'sysBaseDatePrev1',
      label: 'prev基準日(1)',
      required: false,
      disabled: false,
      mmdd: true,
      isRequired: false,
      type: 'mmdd',
      format: 'MM/dd',
      mask: '99/99',
      minLength: 0,
      maxLength: 10,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    sysBaseDatePrev2: {
      id: 'sysBaseDatePrev2',
      label: 'prev基準日(2)',
      required: false,
      disabled: false,
      mmdd: true,
      isRequired: false,
      type: 'mmdd',
      format: 'MM/dd',
      mask: '99/99',
      minLength: 0,
      maxLength: 10,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    // sysCommisonChangeDate: {
    //   id: 'sysCommisonChangeDate',
    //   label: 'COMM額算出切替日',
    //   required: false,
    //   disabled: false,
    //   date: true,
    //   minLength: 0,
    //   maxLength: 10,
    //   original: '',
    //   value: '',
    //   text: '',
    //   dirty: '',
    //   isInvalid: false,
    //   helpertext: '',
    //   validtype: [],
    //   stringcase: '',
    // },
    sysFareJudg: {
      id: 'sysFareJudg',
      label: '_none',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 10,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    sysWaiverCodeRegistrCol: {
      id: 'sysWaiverCodeRegistrCol',
      label: '_none',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 10,
      original: '',
      value: '1',
      text: '1',
      dirty: '1',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
  },
  log: {
    logOutputFrom: {
      id: 'logOutputFrom',
      label: '対象日',
      required: false,
      disabled: false,
      date: true,
      isRequired: false,
      type: 'date1',
      format: 'yyyy/MM/dd',
      mask: '9999/99/99',
      minLength: 0,
      maxLength: 10,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    logOutputTo: {
      id: 'logOutputTo',
      label: '',
      required: false,
      disabled: false,
      date: true,
      isRequired: false,
      type: 'date1',
      format: 'yyyy/MM/dd',
      mask: '9999/99/99',
      minLength: 0,
      maxLength: 10,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
  },
  data: {
    dmiPeriod: {
      id: 'dmiPeriod',
      label: '_none',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 10,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    dmiPeriodMm: {
      id: 'dmiPeriodMm',
      label: '_none',
      required: false,
      disabled: false,
      type: 'number',
      number: true,
      min: 1,
      max: 99,
      minLength: 0,
      maxLength: 7,
      isRequired: false,
      minLength: 0,
      maxLength: 2,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    dmiPeriodDate: {
      id: 'dmiPeriodDate',
      label: '_none',
      required: false,
      disabled: false,
      date: true,
      isRequired: false,
      type: 'date1-lg',
      format: 'yyyy/MM/dd',
      mask: '9999/99/99',
      minLength: 0,
      maxLength: 10,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    dmiDateType: {
      id: 'dmiDateType',
      label: '_none',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 10,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    dmiOpentktType: {
      id: 'dmiOpentktType',
      label: '_none',
      required: false,
      disabled: false,
      minLength: 0,
      maxLength: 10,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
      validtype: [],
      stringcase: '',
    },
    fltBoardDate: '',
    tktIsuDate: '',
    tktDepDate: '',
  },

  cancel: {
    id: 'cancel',
    label: <span><span className='material-icons-outlined md'>cancel</span>キャンセル</span>,
    disabled: false,
  },
  save: {
    id: 'save',
    label: <span><span className='material-icons-outlined md'>file_upload</span>登録</span>,
    disabled: false,
  },
  run: {
    id: 'run',
    label: <span><span className='material-icons-outlined md'>file_upload</span>実行</span>,
    disabled: false,
  },
  setting: {
    id: 'setting',
    label: <span><span className='material-icons-outlined md'>file_upload</span>設定</span>,
    disabled: false,
  },
  fileRef: {
    id: 'fileRef',
    label: '参照',
    disabled: false,
  },
  fileDelete: {
    id: 'fileDelete',
    label: '削除',
    disabled: false,
  },
  usrDelete: {
    id: 'usrDelete',
    label: '削除',
    disabled: false,
  },
  termDelete: {
    id: 'termDelete',
    label: 'Plusクライアント 端末情報削除',
    disabled: false,
  },
  download: {
    id: 'download',
    label: 'ダウンロード',
    disabled: false,
  },
}
// reducer
export const SystemReducer = handleActions({
  [SystemAction.changeInputBiz]: (state, action) => ({...state, biz: onChangeInput(state.biz, action.payload)}),
  [SystemAction.changeInputSys]: (state, action) => ({...state, sys: onChangeInput(state.sys, action.payload)}),
  [SystemAction.changeInputData]: (state, action) => ({...state, data: onChangeInput(state.data, action.payload)}),
  [SystemAction.changeInputLog]: (state, action) => ({...state, log: onChangeInput(state.log, action.payload)}),
  [SystemAction.changeInputUser]: (state, action) => ({...state, user: onChangeInput(state.user, action.payload)}),
  [SystemAction.setInputSystem]: (state, action) => ({...state, ...action.payload,}),
  [SystemAction.clearInputSystem]: (state, action) => (clear(initialState, state)),
  [SystemAction.initSession]: (state, action) => (initState(initialState)),
  [SystemAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)
