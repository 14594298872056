import { axiosInstance } from 'http/index'

export const SummaryApi = {
  init: (data) => {
    const instance = axiosInstance;
    return instance.post('/summary/init', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  save: (data) => {
    const instance = axiosInstance;
    return instance.post('/summary/registReport', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  delete: (data) => {
    const instance = axiosInstance;
    return instance.post('/summary/deleteReport', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
}
