import _ from 'lodash';
import { store } from 'store'
import { LoginAction } from 'ducks/Login';
import { alert, convert } from "lib";
import { MasterApi, MainApi, ProcessApi } from 'webapi'

export const getInitData = (appAction, isForce, loginAction, login, app, location) => {
  let loginCd = ''
  let office = ''
  let mbrCd = ''
  let member = {}
  let termProList = {}
  let tktCount = 0
  let tktCountLimit = 0

  appAction.showInitProcessing({isInitProcessing: true})
  
  const isForceGet = isForce || location && (location.pathname.startsWith('/tkt/edit') || location.pathname.endsWith('/search'))
  const isGetAlAndCity = isForceGet || !app.alList
  const isGetMaster = isForceGet || !app.areaList
  const isGetSystem = isForceGet || !app.bizList
  MasterApi.all({isGetAlAndCity: isGetAlAndCity, isGetMaster: isGetMaster, isGetSystem: isGetSystem})
  .then(res => {
    if (res.payload.code = '000') {
      if (isGetAlAndCity) {
        _.forEach(res.payload.alList.mstAirlineCdList, (r, i) =>{
          // r.listName = `${r.alnCd2}(${r.alnCdn})`
          r.listName = `${r.alnCd2}`
        })
        _.forEach(res.payload.cityList.mstCityAirportList, (r, i) =>{
          // r.listName = `${r.cityCd}(${r.cityNamee})`
          r.listName = `${r.cityCd}`
        })
      }
      if (isGetMaster) {
        _.forEach(res.payload.areaList.masterCodeList, (r, i) =>{
          // r.listName = `${r.cd}(${r.namej})`
          r.listName = `${r.cd}`
          r.areaCd = r.cd
        })
        _.forEach(res.payload.eoList.masterCodeList, (r, i) =>{
          // r.listName = `${r.cd}(${r.namej})`
          r.listName = `${r.cd}`
          r.eoCd = r.cd
        })
        _.forEach(res.payload.freeList.masterCodeList, (r, i) =>{
          // r.listName = `${r.cd}(${r.namej})`
          r.listName = `${r.cd}`
          r.freeCd = r.cd
        })
        _.forEach(res.payload.kb1List.masterCodeList, (r, i) =>{
          // r.listName = `${r.cd}(${r.namej})`
          r.listName = `${r.cd}`
          r.kb1Cd = r.cd
        })
        _.forEach(res.payload.kb2List.masterCodeList, (r, i) =>{
          // r.listName = `${r.cd}(${r.namej})`
          r.listName = `${r.cd}`
          r.kb2Cd = r.cd
        })
        _.forEach(res.payload.orderList.masterCodeList, (r, i) =>{
          // r.listName = `${r.cd}(${r.namej})`
          r.listName = `${r.cd}`
          r.orderCd = r.cd
        })
        _.forEach(res.payload.payList.masterCodeList, (r, i) =>{
          // r.listName = `${r.cd}(${r.namej})`
          r.listName = `${r.cd}`
          r.payCd = r.cd
        })
        _.forEach(res.payload.sectionList.masterCodeList, (r, i) =>{
          // r.listName = `${r.cd}(${r.namej})`
          r.listName = `${r.cd}`
          r.sectionCd = r.cd
        })
        _.forEach(res.payload.tourList.masterCodeList, (r, i) =>{
          // r.listName = `${r.cd}(${r.namej})`
          r.listName = `${r.cd}`
          r.tourCd = r.cd
        })
        _.forEach(res.payload.companyList.mstCompanyCdList, (r, i) =>{
          // r.listName = `${r.comCd}(${r.comNamej})`
          r.listName = `${r.comCd}`
          r.companyCd = r.comCd
          r.cd = r.comCd
          r.namej = r.comNamej
        })
      }
      _.forEach(res.payload.memberList.mstMemberCdList, (r, i) =>{
        // r.listName = `${r.mbrCd}(${r.mbrNamej})`
        r.listName = `${r.mbrCd}`
        r.memberCd = r.mbrCd
        r.cd = r.mbrCd
        r.namej = r.mbrNamej
      })
      _.forEach(res.payload.allMemberList.mstMemberCdList, (r, i) =>{
        // r.listName = `${r.mbrCd}(${r.mbrNamej})`
        r.listName = `${r.mbrCd}`
        r.memberCd = r.mbrCd
        r.cd = r.mbrCd
        r.namej = r.mbrNamej
      })
      // const _mbrCd = mbrCd?mbrCd:store.getState().Login.mbrCd
      // console.log(_mbrCd)
      // console.log(_.filter(res.payload.memberList.mstMemberCdList, r => r.mbrCd === _mbrCd))
      let memberList = null
      if (login.mntOffice && login.mntOffice !== login.office) {
        // 保守員
        memberList = res.payload.allMemberList.mstMemberCdList
        if (_.filter(memberList, r => r.mbrAuth === '1').length > 0) {
          member = _.filter(memberList, r => r.mbrAuth === '1')[0]
        }
      } else {
        memberList = res.payload.memberList.mstMemberCdList
        if (_.filter(memberList, r => r.mbrCd === res.payload.mbrCd).length > 0) {
          member = _.filter(memberList, r => r.mbrCd === res.payload.mbrCd)[0]
        }
      }
      // store.dispatch(LoginAction.setInputLogin({member: member}))

      loginCd = res.payload.loginCd
      office = res.payload.mntOfficeId?res.payload.mntOfficeId:res.payload.officeId
      mbrCd = res.payload.mbrCd
      loginAction.setInputLogin({
        loginCd: res.payload.loginCd,
        office: office,
        mbrCd: res.payload.mbrCd,
        member: member,
      })
      
      const dispLimitObj = isGetSystem?_.find(res.payload.sysList.sysSystemIniList, r => r.sysItemId === 'SEARCH_RESULTS_DISP_LIMIT'):app.dispLimit
      termProList = res.payload.termProList
      appAction.setMasterData({
        isInitData: true,
        alList: isGetAlAndCity?res.payload.alList.mstAirlineCdList:app.alList,
        cityList: isGetAlAndCity?res.payload.cityList.mstCityAirportList:app.cityList,
        areaList: isGetMaster?res.payload.areaList.masterCodeList:app.areaList,
        eoList: isGetMaster?res.payload.eoList.masterCodeList:app.eoList,
        freeList: isGetMaster?res.payload.freeList.masterCodeList:app.freeList,
        kb1List: isGetMaster?res.payload.kb1List.masterCodeList:app.kb1List,
        kb2List: isGetMaster?res.payload.kb2List.masterCodeList:app.kb2List,
        orderList: isGetMaster?res.payload.orderList.masterCodeList:app.orderList,
        payList: isGetMaster?res.payload.payList.masterCodeList:app.payList,
        sectionList: isGetMaster?res.payload.sectionList.masterCodeList:app.sectionList,
        tourList: isGetMaster?res.payload.tourList.masterCodeList:app.tourList,
        companyList: isGetMaster?res.payload.companyList.mstCompanyCdList:app.companyList,
        memberList: memberList,
        termProList: res.payload.termProList,
        officeList: res.payload.officeList,
        isStaff: res.payload.staff,
        bizList: isGetSystem?res.payload.bizList.sysBizIniList:app.bizList,
        sysList: isGetSystem?res.payload.sysList.sysSystemIniList:app.sysList,
        dispLimit: dispLimitObj?_.toNumber(dispLimitObj.sysItemValue):null,
      })
    }
  }).catch(() =>{
  }).finally(() => {
    MainApi.tktCount()
    .then(res => {
      if (res.payload && res.payload.code === '000') {
        tktCount = res.payload.tktCount?_.toNumber(res.payload.tktCount):0
        tktCountLimit = res.payload.tktCountLimit?_.toNumber(res.payload.tktCountLimit):0
        appAction.setInputApp({
          tktCount: tktCount,
          tktCountLimit: tktCountLimit,
          lastTktRcvDate: res.payload.lastTktRcvDate?convert.toDateTimeString(res.payload.lastTktRcvDate, 'yyyy/MM/dd HH:mm'):'',
        })
      }
    }).catch(err => {
    }).finally(() => {
      // --------------------------------------担当者--上限--端プロ
      // ----auth010:発券データ詳細----------------------×----
      // ----auth020:発券データ検索ＣＳＶ------com01-------- 
      // ----auth300:発券データ入出力--------------------×
      // ----auth030:発券データ入力----------------------×
      // ----auth031:----タイマー-----------------------------function_uripro-timer-input_tkt
      // ----auth032:----クライアントＡＰ連携------------------function_uripro-timer-input_tkt-client_ap
      // ----auth040:発券データ出力------------com02---- ×
      // ----auth041:----タイマー----------------------- ----function_uripro-timer-output_tkt
      // ----auth042:----クライアントＡＰ連携------------------function_uripro-timer-output_tkt-client_ap
      // ----auth050:ＥＸＣＥＬ----------------com03---- ×
      // ----auth051:----タイマー------------------------ ----function_uripro-timer-output_excel
      // ----auth052:----クライアントＡＰ連携------------------function_uripro-timer-output_excel-client_ap
      // ----auth060:BI------------------------com04---- ×
      // ----auth070:固定帳票----------------------------×
      // ----auth071:----タイマー-----------------------------function_uripro-timer-fixed_form
      // ----auth072:----クライアントＡＰ連携------------------function_uripro-timer-fixed_form-client_ap
      // ----auth080:メンテ(担当者)------------com08--------
      // ----auth090:メンテ(その他)------------com09--------
      // ----auth100:タイマー------------------com10--------
      // ----auth110:システム------------------com11--------
      // ----auth120:ＰＲＩＳＭ----------------com14---- ×----function_prism
      // ----authIfn:利用可能GDS – INFINI----------------------gds_infini
      // ----authTrp:利用可能GDS – TRAVELPORT------------------gds_travelport
      // ----authSbr:利用可能GDS – SABRE ----------------------gds_sabre
      // ----authFtp:SFTPファイル共有--------------------------function_sftp_file
      // ----authCli:PLUSクライアント--------------------------auth032 || auth042 || auth052 || auth072
      // console.log('----------auth----------')
      // console.log(mbrCd)
      // console.log(member)
      // console.log(tktCountLimit)
      // console.log(tktCount)
      // console.log(termProList)
      const limit = (tktCount > tktCountLimit)
      // console.log(limit)
      if (termProList && termProList.length > 0) {
        loginAction.setInputLogin({
          auth010: limit?'0':'1',
          auth020: member.mbrCom01,
          auth300: limit?'0':'1',
          auth030: limit?'0':'1',
          auth031: _.find(termProList, r => r.proItemId==='function_uripro-timer-input_tkt').proItemValue,
          auth032: _.find(termProList, r => r.proItemId==='function_uripro-timer-input_tkt-client_ap').proItemValue,
          auth040: limit?'0':member.mbrCom02,
          auth041: _.find(termProList, r => r.proItemId==='function_uripro-timer-output_tkt').proItemValue,
          auth042: _.find(termProList, r => r.proItemId==='function_uripro-timer-output_tkt-client_ap').proItemValue,
          auth050: limit?'0':member.mbrCom03,
          auth051: _.find(termProList, r => r.proItemId==='function_uripro-timer-output_excel').proItemValue,
          auth052: _.find(termProList, r => r.proItemId==='function_uripro-timer-output_excel-client_ap').proItemValue,
          auth060: limit?'0':member.mbrCom04,
          auth070: limit?'0':'1',
          auth071: _.find(termProList, r => r.proItemId==='function_uripro-timer-fixed_form').proItemValue,
          auth072: _.find(termProList, r => r.proItemId==='function_uripro-timer-fixed_form-client_ap').proItemValue,
          auth080: member.mbrCom08,
          auth090: member.mbrCom09,
          auth100: member.mbrCom10,
          auth110: member.mbrCom11,
          auth120: limit?'0':member.mbrCom14==='0'?'0':_.find(termProList, r => r.proItemId==='function_prism').proItemValue,
          authIfn: _.find(termProList, r => r.proItemId==='gds_infini').proItemValue,
          authTrp: _.find(termProList, r => r.proItemId==='gds_travelport').proItemValue,
          authSbr: _.find(termProList, r => r.proItemId==='gds_sabre').proItemValue,
          authFtp: _.find(termProList, r => r.proItemId==='function_sftp_file').proItemValue,
          authCli: _.find(termProList, r => r.proItemId==='function_uripro-timer-input_tkt-client_ap').proItemValue==='1'
            || _.find(termProList, r => r.proItemId==='function_uripro-timer-output_tkt-client_ap').proItemValue==='1'
            || _.find(termProList, r => r.proItemId==='function_uripro-timer-output_excel-client_ap').proItemValue==='1'
            || _.find(termProList, r => r.proItemId==='function_uripro-timer-fixed_form-client_ap').proItemValue==='1'?'1':'0',
          migration: _.find(termProList, r => r.proItemId==='data_transfer_flag')?_.find(termProList, r => r.proItemId==='data_transfer_flag').proItemValue:'0',
          // member: member,
        })
        appAction.setInputApp({
          isHeaderShow: !window.name.startsWith('_tktEdit'),
          isFooterShow: true,
        })
      }
    })

    ProcessApi.getList({field: '1'})
    .then(res => {
      if (res.payload && res.payload.bizProcessingResultDtoList) {
        appAction.setBatchResult({processingList: res.payload.bizProcessingResultDtoList})
      }
    })
    .catch(() =>{})
    .finally(() => {})
    appAction.showInitProcessing({isInitProcessing: false})
  })

}
