import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { PrismAction } from 'ducks/Prism';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert, dataMapper } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import * as wijmo from '@grapecity/wijmo';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER, TOOL_TIP } from 'constants/search';
import { PRINT_MESSAGE, ACCOUNT_CODE_TYPE, ACCOUNT_CODE_TYPE_BLANK, SPECIFIED, RANGE } from 'constants/prism';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { PrismApi } from 'webapi'

export const PrismComponent = () => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const prism = useSelector(state => state.Prism);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const prismAction = bindActionCreators(PrismAction, dispatch);

  const [procPopup, setProcPopup] = React.useState(null)
  const [grid, setGrid] = React.useState(null)
  // 
  const [dateRadio, setDateRadio] = React.useState('0')
  const [dateChange, setDateChange] = React.useState(false)
  const [period, setPeriod] = React.useState({
    1: {text: '0', disabled: true},
    2: {text: '0', disabled: true},
    3: {text: '0', disabled: true},
    4: {text: '0', disabled: true},
  })
  const [periodChange, setPeriodChange] = React.useState(false)
  const [periodStart, setPeriodStart] = React.useState(-1)
  const [periodEnd, setPeriodEnd] = React.useState(-1)
  const [dateNumber, setDateNumber] = React.useState({
    id: 'dateNumber',
    label: '_none',
    suffix: '日前',
    min: 0,
    max: 10,
    step: 1,
    maxLength: 2,
    value: 0,
    text: 0,
    disabled: false,
  })
  const [selectedOrders, setSelectedOrders] = React.useState([])

  const [fromToCheck, setFromToCheck] = React.useState(false)
  const [dateClear, setDateClear] = React.useState(false)
  
  // 初期化
  React.useEffect(() => {
    const objNumber = []
    const obj = []
    setDateRadio('0')
    setPeriod({
      1: {text: '0', disabled: true},
      2: {text: '0', disabled: true},
      3: {text: '0', disabled: true},
      4: {text: '0', disabled: true},
    })
    setDateNumber({
      ...dateNumber,
      original: null,
      value: null,
      text: null,
      disabled: false,
    })
  }, [])

  // xx日前を変更
  React.useEffect(() => {
    const fromDate = prism.prismSearchFrom
    const toDate = prism.prismSearchTo
    const number = dateNumber
    let dt = wijmo.DateTime
    let today = dt.newDate();
    let start = dt.addDays(today, -number.text)
    start.setHours(0,0,0,0)
    today.setHours(23,59,59,999)
    // console.log(dateNumber)
    // console.log(dateNumber.text)
    // console.log(dateNumber.text)
    if (!number.disabled) {
      prismAction.changeInputPrism(convert.getTarget(fromDate, 'text', start))
      setTimeout(() => {
        prismAction.changeInputPrism(convert.getTarget(toDate, 'text', today))
      })
    }
  }, [dateNumber.text])

  React.useEffect(() => {
    const fromDate = prism.prismSearchFrom
    const toDate = prism.prismSearchTo
    let dt = wijmo.DateTime, now = new Date();
    let start = dt.newDate();
    let end = dt.newDate();

    switch (dateRadio) {
      case '0':
        setDateChange(true)
        break;
      case '1':
        start = dt.monthFirst(dt.addMonths(now, -1))
        end = dt.monthLast(dt.addMonths(now, -1))
        prismAction.changeInputPrism(convert.getTarget(fromDate, 'text', start))
        setTimeout(() => {
          prismAction.changeInputPrism(convert.getTarget(toDate, 'text', end))
          setDateChange(true)
        })
        break;
      case '2':
        start = dt.monthFirst(now)
        end = dt.monthLast(now)
        prismAction.changeInputPrism(convert.getTarget(fromDate, 'text', start))
        setTimeout(() => {
          prismAction.changeInputPrism(convert.getTarget(toDate, 'text', end))
          setDateChange(true)
        })
        break;
      case '3':
        start = dt.addDays(now, -1)
        start.setHours(0,0,0,0)
        end = dt.addDays(now, -1)
        end.setHours(23,59,59,999)
        prismAction.changeInputPrism(convert.getTarget(fromDate, 'text', start))
        setTimeout(() => {
          prismAction.changeInputPrism(convert.getTarget(toDate, 'text', end))
          setDateChange(true)
        })
        break;
      case '4':
        start = dt.addDays(now, 0)
        start.setHours(0,0,0,0)
        end = dt.addDays(now, 0)
        end.setHours(23,59,59,999)
        prismAction.changeInputPrism(convert.getTarget(fromDate, 'text', start))
        setTimeout(() => {
          prismAction.changeInputPrism(convert.getTarget(toDate, 'text', end))
          setDateChange(true)
        })
        break;
    }
  }, [dateRadio])

  React.useEffect(() => {
    if (dateChange) {
      const obj ={}
      obj.prismSearchFrom ={
        ...prism.prismSearchFrom,
        disabled: (dateRadio !== '0')
      }
      obj.prismSearchTo ={
        ...prism.prismSearchTo,
        disabled: (dateRadio !== '0')
      }
      prismAction.setInputPrism({...obj})
      
      setDateNumber({
        ...dateNumber,
        disabled: (dateRadio !== '0')
      })

      let checkObj = {}
      _.forEach(period, (v, k) => {
        checkObj[k] = {
          ...v,
          disabled: (dateRadio !== '1' && dateRadio !== '2')
        }
        if (dateRadio !== '1' && dateRadio !== '2') {
          checkObj[k].text = '0'
        }
      })
      setPeriod({...checkObj})

      setDateChange(false)
    }
  }, [dateChange])

  React.useEffect(() => {
    if (!periodChange && (dateRadio === '1' || dateRadio === '2') ) {
      let start = -1
      let end = -1
      let checkObj = {}
      _.forEach(period, (v, k) => {
        if (v.text === '1') {
          if (start < 0) {
            start = k
            end = k
          }
          else end = k
        }
      })
      _.forEach(period, (v, k) => {
        checkObj[k] = {
          ...v,
          text: (k >= start && k <= end)?'1':'0'
        }
      })
      setPeriod({...checkObj})
      setPeriodChange(true)
      setPeriodStart(start)
      setPeriodEnd(end)

    }
  }, [period])

  React.useEffect(() => {
    const fromDate = prism.prismSearchFrom
    const toDate = prism.prismSearchTo
    let dt = wijmo.DateTime, now = new Date();
    // 1P:1-7, 2P:8-15, 3P:16-23, 4P:24-99
    let p1s = dt.monthFirst(dt.addMonths(now, dateRadio==='1'?-1:0))
    let p1e = dt.addDays(p1s, 6)
    let p2s = dt.addDays(p1e, 1)
    let p2e = dt.addDays(p2s, 7)
    let p3s = dt.addDays(p2e, 1)
    let p3e = dt.addDays(p3s, 7)
    let p4s = dt.addDays(p3e, 1)
    let p4e = dt.monthLast(dt.addMonths(now, dateRadio==='1'?-1:0))
    p1s.setHours(0,0,0,0)
    p2s.setHours(0,0,0,0)
    p3s.setHours(0,0,0,0)
    p4s.setHours(0,0,0,0)
    p1e.setHours(23,59,59,999)
    p2e.setHours(23,59,59,999)
    p3e.setHours(23,59,59,999)
    p4e.setHours(23,59,59,999)
    let start = (dateRadio==='1' || dateRadio==='2')?p1s:null
    let end = (dateRadio==='1' || dateRadio==='2')?p4e:null
    switch (periodStart) {
      case '1':
        start = p1s
        break;
      case '2':
        start = p2s
        break;
      case '3':
        start = p3s
        break;
      case '4':
        start = p4s
        break;
    }
    switch (periodEnd) {
      case '1':
        end = p1e
        break;
      case '2':
        end = p2e
        break;
      case '3':
        end = p3e
        break;
      case '4':
        end = p4e
        break;
    }
    prismAction.changeInputPrism(convert.getTarget(fromDate, 'text', start))
    setTimeout(() => {
      prismAction.changeInputPrism(convert.getTarget(toDate, 'text', end))
    })
    setPeriodChange(false)
  }, [periodChange])

  // 
  React.useEffect(() => {
    const caller = (location.state && location.state.caller) ?location.state.caller: ''
      // appAction.showProcessing({isProcessing: true})
    appAction.setMessage('')
    appAction.showProcessing({isProcessing: true})
    PrismApi.init({execType: '0'})
    .then(res => {
      // console.log(res)
      appAction.setBatchResult({processingSeq: ''})
      const obj = {}
      let arrayCust = []
      
      _.forEach(res.payload, (v, k) => {
        _.forEach(prism, (o, id) => {
          if (id === k) {
            obj[id] = {
              ...prism[id],
              original: v,
              value: v,
              text: v,
            }
          }
        })
        if (k === 'prismSearchPeriod' && v && v.length === 4) {
          setPeriod({
            1: {text: v.substring(0,1), disabled: true},
            2: {text: v.substring(1,2), disabled: true},
            3: {text: v.substring(2,3), disabled: true},
            4: {text: v.substring(3,4), disabled: true},
          })
        }
        if (k === 'prismCustomer' || k === 'prismInvoice') {
          
          obj[k] = {
            ...prism[k],
            original: v?v + '':'0',
            value: v?v + '':'0',
            text: v?v + '':'0',
          }
        }
        if (k === 'prismSearchTerm') setDateRadio(v)
        if (k === 'prismSearchCodes') {
          if (res.payload.prismRange === '1') {
            const text = v && v.split(',')
            if (text && text.length > 0) {
              obj.customerFrom = {
                ...prism.customerFrom,
                original: text[0],
                value: text[0],
                text: text[0],
              }
            }
            if (text && text.length > 1) {
              obj.customerTo = {
                ...prism.customerFrom,
                original: text[1],
                value: text[1],
                text: text[1],
              }
            }
          }
          if (res.payload.prismRange === '0') {
            const text = v && v.split(',')
            // obj.prismSearchCodes = {
            //   ...prism.prismSearchCodes,
            //   original: text,
            //   value: text,
            //   text: text,
            // }

            // console.log(text)
            const customer = getcustomer(obj.prismCustomer.text)
            _.forEach(text, (record, index) => {
              if (_.filter(customer.list, r => r[customer.selectedValuePath] === record) == 0) {
                let item ={}
                item.cd = record
                item[customer.selectedValuePath] = record
                item.listName = record
                arrayCust = arrayCust.concat([item])
              } else {
                arrayCust = arrayCust.concat(_.filter(customer.list, r => r[customer.selectedValuePath] === record))
              }
            })
            setTimeout(() => {
              setSelectedOrders(arrayCust)
            })
          }
        }
      })

      // console.log(obj)
      prismAction.setInputPrism(obj)
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }, [])

  const execDownload = () => {

    // if (!prism.searchSql) {
    //   appAction.setMessage({message: [{id: MESSAGE.noSearch().icon, message: MESSAGE.noSearch().text, type: MESSAGE.noSearch().icon}]})
    //   return
    // }
    // if (!prism.tktOutFileName.text) {
    //   appAction.setMessage({message: [{id: MESSAGE.noFileName().icon, message: MESSAGE.noFileName().text, type: MESSAGE.noFileName().icon}]})
    //   return
    // }
    appAction.setMessage('')

    let entry ={}
    let hasError = false
    _.forEach(prism, (v, k) => {
      if (v && !_.isUndefined(v.value)) {
        entry[k] = entryCheck(prism, convert.getTarget(prism[k], 'text', prism[k].text))[k]
        hasError = hasError || entry[k].isInvalid
        // if (entry[k].isInvalid) console.log(k)
      }
    })
    prismAction.setInputPrism(entry)
    if (hasError) return

    const dto = {
      isTimerAccess: false,
      bizPrismIniStringDto: {
        prismSearchTerm: dateRadio,
        prismSearchPeriod: period[1].text + period[2].text + period[3].text + period[4].text,
        cmnMbrcd: login.loginCd,
      }
    }

    _.forEach(prism, (v, k) => {
      if (!_.isUndefined(v.value)) dto.bizPrismIniStringDto[k] = v.text
      if (v.type === 'date') dto.bizPrismIniStringDto[k] = convert.toDateString(v.text, 'yyyy/MM/dd')
      if (k === 'prismSearchCodes') {
        if (prism.prismSpecified.text === '2') {
          dto.bizPrismIniStringDto[k] = []
        } else if (prism.prismRange.text === '1') {
          dto.bizPrismIniStringDto[k] = [prism.customerFrom.text, prism.customerTo.text]
        } else {
          const custArray = []
          const customer = getcustomer(prism.prismCustomer.text)
          _.forEach(selectedOrders, (r, i) => {
            custArray.push(r[customer.selectedValuePath])
          })
          dto.bizPrismIniStringDto[k] = custArray
        }
      }
      if (k === 'prismInvoice' && !v.text) {
        dto.bizPrismIniStringDto.prismInvoice = '0'
      }
    })

    PrismApi.download(dto).then((res) => {
      if (res.payload.code === '000') {
        appAction.setBatchResult({processingSeq: res.payload.processingSeq})
      
        // setIfnFiles(null)
        procPopup.show(true, (sender) => {
          // if (document.getElementById(['uploadFile'])) document.getElementById(['uploadFile']).value = '';
          if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
            // console.log('ok')
            // setFail(0)
            // setSkip(0)
          } else {
            // setFileInvalid(false)
            // setFail(0)
            // setSkip(0)
            // console.log('cancel')
          }
        })
      } else {
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  React.useEffect(() => {
    // console.log(selectedOrders)
    const custArray = []
    const customer = getcustomer(prism.prismCustomer.text)
    _.forEach(selectedOrders, (r, i) => {
      custArray.push(r[customer.selectedValuePath])
    })
    // console.log(customer)
    // console.log(custArray)
    const obj = convert.getTarget(prism.prismSearchCodes, 'text', custArray)
    delete obj.id
    prismAction.setInputPrism(obj)

  }, [selectedOrders])

  React.useEffect(() => {
    if (!app.processing && prism.prismCustomer.text) {
      // console.log(selectedOrders)
      setSelectedOrders([])
    }
    if (prism.prismCustomer.text) {
      let customer = getcustomer(prism.prismCustomer.text)
      // console.log(customer)
      prismAction.setInputPrism({
        prismSearchCodes: {
          ...prism.prismSearchCodes,
          maxLength: customer.maxLength,
        },
        customerFrom: {
          ...prism.customerFrom,
          maxLength: customer.maxLength,
          original: '',
          value: '',
          text: '',
        },
        customerTo: {
          ...prism.customerTo,
          maxLength: customer.maxLength,
          original: '',
          value: '',
          text: '',
        }
      })
    }
  }, [app.processing, prism.prismCustomer.text])

  const getcustomer =(prismCustomer) => {
    const prismCust = prismCustomer?prismCustomer:prism.prismCustomer.text
    // console.log(prismCustomer)
    // console.log(prism.prismCustomer.text)
    let customer = {}
    switch (prismCust) {
      case '1': customer = {
        list: app.orderList,
        selectedValuePath: 'orderCd',
        displayMemberPath: 'listName',
        searchMemberPath: 'orderCd',
        maxLength: 12,
      }
      break;
      case '2': customer = {
        list: app.companyList,
        selectedValuePath: 'companyCd',
        displayMemberPath: 'listName',
        searchMemberPath: 'companyCd',
        maxLength: 10,
      }
      break;
      case '3': customer = {
        list: app.freeList,
        selectedValuePath: 'freeCd',
        displayMemberPath: 'listName',
        searchMemberPath: 'freeCd',
        maxLength: 10,
      }
      break;
      case '4': customer = {
        list: app.payList,
        selectedValuePath: 'payCd',
        displayMemberPath: 'listName',
        searchMemberPath: 'payCd',
        maxLength: 1,
      }
      break;
      case '5': customer = {
        list: app.tourList,
        selectedValuePath: 'tourCd',
        displayMemberPath: 'listName',
        searchMemberPath: 'tourCd',
        maxLength: 15,
      }
      break;
      case '6': customer = {
        list: app.eoList,
        selectedValuePath: 'eoCd',
        displayMemberPath: 'listName',
        searchMemberPath: 'eoCd',
        maxLength: 7,
      }
      break;
      case '7': customer = {
        list: app.areaList,
        selectedValuePath: 'areaCd',
        displayMemberPath: 'listName',
        searchMemberPath: 'areaCd',
        maxLength: 3,
      }
      break;
      case '8': customer = {
        list: app.sectionList,
        selectedValuePath: 'sectionCd',
        displayMemberPath: 'listName',
        searchMemberPath: 'sectionCd',
        maxLength: 6,
      }
      break;
      case '9': customer = {
        list: app.memberList,
        selectedValuePath: 'memberCd',
        displayMemberPath: 'listName',
        searchMemberPath: 'memberCd',
        maxLength: 6,
      }
      break;
      case '10': customer = {
        list: app.kb1List,
        selectedValuePath: 'kb1Cd',
        displayMemberPath: 'listName',
        searchMemberPath: 'kb1Cd',
        maxLength: 7,
      }
      break;
      case '11': customer = {
        list: app.kb2List,
        selectedValuePath: 'kb2Cd',
        displayMemberPath: 'listName',
        searchMemberPath: 'kb2Cd',
        maxLength: 7,
      }
      break;
      default: customer = {
        list: [],
        selectedValuePath: 'cd',
        displayMemberPath: 'listName',
        searchMemberPath: 'name',
        maxLength:20,
      }
    }
    return customer
  }

  React.useEffect(() => {
    if (fromToCheck) {
      const from = prism.prismSearchFrom
      const to = prism.prismSearchTo
      let isOk = true
      // console.log(from)
      // console.log(to)
      if (!_.isNil(from.text) && from.text !== null && (_.isNil(to.text) || to.text === null)) {
        let dt = wijmo.DateTime
        let copyText = new Date(from.text)
        copyText.setHours(23,59,59,999)
        prismAction.changeInputPrism(convert.getTarget(to, 'text', copyText))
      }
      if (from.text && to.text) {
        if (from.text.getTime() > to.text.getTime()) {
          isOk = false
        }
      }
      let obj = {}
      if (!isOk) {
        obj['prismSearchTo'] = {
          ...to,
          value: null,
          text: null,
        }
      }
      prismAction.setInputPrism(obj)
      setFromToCheck(false)
    }
  }, [fromToCheck])

  const clearCode = () => {
    const obj = {}
    obj['prismSpecified'] = {
      ...prism.prismSpecified,
      original: '0',
      value: '0',
      text: '0',
    }
    obj['prismRange'] = {
      ...prism.prismRange,
      original: '0',
      value: '0',
      text: '0',
    }
    obj['customerFrom'] = {
      ...prism.customerFrom,
      original: '',
      value: '',
      text: '',
    }
    obj['customerTo'] = {
      ...prism.customerTo,
      original: '',
      value: '',
      text: '',
    }
    setSelectedOrders([])
    prismAction.setInputPrism({...obj})
  }

  const clearDate = () => {
    setDateRadio('0')
    setPeriod({
      1: {text: '0', disabled: true},
      2: {text: '0', disabled: true},
      3: {text: '0', disabled: true},
      4: {text: '0', disabled: true},
    })
    setDateNumber({
      ...dateNumber,
      original: null,
      value: null,
      text: null,
      disabled: false,
    })
    setTimeout(() => {
      setDateClear(true)
    })
  }

  React.useEffect(() => {
    if (dateClear) {
      const obj = {}
      obj['prismSearchFrom'] = {
        ...prism.prismSearchFrom,
        original: null,
        value: null,
        text: null,
      }
      obj['prismSearchTo'] = {
        ...prism.prismSearchTo,
        original: null,
        value: null,
        text: null,
      }
      prismAction.setInputPrism({...obj})
      setDateClear(false)
    }
  }, [dateClear])

  return (
    <React.Fragment>
      <Container fluid className='main'>
        <Title
          title={TITLE.prism.name}
          icon={TITLE.prism.icon}
        />
        <div>
          <ProcessingPopup 
            popup={procPopup}
            setPopup={setProcPopup}
            header={PRINT_MESSAGE.header}
            processingTitle={PRINT_MESSAGE.processingTitle}
            processingMessage1={PRINT_MESSAGE.processingMessage1}
            resultTitle={PRINT_MESSAGE.resultTitle}
            resultMessage1={PRINT_MESSAGE.resultMessage1}
          />
        </div>
        <Card
          root='max-button2'
          size='lg'
          hideClose
          header={null}
          subheader={null}
          body={
            <React.Fragment>
              <Card
                root='max'
                size='md'
                hideClose
                header={TITLE.prism.code.header}
                subheader={TITLE.prism.code.subheader}
                body={
                  <React.Fragment>
                    <Common.Text
                      attrs={{...prism.prismIata}}
                      onBlur={(e) => prismAction.changeInputPrism(e)}
                      group={{
                        className: 'width-10 mr-2 mb-3',
                      }}
                    />
                    <Common.Select
                      attrs={{...prism.prismInvoice}}
                      group={{
                        className: 'width-31 mr-2',
                        textwidth: 'width-31',
                      }}
                      minLength={1}
                      list={ACCOUNT_CODE_TYPE_BLANK}
                      onChange={(e) => prismAction.changeInputPrism(e)}
                    />
                    <Common.Select
                      attrs={{...prism.prismCustomer}}
                      group={{
                        className: 'width-31 mr-2',
                        textwidth: 'width-31',
                      }}
                      minLength={1}
                      list={ACCOUNT_CODE_TYPE}
                      onChange={(e) => prismAction.changeInputPrism(e)}
                    />
                    <Common.Text
                      attrs={{...prism.prismBranch}}
                      onBlur={(e) => prismAction.changeInputPrism(e)}
                      group={{
                        className: 'width-10 mr-2 mb-3',
                      }}
                    />
                    <Common.Text
                      attrs={{...prism.prismIso}}
                      onBlur={(e) => prismAction.changeInputPrism(e)}
                      group={{
                        className: 'width-10 mr-0 mb-3',
                      }}
                    />
                  </React.Fragment>
                }
              />
              <Card
                root='max'
                size='md'
                hideClose
                header={TITLE.prism.condition.header}
                subheader={TITLE.prism.condition.subheader}
                body={
                  <React.Fragment>
                    <Card
                      root='max'
                      size='md'
                      hideClose
                      header={<span className='mr-4'>{`CUSTOMER(${dataMapper(ACCOUNT_CODE_TYPE, 'value', 'name', prism.prismCustomer.text)})`}</span>}
                      subheader={prism.prismSpecified.text !== '2' && prism.prismRange.text == '0' && 
                        <span className='ml-5'><div className="ml-2 d-inline tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div></span>}
                      body={
                        <React.Fragment>
                          <div className='card-condition-item'>
                            <div className='d-flex'>
                              <Common.Select
                                attrs={{...prism.prismSpecified}}
                                group={{
                                  className: 'width-20 mr-2',
                                  textwidth: 'width-20',
                                }}
                                minLength={1}
                                list={SPECIFIED}
                                onChange={(e) => prismAction.changeInputPrism(e)}
                              />
                              {prism.prismSpecified.text !== '2' &&
                                <Common.Select
                                  attrs={{...prism.prismRange}}
                                  group={{
                                    className: 'width-15 mr-2',
                                    textwidth: 'width-15',
                                  }}
                                  minLength={1}
                                  list={RANGE}
                                  onChange={(e) => prismAction.changeInputPrism(e)}
                                />
                              }
                              {prism.prismSpecified.text !== '2' && prism.prismRange.text == '0' &&
                                <Common.MultiSelect
                                  attrs={{
                                    ...prism.prismSearchCodes,
                                    delay: 10,
                                  }}
                                  group={{
                                    className: 'width-max-content mr-2',
                                    // textwidth: 'min-width-13',
                                    textwidth: 'min-width-25',
                                    textsuffixwidth: 'max-width-8',
                                  }}
                                  // maxSelectedItems={1}
                                  minLength={1}
                                  selectedValuePath={getcustomer().selectedValuePath}
                                  // displayName="namej"
                                  displayMemberPath={getcustomer().displayMemberPath}
                                  list={getcustomer().list}
                                  searchMemberPath={getcustomer().searchMemberPath}
                                  selectedItems={selectedOrders}
                                  setSelectedItems={setSelectedOrders}
                                  template={'<table><tr>' +
                                            '<td class="width-13" title="コード">{cd}</td>' +
                                            '<td class="" title="名称">{namej}</td>' +
                                            '</tr></table>'}
                                  isEditable={true}
                                />
                              }
                              {prism.prismSpecified.text !== '2' && prism.prismRange.text == '1' &&
                                <React.Fragment>
                                  <Common.Text
                                    attrs={{...prism.customerFrom}}
                                    onBlur={(e) => {
                                      prismAction.changeInputPrism(e)
                                      if (!prism.customerTo.text) {
                                        prismAction.changeInputPrism(convert.getTarget(prism.customerTo, 'text', e[e.id].text))
                                      }
                                    }}
                                    group={{
                                      className: 'width-10 mr-2',
                                    }}
                                  />
                                  <div className='text-center pt-2 mr-2'>～</div>
                                  <Common.Text
                                    attrs={{...prism.customerTo}}
                                    onBlur={(e) => prismAction.changeInputPrism(e)}
                                    group={{
                                      className: 'width-10 mr-2',
                                    }}
                                  />
                                </React.Fragment>
                              }
                            </div>
                          </div>
                          <div className='d-inline-block mb-1'>
                            <Button
                              id='clear'
                              className='button-gray small width-max-content'
                              onClick={(e) => clearCode()}
                            >
                              クリア
                            </Button>
                          </div>
                        </React.Fragment>
                      }
                    />
                    <Card
                      root='max'
                      size='md'
                      hideClose
                      header={prism.label[1]}
                      subheader={null}
                      body={
                        <React.Fragment>
                          <div className='card-condition-item mb-2'>
                            <Common.Radio
                              btnSize='at'
                              attrs={{
                                id: 'dateRadio',
                                value: dateRadio,
                                text: dateRadio,
                                disabled: false,
                              }}
                              radios={RADIOS.choiceDate}
                              // className='min-width-33 width-33 mr-3 p-0'
                              onChange={(e) => {
                                setDateRadio(e.dateRadio.text)
                                // prismAction.changeInputPrism(e)
                              }}
                            />
                          </div>
                          <div className='card-condition-item'>
                            <Common.DateTime
                              attrs={{...prism.prismSearchFrom}}
                              onBlur={(e) => {
                                prismAction.changeInputPrism(e)
                                setTimeout(() => {
                                  setFromToCheck(true)
                                })
                              }}
                              group={{
                                // ...props.group,
                                className: 'width-14 mr-2 mb-1',
                              }}
                            />
                            <div className='text-center mt-2 mr-2'>～</div>
                            <Common.DateTime
                              attrs={{...prism.prismSearchTo}}
                              onBlur={(e) => {
                                if (_.isDate(e.prismSearchTo.text)) {
                                  if (_.isDate(prism.prismSearchTo.text)) {
                                    if (prism.prismSearchTo.text.getFullYear() === e.prismSearchTo.text.getFullYear()
                                      && prism.prismSearchTo.text.getMonth() === e.prismSearchTo.text.getMonth()
                                      && prism.prismSearchTo.text.getDate() === e.prismSearchTo.text.getDate()) {
                                        prismAction.changeInputPrism(e)
                                    } else {
                                      if (prism.prismSearchTo.text.getHours() === e.prismSearchTo.text.getHours()
                                        && prism.prismSearchTo.text.getMinutes() === e.prismSearchTo.text.getMinutes()) {
                                        // 日付のみ変更された
                                        e.prismSearchTo.text.setHours(23,59,59,999)
                                        prismAction.changeInputPrism(e)
                                      } else {
                                        // 日付と時間が変更された
                                        prismAction.changeInputPrism(e)
                                      }
                                    }
                                  } else {
                                    // nullから設定された
                                    e.prismSearchTo.text.setHours(23,59,59,999)
                                    prismAction.changeInputPrism(e)
                                  }
                                } else {
                                  prismAction.changeInputPrism(e)
                                }
                                setTimeout(() => {
                                  e.prismSearchTo.text && setFromToCheck(true)
                                })
                              }}
                              group={{
                                // ...props.group,
                                className: 'width-14 mr-2 mb-1',
                              }}
                            />
                            <Common.Number
                              attrs={dateNumber}
                              valueChanged={(e) => {setDateNumber(e.dateNumber)}}
                              group={{
                                className: 'width-16 ml-4 mr-0 mb-1',
                                textwidth: 'width-10'
                              }}
                            />
                            <div className='d-flex flex-column my-0'>
                              {prism.label[2]}
                              <div className='flex-row'>
                              {_.map(period, (v, k) => {
                                return (
                                  <Common.Check
                                    key={k}
                                    attrs={{
                                      id: `period${k}`,
                                      label: `${k}P`,
                                      value: v.text,
                                      text: v.text,
                                      disabled: v.disabled,
                                    }}
                                    className='min-width-5 width-5 m-0 p-0'
                                    onChange={(e) => {
                                      const obj = {...period}
                                      obj[k] = {
                                        ...e[`period${k}`]
                                      }
                                      setPeriod({
                                        ...obj,
                                      })
                                    }}
                                  />
                                )
                              })}
                              </div>
                            </div>
                          </div>
                          <div className='d-inline-block mb-1'>
                            <Button
                              id='clear'
                              className='button-gray small width-max-content'
                              onClick={(e) => clearDate()}
                            >
                              クリア
                            </Button>
                          </div>
                        </React.Fragment>
                      }
                    />
                  </React.Fragment>
                }
              />
            </React.Fragment>
          }
        />
        <Card 
          size='lg'
          root='button'
          hideClose
          header={null}
          subheader={null}
          body={
            <div className='card-buttom-button'>
              <Button
                {...prism.download}
                className='button-blue ml-auto width-max-content'
                disabled={app.isProcessing}
                onClick={() => execDownload()}
              >
                {prism.download.label}
              </Button>
            </div>
          }
        />
      </Container>
    </React.Fragment>
  )
}