import { axiosInstanceNoerr, axiosInstanceForBlob } from 'http/index'

export const MainApi = {
  info: () => {
    const instance = axiosInstanceNoerr;
    return instance.get('/main/info').then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  showInfo: () => {
    const instance = axiosInstanceNoerr;
    return instance.get('/main/showinfo').then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },

  tktCount: () => {
    const instance = axiosInstanceNoerr;
    return instance.get('/main/tktcount').then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },

}
