import _ from 'lodash';
import { convert, dataMapper } from "lib";
import { SEARCH, COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';

export const createDispSearch = (searchJson) => {
  // console.log(searchJson)
  let returnArray = []
  _.forEach(searchJson, (v, k) => {
    _.forEach(SEARCH[1].items, (item, key) => {
      // console.log(item.items)
      // console.log(k)
      const searchItem = _.find(item.items, ['key', k])
      if (searchItem) {
        // console.log(searchItem)
        // console.log(v)
        let text = ''
        let prefix1 = ''
        let prefix2 = ''
        let strFrom = ''
        let strRange = ''
        let strTo = ''
        let dateDayFrom = ''
        let dateTimeFrom = ''
        let dateRange = ''
        let dateDayTo = ''
        let dateTimeTo = ''
        let check1 = ''
        let check2 = ''
        let checkString = ''
        let chdate = '', period = '', period1 = '', period2 = '', period3 = '', period4 = ''
        switch (searchItem.type) {
          case 'check':
            _.forEach(v, (value, name) => {
              if (value) {
                text = (text?(text + ','):'') + (value && dataMapper(CHECKS[searchItem.key], 'id', 'label', name))
              }
            })
            if (text) returnArray.push(searchItem.header + ' : ' + text)
            break;
          case 'check_in':
            _.forEach(v, (value, name) => {
              if (name === 'radioType') {
                prefix1 = dataMapper(RADIOS.clude, 'value', 'name', value)
              } else if (name !== 'clusterRadioType') {
                if (value) {
                  text = (text?(text + ','):'') + (value && dataMapper(CHECKS[searchItem.key], 'id', 'label', name))
                }
              }
            })
            if (text) returnArray.push(searchItem.header + ' : ' + '(' + prefix1 + ')' + text)
            break;
          case 'code':
            _.forEach(v, (value, name) => {
              if (name === 'radioType') {
                prefix1 = dataMapper(RADIOS.clude, 'value', 'name', value)
              } else if (name !== 'clusterRadioType') {
                text = v.formList
              }
            })
            if (text) returnArray.push(searchItem.header + ' : ' + '(' + prefix1 + ')' + text)
            break;
          case 'code_range':
            _.forEach(v, (value, name) => {
              if (name === 'radioType') {
                prefix1 = dataMapper(RADIOS.clude, 'value', 'name', value)
              } else if (name === 'dataTypeRadioType') {
                prefix2 = dataMapper(RADIOS.choiceBlank, 'value', 'name', value)
              } else if (v.dataTypeRadioType === '1' && name === 'formList') {
                text = value
              } else if (v.dataTypeRadioType === '0' && name === 'strFrom') {
                strFrom = value
                strRange = '～'
              } else if (v.dataTypeRadioType === '0' && name === 'strTo') {
                strTo = value
                strRange = '～'
              }
            })
            returnArray.push(searchItem.header + ' : ' + '(' + prefix1 + ')' + '(' + prefix2 + ')' + strFrom + strRange + strTo + text)
            break;
          case 'date':
          case 'datetime':
            _.forEach(v, (value, name) => {
              if (name === 'chdate') {
                chdate = value
              } else if (name === 'period1') {
                period1 = value
              } else if (name === 'period2') {
                period2 = value
              } else if (name === 'period3') {
                period3 = value
              } else if (name === 'period4') {
                period4 = value
              } else if (name === 'dateDayFrom') {
                dateDayFrom = value
              } else if (name === 'dateTimeFrom') {
                dateTimeFrom = value
              } else if (name === 'dateDayTo') {
                dateDayTo = value
              } else if (name === 'dateTimeTo') {
                dateTimeTo = value
              }
              dateRange = '～'
            })
            if (period1 === '1') {
              period = '1P'
            }
            if (period2 === '1') {
              period = period + (period?',':'') + '2P'
            }
            if (period3 === '1') {
              period = period + (period?',':'') + '3P'
            }
            if (period4 === '1') {
              period = period + (period?',':'') + '4P'
            }
            if (period) {
              period = '(' + period + ')'
            }
            switch (chdate) {
              case '1': 
                returnArray.push(searchItem.header + ' : 前月' + period)
                break;
              case '2': 
                returnArray.push(searchItem.header + ' : 今月' + period)
                break;
              case '3': 
                returnArray.push(searchItem.header + ' : 前日')
                break;
              case '4': 
                returnArray.push(searchItem.header + ' : 本日')
                break;
              default:
                returnArray.push(searchItem.header + ' : ' + dateDayFrom + ' ' + dateTimeFrom  + dateRange + dateDayTo + ' ' + dateTimeTo)
            }
            break;
          case 'mmyy':
            _.forEach(v, (value, name) => {
              if (name === 'radioType') {
                prefix1 = dataMapper(RADIOS.choice, 'value', 'name', value)
              } else if (v.radioType === '0' && name === 'dateFrom') {
                dateDayFrom = value
                dateRange = '～'
              } else if (v.radioType === '0' && name === 'dateTo') {
                dateDayTo = value
                dateRange = '～'
              } else if (v.radioType === '1' && name === 'formList') {
                text = value
              }
            })
            returnArray.push(searchItem.header + ' : ' + '(' + prefix1 + ')' + dateDayFrom + dateRange + dateDayTo)
            break;
          case 'radio_check':
            _.forEach(v, (value, name) => {
              if (name === 'radioType' && value === '0') {
                prefix1 = dataMapper(RADIOS[searchItem.key], 'value', 'name', value)
              } else if (v.radioType === '1' && name.endsWith('Flg')) {
                check2 = (check1 && value)?dataMapper(CHECKS[searchItem.key], 'id', 'label', name):''
                check1 = (!check1 && value)?dataMapper(CHECKS[searchItem.key], 'id', 'label', name):check1
                checkString = (check1 && check2)?'または':''
              }
            })
            returnArray.push(searchItem.header + ' : ' + prefix1 + check1 + checkString + check2)
            break;
          case 'radio':
            _.forEach(v, (value, name) => {
              if (name === 'radioType') {
                text = dataMapper(RADIOS[searchItem.key], 'value', 'name', value)
              }
            })
            returnArray.push(searchItem.header + ' : ' + text)
            break;
          case 'text_multi':
          case 'text_blank':
            _.forEach(v, (value, name) => {
              if (name === 'radioType') {
                prefix1 = dataMapper(RADIOS.blank, 'value', 'name', value)
              } else if (v.radioType === '0' && name === 'formList') {
                text = value
              }
            })
            returnArray.push(searchItem.header + ' : ' + '(' + prefix1 + ')' + text)
            break;
          case 'text':
            v.formList && returnArray.push(searchItem.header + ':' + v.formList)
            break;
          case 'text_in_blank':
            _.forEach(v, (value, name) => {
              if (name === 'clusterRadioType') {
                prefix1 = dataMapper(RADIOS.clude, 'value', 'name', value)
              } else if (name === 'dataTypeRadioType' && value === '1') {
                prefix2 = dataMapper(RADIOS.blank, 'value', 'name', value)
              } else if (v.dataTypeRadioType === '0' && name === 'formList') {
                text = value
              }
            })
            returnArray.push(searchItem.header + ' : ' + '(' + prefix1 + ')' + prefix2 + text)
            break;
          case 'text_in':
            _.forEach(v, (value, name) => {
              if (name === 'radioType') {
                prefix1 = dataMapper(RADIOS.clude, 'value', 'name', value)
              } else if (name === 'formList') {
                text = value
              }
            })
            returnArray.push(searchItem.header + ' : ' + '(' + prefix1 + ')' + text)
            break;
          case 'text_in_range':
            _.forEach(v, (value, name) => {
              if (name === 'clusterRadioType') {
                prefix1 = dataMapper(RADIOS.clude, 'value', 'name', value)
              } else if (name === 'dataTypeRadioType') {
                prefix2 = dataMapper(RADIOS.choice, 'value', 'name', value)
              } else if (v.dataTypeRadioType === '1' && name === 'formList') {
                text = value
              } else if (v.dataTypeRadioType === '0' && name === 'from') {
                strFrom = value
                strRange = '～'
              } else if (v.dataTypeRadioType === '0' && name === 'to') {
                strTo = value
                strRange = '～'
              }
            })
            returnArray.push(searchItem.header + ' : ' + '(' + prefix1 + ')' + '(' + prefix2 + ')' + strFrom + strRange + strTo + text)
            break;
          case 'text_range':
            _.forEach(v, (value, name) => {
              if (name === 'dataTypeRadioType') {
                prefix1 = dataMapper(RADIOS.choice, 'value', 'name', value)
              } else if (v.dataTypeRadioType === '1' && name === 'formList') {
                text = value
              } else if (v.dataTypeRadioType === '0' && name === 'strFrom') {
                strFrom = value
                strRange = '～'
              } else if (v.dataTypeRadioType === '0' && name === 'strTo') {
                strTo = value
                strRange = '～'
              }
            })
            returnArray.push(searchItem.header + ' : ' + '(' + prefix1 + ')' + strFrom + strRange + strTo + text)
            break;
        }
      }
    })
  })
  return returnArray
}