import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from 'components';
import * as Common from 'components/common';

import { Button } from 'react-bootstrap';
import _ from 'lodash';
import { RADIOS, CHECKS } from 'constants/search';
import { entryCheck, convert } from "lib";
import { HELPER_MESSAGE } from 'constants/message';
// import '../styles/conditionCard.scss'

export const CardTextInRangeComponent = (props) => {
  const [selItems, setSelItems] = React.useState([])
  const [selValPath, setSelValPath] = React.useState('')
  const [dispMemPath, setDispMemPath] = React.useState('')
  const [srchMemPath, setSrchMemPath] = React.useState('')
  const [dataList, setDataList] = React.useState([])
  const [fromToCheck, setFromToCheck] = React.useState(false)
  const toElm = useRef(null);

  // 初期化
  React.useEffect(() => {
    clear(true)
    setTimeout(() => {
      _.forEach(props.search, (item, key) => {
        if (key === props.condition.key + '_array') {
          if (_.isArray(item.text)) setSelItems(item.text)
        }
      })
    })
    // 
    switch (props.condition.key) {
      default:
        setSelValPath('text')
        setDispMemPath('text')
        setDataList([])
        setSrchMemPath('text')
        break;
    }
  }, [props.condition.key])

  // clear
  const clear = (isKeep) => {
    const obj = {}
    if (!isKeep) setSelItems([])
    if (!props.items[props.items.length-1].value || !isKeep) {
      obj[props.items[props.items.length-1].id] = {
        ...props.items[props.items.length-1],
        original: RADIOS.clude[0].value,
        value: RADIOS.clude[0].value,
        text: RADIOS.clude[0].value,
      }
    }
    if (!props.items[props.items.length-2].value || !isKeep) {
      obj[props.items[props.items.length-2].id] = {
        ...props.items[props.items.length-2],
        original: RADIOS.choice[1].value,
        value: RADIOS.choice[1].value,
        text: RADIOS.choice[1].value,
      }
    }
    !isKeep && _.forEach(props.items, (r, i) => {
      if (i < props.items.length-2 && !r.id.endsWith('_array')) {
        obj[r.id] = {
          ...r,
          original: '',
          value: '',
          text: '',
        }
      }
    })
    Object.keys(obj).length && props.setInput({...obj})
  }

  React.useEffect(() => {
    _.map(props.items, (r, i) => {
      if (r.id.endsWith('array')) {
        const obj = convert.getTarget(r, 'text', selItems)
        delete obj.id
        props.setInput(obj)
      }
    })
  }, [selItems])

  React.useEffect(() => {
    if (fromToCheck) {
      const fromAry = _.filter(props.items, (r, i) => _.endsWith(r.id, 'from'))
      const toAry = _.filter(props.items, (r, i) => _.endsWith(r.id, 'to'))
      const from = (fromAry && fromAry.length > 0)? fromAry[0]:{}
      const to = (toAry && toAry.length > 0)? toAry[0]:{}
      let isOk = true
      // console.log(from.text)
      // console.log(to.text)
      if (!_.isNil(from.text) && from.text !== '' && (_.isNil(to.text) || to.text === '')) {
        props.onBlur(convert.getTarget(to, 'text', from.text))
      }
      if (!_.isNil(from.text) && !_.isNil(to.text) && from.text !== '' && to.text !== '') {
        // if (!_.isNaN(_.toNumber(from.text)) && !_.isNaN(_.toNumber(to.text))) {
        //   if (_.toNumber(from.text) > _.toNumber(to.text)) isOk = false
        // } else {
          if (from.text > to.text) isOk = false
      //   }
      }
      let obj = {}
      // console.log(isOk)
      if (!isOk) {
        obj[to.id] = {
          ...to,
          value: null,
          text: null,
        }
      }
      props.setInput(obj)
      setFromToCheck(false)
    }
  }, [fromToCheck])

  const getBody = (choiceType) => {
    let getItem = null;
    switch (choiceType) {
      case RADIOS.choice[0].value: 
        getItem = _.map(props.items, (r, i) => {
          if (_.endsWith(r.id, 'from'))
            return (
              <React.Fragment key={i}>
                <Common.Number
                  key={i}
                  attrs={r}
                  onBlur={(e) => {
                    props.onBlur(e)
                    setTimeout(() => {
                      setFromToCheck(true)
                    })
                  }}
                  group={{
                    ...props.group,
                    className: 'width-10 mr-2 mb-1'
                  }}
                  valueChanged={(e) => {
                    if (String(e[r.id].text).length == String(r.max).length) toElm.current.control.focus()
                  }}
                />
                <div className='text-center pt-2 mr-2 mb-2'>～</div>
              </React.Fragment>
            )
          else if (_.endsWith(r.id, 'to'))
            return (
              <React.Fragment key={i}>
                <Common.Number
                  key={i}
                  attrs={r}
                  onBlur={(e) => {
                    props.onBlur(e)
                    setTimeout(() => {
                      e[r.id].text && setFromToCheck(true)
                    })
                  }}
                  group={{
                    ...props.group,
                    className: 'width-10 mr-2 mb-1'
                  }}
                  ref={toElm}
                />
              </React.Fragment>
            )
          else return
        })
        break;
      case RADIOS.choice[1].value:
        getItem = _.map(props.items, (r, i) => {
          if (r.id.endsWith('array')) {
            return (
              <React.Fragment key={i}>
                <Common.MultiSelect
                  key={i}
                  attrs={r}
                  onBlur={(e) => props.onBlur(e)}
                  group={{
                    className: 'width-max-content mr-2 mb-1',
                    textwidth: 'min-width-30',
                    textsuffixwidth: 'max-width-25',
                    inputwidth: '150px'
                  }}
                  minLength={1}
                  selectedValuePath={selValPath}
                  displayMemberPath={dispMemPath}
                  list={dataList}
                  searchMemberPath={srchMemPath}
                  selectedItems={selItems}
                  setSelectedItems={setSelItems}
                  // template={getTemp()}
                  isEditable={true}
                  displaytype='p2'
                  isWildCard={props.condition.isWildCard}
                />
              </React.Fragment>
            )
          } else return
        })
    }
    return (
      <React.Fragment>
        <div className='card-condition-item'>
          <Common.RadioButton
            btnSize='sm'
            className='min-width-25 width-25 mr-3'
            attrs={props.items[props.items.length-2]}
            radios={RADIOS.choice}
            onChange={(e) => props.onBlur(e)}
          />
          {getItem}
        </div>
        <div className='d-inline-block mb-1'>
          <Button
            id='clear'
            className='button-gray small width-max-content'
            onClick={(e) => clear()}
          >
            クリア
          </Button>
        </div>
      </React.Fragment>
    )
  }
  
  return (
    <React.Fragment>
      <Card 
        size={props.cardSize}
        header={
          <React.Fragment>
            <span>{props.condition.header}</span>
            <Common.RadioButton
              btnSize='sm'
              attrs={props.items[props.items.length-1]}
              radios={RADIOS.clude}
              onChange={(e) => props.onBlur(e)}
              className='width-12 min-width-12 mr-3'
            />
          </React.Fragment>
        }
        subheader={props.items[props.items.length-2].text===RADIOS.choice[1].value?props.condition.subheader:null}
        body={getBody(props.items[props.items.length-2].text)}
        condition={props.condition}
        onClose={props.onClose}
      />
    </React.Fragment>
  )
}
