import React from 'react';
import _ from 'lodash';
import { HELPER_MESSAGE } from 'constants/message';
import { validation } from './Validation';

export const entryCheck = (state, payload) => {
  const ret = {};
  const id = payload.id;
  const check = state[id];

  let errortype = [];
  let message = [];
  let helpertext = [];
  let newValue = payload[id].text
  
  const label = check.label;
  
  // // 半角変換対象
  // const isSingle = _.intersection(check.validtype, ['alphanum','num','tel','zipCode','telSearch','zipCodeSearch']).length>0;
  // // 数字制限
  // const isNum = _.intersection(check.validtype, ['num']).length>0;
  // // 数字orハイフン制限
  // const isTel = _.intersection(check.validtype, ['tel','telSearch','zipCodeSearch']).length>0;
  // // 数字or英字制限
  // const isAlphaNum = _.intersection(check.validtype, ['alphanum']).length>0;
  // // 全角制限
  // const isFullWidth = _.intersection(check.validtype, ['fullWidth']).length>0;
  // // checkboxの場合、checkedをvalueにセット
  // let newValue = (payload.target.type === 'checkbox')?
  //                   payload.target.checked:
  //                   payload.target.value;

  // // 半角変換
  // if (isSingle) newValue = convert.toSingleByte(newValue);
  // // 数字
  // if (isNum) newValue = newValue.replace(/[^0-9]/g, '')
  // // 数字 or ハイフン
  // if (isTel) newValue = newValue.replace(/[^0-9-]/g, '')
  // // 英字 or 数字
  // if (isAlphaNum) newValue = newValue.replace(/[^a-zA-Z0-9]/g, '')
  // // 全角
  // if (isFullWidth) newValue = newValue.replace(/[\x01-\x7E\uFF66-\uFF9F]/g, '')

  // // ハイフン除去＋数値変換
  // if (_.intersection(check.validtype, ['zipCode','zipCodeSearch']).length>0) {
  //   newValue = _.replace(newValue, '-', '')
  // }

  // // maxLengthで切り出し
  // if (check.inputProps && check.inputProps.maxLength && _.isString(newValue)) newValue = newValue.substr(0, check.inputProps.maxLength)
  // // 大文字変換
  // if (check.stringcase==='upper') newValue = newValue.toUpperCase();

  // // min-max
  // if (check.required) {
  //   if (check.inputProps && check.inputProps.min && _.toNumber(newValue) < check.inputProps.min)
  //     newValue = check.inputProps.min
  //   if (check.inputProps && check.inputProps.max && _.toNumber(newValue) > check.inputProps.max)
  //     newValue = check.inputProps.max
  // } else {
  //   if (check.inputProps && check.inputProps.min && _.toNumber(newValue) < check.inputProps.min)
  //     newValue = ''
  //   if (check.inputProps && check.inputProps.max && _.toNumber(newValue) > check.inputProps.max)
  //     newValue = ''
  // }
  // check minLength
  if (check.minLength && newValue.length>0 && newValue.length < check.minLength) {
    errortype.push('length');
    helpertext = HELPER_MESSAGE['length'][0];
    if (check.minLength && check.maxLength && check.minLength===check.maxLength)
      helpertext += check.minLength + HELPER_MESSAGE['length'][3]
    else {
      helpertext += check.minLength + HELPER_MESSAGE['length'][1]
      if (check.maxLength)
        helpertext += check.maxLength + HELPER_MESSAGE['length'][2]
    }
    helpertext += HELPER_MESSAGE['length'][4];
    // message.push((<a key={message.length} href={"#"+target}>{label}:{VALIDATE_MESSAGE['length']}<br/></a>))
  }
  // validate
  if (check.required && !validation['required'](newValue)) {
    errortype.push('required');
    helpertext = check.requiredSelect?HELPER_MESSAGE['requiredSelect']: HELPER_MESSAGE['required'];
    // message.push((<a key={message.length} href={"#"+target}>{label}:{VALIDATE_MESSAGE['required']}<br/></a>))
  }
  _.forEach(check.validtype, (v, k) => {
    if (v === 'password' && !validation[v](newValue)) {
      errortype.push(v);
      // helpertext = HELPER_MESSAGE[v][0] + label + HELPER_MESSAGE[v][1];
      helpertext = HELPER_MESSAGE[v];
      // message.push((<a key={target + message.length} href={"#"+target}>{label}:{VALIDATE_MESSAGE[v]}<br/></a>))
    } else if (!validation[v](newValue)) {
      errortype.push(v);
      helpertext = HELPER_MESSAGE[v];
      // message.push((<a key={target + message.length} href={"#"+target}>{label}:{VALIDATE_MESSAGE[v]}<br/></a>))
    }
  })
  
  // set isInvalid
  ret[id] = {
    ...check,
    value: newValue,
    isInvalid: errortype.length!==0,
    helpertext: helpertext,
    // dirty: _.toString(check.original)!==_.toString(newValue)?'dirty':'',
  }
  
  return {
    // ...state,
    ...ret,
  }
}
