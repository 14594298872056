import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';
import { CalcPane } from './CalcPane'
import { FarePane } from './FarePane'
import { AccPane } from './AccPane'
import { FlightPane } from './FlightPane'
import { TaxPane } from './TaxPane'
import { CardPane } from './CardPane'
import { RefundPane } from './RefundPane'
import { ReissuePane } from './ReissuePane'
import { CustPane } from './CustPane'
import { OtherPane } from './OtherPane'
import { EmdPane } from './EmdPane'
import { AddPane } from './AddPane'


export const TicketEditPane = (props) => {

  const app = useSelector(state => state.App);
  const tktEdit = useSelector(state => state.TktEdit);

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  switch (props.pane) {
    case 'calc': return (<CalcPane />)
    case 'fare': return (<FarePane />)
    case 'acc': return (<AccPane />)
    case 'flight': return (<FlightPane gridEditing={props.gridEditing} setGridEditing={props.setGridEditing} />)
    case 'tax': return (<TaxPane />)
    case 'card': return (<CardPane />)
    case 'refund': return (<RefundPane />)
    case 'reissue': return (<ReissuePane />)
    case 'cust': return (<CustPane />)
    case 'other': return (<OtherPane />)
    case 'emd': return (<EmdPane />)
    case 'add': return (<AddPane />)
    default: return (<div></div>)
  }
}