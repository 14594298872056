import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { InputDate, InputMask, ComboBox, AutoComplete, InputColor, InputNumber } from '@grapecity/wijmo.input';
import { CollectionView, setSelectionRange } from "@grapecity/wijmo";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { AUTH_MAP, LIST_ITEMS, COM_KBN_MAP, COM_TAX_MAP, COMMH_KBN_MAP, COMMH_CLS_MAP, TKTKBN_MAP } from 'constants/master';
import { convert, entryCheck, alert, validation } from "lib";

export const CompanyDetailPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  const {popup, setPopup} = props

  const [commhCls, setCommhCls] = React.useState('')
  const [headerList, setHeaderList] = React.useState([])
  const [dataList, setDataList] = React.useState([])
  const [itemList, setItemList] = React.useState([])
  const [addBodyRow, setAddBodyRow] = React.useState(-1)

  const initPopup = (form) => {
    setPopup(form)
  }

  const getList = (isNew, isHeader, cls) => {
    if (isHeader) {
      let list = master.companyCommHeaderList.map(r => {
        if (r.commhCls===cls && (isNew? r.addRow===props.data.item.addRow: r.commhComCd===props.data.item.comCd)) {
          const obj = {}
          _.forEach(r, (val, key) => {
            obj[key] = val
          })
          obj.addRow = props.data.item.addRow
          // original
          _.forEach(r, (rec, idx) => {
            if (!r.isAddingNew && !r.original) {
              obj.original = {}
              _.forEach(LIST_ITEMS.commH, (col, j) => {
                obj.original[col.id] = r[col.id]
              })
            }
          })
          return obj
        }
      }).filter(v => v)
      if (list.length > 0) {
        return list
      } else {
        return [{commhCls: cls, addRow: props.data.item.addRow, commhComCd: props.data.item.comCd, commhKbn: '0', commhRate: 0, commhSum: 0}]
      }
    } else {
      return master.companyCommBodyList.map(r => {
        if (r.commbCls===cls && (isNew? r.addRow===props.data.item.addRow: r.commbComCd===props.data.item.comCd)) {
          const obj = {}
          _.forEach(r, (val, key) => {
            obj[key] = val
          })
          return obj
        }
      }).filter(v => v)
    }
  }

  React.useEffect(() => {
    if (props.data) {
      setCommhCls('0')
      // 
      if (!props.data.item.isAddingNew) {
        masterAction.setInputMaster({
          companyDetail: {
            ...master.companyDetail,
            comCd:{
              ...master.companyDetail.comCd,
              disabled: true
            },
          },
        })
      }
      // 
      _.forEach(master.companyDetail, (v, k) => {
        if (props.data.item[k]) {
          masterAction.changeInputComDetail(convert.getTarget(v, 'text', props.data.item[k]))
        }
      })
      setHeaderList([
        getList(props.data.item.isAddingNew, true, '0'),
        getList(props.data.item.isAddingNew, true, '1'),
        getList(props.data.item.isAddingNew, true, '2'),
        getList(props.data.item.isAddingNew, true, '3'),
        getList(props.data.item.isAddingNew, true, '4'),
      ])
      setItemList([
        getList(props.data.item.isAddingNew, false, '0'),
        getList(props.data.item.isAddingNew, false, '1'),
        getList(props.data.item.isAddingNew, false, '2'),
        getList(props.data.item.isAddingNew, false, '3'),
        getList(props.data.item.isAddingNew, false, '4'),
      ])
    }
  }, [props.data])

  React.useEffect(() => {
    // console.log(commhCls)
    if (commhCls) {
      const header = headerList[commhCls]
      masterAction.changeInputComDetail(convert.getTarget(master.companyDetail.commhKbn, 'text', header.length > 0?header[0].commhKbn:'0'))
      masterAction.changeInputComDetail(convert.getTarget(master.companyDetail.commhRate, 'text', header.length > 0?header[0].commhRate:0))
      masterAction.changeInputComDetail(convert.getTarget(master.companyDetail.commhSum, 'text', header.length > 0?header[0].commhSum:0))
      setDataList(itemList[commhCls])
    } else {
      setDataList([])
    }
  }, [commhCls])

  React.useEffect(() => {
    if (headerList.length > 0 && commhCls) {
      headerList[commhCls][0].commhRate = master.companyDetail.commhRate.text
    }
  }, [master.companyDetail.commhRate.text])
  React.useEffect(() => {
    if (headerList.length > 0 && commhCls) {
      headerList[commhCls][0].commhSum = master.companyDetail.commhSum.text
    }
  }, [master.companyDetail.commhSum.text])

  const execOk = () => {
    let hasError = false
    const data = {...props.data}

    let companyDetail = {...master.companyDetail}
    _.forEach(_.filter(master.companyDetail, r => !_.isUndefined(r.value)), (v, k) => {
      companyDetail[v.id] = entryCheck(master.companyDetail, convert.getTarget(v, 'text', v.text))[v.id]
      hasError = hasError ||  companyDetail[v.id].isInvalid
    })
    masterAction.setInputMaster({
      companyDetail: {...companyDetail},
    })

    hasError = hasError || _.filter(itemList, (r) => r.hasError).length > 0
    // console.log(hasError)
    if (hasError) return

    // originalを設定
    if (!data.item.isAddingNew && !data.item.original) {
      data.item.original = {}
      _.forEach(props.colDef, (r, i) => {
        data.item.original[r.id] = data.item[r.id]
      })
    }
    // isEdit
    _.forEach(headerList, (r, i) => {
      // 区分(COMM率/定率/定額)の選択状態によって未選択の項目をクリア
      switch (r[0].commhKbn) {
        case '0':
          r[0].commhRate = 0
          r[0].commhSum = 0
          break;
        case '1':
          r[0].commhSum = 0
          itemList[i] = []
          break;
        case '2':
          r[0].commhRate = 0
          itemList[i] = []
          break;
      }
      _.forEach(r, (rec, idx) => {
        let isDirty = false
        if (r[0] && r[0].original) {
          _.forEach(LIST_ITEMS.commH, (col, j) => {
            isDirty = isDirty || (
              r[0][col.id] && r[0].original[col.id] && 
              _.isDate(r[0][col.id]) && _.isDate(r[0].original[col.id])
                ? r[0].original[col.id].valueOf() !== r[0][col.id].valueOf()
                : r[0].original[col.id] !== r[0][col.id])
          })
          r[0].isEdit = isDirty
        }
      })
    })

  // 編集内容を反映
    _.forEach(props.colDef, (r, i) => {
      if (master.companyDetail[r.id]) {
        data.item[r.id] = master.companyDetail[r.id].text
      }
    })
    // 
    let isHeaderEdit = false
    let isBodyEdit = false
    _.forEach(headerList, (r, i) => {
      // dirty
      isHeaderEdit = isHeaderEdit || r[0].isEdit
    })
    // dirty
    _.forEach(itemList, (list, i) => {
      _.forEach(list, (r, j) => {
        isBodyEdit = isBodyEdit || r.isAddingNew || r.isEdit
      })
    })
    data.item.detailButton = isHeaderEdit || isBodyEdit ? 'isEdit' : ''

    const header = _.concat(headerList[0], headerList[1], headerList[2], headerList[3], headerList[4], 
      master.companyCommHeaderList.filter(r => (props.data.item.isAddingNew? r.addRow!==props.data.item.addRow: r.commhComCd!==props.data.item.comCd)))
    const body = _.concat(itemList[0], itemList[1], itemList[2], itemList[3], itemList[4],
      master.companyCommBodyList.filter(r => (props.data.item.isAddingNew? r.addRow!==props.data.item.addRow: r.commbComCd!==props.data.item.comCd)))
    masterAction.setInputMaster({
      companyCommHeaderList: header,
      companyCommBodyList: body,
    })
// 削除ボタンの動作
    props.setData(data)
    popup.dialogResult = 'wj-hide-ok'
    popup.hide()
    setTimeout(() => setCommhCls(''))
    // masterAction.changeInputComDetail({
    //   id: 'commhCls',
    //   commhCls: {
    //     ...master.companyDetail.commhCls,
    //     text: '',
    //   }
    // })
  }

  const getHeaderColumn = () => {
    const column = []
    _.forEach(LIST_ITEMS.commH, (r, i) => {
      let obj = {}
      obj.visible = (r.isHeader===true)
      obj.void = !(r.isHeader===true)
      column.push({...r, ...obj})
    })
    return column
  }

  const getColumn = () => {
    const column = []
    _.forEach(LIST_ITEMS.commB, (r, i) => {
      let obj = {}
      obj.visible = (r.isHeader===true)
      obj.void = !(r.isHeader===true)
      switch (r.id) {
        case 'commbCustRate':
        case 'commbCommRate':
          r.editor = new InputNumber(document.createElement('div'), {
            min: r.min,
            max: r.max,
            format: r.format,
            // gotFocus: (s, e) => {
            //   const length = s.text.length
            //   setTimeout(() => {
            //     setSelectionRange(s.inputElement, length)
            //   })
            // }
          });
          break;
        default:
          break;
      }
      column.push({...r, ...obj})
    })
    return column
  }

  const gridEditEnded = (g, e) => {
    _.forEach(g.rows[e.row] && g.rows[e.row].dataItem, (v, k) => {
      if (g.columns[e.col].binding === 'commbCommRate' && k === 'commbCommRate') {
        g.rows[e.row].dataItem.commbOwnRate = g.rows[e.row].dataItem.commbCommRate - g.rows[e.row].dataItem.commbCustRate
      }
      if (g.columns[e.col].binding === 'commbCustRate' && k === 'commbCustRate') {
        g.rows[e.row].dataItem.commbOwnRate = g.rows[e.row].dataItem.commbCommRate - g.rows[e.row].dataItem.commbCustRate
      }
      g.refresh()
    })
  }
  
  React.useEffect(() => {
    if (addBodyRow > -1) {
      props.bodyGrid.collectionView.currentItem.commbComCd = props.data.item.comCd
      props.bodyGrid.collectionView.currentItem.commbCls = commhCls
      props.bodyGrid.collectionView.currentItem.addRow = props.data.item.addRow
      
      setAddBodyRow(-1)
    }
  }, [addBodyRow])

  React.useEffect(() => {
    switch (master.companyDetail.commhKbn.text) {
      case '0':
        masterAction.setInputMaster({
          companyDetail: {
            ...master.companyDetail,
            commhRate:{
              ...master.companyDetail.commhRate,
              disabled: true
            },
            commhSum:{
              ...master.companyDetail.commhSum,
              disabled: true
            }
          },
        })
        break;
      case '1':
        masterAction.setInputMaster({
          companyDetail: {
            ...master.companyDetail,
            commhRate:{
              ...master.companyDetail.commhRate,
              disabled: false
            },
            commhSum:{
              ...master.companyDetail.commhSum,
              disabled: true
            }
          },
        })
        break;
      case '2':
        masterAction.setInputMaster({
          companyDetail: {
            ...master.companyDetail,
            commhRate:{
              ...master.companyDetail.commhRate,
              disabled: true
            },
            commhSum:{
              ...master.companyDetail.commhSum,
              disabled: false
            }
          },
        })
        break;
    }
  }, [master.companyDetail.commhKbn.text])
  
  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
      >
        <Card 
          root='popup w-75vw'
          size='md'
          header={`詳細　${props.data && props.data.item.comCd}`}
          headerClass='wj-dialog-header'
          subheader={null}
          hideClose={false}
          onClose={(e) => {
            popup.hide('cancel')
            setTimeout(() => setCommhCls(''))
          }}
          body={
            <React.Fragment>
              <Card 
                root='popup h-75vh'
                size='md'
                hideClose
                header={null}
                subheader={null}
                body={
                <React.Fragment>
                  <div className='d-inline-block align-top width-p-60 mr-5'>
                    <div>
                      <Common.Text
                        attrs={master.companyDetail.comCd}
                        onBlur={(e) => {
                          // console.log(e)
                          masterAction.changeInputComDetail(e)
                          // console.log(e.comCd.text)
                          // const entry = entryCheck({comCd: master.companyDetail.comCd}, convert.getTarget(master.companyDetail.comCd, 'text', e.comCd.text))
                          // masterAction.setInputMaster({companyDetail: entry})
                        }}
                        group={{
                          ...props.group,
                          className: 'width-15 mr-5',
                        }}
                      />
                      <Form.Group className='width-max-content mr-5'>
                        <Form.Label>{master.companyDetail.comKbn.label}</Form.Label>
                        <Common.Radio
                          className='d-flex'
                          attrs={{
                            ...master.companyDetail.comKbn,
                            label: '',
                          }}
                          radios={COM_KBN_MAP}
                          onChange={(e) => masterAction.changeInputComDetail(e)}
                          // group={{
                          //   ...props.group,
                          //   className: 'width-max-content mr-0'
                          // }}
                        />
                      </Form.Group>
                      <Form.Group className='width-max-content mr-0'>
                        <Form.Label>{master.companyDetail.comCommtax.label}</Form.Label>
                        <Common.Radio
                          className='d-flex'
                          attrs={{
                            ...master.companyDetail.comCommtax,
                            label: '',
                          }}
                          radios={COM_TAX_MAP}
                          onChange={(e) => masterAction.changeInputComDetail(e)}
                        />
                      </Form.Group>
                    </div>
                    <Common.Text
                      attrs={master.companyDetail.comNamej}
                      onBlur={(e) => masterAction.changeInputComDetail(e)}
                      group={{
                        ...props.group,
                        className: 'w-100 mr-0',
                      }}
                    />
                    <Common.Text
                      attrs={master.companyDetail.comNamee}
                      onBlur={(e) => masterAction.changeInputComDetail(e)}
                      group={{
                        ...props.group,
                        className: 'w-100 mr-0',
                      }}
                    />
                    <Common.Text
                      attrs={master.companyDetail.comSecNamej}
                      onBlur={(e) => masterAction.changeInputComDetail(e)}
                      group={{
                        ...props.group,
                        className: 'w-100 mr-0',
                      }}
                    />
                    <Common.Text
                      attrs={master.companyDetail.comSecNamee}
                      onBlur={(e) => masterAction.changeInputComDetail(e)}
                      group={{
                        ...props.group,
                        className: 'w-100 mr-0',
                      }}
                    />
                    <div className='d-flex'>
                      <Common.Text
                        attrs={master.companyDetail.comTel}
                        onBlur={(e) => masterAction.changeInputComDetail(e)}
                        group={{
                          ...props.group,
                          className: 'width-p-45 mr-auto',
                        }}
                      />
                      <Common.Text
                        attrs={master.companyDetail.comFax}
                        onBlur={(e) => masterAction.changeInputComDetail(e)}
                        group={{
                          ...props.group,
                          className: 'width-p-45 ml-auto mr-0',
                        }}
                      />
                    </div>
                    <div className='d-flex'>
                      <Common.Text
                        attrs={master.companyDetail.comPostno}
                        onBlur={(e) => masterAction.changeInputComDetail(e)}
                        group={{
                          ...props.group,
                          className: 'width-p-45 mr-auto',
                        }}
                      />
                      <Common.Text
                        attrs={master.companyDetail.comAdddivj}
                        onBlur={(e) => masterAction.changeInputComDetail(e)}
                        group={{
                          ...props.group,
                          className: 'width-p-45 ml-auto mr-0',
                        }}
                      />
                    </div>
                    <Common.Text
                      attrs={master.companyDetail.comAdd1j}
                      onBlur={(e) => masterAction.changeInputComDetail(e)}
                      group={{
                        ...props.group,
                        className: 'w-100 mr-0',
                      }}
                    />
                    <Common.Text
                      attrs={master.companyDetail.comAdd2j}
                      onBlur={(e) => masterAction.changeInputComDetail(e)}
                      group={{
                        ...props.group,
                        className: 'w-100 mr-0',
                      }}
                    />
                    <Common.Text
                      attrs={master.companyDetail.comAdd1e}
                      onBlur={(e) => masterAction.changeInputComDetail(e)}
                      group={{
                        ...props.group,
                        className: 'w-100 mr-0',
                      }}
                    />
                    <Common.Text
                      attrs={master.companyDetail.comAdd2e}
                      onBlur={(e) => masterAction.changeInputComDetail(e)}
                      group={{
                        ...props.group,
                        className: 'w-100 mr-0',
                      }}
                    />
                  </div>
                  <div id='commh' className='d-inline-block width-p-35'>
                    <div className='commhTop'>
                      <Common.RadioButton
                        attrs={{
                          id: 'commhCls',
                          original: commhCls,
                          text: commhCls,
                          value: commhCls,
                          className: 'commhMenu',
                        }}
                        className='w-100'
                        radios={COMMH_CLS_MAP}
                        onChange={(e) => {setCommhCls(e.commhCls.text)}}
                      />
                    </div>
                    <div className='commhEdit'>
                      <div className='m-2'>
                        <Common.Radio
                          className='d-inline-flex flex-column align-top'
                          attrs={{...master.companyDetail.commhKbn, block:true}}
                          radios={COMMH_KBN_MAP}
                          onChange={(e) => {
                            masterAction.changeInputComDetail(e)
                            headerList[commhCls][0].commhKbn = e.commhKbn.text
                          }}
                        />
                        <div className='d-inline-flex flex-column'>
                          <Common.Number
                            attrs={master.companyDetail.commhRate}
                            onBlur={(e) => masterAction.changeInputComDetail(e)}
                            group={{
                              ...props.group,
                              className: 'width-10 mr-0',
                            }}
                          />
                          <Common.Number
                            attrs={master.companyDetail.commhSum}
                            onBlur={(e) => masterAction.changeInputComDetail(e)}
                            group={{
                              ...props.group,
                              className: 'width-10 mr-0',
                            }}
                          />
                        </div>
                        <div className='master-list-body company-popup' >
                          <Common.Grid
                            thisGrid={props.bodyGrid}
                            setGrid={props.setBodyGrid}
                            colDef={getColumn()}
                            dataList={dataList}
                            grid={{
                              isReadOnly: props.disabled,
                              allowAddNew: !props.disabled,
                              editEnded: (g, e) => {gridEditEnded(g, e)},
                              // itemsSourceChanging: (e) => setCommhCls('-1'),
                              // itemsSourceChanged: (e) => {
                              //   setCommhCls('0')
                              //   console.log(e)
                              // },
                              rowAdded: (g, e) => {setAddBodyRow(e.row)},
                              selectCol: 4,
                            }}
                            setInput={masterAction.setInputMaster}
                          />
                          <Common.Grid
                            className='d-none'
                            thisGrid={props.headerGrid}
                            setGrid={props.setHeaderGrid}
                            colDef={getHeaderColumn()}
                            dataList={headerList[commhCls]}
                            grid={{
                              isReadOnly: true,
                              allowAddNew: true,
                              // itemsSourceChanging: (e) => setCommhCls('-1'),
                              // itemsSourceChanged: (e) => {
                              //   setCommhCls('0')
                              //   console.log(e)
                              // },
                              // rowAdded: (g, e) => {setAddRow(e.row)},
                            }}
                            setInput={masterAction.setInputMaster}
                          />
                        </div>
                      </div>
                    <Button
                      className='button-gray btn-sm ml-2 mr-auto'
                      onClick={() => {
                        _.forEachRight(props.bodyGrid.itemsSource.items, (r, i) => {
                          if (r.isSelected) props.bodyGrid.itemsSource.removeAt(i)
                        })
                      }}
                      disabled={app.isProcessing || props.disabled}
                    >
                      <span className='material-icons-outlined md mr-2'>delete_forever</span>
                      削除
                    </Button>
                    </div>
                  </div>
                </React.Fragment>
              }
            />
            <Card 
              size='md'
              root={`popup-button ${props.size}`}
              // body={props.button}
              body={
                <React.Fragment>
                  <Button
                    className='button-gray mr-auto'
                    onClick={() => {
                      popup.hide('cancel')
                      setTimeout(() => setCommhCls(''))
                    }}
                    disabled={app.isProcessing}
                  >
                    <span className='material-icons-outlined md mr-2'>cancel</span>
                    キャンセル
                  </Button>
                  <Button
                    className='button-gray ml-auto'
                    onClick={() => {
                      execOk()
                    }}
                    disabled={master.editing || app.isProcessing || props.disabled}
                  >
                    <span className='material-icons-outlined md mr-2'>done</span>
                    OK
                  </Button>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      />
      </wjInput.Popup>
    </React.Fragment>
  )
})