import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { PivotAction } from 'ducks/Pivot';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { CHART_TYPE } from 'constants/pivot';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';
import { SummaryApi } from 'webapi'
import { Step } from './Step';
import { CLIENT_DOWNLOAD_URL } from 'constants/index';

export const Step4 = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const pivot = useSelector(state => state.Pivot);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);
  const pivotAction = bindActionCreators(PivotAction, dispatch);

  const execClient = () => {
    // PLUSクライアントDownload
    const linkEl = document.createElement('a');
    linkEl.href = 'plusclient:' + app.clientKey;
    document.body.appendChild(linkEl);
    appAction.setInputApp({isUnloadCheck: false})
    setTimeout(() => {
      linkEl.click();
      setTimeout(() => {
        appAction.setInputApp({isUnloadCheck: true})
        linkEl.parentNode.removeChild(linkEl);
      })
    })
  }

  return (
    <React.Fragment>
      <Card 
        root='popup'
        size='lg'
        hideClose
        header={null}
        headerClass='wj-dialog-header'
        body={
          <React.Fragment>
            <div className='step0 p-5'>
              <div className='step0-title'>
                URI-Proへのデータ移行が完了しました
              </div>
              <div className='step4-body'>
                <div>URI-Pro（クラウド版）の操作に関するお問い合わせは、Amadeusカスタマーサポートまでご連絡ください。</div>
                <div>本移行に関するお問い合わせは、Amadeus移行窓口までご連絡ください。</div>
              </div>
              {login.authCli === '1' &&
                <div className='step0-body'>
                  <div>※URI-Pro Plusをご利用の場合は、こちらからPlusクライアントを起動し、タイマー設定を再開してください。</div>
                  <div className='d-flex'>
                    <Button
                      className='button-blue width-20'
                      onClick={() => execClient()}
                      disabled={app.isProcessing}
                    >
                      <div className='d-flex flex-row align-items-center justify-content-center'>
                        <div className='d-inline-block'>Plusクライアント起動</div>
                      </div>
                    </Button>
                  </div>
                </div>
              }
              <div className='d-flex'>
                <Button
                  className='button-blue width-20 mx-auto'
                  onClick={props.action}
                  disabled={app.isProcessing}
                >
                  <div className='d-flex flex-row align-items-center justify-content-center'>
                    <div className='material-icons-outlined lg'>check_circle</div>
                    <div className='d-inline-block'>閉じる</div>
                  </div>
                </Button>
              </div>
            </div>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
})