import { handleActions, createAction } from 'redux-actions'
import { onChangeInput, initState } from 'lib'
import _ from 'lodash';

// const
export const PIVOT = {
  CHANGE_INPUT_PIVOT : 'CHANGE_INPUT_PIVOT',
  SET_INPUT_PIVOT : 'SET_INPUT_PIVOT',
  ADD_PIVOT_ITEM : 'ADD_PIVOT_ITEM',
  REMOVE_PIVOT_ITEM : 'REMOVE_PIVOT_ITEM',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
};

// action
export const PivotAction = {
  changeInputPivot: createAction(PIVOT.CHANGE_INPUT_PIVOT, (args) => (args)),
  setInputPivot: createAction(PIVOT.SET_INPUT_PIVOT, (args) => (args)),
  addPivotItem: createAction(PIVOT.ADD_PIVOT_ITEM, (args) => (args)),
  removePivotItem: createAction(PIVOT.REMOVE_PIVOT_ITEM, (args) => (args)),
  initSession: createAction(PIVOT.INIT_SESSION),
  clearSession: createAction(PIVOT.CLEAR_SESSION),
}

// initialState
const initialState = {
  // 検索
  sizeHorizontal: ['100%', '1'],
  sizeVertical: ['20%', '1'],
  sizeGraph: ['200px', '1'],
  condition: {
    id: 'condition',
    label: '',
    required: true,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  checkedItems: [
    
  ],
  checkedItemsSetting: [
  ],
  recentlyCondition: [],
  conditionPattern: [],
  patternList: [],
  patternName: {
    id: 'patternName',
    label: '名称',
    required: true,
    disabled: false,
    minLength: 0,
    maxLength: 30,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  searchUnit: {
    id: 'searchUnit',
    label: '',
    prefix: '表示単位',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
  },
  condClear: {
    id: 'clear',
    label: <span><span className='material-icons-outlined md'>delete_forever</span>検索条件全クリア</span>,
    disabled: false,
  },
  condEdit: {
    id: 'edit',
    label: <span><span className='material-icons-outlined md'>cloud_upload</span>検索条件パターン登録</span>,
    disabled: false,
  },
  search: {
    id: 'search',
    label: <span><span className='material-icons-outlined md'>search</span>発券データ検索</span>,
    disabled: false,
  },
  // 一覧表示
  dispCheckedItems: [
    {
      id: 'tktTktnoAl',
      header: 'TKT NO.（AIRLINEコード）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktTktnoSn',
      header: 'TKT NO.（S/N）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktPaxName',
      header: 'PAX NAME',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktStatus',
      header: 'TKTステータス',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktIsuDate',
      header: '発券日',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktDepDate',
      header: '出発日',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktDetailIssuedFlg',
      header: 'チケット明細発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
    },
  ],
  bulk: {},
  exec: {
    id: 'exec',
    label: <span><span className='material-icons-outlined md'>done</span>設定</span>,
    disabled: false,
  },
  research: {
    id: 'research',
    label: <span><span className='material-icons-outlined md'>search</span>再検索</span>,
    disabled: false,
  },
  // --
  nsbFlg: {
    id: 'nsbFlg',
    label: 'NSB額が計算できなかった時',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  nsbOption: {
    id: 'nsbOption',
    label: '_none',
    required: false,
    disabled: true,
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  faretypeConversionFlg: {
    id: 'faretypeConversionFlg',
    label: '運賃種別PEXをOTHに変換して出力',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  taxIncludeFlg: {
    id: 'taxIncludeFlg',
    label: 'SHAREにTAX含む',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  productionJudgeFlg: {
    id: 'productionJudgeFlg',
    label: 'PRODUCTIONを判定',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  voidPrintFlg: {
    id: 'voidPrintFlg',
    label: 'VOID券の金額を0で表示',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  cnjtktAmountPringFlg: {
    id: 'cnjtktAmountPringFlg',
    label: '親TKTが表示されない先頭の子TKTデータに金額を表示',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  // 
  displayRowTotalFlg: {
    id: 'displayRowTotalFlg',
    label: '行の合計を表示する',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  displayRowSubtotalFlg: {
    id: 'displayRowSubtotalFlg',
    label: '行の小計を表示する',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  displayColumnTotalFlg: {
    id: 'displayColumnTotalFlg',
    label: '列の合計を表示する',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  displayColumnSubtotalFlg: {
    id: 'displayColumnSubtotalFlg',
    label: '列の小計を表示する',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  displayComponentRatioFlg: {
    id: 'displayComponentRatioFlg',
    label: '構成比率を同時に表示',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  displayYoyFlg: {
    id: 'displayYoyFlg',
    label: '前年比率を同時に表示',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  graphType: {
    id: 'graphType',
    label: 'グラフ種別',
    required: false,
    disabled: false,
    original: 'Column',
    value: 'Column',
    text: 'Column',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  // 
  viewDefinition: {
    id: 'viewDefinition',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  // 
  reportTitle: {
    id: 'reportTitle',
    label: 'レポートタイトル',
    required: true,
    disabled: false,
    minLength: 0,
    maxLength: 50,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },

  //
  new: {
    id: 'new',
    label: <span><span className='material-icons-outlined md'>note_add</span>新規レポート</span>,
    disabled: false,
  },
  edit: {
    id: 'edit',
    label: <span><span className='material-icons-outlined md'>cloud_upload</span>保存</span>,
    disabled: false,
  },
  copy: {
    id: 'copy',
    label: <span><span className='material-icons-outlined md'>content_copy</span>複写</span>,
    disabled: false,
  },
  delete: {
    id: 'delete',
    label: <span><span className='material-icons-outlined md'>delete</span>削除</span>,
    disabled: false,
  },
  print: {
    id: 'print',
    label: <span><span className='material-icons-outlined md'>print</span>印刷</span>,
    disabled: false,
  },
  excel: {
    id: 'excel',
    label: <span><span className='material-icons-outlined md'>file_download</span>EXCEL出力</span>,
    disabled: false,
  },
}

// reducer
export const PivotReducer = handleActions({
  [PivotAction.changeInputPivot]: (state, action) => (onChangeInput(state, action.payload)),
  [PivotAction.setInputPivot]: (state, action) => ({...state, ...action.payload,}),
  [PivotAction.addPivotItem]: (state, action) => ({
    ...state,
    checkedItems: _.concat(state.checkedItems, action.payload)
  }),
  [PivotAction.removePivotItem]: (state, action) => {
    const obj = Object.assign({}, state)
    _.map(state, (v,k) => {
      if (k.startsWith(action.payload[0].key + '_')) delete obj[k]
    })
    return ({
    ...obj,
    checkedItems: _.reject(state.checkedItems, {header: action.payload[0].header})//keyに変更
  })},
  [PivotAction.initSession]: (state, action) => (initState(initialState)),
  [PivotAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)
