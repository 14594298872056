import React from 'react';
import { Calendar } from '@grapecity/wijmo.react.input';
import reactDom from 'react-dom';
import * as wjInput from '@grapecity/wijmo.react.input';
import { 
  Form,
  InputGroup,
  Row,
  Col,
} from 'react-bootstrap';
import _ from 'lodash';
import { convert } from "lib";

export const Mask = React.forwardRef((props, ref) => {

  const {attrs} = props;
  const [controll, setControll] = React.useState(null);
  const [text, setText] = React.useState('');

  const _initialize = (ctrl) => {
    setControll(ctrl)
    ctrl.inputElement.id = attrs.id;
  }

  const changeInput = (c, e) => {
    let newValue = c.value;
    if (attrs.maxLength && attrs.maxLength >= 0) newValue = c.value.substr(0,attrs.maxLength);
    setText(newValue);
  }

  React.useEffect(() => {
    const txt = _.isNil(attrs.text)?'':attrs.text
    setText(txt);
  }, [attrs.text])

  React.useEffect(() => {
    if (controll) {
      if (attrs.helpertext.length > 0){
        controll.hostElement.classList.add('is-invalid');
        controll.inputElement.classList.add('is-invalid');
      } else {
        controll.hostElement.classList.remove('is-invalid');
        controll.inputElement.classList.remove('is-invalid');
      }
    }
  }, [attrs.helpertext])

  return (
    <React.Fragment>
      <Form.Group
        controlId={attrs.id}
        {...props.group}
        className={`${props.group.className} ${attrs.visible===false?'d-none':''}`}
      >
        <Form.Label
          sm="3"
          className={
            `${attrs.required && 'label-required'} ${attrs.label==='_none' && 'd-none'} ${!attrs.labelclass?'':attrs.labelclass}`
          }
        >
          {attrs.label}
        </Form.Label>
        <InputGroup>
          <InputGroup.Text
            sm="3"
            className={`${!attrs.prefix && 'd-none'} text-prefix`}
            {...props.prefix}
          >
            {attrs.prefix}
          </InputGroup.Text>
          <wjInput.InputMask
            {...props.control}
            isRequired={false}
            mask={attrs.mask}
            inputType={attrs.type}
            isInvalid={attrs.isInvalid}
            value={text}
            required={attrs.required}
            isDisabled={attrs.disabled}
            initialized={(control) => _initialize(control)}
            // maxLength={attrs.maxLength}
            // minLength={attrs.minLength}
            placeholder={attrs.placeholder}
            valueChanged={(c, e) =>{ 
              changeInput(c, e)
              if (props.onChange) props.onChange(convert.getTarget(attrs, 'text', c.value));
            }}
            lostFocus={(c, e) => {props.onBlur(convert.getTarget(attrs, 'text', c.value))}}
            onInput={(e) => props.onInput && props.onInput(e)}
            ref={ref}
          />
          <InputGroup.Text
            sm="3"
            className={`${!attrs.suffix && 'd-none'} text-suffix`}
            {...props.suffix}
          >
            {attrs.suffix}
          </InputGroup.Text>
          <Form.Control.Feedback type="invalid">
            {attrs.helpertext}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </React.Fragment>
  )

    // onKeyPress={e => {
    //   if (e.key === 'Enter' && !attrs.multiline) {
    //     document.getElementById(id).blur();
    //     window.setTimeout(()=>{
    //       if (enter && !document.querySelector(enter).disabled) {
    //         document.querySelector(enter).click();
    //       } else {
    //         // node.focus()
    //       }
    //       // e.preventDefault(); 
    //       return false;
    //     })
    //   }
    // }}
    // {...Inputattrs}
    // {...attrs}
    // onChange={(e) => setValue(e)}
  //   onBlur={(e) => {
  //   }}
  // />;
})
