import { axiosInstance, axiosInstanceForBlob } from 'http/index'

export const ProcessApi = {
  init: (data) => {
    const instance = axiosInstance;
    return instance.post('/process/init', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  getList: (data) => {
    const instance = axiosInstance;
    return instance.post('/process/getList', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  get: (data) => {
    const instance = axiosInstance;
    return instance.get('/process/get/' + data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
}
