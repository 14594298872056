import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { SystemAction } from 'ducks/System';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { CHART_TYPE } from 'constants/pivot';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';
import { SystemApi } from 'webapi'
import { format } from '@grapecity/wijmo';

export const ConfirmPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const system = useSelector(state => state.System);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);
  const systemAction = bindActionCreators(SystemAction, dispatch);

  const [confirmText, setConfirmText] = React.useState('')

  const initPopup = (form) => {
    props.setPopup(form)
  }

  const action = () => {
    const dto = {
      appCode: 'exec',
      sysDatamentIniDto: {
        ...props.popupData.data,
        // dmiPeriod: system.data.dmiPeriod.text,
        // dmiPeriodMm: system.data.dmiPeriodMm.text,
        // dmiPeriodDate: convert.toDateString(system.data.dmiPeriodDate.text, 'yyyy-MM-dd'),
        // dmiDateType: system.data.dmiDateType.text,
        // dmiOpentktType: system.data.dmiOpentktType.text,
        // dmiBackup: ,
        // dmiMbrcd: ,
        // dmiExecDatetime: ,
      },
    }

    appAction.showProcessing({isProcessing: true})
    SystemApi.edit(system.type, dto)
    .then((res) => {
      appAction.setMessage({message: res.payload.messageList})
    })
    .catch(() =>{})
    .finally(() => {
      props.popup.hide('ok')
      appAction.showProcessing({isProcessing: false})
    })
  }

  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
      >
        <Card 
          root='popup'
          size='lg'
          hideClose
          header={props.header}
          headerClass='wj-dialog-header'
          subheader={props.subheader}
          // body={props.body}
          body={
            <React.Fragment>
              <div className='confirm-body'>
                <div className='title'>データメンテナンス実行確認</div>
                <div>指定された条件で、該当する発券データを削除します。</div>
                <div>当処理によって削除されたデータは<span className='warn-color'>復旧できません</span>ので十分注意してください。</div>
                <div className='mt-4'>・削除条件：
                  <span className='warn-color'>{props.popupData.dmiDateType}</span>が
                  <span className='warn-color'>{props.popupData.deleteDate}</span>以前のデータが削除されます。
                </div>
                <div>・OPEN TKT取り扱い：{props.popupData.dmiOpentktType}</div>
                <div className='my-4'>今回の処理で削除されるデータ件数は、<span className='warn-color'>{format('{count:n0}', {count: props.popupData.deleteNum})}件</span>です。</div>
                <div className='confirm-sign'>
                  <div>上記内容で実施する場合、『実行』と入力して、実行ボタンをクリックしてください。</div>
                  <Common.Text 
                    attrs={{
                      label: '_none',
                      id: 'confirmText',
                      maxLength: 4,
                    }}
                    onBlur={(e) => setConfirmText(e.confirmText.text)}
                    group={{
                      className: 'width-10 mr-0 mb-2',
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          }
        />
        <Card 
          size='md'
          root={`popup-button ${props.size}`}
          // body={props.button}
          body={
            <React.Fragment>
              <Button className='button-gray mr-auto wj-hide-cancel'>
                <span className='material-icons-outlined md mr-2'>cancel</span>
                キャンセル
              </Button>
              <Button
                className='button-blue ml-auto'
                onClick={() => action()}
                disabled={app.isProcessing || confirmText!=='実行'}
              >
                実行
              </Button>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})