import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { SystemAction } from 'ducks/System';

import * as Common from 'components/common';
import { Card } from 'components';
import { ConfirmPopup } from './modal/ConfirmPopup';
import {
  OverlayTrigger,
  Tooltip,
  Col,
  Row,
  Container,
  Form,
  Button,
  Toast,
  Image,
} from 'react-bootstrap';
import { convert, entryCheck, alert, dataMapper, useUnmountRef, useSafeState } from "lib";
import _ from 'lodash';
import * as wijmo from '@grapecity/wijmo';
import { CellRange, DataMap } from "@grapecity/wijmo.grid";
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import { InputDate, ComboBox, AutoComplete, MultiAutoComplete, InputColor, InputNumber } from '@grapecity/wijmo.input';

import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { DMI_PERIOD, DMI_DATE_TYPE, DMI_OPENTKT_TYPE } from 'constants/system';
import { SystemApi } from 'webapi'

export const MaintComponent = (props) => {

  const app = useSelector(state => state.App);
  const system = useSelector(state => state.System);
  const login = useSelector(state => state.Login);

  const history = useHistory();
  const location = useLocation();
  const { type } = useParams();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const systemAction = bindActionCreators(SystemAction, dispatch);
  
  const unmountRef = useUnmountRef();
  
  const [confirm, setConfirm] = useSafeState(unmountRef, {
    dmiDateType: '',
    deleteDate: '',
    deleteNum: '',
    dmiOpentktType: '',
  })
  const [popup, setPopup] = useSafeState(unmountRef, null)

  const getData = (keepMessage) => {
    if (system.type!=='maint') return
    appAction.showProcessing({isProcessing: true})
    if (!keepMessage) appAction.setMessage('')

    SystemApi.search('maint', {})
    .then(res => {//console.log(res)
      if (res.payload.code === '000' || res.payload.code === '100') {
        // console.log(res.payload)
        let ui = {data: {}};
        // ui originalへ設定
        const original = (value, def) => {
          if (def.date) return convert.toDateString(value, 'yyyy/MM/dd')
              ? new Date(convert.toDateString(value, 'yyyy/MM/dd'))
              : null
          if (def.number) return value?_.toNumber(value):null
          return _.isNil(value)?'':value;
        }
        ui.data.fltBoardDate = res.payload.fltBoardDate?convert.toDateString(res.payload.fltBoardDate, 'yyyy/MM/dd'):null
        ui.data.tktDepDate = res.payload.tktDepDate?convert.toDateString(res.payload.tktDepDate, 'yyyy/MM/dd'):null
        ui.data.tktIsuDate = res.payload.tktIsuDate?convert.toDateString(res.payload.tktIsuDate, 'yyyy/MM/dd'):null
        _.forEach(res.payload.sysDatamentIniDto, (v, k) => {
          if (system.data[k]) {
            ui.data[k] = {
              ...system.data[k],
              original: original(v, system.data[k]),
              text: original(v, system.data[k]),
              value: original(v, system.data[k]),
              helpertext: '',
              isInvalid: false,
              dirty: '',
            }
          }
        })

        ui.data.dmihMentDate = res.payload.dmihMentDate?convert.toDateString(res.payload.dmihMentDate, 'yyyy/MM/dd'):null
        ui.data.period = dataMapper(DMI_PERIOD, 'value', 'name', res.payload.sysDatamentIniDto.dmiPeriod)
          + '(' + (
            res.payload.sysDatamentIniDto.dmiPeriod === '1'?
            res.payload.sysDatamentIniDto.dmiPeriodMm:
            res.payload.sysDatamentIniDto.dmiPeriodDate?convert.toDateString(res.payload.sysDatamentIniDto.dmiPeriodDate, 'yyyy/MM/dd'):''
          ) + ')'
        ui.data.dateType = dataMapper(DMI_DATE_TYPE, 'value', 'name', res.payload.sysDatamentIniDto.dmiDateType)
        ui.data.opentktType = dataMapper(DMI_OPENTKT_TYPE, 'value', 'name', res.payload.sysDatamentIniDto.dmiOpentktType)
        
        // console.log(ui)
        systemAction.setInputSystem({...ui})
        
      } else {
        // error
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  React.useEffect(() => {
    if (props.isModal) {
      if (!props.isOpen) {
        return
      } else {
        getData()
      }
    } else {
      if (system.type === type) getData()
    }
  }, [props.isOpen, system.type])

  const execSave = () => {
    // let entry = {};
    // let hasError = false;
    // _.forEach(system.data, (v, k) => {
    //   if (v && !_.isUndefined(v.value)) {
    //     entry[k] = entryCheck(system.data, convert.getTarget(v, 'text', v.text))[k]
    //     hasError = hasError || entry[k].isInvalid
    //   }
    // })
    // systemAction.setInputSystem({
    //   data: {
    //     ...system.data,
    //     ...entry,
    //   },
    // })
    // if (hasError) return

    if (system.data.dmiPeriod.text === '1' && !system.data.dmiPeriodMm.text) {
      appAction.setMessage({message: [{message: 'データ保有月数を入力してください。', type: 'error'}]})
      return
    }
    if (system.data.dmiPeriod.text === '0' && !system.data.dmiPeriodDate.text) {
      appAction.setMessage({message: [{message: '対象日を入力してください。', type: 'error'}]})
      return
    }

    const dto = {
      appCode: 'setting',
      isTimerAccess: props.isModal,
      sysDatamentIniStringDto: {
        dmiPeriod: system.data.dmiPeriod.text,
        dmiPeriodMm: system.data.dmiPeriodMm.text,
        dmiPeriodDate: convert.toDateString(system.data.dmiPeriodDate.text, 'yyyy-MM-dd'),
        dmiDateType: system.data.dmiDateType.text,
        dmiOpentktType: system.data.dmiOpentktType.text,
        // dmiBackup: ,
        // dmiMbrcd: ,
        // dmiExecDatetime: ,
      },
    }

    appAction.showProcessing({isProcessing: true})
    SystemApi.edit('maint', dto)
    .then((res) => {
      if (res.payload.code === '000') {
        if (!props.isModal) {
          setConfirm({
            dmiDateType: dataMapper(DMI_DATE_TYPE, 'value', 'name', res.payload.sysDatamentIniDto.dmiDateType),
            deleteDate: convert.toDateString(new Date(res.payload.sysDatamentIniDto.deleteDate), 'yyyy/MM/dd'),
            deleteNum: res.payload.sysDatamentIniDto.deleteNum,
            dmiOpentktType: dataMapper(DMI_OPENTKT_TYPE, 'value', 'name', res.payload.sysDatamentIniDto.dmiOpentktType),
            data: res.payload.sysDatamentIniDto,
          })
          popup.show(true, (sender) => {
            // if (document.getElementById(['uploadPath'])) document.getElementById(['uploadPath']).value = '';
            if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
              // console.log('ok')
              getData(true)
            } else {
              // console.log('cancel')
            }
          })
        } else {
          appAction.setMessage({message: res.payload.messageList})
          props.onSuccess()
        }
      } else {
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  React.useEffect(() => {
    systemAction.setInputSystem({
      data: {
        ...system.data,
        dmiPeriodMm: {
          ...system.data.dmiPeriodMm,
          // required: (system.data.dmiPeriod.text === '1'),
          disabled: (system.data.dmiPeriod.text !== '1'),
        },
        dmiPeriodDate: {
          ...system.data.dmiPeriodDate,
          // required: (system.data.dmiPeriod.text !== '1'),
          disabled: (system.data.dmiPeriod.text === '1'),
        },
      }
    })
  }, [system.data.dmiPeriod.text])

  return (
    <React.Fragment>
      <ConfirmPopup
        popup={popup}
        setPopup={setPopup}
        popupData={confirm}
        setPopupData={setConfirm}
      />
      <Card
        size='md'
        root='max-button'
        hideClose
        header={null}
        subheader={null}
        body={
          <React.Fragment>
            <Card
              size='lg'
              // root='max'
              hideClose
              header={TITLE.system.data}
              subheader={null}
              body={
                <div className='d-inline-flex flex-column width-p-100 mb-5'>
                  <div className='d-inline-flex ml-5 mt-5 mb-4 align-items-baseline prev-datamaint'>
                    <div className='mt-2 mr-0'><label sm="3" className='form-label text-left width-12'>前回処理条件</label></div>
                    <div className='mt-2 mr-2'><label sm="3" className='form-label text-left'>前回実行日:</label></div>
                    <div className='mr-4'><label sm="3" className='form-label text-left'>{system.data.dmihMentDate?system.data.dmihMentDate:'履歴無し'}</label></div>
                    <div className='mt-2 mr-0'><label sm="3" className='form-label text-left'>実行ピリオド:</label></div>
                    <div className='mr-4'><label sm="3" className='form-label text-left'>{system.data.dmihMentDate?system.data.period:'履歴無し'}</label></div>
                    <div className='mt-2 mr-0'><label sm="3" className='form-label text-left'>切替日基準:</label></div>
                    <div className='mr-4'><label sm="3" className='form-label text-left'>{system.data.dmihMentDate?system.data.dateType:'履歴無し'}</label></div>
                    <div className='mt-2 mr-0'><label sm="3" className='form-label text-left'>OPEN TKTの取り扱い:</label></div>
                    <div className='mr-0'><label sm="3" className='form-label text-left'>{system.data.dmihMentDate?system.data.opentktType:'履歴無し'}</label></div>
                  </div>
                  <div className='d-inline-flex ml-5 mt-5 mb-4'>
                    <div className='mt-2 mr-0'><label sm="3" className='form-label text-left width-12'>実行ピリオド</label></div>
                    <div className='mr-5'>
                      <Common.Radio
                        attrs={{...system.data.dmiPeriod, block:true}}
                        radios={DMI_PERIOD}
                        className='mt-2 mb-4'
                        onChange={(e) => systemAction.changeInputData(e)}
                      />
                    </div>
                    <div className='d-block'>
                      <div className='mb-2'>
                        <label className='text-left width-auto'>指定された保有月数以前のデータを削除します。データ保有月数</label>
                        <Common.Number
                          attrs={{...system.data.dmiPeriodMm}}
                          onBlur={(e) => systemAction.changeInputData(e)}
                          group={{
                            className: 'width-5'
                          }}
                        />
                        <label className='text-left width-auto'>ヶ月</label>
                      </div>
                      <div className='d-inline-flex align-items-center'>
                        <Common.DateTime
                          attrs={{...system.data.dmiPeriodDate}}
                          onBlur={(e) => systemAction.changeInputData(e)}
                          group={{
                            className: 'width-max-content'
                          }}
                        />
                        <label className='text-left width-auto'>までのデータを削除します。</label>
                      </div>
                    </div>
                  </div>
                  <div className='d-inline-flex ml-5'>
                    <div className='mr-0'><label sm="3" className='form-label text-left width-12'>切替日基準</label></div>
                    <div className='d-inline-flex align-items-end'>
                      <Common.Radio
                        attrs={{...system.data.dmiDateType}}
                        radios={DMI_DATE_TYPE}
                        className='mr-5'
                        onChange={(e) => systemAction.changeInputData(e)}
                      />
                    </div>
                  </div>
                  <div className='d-inline-flex ml-5 mb-5'>
                    <div className='mr-0'><label sm="3" className='form-label text-left width-12'>(最も古い対象日付)</label></div>
                    <div className='d-inline-flex align-items-end ml-3'>
                      <div className='width-10'>({system.data.fltBoardDate})</div>
                      <div className='width-10'>({system.data.tktIsuDate})</div>
                      <div className='width-10'>({system.data.tktDepDate})</div>
                    </div>
                  </div>
                  <div className='d-inline-flex ml-5'>
                    <div className='mr-0'><label sm="3" className='form-label text-left width-12'>OPEN TKTの取り扱い</label></div>
                    <Common.Radio
                      attrs={{...system.data.dmiOpentktType}}
                      radios={DMI_OPENTKT_TYPE}
                      onChange={(e) => systemAction.changeInputData(e)}
                    />
                  </div>
                </div>
              }
            />
          </React.Fragment>
        }
      />
      <Card 
        size='lg'
        root='button'
        hideClose
        header={null}
        subheader={null}
        body={
          <div className='card-buttom-button'>
          {props.isModal &&
            <React.Fragment>
              <Button
              {...system.cancel}
              className='button-gray mr-auto width-max-content'
              disabled={app.isProcessing}
              onClick={() => props.onClose()}
              >
                {system.cancel.label}
              </Button>
              <Button
                {...system.setting}
                className='button-blue mt-auto ml-auto'
                onClick={() => execSave()}
                disabled={app.isProcessing || (login.auth110 && login.auth110!=='1')}
              >
                {system.setting.label}
              </Button>
            </React.Fragment>
          }
          {!props.isModal &&
            <Button
              {...system.run}
              className='button-blue mt-auto ml-auto'
              onClick={() => execSave()}
              disabled={app.isProcessing || (login.auth110 && login.auth110!=='1')}
            >
              {system.run.label}
            </Button>
          }
          </div>
        }
      />
    </React.Fragment>
  )
}