import React from 'react';
import { Calendar } from '@grapecity/wijmo.react.input';
import reactDom from 'react-dom';
import { 
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
} from 'react-bootstrap';
import _ from 'lodash';
import { convert } from "lib";

export const Text = React.forwardRef((props, ref) => {

  const {attrs} = props;
  const [text,setText] = React.useState('');

  const changeInput = (e) => {
    let newValue = e.target.value;
    if (attrs.maxLength && attrs.maxLength >= 0) newValue = e.target.value.substr(0,attrs.maxLength);
    setText(newValue);
  }

  React.useEffect(() => {
    const text = _.isNil(attrs.text)?'':attrs.text
    setText(text);
  }, [attrs.text])

  return (
    <React.Fragment>
      <Form.Group
        controlId={attrs.id}
        {...props.group}
        className={`${props.group.className} ${attrs.visible===false?'d-none':''}`}
      >
        <OverlayTrigger overlay={<Tooltip className={`${attrs.labelTooltip?'':'d-none'}`}>{attrs.labelTooltip}</Tooltip>}>
          <Form.Label
            sm="3"
            className={
              `${attrs.required && 'label-required'} ${attrs.label==='_none' && 'd-none'} ${!attrs.labelclass?'':attrs.labelclass}`
            }
          >
            {attrs.label}
          </Form.Label>
        </OverlayTrigger>
        <InputGroup>
          <InputGroup.Text
            sm="3"
            className={`${!attrs.prefix && 'd-none'} text-prefix`}
            {...props.prefix}
          >
            {attrs.prefix}
          </InputGroup.Text>
          <OverlayTrigger overlay={<Tooltip className={`${attrs.textTooltip?'':'d-none'}`}>{attrs.textTooltip}</Tooltip>}>
            <Form.Control
              as={attrs.as?attrs.as:'input'}
              {...props.control}
              type={attrs.type?attrs.type:'text'}
              isInvalid={attrs.isInvalid}
              value={text}
              required={attrs.required}
              disabled={attrs.disabled}
              maxLength={attrs.maxLength}
              minLength={attrs.minLength}
              placeholder={attrs.placeholder}
              onChange={(e) => {
                changeInput(e)
                if (props.onChange) props.onChange(convert.getTarget(attrs, 'text', e.target.value));
              }}
              onBlur={(e) => {
                const value = props.onBlur(convert.getTarget(attrs, 'text', e.target.value))
                if (props.isBlurChange && value) {
                  e.target.value = value
                  changeInput(e)}
              }}
              onInput={(e) => props.onInput && props.onInput(e)}
              onKeyPress={e => {
                if (e.key === 'Enter' && attrs.as !== 'textarea') {
                  document.getElementById(attrs.id).blur();
                  window.setTimeout(()=>{
                    if (attrs.enter && !document.querySelector(attrs.enter).disabled) {
                      document.querySelector(attrs.enter).click();
                    } else {
                      // node.focus()
                    }
                    // e.preventDefault(); 
                    return false;
                  })
                }
              }}
              ref={ref}
            />
          </OverlayTrigger>
          <InputGroup.Text
            sm="3"
            className={`${!attrs.suffix && 'd-none'} text-suffix`}
            {...props.suffix}
          >
            {attrs.suffix}
          </InputGroup.Text>
          <Form.Control.Feedback type="invalid">
            {attrs.helpertext}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </React.Fragment>
  )
})
