import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import reactDom from 'react-dom';
import { Form, InputGroup} from 'react-bootstrap';
import * as wijmo from '@grapecity/wijmo';
import * as wjInput from '@grapecity/wijmo.react.input';
import _ from 'lodash';
import { validation } from "lib";
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { AppAction } from 'ducks/App';
import { alert, getInitData } from "lib";

export const Select = React.forwardRef((props, ref) => {

  const {attrs} = props;
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);

  const [sender, setSender] = React.useState(null);

  React.useEffect(() => {
    if (sender) {
      let index = -1
      const text = _.forEach(props.list, (r, i) => {
        if (r.value === _.toString(attrs.text)) index = i
      })
      sender.selectedIndex = index
    }
  }, [sender, attrs.text])

  return (
    <React.Fragment>
      <Form.Group
        controlId={attrs.id}
        {...props.group}
        className={`form-group ${props.group.className} ${attrs.visible===false?'d-none':''}`}
      >
        <Form.Label
          sm="3"
          className={
            `${attrs.required && 'label-required'} ${attrs.label==='_none' && 'd-none'}`
          }
        >
          {attrs.label}
        </Form.Label>
        <InputGroup>
          <div
            className={`${props.group.textwidth} d-inline-flex ${attrs.isInvalid?'is-invalid':''}`}
            style={{'flexWrap': 'nowrap'}}
          >
            <wjInput.ComboBox
              headerPath={props.selectedValuePath}
              isInvalid={attrs.isInvalid}
              required={attrs.required}
              disabled={attrs.disabled}
              maxLength={attrs.maxLength}
              minLength={attrs.minLength}
              delay={attrs.delay}
              placeholder={attrs.placeholder}
              isDisabled={attrs.disabled}
              className={`${attrs.isInvalid?'is-invalid':''}` + `${props.group.textwidth}`}
              itemsSource={props.list}
              selectedIndexChanged={(s) => {
                const target = {id: attrs.id}
                target[attrs.id] = {
                  ...attrs,
                  text: s.selectedValue && s.selectedValue.value?s.selectedValue.value:'',
                }
                props.onChange(target)
              }}
              // className={props.className}
              isRequired={attrs.isRequired?attrs.isRequired:false}
              displayMemberPath={'name'}
              initialized={(s) => setSender(s)}
              {...props}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            {attrs.helpertext}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </React.Fragment>
  )

    // onKeyPress={e => {
    //   if (e.key === 'Enter' && !attrs.multiline) {
    //     document.getElementById(id).blur();
    //     window.setTimeout(()=>{
    //       if (enter && !document.querySelector(enter).disabled) {
    //         document.querySelector(enter).click();
    //       } else {
    //         // node.focus()
    //       }
    //       // e.preventDefault(); 
    //       return false;
    //     })
    //   }
    // }}
    // {...Inputattrs}
    // {...attrs}
    // onChange={(e) => setValue(e)}
  //   onBlur={(e) => {
  //   }}
  // />;
})
