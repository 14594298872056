// import React from 'react';
import _ from 'lodash';

export const onChangeInput = (state, payload) => {
  const ret = {};
  const id = payload.id;
  const check = state[id];
  let newValue = payload[id].text

  //
  if (!check) return state;
  //
  // // checkboxの場合、checkedをvalueにセット
  // let newValue = (payload.target.type === 'checkbox')?
  //                   payload.target.checked:
  //                   payload.target.value;

  // 大文字変換
  if (check.stringcase==='upper') newValue = newValue.toUpperCase();

  ret[id] = {
    ...check,
    text: newValue,
    // value: newValue,
    dirty: _.toString(check.original)!==_.toString(newValue)?'dirty':'',
  }

  return {
    ...state,
    ...ret,
  }
}
