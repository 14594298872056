import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import * as wijmo from '@grapecity/wijmo';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { LIST_ITEMS, PDT_FARE_ID, TBL_TYPE, TKTKBN_MAP } from 'constants/master';
import { HEADER, TMR_INTERVAL, TMR_WEEKLY, TMR_MONTHLY } from 'constants/timer';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';
import { TimerAction } from 'ducks/Timer';

export const IntervalPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const timer = useSelector(state => state.Timer);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const timerAction = bindActionCreators(TimerAction, dispatch);

  const [pat, setPat] = React.useState('')

  const initPopup = (form) => {
    props.setPopup(form)
  }

  React.useEffect(() => {
    if (props.popup && props.popup.isVisible) {
      let initDate = new Date()
      initDate.setHours('00')
      initDate.setMinutes('00')
      initDate.setSeconds('00')
      const obj = {...timer.timerReponseDto[props.popupData]}
      timerAction.changeInputTimer(convert.getTarget(timer.tmrInterval, 'text', obj.tmrInterval?obj.tmrInterval:'S'))
      let date = obj.tmrIntervalOnce?new Date(convert.datehhmm2string(obj.tmrIntervalOnce)):null
      timerAction.changeInputTimer(convert.getTarget(timer.tmrIntervalOnce, 'text', date))
      timerAction.changeInputTimer(convert.getTarget(timer.tmrIntervalDaily, 'text', obj.tmrIntervalDaily?convert.hhmm2date(obj.tmrIntervalDaily):initDate))
      timerAction.changeInputTimer(convert.getTarget(timer.tmrIntervalWeekly, 'text', obj.tmrIntervalWeekly?obj.tmrIntervalWeekly:'1'))
      timerAction.changeInputTimer(convert.getTarget(timer.tmrIntervalWeeklyTime, 'text', obj.tmrIntervalWeeklyTime?convert.hhmm2date(obj.tmrIntervalWeeklyTime):initDate))
      timerAction.changeInputTimer(convert.getTarget(timer.tmrIntervalMonthly, 'text', obj.tmrIntervalMonthly?obj.tmrIntervalMonthly:'1'))
      timerAction.changeInputTimer(convert.getTarget(timer.tmrIntervalMonthlyTime, 'text', obj.tmrIntervalMonthlyTime?convert.hhmm2date(obj.tmrIntervalMonthlyTime):initDate))
      if (obj.tmrIntervalTime && obj.tmrIntervalTime.length > 0) {
        timerAction.changeInputTimer(convert.getTarget(timer.tmrIntervalTimeHour, 'text', _.toNumber(obj.tmrIntervalTime.substring(0,2))))
        timerAction.changeInputTimer(convert.getTarget(timer.tmrIntervalTimeMin, 'text', _.toNumber(obj.tmrIntervalTime.substring(2,4))))
      } else {
        timerAction.changeInputTimer(convert.getTarget(timer.tmrIntervalTimeHour, 'text', 0))
        timerAction.changeInputTimer(convert.getTarget(timer.tmrIntervalTimeMin, 'text', 0))
      }
      date = obj.tmrOutTo?new Date(convert.datehhmm2string(obj.tmrOutTo)):initDate
      timerAction.changeInputTimer(convert.getTarget(timer.tmrOutTo, 'text', date))
    }
  }, [props.popupData, props.popup && props.popup.isVisible])

  const action = () => {
    // console.log(timer.timerReponseDto[props.popupData])
    appAction.setMessage('')

    if (timer.tmrInterval.text === 'I') {
      // console.log(timer.tmrIntervalTimeHour.text)
      if (!timer.tmrIntervalTimeHour.text && !timer.tmrIntervalTimeMin.text) {
        timerAction.setInputTimer({
          tmrIntervalTimeHour: {
            ...timer.tmrIntervalTimeHour,
            isInvalid: true,
            helpertext: MESSAGE.noSelectError('時間間隔').text,
          },
          tmrIntervalTimeMin: {
            ...timer.tmrIntervalTimeMin,
            isInvalid: true,
            helpertext: ' ',
          }
        })
        return
      }
    }

    let dt = wijmo.DateTime, now = new Date()
    now.setHours(0)
    now.setMinutes(0)
    now.setSeconds(0)
    now.setMilliseconds(0)
    if (timer.timerReponseDto[props.popupData].tmrStatus === '00') {
      if (timer.timerReponseDto[props.popupData].tmrPcsSeq===2 || timer.timerReponseDto[props.popupData].tmrPcsSeq===3 || timer.timerReponseDto[props.popupData].tmrPcsSeq===4) {
        if (timer.tmrOutTo.text && app.limitMonth > -1) {
          if (dt.addMonths(now, -app.limitMonth) > timer.tmrOutTo.text) {
            appAction.setMessage({message: [{
              id: MESSAGE.outRangeTimerDate().id,
              message: MESSAGE.outRangeTimerDate(app.limitMonth).text,
              type: MESSAGE.outRangeTimerDate().icon,
            }]})
            return
          }
        }
      }
    }

    let original = [].concat(timer.timerReponseDto)
    _.forEach(original[props.popupData], (v, k) => {
      if (k === 'tmrInterval') original[props.popupData][k] = timer.tmrInterval.text
      if (k === 'tmrIntervalOnce') original[props.popupData][k] = convert.toDateTimeString(timer.tmrIntervalOnce.text, 'yyyyMMddHHmm')
      if (k === 'tmrIntervalDaily') original[props.popupData][k] = convert.time2string(timer.tmrIntervalDaily.text, 'HHmm')
      if (k === 'tmrIntervalWeekly') original[props.popupData][k] = timer.tmrIntervalWeekly.text
      if (k === 'tmrIntervalWeeklyTime') original[props.popupData][k] = convert.time2string(timer.tmrIntervalWeeklyTime.text, 'HHmm')
      if (k === 'tmrIntervalMonthly') original[props.popupData][k] = timer.tmrIntervalMonthly.text
      if (k === 'tmrIntervalMonthlyTime') original[props.popupData][k] = convert.time2string(timer.tmrIntervalMonthlyTime.text, 'HHmm')
      if (k === 'tmrIntervalTime') {
        original[props.popupData][k] = _.padStart(timer.tmrIntervalTimeHour.text, 2, '00') + _.padStart(timer.tmrIntervalTimeMin.text, 2, '00')
      }
      if (k === 'tmrOutTo') original[props.popupData][k] = convert.toDateTimeString(timer.tmrOutTo.text, 'yyyyMMddHHmm')
    })
    const tmrValidFromText = timer.tmrValidFromText.text
    const tmrValidToText = timer.tmrValidToText.text
    const isSpecify = original[props.popupData].tmrInterval === 'S'
    timerAction.setInputTimer({
      timerReponseDto: original,
      tmrValidFromText: {
        ...timer.tmrValidFromText,
        text: isSpecify?null:tmrValidFromText?tmrValidFromText:null,
        value: isSpecify?null:tmrValidFromText?tmrValidFromText:null,
        disabled: isSpecify,
      },
      tmrValidToText: {
        ...timer.tmrValidToText,
        text: isSpecify?null:tmrValidToText?tmrValidToText:null,
        value: isSpecify?null:tmrValidToText?tmrValidToText:null,
        disabled: isSpecify,
      },
    })
    props.setPopupData(-1)
    closing();
    props.popup.hide()
  }

  const closing =() => {
    timerAction.setInputTimer({
      tmrIntervalTimeHour: {
        ...timer.tmrIntervalTimeHour,
        isInvalid: false,
        helpertext: '',
      },
      tmrIntervalTimeMin: {
        ...timer.tmrIntervalTimeMin,
        isInvalid: false,
        helpertext: '',
      }
    })
  }

  // React.useEffect(() => {
  //   console.log(timer.tmrInterval.text)
  // }, [timer.tmrInterval.text ])

  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
        removeOnHide={false}
      >
        <Card
          root='popup'
          size='lg'
          hideClose
          header={props.header}
          headerClass='wj-dialog-header'
          subheader={props.subheader}
          // body={props.body}
          body={
            <React.Fragment>
              {(timer.timerReponseDto && timer.timerReponseDto[props.popupData] && 
              (timer.timerReponseDto[props.popupData].tmrPcsSeq===2 || timer.timerReponseDto[props.popupData].tmrPcsSeq===3 || timer.timerReponseDto[props.popupData].tmrPcsSeq===4)) &&
                <div className='d-flex align-items-center'>
                  <div className='d-flex'>
                      <Common.DateTime
                        attrs={{
                          ...timer.tmrOutTo,
                          disabled: timer.timerReponseDto[props.popupData].tmrStatus !== '00',
                        }}
                        onBlur={(e) => timerAction.changeInputTimer(e)}
                        group={{
                          className: 'width-max-content mr-1 mb-5',
                        }}
                      />
                  </div>
                  <label sm="3" className='label-color mb-0 ml-0 text-left width-16'>
                    以降のデータを出力する
                  </label>
                </div>
              }
              <div className='d-flex'>
                <Common.Select
                  attrs={{...timer.tmrInterval}}
                  group={{
                    className: 'width-15 mr-3',
                    textwidth: 'width-15',
                  }}
                  minLength={1}
                  list={TMR_INTERVAL}
                  onChange={(e) => timerAction.changeInputTimer(e)}
                />
                {timer.tmrInterval.text === 'S' &&
                  <Common.DateTime
                    attrs={timer.tmrIntervalOnce}
                    onBlur={(e) => timerAction.changeInputTimer(e)}
                    group={{
                      className: 'width-max-content mr-5 mb-5',
                    }}
                  />
                }
                {timer.tmrInterval.text === 'D' &&
                  <Common.Time
                    attrs={timer.tmrIntervalDaily}
                    onBlur={(e) => timerAction.changeInputTimer(e)}
                    group={{
                      className: 'width-max-content mr-5 mb-5',
                    }}
                  />
                }
                {timer.tmrInterval.text === 'W' &&
                  <React.Fragment>
                    <Common.Select
                      attrs={{...timer.tmrIntervalWeekly}}
                      group={{
                        className: 'width-15 mr-3',
                        textwidth: 'width-15',
                      }}
                      minLength={1}
                      list={TMR_WEEKLY}
                      onChange={(e) => timerAction.changeInputTimer(e)}
                    />
                    <Common.Time
                      attrs={timer.tmrIntervalWeeklyTime}
                      onBlur={(e) => timerAction.changeInputTimer(e)}
                      group={{
                        className: 'width-max-content mr-5 mb-5',
                      }}
                    />
                  </React.Fragment>
                }
                {timer.tmrInterval.text === 'M' &&
                  <React.Fragment>
                    <Common.Select
                      attrs={{...timer.tmrIntervalMonthly}}
                      group={{
                        className: 'width-15 mr-3',
                        textwidth: 'width-15',
                      }}
                      minLength={1}
                      list={TMR_MONTHLY}
                      onChange={(e) => timerAction.changeInputTimer(e)}
                    />
                    <Common.Time
                      attrs={timer.tmrIntervalMonthlyTime}
                      onBlur={(e) => timerAction.changeInputTimer(e)}
                      group={{
                        className: 'width-max-content mr-5 mb-5',
                      }}
                    />
                  </React.Fragment>
                }
                {timer.tmrInterval.text === 'I' &&
                  <React.Fragment>
                    <Common.Number
                      attrs={timer.tmrIntervalTimeHour}
                      onBlur={(e) => timerAction.changeInputTimer(e)}
                      group={{
                        className: 'width-max-content mr-5 mb-5',
                      }}
                    />
                    <Common.Number
                      attrs={timer.tmrIntervalTimeMin}
                      onBlur={(e) => timerAction.changeInputTimer(e)}
                      group={{
                        className: 'width-max-content mr-5 mb-5',
                      }}
                    />
                  </React.Fragment>
                }
              </div>
            </React.Fragment>
          }
        />
        <Card 
          size='md'
          root={`popup-button ${props.size}`}
          // body={props.button}
          body={
            <React.Fragment>
              <Button
                className='button-gray mr-auto'
                onClick={() => {
                  props.setPopupData(-1)
                  closing();
                  props.popup.hide('wj-hide-cancel')
                }}
              >
                <span className='material-icons-outlined md mr-2'>cancel</span>
                キャンセル
              </Button>
              <Button
                className='button-blue ml-auto'
                onClick={() => action(props.popupData?'update':'insert')}
                disabled={app.isProcessing}
              >
                <span className='material-icons-outlined md mr-2'>file_upload</span>
                設定
              </Button>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})