import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { PivotAction } from 'ducks/Pivot';
import * as Common from 'components/common';
//
import {
  DateTime,
} from '@grapecity/wijmo';
import '@grapecity/wijmo.touch'; // support drag/drop on touch devices
import * as Olap from '@grapecity/wijmo.react.olap';
import * as wjcOlap from '@grapecity/wijmo.olap';
import * as wjChart from '@grapecity/wijmo.chart';
import * as wjGrid from '@grapecity/wijmo.grid';
import { TabPanel, Tab } from '@grapecity/wijmo.react.nav';
import {
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Card as BootCard,
} from 'react-bootstrap';
import { Title } from 'components';
import { Responsive, WidthProvider } from 'react-grid-layout';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { TITLE } from 'constants/title';
import { Card } from 'components';
import { SummaryApi } from 'webapi'
import { entryCheck, convert, getCondition } from "lib";
import { FIELDS, NSB_OPTION, BI_MAP } from 'constants/pivot';
import { MESSAGE } from 'constants/message';
import { TKT_STATUS } from 'constants/ticket';
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import _ from 'lodash';
import { dataMapper } from 'lib';

export const PivotDisplay = (props) => {

  const app = useSelector(state => state.App);
  const tktSearch = useSelector(state => state.TktSearch);
  const pivot = useSelector(state => state.Pivot);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const pivotAction = bindActionCreators(PivotAction, dispatch);

  // const { panel, setSelected, getGraphHeight, initializePivotGrid, loadedGrid, initializePivotChart } = props

  return (
    <React.Fragment>
      <div className='d-flex flex-row'>
        <div className='bi-option flex-grow-1'>
          <div className='d-flex flex-row flex-grow-1'>
            <div className='d-flex flex-column flex-grow-1'>
              <div className='mb-2'>
                <Common.Check
                  attrs={{...pivot.faretypeConversionFlg}}
                  onChange={(e) => pivotAction.changeInputPivot(e)}
                />
              </div>
              <div className='mb-2'>
                <Common.Check
                  attrs={{...pivot.taxIncludeFlg}}
                  onChange={(e) => pivotAction.changeInputPivot(e)}
                />
              </div>
              <div className='mb-2'>
                <Common.Check
                  attrs={{...pivot.productionJudgeFlg}}
                  onChange={(e) => pivotAction.changeInputPivot(e)}
                />
              </div>
            </div>
            <div className='d-flex flex-column flex-grow-1'>
              <div className='mb-2'>
                <Common.Check
                  attrs={{...pivot.voidPrintFlg}}
                  onChange={(e) => pivotAction.changeInputPivot(e)}
                />
              </div>
              <div className='mb-0'>
                <Common.Check
                  attrs={{...pivot.cnjtktAmountPringFlg}}
                  onChange={(e) => pivotAction.changeInputPivot(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='bi-option flex-grow-1'>
          <div className='d-flex flex-row flex-grow-1'>
            <div className='d-flex flex-column flex-grow-1'>
              <div className='mb-2'>
                <Common.Check
                  attrs={{...pivot.displayRowTotalFlg}}
                  onChange={(e) => pivotAction.changeInputPivot(e)}
                />
              </div>
              <div className='mb-2'>
                <Common.Check
                  attrs={{...pivot.displayRowSubtotalFlg}}
                  onChange={(e) => pivotAction.changeInputPivot(e)}
                />
              </div>
              <div className='mb-2'>
                <Common.Check
                  attrs={{...pivot.displayColumnTotalFlg}}
                  onChange={(e) => pivotAction.changeInputPivot(e)}
                />
              </div>
              <div className='mb-2'>
                <Common.Check
                  attrs={{...pivot.displayColumnSubtotalFlg}}
                  onChange={(e) => pivotAction.changeInputPivot(e)}
                />
              </div>
            </div>
            <div className='d-flex flex-column flex-grow-1'>
              <div className='mb-2'>
                <Common.Check
                  attrs={{...pivot.displayComponentRatioFlg}}
                  onChange={(e) => pivotAction.changeInputPivot(e)}
                />
              </div>
              {/* <div className='mb-2'>
                <Common.Check
                  attrs={{...pivot.displayYoyFlg}}
                  onChange={(e) => pivotAction.changeInputPivot(e)}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
