import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'components';
import * as Common from 'components/common';

import { Button } from 'react-bootstrap';
import _ from 'lodash';
import { RADIOS, CHECKS } from 'constants/search';
import { entryCheck, convert } from "lib";
// import '../styles/conditionCard.scss'

export const CardCodeComponent = (props) => {

  const app = useSelector(state => state.App);
  const [selItems, setSelItems] = React.useState([])
  const [selValPath, setSelValPath] = React.useState('')
  const [dispMemPath, setDispMemPath] = React.useState('')
  const [srchMemPath, setSrchMemPath] = React.useState('')
  const [dataList, setDataList] = React.useState([])

  // 初期化
  React.useEffect(() => {
    let list = []
    let listType = ''
    switch (props.condition.key) {
      case 'tktTktNoAl':
        setSelValPath('alnCd2')
        setDispMemPath('listName')
        setDataList(app.alList)
        list = app.alList
        listType = 'al'
        setSrchMemPath('alnCd2,alnCdn')
        break;
      case 'fltAirlineCd':
        setSelValPath('alnCd2')
        setDispMemPath('listName')
        setDataList(app.alList)
        list = app.alList
        listType = 'al'
        setSrchMemPath('alnCd2,alnCdn')
        break;
      case 'fltFrom':
        setSelValPath('cityCd')
        setDispMemPath('listName')
        setDataList(app.cityList)
        list = app.cityList
        listType = 'city'
        setSrchMemPath('cityCd')
        break;
      case 'fltTo':
        setSelValPath('cityCd')
        setDispMemPath('listName')
        setDataList(app.cityList)
        list = app.cityList
        listType = 'city'
        setSrchMemPath('cityCd')
        break;
    }
    // setTimeout(() => {
    //   console.log(dataList)
    // })
    clear(true)
    setTimeout(() => {
      _.forEach(props.search, (item, key) => {
        if (key === props.condition.key + '_array') {
          if (_.isArray(item.text)) {
            if (_.isObject(item.text[0])) {
              setSelItems(item.text)
            } else {
              let arr = []
              _.forEach(item.text, (r, i) => {
                if (listType === 'al') {
                  let find = _.find(list, data => data.alnCdn === r)
                  if (find) {
                    arr.push(find)
                  } else {
                    arr.push({
                      alnCd2: r,
                      alnCdn: r,
                      listName: r,
                    })
                  }
                } else {
                  let find = _.find(list, data => data.cityCd === r)
                  if (find) {
                    arr.push(find)
                  } else {
                    arr.push({
                      cityCd: r,
                      listName: r,
                    })
                  }
                }
              })
              setSelItems(arr)
            }
          }
        }
      })
    })
  }, [props.condition.key])

  // clear
  const clear = (isKeep) => {
    const obj = {}
    if (!isKeep) setSelItems([])
    if (!props.items[props.items.length-1].value || !isKeep) {
      obj[props.items[props.items.length-1].id] = {
        ...props.items[props.items.length-1],
        original: RADIOS.clude[0].value,
        value: RADIOS.clude[0].value,
        text: RADIOS.clude[0].value,
      }
    }
    Object.keys(obj).length && props.setInput({...obj})
  }

  React.useEffect(() => {
    _.map(props.items, (r, i) => {
      if (r.id.endsWith('array')) {
        const obj = convert.getTarget(r, 'text', selItems)
        delete obj.id
        props.setInput(obj)
      }
    })
  }, [selItems])

  const getTemp = () => {
    switch (props.condition.key) {
      case 'tktTktNoAl':
        return '<table><tr>' +
          '<td class="width-5" title="コード 2桁">{alnCd2}</td>' +
          '<td class="width-5" title="コード 数字">{alnCdn}</td>' +
          '<td class="" title="名称">{alnNamej}</td>' +
          '</tr></table>'
      case 'fltAirlineCd':
        return '<table><tr>' +
          '<td class="width-5" title="コード 2桁">{alnCd2}</td>' +
          '<td class="width-5" title="コード 数字">{alnCdn}</td>' +
          '<td class="" title="名称">{alnNamej}</td>' +
          '</tr></table>'
      case 'fltFrom':
        return '<table><tr>' +
          '<td class="width-5" title="コード">{cityCd}</td>' +
          '<td class="" title="名称">{cityNamee}</td>' +
          '</tr></table>'
      case 'fltTo':
        return '<table><tr>' +
          '<td class="width-5" title="コード">{cityCd}</td>' +
          '<td class="" title="名称">{cityNamee}</td>' +
          '</tr></table>'
    }
  }

  const getBody = () => {
    const getItem = _.map(props.items, (r, i) => {
      if (r.id.endsWith('array')) {
        return (
          <React.Fragment key={i}>
            <Common.MultiSelect
              key={i}
              onBlur={(e) => props.onBlur(e)}
              attrs={r}
              group={{
                className: 'width-max-content mr-2 mb-1',
                textwidth: 'min-width-13',
                textsuffixwidth: 'max-width-8',
              }}
              // maxSelectedItems={10}
              minLength={1}
              selectedValuePath={selValPath}
              displayMemberPath={dispMemPath}
              list={dataList}
              searchMemberPath={srchMemPath}
              selectedItems={selItems}
              setSelectedItems={setSelItems}
              template={getTemp()}
              isEditable={true}
              isWildCard={props.condition.key==='fltFrom' || props.condition.key === 'fltTo' || props.condition.key === 'fltAirlineCd'}
            />
          </React.Fragment>
        )
      } else return
    })
    return (
      <React.Fragment>
        <div className='card-condition-item'>
          <Common.RadioButton
            btnSize='sm'
            className='min-width-15 width-15 mr-3'
            attrs={props.items[props.items.length-1]}
            radios={RADIOS.clude}
            onChange={(e) => props.onBlur(e)}
          />
          {/* <div className='inner'> */}
            {getItem}
          {/* </div> */}
        </div>
        <div className='d-inline-block mb-1'>
          <Button
            id='clear'
            className='button-gray small width-max-content'
            onClick={(e) => clear()}
          >
            クリア
          </Button>
        </div>
      </React.Fragment>
    )
  }
  
  return (
    <React.Fragment>
      <Card 
        size={props.cardSize}
        header={props.condition.header}
        subheader={props.condition.subheader}
        body={getBody()}
        condition={props.condition}
        onClose={props.onClose}
      />
    </React.Fragment>
  )
}

CardCodeComponent.defaultProps = {};
