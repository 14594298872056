import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TimerAction } from 'ducks/Timer';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { IntervalPopup } from 'components/Timer/modal/IntervalPopup';
import { TicketImportPopup } from 'components/Timer/modal/TicketImportPopup';
import { TicketExportPopup } from 'components/Timer/modal/TicketExportPopup';
import { TicketExcelPopup } from 'components/Timer/modal/TicketExcelPopup';
import { ReportPopup } from 'components/Timer/modal/ReportPopup';
import { MaintPopup } from 'components/Timer/modal/MaintPopup';
import { ProcessComponent } from 'components/Process/ProcessComponent';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { alert, entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import * as wijmo from '@grapecity/wijmo';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TabPanel, Tab } from '@grapecity/wijmo.react.nav';
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { HEADER, TMR_INTERVAL, TMR_SCHEDULE, TMR_WEEKLY, TMR_MONTHLY } from 'constants/timer';
import { MasterAction } from 'ducks/Master';
import { TimerApi } from 'webapi'
import reactSplitPane from 'react-split-pane';
import { getMonth } from 'date-fns';

export const TimerComponent = () => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const timer = useSelector(state => state.Timer);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const timerAction = bindActionCreators(TimerAction, dispatch);

  const [importPopup, setImportPopup] = React.useState(null)
  const [exportPopup, setExportPopup] = React.useState(null)
  const [excelPopup, setExcelPopup] = React.useState(null)
  const [reportPopup, setReportPopup] = React.useState(null)
  const [maintPopup, setMaintPopup] = React.useState(null)
  const [popup, setPopup] = React.useState(null)
  const [popupData, setPopupData] = React.useState(null)
  const [selected, setSelected] = React.useState(-1)
  const [editRow, setEditRow] = React.useState(null)
  const [editRowItem, setEditRowItem] = React.useState(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [tab, setTab] = React.useState('timer')

  // 
  React.useEffect(() => {
    appAction.showProcessing({isProcessing: true})
    appAction.setMessage('')

    TimerApi.init({})
    .then(res => {
      
      timerAction.setInputTimer({
        timerReponseDto: _.sortBy(res.payload.timerReponseDto, ['tmrPcsSeq']),
      })
      setSelected(0)
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }, [])

  const execSetting = () => {
    const target = timer.timerReponseDto[editRow]

    // 有効期間大小チェック
    if (timer.tmrValidFromText.text && timer.tmrValidToText.text) {
      if (timer.tmrValidFromText.text.getTime() > timer.tmrValidToText.text.getTime()) {
        appAction.setMessage({message: [{
          id: MESSAGE.magnitudeError().id,
          message: MESSAGE.magnitudeError('有効期間').text,
          type: MESSAGE.magnitudeError().icon,
        }]})
        return
      }
    }
    // 有効期間外チェック
    if (timer.tmrValidToText.text) {
      let dt = wijmo.DateTime
      let now = new Date();
      const today = dt.newDate(now.getFullYear(), now.getMonth(), now.getDate())
      
      if (today.getTime() > timer.tmrValidToText.text.getTime()) {
        appAction.setMessage({message: [{
          id: MESSAGE.invalidRangeError().id,
          message: MESSAGE.invalidRangeError().text,
          type: MESSAGE.invalidRangeError().icon,
        }]})
        return
      }
    }

    // 設定チェック
    if (!target.tmrInterval) {
      appAction.setMessage({message: [{
        id: MESSAGE.noSelectError().id,
        message: MESSAGE.noSelectError('実行間隔').text,
        type: MESSAGE.noSelectError().icon,
      }]})
      return
    }

    // 即時/指定 過去日チェック
    if (target.tmrInterval === 'S') {
      let dt = wijmo.DateTime
      let now = new Date();
      const today = dt.newDate(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes())
      if (target.tmrIntervalOnce && convert.toDate(target.tmrIntervalOnce) <= today) {
        appAction.setMessage({message: [{
          id: MESSAGE.pastDateTime().id,
          message: MESSAGE.pastDateTime().text,
          type: MESSAGE.pastDateTime().icon,
        }]})
        return
      }
    }

    // 確認
    alert().fire({
      icon: target.tmrPcsSeq===5?MESSAGE.confirmTimerSettingForMaint().icon:MESSAGE.confirmTimerSetting().icon,
      title: target.tmrPcsSeq===5?MESSAGE.confirmTimerSettingForMaint().text:MESSAGE.confirmTimerSetting().text
    })
    .then((result) => {
      if (result.isConfirmed) {

        const timeIntervalTime = (target.tmrIntervalTime && target.tmrIntervalTime.length === 4)?target.tmrIntervalTime.substring(0, 2):''
        const timeIntervalMin = (target.tmrIntervalTime && target.tmrIntervalTime.length === 4)?target.tmrIntervalTime.substring(2, 4):''
        const immediatelyDate = (target.tmrIntervalOnce && target.tmrIntervalOnce.length === 12)?target.tmrIntervalOnce.substring(0, 8):''
        const immediatelyTime = (target.tmrIntervalOnce && target.tmrIntervalOnce.length === 12)?target.tmrIntervalOnce.substring(8, 12):''
        const extractStartDate = (target.tmrOutTo && target.tmrOutTo.length === 12)?target.tmrOutTo.substring(0, 8):''
        const extractStartTime = (target.tmrOutTo && target.tmrOutTo.length === 12)?target.tmrOutTo.substring(8, 12):''
        const dto = {
          inputTmrPcsSeq: target.tmrPcsSeq,
          okOrCancelFlg: true,
          execIntervalchoice: target.tmrInterval,
          timeIntervalTime: timeIntervalTime,
          timeIntervalMin: timeIntervalMin,
          immediatelyDate: immediatelyDate,
          immediatelyTime: immediatelyTime,
          everydayTime: target.tmrIntervalDaily,
          weeklyDayOfWeekly: target.tmrIntervalWeekly,
          weeklyTime: target.tmrIntervalWeeklyTime,
          monthlyDay: target.tmrIntervalMonthly,
          monthlyTime: target.tmrIntervalMonthlyTime,
          validityPeriodFlg: false,
          validityPeriodFrom: convert.toDateString(timer.tmrValidFromText.text, 'yyyy/MM/dd'),
          validityPeriodTo: convert.toDateString(timer.tmrValidToText.text, 'yyyy/MM/dd'),
          extractStartDate: extractStartDate,
          extractStartTime: extractStartTime,
        }

        appAction.showProcessing({isProcessing: true})
        appAction.setMessage('')
        setSelected(-1)
        TimerApi.regist(dto).then((res) => {
          appAction.setMessage({message: res.payload.messageList})
          if (res.payload.code === '000') {

            const obj = {}
            _.forEach(res.payload, (v, k) => {
              _.forEach(timer, (o, id) => {
                if (id === k) {
                  obj[id] = {
                    ...timer[id],
                    original: v,
                    value: v,
                    text: v,
                  }
                }
              })
            })
            
            obj.timerReponseDto = []
            _.forEach(timer.timerReponseDto, (r, i) => {
              const index = _.findIndex(res.payload.timerReponseDto, {tmrPcsSeq: r.tmrPcsSeq})
              if (index < 0) {
                obj.timerReponseDto.push(r)
              } else {
                obj.timerReponseDto.push(res.payload.timerReponseDto[index])
              }
            })
            _.sortBy(obj.timerReponseDto, ['tmrPcsSeq'])
            timerAction.setInputTimer({...obj})
            setEditRow(null)
            setEditRowItem(null)
          } else {
            
          }
        })
        .catch(() =>{})
        .finally(() => {
          appAction.showProcessing({isProcessing: false})
          setSelected(selected)
        })
      }
    })
  }

  const execUpdate = (type) => {

    const icon = type===0?MESSAGE.confirmTimerRelease().icon:type===1?MESSAGE.confirmTimerPause().icon:MESSAGE.confirmTimerResume().icon
    const text = type===0?MESSAGE.confirmTimerRelease().text:type===1?MESSAGE.confirmTimerPause().text:MESSAGE.confirmTimerResume().text
    const showDenyButton = (type === 2)
    const confirmButtonText = (type === 2)?'Yes':'OK'
    const denyButtonText = (type === 2)?'No':'No'
    
    // 有効期間外チェック
    if (timer.timerReponseDto[selected].tmrValidToText) {
      const validTo = new Date(convert.toDateString(timer.timerReponseDto[selected].tmrValidToText))
      if (type === 2 && validTo) {
        let dt = wijmo.DateTime
        let now = new Date();
        const today = dt.newDate(now.getFullYear(), now.getMonth(), now.getDate())

        if (today.getTime() > validTo.getTime()) {
          appAction.setMessage({message: [{
            id: MESSAGE.invalidRangeError().id,
            message: MESSAGE.invalidRangeError().text,
            type: MESSAGE.invalidRangeError().icon,
          }]})
          return
        }
      }
    }

    // 即時/指定 過去日チェック
    if (type === 2 && timer.timerReponseDto[selected].tmrInterval === 'S') {
      let dt = wijmo.DateTime
      let now = new Date();
      const today = dt.newDate(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes())
      if (timer.timerReponseDto[selected].tmrIntervalOnce && convert.toDate(timer.timerReponseDto[selected].tmrIntervalOnce) <= today) {
        appAction.setMessage({message: [{
          id: MESSAGE.pastDateTime().id,
          message: MESSAGE.pastDateTime().text,
          type: MESSAGE.pastDateTime().icon,
        }]})
        return
      }
    }

    alert().fire({
      icon: icon,
      title: text,
      showDenyButton: showDenyButton,
      confirmButtonText: confirmButtonText,
      denyButtonText: denyButtonText,
    })
    .then((result) => {
      if (result.isConfirmed || result.isDenied) {
        doUpdate(type, result.isConfirmed)
      }
    })
  }

  const doUpdate = (type, isConfirmed) => {
    const target = timer.timerReponseDto[selected]
    const dto = {
      inputTmrPcsSeq: target.tmrPcsSeq,
      proc: type,
      subButton: isConfirmed?0:1,
    }

    appAction.showProcessing({isProcessing: true})
    appAction.setMessage('')
    setSelected(-1)
    TimerApi.update(dto).then((res) => {
      appAction.setMessage({message: res.payload.messageList})
      if (res.payload.code === '000') {

        const obj = {}
        _.forEach(res.payload, (v, k) => {
          _.forEach(timer, (o, id) => {
            if (id === k) {
              obj[id] = {
                ...timer[id],
                original: v,
                value: v,
                text: v,
              }
            }
          })
        })
        
        obj.timerReponseDto = []
        _.forEach(timer.timerReponseDto, (r, i) => {
          const index = _.findIndex(res.payload.timerReponseDto, {tmrPcsSeq: r.tmrPcsSeq})
          if (index < 0) {
            obj.timerReponseDto.push(r)
          } else {
            obj.timerReponseDto.push(res.payload.timerReponseDto[index])
          }
        })
        _.sortBy(obj.timerReponseDto, ['tmrPcsSeq'])
        timerAction.setInputTimer({...obj})
        setEditRow(null)
        setEditRowItem(null)
      } else {
        
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
      setSelected(selected)
    })
  }

  const openSettings = (index) => {
    let func = null
    switch (index) {
      case 1: func = importPopup
        break;
      case 2: func = exportPopup
        break;
      case 3: func = excelPopup
        break;
      case 4: func = reportPopup
        break;
      case 5: func = maintPopup
        break;
    }
    setIsOpen(true)
    func.show(true, (sender) => {
      if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
        // console.log('ok')
        setIsOpen(false)
      } else {
        // console.log('cancel')
        setIsOpen(false)
      }
    })
  }

  const openInterval = (index) => {
    setPopupData(index)
    popup.show(true, (sender) => {
      if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
        // console.log('ok')
      } else {
        // console.log('cancel')
      }
    })
  }

  const getSchedule = (index, interval) => {
    switch (interval) {
      case 'S': return {
        schedule: _.filter(TMR_SCHEDULE, r => r.value === interval)[0].name,
        scheduleTime: timer.timerReponseDto[index].tmrIntervalOnce?
          convert.datehhmm2string(timer.timerReponseDto[index].tmrIntervalOnce, 'yyyy/MM/dd HH:mm'):
          convert.datehhmm2string(timer.timerReponseDto[index].tmrNextRunTime, 'yyyy/MM/dd HH:mm'),
        interval: _.filter(TMR_INTERVAL, r => r.value === interval)[0].name,
        intervalTime: convert.datehhmm2string(timer.timerReponseDto[index].tmrIntervalOnce, 'yyyy/MM/dd HH:mm'),
      }
      case 'D': return {
        schedule: _.filter(TMR_SCHEDULE, r => r.value === interval)[0].name,
        scheduleTime: convert.hhmm2string(timer.timerReponseDto[index].tmrIntervalDaily),
        interval: _.filter(TMR_INTERVAL, r => r.value === interval)[0].name,
        intervalTime: convert.hhmm2string(timer.timerReponseDto[index].tmrIntervalDaily),
      }
      case 'I':
        const time = timer.timerReponseDto[index].tmrIntervalTime
        const hh = (time && time.length === 4)?time.substring(0, 2):'00'
        const mm = (time && time.length === 4)?time.substring(2, 4):'00'
        return {
          schedule: _.filter(TMR_SCHEDULE, r => r.value === interval)[0].name,
          scheduleTime: convert.hhmm2string(timer.timerReponseDto[index].tmrIntervalTime),
          interval: _.filter(TMR_INTERVAL, r => r.value === interval)[0].name,
          intervalTime: `${hh}時間 ${mm}分`,
        }
      case 'W': return {
        schedule: _.filter(TMR_WEEKLY, r => r.value === timer.timerReponseDto[index].tmrIntervalWeekly)[0].name,
        scheduleTime: convert.hhmm2string(timer.timerReponseDto[index].tmrIntervalWeeklyTime),
        interval: _.filter(TMR_INTERVAL, r => r.value === interval)[0].name,
        intervalTime: _.filter(TMR_WEEKLY, r => r.value === timer.timerReponseDto[index].tmrIntervalWeekly)[0].name
                    + convert.hhmm2string(timer.timerReponseDto[index].tmrIntervalWeeklyTime),
      }
      case 'M': return {
        schedule: _.filter(TMR_MONTHLY, r => r.value === timer.timerReponseDto[index].tmrIntervalMonthly)[0].name,
        scheduleTime: convert.hhmm2string(timer.timerReponseDto[index].tmrIntervalMonthlyTime),
        interval: _.filter(TMR_INTERVAL, r => r.value === interval)[0].name,
        intervalTime: _.filter(TMR_MONTHLY, r => r.value === timer.timerReponseDto[index].tmrIntervalMonthly)[0].name
                    + convert.hhmm2string(timer.timerReponseDto[index].tmrIntervalMonthlyTime),
      }
      default: return {
        schedule: '未設定',
        scheduleTime: '',
        interval: '未設定',
        intervalTime: '',
      }
    }
  }

  React.useEffect(() => {
    const obj = {}
    let tmrValidFromText = null
    let tmrValidToText = null
    let tmrInterval = ''

    if (timer.timerReponseDto[editRow]) {
      tmrValidFromText = timer.timerReponseDto[editRow].tmrValidFromText
    }
    if (timer.timerReponseDto[editRow]) {
      tmrValidToText = timer.timerReponseDto[editRow].tmrValidToText
    }
    if (timer.timerReponseDto[editRow]) {
      tmrInterval = timer.timerReponseDto[editRow].tmrInterval
    }
    obj.tmrValidFromText = {
      ...timer.tmrValidFromText,
      dirty: '',
      original: _.isNull(editRow)?null:tmrValidFromText?new Date(convert.toDateString(tmrValidFromText)):null,
      value: _.isNull(editRow)?null:tmrValidFromText?new Date(convert.toDateString(tmrValidFromText)):null,
      text: _.isNull(editRow)?null:tmrValidFromText?new Date(convert.toDateString(tmrValidFromText)):null,
      disabled: _.isNull(editRow)?false:tmrInterval==='S',
    }
    obj.tmrValidToText = {
      ...timer.tmrValidToText,
      dirty: '',
      original: _.isNull(editRow)?null:tmrValidToText?new Date(convert.toDateString(tmrValidToText)):null,
      value: _.isNull(editRow)?null:tmrValidToText?new Date(convert.toDateString(tmrValidToText)):null,
      text: _.isNull(editRow)?null:tmrValidToText?new Date(convert.toDateString(tmrValidToText)):null,
      disabled: _.isNull(editRow)?false:tmrInterval==='S',
    }
    timerAction.setInputTimer({...obj})
  }, [editRow])

  React.useEffect(() => {
    if (timer.timerReponseDto[selected]) {
      const obj = {}
      switch (timer.timerReponseDto[selected].tmrStatus) {
        case '00'://未設定
          obj.release = {...timer.release, disabled: true}
          obj.edit = {...timer.edit, disabled: false}
          obj.pause = {...timer.pause, disabled: true}
          obj.resume = {...timer.resume, disabled: true}
          break;
        case '01'://停止中
          obj.release = {...timer.release, disabled: false}
          obj.edit = {...timer.edit, disabled: false}
          obj.pause = {...timer.pause, disabled: true}
          obj.resume = {...timer.resume, disabled: false}
          break;
        case '02'://待機中
          obj.release = {...timer.release, disabled: false}
          obj.edit = {...timer.edit, disabled: false}
          obj.pause = {...timer.pause, disabled: false}
          obj.resume = {...timer.resume, disabled: true}
          break;
        case '03'://実行中
          obj.release = {...timer.release, disabled: true}
          obj.edit = {...timer.edit, disabled: true}
          obj.pause = {...timer.pause, disabled: false}
          obj.resume = {...timer.resume, disabled: true}
          break;
        case '10'://エラー有
          obj.release = {...timer.release, disabled: false}
          obj.edit = {...timer.edit, disabled: false}
          obj.pause = {...timer.pause, disabled: true}
          obj.resume = {...timer.resume, disabled: false}
          break;
        case '99'://有効期間外
          obj.release = {...timer.release, disabled: false}
          obj.edit = {...timer.edit, disabled: false}
          obj.pause = {...timer.pause, disabled: true}
          obj.resume = {...timer.resume, disabled: true}
          break;
        default:
          obj.release = {...timer.release, disabled: true}
          obj.edit = {...timer.edit, disabled: true}
          obj.pause = {...timer.pause, disabled: true}
          obj.resume = {...timer.resume, disabled: true}
          break;
      }
      let authDisabled = false
      switch (timer.timerReponseDto[selected].tmrPcsSeq) {
        case 1: authDisabled = (login.auth030 !== '1'); break;
        case 2: authDisabled = (login.auth040 !== '1'); break;
        case 3: authDisabled = (login.auth050 !== '1'); break;
        case 4: authDisabled = (login.auth070 !== '1'); break;
        case 5: authDisabled = (login.auth110 !== '1'); break;
      }
      
      obj.release = {...obj.release, disabled: obj.release.disabled || authDisabled}
      obj.edit = {...obj.edit, disabled: obj.edit.disabled || authDisabled}
      obj.pause = {...obj.pause, disabled: obj.pause.disabled || authDisabled}
      obj.resume = {...obj.resume, disabled: obj.resume.disabled || authDisabled}

      timerAction.setInputTimer({...obj})
    }
  }, [selected])

  // React.useEffect(() => {
  //   editRowItem && console.log(editRowItem.tmrInterval)
  // }, [editRowItem && editRowItem.tmrInterval])

  return (
    <React.Fragment>
      <Container fluid className='main'>
        <Title
          title={TITLE.timer.name}
          icon={TITLE.timer.icon}
        />
        <div>
          <IntervalPopup 
            popup={popup}
            setPopup={setPopup}
            popupData={popupData}
            setPopupData={setPopupData}
            header={<span>実行間隔</span>}
            timer={timer}
          />
          <TicketImportPopup 
            popup={importPopup}
            setPopup={setImportPopup}
            isOpen={isOpen}
            header={null}
          />
          <TicketExportPopup 
            popup={exportPopup}
            setPopup={setExportPopup}
            isOpen={isOpen}
            header={null}
          />
          <TicketExcelPopup 
            popup={excelPopup}
            setPopup={setExcelPopup}
            isOpen={isOpen}
            header={null}
          />
          <ReportPopup 
            popup={reportPopup}
            setPopup={setReportPopup}
            isOpen={isOpen}
            header={null}
          />
          <MaintPopup 
            popup={maintPopup}
            setPopup={setMaintPopup}
            isOpen={isOpen}
            header={null}
          />
        </div>
        <TabPanel
          selectedIndex={tab==='process'?1:0}
          selectedIndexChanged={(e) =>{
            if (e.selectedIndex===1) setTab('process')
            else setTab('timer')
          }}
          className='mt-1'
        >
          <Tab>
            <a>タイマー設定</a>
            <div>
              <Card
                root='max-button'
                size='lg'
                hideClose
                header={null}
                subheader={null}
                body={
                  <React.Fragment>
                    <Card
                      root='max'
                      size='md'
                      hideClose
                      // header={TITLE.timer.code.header}
                      // subheader={TITLE.timer.code.subheader}
                      body={
                        <React.Fragment>
                          <Table id='timer-table' bordered hover>
                            <thead>
                              <tr>
                                {HEADER.map((r, i) => {
                                  if (r.sub && r.sub.length > 0) return <th key={i} colSpan='2'>{r.header}</th>
                                  else if (r.sub && r.sub.length === 0) return <th key={i} colSpan='2' rowSpan='2'>{r.header}</th>
                                  else return <th key={i} rowSpan='2'>{r.header}</th>
                                })}
                              </tr>
                              <tr>
                                {HEADER.map((r, i) => {
                                  return (r.sub && r.sub.length > 0)?r.sub.map((v, k) => <th key={k}>{v.header}</th>):null
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {timer.timerReponseDto.map((r, i) => {return(
                                    (r.tmrPcsSeq===1 && login.auth031==='1')
                                ||  (r.tmrPcsSeq===2 && login.auth041==='1')
                                ||  (r.tmrPcsSeq===3 && login.auth051==='1')
                                ||  (r.tmrPcsSeq===4 && login.auth071==='1')
                                ||  (r.tmrPcsSeq===5))
                                && (
                                <tr className={editRow===null?selected===i?'selected':'':editRow===i?'':'gray-row'} key={i} onClick={(e) => setSelected(i)}>
                                  <td>{i+1}</td>
                                  <td className={i===editRow?'edit-row':''}>
                                    {i===editRow
                                      ? <div className="tooltip-text" title='クリックすると詳細設定画面が表示されます' onClick={(e) => openSettings(r.tmrPcsSeq)}>{r.tmrPcsName}</div>
                                      : <div>{r.tmrPcsName}</div>
                                    }
                                  </td>
                                  <td>{r.tmrStatusText}</td>
                                  <td>{getSchedule(i, r.tmrInterval).schedule}</td>
                                  <td>{getSchedule(i, r.tmrInterval).scheduleTime}</td>
                                  <td className={i===editRow?'edit-row py-0':''}>
                                    {i===editRow
                                      ? <Common.DateTime
                                          attrs={timer.tmrValidFromText}
                                          // range={_.filter(props.items, (r) => _.endsWith(r.id, 'to'))[0]}
                                          onBlur={(e) => timerAction.changeInputTimer(e)}
                                          group={{
                                            className: 'width-max-content mr-0 mb-0',
                                          }}
                                        />
                                      : <div>{r.tmrValidFromText}</div>
                                    }
                                  </td>
                                  <td className={i===editRow?'edit-row py-0':''}>
                                    {i===editRow
                                      ? <Common.DateTime
                                          attrs={timer.tmrValidToText}
                                          // range={_.filter(props.items, (r) => _.endsWith(r.id, 'to'))[0]}
                                          onBlur={(e) => timerAction.changeInputTimer(e)}
                                          group={{
                                            className: 'width-max-content mr-0 mb-0',
                                          }}
                                        />
                                      : <div>{r.tmrValidToText}</div>
                                    }
                                  </td>
                                  <td>{r.tmrAfterFilepath}</td>
                                  <td className={i===editRow?'edit-row':''}>
                                    {i===editRow
                                      ? <div className="tooltip-text" onClick={(e) => openInterval(i)}>{getSchedule(i, r.tmrInterval).interval}</div>
                                      : <div>{getSchedule(i, r.tmrInterval).interval}</div>
                                    }
                                  </td>
                                  <td>{getSchedule(i, r.tmrInterval).intervalTime}</td>
                                </tr>
                              )})}
                          </tbody>
                        </Table>
                        </React.Fragment>
                      }
                    />
                  </React.Fragment>
                }
              />
              <Card 
                size='lg'
                root='button'
                hideClose
                header={null}
                subheader={null}
                body={
                  <React.Fragment>
                    <div className='d-inline-block width-p-65'>
                      <div className='card-buttom-button dent'>
                        <Button
                          {...timer.release}
                          className='button-gray mr-2 width-max-content'
                          disabled={timer.release.disabled || app.isProcessing || editRow !== null}
                          onClick={() => execUpdate(0)}
                        >
                          {timer.release.label}
                        </Button>
                        <Button
                          {...timer.edit}
                          className='button-gray mr-auto width-max-content'
                          disabled={timer.edit.disabled || app.isProcessing || editRow !== null}
                          onClick={() => {
                            setEditRow(selected)
                            const object = {}
                            Object.assign(object, timer.timerReponseDto[selected])
                            setEditRowItem(object)
                          }}
                        >
                          {timer.edit.label}
                        </Button>
                        <Button
                          {...timer.pause}
                          className='button-gray ml-auto width-max-content'
                          disabled={timer.pause.disabled || app.isProcessing || editRow !== null}
                          onClick={() => execUpdate(1)}
                        >
                          {timer.pause.label}
                        </Button>
                        <Button
                          {...timer.resume}
                          className='button-gray ml-2 width-max-content'
                          disabled={timer.resume.disabled || app.isProcessing || editRow !== null}
                          onClick={() => execUpdate(2)}
                        >
                          {timer.resume.label}
                        </Button>
                      </div>
                    </div>
                    <div className='d-inline-block width-p-1'></div>
                    <div className='d-inline-block width-p-34'>
                      <div className='card-buttom-button dent'>
                        <Button
                          {...timer.cancel}
                          className='button-gray mr-auto width-max-content'
                          disabled={app.isProcessing || editRow === null}
                          onClick={() => {
                            timer.timerReponseDto[editRow] = editRowItem
                            setEditRowItem(null)
                            setEditRow(null)
                          }}
                        >
                          {timer.cancel.label}
                        </Button>
                        <Button
                          {...timer.setting}
                          className='button-blue ml-auto width-max-content'
                          disabled={app.isProcessing || editRow === null}
                          onClick={() => execSetting()}
                        >
                          {timer.setting.label}
                        </Button>
                      </div>
                    </div>
                  </React.Fragment>
                }
              />
            </div>
          </Tab>
          <Tab>
            <a>処理結果</a>
            <div>
              {tab === 'process' &&
              <ProcessComponent />
              }
            </div>
          </Tab>
        </TabPanel>
      </Container>
      <Common.RouteLeavingGuard
        when={!_.isNull(editRow)}
        navigate={(path, state) => history.push(path, state)}
        shouldBlockNavigation={(location) => {
          return (!location.state || !(location.state && location.state.nonRouteLeavingGuard))
        }}
        uiState='Timer'
      /> 
    </React.Fragment>
  )
}