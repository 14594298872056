import { axiosInstanceMigration } from 'http/index'

export const MigrationApi = {
  stepone: (data) => {
    const instance = axiosInstanceMigration;
    return instance.post('/migration/stepone', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  steptwo: (data) => {
    const instance = axiosInstanceMigration;
    return instance.post('/migration/steptwo', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  stepthree: (data) => {
    const instance = axiosInstanceMigration;
    return instance.post('/migration/stepthree', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  getCount: (data) => {
    const instance = axiosInstanceMigration;
    return instance.post('/migration/getCount', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
}
