import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { ReportAction } from 'ducks/Report';
import * as Common from 'components/common';
import { Title, SearchList, Condition, SearchResult } from 'components';
import { 
  Col,
  Row,
  Container,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, makeCondition } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { TITLE } from 'constants/title';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { TicketApi } from 'webapi'

export const ReportSearchComponent = () => {

  const app = useSelector(state => state.App);
  const report = useSelector(state => state.Report);

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const reportAction = bindActionCreators(ReportAction, dispatch);

  return (
    <React.Fragment>
      <Container fluid className='main'>
        <Title title={TITLE.reportSearch.name} icon={TITLE.reportSearch.icon}/>
        <SplitPane split="horizontal" className='split-pane-top'
          onChange={(size) => {reportAction.setInputReport({sizeHorizontal: size})}}>
          <SplitPane split="vertical" initialSize={report.sizeHorizontal[0]}
            onChange={(size) => {reportAction.setInputReport({sizeVertical: size})}}>
            <Pane initialSize={report.sizeVertical[0]}>
              <SearchList
                title={TITLE.reportSearch.searchList}
                tktSrchptPrivForm='B0801'
                search={report}
                onBlur={reportAction.changeInputReport}
                setInput={reportAction.setInputReport}
                remove={reportAction.removeReportItem}
                add={reportAction.addReportItem}
                searchApi={TicketApi}
                checkedItems={report.checkedItems}
                checkedItemsSetting={report.checkedItemsSetting}
                checkedItemsChanged={(e) => {
                  if (e.selectedItem) {
                    // if (_.findIndex(e.checkedItems, e.selectedItem) < 0) {
                    if (_.findIndex(e.checkedItems, o => o.key === e.selectedItem.key) < 0) {
                      reportAction.removeReportItem([e.selectedItem])
                    } else {
                      const selectedItem = {
                        key: e.selectedItem.key,
                        type: e.selectedItem.type,
                      }
                      reportAction.setInputReport(makeCondition(e.selectedItem, report))
                      reportAction.addReportItem([e.selectedItem])
                      // focus
                      let element = null
                      setTimeout(() => {
                        if (selectedItem.type==='check') {
                          element = document.getElementById(
                            selectedItem.key + '_' +
                            CHECKS[selectedItem.key][0].id);
                        // } else if (e.selectedItem.type==='radio') {
                        //   element = document.getElementById(e.selectedItem.key + -0);
                        } else {
                          element = document.getElementById(selectedItem.key + '_array');
                          element = element?element:document.getElementById(selectedItem.key + '_0');
                          element = element?element:document.getElementById(selectedItem.key + '_from');
                        }
                        //
                        // console.log(element)
                        element && element.focus();
                      });
                    }
                  }
                  reportAction.setInputReport({listChanged: false})
                }}
              />
            </Pane>
            <Pane>
              <Condition
                search={report}
                onBlur={reportAction.changeInputReport}
                setInput={reportAction.setInputReport}
                title={TITLE.reportSearch.condition}
                onClose={reportAction.removeReportItem}
                // searchApi={TicketApi.findSearch}
                searchApi={TicketApi}
                tktSrchptPrivForm='B0801'
              />
            </Pane>
          </SplitPane>
          <Pane>
            <SearchResult
              search={report}
              onBlur={reportAction.changeInputReport}
              setInput={reportAction.setInputReport}
              title={TITLE.reportSearch.result}
              searchApi={TicketApi}
              tktSrchptPrivForm='B0801'
            />
          </Pane>
        </SplitPane>
      </Container>
      <Common.RouteLeavingGuard
        when={true}
        shouldBlockNavigation={()=>false}
      />
    </React.Fragment>
  )
}