import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktSearchAction } from 'ducks/TktSearch';
import * as Common from 'components/common';
import { Title, SearchList, Condition, SearchResult } from 'components';
import { 
  Col,
  Row,
  Container,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, makeCondition } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { TITLE } from 'constants/title';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import {TicketApi } from 'webapi'

export const TicketSearchComponent = () => {

  const app = useSelector(state => state.App);
  const tktSearch = useSelector(state => state.TktSearch);

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const tktSearchAction = bindActionCreators(TktSearchAction, dispatch);

  return (
    <React.Fragment>
      <Container fluid className='main'>
        <Title title={TITLE.ticketSearch.name} icon={TITLE.ticketSearch.icon}/>
        <SplitPane split="horizontal" className='split-pane-top'
          onChange={(size) => {tktSearchAction.setInputTktSearch({sizeHorizontal: size})}}>
          <SplitPane split="vertical" initialSize={tktSearch.sizeHorizontal[0]}
            onChange={(size) => {tktSearchAction.setInputTktSearch({sizeVertical: size})}}>
            <Pane initialSize={tktSearch.sizeVertical[0]}>
              <SearchList
                title={TITLE.ticketSearch.searchList}
                tktSrchptPrivForm='B0101'
                search={tktSearch}
                onBlur={tktSearchAction.changeInputTktSearch}
                setInput={tktSearchAction.setInputTktSearch}
                remove={tktSearchAction.removeTktSearchItem}
                add={tktSearchAction.addTktSearchItem}
                searchApi={TicketApi}
                checkedItems={tktSearch.checkedItems}
                checkedItemsSetting={tktSearch.checkedItemsSetting}
                checkedItemsChanged={(e) => {
                  if (e.selectedItem) {
                    // if (_.findIndex(e.checkedItems, e.selectedItem) < 0) {
                    if (_.findIndex(e.checkedItems, o => o.key === e.selectedItem.key) < 0) {
                      tktSearchAction.removeTktSearchItem([e.selectedItem])
                    } else {
                      const selectedItem = {
                        key: e.selectedItem.key,
                        type: e.selectedItem.type,
                      }
                      tktSearchAction.setInputTktSearch(makeCondition(e.selectedItem, tktSearch))
                      tktSearchAction.addTktSearchItem([e.selectedItem])
                      // focus
                      let element = null
                      setTimeout(() => {
                        if (selectedItem.type==='check') {
                          element = document.getElementById(
                            selectedItem.key + '_' +
                            CHECKS[selectedItem.key][0].id);
                        // } else if (e.selectedItem.type==='radio') {
                        //   element = document.getElementById(e.selectedItem.key + -0);
                        } else {
                          element = document.getElementById(selectedItem.key + '_array');
                          element = element?element:document.getElementById(selectedItem.key + '_0');
                          element = element?element:document.getElementById(selectedItem.key + '_from');
                        }
                        //
                        // console.log(element)
                        element && element.focus();
                      });
                    }
                  }
                  tktSearchAction.setInputTktSearch({listChanged: false})
                }}
                
              />
            </Pane>
            <Pane>
              <Condition
                search={tktSearch}
                onBlur={tktSearchAction.changeInputTktSearch}
                setInput={tktSearchAction.setInputTktSearch}
                title={TITLE.ticketSearch.condition}
                onClose={tktSearchAction.removeTktSearchItem}
                // searchApi={TicketApi.findSearch}
                searchApi={TicketApi}
                tktSrchptPrivForm='B0101'
              />
            </Pane>
          </SplitPane>
          {tktSearch.data && tktSearch.data.length &&
          <Pane>
            <SearchResult
              search={tktSearch}
              onBlur={tktSearchAction.changeInputTktSearch}
              setInput={tktSearchAction.setInputTktSearch}
              title={TITLE.ticketSearch.result}
              searchApi={TicketApi}
              tktSrchptPrivForm='B0101'
            />
          </Pane>
          }
        </SplitPane>
      </Container>
      <Common.RouteLeavingGuard
        when={true}
        shouldBlockNavigation={()=>false}
      />
    </React.Fragment>
  )
}