import _ from 'lodash';


export const TKT_STATUS_CODE = [
  {name: '', value: ''},
  {name: '', value: '1'},
  {name: 'V', value: '2'},
  {name: 'OV', value: '3'},
  {name: 'R', value: '4'},
  {name: 'AR', value: '5'},
  {name: '', value: '6'},
  {name: '', value: '7'},
  {name: '', value: '8'},
  {name: '', value: '9'},
]

export const NSB_OPTION = [
  {name: 'NSB額にIT-NOを設定', value: '1'},
  {name: 'NSB額にIT-NOの先頭5桁を*で囲って設定', value: '2'},
]

export const CHART_TYPE = [
  {name: '縦棒', value: 'Column'},
  {name: '横棒', value: 'Bar'},
  {name: '折れ線', value: 'Line'},
  {name: '面', value: 'Area'},
  {name: '円', value: 'Pie'},
  {name: '散布', value: 'Scatter'},
]

export const FIELDS = [
  { header: 'レポート項目', dataType: 0, subFields: [
    { header: 'TKTデータ項目', dataType: 0, subFields: [
      { key: 110 ,header: 'TKT NO. (A/L コード)', binding: 'tktTktnoAl', format: ''},
      { key: 120 ,header: 'TKT NO.(S/N)', binding: 'tktTktnoSn', format: ''},
      { key: 800 ,header: 'TKT NO.', binding: 'tktTktno', format: ''},
      // { key: 810 ,header: 'TKT NO. FROM-TO', binding: 'tktTktnoFromTo', format: ''},
      { key: 130 ,header: '親TKT NO.', binding: 'tktParentTktno', format: ''},
      { key: 950 ,header: 'AIRLINEコード', binding: 'tktTktnoAlForReport', format: ''},
      { key: 140 ,header: 'TKTステータス', binding: 'tktStatus', format: '', },
      { key: 1500 ,header: 'TKTステータスコード', binding: 'tktStatusCd', format: ''},
      { key: 150 ,header: 'PAX NAME', binding: 'tktPaxName', format: ''},
      { key: 160 ,header: 'PAX TYPE', binding: 'tktPaxType', format: ''},
      { key: 170 ,header: 'PTC', binding: 'tktPtc', format: ''},
      { key: 400 ,header: 'IATAコード', binding: 'tktIata', format: ''},
      { key: 230 ,header: 'GDS区分', binding: 'tktGds', format: ''},
      { key: 410 ,header: 'PNRリファレンス', binding: 'tktPnr', format: ''},
      { key: 1510 ,header: 'ORDER ID', binding: 'tktOrderId', format: ''},
      { key: 1520 ,header: 'NDCフラグ', binding: 'tktNdcFlg', format: ''},
      { key: 190 ,header: '券種', binding: 'tktTktType', format: ''},
      { key: 200 ,header: '券種(処理日毎)', binding: 'tktTktType2', format: ''},
      { key: 210 ,header: '運賃種別', binding: 'tktFareType', format: ''},
      { key: 220 ,header: '運賃種別コード', binding: 'tktFareCd', format: ''},
      { key: 250 ,header: 'VOID ID', binding: 'tktVoidId', format: ''},
      { key: 240 ,header: 'オンラインVOID ID', binding: 'tktOvoId', format: ''},
      { key: 490 ,header: '月跨ぎフラグ', binding: 'tktMonthStepoverFlg', format: ''},
      { key: 500 ,header: 'チケット明細発行済フラグ', binding: 'tktDetailIssuedFlg', format: ''},
      { key: 510 ,header: 'お客様用発行済フラグ', binding: 'tktCustomerOutputFlg', format: ''},
      { key: 1530 ,header: 'AGENT COUPON発行済フラグ', binding: 'tktAgentCouponOutputFlg', format: ''},
      { key: 1540 ,header: 'CCCF発行済フラグ', binding: 'tktCccfOutputFlg', format: ''},
      { key: 1550 ,header: 'REFUND NOTICE発行済フラグ', binding: 'tktRefundNoticeOutputFlg', format: ''},
      { key: 180 ,header: 'COMM RATE', binding: 'tktCommRate', format: 'p2', dataType: 'String'},
      { key: 420 ,header: 'クレジット会社コード', binding: 'tktCreditCom', format: ''},
      { key: 430 ,header: 'クレジットカードNO.', binding: 'tktCardNum', format: ''},
      { key: 370 ,header: 'NR-ID', binding: 'tktNrid', format: ''},
      { key: 380 ,header: 'IT-NO.(VC&CAR)', binding: 'tktItno', format: ''},
      { key: 710 ,header: 'TOUR CODE', binding: 'tktTourCd', format: ''},
      { key: 690 ,header: 'VC', binding: 'tktVc', format: ''},
      { key: 700 ,header: 'CAR', binding: 'tktCar', format: ''},
      { key: 7321 ,header: 'NSB額(IT-NO)', binding: 'tktNsbItno', format: 'c0', dataType: 'String'},
      { key: 7322 ,header: 'NSB額(*IT-NO先頭5桁*)', binding: 'tktNsbItnoFiveDigit', format: 'c0', dataType: 'String'},
      { key: 460 ,header: 'FARE CALCULATION MODE INDICATOR', binding: 'tktFareCalMode', format: ''},
      { key: 470 ,header: 'FARE CALCULATION PRICING INDICATOR', binding: 'tktFareCalPricingInd', format: ''},
      { key: 480 ,header: 'FARE CALCULATION', binding: 'tktFareCalculation', format: ''},
      { key: 520 ,header: 'FARE', binding: 'tktFare', format: ''},
      { key: 530 ,header: 'EQUIVALENT FARE PAID', binding: 'tktEqFapd', format: ''},
      { key: 540 ,header: 'TOTAL', binding: 'tktTotal', format: ''},
      { key: 550 ,header: 'ENDORSEMENTS/RESTRICTIONS', binding: 'tktEndRest', format: ''},
      { key: 590 ,header: 'FORM OF PAYMENT', binding: 'tktFormPay', format: ''},
      { key: 260 ,header: '受注コード(R)', binding: 'tkt7lOdrCd', format: ''},
      { key: 270 ,header: 'セクションコード(S)', binding: 'tkt7lSecCd', format: ''},
      { key: 280 ,header: '担当者コード(A)', binding: 'tkt7lMbrCd', format: ''},
      { key: 290 ,header: '方面コード(D)', binding: 'tkt7lAreaCd', format: ''},
      { key: 300 ,header: '法人コード(C)', binding: 'tkt7lComCd', format: ''},
      { key: 870 ,header: '法人区分', binding: 'tkt7lComKubun', format: ''},
      { key: 310 ,header: '入金コード(M)', binding: 'tkt7lPayCd', format: ''},
      { key: 320 ,header: 'FREEコード(F)', binding: 'tkt7lFreeCd', format: ''},
      { key: 330 ,header: 'ツアーコード(T)', binding: 'tkt7lTourCd', format: ''},
      { key: 340 ,header: 'KBコード1(K)', binding: 'tkt7lKb1Cd', format: ''},
      { key: 350 ,header: 'KBコード2(B)', binding: 'tkt7lKb2Cd', format: ''},
      { key: 360 ,header: 'E/Oコード(E)', binding: 'tkt7lEoCd', format: ''},
      { key: 830 ,header: '受注コード名', binding: 'tkt7lOdrCdName', format: ''},
      { key: 840 ,header: 'セクションコード名', binding: 'tkt7lSecCdName', format: ''},
      { key: 850 ,header: '担当者名(日本語)', binding: 'tkt7lMbrCdName', format: ''},
      { key: 860 ,header: '方面名', binding: 'tkt7lAreaCdName', format: ''},
      { key: 880 ,header: '法人名(日本語)', binding: 'tkt7lComCdName', format: ''},
      { key: 890 ,header: '入金コード名', binding: 'tkt7lPayCdName', format: ''},
      { key: 900 ,header: 'FREEコード名', binding: 'tkt7lFreeCdName', format: ''},
      { key: 910 ,header: 'ツアーコード名', binding: 'tkt7lTourCdName', format: ''},
      { key: 920 ,header: 'KBコード1名', binding: 'tkt7lKb1CdName', format: ''},
      { key: 930 ,header: 'KBコード2名', binding: 'tkt7lKb2CdName', format: ''},
      { key: 940 ,header: 'E/Oコード名', binding: 'tkt7lEoCdName', format: ''},
      { key: 960 ,header: '税ID', binding: 'taxId', format: ''},
      { key: 390 ,header: 'REFUND ID', binding: 'tktRefId', format: ''},
      { key: 630 ,header: 'AUTO REFUND ID', binding: 'tktAtRefid', format: ''},
      { key: 650 ,header: 'REFUND区分', binding: 'tktRefType', format: ''},
      { key: 821 ,header: 'REFUND COUPON NO1', binding: 'tktCpnNo1', format: ''},
      { key: 822 ,header: 'REFUND COUPON NO2', binding: 'tktCpnNo2', format: ''},
      { key: 823 ,header: 'REFUND COUPON NO3', binding: 'tktCpnNo3', format: ''},
      { key: 824 ,header: 'REFUND COUPON NO4', binding: 'tktCpnNo4', format: ''},
      { key: 820 ,header: 'REFUND COUPON NO(1～4)', binding: 'tktCpnNo1234', format: ''},
      { key: 660 ,header: 'SAC', binding: 'tktSac', format: ''},
      { key: 640 ,header: 'WAIVER CODE', binding: 'tktWaiverCode', format: ''},
      { key: 670 ,header: 'REFUND MEMO', binding: 'tktRefMemo', format: ''},
      { key: 680 ,header: 'REFUND PFC(XF) DATA', binding: 'tktRefPfc', format: ''},
      { key: 560 ,header: 'REISSUE ID', binding: 'tktReissueId', format: ''},
      { key: 570 ,header: 'ORIGINAL ISSUE', binding: 'tktOrgIsu', format: ''},
      { key: 580 ,header: 'ISSUED IN EXCHANGE', binding: 'tktIsuEx', format: ''},
      { key: 2080 ,header: 'CARD有効期限', binding: 'tktCaExdate', format: ''},
      { key: 720 ,header: 'CARD会員名', binding: 'tktCaMem', format: ''},
      { key: 730 ,header: 'CARD承認カードID', binding: 'tktCaAprid', format: ''},
      { key: 740 ,header: 'CARD承認番号', binding: 'tktCaAprno', format: ''},
      { key: 760 ,header: '機能分類コード', binding: 'tktFuncCd', format: ''},
      { key: 770 ,header: 'CARD REMARKS1', binding: 'tktRmks1', format: ''},
      { key: 780 ,header: 'CARD REMARKS2', binding: 'tktRmks2', format: ''},
      { key: 790 ,header: 'CARD REMARKS3', binding: 'tktRmks3', format: ''},
      { key: 185 ,header: '得意先%', binding: 'tktTokuiPercent', format: 'p2', dataType: 'String'},
      // { key: 1610 ,header: 'EMDフラグ', binding: 'tktEmdFlag', format: ''},
      { key: 1620 ,header: 'CONJUNCTION TICKET DATA', binding: 'tktConjData', format: ''},
      { key: 1630 ,header: 'REASON FOR ISSUANCE CODE', binding: 'tktReasonIssuanceCode', format: ''},
      { key: 1640 ,header: 'REASON FOR ISSUANCE DESCRIPTION', binding: 'tktReasonIssuanceDesc', format: ''},
      { key: 1650 ,header: 'EMD TKT REMARKS', binding: 'tktEmdRmks', format: ''},
      { key: 600 ,header: 'メモ', binding: 'tktMemo', format: ''},
      { key: 440 ,header: '予約OFFICE ID/PCC', binding: 'tktReserveOfficeidAndPcc', format: ''},
      { key: 450 ,header: '発券OFFICE ID/PCC', binding: 'tktTicketingOfficeidAndPcc', format: ''},
      { key: 610 ,header: '登録担当セクションコード', binding: 'tktRstsecCd', format: ''},
      { key: 620 ,header: '登録担当者コード', binding: 'tktRstchrCd', format: ''},
      { key: 1660 ,header: 'シリアルナンバー', binding: 'serialNum', format: ''},
      { header: '日付項目（TKT）', dataType: 0, subFields: [
        { key: 2010 ,header: '発券日', binding: 'tktIsuDate', format: '', dataType: 'Date'},
        { key: 2020 ,header: '出発日', binding: 'tktDepDate', format: '', dataType: 'Date'},
        { key: 2090 ,header: '出発曜日', binding: 'tktDepDateDayOfWeek', format: ''},
        { key: 2030 ,header: '国際線出発日', binding: 'tktIntDepDate', format: '', dataType: 'Date'},
        { key: 2100 ,header: '国際出発曜日', binding: 'tktIntDepDateDayOfWeek', format: ''},
        { key: 2070 ,header: 'VOID処理日', binding: 'tktVoidDate', format: '', dataType: 'Date'},
        { key: 2040 ,header: 'REFUND処理日', binding: 'tktRefDate', format: '', dataType: 'Date'},
        { key: 2110 ,header: '処理日', binding: 'proDate', format: '', dataType: 'Date'},
        { key: 2050 ,header: '受信日時', binding: 'tktRcvDate', format: 'yyyy/MM/dd H:mm', dataType: 'Date'},
        { key: 2060 ,header: '登録日時', binding: 'tktRstDate', format: 'yyyy/MM/dd H:mm', dataType: 'Date'},
      ]}
    ]},
    { header: 'FLTデータ項目', dataType: 0, subFields: [
      { key: 3020 ,header: 'CPN NO.', binding: 'fltCpnNo', format: ''},
      { key: 3010 ,header: 'FLT SEG NO.', binding: 'fltFltSeg', format: 'n0', dataType: 'String'},
      { key: 3500 ,header: 'STOP OVER CODE', binding: 'fltStopoverCode', format: ''},
      { key: 3030 ,header: 'FLT A/Lコード', binding: 'fltAirlineCd', format: ''},
      { key: 3040 ,header: 'FLT NO.', binding: 'fltFltNum', format: ''},
      { key: 3100 ,header: '搭乗クラス', binding: 'fltClass', format: ''},
      { key: 3110 ,header: '出発地', binding: 'fltFrom', format: ''},
      { key: 3120 ,header: '到着地', binding: 'fltTo', format: ''},
      { key: 3130 ,header: 'FARE BASIS', binding: 'fltFareBasis', format: ''},
      { key: 3070 ,header: 'NVA', binding: 'fltNva', format: '', dataType: 'Date'},
      { key: 3080 ,header: 'NVB', binding: 'fltNvb', format: '', dataType: 'Date'},
      { key: 3090 ,header: 'BAGGAGE ALLOWANCE', binding: 'fltBagAlw', format: ''},
      // { key: 3140 ,header: '運賃種別(FLT)', binding: 'fltFareType', format: ''},
      // { key: 3150 ,header: '運賃種別コード(FLT)', binding: 'fltFareCd', format: ''},
      { key: 3125 ,header: 'フライト区間種別', binding: 'fltIndByteTkt', format: ''},
      // { key: 3160 ,header: 'シリアルナンバー(FLT)', binding: 'fltTktnoSn', format: ''},
      { key: 7140 ,header: 'GROSS/CASH (FLT単位)', binding: 'tktGsCashLedger', format: 'c0', dataType: 'String'},
      { key: 7141 ,header: 'GROSS/CREDIT (FLT単位)', binding: 'tktGsCreditLedger', format: 'c0', dataType: 'String'},
      { key: 7150 ,header: 'TAX/CASH (FLT単位)', binding: 'tktTaxCashLedger', format: 'c0', dataType: 'String'},
      { key: 7151 ,header: 'TAX/CREDIT (FLT単位)', binding: 'tktTaxCreditLedger', format: 'c0', dataType: 'String'},
      { header: '日付項目(FLT)', dataType: 0, subFields: [
        { key: 5010 ,header: '搭乗日', binding: 'fltBoardDate', format: '', dataType: 'Date'},
        { key: 3050 ,header: '搭乗時間', binding: 'fltBoardTime', format: 'H:mm', dataType: 'Date'},
        { key: 3170 ,header: '搭乗曜日', binding: 'fltBoardDateDayOfWeek', format: ''},
        { key: 5020 ,header: '到着日', binding: 'fltArrDate', format: '', dataType: 'Date'},
        { key: 3060 ,header: '到着時間', binding: 'fltArrTime', format: 'H:mm', dataType: 'Date'},
        // { key: 5030 ,header: '登録日時(FLT)', binding: 'fltRstDate', format: 'yyyy/MM/dd H:mm', dataType: 'Date'},
      ]}
    ]},
    { header: 'EMDクーポンデータ項目', dataType: 0, subFields: [
      { key: 6010 ,header: 'EMD CPN NO.', binding: 'emdCpnNo', format: 'n0', dataType: 'String'},
      { key: 6020 ,header: 'REASON FOR ISSUANCE SUB CODE', binding: 'emdReasonIssuanceSubCode', format: ''},
      { key: 6030 ,header: 'REASON FOR ISSUANCE SUB CODE DESCRIPTION', binding: 'emdReasonIssuanceSubCodeDesc', format: ''},
      { key: 6040 ,header: 'COUPON FILED FEE AMOUNT', binding: 'emdCpnFieldFeeAmt', format: ''},
      { key: 6050 ,header: 'COUPON VALUE AMOUNT', binding: 'emdCpnValueAmt', format: ''},
      { key: 6060 ,header: 'CONSUMED AT ISSUANCE INDICATOR', binding: 'emdConsumedIssuaranceInd', format: ''},
      { key: 6070 ,header: 'COUPON REMARKS', binding: 'emdCpnRmks', format: ''},
    ]},
  ]},
  { header: '集計項目', dataType: 0, subFields: [
    { header: 'TKTデータ項目(集計)', dataType: 0, subFields: [
      { key: 7010 ,header: 'GROSS/CASH', binding: 'tktGsCash', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7020 ,header: 'GROSS/CREDIT', binding: 'tktGsCredit', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7030 ,header: 'TAX/CASH', binding: 'tktTaxCash', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7040 ,header: 'TAX/CREDIT', binding: 'tktTaxCredit', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7135 ,header: 'GROSS(CASH・CREDIT)', binding: 'tktGsCashAndCredit', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7136 ,header: 'TAX(CASH・CREDIT)', binding: 'tktTaxCashAndCredit', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7160 ,header: 'GROSS/TAX計', binding: 'totalGsTax', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7330 ,header: 'COMMISSION額', binding: 'tktCommAmt', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7340 ,header: 'COMMISSION額消費税', binding: 'tktCommAmtTax', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7350 ,header: 'COMMISSION額(税込)', binding: 'tktCommAmtTaxIncluded', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7360 ,header: 'BSP請求額', binding: 'tktBspClm', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7370 ,header: 'BSP請求額(税込)', binding: 'tktBspClmTaxIncluded', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7090 ,header: 'SALES AMOUNT', binding: 'salesAmt', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7130 ,header: '2A NET額', binding: 'tkt2aNet', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7320 ,header: 'NSB額', binding: 'tktNsb', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 750 ,header: 'CARD承認金額', binding: 'tktCaApramt', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7270 ,header: '得意先金額', binding: 'tktTokuiClm', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7280 ,header: '得意先消費税', binding: 'tktTokuiTax', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7290 ,header: '得意先金額(税込)', binding: 'tktTokuiClmTaxIncluded', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7080 ,header: '得意先請求金額', binding: 'tktTokuiClm', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7300 ,header: '自社手数料', binding: 'inHouseFee', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7070 ,header: 'CANCEL PENALTY', binding: 'tktLesCntPen', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7500 ,header: 'GROSS FARE PAID', binding: 'tktGrsFarePd', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7120 ,header: 'GROSS FARE USED', binding: 'tktFrUsed', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7050 ,header: 'GROSS FARE REFUND', binding: 'tktGrsFareRef', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7060 ,header: 'TAX REFUND', binding: 'tktTaxRef', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7100 ,header: 'TOTAL AMOUNT', binding: 'tktTotalAmt', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7510 ,header: 'NET FARE REFUND', binding: 'tktNetFareRef', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7110 ,header: 'NET FARE ADJUSTMENT', binding: 'tktNetFare', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7310 ,header: 'TOTAL AMOUNT(NFA)', binding: 'totalAmtNfa', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7520 ,header: 'REFUND COMMISSION額', binding: 'tktRefundCommissinonAmt', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7530 ,header: 'REFUND COMMISSION額消費税', binding: 'tktRefundCommissinonAmtTax', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7540 ,header: 'BALANCE PAYABLE', binding: 'tktBalancePayable', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7250 ,header: 'SHARE', binding: 'tktShare', format: 'p2', dataType: 'Number', aggregate: 'Sum', showAs: 'PctCol'},
      { key: 7380 ,header: 'FREE項目', binding: 'tktFreeAmt', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7390 ,header: 'KB・NET額', binding: 'tktKbNetAmt', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
      { key: 7170 ,header: '冊数(合計)', binding: 'totalBookCnt', format: '', dataType: 'Number', aggregate: 'Sum'},
      { key: 7180 ,header: '冊数(通常)', binding: 'nomalBookCnt', format: '', dataType: 'Number', aggregate: 'Sum'},
      { key: 7190 ,header: '冊数(VOID)', binding: 'voidBookCnt', format: '', dataType: 'Number', aggregate: 'Sum'},
      { key: 7200 ,header: '冊数(REFUND)', binding: 'refBookCnt', format: '', dataType: 'Number', aggregate: 'Sum'},
      { key: 7210 ,header: '人数(合計)', binding: 'totalNumOfPeople', format: '', dataType: 'Number', aggregate: 'Sum'},
      { key: 7220 ,header: '人数(大人)', binding: 'adultNumOfPeople', format: '', dataType: 'Number', aggregate: 'Sum'},
      { key: 7230 ,header: '人数(小児)', binding: 'childNumOfPeople', format: '', dataType: 'Number', aggregate: 'Sum'},
      { key: 7240 ,header: '人数(幼児)', binding: 'infantNumOfPeople', format: '', dataType: 'Number', aggregate: 'Sum'},
    ]},
    { header: 'FLTデータ項目(集計)', dataType: 0, subFields: [
      { key: 8010 ,header: 'サポート額', binding: 'fltSupportFee', format: 'c0', dataType: 'Number', aggregate: 'Sum'},
    ]}
  ]}

]
export const BI_MAP = [
  {key: 'GROSS/CASH', id: 'tktGsCash', tktTop: 'tktGsCashChildTktTop', void: 'tktGsCashVoid'},
  {key: 'GROSS/CREDIT', id: 'tktGsCredit', tktTop: 'tktGsCreditChildTktTop', void: 'tktGsCreditVoid'},
  {key: 'TAX/CASH', id: 'tktTaxCash', tktTop: 'tktTaxCashChildTktTop', void: 'tktTaxCashVoid'},
  {key: 'TAX/CREDIT', id: 'tktTaxCredit', tktTop: 'tktTaxCreditChildTktTop', void: 'tktTaxCreditVoid'},
  {key: 'GROSS/TAX計', id: 'totalGsTax', tktTop: 'tktGsTaxChildTktTop', void: 'tktGsTaxVoid'},
  {key: 'GROSS FARE REFUND', id: 'tktGrsFareRef', tktTop: 'tktGrsFareRefChildTktTop', void: 'tktGrsFareRefVoid'},
  {key: 'TAX REFUND', id: 'tktTaxRef', tktTop: 'tktTaxRefChildTktTop', void: 'tktTaxRefVoid'},
  {key: 'CANCEL PENALTY', id: 'tktLesCntPen', tktTop: 'tktLesCntPenChildTktTop', void: 'tktLesCntPenVoid'},
  {key: '得意先請求金額', id: 'tktTokuiClm', tktTop: 'tktTokuiClmChildTktTop', void: 'tktTokuiClmVoid'},
  {key: 'SALES AMOUNT', id: 'salesAmt', tktTop: 'tktSalesAmountChildTktTop', void: 'tktSalesAmountVoid'},
  {key: 'TOTAL AMOUNT', id: 'tktTotalAmt', tktTop: 'tktTotalAmtChildTktTop', void: 'tktTotalAmtVoid'},
  {key: 'NET FARE REFUND', id: 'tktNetFareRef', tktTop: 'tktNetFareRefChildTktTop', void: 'tktNetFareRefVoid'},
  {key: 'NET FARE ADJUSTMENT', id: 'tktNetFare', tktTop: 'tktNetFareChildTktTop', void: 'tktNetFareVoid'},
  {key: 'TOTAL AMOUNT(NFA)', id: 'totalAmtNfa', tktTop: 'tktTotalAmtNfaChildTktTop', void: 'tktTotalAmtNfaVoid'},
  {key: 'REFUND COMMISSION額', id: 'tktRefundCommissinonAmt', tktTop: 'tktRefCommAmountChildTktTop', void: 'tktRefCommAmountVoid'},
  {key: 'REFUND COMMISSION額消費税', id: 'tktRefundCommissinonAmtTax', tktTop: 'tktRefCommAmounTaxtChildTktTop', void: 'tktRefCommAmounTaxtVoid'},
  {key: 'BALANCE PAYABLE', id: 'tktBalancePayable', tktTop: 'balancePayableChildTktTop', void: 'balancePayableVoid'},
  {key: 'GROSS FARE USED', id: 'tktFrUsed', tktTop: 'tktFrUsedChildTktTop', void: 'tktFrUsedVoid'},
  {key: 'GROSS FARE PAID', id: 'tktGrsFarePd', tktTop: 'tktGrsFarePdChildTktTop', void: 'tktGrsFarePdVoid'},
  {key: '2A NET額', id: 'tkt2aNet', tktTop: 'tkt2aNetChildTktTop', void: 'tkt2aNetVoid'},
  {key: 'GROSS(CASH・CREDIT)', id: 'tktGsCashAndCredit', tktTop: 'tktGsCashCreditChildTktTop', void: 'tktGsCashCreditVoid'},
  {key: 'TAX(CASH・CREDIT)', id: 'tktTaxCashAndCredit', tktTop: 'tktTaxCashCreditChildTktTop', void: 'tktTaxCashCreditVoid'},
  {key: '得意先消費税', id: 'tktTokuiTax', tktTop: 'tktTokuiTaxChildTktTop', void: 'tktTokuiTaxVoid'},
  {key: '得意先金額', id: 'tktTokuiAmount', tktTop: 'tktTokuiAmountChildTktTop', void: 'tktTokuiAmountVoid'},
  {key: '得意先金額(税込)', id: 'tktTokuiClmTaxIncluded', tktTop: 'tktTokuiAmountTaxIncludeChildTktTop', void: 'tktTokuiAmountTaxIncludeVoid'},
  {key: '自社手数料', id: 'inHouseFee', tktTop: 'inHouseFeeChildTktTop', void: 'inHouseFeeVoid'},
  {key: 'COMMISSION額', id: 'tktCommAmt', tktTop: 'tktcommAmountChildTktTop', void: 'tktcommAmountVoid'},
  {key: 'COMMISSION額消費税', id: 'tktCommAmtTax', tktTop: 'tktcommAmountTaxChildTktTop', void: 'tktcommAmountTaxVoid'},
  {key: 'COMMISSION額(税込)', id: 'tktCommAmtTaxIncluded', tktTop: 'tktcommAmountTaxIncludeChildTktTop', void: 'tktcommAmountTaxIncludeVoid'},
  {key: 'BSP請求額', id: 'tktBspClm', tktTop: 'tktBspBillingAmountChildTktTop', void: 'tktBspBillingAmountVoid'},
  {key: 'BSP請求額(税込)', id: 'tktBspClmTaxIncluded', tktTop: 'tktBspBillingAmountTaxIncludeChildTktTop', void: 'tktBspBillingAmountTaxIncludeVoid'},
  {key: 'FREE項目', id: 'tktFreeAmt', tktTop: 'tktFreeAmountChildTktTop', void: 'tktFreeAmountVoid'},
  {key: 'KB・NET額', id: 'tktKbNetAmt', tktTop: 'tktKbNetAmountChildTktTop', void: 'tktKbNetAmountVoid'},
]

export const BI_INTEGER =[
  'tktCaApramt',
  'tktGsCashLedger',
  'tktGsCreditLedger',
  'tktTaxCashLedger',
  'tktTaxCreditLedger',
]

export const BI_INTEGER_NO =[
  'fltFltSeg',
  'emdCpnNo',
]