import _ from 'lodash';

export const SYSTEM_MENU= [
  {value: 'travel', name: '旅行会社情報・システム設定'},
  // {value: 'sys', name: 'システム設定'},
  {value: 'maint', name: 'データメンテナンス'},
  // {value: 'user', name: 'ユーザID管理'},
]
export const FARE_JUDG = [
  {name: '判定有', value: '1'},
  {name: '判定無', value: '0'},
]

export const DMI_PERIOD = [
  {name: '月次', value: '1'},
  {name: '日付指定', value: '0'},
]

export const DMI_DATE_TYPE = [
  {name: '搭乗日', value: '0'},
  {name: '発券日', value: '1'},
  {name: '出発日', value: '2'},
]

export const DMI_OPENTKT_TYPE = [
  {name: '削除する', value: '0'},
  {name: '削除しない(ただし、発券後1年以上経過した場合は削除します)', value: '1'},
]

export const SYS_WAIVER_CODE_REGISTR_COL = [
  {name: 'WAIVER CODE', value: '0'},
  {name: 'REFUND MEMO', value: '1'},
]

export const LOG_DATE_RANGE = 14

export const LIST_ITEMS = {
  usr: [
    {
      id: '',
      header: '',
      isReadOnly: true,
      isRequired: false,
      width: 33,
      allowResizing: false,
      allowSorting: false,
      align: 'center',
    },
    {
      id: 'userId',
      header: 'ユーザID',
      format: null,
      isReadOnly: false,
      isEditable: false,
      isKey: true,
      isRequired: true,
      maxLength: 14,
      width: '2*',
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['required','halfWidth','uniqueGrid'],
    },
    {
      id: 'mbrCd',
      header: '権限(担当者コード)',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: true,
      maxLength: 6,
      width: '2*',
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: '',
      validtype: ['required'],
    },
    {
      id: 'usrDelete',
      header: '削除',
      format: null,
      isReadOnly: false,
      maxLength: 10,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      align: 'center',
      isHeader: true,
    },
  ],
  download: [
    {
      id: 'id',
      header: 'id',
    },
    {
      id: 'officeID',
      header: 'officeID',
    },
    {
      id: 'loginID',
      header: 'loginID',
    },
    {
      id: 'datetime',
      header: 'datetime',
      dataType: 1,
    },
    {
      id: 'functionID',
      header: 'functionID',
    },
    {
      id: 'functionName',
      header: 'functionName',
    },
    {
      id: 'functionParameter',
      header: 'functionParameter',
    },
  ]
}