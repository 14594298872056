import 'core-js/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { configureStore, history, persistor } from 'store'
import { PersistGate } from 'redux-persist/integration/react'
import { App } from 'components';

import * as wjcCore from '@grapecity/wijmo';
import '@grapecity/wijmo.cultures/wijmo.culture.ja';
import '@grapecity/wijmo.styles/wijmo.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'index.css';

// let key = 'devwww.uripro.jp,468229673524273#B0oiiiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPn9WUiB7U9xEboJmcm3UdNZGcQFVWw8WeD5WQvIkWx84cFFkV7U6VnVTZ4NzbuNnYlFFOPZzSIFEcjZzYrUjTGZEUyRTa8Q4Z85kS6RWTr2iWJdzVXBFRhNHO7YUS7YWSyYlSvVndiNzVSZzQi9Ge9c6LuVWZERlcwtGbUFUU4JTOpdTUiF7a8AnNyo7KJpmUWN4ZrFEOhF4aUFGUztEcXlnTjFHSO3EOCBTZwIDRwBXYIF4YDlnUygXOYZEbSh4ZOJFSrUWZnpFcJF4Tr56KiFnZvgjQ6UERMJVcwp6Y8t4dXZlc6cEW8FXRzJ5UkNmQIpFd7IlNyF6bTlESh3UUvYmQwklQPV7YHh7SjJkMMREU83Wd5kDRslGdQVFZDVjc4xEe83yQvkWUHtUQ5YmMudkRx94Y0pVar5EVPlTUWZzTIhVO6BXYOVkROhXTBFDSPVDRaNkRiojITJCLiQTNCVUR8QTNiojIIJCLyIDM7YTOwQTO0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiI5MDNzIDMgUDMxAjMyAjMiojI4J7QiwiIwpmLvJHcpJXduc7d7ZXZkJiOiMXbEJCLi8LpnrJvk/IvlrKomP1gjH9gjP0gjjrgjz1gjfrgjPqgjb8gjTqgjLqgjLiOiEmTDJCLiMzNyQjM5MzN6kjMygjN4IiOiQWSiwSfdtlOicGbmJCLiMjdxmDiy'
let key = 'www.uripro.jp,468229673524273#B0YIJVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zd4VTRrMkdydUbGJDMjdXRiVWaWBXU8IGV5hzKhtmWHVjavlTdwVFbxplZ4BHW8xmc6M5b4YjM0l7LNJkdJp7LRNUQhV4ZxFmaycWNzdnQXdTM5J5KDFDZXh6RnRXWVJ6bU3GbylVOuNHdoNXd8ZGeywGOsRjeCJ5SuBFM0NGMGV6YwUmMHtUM7QXez3GaOFjS52WQXZEMMdHaQ96KrV5dNFzTEtkUjdEb65WUvokWwYnMWNGWpZFdzAzbElGNOdzTvV7ZCVVa0hFOJhUWst6cz8WZhNndrEla0dESKB7QFBTZ4dUWMdmT8J6a0Z7QqZkUKVWdRRWcoRUMLRUQ8NUUzB7cNlUOvIlYidjMCN7Y4UUb5clZNhDWDZHVNpVeQFEbDlVOaFDUChEWTtmWiRnRadWWQZ5LpNWTZpVMFFFavMmeElEMGF4c7JXdPZEcuBlZoZVMFplI0IyUiwiI4YUM8cDRxUjI0ICSiwCN6QDNygzM7YTM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsICM5gDNzADI4AjNwIjMwIjI0ICdyNkIsICcq9ybyBXayVnL7d7diojIz5GRiwiI+S09ayL9Pyb9qCq9zO88RO88jO888K882O887K88jK88GO88kK88iK88iojIh94QiwiIzcjM4ITNzcjN9IjM8YDNiojIklkIs4XXbpjInxmZiwiIzYXMyAIMJo'
if (process.env.NODE_ENV === `development`) {
  key = '127.0.0.1|devwww.uripro.jp|localhost,879851643178951#B0ILcIjdxIDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRNUa4VzRsxUeLhkMzpEckh5MuNXWxljUG3CczFUSxg6VvNle7UVe93CbspHWJdzSLZHRzN6Nx2mYxwEe0NjRo3UUrRXRzxERGRXVTdjZB3EN83maHhWZXF6czlzQxo6TPN5KKt4Y4pEd5d4K5g7NolEZ8xGOPF6YK3UR4QETMFkexB7NZ3EZjhUOzEmdSRDMFh6RHtyK7E6ct5UerJHOTxUcF3WYTNXMJRjNw2iT5ElYi9UQ73iSPd6UykVUnZ5VlFDc5Y6QTJkNkpmR8N7NJR4Y7lTQotkYlpkZHhDUZhHdmRUeFVHe09Uey8UOwJ4N4YEevYFVkZmSVhkN5FHOIJTTG3EZj3iVDNGenx6MBZHWOFHMWplRO3EcE9WWjplWsVkUQVTOyJ4LxQkcZdDUGN4ZBB5ViBXc4JXVvcXSNNjS95WYi9kVKR7TxElMShUetVzLpN7RiojITJCLiYjQyETNFhTMiojIIJCLwgjMwEDO8gjN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLicjM5EDNwASNyIDMyIDMyIiOiQncDJCLiQ7cvhGbhN6bsxCcq9ybyBXayVnL7d7d6VGZsEjLw8CMucjMxIiOiMXbEJCLi8LpnrJvk/IvlrKomLqgjjqgjbqgjD0gjb8gjnrgjfrgjzKnmX0lmLiOiEmTDJCLiETN9gzNxMDN6ETN8kzN8IiOiQWSiwSfdtlOicMbgJ'
}
wjcCore.setLicenseKey(key);

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
