import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import * as Common from 'components/common';
import { Title, Card } from 'components';
import { MasterListComponent as MasterList } from './MasterListComponent';
import { AccountCodeSearchComponent as AccountCodeSearch } from './AccountCodeSearchComponent';
import { CompanySearchComponent as CompanySearch } from './CompanySearchComponent';
import { MemberSearchComponent as MemberSearch } from './MemberSearchComponent';
import { ProductionSearchComponent as ProductionSearch } from './ProductionSearchComponent';
import { DecodeSearchComponent as DecodeSearch } from './DecodeSearchComponent';
import { FareSearchComponent as FareSearch } from './FareSearchComponent';
import { 
  Col,
  Row,
  Container,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, getInitData } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { TITLE } from 'constants/title';
import { MASTER, EMPTY_SEARCH } from 'constants/master';

export const MasterComponent = () => {

  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  const login = useSelector(state => state.Login);
  
  const history = useHistory();
  const location = useLocation();
  const { type } = useParams();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  // const onActionClick = (master.type, action) => {
  //   let entry = {};
  //   let hasError = false;
  //   // setMessage('')

  //   _.forEach(props.login[master.type].target, (v,k) => {
  //       entry = entryCheck(props.login, {target: {id: v, value: props.login[v].value}})
  //       hasError = hasError || entry[v].isInvalid
  //       props.setInput(entry)
  //   })
  //   !hasError && action()
  // }

  React.useEffect(() => {
    const array = (_.filter(MASTER, {key: type}).length > 0)?MASTER:MASTER[0].items
    const key = (_.filter(array, {key: type})[0])
      ? _.filter(array, {key: type})[0].key: null
    // console.log('master type change')
    appAction.setMessage({message: ''})
    if (key) {
      const isCode = !(_.filter(MASTER, {key: type}).length > 0)
      // console.log(isCode)
      masterAction.setInputMaster({isCode: isCode, type: type})
    } else {
      history.replace(`${TITLE.master.mainLink}/main`)
    }
  }, [type])

  const generateComponent = () => {
    switch (master.type) {
      case 'order':
      case 'area':
      case 'section':
      case 'free':
      case 'tour':
      case 'kb1':
      case 'kb2':
      case 'pay':
      case 'eo':
        return (
          <React.Fragment>
            <AccountCodeSearch disabled={login.auth090 && login.auth090!=='1'} />
          </React.Fragment>
        )
      case 'company':
        return (
          <React.Fragment>
            <CompanySearch disabled={login.auth090 && login.auth090!=='1'} />
          </React.Fragment>
        )
      case 'member':
        return (
          <React.Fragment>
            <MemberSearch disabled={login.auth080 && login.auth080!=='1'} />
          </React.Fragment>
        )
      case 'production':
        return (
          <React.Fragment>
            <ProductionSearch disabled={login.auth090 && login.auth090!=='1'} />
          </React.Fragment>
        )
      case 'decode':
        return (
          <React.Fragment>
            <DecodeSearch disabled={login.auth090 && login.auth090!=='1'} />
          </React.Fragment>
        )
      case 'fare':
        return (
          <React.Fragment>
            <FareSearch disabled={login.auth090 && login.auth090!=='1'} />
          </React.Fragment>
        )
      default:
        return EMPTY_SEARCH
    }
  }

  const getDisabled = () => {
    return (master.type === 'member' && login.auth080 && login.auth080!=='1')
      || (master.type !== 'member' && login.auth090 && login.auth090!=='1')
  }

  return (
    <React.Fragment>
      <Container fluid className='main'>
        <Title title={TITLE.master.name} icon={TITLE.master.icon}/>
        <SplitPane split="vertical" className='split-pane-top'>
          <Pane initialSize='20%' minSize='20%' maxSize='20%'>
            <MasterList disabled={getDisabled()} />
          </Pane>
          <Pane>
            {generateComponent()}
          </Pane>
        </SplitPane>
      </Container>
      <Common.RouteLeavingGuard
        when={true}
        navigate={(path, state) => history.push(path, state)}
        shouldBlockNavigation={(location) => {
          return (!location.state || !(location.state && location.state.nonRouteLeavingGuard))
        }}
        uiState='Master'
      /> 
    </React.Fragment>
  )
}