import _ from 'lodash';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ReactSwal = withReactContent(Swal)

export const alert = (props) => {

  let reverseButtons = true
  let focusCancel = true
  if (props) {
    if (props.showConfirmButton === false || props.showCancelButton === false) {
      reverseButtons = false
      focusCancel = false
    }
  }

  return ReactSwal.mixin({
    toast: false,
    position: 'middle-center',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: 'OK',
    cancelButtonText: 'キャンセル',
    allowOutsideClick: false,
    allowEscapeKey: false,
    focusCancel: focusCancel,
    reverseButtons: reverseButtons,
    width: '30%',
    grow: 'row',
    ...props,
    // background: 'rgb(245, 225, 225)',
    // timer: 5000,
    // timerProgressBar: true,
    // didOpen: (toast) => {
    //   toast.addEventListener('mouseenter', Swal.stopTimer)
    //   toast.addEventListener('mouseleave', Swal.resumeTimer)
    // }
  })
}