import { handleActions, createAction } from 'redux-actions'
import { onChangeInput, initState } from 'lib'
import _ from 'lodash';

// const
export const TKT_IMPORT = {
  CHANGE_INPUT_TKT_IMPORT : 'CHANGE_INPUT_TKT_IMPORT',
  SET_INPUT_TKT_IMPORT : 'SET_INPUT_TKT_IMPORT',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
};

// action
export const TktImportAction = {
  changeInputTktImport: createAction(TKT_IMPORT.CHANGE_INPUT_TKT_IMPORT, (args) => (args)),
  setInputTktImport: createAction(TKT_IMPORT.SET_INPUT_TKT_IMPORT, (args) => (args)),
  initSession: createAction(TKT_IMPORT.INIT_SESSION),
  clearSession: createAction(TKT_IMPORT.CLEAR_SESSION),
}

// initialState
const initialState = {
  label: [
    'ファイル読込元',
  ],
  tktInInputType: {
    id: 'tktInInputType',
    label: '',
    required: false,
    disabled: false,
    original: '0',
    value: '0',
    text: '0',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInAmsFlg: {
    id: 'tktInAmsFlg',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInAmsPath: {
    id: 'tktInAmsPath',
    label: '読込ファイル指定',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInAmsS3Path: {
    id: 'tktInAmsS3Path',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInIfnFlg: {
    id: 'tktInIfnFlg',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInIfnPath: {
    id: 'tktInIfnPath',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInIfnS3Path: {
    id: 'tktInIfnS3Path',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInTrpFlg: {
    id: 'tktInTrpFlg',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInTrpPath: {
    id: 'tktInTrpPath',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInTrpS3Path: {
    id: 'tktInTrpS3Path',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInSbrFlg: {
    id: 'tktInSbrFlg',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInSbrPath: {
    id: 'tktInSbrPath',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInSbrS3Path: {
    id: 'tktInSbrS3Path',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInOthFlg: {
    id: 'tktInOthFlg',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInOthPath: {
    id: 'tktInOthPath',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktInOthS3Path: {
    id: 'tktInOthS3Path',
    label: '',
    required: false,
    disabled: false,
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  cancel: {
    id: 'cancel',
    label: <span><span className='material-icons-outlined md'>cancel</span>キャンセル</span>,
    disabled: false,
  },
  setting: {
    id: 'setting',
    label: <span><span className='material-icons-outlined md'>settings</span>設定</span>,
    disabled: false,
  },
  upload: {
    id: 'upload',
    label: <span><span className='material-icons-outlined md'>file_upload</span>発券データ入力実行</span>,
    disabled: false,
  },
  before: {
    id: 'before',
    label: <span><span className='material-icons-outlined md'>history</span>前回エラーデータ</span>,
    disabled: false,
  },
  csv: {
    id: 'csv',
    label: <span><span className='material-icons-outlined md'>file_download</span>CSV出力</span>,
    disabled: false,
  },
}
// reducer
export const TktImportReducer = handleActions({
  [TktImportAction.changeInputTktImport]: (state, action) => (onChangeInput(state, action.payload)),
  [TktImportAction.setInputTktImport]: (state, action) => ({...state, ...action.payload,}),
  [TktImportAction.initSession]: (state, action) => (initState(initialState)),
  [TktImportAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)
