import _ from 'lodash';

export const HEADER = [
  {header: 'No.'},
  {header: '処理名'},
  {header: '稼働状態'},
  {
    header: '実行スケジュール',
    sub: []
  },
  {
    header: '有効期間',
    sub: [
      {header: '開始日'},
      {header: '終了日'}
    ]
  },
  {header: '後処理'},
  {
    header: '実行間隔',
    sub: []
  },
]

export const TMR_INTERVAL =[
  {name: '即時/指定', value: 'S'},
  {name: '毎日', value: 'D'},
  {name: '毎週', value: 'W'},
  {name: '毎月', value: 'M'},
  {name: '時間間隔', value: 'I'},
]

export const TMR_SCHEDULE =[
  {name: '即時/日時指定', value: 'S'},
  {name: '毎日', value: 'D'},
  {name: '時間間隔', value: 'I'},
]

export const TMR_WEEKLY = [
  {name: '月曜', value: '1'},
  {name: '火曜', value: '2'},
  {name: '水曜', value: '3'},
  {name: '木曜', value: '4'},
  {name: '金曜', value: '5'},
  {name: '土曜', value: '6'},
  {name: '日曜', value: '7'},
]

export const TMR_MONTHLY = [
  {name: '1日', value: '1'},
  {name: '2日', value: '2'},
  {name: '3日', value: '3'},
  {name: '4日', value: '4'},
  {name: '5日', value: '5'},
  {name: '6日', value: '6'},
  {name: '7日', value: '7'},
  {name: '8日', value: '8'},
  {name: '9日', value: '9'},
  {name: '10日', value: '10'},
  {name: '11日', value: '11'},
  {name: '12日', value: '12'},
  {name: '13日', value: '13'},
  {name: '14日', value: '14'},
  {name: '15日', value: '15'},
  {name: '16日', value: '16'},
  {name: '17日', value: '17'},
  {name: '18日', value: '18'},
  {name: '19日', value: '19'},
  {name: '20日', value: '20'},
  {name: '21日', value: '21'},
  {name: '22日', value: '22'},
  {name: '23日', value: '23'},
  {name: '24日', value: '24'},
  {name: '25日', value: '25'},
  {name: '26日', value: '26'},
  {name: '27日', value: '27'},
  {name: '28日', value: '28'},
  {name: '29日', value: '29'},
  {name: '30日', value: '30'},
  {name: '最終日', value: '31'},
]

export const PROCESS = [
  
]

