import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { 
  Navbar,
  Nav,
  NavDropdown,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Breadcrumb,
} from 'react-bootstrap';

import _ from 'lodash';
import { EXTERNAL_URL } from 'constants';
// import './styles/contents.scss'

export const TitleComponent = (props) => {

  const app = useSelector(state => state.App);
  const history = useHistory();
  const location = useLocation();

  return (
    <React.Fragment>
      <Row className='contentTitle'>
        <Col className='title-column' sm={4}>
          <span className={`material-icons${props.icon.style} title-icon`}>{props.icon.name}</span>
          <span className='title-text'>{props.title}</span>
        </Col>
        <Col className='title-column' sm={8}>
          {/* <span className='message'>{app.message}</span> */}
          {/* <Breadcrumb className='title-breadcrumb'>
            <Breadcrumb.Item
              target='/afd'
              onClick={(e)=>{console.log(e.target)}}
            >
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              active
            >
              Data
            </Breadcrumb.Item>
          </Breadcrumb> */}
          {props.button}
        </Col>
      </Row>
    </React.Fragment>
  )
}

TitleComponent.defaultProps = {};
