import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE, LIST_ITEMS, TKT_KB_FLAG_MAP } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';

export const AddPane = (props) => {

  const app = useSelector(state => state.App);
  const tktEdit = useSelector(state => state.TktEdit);
  
  const dispatch = useDispatch();
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  const [grid, setGrid] = React.useState(null)

  const gridFormatItem = (g, e) => {
    const item = e.getRow().dataItem
    const bind = e.getColumn().binding
    _.forEach(g.rowHeaders.rows, (r, i) => {
      // g.rowHeaders.setCellData(i, 0, i + 1)
      toggleClass(e.cell, 'wj-wrap', g.columnHeaders === e.panel);
    })
  }

  const itemFormatter = (panel, r, c, cell) => {
    // 列ヘッダは中央
    if (panel.cellType == 2) {
      cell.style.textAlign = 'center';
    }
  }

  return (
    <React.Fragment>
      <div className='add-list-body' >
        <Common.Grid
          thisGrid={grid}
          setGrid={setGrid}
          colDef={LIST_ITEMS.add}
          // masterType={master.type}
          dataList={tktEdit.bstTktAmtItemListDto}
          // setRowHeaderWidth={setRowHeaderWidth}
          // setColWidth={setColWidth}
          noSelector={true}
          grid={{
            isReadOnly: app.tktEditFormMode==='3',
            allowAddNew: false,
            // frozenColumns: 1,
            imeEnabled: false,
            itemFormatter: (panel, r, c, cell) => itemFormatter(panel, r, c, cell)
          }}
          setInput={tktEditAction.setInputTktEdit}
        />
      </div>
    </React.Fragment>
  )
}