import _ from 'lodash';
import { SEARCH } from 'constants/search';
import { convert } from "lib";

export const getPatternList = (patternList) => {
  let list = []
  _.forEach(patternList, (v, k) => {
    if (v.tktSrchptName !== '最近使った検索条件') {
      if (v.tktSrchptName === '前回検索条件') {
        list.push({
          ...v,
          header: `<div class='pdiv'><span key=${v.tktSrchptId} name=${v.tktSrchptName}>${v.tktSrchptName}</span></div>`,
        })
      } else {
        list.push({
          ...v,
          header: `<div class='pdiv'><span key=${v.tktSrchptId} name=${v.tktSrchptName}>${v.tktSrchptName}</span><button id=${v.tktSrchptId} class='pbutton'>edit</button></div>`,
        })
      }
    }
  })
  return list
}