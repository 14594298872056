import React from 'react';
import { Redirect } from 'react-router-dom';
import { MainComponent } from 'components/MainComponent';
import { MasterComponent } from 'components/Master/MasterComponent';
import { SystemComponent } from 'components/System/SystemComponent';
import { TicketSearchComponent } from 'components/Ticket/TicketSearchComponent';
import { TicketEditComponent } from 'components/Ticket/TicketEditComponent';
import { TicketImportComponent } from 'components/Ticket/TicketImportComponent';
import { TicketExportComponent } from 'components/Ticket/TicketExportComponent';
import { TicketExportSearchComponent } from 'components/Ticket/TicketExportSearchComponent';
import { TicketExcelComponent } from 'components/Ticket/TicketExcelComponent';
import { TicketExcelSearchComponent } from 'components/Ticket/TicketExcelSearchComponent';
import { PivotComponent } from 'components/Pivot/PivotComponent';
import { PivotSearchComponent } from 'components/Pivot/PivotSearchComponent';
import { ReportComponent } from 'components/Report/ReportComponent';
import { ReportSearchComponent } from 'components/Report/ReportSearchComponent';
import { PrismComponent } from 'components/Prism/PrismComponent';
import { TimerComponent } from 'components/Timer/TimerComponent';
import { InfoComponent } from 'components/Info/InfoComponent';
import { ProcessComponent } from 'components/Process/ProcessComponent';
import { TITLE } from 'constants/title';

export const routes = [
  {
    path: TITLE.main.link,
    exact: true,
    component: MainComponent,
  },
  // URI-Pro BI検索
  {
    path: TITLE.pivotSearch.link,
    exact: true,
    component: PivotSearchComponent,
  },
  // URI-Pro BI
  {
    path: TITLE.pivot.link,
    exact: true,
    component: PivotComponent,
  },
  // 固定帳票 検索
  {
    path: TITLE.reportSearch.link,
    exact: true,
    component: ReportSearchComponent,
  },
  // 固定帳票
  {
    path: TITLE.report.link,
    exact: true,
    component: ReportComponent,
  },
  // PRISM
  {
    path: TITLE.prism.link,
    exact: true,
    component: PrismComponent,
  },
  // PRISM
  {
    path: TITLE.timer.link,
    exact: true,
    component: TimerComponent,
  },
  // 発券データ検索
  {
    path: TITLE.ticketSearch.link,
    exact: true,
    component: TicketSearchComponent,
  },
  // 発券データ詳細
  {
    path: TITLE.ticketEdit.link,
    exact: true,
    component: TicketEditComponent,
  },
  // 発券データ入力
  {
    path: TITLE.ticketImport.link,
    exact: true,
    component: TicketImportComponent,
  },
  // 発券データ出力
  {
    path: TITLE.ticketExport.link,
    exact: true,
    component: TicketExportComponent,
  },
  // 発券データ出力 検索
  {
    path: TITLE.ticketExportSearch.link,
    exact: true,
    component: TicketExportSearchComponent,
  },
  // 発券データEXCEL出力
  {
    path: TITLE.ticketExcel.link,
    exact: true,
    component: TicketExcelComponent,
  },
  // 発券データEXCEL出力 検索
  {
    path: TITLE.ticketExcelSearch.link,
    exact: true,
    component: TicketExcelSearchComponent,
  },
  // テーブルメンテナンス
  // { path: TITLE.master.mainLink,
  //   exact: true,
  //   component: MasterComponent,
  // },
  { path: TITLE.master.link,
    exact: true,
    component: MasterComponent,
  },
  { path: TITLE.master.mainLink,
    component: () => <Redirect to={`${TITLE.master.mainLink}/main`} />
  },
  { path: TITLE.system.link ,
    exact: true,
    component: SystemComponent,
  },
  { path: TITLE.system.mainLink,
    component: () => <Redirect to={`${TITLE.system.mainLink}/travel`} />
  },
  // { path: TITLE.error.link,
  //   component: ErrorComponent,
  //   // component: TicketExcelSearchComponent,
  // },
  
  { path: TITLE.process.link,
    exact: true,
    component: ProcessComponent,
  },
  { path: TITLE.info.link,
    exact: true,
    component: InfoComponent,
  },
  {
    path: '*',
    component: () => <Redirect to={TITLE.main.link} />
  }
  // { path: TITLE.userEdit.link,
  //   exact: true,
  //   component: Containers.UserEdit,
  // },
  // { path: TITLE.userSearch.link,
  //   component: () => <Redirect to={TITLE.userSearch.link} />
  // },
  // { path: TITLE.companySearch.link,
  //   exact: true,
  //   component: Containers.CompanySearch,
  // },
  // { path: TITLE.companyEdit.link,
  //   exact: true,
  //   component: Containers.CompanyEdit,
  // },
  // { path: TITLE.companySearch.link,
  //   component: () => <Redirect to={TITLE.companySearch.link} />
  // },
  // { path: TITLE.agencyEdit.link,
  //   exact: true,
  //   component: Containers.AgencyEdit,
  // },
  // { path: TITLE.agencySearch.link,
  //   component: () => <Redirect to={TITLE.companySearch.link} />
  // },
  // { path: TITLE.terminalSearch.link,
  //   exact: true,
  //   component: Containers.TerminalSearch,
  // },
  // { path: TITLE.terminalEdit.link,
  //   exact: true,
  //   component: Containers.TerminalEdit,
  // },
  // { path: TITLE.terminalSearch.link,
  //   component: () => <Redirect to={TITLE.terminalSearch.link} />
  // },
  // { path: TITLE.requestPasswordSearch.link,
  //   exact: true,
  //   component: Containers.RequestPasswordSearch,
  // },
  // { path: TITLE.requestPasswordSearch.link,
  //   component: () => <Redirect to={TITLE.requestPasswordSearch.link} />
  // },
  // { path: TITLE.fileImport.link,
  //   exact: true,
  //   component: Containers.FileImport,
  // },
  // { path: TITLE.fileImport.link,
  //   component: () => <Redirect to={TITLE.fileImport.link} />
  // },
  // { path: TITLE.logout.link,
  //   component: Containers.Logout,
  // },
  // { path: '*',
  //   component: () => <Redirect to={TITLE.login.link} />
  // }
]
