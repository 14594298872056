import React from 'react';
import { Calendar } from '@grapecity/wijmo.react.input';
import reactDom from 'react-dom';
import { 
  Form,
} from 'react-bootstrap';
import _ from 'lodash';

export const Radio = React.forwardRef((props, ref) => {

  const {attrs} = props;

  const genRender = () => {
    const render = [];
    _.forEach(props.radios, (radio, idx) => {
      render.push(
        <Form.Check
          inline={attrs.block?false:true}
          key={idx}
          label={radio.name}
          type='radio'
          id={`${attrs.id}_${idx}`}
          name={attrs.id}
          checked={attrs.text === radio.value}
          disabled={attrs.disabled}
          onChange={(e) => {
            const target = {id: attrs.id}
            target[attrs.id] = {
              ...attrs,
              text: radio.value,
            }
            props.onChange(target)
          }}
          className={props.className}
        />
      )
    })
    return render;
  }

  return (
    <React.Fragment>
      <div
        className={`d-inline ${props.className} ${attrs.visible===false?'d-none':''}`}
      >
        <label
          sm="3"
          className={
            `${attrs.required && 'label-required'} ${attrs.label==='_none'?'d-none':'label-color mb-0 mr-3'} ${props.labelClass}`
          }
        >
          {attrs.label}
        </label>
        {genRender()}
      </div>
    </React.Fragment>
  )
})
