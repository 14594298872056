import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { AppReducer } from './App';
import { LoginReducer } from './Login';
import { LogoutReducer } from './Logout';
import { TktSearchReducer } from './TktSearch';
import { TktEditReducer } from './TktEdit';
import { TktImportReducer } from './TktImport';
import { TktExportReducer } from './TktExport';
import { TktExcelReducer } from './TktExcel';
import { PivotReducer } from './Pivot';
import { ReportReducer } from './Report';
import { ProcessReducer } from './Process';
import { PrismReducer } from './Prism';
import { TimerReducer } from './Timer';
import { MasterReducer } from './Master';
import { SystemReducer } from './System';

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'

const authPersistConfig = {
  key: 'auth',
  storage: storageSession,
  whitelist: ['loginCd', 'mbrCd', 'office', 'mntOffice', 'authorization', 'authCode']
  // whitelist: ['access_token', 'id_token', 'token_type', 'expires_in']
}
const appPersistConfig = {
  key: 'app',
  storage: storageSession,
  whitelist: ['gatewayBaseUri', 'ticketLimit', 'mntToolUri', 'reloadTime', 'limitMonth']
  // whitelist: ['access_token', 'id_token', 'token_type', 'expires_in']
}

export default (history) => combineReducers({
  App: persistReducer(appPersistConfig, AppReducer),
  Login: persistReducer(authPersistConfig, LoginReducer),
  // Login: LoginReducer,
  Logout: LogoutReducer,
  Master: MasterReducer,
  System: SystemReducer,
  TktSearch: TktSearchReducer,
  TktEdit: TktEditReducer,
  TktImport: TktImportReducer,
  TktExport: TktExportReducer,
  TktExcel: TktExcelReducer,
  Pivot: PivotReducer,
  Report: ReportReducer,
  Process: ProcessReducer,
  Prism: PrismReducer,
  Timer: TimerReducer,
  // TktSearchItem: TktSearchItemReducer,
  // PasswordChange: PasswordChangeReducer,
  // UserSearch: UserSearchReducer,
  // UserEdit: UserEditReducer,
  // CompanySearch: CompanySearchReducer,
  // CompanyEdit: CompanyEditReducer,
  // AgencyEdit: AgencyEditReducer,
  // TerminalSearch: TerminalSearchReducer,
  // TerminalEdit: TerminalEditReducer,
  // RequestPasswordSearch: RequestPasswordSearchReducer,
  // FileImport: FileImportReducer,
  // Account: persistReducer(authPersistConfig, AccountReducer),
  // Password: PasswordReducer,
  // User: UserReducer,
  // Company: CompanyReducer,
  // Agency: AgencyReducer,
  // Terminal: TerminalReducer,
  // RequestPassword: RequestPasswordReducer,
  // File: FileReducer,
  router: connectRouter(history),
});