import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, } from 'react-bootstrap';
import * as wijmo from '@grapecity/wijmo';
import { Card } from 'components';
import * as Common from 'components/common';
import { convert } from 'lib'

import _ from 'lodash';
import { RADIOS, CHECKS } from 'constants/search';
import { setDate } from 'date-fns';
// import '../styles/conditionCard.scss'

export const CardDateComponent = (props) => {

  // const [show, setShow] = React.useState(false)
  // const [dateRadio, setDateRadio] = React.useState('0')
  const [dateChange, setDateChange] = React.useState(null)
  const [fromToCheck, setFromToCheck] = React.useState(false)
  // const [period, setPeriod] = React.useState({
  //   1: {text: '0', disabled: true},
  //   2: {text: '0', disabled: true},
  //   3: {text: '0', disabled: true},
  //   4: {text: '0', disabled: true},
  // })
  // const [periodChange, setPeriodChange] = React.useState(false)
  // const [periodStart, setPeriodStart] = React.useState(-1)
  // const [periodEnd, setPeriodEnd] = React.useState(-1)

  // 初期化
  React.useEffect(() => {
    clear(true)
  }, [props.condition.key])

  // clear
  const clear = (isKeep) => {
    const obj = []
    _.forEach(props.items, (r, i) => {
      // chdate(-6)
      if (_.endsWith(r.id, '_chdate') && (!r.text || !isKeep)) {
        obj[r.id] = {
          ...r,
          original: '0',
          value: '0',
          text: '0',
          disabled: false,
        }
      }
      // period(-2~-5)
      if (_.includes(r.id, '_period') && (!r.text || !isKeep)) {
        obj[r.id] = {
          ...r,
          original: '0',
          value: '0',
          text: '0',
          disabled: true,
        }
      }
      // number(-1)
      if (_.endsWith(r.id, '_number') && (!r.text || !isKeep)) {
        obj[r.id] = {
          ...r,
          original: null,
          value: null,
          text: null,
          disabled: false,
        }
      }
      // 
      if (_.endsWith(r.id, 'from') && (!r.text || !isKeep)) {
        obj[r.id] = {
          ...r,
          original: null,
          value: null,
          text: null,
          disabled: false,
        }
      }
      // 
      if (_.endsWith(r.id, 'to') && (!r.text || !isKeep)) {
        obj[r.id] = {
          ...r,
          original: null,
          value: null,
          text: null,
          disabled: false,
        }
      }
    })
    Object.keys(obj).length && props.setInput({...obj})
  }

  // xx日前を変更
  React.useEffect(() => {
    if (_.isNull(dateChange)) {
      // console.log(_.filter(props.items, (r) => _.endsWith(r.id, 'number'))[0].text)
      setDateChange(_.isNull(_.filter(props.items, (r) => _.endsWith(r.id, 'number'))[0].text)?null:
        _.filter(props.items, (r) => _.endsWith(r.id, 'number'))[0].text * -1)
    }
  }, [_.filter(props.items, (r) => _.endsWith(r.id, 'number'))[0].text])
  // ラジオボタンを変更
  React.useEffect(() => {
    if (_.isNull(dateChange)) {
      // console.log(_.filter(props.items, (r) => _.endsWith(r.id, '_chdate'))[0].text)
      setDateChange(_.filter(props.items, (r) => _.endsWith(r.id, '_chdate'))[0].text)
    }
  }, [_.filter(props.items, (r) => _.endsWith(r.id, '_chdate'))[0].text])
  // ピリオド変更
  React.useEffect(() => {
    if (_.isNull(dateChange)) {
      // console.log(_.filter(props.items, (r) => _.includes(r.id, '_period')))
      setDateChange('period')
    }
  }, [_.filter(props.items, (r) => _.endsWith(r.id, '_period1'))[0].text,
      _.filter(props.items, (r) => _.endsWith(r.id, '_period2'))[0].text,
      _.filter(props.items, (r) => _.endsWith(r.id, '_period3'))[0].text,
      _.filter(props.items, (r) => _.endsWith(r.id, '_period4'))[0].text])
  // 日付設定
  React.useEffect(() => {
    // console.log(dateChange)
    if (!_.isNull(dateChange)) {
      const obj ={}
      const fromDate = _.filter(props.items, (r) => _.endsWith(r.id, 'from'))[0]
      const toDate = _.filter(props.items, (r) => _.endsWith(r.id, 'to'))[0]
      const number = _.filter(props.items, (r) => _.endsWith(r.id, 'number'))[0]
      const chdate = _.filter(props.items, (r) => _.endsWith(r.id, '_chdate'))[0]
      const period = _.filter(props.items, (r) => _.includes(r.id, '_period'))

      obj[chdate.id] = {...chdate}
      obj[number.id] = {...number}

      let dt = wijmo.DateTime
      let today = dt.newDate();
      let startDate = dt.newDate();
      let endDate = dt.newDate();
      let startPeriod = -1
      let endPeriod = -1

      if (dateChange === 'period') {
        // periodを変更
        // periodの自動変更を行い、From-Toを設定させる
        // console.log(chdate.text)
        if (chdate.text === '1' || chdate.text === '2') {
          startPeriod = getPeriodRange(period).start
          endPeriod = getPeriodRange(period).end
          startDate = onPeriodChange(startPeriod, endPeriod).start
          endDate = onPeriodChange(startPeriod, endPeriod).end
          obj[chdate.id] = {...chdate}
          obj[number.id] = {...number, text: null}
        } else {
          switch (chdate.text) {
            case '0':
              startDate = fromDate.text
              endDate = toDate.text
              break;
            case '3':
              startDate = dt.addDays(today, -1)
              startDate.setHours(0,0,0,0)
              endDate = dt.addDays(today, -1)
              endDate.setHours(23,59,59,999)
              break;
            case '4':
              startDate = dt.addDays(today, 0)
              startDate.setHours(0,0,0,0)
              endDate = dt.addDays(today, 0)
              endDate.setHours(23,59,59,999)
              break;
          }
        }
      } else if (_.isNumber(dateChange)) {
        // xx日前を変更
        // 任意に戻し、From-Toを設定
        if (!number.disabled) {
          startDate = dt.addDays(today, -number.text)
          startDate.setHours(0,0,0,0)
          endDate = dt.newDate();
          endDate.setHours(23,59,59,999)
          obj[chdate.id] = {...chdate, text: '0'}
          obj[number.id] = {...number}
        }
      } else if (!_.isObject(dateChange)) {
        // ラジオボタンを変更
        // ラジオボタンとピリオドの状態から、From-Toを設定させる
        obj[chdate.id] = {...chdate}
        obj[number.id] = {
          ...number,
          text: chdate.text==='0'?number.text:null,
          disabled: chdate.text!=='0',
        }
        switch (chdate.text) {
          case '0':
            startDate = fromDate.text
            endDate = toDate.text
            break;
          case '1':
            startPeriod = getPeriodRange(period).start
            endPeriod = getPeriodRange(period).end
            startDate = onPeriodChange(startPeriod, endPeriod).start
            endDate = onPeriodChange(startPeriod, endPeriod).end
            break;
          case '2':
            startPeriod = getPeriodRange(period).start
            endPeriod = getPeriodRange(period).end
            startDate = onPeriodChange(startPeriod, endPeriod).start
            endDate = onPeriodChange(startPeriod, endPeriod).end
            break;
          case '3':
            startDate = dt.addDays(today, -1)
            startDate.setHours(0,0,0,0)
            endDate = dt.addDays(today, -1)
            endDate.setHours(23,59,59,999)
            break;
          case '4':
            startDate = dt.addDays(today, 0)
            startDate.setHours(0,0,0,0)
            endDate = dt.addDays(today, 0)
            endDate.setHours(23,59,59,999)
            break;
        }
      } else {
        // console.log(dateChange)
      }

      obj[fromDate.id] = {
        ...fromDate,
        text: startDate,
        disabled: (obj[chdate.id].text !== '0')
      }
      
      _.forEach(period, (r, i) => {
        obj[r.id] = {
          ...r,
          text: ((i + 1) >= startPeriod && (i + 1) <= endPeriod)?'1':'0',
          disabled: (obj[chdate.id].text !== '1' && obj[chdate.id].text !== '2')
        }
        if (obj[chdate.id].text !== '1' && obj[chdate.id].text !== '2') {
          obj[r.id].text = '0'
        }
      })

      // console.log(obj)
      // console.log(startDate)
      // console.log(endDate)
      props.setInput({...obj})
      setTimeout(() => {
        let objToDate = {}
        objToDate[toDate.id] = {
          ...toDate,
          text: endDate,
          disabled: (chdate.text !== '0')
        }
        props.setInput(objToDate)
        setTimeout(() => {
          setDateChange(null)
        })
      })
    }
  }, [dateChange])

  const getPeriodRange = (period) => {
    let start = -1
    let end = -1
    _.forEach(period, (r, i) => {
      if (r.text === '1') {
        if (start < 0) {
          start = i + 1
          end = i + 1
        }
        else end = i + 1
      }
    })
    return {start: '' + start, end: '' + end}
  }

  const onPeriodChange = (startPeriod, endPeriod) => {
    const chdate = _.filter(props.items, (r) => _.endsWith(r.id, '_chdate'))[0].text
    // console.log(chdate)
    // console.log(startPeriod)
    // console.log(endPeriod)
    let dt = wijmo.DateTime, now = new Date();
    // 1P:1-7, 2P:8-15, 3P:16-23, 4P:24-99
    let p1s = dt.monthFirst(dt.addMonths(now, chdate==='1'?-1:0))
    let p1e = dt.addDays(p1s, 6)
    let p2s = dt.addDays(p1e, 1)
    let p2e = dt.addDays(p2s, 7)
    let p3s = dt.addDays(p2e, 1)
    let p3e = dt.addDays(p3s, 7)
    let p4s = dt.addDays(p3e, 1)
    let p4e = dt.monthLast(dt.addMonths(now, chdate==='1'?-1:0))
    p1s.setHours(0,0,0,0)
    p2s.setHours(0,0,0,0)
    p3s.setHours(0,0,0,0)
    p4s.setHours(0,0,0,0)
    p1e.setHours(23,59,59,999)
    p2e.setHours(23,59,59,999)
    p3e.setHours(23,59,59,999)
    p4e.setHours(23,59,59,999)
    let start = (chdate==='1' || chdate==='2')?p1s:null
    let end = (chdate==='1' || chdate==='2')?p4e:null
    switch (startPeriod) {
      case '1':
        start = p1s
        break;
      case '2':
        start = p2s
        break;
      case '3':
        start = p3s
        break;
      case '4':
        start = p4s
        break;
    }
    switch (endPeriod) {
      case '1':
        end = p1e
        break;
      case '2':
        end = p2e
        break;
      case '3':
        end = p3e
        break;
      case '4':
        end = p4e
        break;
    }
    return {start: start, end: end}
  }

  
  React.useEffect(() => {
    if (fromToCheck) {
      const fromAry = _.filter(props.items, (r, i) => _.endsWith(r.id, 'from'))
      const toAry = _.filter(props.items, (r, i) => _.endsWith(r.id, 'to'))
      const from = (fromAry && fromAry.length > 0)? fromAry[0]:{}
      const to = (toAry && toAry.length > 0)? toAry[0]:{}
      let isOk = true
      // console.log(from)
      // console.log(to)
      if (!_.isNil(from.text) && from.text !== null && (_.isNil(to.text) || to.text === null)) {
        let dt = wijmo.DateTime
        let copyText = new Date(from.text)
        copyText.setHours(23,59,59,999)
        props.onBlur(convert.getTarget(to, 'text', copyText))
      }
      if (from.text && to.text) {
        if (from.text.getTime() > to.text.getTime()) {
          isOk = false
        }
      }
      let obj = {}
      if (!isOk) {
        obj[to.id] = {
          ...to,
          value: null,
          text: null,
        }
      }
      props.setInput(obj)
      setFromToCheck(false)
    }
  }, [fromToCheck])

  const getBody = () => {
    const getItem = _.map(props.items, (r, i) => {
      if (_.endsWith(r.id, 'from')) {
        return (
          <React.Fragment key={i}>
            <Common.DateTime
              attrs={r}
              // range={_.filter(props.items, (r) => _.endsWith(r.id, 'to'))[0]}
              onBlur={(e) => {
                // console.log(e)
                // setDateChange(e)
                props.onBlur(e)
                setTimeout(() => {
                  setFromToCheck(true)
                })
              }}
              group={{
                ...props.group,
                className: 'width-max-content mr-2 mb-1',
              }}
            />
            <div className='text-center mt-2 mr-2'>～</div>
          </React.Fragment>
        )
      } else if (_.endsWith(r.id, 'to')) {
        return (
          <React.Fragment key={i}>
            <Common.DateTime
              attrs={{...r}}
              onBlur={(e) => {
                if (_.isDate(e[r.id].text)) {
                  if (_.isDate(r.text)) {
                    if (r.text.getFullYear() === e[r.id].text.getFullYear()
                      && r.text.getMonth() === e[r.id].text.getMonth()
                      && r.text.getDate() === e[r.id].text.getDate()) {
                      props.onBlur(e)
                    } else {
                      if (r.text.getHours() === e[r.id].text.getHours()
                        && r.text.getMinutes() === e[r.id].text.getMinutes()) {
                        // 日付のみ変更された
                        e[r.id].text.setHours(23,59,59,999)
                        props.onBlur(e)
                      } else {
                        // 日付と時間が変更された
                        props.onBlur(e)
                      }
                    }
                  } else {
                    // nullから設定された
                    e[r.id].text.setHours(23,59,59,999)
                    props.onBlur(e)
                  }
                } else {
                  props.onBlur(e)
                }
                setTimeout(() => {
                  e[r.id].text && setFromToCheck(true)
                })
              }}
              group={{
                ...props.group,
                className: 'width-max-content mr-2 mb-1',
              }}
            />
            <div/>
          </React.Fragment>
        )
      } else if (_.endsWith(r.id, 'number')) {
        return (
          <React.Fragment key={i}>
            <Common.Number
              attrs={r}
              valueChanged={(e) => {props.onBlur(e)}}
              group={{
                ...props.group,
                className: 'width-16 ml-4 mr-0 mb-1',
                textwidth: 'width-10'
              }}
            />
            {/* <Button
              variant='outline-secondary'
              id='range-icon'
              className='date-range-icon'
              size='sm'
              // disabled={r.disabled}
              onClick={(e) => setShow(!show)}
            >
              <span className="material-icons-outlined lg">event_available</span>
            </Button> */}
          </React.Fragment>
        )
      } else return
    })

    return (
      <React.Fragment>
        <div className='card-condition-item mb-2'>
          <Common.Radio
            btnSize='at'
            attrs={{
              ..._.filter(props.items, (r) => _.endsWith(r.id, '_chdate'))[0]
            }}
            radios={RADIOS.choiceDate}
            // className='min-width-33 width-33 mr-3 p-0'
            onChange={(e) => {props.onBlur(e)}}
          />
        </div>
        <div className='card-condition-item'>
          {getItem}
          <div className='d-flex flex-column my-0'>
            ピリオド
            <div className='flex-row'>
            {_.map(_.filter(props.items, (r) => _.includes(r.id, '_period')), (r, i) => {
              return (
                <Common.Check
                  key={i}
                  attrs={r}
                  className='min-width-5 width-5 m-0 p-0'
                  onChange={(e) => props.onBlur(e)}
                />
              )
            })}
            </div>
          </div>
        </div>
        {/* <div>
          <Common.DateRange
            attrs={{
              show: show,
              disabled: _.filter(props.items, (r) => _.endsWith(r.id, 'number'))[0].disabled,
            }}
            dates={{
              fromAttr: _.filter(props.items, (r) => _.endsWith(r.id, 'from'))[0],
              toAttr: _.filter(props.items, (r) => _.endsWith(r.id, 'to'))[0],
              numberAttr: _.filter(props.items, (r) => _.endsWith(r.id, 'number'))[0],
              period1Attr: _.filter(props.items, (r) => _.endsWith(r.id, 'period1'))[0],
              period2Attr: _.filter(props.items, (r) => _.endsWith(r.id, 'period2'))[0],
              period3Attr: _.filter(props.items, (r) => _.endsWith(r.id, 'period3'))[0],
              period4Attr: _.filter(props.items, (r) => _.endsWith(r.id, 'period4'))[0],
              condAttr: _.filter(props.items, (r) => _.endsWith(r.id, 'choiceDate'))[0],
            }}
            onValueChanged={(e) => props.onBlur(e)}
            setInput={(e) => props.setInput(e)}
          />
        </div> */}
        <div className='d-inline-block mb-1'>
          <Button
            id='clear'
            className='button-gray small width-max-content'
            onClick={(e) => clear()}
          >
            クリア
          </Button>
        </div>
      </React.Fragment>
    )
  }
  
  return (
    <React.Fragment>
      <Card
        size={props.cardSize}
        header={props.condition.header}
        subheader={props.condition.subheader}
        body={getBody()}
        condition={props.condition}
        onClose={props.onClose}
      />
    </React.Fragment>
  )
}

CardDateComponent.defaultProps = {};
