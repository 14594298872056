import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';

import * as Common from 'components/common';
import { Card } from 'components';
import {
  OverlayTrigger,
  Tooltip,
  Col,
  Row,
  Container,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import { MultiRow } from '@grapecity/wijmo.react.grid.multirow';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { Selector } from "@grapecity/wijmo.grid.selector";
import { CollectionView, setSelectionRange } from "@grapecity/wijmo";
import * as wijmo from '@grapecity/wijmo';
import { CellMaker } from "@grapecity/wijmo.grid.cellmaker";
import { CellRange, DataMap } from "@grapecity/wijmo.grid";
import { InputDate, ComboBox, AutoComplete, MultiAutoComplete, InputColor, InputNumber } from '@grapecity/wijmo.input';
import { convert, entryCheck, alert, validation } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { LIST_ITEMS, DECODE_MAP } from 'constants/master';
import { MasterApi } from 'webapi'
import { MemberAuthPopup } from 'components/Master/modal/MemberAuthPopup';

export const MemberSearchComponent = (props) => {
  
  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  const [itemChanged, setItemChanged] = React.useState(false)
  const [grid, setGrid] = React.useState(null)
  const [selector, setSelector] = React.useState(null)
  const [selectedItems, setSelectedItems] = React.useState(null)
  const [action, setAction] = React.useState('')
  const [rowHeaderWidth, setRowHeaderWidth] = React.useState(0)
  const [colWidth, setColWidth] = React.useState([])
  const [filters, setFilters] = React.useState({})
  const [detail, setDetail] = React.useState(null)
  const [detailGrid, setDetailGrid] = React.useState(null)
  const [addRow, setAddRow] = React.useState(-1)
  const [popup, setPopup] = React.useState(null)
  const [popupData, setPopupData] = React.useState(null)

  const getData = (isCode, type) => {
    appAction.showProcessing({isProcessing: true})
    appAction.setMessage('')
    // フィルタの初期化
    const filter = {}
    _.forEach(LIST_ITEMS.member, (r, i) => filter[r.id] = '')
    setFilters(filter)

    MasterApi.search(isCode, type, {requestCodeName: type.toUpperCase(), search:{}})
    .then(res => {

      const obj = {}
      appAction.setMessage({message: res.payload.messageList})
      _.forEach(master, (v, k) => {
        if (k.endsWith('List')) obj[k] = []
      })

      if (res.payload.code === '000' || res.payload.code === '100') {
        obj.selDate = res.payload.selDate
        let dataList = [].concat(res.payload.mstMemberCdList)
        _.forEach(dataList,(r, i) => {
          r.mbrCdOrg = r.mbrCd
          _.forEach(_.filter(LIST_ITEMS.member, 'checkbox'), (record, index) => {
            r[record.id] = r[record.id]==='1'
          })
        })
        obj[`${type}List`] = dataList
      }
      masterAction.setInputMaster({...obj})
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  React.useEffect(() => {
    appAction.setMessage('')
    getData(master.isCode, master.type)
    if (grid) {
      grid.select(-1, -1)
      setTimeout(() => {
        (grid.rows.length > 0)?grid.select(0, 0):grid.select(-1, 0)
      })
    }
  }, [master.type])

  React.useEffect(() => {
    if (grid && grid.collectionView) grid.collectionView.filters.clear()
    _.forEach(filters, (v, k) => {
      grid.collectionView.filters.push((item) => {
        if (v) {
          if (!_.isDate(item[k]))
            return item[k] && item[k].toLowerCase().indexOf(v) > -1;
          else return convert.toDateString(item[k], 'yyyy/MM/dd').indexOf(v) > -1;
        } else return true
        // return v && !_.isDate(item[k])?item[k].toLowerCase().indexOf(v) > -1: true;
      });
    })
    _.forEach(grid && grid.rows, r => {
      if (r.dataItem) r.isSelected = (r.dataItem.isSelected===true)
    })
    masterAction.setInputMaster({listChange: true})
  }, [filters])

  const execDelete = (isCode, type) => {
    const selectList = []
    const isNewSelectList = []

    //新規行は強制的に選択状態
    _.forEach(grid.rows, (r, i) => {
      if (r.dataItem && r.dataItem.isAddingNew) {
        r.isSelected = true
      }
    })
    grid.refresh()

    _.forEach(master[`${type}List`].filter(r => r.isSelected), (r, i) =>{
      if (!r.isAddingNew) {
        selectList.push({
          ...r,
        })
      } else isNewSelectList.push({...r})
    })
    if (isNewSelectList.length < 1 && selectList.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return 
    }
    if (selectList.filter(r => r.mbrAuth !== '0').length > 0) {
      alert().fire({
        icon: MESSAGE.deleteAdmin().icon,
        title: MESSAGE.deleteAdmin().text,
        showCancelButton: false,
      })
      return
    }
    alert().fire({
      icon: MESSAGE.confirmDeleteList().icon,
      title: MESSAGE.confirmDeleteList(master[`${type}List`].filter(r => r.isSelected).length).text
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (selectList.length > 0) {
          const deleteList = []
          _.forEach(selectList, (r, i) => {
            deleteList.push({
              appCode: 'delete',
              mbrCd: r.mbrCdOrg,
              mbrCdOrg: r.mbrCdOrg,
            })
          })
          appAction.showProcessing({isProcessing: true})
          MasterApi.edit(isCode, type, {
            requestCodeName: type.toUpperCase(),
            isRequestSearch: true,
            search: {},
            requestList: deleteList,
            selDate: master.selDate,
          })
          .then((res) => {
            appAction.setMessage({message: res.payload.messageList})
            if (res.payload.code === '000') {
              const obj = {}
              obj.selDate = res.payload.selDate
              let dataList = [].concat(res.payload.mstMemberCdList)
              _.forEach(dataList,(r, i) => {
                r.mbrCdOrg = r.mbrCd
                _.forEach(_.filter(LIST_ITEMS.member, 'checkbox'), (record, index) => {
                  r[record.id] = r[record.id]==='1'
                })
              })
              obj[`${type}List`] = dataList
              masterAction.setInputMaster({...obj})
            }
            // フィルタの初期化
            const filter = {}
            _.forEach(LIST_ITEMS.member, (r, i) => filter[r.id] = '')
            setFilters(filter)
          })
          .catch(() =>{})
          .finally(() => {
            appAction.showProcessing({isProcessing: false})
          })
        } else {
          getData(master.isCode, master.type)
        }
      }
    });
  }
  
  const execSave = (isCode, type) => {
    const err = _.filter(master[`${master.type}List`], (r) => r.hasError)
    const editList = []
    _.forEach(master[`${master.type}List`], (r) => {
      if (r.isAddingNew) editList.push({...r, appCode: 'insert'})
      if (!r.isAddingNew && r.isEdit) editList.push({...r, appCode: 'update'})
    })
    _.forEach(editList,(r, i) => {
      _.forEach(_.filter(LIST_ITEMS.member, 'checkbox'), (record, index) => {
        r[record.id] = r[record.id]?'1':'0'
      })
    })

    if (err.length > 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.hasError().id,
        message: MESSAGE.hasError().text,
        type: MESSAGE.hasError().icon,
      }]})
      return
    }
    if (editList.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.noTarget().id,
        message: MESSAGE.noTarget().text,
        type: MESSAGE.noTarget().icon,
      }]})
      return
    }

    appAction.showProcessing({isProcessing: true})
    MasterApi.edit(isCode, type, {
      requestCodeName: type.toUpperCase(),
      isUpdateIgnore: true,
      isRequestSearch: true,
      search: {},
      requestList: editList,
      selDate: master.selDate,
    })
    .then((res) => {
      appAction.setMessage({message: res.payload.messageList})
      if (res.payload.code === '000') {
        const obj = {}
        obj.selDate = res.payload.selDate
        let dataList = [].concat(res.payload.mstMemberCdList)
        _.forEach(dataList,(r, i) => {
          r.mbrCdOrg = r.mbrCd
          _.forEach(_.filter(LIST_ITEMS.member, 'checkbox'), (record, index) => {
            r[record.id] = r[record.id]==='1'
          })
        })
        obj[`${type}List`] = dataList
        masterAction.setInputMaster({...obj})
      }
      // フィルタの初期化
      const filter = {}
      _.forEach(LIST_ITEMS.member, (r, i) => filter[r.id] = '')
      setFilters(filter)
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  const execCsv = (isCode, type) => {
    const csv = []
    _.forEach(master[`${type}List`].filter(r => r.isSelected), (r, i) =>{
      if (r.mbrAuth === '0') {
        csv.push({
          ...r,
        })
      }
    })
    const upd = _.filter(csv, (r, i) => r.isAddingNew || r.isEdit)
    if (csv.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return
    }
    if (upd.length > 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.editedSelection().id,
        message: MESSAGE.editedSelection().text,
        type: MESSAGE.editedSelection().icon,
      }]})
      return
    }
    appAction.showProcessing({isProcessing: true})
    MasterApi.download(isCode, type, {
      requestCodeName: type.toUpperCase(),
      downloadFileName: `${master.type}.csv`,
      requestList: csv,
    }).then((res) => {
      if (res.payload.messageList) {
        appAction.setMessage({message: res.payload.messageList,})
      } else {
        appAction.setMessage('')
        const blob = new Blob([String.fromCharCode(0xFEFF), res.payload], {type: 'text/csv'})
        if (window.navigator.msSaveOrOpenBlob) {
          // for IE,Edge
          window.navigator.msSaveOrOpenBlob(blob, `${master.type}.csv`);
        } else {
          // for chrome, firefox
          const url = URL.createObjectURL(blob);
          const linkEl = document.createElement('a');
          linkEl.href = url;
          linkEl.setAttribute('download', `${master.type}.csv`);
          document.body.appendChild(linkEl);
          linkEl.click();
          URL.revokeObjectURL(url);
          linkEl.parentNode.removeChild(linkEl);
        }
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  const execCopy = () =>{
    const targetList = _.filter(master[`${master.type}List`].filter(r => r.isSelected))
    let target = null
    if (targetList.length === 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return
    } else if (targetList.length === 1) {
      target = targetList[0]
    } else {
      appAction.setMessage({message: [{
        id: MESSAGE.targetMultiSelect().id,
        message: MESSAGE.targetMultiSelect().text,
        type: MESSAGE.targetMultiSelect().icon,
      }]})
      return
    }
    const newRow = {
      ...target,
      isAddingNew: true,
      mbrCd: '',
      mbrAuth: '0',
    }
    grid.collectionView.addNew(newRow)
    setTimeout(() => {
      _.forEach(grid.rows, (r, i) => {
        if (r.dataItem) {
          r.dataItem.isSelected = false
        }
      })
      grid.startEditing(false, null, 1)
    })
  }

  const sectionFormatItem = (c, e) => {
    let template='<table><tr>' +
    '<td class="width-5" title="コード">{cd}</td>' +
    '<td class="" title="名称">{namej}</td>' +
    '</tr></table>'
    e.item.innerHTML = wijmo.format(template, e.data);
  }

  const getColumn = () => {
    const column = []
    const sectionList = app.sectionList?app.sectionList:[]
    _.forEach(LIST_ITEMS.member, (r, i) => {
      let obj = {}
      obj.visible = (r.isHeader===true)
      obj.void = !(r.isHeader===true)
      switch (r.id) {
        case 'mbrSecCd1':
          obj.dataMap = new DataMap(sectionList, 'cd', 'cd')
          obj.editor = new AutoComplete(document.createElement('div'), {
            headerPath: 'cd',
            itemsSource: app.sectionList,
            isRequired: false,
            isEditable: true,
            minLength: 1,
            delay: 10,
            selectedValuePath: 'cd',
            displayMemberPath: 'listName',
            formatItem: (c, e) => sectionFormatItem(c, e),
          })
          break;
        case 'authButton':
          obj.cellTemplate = CellMaker.makeButton({
              text: r.header,
              click: (e, ctx) => {
                if (!ctx.item) return
                setPopupData(ctx)
                popup.show(true, (sender) => {
                  // if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
                  //   console.log(popupData)
                  //   console.log('ok')
                  // } else {
                  //   console.log('cancel')
                  // }
                })
              }
            })
      }
      column.push({...r, ...obj})
    })
    return column
  }

  const getDetailColumn = () => {
    const column = []
    _.forEach(LIST_ITEMS.member, (r, i) => {
      let obj = {}
      obj.visible = (r.isDetail===true)
      obj.void = !(r.isDetail===true)
      column.push({...r, ...obj})
    })
    return column
  }

  const gridBeginningEdit = (g, e) => {
    if (g.rows[e.row].dataItem.mbrAuth !== '0') e.cancel = true
  }

  const gridFormatItem = (g, e) => {
    const item = e.getRow().dataItem
    const bind = e.getColumn().binding
    if (e.getRow() && (!item || !item.mbrCd) && bind === 'authButton') {
      e.cell.firstChild.disabled = true
    }
  }

  React.useEffect(() => {
    if (detailGrid) {
      detailGrid.columnHeaders.rows.defaultSize = 50
    }
  }, [detailGrid])
  
  React.useEffect(() => {
    if (addRow > -1) {
      grid.collectionView.currentItem.mbrAuth = '0'
      grid.collectionView.currentItem.mbrLockFlg = '0'
      if (!grid.collectionView.currentItem.mbrSecCd2) grid.collectionView.currentItem.mbrSecCd2 = 'AXESS'
      for (let i = 1; i <= 30; i++) {
        if (_.isUndefined(grid.collectionView.currentItem[`mbrCom${_.padStart(i, 2, '0')}`])) {
          grid.collectionView.currentItem[`mbrCom${_.padStart(i, 2, '0')}`] = '0'
        }

      }
      setAddRow(-1)
    }
  }, [addRow])

  return (
    <React.Fragment>
      <Card
        root='max-button'
        size='lg'
        hideClose={!props.isModal}
        onClose={props.isModal?props.onClose:null}
        header={TITLE.master[`${master.type}`].header}
        headerClass={props.isModal?'modal-header':''}
        subheader={TITLE.master[`${master.type}`].subheader}
        body={
          <React.Fragment>
            <div className='list-header filter'>
              <div>
                <span className='list-count ml-3'>
                  {master[`${master.type}List`]?master[`${master.type}List`].length:0}
                </span>
                <span>件 抽出しました</span>
                <span className='ml-3'>
                  {grid && grid.collectionView && _.filter(filters, (r) => r && r.length > 0).length > 0
                    ? `${grid.collectionView.totalItemCount}件表示しています`: ''}
                </span>
              </div>
              <div 
                className='d-inline-block'
                style={{width: rowHeaderWidth}} />
              {/* <OverlayTrigger
                placement='right'
                delay={{ show: 0, hide: 400 }}
                overlay={<Tooltip id='refresh-tooltip'>表示内容を破棄し、データを再取得します</Tooltip>}
              >
                <button
                  className='refresh-button wj-btn-glyph'
                  title='データを再取得します'
                  style={{width: rowHeaderWidth}}
                  onClick={(e) => getData(master.type)}
                >
                  <span className="material-icons-outlined">refresh</span>
                </button>
              </OverlayTrigger> */}
              {LIST_ITEMS.member.filter(r => r.isHeader).map((r,i) => {
                return (
                  r.id !== ''?
                    <Common.Text
                      key={i}
                      attrs={{
                        id: i,
                        label: '_none',
                        placeholder: 'search',
                        type: 'search',
                        text: filters[r.id],
                      }}
                      onBlur={(e) => null}
                      onInput={(e) => {
                        const filter = {...filters}
                        filter[r.id] = e.target.value.toLowerCase();
                        setFilters(filter)
                      }}
                      group={{
                        style: {width: colWidth[i]},
                        className: 'mx-0',
                      }}
                    />
                  :<div key={i} className='d-inline-block' style={{width: colWidth[0]}} />
                )
                })}
            </div>
            <div className='master-list-body' >
              <Common.Grid
                thisGrid={grid}
                setGrid={setGrid}
                colDef={getColumn()}
                dataList={master[`${master.type}List`]}
                setRowHeaderWidth={setRowHeaderWidth}
                setColWidth={setColWidth}
                grid={{
                  isReadOnly: props.disabled,
                  allowAddNew: !props.disabled,
                  rowAdded: (g, e) => {setAddRow(e.row)},
                  gridFormatItem: (g, e) => {gridFormatItem(g, e)},
                }}
                // detailGrid={{
                //   id: 'member-detail',
                //   mode: 'ExpandMulti',
                //   noSelector: true,
                //   thisGrid: detailGrid,
                //   setGrid: setDetailGrid,
                //   setDetail: setDetail,
                //   colDef: getDetailColumn(),
                //   dataList: (ctx) => {return [ctx.item]},
                //   grid: {
                //     isReadOnly: false,
                //     allowAddNew: false,
                //     beginningEdit: (g, e) => {gridBeginningEdit(g, e)},
                //   }
                // }}
                setInput={masterAction.setInputMaster}
              />
            </div>
            <MemberAuthPopup colDef={getColumn()} data={popupData} setData={setPopupData} popup={popup} setPopup={setPopup} className='d-none' disabled={props.disabled} />
          </React.Fragment>
        }
      />
      <Card 
        size='lg'
        root='button'
        hideClose
        header={null}
        subheader={null}
        body={
          <div className='card-buttom-button'>
            <Button
              {...master.delete}
              className='button-warn mt-auto mr-auto'
              onClick={() => execDelete(master.isCode, master.type)}
              disabled={master.delete.disabled || !(grid && grid.rows.length>0) || app.isProcessing || props.disabled}
            >
              {master.delete.label}
            </Button>
            <Button
              {...master.copy}
              className='button-gray mt-auto mx-auto'
              onClick={() => execCopy()}
              disabled={master.copy.disabled || !(grid && grid.rows.length>0) || master.editing || app.isProcessing || props.disabled}
            >
              {master.copy.label}
            </Button>
            <Button
              {...master.csv}
              className='button-gray mt-auto ml-auto'
              onClick={() => execCsv(master.isCode, master.type)}
              disabled={master.csv.disabled || !(grid && grid.rows.length>0) || app.isProcessing || props.disabled}
            >
              {master.csv.label}
            </Button>
            <Button
              {...master.save}
              className='button-blue mt-auto ml-5'
              onClick={() => execSave(master.isCode, master.type)}
              disabled={master.editing || app.isProcessing || props.disabled}
            >
              {master.save.label}
            </Button>
          </div>
        }
      />
    </React.Fragment>
  )
}