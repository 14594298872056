import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktSearchAction } from 'ducks';
import * as Common from 'components/common';
import { Card } from 'components';
import * as CondCard from './card';
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { SEARCH, MAX_CONDITION_MESSAGE, RADIOS, CHECKS } from 'constants/search';
import { LIST_ITEMS, FLT_IND_BYTE_TKT } from 'constants/searchResult';
import { MESSAGE } from 'constants/message';
import { alert, entryCheck, convert, getCondition, getPattern, getPatternList, dataMapper } from "lib";
import _, { find, uniq } from 'lodash';
import * as wijmo from '@grapecity/wijmo';
import { DateTime } from '@grapecity/wijmo';
import { PatternNamePopup } from 'components/contents/search/modal/PatternNamePopup';

export const ConditionComponent = (props) => {

  const app = useSelector(state => state.App);
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);

  const [popup, setPopup] = React.useState(null)
  const [popupData, setPopupData] = React.useState(null)

  // const [checkedItems, setCheckedItems] = React.useState([]);
  // React.useEffect(() => {
  //   console.log(popup)
  // }, [popup])
  // 初期化
  React.useEffect(() => {
    if (!props.search.condition.value) {
      props.setInput({condition: {
        ...props.search.condition,
        original: RADIOS.condition[0].value,
        value: RADIOS.condition[0].value,
        text: RADIOS.condition[0].value,
      }})
    }
    if (!props.search.searchUnit.value) {
      props.setInput({searchUnit: {
        ...props.search.searchUnit,
        original: RADIOS.searchUnit[0].value,
        value: RADIOS.searchUnit[0].value,
        text: RADIOS.searchUnit[0].value,
      }})
    }
    props.setInput({
      data: [],
    })
    // 前回検索条件＋最近使った検索条件
    appAction.showProcessing({isProcessing: true})
    Promise.all([
      props.searchApi.findSearchPattern({
        tktSrchptPrivForm: props.tktSrchptPrivForm,
      }),
      props.searchApi.findSearchItem({
        tktSrchptObjectKbn: '0',
        tktSrchptPrivForm: props.tktSrchptPrivForm,
      }),
      props.searchApi.findSearchItem({
        tktSrchptObjectKbn: '2',
        tktSrchptPrivForm: props.tktSrchptPrivForm,
      })
    ])
    .then(([patRes, defItemRes, itemRes]) => {
      // console.log(patRes.payload)
      appAction.setMessage('')
      if (patRes.payload.code === '000') {
        // console.log(location.pathname.startsWith('/tkt/search'))
        let checkedItemsSetting = []
        if ((location.pathname.startsWith('/tkt/search'))) {
          checkedItemsSetting = getPattern(patRes.payload.patternMap, '前回検索条件')
          // console.log(checkedItemsSetting)
        } else {
          // console.log(props.search.searchJson)
          _.forEach(props.search.searchJson, (v, k) => {
            _.forEach(SEARCH[1].items, (item, key) => {
              const searchItem = _.find(item.items, ['key', k])
              if (searchItem) {
                checkedItemsSetting.push(searchItem)
              }
            })
          })
        }
        props.setInput({
          recentlyCondition: _.take(getPattern(patRes.payload.patternMap, '最近使った検索条件'), 10),
          checkedItemsSetting: checkedItemsSetting,
          patternList: getPatternList(patRes.payload.patternList),
          patternMap: patRes.payload.patternMap,
          sizeHorizontal: [location.pathname.startsWith('/tkt/search')?'100%':'90%', '1'],
        })
      }
      if (defItemRes.payload.code === '000') {
        props.setInput({
          defaultItemList: defItemRes.payload.list,
        })
      }
      if (itemRes.payload.code === '000') {
        props.setInput({
          currentItemList: itemRes.payload.list,
        })
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }, [])

  // search
  const action = (type) => {
    // 入力チェック
    switch (type) {
      case 'search': 
        props.setInput({searching: true})
        break;
    }
  }

  React.useEffect(() => {
    // console.log('searching')
    if (props.search.searching) {
      // 検索API
      const condition = getCondition(props.search)
      // console.log(condition)
      if (_.isEmpty(condition)) {
        props.setInput({
          searching: false,
        })
        appAction.setMessage({message: [{id: MESSAGE.noCondition().id, message: MESSAGE.noCondition().text, type: MESSAGE.noCondition().icon}]})
        return
      }
      
      appAction.showProcessing({isProcessing: true})
      
      let searchJson = []
      let recentlyJson = []
      // console.log(props.search.recentlyCondition)
      
      _.forEach(condition, (v, k) => {
        searchJson.push(k)
        recentlyJson.push(k)
      })
      _.forEach(props.search.recentlyCondition, (v, k) => {
        recentlyJson.push(v.key)
      })
      
      props.searchApi.findSearch({
        viewOption: props.search.searchUnit.text,
        searchOption: props.search.condition.text,
        // searchString: JSON.stringify(condition),
        searchString: JSON.stringify(_.uniq(searchJson)),
        recentlyString: JSON.stringify(_.uniq(recentlyJson)),
        processId: props.tktSrchptPrivForm,
        ...condition,
      })
      .then(res => {
        !props.search.keepMessage && appAction.setMessage('')
        if (res.payload.code === '000') {

          // 整形
          let type = props.search.searchUnit && props.search.searchUnit.text==='0'?'ticket':'flight'
          let resultList = type==='ticket'?res.payload.resList:res.payload.resFltList
          _.forEach(resultList, (data, i) => {
            _.forEach(data, (v, k) => {
              const _find = _.find(LIST_ITEMS[type], { 'id': k})
              if (_find && _find.dataType === 'Date') {
                if (_find.format !== 'HH:mm') {
                  resultList[i][k] = convert.toDate(v)
                }
                if (_find.format === 'HH:mm') {
                  resultList[i][k] = convert.time2string(convert.timeString2HHmmDate(v))
                }
              }
              if (_find && _find.id === 'tktCaApramt') {
                resultList[i][k] = v?_.toNumber(v):''
              }
              if (_find && _find.id.startsWith('supportSum')) {
                resultList[i][k] = v?_.toNumber(v):''
              }
              if (_find && _find.id.startsWith('fltIndByteTkt')) {
                resultList[i][k] = v?dataMapper(FLT_IND_BYTE_TKT, 'value', 'label', v):''
              }
              if (_find && _find.id.startsWith('tktBookingPcc')) {
                resultList[i][k] = data.tktReserveOfficeid?data.tktReserveOfficeid:v
              }
              if (_find && _find.id.startsWith('tktTktPcc')) {
                resultList[i][k] = data.tktTicketingOfficeid?data.tktTicketingOfficeid:v
              }
            })
          })

          if (resultList.length > app.dispLimit) {
            setTimeout(() => {
              alert().fire({
                icon: MESSAGE.dispLimit().icon,
                title: MESSAGE.dispLimit('確認').text
              })
              .then((result) => {
                if (result.isConfirmed) {
                  props.setInput({
                    ...props.search,
                    sizeHorizontal:['0%', '1'],
                    data: resultList,
                    size1:['15%', '1'],
                    searchJson: condition,
                    searchSql: res.payload.searchSql,
                    recentlyCondition: _.take(getPattern(res.payload.patternMap, '最近使った検索条件'), 10),
                    // checkedItemsSetting: getPattern(res.payload.patternMap, '前回検索条件'),
                    patternList: getPatternList(res.payload.patternList),
                    patternMap: res.payload.patternMap,
                    keepMessage: false,
                    searching: false,
                    selDate: res.payload.selDate,
                    delete: {
                      ...props.search.delete,
                      disabled: props.search.searchUnit.text === '1'
                    }
                  })
                } else {
                  props.setInput({
                    ...props.search,
                    sizeHorizontal:['100%', '1'],
                    recentlyCondition: _.take(getPattern(res.payload.patternMap, '最近使った検索条件'), 10),
                    data: [],
                    searchJson: condition,
                    searchSql: res.payload.searchSql,
                    // checkedItemsSetting: getPattern(res.payload.patternMap, '前回検索条件'),
                    patternList: getPatternList(res.payload.patternList),
                    patternMap: res.payload.patternMap,
                    keepMessage: false,
                    searching: false,
                  })
                }
              })
            })
          } else {
            props.setInput({
              ...props.search,
              sizeHorizontal:['0%', '1'],
              data: resultList,
              size1:['15%', '1'],
              searchJson: condition,
              searchSql: res.payload.searchSql,
              recentlyCondition: _.take(getPattern(res.payload.patternMap, '最近使った検索条件'), 10),
              // checkedItemsSetting: getPattern(res.payload.patternMap, '前回検索条件'),
              patternList: getPatternList(res.payload.patternList),
              patternMap: res.payload.patternMap,
              keepMessage: false,
              searching: false,
              selDate: res.payload.selDate,
              delete: {
                ...props.search.delete,
                disabled: props.search.searchUnit.text === '1'
              }
            })
          }
        } else if (res.payload.code === '100') {
          props.setInput({
            ...props.search,
            sizeHorizontal: [location.pathname.startsWith('/tkt/search')?'100%':'90%', '1'],
            recentlyCondition: _.take(getPattern(res.payload.patternMap, '最近使った検索条件'), 10),
            data: [],
            searchJson: condition,
            searchSql: res.payload.searchSql,
            // checkedItemsSetting: getPattern(res.payload.patternMap, '前回検索条件'),
            patternList: getPatternList(res.payload.patternList),
            patternMap: res.payload.patternMap,
            keepMessage: false,
            searching: false,
          })
          appAction.setMessage({message: res.payload.messageList})
        } else {
          props.setInput({
            keepMessage: false,
            searching: false,
          })
          appAction.setMessage({message: res.payload.messageList})
        }
      })
      .catch(err => {
        // setName('')
      })
      .finally(() => {
        appAction.showProcessing({isProcessing: false})
      })
    }

  }, [props.search.searching])

  React.useEffect(() => {
    if (props.search.searchingAndExec) {
      // 検索API
      const condition = getCondition(props.search)
      if (_.isEmpty(condition)) {
        props.setInput({
          searchingAndExec: false,
        })
        appAction.setMessage({message: [{id: MESSAGE.noCondition().id, message: MESSAGE.noCondition().text, type: MESSAGE.noCondition().icon}]})
        return
      }
      
      appAction.showProcessing({isProcessing: true})
      
      let searchJson = []
      let recentlyJson = []

      _.forEach(condition, (v, k) => {
        searchJson.push(k)
        recentlyJson.push(k)
      })
      _.forEach(props.search.recentlyCondition, (v, k) => {
        recentlyJson.push(v.key)
      })
      
      props.searchApi.findSearch({
        // BIの場合は、TKT固定
        viewOption: props.search.nextLoc.indexOf('pivot') < 0?props.search.searchUnit.text:'0',
        searchOption: props.search.condition.text,
        // searchString: JSON.stringify(condition),
        searchString: JSON.stringify(_.uniq(searchJson)),
        recentlyString: JSON.stringify(_.uniq(recentlyJson)),
        processId: props.tktSrchptPrivForm,
        isGetMap: true,
        ...condition,
      })
      .then(res => {
        !props.search.keepMessage && appAction.setMessage('')
        if (res.payload.code === '000' || res.payload.code === '100') {
          // 画面遷移
          props.setInput({
            ...props.search,
            data: [],
            searchJson: condition,
            searchSql: res.payload.searchSql,
            patternList: getPatternList(res.payload.patternList),
            patternMap: res.payload.patternMap,
            keepMessage: false,
            searchingAndExec: false,
          })
          setTimeout(() => {
            history.push(props.search.nextLoc, props.search.nextState)
          }, 500)
        } else {
          props.setInput({
            data: [],
            searchJson: condition,
            searchSql: res.payload.searchSql,
            patternList: getPatternList(res.payload.patternList),
            patternMap: res.payload.patternMap,
            keepMessage: false,
            searchingAndExec: false,
          })
          appAction.setMessage({message: res.payload.messageList})
        }
      })
      .catch(err => {
        // setName('')
      })
      .finally(() => {
        // appAction.showProcessing({isProcessing: false})
      })
    }
  }, [props.search.searchingAndExec])

  const renderCard = () => {
    // console.log(props.search)
    const render = []
    _.forEach(props.search.checkedItems, (r, i) => {
      const items = r && _.filter(props.search, (v, k) => {
        return v && v.id && (v.id.startsWith(r.key + '_') || v.id === r.key)
      })
      switch (r && r.type) {
        case 'code':
          render.push(
            <CondCard.Code
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'code_range':
          render.push(
            <CondCard.CodeRange
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'text':
          render.push(
            <CondCard.Text
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'text_in':
          render.push(
            <CondCard.TextIn
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'text_multi':
        case 'text_blank':
          render.push(
            <CondCard.TextBlank
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'text_in_blank':
          render.push(
            <CondCard.TextInBlank
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'text_range':
          render.push(
            <CondCard.TextRange
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'text_in_range':
          render.push(
            <CondCard.TextInRange
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'check':
          render.push(
            <CondCard.Check
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'check_in':
          render.push(
            <CondCard.CheckIn
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'radio':
          render.push(
            <CondCard.Radio
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'radio_check':
          render.push(
            <CondCard.RadioCheck
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'date':
          render.push(
            <CondCard.Date
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'datetime':
          render.push(
            <CondCard.Date
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
        case 'mmyy':
          render.push(
            <CondCard.Mmyy
              key={i}
              cardSize='md'
              condition={r}
              items={items}
              search={props.search}
              setInput={props.setInput}
              onBlur={props.onBlur}
              onClose={props.onClose}
            />
          )
          break;
      }
    })
    // console.log(render)
    return render;
  }

  // const patternLoad = () => {
  //   if (props.search.searching) {
  //     // 検索API
  //     const condition = getCondition(props.search)
  //     console.log(condition)
  //     // let check = {}
  //     // _.forEach(condition, (v, k) => {
  //     //   const item = {...v}
  //     //   delete item.radioType
  //     //   delete item.dataTypeRadioType
  //     //   delete item.clusterRadioType
  //     //   check = {...item}

  //     // })
  //     // console.log(check)
  //     if (_.isEmpty(condition)) {
  //       props.setInput({searching: false})
  //       appAction.setMessage({message: [{id: MESSAGE.noCondition().icon, message: MESSAGE.noCondition().text, type: MESSAGE.noCondition().icon}]})
  //       return
  //     }
  //     appAction.showProcessing({isProcessing: true})
      
  //     let searchJson = []
  //     let recentlyJson = []
  //     console.log(props.search.recentlyCondition)
      
  //     _.forEach(condition, (v, k) => {
  //       searchJson.push(k)
  //       recentlyJson.push(k)
  //     })
  //     _.forEach(props.search.recentlyCondition, (v, k) => {
  //       recentlyJson.push(v.key)
  //     })
      
  //     props.searchApi.findSearch({
  //       viewOption: props.search.searchUnit.text,
  //       searchOption: props.search.condition.text,
  //       // searchString: JSON.stringify(condition),
  //       searchString: JSON.stringify(_.uniq(searchJson)),
  //       recentlyString: JSON.stringify(_.uniq(recentlyJson)),
  //       ...condition,
  //     })
  //     .then(res => {
  //       appAction.setMessage('')
  //       if (res.payload.code === '000') {
  //         props.setInput({
  //           sizeHorizontal:['0%', '1'],
  //           data: res.payload.resList,
  //           size1:['15%', '1'],
  //           searchSql: res.payload.searchSql,
  //           recentlyCondition: getPattern(res.payload.patternMap, '最近使った検索条件'),
  //           // checkedItemsSetting: getPattern(res.payload.patternMap, '前回検索条件'),
  //         })
  //       } else if (res.payload.code === '100') {
  //         props.setInput({
  //           recentlyCondition: getPattern(res.payload.patternMap, '最近使った検索条件'),
  //           // checkedItemsSetting: getPattern(res.payload.patternMap, '前回検索条件'),
  //         })
  //         appAction.setMessage({message: res.payload.messageList})
  //       } else {
  //         appAction.setMessage({message: res.payload.messageList})
  //       }
  //     })
  //     .catch(err => {
  //       // setName('')
  //     })
  //     .catch(() =>{})
  //     .finally(() => {
  //       appAction.showProcessing({isProcessing: false})
  //       props.setInput({searching: false})
  //     })
  //   }
  // }

  return (
    <React.Fragment>
      <PatternNamePopup 
        popup={popup}
        setPopup={setPopup}
        popupData={popupData}
        setPopupData={setPopupData}
        header={props.search.condEdit.label}
        search={props.search}
        onBlur={props.onBlur}
        setInput={props.setInput}
        searchApi={props.searchApi}
        tktSrchptPrivForm={props.tktSrchptPrivForm}
      />
      <Card 
        root='header-only'
        size='lg'
        hideClose
        header={
          <React.Fragment>
            <span>{props.title.header}</span>
            <Common.RadioButton
              btnSize='sm'
              attrs={props.search.condition}
              radios={RADIOS.condition}
              onChange={props.onBlur}
            />
          </React.Fragment>
        }
        subheader={`${props.search.checkedItems.length}${MAX_CONDITION_MESSAGE}`}
        subheaderClass='text-right'
        body={null}
      />
      <Card 
        root='max-button-search'
        size='lg'
        hideClose
        body={
          <React.Fragment>
            {renderCard()}
          </React.Fragment>
        }
      />
      {/* !location.pathname.startsWith('/tkt/search') && */}
      <Card 
        size='lg'
        root='button'
        hideClose
        header={null}
        subheader={null}
        body={
          <div className='card-buttom-button'>
            <Button
              {...props.search.condClear}
              className='button-gray mr-3 width-max-content'
              onClick={(e) => {
                props.setInput({checkedItems:[]})
              }}
              disabled={app.isProcessing}
            >
              {props.search.condClear.label}
            </Button>
            <Button
              {...props.search.condEdit}
              className='button-gray mr-auto'
              disabled={app.isProcessing}
              onClick={(e) => {
                if (props.search.checkedItems.length < 1) {
                  appAction.setMessage({message: [{id: MESSAGE.noCondition().id, message: MESSAGE.noCondition().text, type: MESSAGE.noCondition().icon}]})
                  return
                }
                setPopupData('')
                popup.show(true, (sender) => {
                  if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
                    // console.log('ok')
                  } else {
                    // console.log('cancel')
                  }
                  setPopupData('')
                })
              }}
            >
              {props.search.condEdit.label}
            </Button>
            <div className='ml-auto width-max-content'>
              <div className='mr-2 d-inline'>{props.search.searchUnit.prefix}</div>
              <Common.RadioButton
                btnSize='sm'
                className='mr-5 width-25'
                attrs={props.search.searchUnit}
                radios={RADIOS.searchUnit}
                onChange={props.onBlur}
              />
            </div>
            <Button
              {...props.search.search}
              className='button-blue width-max-content'
              onClick={(e) => action('search')}
              disabled={app.isProcessing}
            >
              {props.search.search.label}
            </Button>
          </div>
        }
      />
    </React.Fragment>
  )
}