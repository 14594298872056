import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Row, Col } from 'react-bootstrap';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjGridNom from '@grapecity/wijmo.grid';
import { MAX_ITEMS } from 'constants/searchResult';
import { LIST_ITEMS } from 'constants/ticket';
import { Card } from 'components';
import _ from 'lodash';

export const ItemPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);

  const [popup, setPopup] = React.useState(null)
  const initPopup = (form) => {
    // form.hostElement.classList.add('item-popup')
    setPopup(form)
  }

  const [sourceData, setSourceData] = React.useState([])
  const [targetData, setTargetData] = React.useState([])

  const [sourceGrid, setSourceGrid] = React.useState(null)
  const [targetGrid, setTargetGrid] = React.useState(null)
  const [btnDisabled, setBtnDisabled] = React.useState(false)
  const [sortBtnDisabled, setSortBtnDisabled] = React.useState(false)
  const [initData, setInitData] = React.useState(false)

  
  const initSourceGrid = (g) => {
    setSourceGrid(g)
    g.selectionMode = 'ListBox'
  }
  
  const initTargetGrid = (g) => {
    setTargetGrid(g)
    g.selectionMode = 'ListBox'
  }

  const itemsSourceChanged = (g, e) => {
    // console.log(g)
    g.rowHeaders.columns.defaultSize = 50;
    for (let r = 0; r < g.rowHeaders.rows.length; r++) {
      g.rowHeaders.setCellData(r, 0, r + 1);
    }
  }

  React.useEffect(() => {
    if (sourceGrid && targetGrid) {
      let newSource = []
      _.forEach(props.listItems, (src, i) => {
        if (!_.find(targetData, (r, i) => {return r.id === src.id})) {
          newSource.push(src)
        }
      })
      // console.log(newSource)
      setSourceData(newSource)
      setInitData(false)
    }
  }, [targetData.length, initData])

  const onClick = (e) => {
    // console.log(props.listItems)
    // console.log(props.dispCheckedItems)
    setSourceData(props.listItems)
    setTargetData(props.dispCheckedItems)
    popup.show(true, (sender) => {
      // console.log(sender.dialogResult)
      if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
        appAction.showProcessing({isProcessing: true})
        let targetArray = []
        _.forEach(targetGrid.rows, r => {
          targetArray.push(r.dataItem)
        })
        let settingJsonString = JSON.stringify(targetArray)
        // console.log(settingJsonString)

        props.searchApi.findRegistItem({
          tktDispType: props.search.searchUnit.text==='0'?'1':'2',
          tktSrchptObjectKbn: '2',
          tktSrchptPrivForm: props.tktSrchptPrivForm,
          registDispItem: settingJsonString,
        })
        .then(res => {
          appAction.setMessage('')
          if (res.payload.code === '000') {
            const curItems = _.filter(res.payload.list, r => r.tktDispType === (props.search.searchUnit.text==='0'?'1':'2'))
            props.setInput({
              currentItemList: res.payload.list,
              dispCheckedItems: JSON.parse(curItems[0].tktSrchlstItem),
            })
          } else {
            appAction.setMessage({message: res.payload.messageList})
          }
        })
        .catch(err => {})
        .finally(() => {
          appAction.showProcessing({isProcessing: false})
        })

      } else {
        // console.log('cancel')
      }
      setSourceData([])
      setTargetData([])
    })
  }
  
  const addItem = () => {
    // console.log(sourceGrid.selectedRows)
    // console.log(sourceGrid.selection.row)
    if ((sourceGrid.selectedRows.length + targetGrid.rows.length) > MAX_ITEMS) return
    setBtnDisabled(true)
    const row1 = sourceGrid.selection.row
    const row2 = sourceGrid.selection.row2
    const rowIndex = row1 > row2? row2: row1
    const cRange = new wjGridNom.CellRange(rowIndex, 0)
    let addRows = []
    _.forEach(sourceGrid.selectedRows, r => {
      addRows.push(r.dataItem)
    })
    setTargetData(_.concat(targetData, addRows))
    setTimeout(() => {
      sourceGrid.selection = cRange
      setBtnDisabled(false)
    })
  }

  const delItem = () => {
    // console.log(targetGrid.selectedRows)
    setBtnDisabled(true)
    const row1 = targetGrid.selection.row
    const row2 = targetGrid.selection.row2
    const rowIndex = row1 > row2? row2: row1
    const cRange = new wjGridNom.CellRange(rowIndex, 0)
    let rows = []
    _.forEach(targetGrid.rows, r => {
      const deletableRows = _.filter(targetGrid.selectedRows, r => r.dataItem.id !== 'tktTktnoAl' && r.dataItem.id !== 'tktTktnoSn')
      if (!_.find(deletableRows, r)) {
        rows.push(r.dataItem)
      }
    })
    setTargetData(rows)
    setTimeout(() => {
      targetGrid.selection = cRange
      setBtnDisabled(false)
    })
  }

  const sortItem = (type) => {
    // console.log(type)
    // console.log(targetGrid.selection.row)
    let fromIdx = targetGrid.selection.row
    let toIdx = 0
    switch (type) {
      case 'top':
        toIdx = 0
        break;
      case 'up':
        toIdx = (fromIdx - 1)<0?fromIdx:fromIdx - 1
        break;
      case 'down':
        toIdx = (fromIdx + 1)>(targetGrid.rows.length - 1)?fromIdx:fromIdx + 1
        break;
      case 'bottom':
        toIdx = targetGrid.rows.length -1
        break;
    }
    targetGrid.rows.moveElement(fromIdx, toIdx)
    itemsSourceChanged(targetGrid)
    targetGrid.selection = new wjGridNom.CellRange(toIdx, 0)
    // カーソルが移動しない場合があるため
    setTimeout(() => {
      targetGrid.selection = new wjGridNom.CellRange(toIdx, 0)
    })
  }

  const initSettings = (e) => {
    // console.log(e)
    const defItems = _.filter(props.search.defaultItemList, r => r.tktDispType === (props.search.searchUnit.text==='0'?'1':'2'))
    setTargetData(JSON.parse(defItems[0].tktSrchlstItem))
    setInitData(true)
  }

  return (
    <React.Fragment>
      <button
        className='button-gray grid-button setting wj-btn-glyph'
        onClick={(e) => onClick(e)}
      >
        {props.button}
      </button>
      <wjInput.Popup
        isDraggable={true}
        isResizable={false}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
        removeOnHide={false}
      >
        <Card
          header='表示項目設定'
          headerClass='wj-dialog-header'
          subheader={
            <React.Fragment>
              <span className='pt-1'>{`表示項目選択${targetData.length}/${MAX_ITEMS}`}</span>
              <button type="button" className="close wj-hide-cancel">&times;</button>
            </React.Fragment>
          }
          size='lg'
          root='popup w-75vw'
          hideClose
          bodyClass='px-5'
          body={
            <Row className='w-auto'>
              <Col xs={5}>
                <Card
                  header='表示項目選択'
                  size='lg'
                  // root='popup'
                  hideClose
                  bodyClass='vh-50'
                  body={
                    <div className='settings-list-body' >
                      <wjGrid.FlexGrid
                        deferResizing={true}
                        itemsSource={sourceData}
                        allowSorting={'None'}
                        allowDragging={false}
                        showMarquee={true}
                        // frozenColumns={1}
                        isReadOnly={true}
                        autoSearch={true}
                        initialized={(s) => initSourceGrid(s)}
                        itemsSourceChanged={(g, e) => itemsSourceChanged(g, e)}
                      >
                        {LIST_ITEMS.settings.map((r,i) => {
                          return (
                            <wjGrid.FlexGridColumn
                              key={i}
                              binding={r.id}
                              header={r.header}
                              dataType={r.dataType}
                              format={r.format}
                              isReadOnly={r.isReadOnly}
                              isRequired={r.isRequired}
                              maxLength={r.maxLength}
                              width={r.width}
                              minWidth={r.minWidth}
                              maxWidth={r.maxWidth}
                              allowSorting={r.allowSorting}
                              align={r.align}
                              dataMap={r.dataMap}
                            />
                          )})
                        }
                      </wjGrid.FlexGrid>
                    </div>
                  }
                />
              </Col>
              <Col xs={1} className='icon-center'>
                <div className='d-flex flex-column'>
                  <Button
                    className='button-gray mb-3'
                    disabled={btnDisabled}
                    onClick={(e) => addItem()}
                  >
                    <span className="material-icons-outlined">keyboard_arrow_right</span>
                  </Button>
                  <Button
                    className='button-gray'
                    disabled={btnDisabled}
                    onClick={(e) => delItem()}
                  >
                    <span className="material-icons-outlined">keyboard_arrow_left</span>
                  </Button>
                </div>
              </Col>
              <Col xs={6}>
                <Card
                  header='選択された表示項目'
                  size='lg'
                  // root='popup'
                  subheader={<div className='settings-sub-header'>※矢印をクリックで順番変更可能</div>}
                  hideClose
                  bodyClass='vh-50'
                  body={
                    <div className='settings-list-body' >
                      <div className='d-flex flex-row'>
                        <wjGrid.FlexGrid
                          deferResizing={true}
                          itemsSource={targetData}
                          allowSorting={'None'}
                          allowDragging={false}
                          showMarquee={true}
                          // frozenColumns={1}
                          isReadOnly={true}
                          autoSearch={true}
                          initialized={(s) => initTargetGrid(s)}
                          itemsSourceChanged={(g, e) => itemsSourceChanged(g, e)}
                          selectionChanged={(g, e) => setSortBtnDisabled(g.selectedRows.length !== 1)}
                        >
                          {LIST_ITEMS.settings.map((r,i) => {
                            return (
                              <wjGrid.FlexGridColumn
                                key={i}
                                binding={r.id}
                                header={r.header}
                                dataType={r.dataType}
                                format={r.format}
                                isReadOnly={r.isReadOnly}
                                isRequired={r.isRequired}
                                maxLength={r.maxLength}
                                width={r.width}
                                minWidth={r.minWidth}
                                maxWidth={r.maxWidth}
                                allowSorting={r.allowSorting}
                                align={r.align}
                                dataMap={r.dataMap}
                              />
                            )})
                          }
                        </wjGrid.FlexGrid>
                        <div className='d-flex flex-column'>
                          <Button
                            className='button-gray mb-1'
                            disabled={sortBtnDisabled}
                            onClick={(e) => sortItem('top')}
                          >
                            <span className="material-icons-outlined md">keyboard_double_arrow_up</span>
                          </Button>
                          <Button
                            className='button-gray mb-0'
                            disabled={sortBtnDisabled}
                            onClick={(e) => sortItem('up')}
                          >
                            <span className="material-icons-outlined md">keyboard_arrow_up</span>
                          </Button>
                          <Button
                            className='button-gray mb-1 mt-auto'
                            disabled={sortBtnDisabled}
                            onClick={(e) => sortItem('down')}
                          >
                            <span className="material-icons-outlined md">keyboard_arrow_down</span>
                          </Button>
                          <Button
                            className='button-gray mb-0'
                            disabled={sortBtnDisabled}
                            onClick={(e) => sortItem('bottom')}
                          >
                            <span className="material-icons-outlined md">keyboard_double_arrow_down</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  }
                />
              </Col>
            </Row>
          }
        />
        <Card 
          size='md'
          root={`popup-button`}
          hideClose
          body={
            <React.Fragment>
              <Button className='button-gray mr-auto wj-hide-cancel'>
                <span className='material-icons-outlined md mr-2'>cancel</span>
                キャンセル
              </Button>
              <Button className='button-gray mx-auto' onClick={(e) => initSettings(e)}>
                <span className='material-icons-outlined md mr-2'>refresh</span>
                初期値に戻す
              </Button>
              <Button
                className='button-blue ml-auto'
                onClick={(e) => {
                  popup.dialogResult='ok'
                  popup.hide()
                  // console.log(e)
                }}
                disabled={app.isProcessing}
              >
                <span className='material-icons-outlined md mr-2'>file_upload</span>
                設定
              </Button>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})