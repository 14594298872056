import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass, Tooltip as wjTooltip, PopupPosition} from "@grapecity/wijmo";
import { CellRange, DataMap } from "@grapecity/wijmo.grid";
import { InputDate, InputTime, ComboBox, AutoComplete, MultiAutoComplete, InputColor, InputNumber } from '@grapecity/wijmo.input';
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE, LIST_ITEMS, TKT_KB_FLAG_MAP, EMD_ID_MAP } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';

export const EmdPane = (props) => {

  const app = useSelector(state => state.App);
  const tktEdit = useSelector(state => state.TktEdit);
  
  const dispatch = useDispatch();
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  const [grid, setGrid] = React.useState(null)

  const getColumn = () => {
    const column = [].concat(LIST_ITEMS.emd)
    _.forEach(column, (r, i) => {
      switch (r.id) {
        case 'emdConsumedIssuaranceInd':
          r.dataMap = new DataMap(EMD_ID_MAP, 'id', 'name')
          r.editor = new AutoComplete(document.createElement('div'), {
            itemsSource: EMD_ID_MAP,
            isEditable: false,
            minLength: 1,
            delay: 10,
            selectedValuePath: 'id',
            displayMemberPath: 'name'
          })
      }
    })
    return column
  }

  React.useEffect(() => {
    if (tktEdit.tktReasonIssuanceCode.text !== tktEdit.tktReasonIssuanceCode.original) {
      tktEditAction.changeInputTktEdit(convert.getTarget(tktEdit.tktReasonIssuanceDesc, 'text', ''))
    } else {
      tktEditAction.changeInputTktEdit(convert.getTarget(tktEdit.tktReasonIssuanceDesc, 'text', tktEdit.tktReasonIssuanceDesc.original))
    }
  }, [tktEdit.tktReasonIssuanceCode.text])

  const gridFormatItem = (g, e) => {
    const item = e.getRow().dataItem
    const bind = e.getColumn().binding
    _.forEach(g.rowHeaders.rows, (r, i) => {
      // g.rowHeaders.setCellData(i, 0, i + 1)
      toggleClass(e.cell, 'wj-wrap', g.columnHeaders === e.panel);
    })
    if (e.panel == g.columnHeaders) {
      new wjTooltip({
        position: PopupPosition.RightTop,
        showAtMouse: true,
        showDelay: 600,
      }).setTooltip(e.cell, '<span>' + e.getColumn().name + '</span>');
    }
    if (item) {
      if (bind === 'emdTktno') {
        toggleClass(e.cell, 'text-indent-200p', (e.row-1) >= 0 && e.getRow().dataItem[bind] === g.rows[e.row-1].dataItem[bind]);
      }
    }
  }

  const itemFormatter = (panel, r, c, cell) => {
    // 列ヘッダは中央
    if (panel.cellType == 2) {
      cell.style.textAlign = 'center';
    }
  }
  
  return (
    <React.Fragment>
      <div className='d-flex'>
        <span className="d-inline-block">
          <Common.Text
            attrs={{
              ...tktEdit.tktReasonIssuanceCode,
            }}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'min-width-3 width-3 mr-2',
            }}
          />
          <label className={`${tktEdit.tktReasonIssuanceCode.visible?'visible':'invisible'}`}>{tktEdit.tktReasonIssuanceDesc.text}</label>
        </span>
      </div>
      <div className='d-flex'>
        <span className="d-inline-block width-p-100">
          <Common.Text
            attrs={{...tktEdit.tktEmdRmks}}
            onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
            group={{
              className: 'width-88 mr-0 mb-2',
            }}
          />
        </span>
      </div>
      <div className={`emd-list-body ${tktEdit.tktEmdCpnData.visible?'visible':'invisible'}`} >
        <Common.Grid
          thisGrid={grid}
          setGrid={setGrid}
          colDef={getColumn()}
          // masterType={master.type}
          dataList={tktEdit.bstEmdCpnDataListDto}
          // setRowHeaderWidth={setRowHeaderWidth}
          // setColWidth={setColWidth}
          noSelector={true}
          grid={{
            isReadOnly: app.tktEditFormMode==='3',
            allowAddNew: false,
            // frozenColumns: 1,
            imeEnabled: false,
            headersVisibility: 'Column',
            // colHeaderDefHeight: 92,
            gridFormatItem: (g, e) => gridFormatItem(g, e),
            itemFormatter: (panel, r, c, cell) => itemFormatter(panel, r, c, cell)
          }}
          setInput={tktEditAction.setInputTktEdit}
        />
      </div>
    </React.Fragment>
  )
}