import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { PivotAction } from 'ducks/Pivot';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { CHART_TYPE } from 'constants/pivot';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';
import { SummaryApi } from 'webapi'
import { Step } from './Step';

export const Step1 = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const pivot = useSelector(state => state.Pivot);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);
  const pivotAction = bindActionCreators(PivotAction, dispatch);

  return (
    <React.Fragment>
      <Card 
        root='popup'
        size='lg'
        hideClose
        header='データ移行　STEP1'
        headerClass='wj-dialog-header'
        body={
          <React.Fragment>
            <Step step={props.step} />
            <div className='py-2 px-5'>
              <div className={`step-error ${props.errorList.length>0?'':'d-none'}`}>
                {props.errorList.map((r, i) => {
                  return <div key={i}>{r.message}</div>
                })}
              </div>
              <div className='step-body mx-auto mt-4'>
                <div>これからURI-Proデータ移行を開始します。</div>
                <div>画面の指示にしたがって操作を進めてください。</div>
                <div className='step-attention mt-2'>[ 注意事項 ]</div>
                <div>・操作を進める場合、移行作業完了までの間、発券データ受信が一時停止します。また、お客様操作により作業の中断や、発券データの受信再開を行うことはできません。</div>
                <div>・移行開始ボタンクリック後、作業を中断する必要がある場合はAmadeusまでご連絡ください。</div>
              </div>
            </div>
          </React.Fragment>
        }
      />
      <Card
        size='md'
        root={`popup-button`}
        body={
          <React.Fragment>
            <Button
              className='button-gray width-20 mx-auto'
              onClick={props.cancel}
              disabled={app.isProcessing}
            >
              <div className='d-flex flex-row align-items-center justify-content-center'>
                <div className='d-inline-block'>キャンセル</div>
              </div>
            </Button>
            <Button
              className='button-blue width-20 mx-auto'
              onClick={props.action}
              disabled={app.isProcessing}
            >
              <div className='d-flex flex-row align-items-center justify-content-center'>
                <div className='d-inline-block'>移行開始</div>
                <div className='material-icons-outlined lg'>arrow_right</div>
              </div>
            </Button>
            <div>
              <div className='ml-3 mb-0'>お問い合わせ先　Amadeus Delivery担当</div>
              <div className='ml-3 mb-0'>メールアドレス <Link to='#' onClick={(e) => {
                appAction.setInputApp({isUnloadCheck: false})
                setTimeout(() => {
                  window.location.href='mailto:uripro.migration.japan@amadeus.com';
                  e.preventDefault();
                  appAction.setInputApp({isUnloadCheck: true})
                })
              }}>uripro.migration.japan@amadeus.com</Link></div>
            </div>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
})