import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const ProcessingComponent = (props) => {
  
  const app = useSelector(state => state.App);

  return (
    <React.Fragment>
      <div className={`processing ${app.isProcessing || app.isInitProcessing?'':'d-none'}`}>
        <Spinner className='processing-icon' animation="border" />
        <div className='processing-message'>{app.processingMessage}</div>
      </div>
    </React.Fragment>
  )
}
