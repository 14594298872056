import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import * as wjc from '@grapecity/wijmo'
import { Button, Form, Nav } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import { EMPTY_SEARCH } from 'constants/master';
import { convert } from "lib";
import { AccountCodeSearchComponent as AccountCodeSearch } from 'components/Master/AccountCodeSearchComponent';
import { CompanySearchComponent as CompanySearch } from 'components/Master/CompanySearchComponent';
import { MemberSearchComponent as MemberSearch } from 'components/Master/MemberSearchComponent';
import { ProductionSearchComponent as ProductionSearch } from 'components/Master/ProductionSearchComponent';
import { DecodeSearchComponent as DecodeSearch } from 'components/Master/DecodeSearchComponent';
import { FareSearchComponent as FareSearch } from 'components/Master/FareSearchComponent';

export const MasterPopup = React.forwardRef((props, ref) => {

  const {popup, setPopup} = props

  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  // const [popup, setPopup] = React.useState(null)

  const initPopup = (form) => {
    setPopup(form)
  }

  const onClick = (e) => {
    masterAction.setInputMaster({isCode: props.isCode, type: props.type})
    popup.show(true, (sender) => {
      // if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
      //   console.log('ok')
      // } else {
      //   console.log('cancel')
      // }
      masterAction.setInputMaster({isCode: false, type: 'main'})
    })
  }

  const generateComponent = () => {
    if (master.type !== props.type) return null
    switch (master.type) {
      case 'order':
      case 'area':
      case 'section':
      case 'free':
      case 'tour':
      case 'kb1':
      case 'kb2':
      case 'pay':
      case 'eo':
        return (
          <React.Fragment>
            <AccountCodeSearch isModal onClose={(e) => popup.hide('cancel')}/>
          </React.Fragment>
        )
      case 'company':
        return (
          <React.Fragment>
            <CompanySearch isModal onClose={(e) => popup.hide('cancel')}/>
          </React.Fragment>
        )
      case 'member':
        return (
          <React.Fragment>
            <MemberSearch isModal onClose={(e) => popup.hide('cancel')}/>
          </React.Fragment>
        )
      case 'production':
        return (
          <React.Fragment>
            <ProductionSearch isModal onClose={(e) => popup.hide('cancel')}/>
          </React.Fragment>
        )
      case 'decode':
        return (
          <React.Fragment>
            <DecodeSearch isModal onClose={(e) => popup.hide('cancel')}/>
          </React.Fragment>
        )
      case 'fare':
        return (
          <React.Fragment>
            <FareSearch isModal onClose={(e) => popup.hide('cancel')}/>
          </React.Fragment>
        )
      default:
        return EMPTY_SEARCH
    }
  }

  React.useEffect(() => {
    masterAction.setInputMaster({isCode: false, type: 'main'})
  }, [])

  return (
    <React.Fragment>
      <Nav
        onSelect={(selectedKey) => onClick(selectedKey)}
        className={`master-popup-nav ${props.className}`}
      >
        <Nav.Link
          eventKey={props.type}
          tabIndex='-1'
          // className='button-gray mt-auto ml-auto'
          // // onClick={() => execImport(master.type)}
          // onClick={(e) => onClick(e)}
          // disabled={master.upload.disabled || props.disabled || app.isProcessing}
        >
          {props.btnName}
        </Nav.Link>
      </Nav>
      <wjInput.Popup
        isDraggable={true}
        isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
        className='modal-master'
      >
        {generateComponent()}
      </wjInput.Popup>
    </React.Fragment>
  )
})