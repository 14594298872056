import _ from 'lodash';
import calc from 'postcss-calc';

// export const EXCEL_MAX_ITEMS = 256;
export const MAX_WINDOW = 16;

export const IFN_FORMAT = [
  {name: 'AXESS形式', value: '1'},
  {name: 'INFINI形式', value: '0'},
]
export const IMPORT_MESSAGE = {
  header: '発券データ入力',
  processingTitle:'発券データ入力を受け付けました',
  processingMessage1: <span className='popup-warn-text'><div>※下の「閉じる」ボタンからこの画面を閉じてください。</div><div>取り込み対象の発券データ量によっては処理完了までに時間がかかりますので、後で「処理結果一覧」から結果をご確認ください。</div></span>,
  processingMessage2: '',
  resultTitle: '発券データ入力の処理が完了しました',
  resultMessage1: '',
  resultMessage2: '',
}
export const EXPORT_MESSAGE = {
  header: '発券データ出力',
  processingTitle:'発券データ出力中',
  processingMessage1: '出力結果は、画面を閉じても後で確認が可能です',
  processingMessage2: '',
  resultTitle: '発券データ出力の処理が完了しました',
  resultMessage1: '',
  resultMessage2: '',
}
export const EXCEL_MESSAGE = {
  header: 'EXCELデータ出力',
  processingTitle:'EXCELデータ出力中',
  processingMessage1: '出力結果は、画面を閉じても後で確認が可能です',
  processingMessage2: '',
  resultTitle: 'EXCELデータ出力の処理が完了しました',
  resultMessage1: '',
  resultMessage2: '',
}
export const TKT_KB_FLAG_MAP = [
  {name: 'PRODUCTION', value: ' '},
  {name: 'マニュアル', value: '1'},
]
export const TKT_STATUS = [
  {name: '通常', value: ''},
  {name: '通常', value: '1'},
  {name: 'マニュアルVOID', value: '2'},
  {name: 'オンラインVOID', value: '3'},
  {name: 'MANUAL REFUND', value: '4'},
  {name: 'AUTO REFUND', value: '5'},
  {name: 'REISSUE元券', value: '6'},
  {name: 'REISSUE新券', value: '7'},
  {name: '親TKTのREFUND情報有', value: '8'},
  {name: 'CONJ.TKTのREFUND情報有', value: '9'},
]
export const PAX_TYPE = [
  {name: 'ADULT', value: ' '},
  {name: 'CHILD', value: 'C'},
  {name: 'INFANT', value: 'I'},
]
export const TICKET_TYPE =[
  {name: 'ET', value: '6'},
  {name: 'EMD', value: '7'},
  {name: 'MPD', value: '4'},
  {name: 'OPATB2', value: '0'},
  {name: 'OPTAT', value: '1'},
  {name: '4P', value: '2'},
  {name: '2P', value: '3'},
  {name: 'MCO', value: '5'},
]
export const FARE_TYPE = [
  {name: 'NOM', value: 'NOM'},
  {name: 'PEX', value: 'PEX'},
  {name: 'GIT', value: 'GIT'},
  {name: 'IIT', value: 'IIT'},
  {name: 'OTH', value: 'OTH'},
  {name: 'CHT', value: 'CHT'},
  {name: '　', value: ''},
]
export const FARE_CD = [
  {name: 'NOM', value: '0'},
  {name: 'PEX', value: '5'},
  {name: 'GIT', value: '1'},
  {name: 'IIT', value: '2'},
  {name: 'OTH', value: '3'},
  {name: 'CHT', value: '4'},
]
export const CRS = [
  {name: 'AXESS', value: '0'},
  {name: 'INFINI', value: '1'},
  {name: 'TRAVELPORT', value: '2'},
  {name: 'SABRE', value: '3'},
  {name: 'OTHER', value: '4'},
  {name: 'AMADEUS', value: '5'},
  {name: 'WORLDSPAN', value: '6'},
  // {name: 'TRAVELPORT', value: '3'},
]
export const IN_INPUT_TYPE = [
  {name: 'Plusクライアント', value: '0'},
  {name: 'リモートフォルダ', value: '1'},
]
export const OUT_FORMAT = [
  {name: 'IFD2AAAA(固定長)', value: '1'},
  {name: 'IFD6AAAA(固定長)', value: '2'},
  {name: 'URI(csv)', value: '3'},
  {name: '新URI(csv)', value: '4'},
]
export const OUT_PEX = [
  {name: 'そのまま出力', value: '0'},
  {name: 'OTHに変換して出力', value: '1'},
]
export const OUT_GROSS_NET_SAME = [
  {name: 'NR-IDを外す（NET精算処理しない）', value: '0'},
  {name: 'NR-IDを外さない（そのまま出力）', value: '1'},
]
export const REF_TYPE = [
  {name: '　', value: ' '},
  {name: 'A', value: 'A'},
  {name: 'F', value: 'F'},
  {name: 'L', value: 'L'},
  {name: 'M', value: 'M'},
]
export const EMD_ID_MAP = [
  {name: '　', id: ' '},
  {name: 'C', id: 'C'},
]

export const EXCEL_OUT_FORMAT = [
  {name: 'XLSX', value: '4'},
  {name: 'CSV', value: '2'},
]

export const EXCEL_OUT_FORMAT_TIMER = [
  {name: 'CSV', value: '2'},
]

export const TKT_XLSINI_HEADER = [
  {name: 'する', value: '1'},
  {name: 'しない', value: '0'},
]

export const CLIENT_OUT_FILE = [
  {name: '追加', value: '1'},
  {name: '置換え', value: '2'},
  {name: 'ファイル追加', value: '3'},
]
export const CLIENT_OUT_FILE_PATTERN = [
  {name: 'DDHHMMSS', value: '3'},
  {name: 'YYYYMMDDHHMMSS', value: '4'},
]

export const S3_OUT_FILE = [
  {name: '置換え', value: '2'},
  {name: 'ファイル追加', value: '3'},
]
export const S3_OUT_FILE_PATTERN = [
  {name: 'DDHHMMSS', value: '3'},
  {name: 'YYYYMMDDHHMMSS', value: '4'},
]

export const TKT_XLSINI_CSMTAX = [
  {name: 'する', value: '1'},
  {name: 'しない', value: '0'},
]

export const TKT_XLSINI_PRD = [
  {name: '判定する', value: '1'},
  {name: '判定しない', value: '0'},
]

export const TKT_XLSINI_PRD_EX = [
  {name: '出力する', value: '1'},
  {name: '出力しない', value: '0'},
]

export const TKT_XLSINI_PRD_KB0 = [
  {name: '出力する', value: '1'},
  {name: '出力しない', value: '0'},
]

export const TKT_XLSINI_PLUS_TYPE = [
  {name: '標準形式', value: '1'},
  {name: 'URI-Pro Plus形式', value: '0'},
]

export const TKT_XLSINI_GROSS_NET_SAME = [
  {name: 'NR-IDを外す（NET精算処理しない）', value: '0'},
  {name: 'NR-IDを外さない（そのまま出力）', value: '1'},
]

export const TKT_XLSINI_PCC = [
  {name: '最大6桁を出力', value: '0'},
  {name: '最大9桁を出力', value: '1'},
]

export const TKT_XLSINI_TOTAL = [
  {name: '先頭フライトのみ', value: '1'},
  {name: '国際線第1フライトのみ', value: '2'},
  {name: 'すべて', value: '3'},
]

export const TKT_XLSINI_FILE_FLAG = [
  {name: '　', value: '0'},
  {name: '　', value: '1'},
]

export const TKT_OVO_ID = [
  {name: 'マニュアルVOID', value: '0'},
  {name: 'オンラインVOID', value: '1'},
]

export const TKT_AT_REFID = [
  {name: 'マニュアルREFUND', value: '0'},
  {name: 'AUTO REFUND', value: '1'},
]

export const TKT_EMD_MPD_FLAG = [
  {name: '券種をEMDで出力', value: '0'},
  {name: '券種をMPDで出力', value: '1'},
]

export const SETTINGS = [
  
]

export const FORM_MODE = [
  {name: '新規', value: '0'},
  {name: '編集', value: '1'},
  {name: '複製', value: '2'},
  {name: '参照', value: '3'},
]
export const XO_MAP = [
  {name: '　', id: ' '},
  {name: 'X', id: 'X'},
]

export const LIST_ITEMS = {
  flight: [
    {
      id: 'fltFltNo',
      header: ' ',
      format: null,
      dataType: 'Number',
      isReadOnly: true,
      // isEditable: false,
      isRequired: false,
      maxLength: 13,
      width: 29,
      minWidth: 29,
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'fltTktno',
      header: 'TKT NO.',
      format: null,
      isReadOnly: true,
      // isEditable: false,
      isRequired: false,
      maxLength: 13,
      width: 120,
      minWidth: 120,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'fltCpnNo',
      header: 'CPN NO.',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 1,
      width: 41,
      minWidth: 41,
      allowSorting: false,
      allowResizing: true,
      align: 'center',
      bulkEdit: false,
      stringcase: '',
      validtype: ['num'],
    },
    {
      id: 'fltStopoverCode',
      header: 'X/O',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 1,
      width: 41,
      minWidth: 41,
      allowSorting: false,
      allowResizing: true,
      align: 'center',
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'fltAirlineCd',
      header: 'A/L',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 2,
      width: 35,
      minWidth: 35,
      allowSorting: false,
      allowResizing: true,
      align: 'center',
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['halfWidth'],
    },
    {
      id: 'fltFltNum',
      header: 'FLT NO.',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 5,
      width: 52,
      minWidth: 52,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['halfWidth'],
      align: 'right',
    },
    {
      id: 'fltClass',
      header: 'CLS',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 2,
      width: 45,
      minWidth: 45,
      allowSorting: false,
      allowResizing: true,
      align: 'center',
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['halfWidth'],
    },
    {
      id: 'fltFrom',
      header: '出発地',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 3,
      width: 52,
      minWidth: 52,
      allowSorting: false,
      allowResizing: true,
      align: 'center',
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['halfWidth'],
    },
    {
      id: 'fltTo',
      header: '到着地',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 3,
      width: 52,
      minWidth: 52,
      allowSorting: false,
      allowResizing: true,
      align: 'center',
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['halfWidth'],
    },
    {
      id: 'fltFareBasis',
      header: 'FARE BASIS',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 15,
      width: '*',
      minWidth: 135,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['halfWidth'],
    },
    {
      id: 'fltBoardDate',
      header: '搭乗日',
      format: 'yyyy/MM/dd',
      dataType: 'Date',
      date: true,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 10,
      width: 88,
      minWidth: 88,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'fltBoardTime',
      header: '搭乗時間',
      format: 'HH:mm',
      dataType: 'Date',
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 5,
      width: 48,
      minWidth: 48,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'fltArrDate',
      header: '到着日',
      format: 'yyyy/MM/dd',
      dataType: 'Date',
      date: true,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 10,
      width: 88,
      minWidth: 88,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'fltArrTime',
      header: '到着時間',
      format: 'HH:mm',
      dataType: 'Date',
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 5,
      width: 48,
      minWidth: 48,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'fltNvb',
      header: 'NVB',
      format: 'yyyy/MM/dd',
      dataType: 'Date',
      date: true,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 8,
      width: 88,
      minWidth: 88,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'fltNva',
      header: 'NVA',
      format: 'yyyy/MM/dd',
      dataType: 'Date',
      date: true,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 8,
      width: 88,
      minWidth: 88,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'fltBagAlw',
      header: 'BGG',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 3,
      width: 42,
      minWidth: 42,
      allowSorting: false,
      allowResizing: true,
      align: 'center',
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['alphanum'],
    },
    {
      id: 'fltKb1',
      header: 'KB金額',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      dataType: 'Number',
      min: 0,
      max: 99999999,
      maxLength: 8,
      width: 86,
      minWidth: 86,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      validtype: [],
    },
    {
      id: 'fltReservationStatus',
      header: '予約STS',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 2,
      width: 52,
      minWidth: 52,
      allowSorting: false,
      allowResizing: true,
      align: 'center',
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['halfWidth'],
    },
  ],
  tax: [
    {
      id: 'taxTaxNo',
      header: 'NO',
      dataType: 'Number',
      format: null,
      isReadOnly: false,
      isEditable: false,
      isRequired: false,
      maxLength: 1,
      width: 65,
      minWidth: 65,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'taxTaxAmt',
      header: 'AMOUNT',
      dataType: 'Number',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 7,
      min: 0,
      max: 9999999,
      width: 100,
      minWidth: 100,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'taxTaxInd',
      header: 'INDICATOR',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 6,
      width: 100,
      minWidth: 100,
      allowSorting: false,
      allowResizing: true,
      align: 'center',
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['halfWidth'],
    },
  ],
  coupon: [
    {
      id: 'tktTktno',
      header: 'TKT NO.',
      format: null,
      isReadOnly: false,
      isEditable: false,
      isRequired: false,
      maxLength: 1,
      width: 120,
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'tktCpnNo1',
      header: '1',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 20,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'tktCpnNo2',
      header: '2',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 20,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'tktCpnNo3',
      header: '3',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 20,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'tktCpnNo4',
      header: '4',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 20,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
  ],
  refundTax: [
    {
      id: 'rtxRftaxNo',
      header: 'NO',
      format: null,
      isReadOnly: false,
      isEditable: false,
      isRequired: false,
      maxLength: 1,
      width: 45,
      minWidth: 45,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'rtxRftaxAmt',
      header: 'AMOUNT',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      dataType: 'Number',
      min: 0,
      max: 9999999,
      maxLength: 7,
      width: 100,
      minWidth: 100,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'rtxRftaxInd',
      header: 'INDICATOR',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 2,
      width: 100,
      minWidth: 100,
      allowSorting: false,
      allowResizing: true,
      align: 'center',
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['halfWidth'],
    },
  ],
  emd: [
    {
      id: 'emdNo',
      header: ' ',
      format: null,
      dataType: 'Number',
      isReadOnly: true,
      // isEditable: false,
      isRequired: false,
      maxLength: 13,
      width: 30,
      minWidth: 30,
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'emdTktno',
      header: 'TKT NO.',
      name: 'TKT NO.',
      format: null,
      isReadOnly: false,
      isEditable: false,
      isRequired: false,
      maxLength: 1,
      width: 120,
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'emdReasonIssuanceSubCode',
      header: 'RFISC',
      name: 'REASON FOR ISSUANCE SUB CODE',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 3,
      width: 60,
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['halfWidth'],
    },
    {
      id: 'emdReasonIssuanceSubCodeDesc',
      header: 'RFISC DESCRIPTION',
      name: 'REASON FOR ISSUANCE SUB CODE DESCRIPTION',
      format: null,
      isReadOnly: true,
      isEditable: true,
      isRequired: false,
      maxLength: 3,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['halfWidth'],
    },
    {
      id: 'emdCpnFieldFeeAmt',
      header: 'CPN FILED FEE AMOUNT',
      name: 'COUPON FILED FEE AMOUNT',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      // dataType: 'Number',
      // min: 0,
      // max: 999999999999,
      maxLength: 12,
      width: 180,
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: ['halfWidth'],
    },
    {
      id: 'emdCpnValueAmt',
      header: 'CPN VALUE AMOUNT',
      name: 'COUPON VALUE AMOUNT',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      // dataType: 'Number',
      // min: 0,
      // max: 999999999999,
      maxLength: 12,
      width: 180,
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: ['halfWidth'],
    },
    {
      id: 'emdConsumedIssuaranceInd',
      header: 'ID',
      name: 'CONSUMED AT ISSUANCE INDICATOR',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 1,
      width: 60,
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['halfWidth'],
    },
    {
      id: 'emdCpnRmks',
      header: 'CPN REMARKS',
      name: 'COUPON REMARKS',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 70,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: ['halfWidth'],
    },
  ],
  add: [
    {
      id: 'amtNo',
      header: ' ',
      format: null,
      dataType: 'Number',
      isReadOnly: true,
      // isEditable: false,
      isRequired: false,
      maxLength: 13,
      width: 30,
      minWidth: 30,
      allowSorting: false,
      allowResizing: false,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'amtItem',
      header: '項目名',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      maxLength: 255,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: 'upper',
      validtype: [],
    },
    {
      id: 'amtItemAmt',
      header: '金額',
      format: null,
      isReadOnly: false,
      isEditable: true,
      isRequired: false,
      dataType: 'Number',
      min: 0,
      max: 999999999,
      maxLength: 9,
      width: 100,
      minWidth: 100,
      allowSorting: false,
      allowResizing: true,
      bulkEdit: false,
      stringcase: '',
      validtype: [],
    },
  ],
  errorLog: [
    {
      id: 'tktInlogCrs',
      header: 'GDS区分',
      format: null,
      isWhite: true,
      isEditable: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'tktInlogTktno',
      header: 'TKT NO.',
      format: null,
      isWhite: true,
      isEditable: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'tktInlogErritem',
      header: 'エラー項目',
      format: null,
      isWhite: true,
      isEditable: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      stringcase: '',
      validtype: [],
    },
    {
      id: 'tktInlogErrval',
      header: 'エラー値',
      format: null,
      isWhite: true,
      isEditable: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      stringcase: '',
      validtype: [],
    },
  ],
  excel_output: [
    // {
    //   id: 'no',
    //   header: 'No',
    //   format: null,
    //   isEditable: false,
    //   isRequired: false,
    //   maxLength: 1,
    //   width: 30,
    //   minWidth: 50,
    //   allowSorting: false,
    //   allowResizing: true,
    //   stringcase: '',
    //   validtype: [],
    // },
    {
      id: 'itemFrmDispName',
      header: '出力項目',
      format: null,
      isEditable: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 50,
      allowSorting: false,
      allowResizing: true,
      stringcase: '',
      validtype: [],
    },
  ],
  settings: [
    {
      id: 'header',
      header: '出力項目',
      format: null,
      isEditable: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 100,
      allowSorting: false,
      allowResizing: true,
      stringcase: '',
      validtype: [],
    },
  ],
  excelSettings: [
    {
      id: 'itemFrmDispName',
      header: '出力項目',
      format: null,
      isEditable: false,
      isRequired: false,
      maxLength: 1,
      width: '*',
      minWidth: 100,
      allowSorting: false,
      allowResizing: true,
      stringcase: '',
      validtype: [],
    },
  ]
}

export const DETAIL_SETTINGS = {
  calc: {
    id: '_calcDispFlg',
    dto: 'dtlCategoryCheck',
    label: '運賃・精算',
    className: 'width-p-49',
    selected: '1',
    items: [
      {id: 'tktGsCash', name: 'GROSS CASH', odto: 'tktGsCash', idto: 'dtlGsCash', selected: true},
      {id: 'tktGsCredit', name: 'GROSS CREDIT', odto: 'tktGsCredit', idto: 'dtlGsCredit', selected: true},
      {id: 'tktTaxCash', name: 'TAX CASH', odto: 'tktTaxCash', idto: 'dtlTaxCash', selected: true},
      {id: 'tktTaxCredit', name: 'TAX CREDIT', odto: 'tktTaxCredit', idto: 'dtlTaxCredit', selected: true},
      {id: 'tktCreditCom', name: 'CARD No.', odto: 'tktCreditComAndCardNum', idto: 'dtlCardNum', selected: true},
      // {id: 'tktCardNum', name: '', odto: 'tktCreditComAndCardNum', idto: 'dtlCardNum', selected: true},
      {id: 'tktCommRate', name: 'COMM RATE', odto: 'tktCommRate', idto: 'dtlCommRate', selected: true},
      {id: 'tktCommP', name: 'COMM額', odto: 'tktCommAmount', idto: 'dtlCommission', selected: true},
      {id: 'tktCommAmtCost', name: 'COMM額消費税', odto: 'tktCommAmountTax', idto: 'dtlCommTax', selected: true},
      {id: 'tktCommTaxin', name: 'COMM額（税込）', odto: 'tktCommAmountIncludeTax', idto: 'dtlCommIncludetax', selected: true},
      {id: 'tktSrcPrice', name: 'TKT原価', odto: 'tktCost', idto: 'dtlCost', selected: true},
      {id: 'tktSalesAmt', name: 'SALES AMOUNT', odto: 'tktSalesAmount', idto: 'dtlSalesAmt', selected: true},
      {id: 'tktNsb', name: 'NSB額', odto: 'tktNsb', idto: 'dtlNsb', selected: true},
      {id: 'tkt2aNet', name: '2A NET額', odto: 'tkt2aNet', idto: 'dtl2aNet', selected: true},
      {id: 'tktBspP', name: 'BSP請求額', odto: 'tktBspBillingAmount', idto: 'dtlBsp', selected: true},
      {id: 'tktBspTaxin', name: 'BSP請求額（税込）', odto: 'tktBspBillingAmountIncludeTax', idto: 'dtlBspIncludetax', selected: true},
      {id: 'tktNrid', name: 'NR-ID', odto: 'tktNrid', idto: 'dtlNrid', selected: true},
      {id: 'tktItno', name: 'IT-NO', odto: 'tktItno', idto: 'dtlItno', selected: true},
      {id: 'tktVc', name: 'VC/CAR', odto: 'tktVcCar', idto: 'dtlVcCar', selected: true},
      // {id: 'tktCar', name: '', odto: 'tktVcCar', idto: 'dtlVcCar', selected: true},
      {id: 'tktTourCd', name: 'TOUR CODE', odto: 'tktTourCd', idto: 'dtlTourCd', selected: true},
    ],
  },
  fare: {
    id: '_fareDispFlg',
    dto: 'dtlCategoryFare',
    label: 'FARE',
    className: 'width-p-49',
    selected: '1',
    items: [
      // {id: 'tktFareCalForm', name: '', odto: '', idto: '', selected: true},
      {id: 'tktFareCalMode', name: 'FCMI', odto: 'fcmi', idto: 'dtlFareCalMode', selected: true},
      {id: 'tktFareCalPricingInd', name: 'FCPI', odto: 'fcpi', idto: 'dtlFareCalPricing', selected: true},
      {id: 'tktFare', name: 'FARE', odto: 'tktFare', idto: 'dtlFare', selected: true},
      {id: 'tktEqFapd', name: 'EQUIV FARE PAID', odto: 'tktEqFapd', idto: 'dtlEqFapd', selected: true},
      {id: 'tktTotal', name: 'TOTAL', odto: 'tktTotal', idto: 'dtlTotal', selected: true},
      {id: 'tktFareCalculation', name: 'FARE CALCULATION', odto: 'tktFareCalculation', idto: 'dtlFareCalculation', selected: true},
      {id: 'tktEndRest', name: 'ENDORSEMENT/RESTRICTIONS', odto: 'tktEndRest', idto: 'dtlEndRest', selected: true},
      {id: 'tktFormPay', name: 'FOP', odto: 'tktFormPay', idto: 'dtlFormPay', selected: true},
    ],
  },
  acc: {
    id: '_accDispFlg',
    dto: 'dtlCategory7l',
    label: 'アカウントコード',
    className: 'width-p-99',
    selected: '1',
    items: [
      {id: 'tkt7lOdrCd', name: '受注コード(R)', odto: 'tkt7lOdrCd', idto: 'dtl7lOdrCd', selected: true},
      {id: 'tkt7lSecCd', name: 'セクションコード(S)', odto: 'tkt7lSecCd', idto: 'dtl7lSecCd', selected: true},
      {id: 'tkt7lMbrCd', name: '担当者コード(A)', odto: 'tkt7lMbrCd', idto: 'dtl7lMbrCd', selected: true},
      {id: 'tkt7lComCd', name: '法人コード(C)', odto: 'tkt7lComCd', idto: 'dtl7lComCd', selected: true},
      {id: 'tkt7lAreaCd', name: '方面コード(D)', odto: 'tkt7lAreaCd', idto: 'dtl7lAreaCd', selected: true},
      {id: 'tkt7lFreeCd', name: 'FREEコード(F)', odto: 'tkt7lFreeCd', idto: 'dtl7lFreeCd', selected: true},
      {id: 'tkt7lPayCd', name: '入金コード(M)', odto: 'tkt7lPayCd', idto: 'dtl7lPayCd', selected: true},
      {id: 'tkt7lTourCd', name: 'ツアーコード(T)', odto: 'tkt7lTourCd', idto: 'dtl7lTourCd', selected: true},
      {id: 'tkt7lKb1Cd', name: 'KBコード1(K)', odto: 'tkt7lKb1Cd', idto: 'dtl7lKb1Cd', selected: true},
      {id: 'tkt7lKb2Cd', name: 'KBコード2(B)', odto: 'tkt7lKb2Cd', idto: 'dtl7lKb2Cd', selected: true},
      {id: 'tkt7lEoCd', name: 'E/Oコード(E)', odto: 'tkt7lEoCd', idto: 'dtl7lEoCd', selected: true},
    ],
  },
  flight: {
    id: '_flightDispFlg',
    dto: 'dtlCategoryFlight',
    label: 'フライト情報',
    className: 'width-p-99',
    selected: '1',
    items: [],
  },
  tax: {
    id: '_taxDispFlg',
    dto: 'dtlCategoryTax',
    label: 'TAX',
    className: 'width-p-49',
    selected: '0',
    items: [],
  },
  card: {
    id: '_cardDispFlg',
    dto: 'dtlCategoryCard',
    label: 'CARD情報',
    className: 'width-p-49',
    selected: '0',
    items: [
      {id: 'tktCaExdate', name: 'CARD有効期限', odto: 'tktCaExdate', idto: 'dtlCaExdate', selected: true},
      {id: 'tktCaMem', name: 'CARD会員名', odto: 'tktCaMem', idto: 'dtlCaMem', selected: true},
      {id: 'tktCaAprid', name: 'CARD承認カードID', odto: 'tktCaAprid', idto: 'dtlCaAprid', selected: true},
      {id: 'tktCaAprno', name: 'CARD承認番号', odto: 'tktCaAprno', idto: 'dtlCaAprno', selected: true},
      {id: 'tktCaApramt', name: 'CARD承認金額', odto: 'tktCaApramt', idto: 'dtlCaApramt', selected: true},
      {id: 'tktFuncCd', name: '機能分類コード', odto: 'tktFuncCd', idto: 'dtlFuncCd', selected: true},
      {id: 'tktRmks1', name: 'CARD REMARKS1', odto: 'tktRmks1', idto: 'dtlRmks1', selected: true},
      {id: 'tktRmks2', name: 'CARD REMARKS2', odto: 'tktRmks2', idto: 'dtlRmks2', selected: true},
      {id: 'tktRmks3', name: 'CARD REMARKS3', odto: 'tktRmks3', idto: 'dtlRmks3', selected: true},
    ],
  },
  refund: {
    id: '_refundDispFlg',
    dto: 'dtlCategoryRefund',
    label: 'REFUND',
    className: 'width-p-49',
    selected: '0',
    items: [
      {id: 'tktRefType', name: 'REFUND区分', odto: 'tktRefType', idto: 'dtlRfeKbn', selected: true},
      {id: 'tktNoCoupon', name: 'TKT NO./COUPON', odto: 'tktNoCoupon', idto: 'dtlTktnoCoupon', selected: true},
      {id: 'tktGrsFarePd', name: 'GROSS FARE PAID', odto: 'tktGrsFarePd', idto: 'dtlGrsFarePaid', selected: true},
      {id: 'tktFrUsed', name: 'GROSS FARE USED', odto: 'tktFrUsed', idto: 'dtlFrUsed', selected: true},
      {id: 'tktGrsFareRef', name: 'GROSS FARE REFUND', odto: 'tktGrsFareRef', idto: 'dtlGrsFareRef', selected: true},
      {id: 'tktTaxRef', name: 'TAX REFUND', odto: 'tktTaxRef', idto: 'dtlTaxRef', selected: true},
      {id: 'tktNetFareRef', name: 'NET FARE REFUND', odto: 'tktNetFareRef', idto: 'dtlNetFareRefund', selected: true},
      {id: 'tktLesCntPen', name: 'CANCEL PENALTY', odto: 'tktLesCntPen', idto: 'dtlLesCntPen', selected: true},
      {id: 'tktNetFare', name: 'NET FARE ADJUSTMENT', odto: 'tktNetfare', idto: 'dtlNetFare', selected: true},
      {id: 'tktTotalAmt', name: 'TOTAL AMOUNT', odto: 'tktTotalAmt', idto: 'dtlTotalAmt', selected: true},
      {id: 'tktTotalAmountNfa', name: 'TOTAL AMOUNT(NFA)', odto: 'tktTotalAmtNfa', idto: 'dtlTotalAmtNfa', selected: true},
      {id: 'tktRefTax', name: 'REFUND TAX', odto: 'tktRefTax', idto: 'dtlRefundTax', selected: true},
      {id: 'refCommRate', name: 'COMM RATE', odto: 'refCommRate', idto: 'dtlRefCommRate', selected: true},
      {id: 'refundCommAmount', name: 'COMM額', odto: 'refCommission', idto: 'dtlRefCommission', selected: true},
      {id: 'refundCommTax', name: 'COMM額消費税', odto: 'refCommTax', idto: 'dtlRefCommTax', selected: true},
      {id: 'tktBalancePayable', name: 'BALANCE PAYABLE', odto: 'balancePayable', idto: 'dtlBalancePayable', selected: true},
      {id: 'tktWaiverCode', name: 'WAVER CODE', odto: 'tktWaiverCode', idto: 'dtlWaiverCode', selected: true},
      {id: 'tktSac', name: 'SAC', odto: 'tktSac', idto: 'dtlSac', selected: true},
      {id: 'tktRefMemo', name: 'REFUND MEMO', odto: 'tktRefMemo', idto: 'dtlRefMemo', selected: true},
      {id: 'tktRefPfc', name: 'REFUND PFC(XF) DATA', odto: 'tktRefPfc', idto: 'dtlRefPfc', selected: true},
    ],
  },
  reissue: {
    id: '_reissueDispFlg',
    dto: 'dtlCategoryReissue',
    label: 'REISSUE',
    className: 'width-p-49',
    selected: '0',
    items: [
      {id: 'tktOrgIsu', name: 'ORIGINAL ISSUE', odto: 'tktOrgIsu', idto: 'dtlOrgIsu', selected: true},
      {id: 'tktIsuEx', name: 'ISSUED IN EXCHANGE', odto: 'tktIsuEx', idto: 'dtlIsuEx', selected: true},
    ],
  },
  cust: {
    id: '_custDispFlg',
    dto: 'dtlCategoryCustom',
    label: '得意先情報',
    className: 'width-p-49',
    selected: '0',
    items: [
      {id: 'tktTokuiClm', name: '得意先請求金額（入力）', odto: 'tktTokuiClmInput', idto: 'dtlTokuiAmtInput', selected: true},
      {id: 'tktTkiTokuiRate', name: '得意先%', odto: 'tktTokuiRate', idto: 'dtlTokuiRate', selected: true},
      {id: 'tktTkiKg', name: '得意先金額', odto: 'tktTokuiAmount', idto: 'dtlTokuiAmt', selected: true},
      {id: 'tktTkiShz', name: '得意先消費税', odto: 'tktTokuiAmountTax', idto: 'dtlTokuiConstax', selected: true},
      {id: 'tktTkiSeikyuKg', name: '得意先請求金額（計算値）', odto: 'tktTokuiClmCalc', idto: 'dtlTokuiClm', selected: true},
      {id: 'tktTkiJstsr', name: '自社手数料', odto: 'inHouseFee', idto: 'dtlCharge', selected: true},
    ],
  },
  other: {
    id: '_otherDispFlg',
    dto: 'dtlCategoryOther',
    label: 'その他情報',
    className: 'width-p-49',
    selected: '0',
    items: [
      {id: 'tktMemo', name: 'MEMO', odto: 'tktMemo', idto: 'dtlMemo', selected: true},
      {id: 'tktRcvDate', name: '受信日', odto: 'tktRcvDate', idto: 'dtlRcvDate', selected: true},
      {id: 'tktRstDate', name: '登録日', odto: 'tktRstDate', idto: 'dtlRstDate', selected: true},
      {id: 'tktBookingPcc', name: '予約OFFICE ID/PCC', odto: 'tktReserveOfficeidAndPcc', idto: 'dtlBookingPcc', selected: true},
      {id: 'tktTktPcc', name: '発券OFFICE ID/PCC', odto: 'tktTicketingOfficeidAndPcc', idto: 'dtlTktPcc', selected: true},
      {id: 'tktDetailIssuedFlg', name: '固定帳票出力状況', odto: 'fixedFormOutputStatus', idto: 'dtlFormOutputStatus', selected: true},
    ],
  },
  emd: {
    id: '_emdDispFlg',
    dto: 'dtlCategoryEmddata',
    label: 'EMD情報',
    className: 'width-p-99',
    selected: '0',
    items: [
      {id: 'tktReasonIssuanceCode', name: 'RFIC', odto: 'tktReasonIssuanceCode', idto: 'dtlReasonForIssuenceCode', selected: true},
      {id: 'tktEmdRmks', name: 'EMD TKT REMARKS', odto: 'tktEmdRmks', idto: 'dtlEmdTktRmks', selected: true},
      {id: 'tktEmdCpnData', name: 'EMD CPN DATA', odto: 'emdCpnData', idto: 'dtlEmdCpnData', selected: true},
    ],
  },
  add: {
    id: '_addDispFlg',
    dto: 'dtlCategoryAmtitem',
    label: '各種追加料金',
    className: 'width-p-49',
    selected: '0',
    items: [],
  },
}
export const INIT_DETAIL_SETTINGS = {
  calc: {
    id: '_calcDispFlg',
    dto: 'dtlCategoryCheck',
    label: '運賃・精算',
    className: 'width-p-49',
    selected: '1',
    items: [
      {id: 'tktGsCash', name: 'GROSS CASH', odto: 'tktGsCash', idto: 'dtlGsCash', selected: true},
      {id: 'tktGsCredit', name: 'GROSS CREDIT', odto: 'tktGsCredit', idto: 'dtlGsCredit', selected: true},
      {id: 'tktTaxCash', name: 'TAX CASH', odto: 'tktTaxCash', idto: 'dtlTaxCash', selected: true},
      {id: 'tktTaxCredit', name: 'TAX CREDIT', odto: 'tktTaxCredit', idto: 'dtlTaxCredit', selected: true},
      {id: 'tktCreditCom', name: 'CARD No.', odto: 'tktCreditComAndCardNum', idto: 'dtlCardNum', selected: true},
      // {id: 'tktCardNum', name: '', odto: 'tktCreditComAndCardNum', idto: 'dtlCardNum', selected: true},
      {id: 'tktCommRate', name: 'COMM RATE', odto: 'tktCommRate', idto: 'dtlCommRate', selected: true},
      {id: 'tktCommP', name: 'COMM額', odto: 'tktCommAmount', idto: 'dtlCommission', selected: true},
      {id: 'tktCommAmtCost', name: 'COMM額消費税', odto: 'tktCommAmountTax', idto: 'dtlCommTax', selected: true},
      {id: 'tktCommTaxin', name: 'COMM額（税込）', odto: 'tktCommAmountIncludeTax', idto: 'dtlCommIncludetax', selected: true},
      {id: 'tktSrcPrice', name: 'TKT原価', odto: 'tktCost', idto: 'dtlCost', selected: true},
      {id: 'tktSalesAmt', name: 'SALES AMOUNT', odto: 'tktSalesAmount', idto: 'dtlSalesAmt', selected: true},
      {id: 'tktNsb', name: 'NSB額', odto: 'tktNsb', idto: 'dtlNsb', selected: true},
      {id: 'tkt2aNet', name: '2A NET額', odto: 'tkt2aNet', idto: 'dtl2aNet', selected: true},
      {id: 'tktBspP', name: 'BSP請求額', odto: 'tktBspBillingAmount', idto: 'dtlBsp', selected: true},
      {id: 'tktBspTaxin', name: 'BSP請求額（税込）', odto: 'tktBspBillingAmountIncludeTax', idto: 'dtlBspIncludetax', selected: true},
      {id: 'tktNrid', name: 'NR-ID', odto: 'tktNrid', idto: 'dtlNrid', selected: true},
      {id: 'tktItno', name: 'IT-NO', odto: 'tktItno', idto: 'dtlItno', selected: true},
      {id: 'tktVc', name: 'VC/CAR', odto: 'tktVcCar', idto: 'dtlVcCar', selected: true},
      // {id: 'tktCar', name: '', odto: 'tktVcCar', idto: 'dtlVcCar', selected: true},
      {id: 'tktTourCd', name: 'TOUR CODE', odto: 'tktTourCd', idto: 'dtlTourCd', selected: true},
    ],
  },
  fare: {
    id: '_fareDispFlg',
    dto: 'dtlCategoryFare',
    label: 'FARE',
    className: 'width-p-49',
    selected: '1',
    items: [
      // {id: 'tktFareCalForm', name: '', odto: '', idto: '', selected: true},
      {id: 'tktFareCalMode', name: 'FCMI', odto: 'fcmi', idto: 'dtlFareCalMode', selected: true},
      {id: 'tktFareCalPricingInd', name: 'FCPI', odto: 'fcpi', idto: 'dtlFareCalPricing', selected: true},
      {id: 'tktFare', name: 'FARE', odto: 'tktFare', idto: 'dtlFare', selected: true},
      {id: 'tktEqFapd', name: 'EQUIV FARE PAID', odto: 'tktEqFapd', idto: 'dtlEqFapd', selected: true},
      {id: 'tktTotal', name: 'TOTAL', odto: 'tktTotal', idto: 'dtlTotal', selected: true},
      {id: 'tktFareCalculation', name: 'FARE CALCULATION', odto: 'tktFareCalculation', idto: 'dtlFareCalculation', selected: true},
      {id: 'tktEndRest', name: 'ENDORSEMENT/RESTRICTIONS', odto: 'tktEndRest', idto: 'dtlEndRest', selected: true},
      {id: 'tktFormPay', name: 'FOP', odto: 'tktFormPay', idto: 'dtlFormPay', selected: true},
    ],
  },
  acc: {
    id: '_accDispFlg',
    dto: 'dtlCategory7l',
    label: 'アカウントコード',
    className: 'width-p-99',
    selected: '1',
    items: [
      {id: 'tkt7lOdrCd', name: '受注コード(R)', odto: 'tkt7lOdrCd', idto: 'dtl7lOdrCd', selected: true},
      {id: 'tkt7lSecCd', name: 'セクションコード(S)', odto: 'tkt7lSecCd', idto: 'dtl7lSecCd', selected: true},
      {id: 'tkt7lMbrCd', name: '担当者コード(A)', odto: 'tkt7lMbrCd', idto: 'dtl7lMbrCd', selected: true},
      {id: 'tkt7lComCd', name: '法人コード(C)', odto: 'tkt7lComCd', idto: 'dtl7lComCd', selected: true},
      {id: 'tkt7lAreaCd', name: '方面コード(D)', odto: 'tkt7lAreaCd', idto: 'dtl7lAreaCd', selected: true},
      {id: 'tkt7lFreeCd', name: 'FREEコード(F)', odto: 'tkt7lFreeCd', idto: 'dtl7lFreeCd', selected: true},
      {id: 'tkt7lPayCd', name: '入金コード(M)', odto: 'tkt7lPayCd', idto: 'dtl7lPayCd', selected: true},
      {id: 'tkt7lTourCd', name: 'ツアーコード(T)', odto: 'tkt7lTourCd', idto: 'dtl7lTourCd', selected: true},
      {id: 'tkt7lKb1Cd', name: 'KBコード1(K)', odto: 'tkt7lKb1Cd', idto: 'dtl7lKb1Cd', selected: true},
      {id: 'tkt7lKb2Cd', name: 'KBコード2(B)', odto: 'tkt7lKb2Cd', idto: 'dtl7lKb2Cd', selected: true},
      {id: 'tkt7lEoCd', name: 'E/Oコード(E)', odto: 'tkt7lEoCd', idto: 'dtl7lEoCd', selected: true},
    ],
  },
  flight: {
    id: '_flightDispFlg',
    dto: 'dtlCategoryFlight',
    label: 'フライト情報',
    className: 'width-p-99',
    selected: '1',
    items: [],
  },
  tax: {
    id: '_taxDispFlg',
    dto: 'dtlCategoryTax',
    label: 'TAX',
    className: 'width-p-49',
    selected: '0',
    items: [],
  },
  card: {
    id: '_cardDispFlg',
    dto: 'dtlCategoryCard',
    label: 'CARD情報',
    className: 'width-p-49',
    selected: '0',
    items: [
      {id: 'tktCaExdate', name: 'CARD有効期限', odto: 'tktCaExdate', idto: 'dtlCaExdate', selected: true},
      {id: 'tktCaMem', name: 'CARD会員名', odto: 'tktCaMem', idto: 'dtlCaMem', selected: true},
      {id: 'tktCaAprid', name: 'CARD承認カードID', odto: 'tktCaAprid', idto: 'dtlCaAprid', selected: true},
      {id: 'tktCaAprno', name: 'CARD承認番号', odto: 'tktCaAprno', idto: 'dtlCaAprno', selected: true},
      {id: 'tktCaApramt', name: 'CARD承認金額', odto: 'tktCaApramt', idto: 'dtlCaApramt', selected: true},
      {id: 'tktFuncCd', name: '機能分類コード', odto: 'tktFuncCd', idto: 'dtlFuncCd', selected: true},
      {id: 'tktRmks1', name: 'CARD REMARKS1', odto: 'tktRmks1', idto: 'dtlRmks1', selected: true},
      {id: 'tktRmks2', name: 'CARD REMARKS2', odto: 'tktRmks2', idto: 'dtlRmks2', selected: true},
      {id: 'tktRmks3', name: 'CARD REMARKS3', odto: 'tktRmks3', idto: 'dtlRmks3', selected: true},
    ],
  },
  refund: {
    id: '_refundDispFlg',
    dto: 'dtlCategoryRefund',
    label: 'REFUND',
    className: 'width-p-49',
    selected: '0',
    items: [
      {id: 'tktRefType', name: 'REFUND区分', odto: 'tktRefType', idto: 'dtlRfeKbn', selected: true},
      {id: 'tktNoCoupon', name: 'TKT NO./COUPON', odto: 'tktNoCoupon', idto: 'dtlTktnoCoupon', selected: true},
      {id: 'tktGrsFarePd', name: 'GROSS FARE PAID', odto: 'tktGrsFarePd', idto: 'dtlGrsFarePaid', selected: true},
      {id: 'tktFrUsed', name: 'GROSS FARE USED', odto: 'tktFrUsed', idto: 'dtlFrUsed', selected: true},
      {id: 'tktGrsFareRef', name: 'GROSS FARE REFUND', odto: 'tktGrsFareRef', idto: 'dtlGrsFareRef', selected: true},
      {id: 'tktTaxRef', name: 'TAX REFUND', odto: 'tktTaxRef', idto: 'dtlTaxRef', selected: true},
      {id: 'tktNetFareRef', name: 'NET FARE REFUND', odto: 'tktNetFareRef', idto: 'dtlNetFareRefund', selected: true},
      {id: 'tktLesCntPen', name: 'CANCEL PENALTY', odto: 'tktLesCntPen', idto: 'dtlLesCntPen', selected: true},
      {id: 'tktNetFare', name: 'NET FARE ADJUSTMENT', odto: 'tktNetfare', idto: 'dtlNetFare', selected: true},
      {id: 'tktTotalAmt', name: 'TOTAL AMOUNT', odto: 'tktTotalAmt', idto: 'dtlTotalAmt', selected: true},
      {id: 'tktTotalAmountNfa', name: 'TOTAL AMOUNT(NFA)', odto: 'tktTotalAmtNfa', idto: 'dtlTotalAmtNfa', selected: true},
      {id: 'tktRefTax', name: 'REFUND TAX', odto: 'tktRefTax', idto: 'dtlRefundTax', selected: true},
      {id: 'refCommRate', name: 'COMM RATE', odto: 'refCommRate', idto: 'dtlRefCommRate', selected: true},
      {id: 'refundCommAmount', name: 'COMM額', odto: 'refCommission', idto: 'dtlRefCommission', selected: true},
      {id: 'refundCommTax', name: 'COMM額消費税', odto: 'refCommTax', idto: 'dtlRefCommTax', selected: true},
      {id: 'tktBalancePayable', name: 'BALANCE PAYABLE', odto: 'balancePayable', idto: 'dtlBalancePayable', selected: true},
      {id: 'tktWaiverCode', name: 'WAVER CODE', odto: 'tktWaiverCode', idto: 'dtlWaiverCode', selected: true},
      {id: 'tktSac', name: 'SAC', odto: 'tktSac', idto: 'dtlSac', selected: true},
      {id: 'tktRefMemo', name: 'REFUND MEMO', odto: 'tktRefMemo', idto: 'dtlRefMemo', selected: true},
      {id: 'tktRefPfc', name: 'REFUND PFC(XF) DATA', odto: 'tktRefPfc', idto: 'dtlRefPfc', selected: true},
    ],
  },
  reissue: {
    id: '_reissueDispFlg',
    dto: 'dtlCategoryReissue',
    label: 'REISSUE',
    className: 'width-p-49',
    selected: '0',
    items: [
      {id: 'tktOrgIsu', name: 'ORIGINAL ISSUE', odto: 'tktOrgIsu', idto: 'dtlOrgIsu', selected: true},
      {id: 'tktIsuEx', name: 'ISSUED IN EXCHANGE', odto: 'tktIsuEx', idto: 'dtlIsuEx', selected: true},
    ],
  },
  cust: {
    id: '_custDispFlg',
    dto: 'dtlCategoryCustom',
    label: '得意先情報',
    className: 'width-p-49',
    selected: '0',
    items: [
      {id: 'tktTokuiClm', name: '得意先請求金額（入力）', odto: 'tktTokuiClmInput', idto: 'dtlTokuiAmtInput', selected: true},
      {id: 'tktTkiTokuiRate', name: '得意先%', odto: 'tktTokuiRate', idto: 'dtlTokuiRate', selected: true},
      {id: 'tktTkiKg', name: '得意先金額', odto: 'tktTokuiAmount', idto: 'dtlTokuiAmt', selected: true},
      {id: 'tktTkiShz', name: '得意先消費税', odto: 'tktTokuiAmountTax', idto: 'dtlTokuiConstax', selected: true},
      {id: 'tktTkiSeikyuKg', name: '得意先請求金額（計算値）', odto: 'tktTokuiClmCalc', idto: 'dtlTokuiClm', selected: true},
      {id: 'tktTkiJstsr', name: '自社手数料', odto: 'inHouseFee', idto: 'dtlCharge', selected: true},
    ],
  },
  other: {
    id: '_otherDispFlg',
    dto: 'dtlCategoryOther',
    label: 'その他情報',
    className: 'width-p-49',
    selected: '0',
    items: [
      {id: 'tktMemo', name: 'MEMO', odto: 'tktMemo', idto: 'dtlMemo', selected: true},
      {id: 'tktRcvDate', name: '受信日', odto: 'tktRcvDate', idto: 'dtlRcvDate', selected: true},
      {id: 'tktRstDate', name: '登録日', odto: 'tktRstDate', idto: 'dtlRstDate', selected: true},
      {id: 'tktBookingPcc', name: '予約OFFICE ID/PCC', odto: 'tktReserveOfficeidAndPcc', idto: 'dtlBookingPcc', selected: true},
      {id: 'tktTktPcc', name: '発券OFFICE ID/PCC', odto: 'tktTicketingOfficeidAndPcc', idto: 'dtlTktPcc', selected: true},
      {id: 'tktDetailIssuedFlg', name: '固定帳票出力状況', odto: 'fixedFormOutputStatus', idto: 'dtlFormOutputStatus', selected: true},
    ],
  },
  emd: {
    id: '_emdDispFlg',
    dto: 'dtlCategoryEmddata',
    label: 'EMD情報',
    className: 'width-p-99',
    selected: '0',
    items: [
      {id: 'tktReasonIssuanceCode', name: 'RFIC', odto: 'tktReasonIssuanceCode', idto: 'dtlReasonForIssuenceCode', selected: true},
      {id: 'tktEmdRmks', name: 'EMD TKT REMARKS', odto: 'tktEmdRmks', idto: 'dtlEmdTktRmks', selected: true},
      {id: 'tktEmdCpnData', name: 'EMD CPN DATA', odto: 'emdCpnData', idto: 'dtlEmdCpnData', selected: true},
    ],
  },
  add: {
    id: '_addDispFlg',
    dto: 'dtlCategoryAmtitem',
    label: '各種追加料金',
    className: 'width-p-49',
    selected: '0',
    items: [],
  },
}