import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { LIST_ITEMS, PDT_FARE_ID, TBL_TYPE, TKTKBN_MAP } from 'constants/master';
import { convert, entryCheck, alert, validation } from "lib";
import { faBullseye } from '@fortawesome/free-solid-svg-icons';

export const CopyToOtherPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  const [popup, setPopup] = React.useState(null)
  const initPopup = (form) => {
    setPopup(form)
  }

  const [alCd, setAlCd] = React.useState([])
  const [copyAlCd, setCopyAlCd] = React.useState([])
  const [copyAlCdAttr, setCopyAlCdAttr] = React.useState({
    id: 'copyFjgAlCd',
    label: 'コピー先',
    required: true,
    value: '',
    text: '',
    isInvalid: faBullseye,
    helperText: '',
  })
  const [copy, setCopy] = React.useState([])

  const onClick = (e) => {
    setCopy(props.onClick())
    if (props.onClick()) {
      popup.show(true, (sender) => {
        // if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
        //   console.log('ok')
        // } else {
        //   // setFileInvalid(false)
        //   console.log('cancel')
        // }
      })
    }
  }

  const execCopyToOther = (isCode, type) => {
    const text = copyAlCd.length === 1? copyAlCd[0].alnCd2: ''
    const check = entryCheck({copyFjgAlCd: copyAlCdAttr}, convert.getTarget(copyAlCdAttr, 'text', text))
    setCopyAlCdAttr(check.copyFjgAlCd)

    if (check.copyFjgAlCd.isInvalid) return
    
    if (alCd[0].alnCd2 === copyAlCd[0].alnCd2) {
      alert().fire({
        icon: MESSAGE.duplicateAirline().icon,
        title: MESSAGE.duplicateAirline().text,
        showCancelButton: false,
      })
      return
    }

    appAction.showProcessing({isProcessing: true})
    MasterApi.edit(isCode, type, {
      requestCodeName: type.toUpperCase(),
      requestList: copy,
      copyFjgAlCd: text,
      isUpdateIgnore: false,
      isRequestSearch: false,
      // selDate: master.selDate,
    }, 'copy').then((res) => {
      if (res.payload.code === '200') {
        alert().fire({
          icon: MESSAGE.confirmFareUpdate().icon,
          title: MESSAGE.confirmFareUpdate().text
        })
        .then((result) => {
          if (result.isConfirmed) {
            MasterApi.edit(isCode, type, {
              requestCodeName: type.toUpperCase(),
              requestList: copy,
              copyFjgAlCd: text,
              isUpdateIgnore: true,
              isRequestSearch: false,
              // selDate: master.selDate,
            }, 'copy').then((res2) => {
              if (res2.payload.messageList) {
                appAction.setMessage({message: res2.payload.messageList,})
              } else {
                appAction.setMessage('')
              }
              popup.hide('wj-hide-ok')
            })
            .catch(() =>{})
            .finally(() => {
              appAction.showProcessing({isProcessing: false})
            })
          } else {
            appAction.setMessage({message: res.payload.messageList,})
            appAction.showProcessing({isProcessing: false})
            popup.hide('wj-hide-ok')
          }
        })
      } else {
        if (res.payload.messageList) {
          appAction.setMessage({message: res.payload.messageList,})
        } else {
          appAction.setMessage('')
        }
        popup.hide('wj-hide-ok')
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  React.useEffect(() => {
    if (props.selectedItems && props.selectedItems.length > 0){
      setAlCd(props.selectedItems)
    }
  },[props.selectedItems])

  return (
    <React.Fragment>
      <Button
        {...master.copyToOther}
        className={props.className?props.className:'button-gray mt-auto ml-auto mr-auto'}
        // onClick={() => execImport(master.type)}
        onClick={(e) => onClick(e)}
        disabled={master.copyToOther.disabled || props.disabled}
      >
        {master.copyToOther.label}
      </Button>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
      >
        <Card 
          root='popup'
          size='md'
          hideClose
          header={props.header}
          headerClass='wj-dialog-header'
          subheader={props.subheader}
          // body={props.body}
          body={
            <React.Fragment>
              <div>
                <div>選択されている{copy.length}件のレコードを複写します</div>
                <Common.MultiSelect
                  attrs={{
                    id: 'fjgAlCd',
                    label: '',
                    type: 'search',
                    helpertext: '',
                    maxLength: 3,
                    minLength: 1,
                    delay: 10,
                    disabled: true,
                  }}
                  group={{
                    style: {
                      'width': 'auto',
                    },
                    className: 'mx-0 ml-3',
                  }}
                  maxSelectedItems={1}
                  minLength={1}
                  selectedValuePath='alnCd2'
                  displayMemberPath={'listName'}
                  displayName="alnNamej"
                  list={app.alList}
                  searchMemberPath={'alnCd2,alnCdn'}
                  selectedItems={props.selectedItems}
                  isEditable={false}
                />
              </div>
              <div>
                <Common.MultiSelect
                  attrs={{
                    id: 'copyFjgAlCd',
                    label: 'コピー先',
                    type: 'search',
                    helpertext: '',
                    maxLength: 3,
                    minLength: 1,
                    delay: 10,
                    required: true,
                    isInvalid: copyAlCdAttr.isInvalid,
                    helpertext: copyAlCdAttr.helpertext,
                  }}
                  group={{
                    style: {
                      'width': 'auto',
                    },
                    className: 'mx-0 ml-3',
                  }}
                  maxSelectedItems={1}
                  minLength={1}
                  selectedValuePath='alnCd2'
                  displayMemberPath={'listName'}
                  displayName="alnNamej"
                  list={app.alList}
                  searchMemberPath={'alnCd2,alnCdn'}
                  setSelectedItems={setCopyAlCd}
                  template={'<table><tr>' +
                            '<td class="width-5" title="コード 2桁">{alnCd2}</td>' +
                            '<td class="width-5" title="コード 数字">{alnCdn}</td>' +
                            '<td class="" title="名称">{alnNamej}</td>' +
                            '</tr></table>'}
                  isEditable={false}
                />
              </div>
            </React.Fragment>
          }
        />
        <Card 
          size='md'
          root={`popup-button ${props.size}`}
          // body={props.button}
          body={
            <React.Fragment>
              <Button className='button-gray mr-auto wj-hide-cancel'>
                <span className='material-icons-outlined md mr-2'>cancel</span>
                キャンセル
              </Button>
              <Button
                className='button-blue ml-auto'
                onClick={() => execCopyToOther(master.isCode, master.type)}
                disabled={app.isProcessing}
              >
                <span className='material-icons-outlined md mr-2'>file_download</span>
                OK
              </Button>
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})