import { DataMap } from "@grapecity/wijmo.grid";
import _ from 'lodash';

export const PROCESS_KIND = [
  {name: '　', value: '-1'},
  {name: 'オンライン', value: '0'},
  {name: 'タイマー', value: '1'},
]

export const PROCESS_RESULT = [
  {name: '　', value: '-1'},
  {name: '正常終了', value: '0'},
  {name: 'エラー有', value: '1'},
]

export const PROCESS_TYPE = [
  {name: '　', value: '-1'},
  {name: '発券データ入力', value: '0'},
  {name: '発券データ出力', value: '1'},
  {name: 'EXCELデータ出力', value: '2'},
  {name: 'PRISMデータ出力', value: '3'},
  // {name: 'URI-Pro BI', value: '4'},
  {name: '固定帳票出力', value: '5'},
  // {name: 'テーブルメンテナンス', value: '6'},
  {name: 'データメンテナンス', value: '7'},
]

export const LIST_ITEMS = {
  list: [
    {
      id: 'processingDateTime',
      header: '処理日時',
      format: 'yyyy年MM月dd日 HH:mm:ss',
      dataType: 'String',
      date: true,
      isReadOnly: true,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      align: 'center',
    },
    {
      id: 'processType1',
      header: '処理区分',
      format: null,
      isReadOnly: true,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      align: 'center',
      dataMap: new DataMap(PROCESS_TYPE, 'value', 'name')
    },
    {
      id: 'processKind',
      header: 'オンライン/タイマー',
      format: null,
      isReadOnly: true,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      align: 'center',
      dataMap: new DataMap(PROCESS_KIND, 'value', 'name')
    },
    {
      id: 'processingResult',
      header: '処理結果',
      format: null,
      isReadOnly: true,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      align: 'center',
      dataMap: new DataMap(PROCESS_RESULT, 'value', 'name')
    },
    {
      id: 'download',
      header: '　',
      format: null,
      isReadOnly: true,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
    },
  ],
  detail: [
    {
      id: 'processingDateTime',
      header: '処理日時',
      format: 'yyyy年MM月dd日 HH:mm:ss',
      dataType: 'String',
      date: true,
      isReadOnly: true,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      align: 'center',
    },
    {
      id: 'resultDetailSubject',
      header: '処理内容',
      format: null,
      isReadOnly: true,
      maxLength: null,
      width: '*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
      align: 'center',
    },
    {
      id: 'resultDetail',
      header: '詳細',
      format: null,
      isReadOnly: true,
      maxLength: null,
      width: '3*',
      minWidth: 10,
      allowSorting: false,
      allowResizing: false,
      stringcase: '',
      validtype: [],
    },
  ],
}