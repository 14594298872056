import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { SystemAction } from 'ducks/System';
import * as Common from 'components/common';
import { Title, Card } from 'components';
import { BizComponent as Biz } from './BizComponent';
import { MaintComponent as Maint } from './MaintComponent';
import { 
  Col,
  Row,
  Container,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { TabPanel, Tab } from '@grapecity/wijmo.react.nav';
import { entryCheck, getInitData } from "lib";
import _ from 'lodash';

import { TITLE } from 'constants/title';
import { SYSTEM_MENU } from 'constants/system';
import { MASTER, EMPTY_SEARCH } from 'constants/master';

export const SystemComponent = () => {

  const app = useSelector(state => state.App);
  const system = useSelector(state => state.System);
  const login = useSelector(state => state.Login);

  const history = useHistory();
  const location = useLocation();
  const { type } = useParams();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const systemAction = bindActionCreators(SystemAction, dispatch);

  const tabChange = (tab) => {
    const sysType = (_.findIndex(TITLE.system.type, {name: tab}) < 0)?'travel':tab
    if (type !== sysType) history.push(`${TITLE.system.mainLink}/${sysType}`)
  }


  React.useEffect(() => {
    tabChange(type)
  }, [])

  React.useEffect(() => {
    // console.log(type)
    systemAction.setInputSystem({type: type})
  }, [type])

  const generateComponent = () => {
    switch (system.type) {
      case 'travel': return <Biz />
      // case 'sys': return <Sys />
      case 'maint': return <Maint />
      // case 'user': return <div>user</div>
    }
  }

  return (
    <React.Fragment>
      <Container fluid className='main'>
        <Title title={TITLE.system.name} icon={TITLE.system.icon}/>
        <div className='systemMenuTop'>
          <Common.RadioButton
            btnSize='sm'
            attrs={{
              id: 'systemMenu',
              text: system.type,
              value: system.type,
              // disabled: !getEditable(),
              disabled: app.isProcessing || (login.auth110 && login.auth110!=='1'),
              className: 'systemMenu',
            }}
            className='w-100'
            radios={SYSTEM_MENU}
            onChange={(e) => tabChange(e.systemMenu.text)}
          />
        </div>
        <div className={`systemEdit ${system.type==='travel'?'':'d-none'}`}>
          <Biz />
        </div>
        <div className={`systemEdit ${system.type==='maint'?'':'d-none'}`}>
          <Maint />
        </div>
      </Container>
      <Common.RouteLeavingGuard
        when={true}
        navigate={(path, state) => history.push(path, state)}
        shouldBlockNavigation={(location) => {
          return (!location.state || !(location.state && location.state.nonRouteLeavingGuard))
        }}
        uiState='System'
        subState={system.type}
      />
    </React.Fragment>
  )
}