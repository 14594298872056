import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { PivotAction } from 'ducks/Pivot';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import * as Common from 'components/common';
import { CHART_TYPE } from 'constants/pivot';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';
import { SummaryApi } from 'webapi'
import { Step } from './Step';
import { Spinner } from 'react-bootstrap';

export const StepP = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const pivot = useSelector(state => state.Pivot);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);
  const pivotAction = bindActionCreators(PivotAction, dispatch);

  return (
    <React.Fragment>
      <Card 
        root='popup'
        size='lg'
        hideClose
        header='データ移行　STEP2'
        headerClass='wj-dialog-header'
        body={
          <React.Fragment>
            <Step step='2' />
            <div className='py-2 px-5'>
              <div className='step-error d-none'></div>
              <div className='step-body center mx-auto mt-4'>
                <div>データ移行中です。移行データ量によっては、処理に時間がかかります。</div>
                <div>画面を閉じず、そのままお待ちください。</div>
                <Spinner className='processing-icon' animation="border" />
              </div>
            </div>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
})