import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { TktEditAction } from 'ducks/TktEdit';
import { MasterApi } from 'webapi'
import { MESSAGE } from 'constants/message';
import { TKT_STATUS, TKT_OVO_ID, TKT_AT_REFID } from 'constants/ticket';
import * as Common from 'components/common';
import { format } from '@grapecity/wijmo';
import { entryCheck, convert } from "lib";

export const StatusPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const tktEdit = useSelector(state => state.TktEdit);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  const [confirmText, setConfirmText] = React.useState('')

  const initPopup = (form) => {
    props.setPopup(form)
  }

  const action = () => {
    const dto = {
      appCode: 'exec',
      sysDatamentIniDto: {
        ...props.popupData.data,
        // dmiPeriod: system.data.dmiPeriod.text,
        // dmiPeriodMm: system.data.dmiPeriodMm.text,
        // dmiPeriodDate: convert.toDateString(system.data.dmiPeriodDate.text, 'yyyy-MM-dd'),
        // dmiDateType: system.data.dmiDateType.text,
        // dmiOpentktType: system.data.dmiOpentktType.text,
        // dmiBackup: ,
        // dmiMbrcd: ,
        // dmiExecDatetime: ,
      },
    }

    // appAction.showProcessing({isProcessing: true})
    // SystemApi.edit(system.type, dto)
    // .then((res) => {
    //   appAction.setMessage({message: res.payload.messageList})
    // })
    // .catch(() =>{})
    // .finally(() => {
    //   props.popup.hide('ok')
    //   appAction.showProcessing({isProcessing: false})
    // })
  }

  React.useEffect(() => {
    if (props.popup && props.popup.isVisible) {
      if (tktEdit.tktVoidId.text === '1') {
        tktEditAction.setInputTktEdit({
          tktOvoId: {
            ...tktEdit.tktOvoId,
            value: '0',
            text: '0',
          },
          tktVoidDate: {
            ...tktEdit.tktVoidDate,
            required: true,
            isInvalid: '',
            helpertext: '',
          },
          tktRefId: {
            ...tktEdit.tktRefId,
            value: '0',
            text: '0',
          },
          tktAtRefid: {
            ...tktEdit.tktAtRefid,
            value: '0',
            text: '0',
          },
          tktRefDate: {
            ...tktEdit.tktRefDate,
            required: false,
            value: null,
            text: null,
            isInvalid: '',
            helpertext: '',
          },
          tktReissueId: {
            ...tktEdit.tktReissueId,
            value: '0',
            text: '0',
          }
        })
      } else {
        tktEditAction.setInputTktEdit({
          tktOvoId: {
            ...tktEdit.tktOvoId,
            value: '0',
            text: '0',
          },
          tktVoidDate: {
            ...tktEdit.tktVoidDate,
            required: false,
            value: null,
            text: null,
            isInvalid: '',
            helpertext: '',
          },
        })
      }
    }
  }, [app.isProcessing, tktEdit.tktVoidId.text])

  
  React.useEffect(() => {
    if (props.popup && props.popup.isVisible) {
      if (tktEdit.tktRefId.text === '1') {
        tktEditAction.setInputTktEdit({
          tktVoidId: {
            ...tktEdit.tktVoidId,
            value: '0',
            text: '0',
          },
          tktOvoId: {
            ...tktEdit.tktOvoId,
            value: '0',
            text: '0',
          },
          tktVoidDate: {
            ...tktEdit.tktVoidDate,
            required: false,
            value: null,
            text: null,
            isInvalid: '',
            helpertext: '',
          },
          tktAtRefid: {
            ...tktEdit.tktAtRefid,
            value: '0',
            text: '0',
          },
          tktRefDate: {
            ...tktEdit.tktRefDate,
            required: true,
            isInvalid: '',
            helpertext: '',
          },
        })
      } else {
        tktEditAction.setInputTktEdit({
          tktAtRefid: {
            ...tktEdit.tktAtRefid,
            value: '0',
            text: '0',
          },
          tktRefDate: {
            ...tktEdit.tktRefDate,
            required: false,
            value: null,
            text: null,
            isInvalid: '',
            helpertext: '',
          },
        })

      }
    }
  }, [app.isProcessing, tktEdit.tktRefId.text])

  React.useEffect(() => {
    if (props.popup && props.popup.isVisible) {
      if (tktEdit.tktVoidDate.text) {
        tktEditAction.setInputTktEdit({
          tktRefundVoidDate: {
            ...tktEdit.tktRefundVoidDate,
            text: tktEdit.tktVoidDate.text,
            value: tktEdit.tktVoidDate.value,
          }
        })
      } else {
        tktEditAction.setInputTktEdit({
          tktRefundVoidDate: {
            ...tktEdit.tktRefundVoidDate,
            text: tktEdit.tktRefDate.text,
            value: tktEdit.tktRefDate.value,
          }
        })
      }
    }
  }, [app.isProcessing, tktEdit.tktVoidDate.text, tktEdit.tktRefDate.text])

  return (
    <React.Fragment>
      <wjInput.Popup
        isDraggable={true}
        // isResizable={true}
        id={props.id}
        initialized={(form)=>initPopup(form)}
        fadeIn={false}
        fadeOut={false}
        hideTrigger='None'
        hiding={(p, e) => {
          if (p.dialogResult !== 'wj-hide-ok') e.cancel = true
        }}
      >
        <Card 
          root='popup w-50vw'
          size='lg'
          hideClose
          header='TKTステータス'
          headerClass='wj-dialog-header'
          subheader={props.subheader}
          // body={props.body}
          body={
            <React.Fragment>
              <div className='status-window d-flex flex-column p-3'>
                <Common.Select
                  attrs={{...tktEdit.tktStatus}}
                  group={{
                    className: 'width-16 mb-5 mr-0',
                  }}
                  minLength={1}
                  list={TKT_STATUS}
                  showDropDownButton={false}
                  className='ticket-status'
                  onChange={(e) => tktEditAction.changeInputTktEdit(e)}
                />
                <div className='d-flex flex-row align-items-start'>
                  <div className='pane1 mt-4 mb-3'>
                    <Common.Check
                      attrs={{...tktEdit.tktVoidId}}
                      className=''
                      onChange={(e) => tktEditAction.changeInputTktEdit(e)}
                    />
                  </div>
                  <div className='d-flex flex-column pane2'>
                    {tktEdit.tktVoidId.text === '1' &&
                      <React.Fragment>
                        <div className='mt-1'><label className='form-label'>{tktEdit.tktOvoId.label}</label></div>
                        <Common.RadioButton
                          btnSize='sm'
                          attrs={{...tktEdit.tktOvoId}}
                          className=''
                          radios={TKT_OVO_ID}
                          onChange={(e) => tktEditAction.changeInputTktEdit(e)}
                        />
                      </React.Fragment>
                    }
                  </div>
                  <div className='pane3 ml-5'>
                    {tktEdit.tktVoidId.text === '1' &&
                      <Common.DateTime
                        attrs={{...tktEdit.tktVoidDate}}
                        onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
                        group={{
                          className: 'mt-1',
                        }}
                      />
                    }
                  </div>
                </div>
                <div className='d-flex flex-row align-items-start mt-4'>
                  <div className='pane1 mt-4 mb-3'>
                    <Common.Check
                      attrs={{...tktEdit.tktRefId}}
                      onChange={(e) => tktEditAction.changeInputTktEdit(e)}
                    />
                  </div>
                  <div className='d-flex flex-column pane2'>
                    {tktEdit.tktRefId.text === '1' &&
                      <React.Fragment>
                        <div className='mt-1'><label className='form-label'>{tktEdit.tktAtRefid.label}</label></div>
                        <Common.RadioButton
                          btnSize='sm'
                          attrs={{...tktEdit.tktAtRefid}}
                          className=''
                          radios={TKT_AT_REFID}
                          onChange={(e) => tktEditAction.changeInputTktEdit(e)}
                        />
                      </React.Fragment>
                    }
                  </div>
                  <div className='pane3 ml-5'>
                    {tktEdit.tktRefId.text === '1' &&
                      <Common.DateTime
                        attrs={{...tktEdit.tktRefDate}}
                        onBlur={(e) => {tktEditAction.changeInputTktEdit(e)}}
                        group={{
                          className: 'mt-1',
                        }}
                      />
                    }
                  </div>
                </div>
                <div className='d-flex flex-row align-items-center mt-4 mb-5'>
                  <Common.Check
                    attrs={{...tktEdit.tktReissueId}}
                    className='pane1 mt-3 mb-3'
                    onChange={(e) => tktEditAction.changeInputTktEdit(e)}
                  />
                </div>
              </div>
            </React.Fragment>
          }
        />
        <Card 
          size='md'
          root={`popup-button ${props.size}`}
          // body={props.button}
          body={
            <React.Fragment>
              <Button
                className='button-gray mr-auto'
                onClick={(e) => {
                  let entry = {}
                  entry.tktVoidDate = entryCheck(tktEdit, convert.getTarget(tktEdit.tktVoidDate, 'text', tktEdit.tktVoidDate.text)).tktVoidDate
                  entry.tktRefDate = entryCheck(tktEdit, convert.getTarget(tktEdit.tktRefDate, 'text', tktEdit.tktRefDate.text)).tktRefDate
                  tktEditAction.setInputTktEdit(entry)
                  if (!entry.tktVoidDate.isInvalid && !entry.tktRefDate.isInvalid) {
                    props.popup.hide('wj-hide-ok')
                  }
                }}
              >
                閉じる
              </Button>
              {/* <Button
                className='button-blue ml-auto'
                onClick={() => action()}
                disabled={app.isProcessing}
              >
                設定
              </Button> */}
            </React.Fragment>
          }
        />
      </wjInput.Popup>
    </React.Fragment>
  )
})