import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';

import * as Common from 'components/common';
import { Card } from 'components';
import {
  OverlayTrigger,
  Tooltip,
  Col,
  Row,
  Container,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import { MultiRow } from '@grapecity/wijmo.react.grid.multirow';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { Selector } from "@grapecity/wijmo.grid.selector";
import { CollectionView, setSelectionRange, toggleClass } from "@grapecity/wijmo";
import { CellRange, DataMap } from "@grapecity/wijmo.grid";
import { InputDate, InputMask, ComboBox, AutoComplete, InputColor, InputNumber } from '@grapecity/wijmo.input';
import { TabPanel, Tab } from '@grapecity/wijmo.react.nav';
import { convert, entryCheck, alert, validation } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { LIST_ITEMS, FARE_MAP, TBL_TYPE, SPC_MAP } from 'constants/master';
import { MasterApi } from 'webapi'
import { CopyToOtherPopup } from './modal/CopyToOtherPopup'

export const FareSearchComponent = (props) => {
  
  const app = useSelector(state => state.App);
  const master = useSelector(state => state.Master);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);

  const [itemChanged, setItemChanged] = React.useState(false)
  const [grid, setGrid] = React.useState(null)
  const [selector, setSelector] = React.useState(null)
  const [selectedItems, setSelectedItems] = React.useState(null)
  const [action, setAction] = React.useState('')
  const [rowHeaderWidth, setRowHeaderWidth] = React.useState(0)
  const [colWidth, setColWidth] = React.useState([])
  const [selectedAls, setSelectedAls] = React.useState([])

  const getEditable = () => {
    return selectedAls.length > 0 && !props.disabled;
  }

  React.useEffect(() => {
    appAction.setMessage('')
    if (selectedAls.length > 0) 
      getData(master.isCode, master.type, selectedAls[0].alnCd2)
    else {
      const obj = {}
      obj.selDate = null
      obj[`${master.type}List`] = []
      appAction.setMessage('')
      masterAction.setInputMaster({...obj})
    }
  }, [selectedAls])

  const getData = (isCode, type, alnCd2) => {
    appAction.showProcessing({isProcessing: true})
    appAction.setMessage('')

    MasterApi.search(isCode, type, {requestCodeName: type.toUpperCase(), search:{fjgAlCd: alnCd2}})
    .then(res => {

      const obj = {}
      appAction.setMessage({message: res.payload.messageList})
      _.forEach(master, (v, k) => {
        if (k.endsWith('List')) obj[k] = []
      })

      if (res.payload.code === '000' || res.payload.code === '100') {
        obj.selDate = res.payload.selDate
        let dataList = [].concat(res.payload.mstFareJudgeList)
        _.forEach(dataList,(r, i) => {
          r['fjgRegNo'] = _.padStart(r['fjgRegNo'], 4, '0')
          _.forEach(_.filter(LIST_ITEMS.fare, 'date'), (record, index) => {
            r[record.id] = convert.toDateString(r[record.id], 'yyyy/MM/dd')
              ? new Date(convert.toDateString(r[record.id], 'yyyy/MM/dd'))
              : null
          })
          _.forEach(_.filter(LIST_ITEMS.fare, 'number'), (record, index) => {
            r[record.id] = r[record.id]?r[record.id]:null
          })
          _.forEach(_.filter(LIST_ITEMS.fare, 'checkbox'), (record, index) => {
            r[record.id] = r[record.id] === '1'
          })
        })
        obj[`${type}List`] = dataList
      }
      masterAction.setInputMaster({...obj})
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  React.useEffect(() => {
    appAction.setMessage('')
    const obj = {}
    _.forEach(master, (v, k) => {
      if (k.endsWith('List')) obj[k] = []
    })
    masterAction.setInputMaster({...obj})
    if (grid) {
      grid.select(-1, -1)
      setTimeout(() => {
        (grid.rows.length > 0)?grid.select(0, 0):grid.select(-1, 0)
      })
    }
  }, [master.type])

  const execDelete = (isCode, type) => {
    const selectList = []
    const isNewSelectList = []
    
    //新規行は強制的に選択状態
    _.forEach(grid.rows, (r, i) => {
      if (r.dataItem && r.dataItem.isAddingNew) {
        r.isSelected = true
      }
    })
    grid.refresh()

    _.forEach(master[`${type}List`].filter(r => r.isSelected), (r, i) =>{
      if (!r.isAddingNew) {
        selectList.push({...r})
      } else isNewSelectList.push({...r})
    })
    if (isNewSelectList.length < 1 && selectList.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return 
    }
    alert().fire({
      icon: MESSAGE.confirmDeleteList().icon,
      title: MESSAGE.confirmDeleteList(master[`${type}List`].filter(r => r.isSelected).length).text
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (selectList.length > 0) {
          _.forEach(selectList, (r, i) => {
            r.appCode = 'delete'
            r.fjgAlCd = selectedAls[0].alnCd2
            _.forEach(_.filter(LIST_ITEMS.fare, 'date'), (record, index) => {
              if (r[record.id]) {
                r[record.id] = convert.toDateString(new Date(r[record.id]), 'yyyy-MM-dd')
              }
            })
            _.forEach(_.filter(LIST_ITEMS.fare, 'checkbox'), (record, index) => {
              r[record.id] = r[record.id]?'1':'0'
            })
            _.forEach(_.filter(LIST_ITEMS.fare, 'number'), (record, index) => {
              r[record.id] = r[record.id]?r[record.id]:null
            })
          })
          appAction.showProcessing({isProcessing: true})
          MasterApi.edit(isCode, type, {
            requestCodeName: type.toUpperCase(),
            isRequestSearch: true,
            search: {fjgAlCd: selectedAls[0].alnCd2},
            requestList: selectList,
            selDate: master.selDate,
          })
          .then((res) => {
            appAction.setMessage({message: res.payload.messageList})
            if (res.payload.code === '000') {
              const obj = {}
              obj.selDate = res.payload.selDate
              let dataList = [].concat(res.payload.mstFareJudgeList)
              _.forEach(dataList,(r, i) => {
                r['fjgRegNo'] = _.padStart(r['fjgRegNo'], 4, '0')
                _.forEach(_.filter(LIST_ITEMS.fare, 'date'), (record, index) => {
                  r[record.id] = convert.toDateString(r[record.id], 'yyyy/MM/dd')
                    ? new Date(convert.toDateString(r[record.id], 'yyyy/MM/dd'))
                    : null
                })
                _.forEach(_.filter(LIST_ITEMS.fare, 'number'), (record, index) => {
                  r[record.id] = r[record.id]?r[record.id]:null
                })
                _.forEach(_.filter(LIST_ITEMS.fare, 'checkbox'), (record, index) => {
                  r[record.id] = r[record.id] === '1'
                })
              })
              obj[`${type}List`] = dataList
              masterAction.setInputMaster({...obj})
            }
          })
          .catch(() =>{})
          .finally(() => {
            appAction.showProcessing({isProcessing: false})
          })
        } else {
          getData(master.isCode, master.type, selectedAls[0].alnCd2)
        }
      }
    });
  }
  
  const execSave = (isCode, type) => {
    const err = _.filter(master[`${master.type}List`], (r) => r.hasError)
    const editList = []
    _.forEach(master[`${master.type}List`], (r) => {
      if (r.isAddingNew) editList.push({...r, appCode: 'insert'})
      if (!r.isAddingNew && r.isEdit) editList.push({...r, appCode: 'update'})
    })
    _.forEach(editList, (r) => {
      r.fjgAlCd = selectedAls[0].alnCd2
      _.forEach(_.filter(LIST_ITEMS.fare, 'date'), (record, index) => {
        if (r[record.id])
          r[record.id] = convert.toDateString(new Date(r[record.id]), 'yyyy-MM-dd')
      })
      _.forEach(_.filter(LIST_ITEMS.fare, 'checkbox'), (record, index) => {
        r[record.id] = r[record.id]?'1':'0'
      })
      _.forEach(_.filter(LIST_ITEMS.fare, 'number'), (record, index) => {
        r[record.id] = r[record.id]?r[record.id]:null
      })
    })

    if (err.length > 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.hasError().id,
        message: MESSAGE.hasError().text,
        type: MESSAGE.hasError().icon,
      }]})
      return
    }
    if (editList.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.noTarget().id,
        message: MESSAGE.noTarget().text,
        type: MESSAGE.noTarget().icon,
      }]})
      return
    }

    appAction.showProcessing({isProcessing: true})
    MasterApi.edit(isCode, type, {
      requestCodeName: type.toUpperCase(),
      isUpdateIgnore: true,
      isRequestSearch: true,
      search: {fjgAlCd: selectedAls[0].alnCd2},
      requestList: editList,
      selDate: master.selDate,
    })
    .then((res) => {
      appAction.setMessage({message: res.payload.messageList})
      if (res.payload.code === '000') {
        const obj = {}
        obj.selDate = res.payload.selDate
        let dataList = [].concat(res.payload.mstFareJudgeList)
        _.forEach(dataList,(r, i) => {
          r['fjgRegNo'] = _.padStart(r['fjgRegNo'], 4, '0')
          _.forEach(_.filter(LIST_ITEMS.fare, 'date'), (record, index) => {
            r[record.id] = convert.toDateString(r[record.id], 'yyyy/MM/dd')
              ? new Date(convert.toDateString(r[record.id], 'yyyy/MM/dd'))
              : null
          })
          _.forEach(_.filter(LIST_ITEMS.fare, 'number'), (record, index) => {
            r[record.id] = r[record.id]?r[record.id]:null
          })
          _.forEach(_.filter(LIST_ITEMS.fare, 'checkbox'), (record, index) => {
            r[record.id] = r[record.id] === '1'
          })
        })
        obj[`${type}List`] = dataList
        masterAction.setInputMaster({...obj})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  const execCsv = (isCode, type) => {
    const csv = []
    _.forEach(master[`${master.type}List`].filter(r => r.isSelected), (r, i) =>{
      csv.push({
        ...r,
      })
    })
    const upd = _.filter(csv, (r, i) => r.isAddingNew || r.isEdit)
    if (csv.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return
    }
    if (upd.length > 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.editedSelection().id,
        message: MESSAGE.editedSelection().text,
        type: MESSAGE.editedSelection().icon,
      }]})
      return
    }
    appAction.showProcessing({isProcessing: true})
    MasterApi.download(isCode, type, {
      requestCodeName: type.toUpperCase(),
      downloadFileName: `${master.type}.csv`,
      requestList: csv,
    }).then((res) => {
      if (res.payload.messageList) {
        appAction.setMessage({message: res.payload.messageList,})
      } else {
        appAction.setMessage('')
        const blob = new Blob([res.payload], {type: 'text/csv'})
        if (window.navigator.msSaveOrOpenBlob) {
          // for IE,Edge
          window.navigator.msSaveOrOpenBlob(blob, `${master.type}.csv`);
        } else {
          // for chrome, firefox
          const url = URL.createObjectURL(blob);
          const linkEl = document.createElement('a');
          linkEl.href = url;
          linkEl.setAttribute('download', `${master.type}.csv`);
          document.body.appendChild(linkEl);
          linkEl.click();
          URL.revokeObjectURL(url);
          linkEl.parentNode.removeChild(linkEl);
        }
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  const execCopy = () =>{
    const targetList = _.filter(master[`${master.type}List`].filter(r => r.isSelected))
    let target = null
    if (targetList.length === 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return
    } else if (targetList.length === 1) {
      target = targetList[0]
    } else {
      appAction.setMessage({message: [{
        id: MESSAGE.targetMultiSelect().id,
        message: MESSAGE.targetMultiSelect().text,
        type: MESSAGE.targetMultiSelect().icon,
      }]})
      return
    }
    const newRow = {
      ...target,
      fjgRegNo: '',
      isAddingNew: true,
    }
    grid.collectionView.addNew(newRow)
    setTimeout(() => {
      _.forEach(grid.rows, (r, i) => {
        if (r.dataItem) {
          r.dataItem.isSelected = false
        }
      })
      grid.startEditing(false, null, 1)
    })
  }

  const execCopyToOther = () => {
    const copy = []
    _.forEach(master[`${master.type}List`].filter(r => r.isSelected), (r, i) =>{
      copy.push({
        ...r,
      })
    })
    const upd = _.filter(copy, (r, i) => r.isAddingNew || r.isEdit)
    if (copy.length < 1) {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return false
    }
    if (upd.length > 0) {
      appAction.setMessage({message: [{
        id: MESSAGE.editedSelection().id,
        message: MESSAGE.editedSelection().text,
        type: MESSAGE.editedSelection().icon,
      }]})
      return false
    }
    _.forEach(copy, (r) => {
      r.fjgAlCd = selectedAls[0].alnCd2
      _.forEach(_.filter(LIST_ITEMS.fare, 'date'), (record, index) => {
        if (r[record.id])
          r[record.id] = convert.toDateString(new Date(r[record.id]), 'yyyy-MM-dd')
      })
      _.forEach(_.filter(LIST_ITEMS.fare, 'checkbox'), (record, index) => {
        r[record.id] = r[record.id]?'1':'0'
      })
      _.forEach(_.filter(LIST_ITEMS.fare, 'number'), (record, index) => {
        r[record.id] = r[record.id]?r[record.id]:null
      })
    })
    return copy
  }

  const getColumn = () => {
    // const column = _.filter(LIST_ITEMS.fare, `tblType${tblType}`)
    const column = [].concat(LIST_ITEMS.fare)
    _.forEach(column, (r, i) => {
      // r.visible = r[`tblType${tblType}`] === true
      // r.void = !(r[`tblType${tblType}`] === true)
      switch (r.id) {
        case 'fjgRegNo':
          break;
        case 'fjgStartDgt':
          break;
        case 'fjgEndDgt':
          // r.editor = new InputNumber(document.createElement('div'), {
          //   min: r.min,
          //   max: r.max,
          //   format: r.format,
          //   isRequired: r.isRequired,
          //   gotFocus: (s, e) => {
          //     const length = s.text.length
          //     setTimeout(() => {
          //       setSelectionRange(s.inputElement, length)
          //     })
          //   },
          // });
          break;
        case 'fjgFromDate':
        case 'fjgToDate':
          r.editor = new InputDate(document.createElement('div'), {
            format: 'yyyy/MM/dd',
            mask: '0000/00/00',
            autoExpandSelection: false,
            isRequired: false,
            gotFocus: (s, e) => {
              let start = 1, end = 1
              if (!isNaN((new Date(s.text)).getTime()) || s.value === '____/__/__') {
                start = 0
                end = 10
              }
              setSelectionRange(s.inputElement, start, end)
            }
          });
          break;
        case 'fjgSpcMet':
          r.dataMap = new DataMap(SPC_MAP, 'id', 'name')
          r.editor = new AutoComplete(document.createElement('div'), {
            itemsSource: SPC_MAP,
            isRequired: r.isRequired,
            isEditable: false,
            minLength: 1,
            delay: 10,
            selectedValuePath: 'id',
            displayMemberPath: 'name'
          })
          break;
        case 'fjgAplFrtype':
          r.dataMap = new DataMap(FARE_MAP, 'id', 'name')
          r.editor = new AutoComplete(document.createElement('div'), {
            itemsSource: FARE_MAP,
            isRequired: r.isRequired,
            isEditable: false,
            minLength: 1,
            delay: 10,
            selectedValuePath: 'id',
            displayMemberPath: 'name'
          })
          break;
        default:
          break;
      }
    })
    return column
  }

  const gridEditEnded = (g, e) => {
    _.forEach(g.rows[e.row].dataItem, (v, k) => {
      if (g.columns[e.col].binding === 'fjgSpcMet' && k === 'fjgSpcMet') {
        if (v === '2') {
          if (!g.rows[e.row].dataItem.fjgStartDgt) g.rows[e.row].dataItem.fjgStartDgt = 1
          if (!g.rows[e.row].dataItem.fjgEndDgt) {
            if ((g.rows[e.row].dataItem.fjgStartDgt + g.rows[e.row].dataItem.fjgChkStr.length - 1) > 15) {
              g.rows[e.row].dataItem.fjgStartDgt = 15 - g.rows[e.row].dataItem.fjgChkStr.length + 1
              g.rows[e.row].dataItem.fjgEndDgt = 15
            } else {
              g.rows[e.row].dataItem.fjgEndDgt = g.rows[e.row].dataItem.fjgStartDgt + g.rows[e.row].dataItem.fjgChkStr.length - 1
            }
          }
        } else {
          g.rows[e.row].dataItem.fjgStartDgt = null
          g.rows[e.row].dataItem.fjgEndDgt = null
        }
      }
      if (g.columns[e.col].binding === 'fjgRegNo' && k === 'fjgRegNo') {
        g.rows[e.row].dataItem.fjgRegNo = _.padStart(g.rows[e.row].dataItem.fjgRegNo, 4, '0')
      }
      if (g.columns[e.col].binding === 'fjgStartDgt' && k === 'fjgStartDgt') {
        // g.rows[e.row].dataItem.fjgEndDgt = g.rows[e.row].dataItem.fjgStartDgt + g.rows[e.row].dataItem.fjgChkStr.length - 1
        if ((g.rows[e.row].dataItem.fjgStartDgt + g.rows[e.row].dataItem.fjgChkStr.length - 1) > 15) {
          g.rows[e.row].dataItem.fjgStartDgt = 15 - g.rows[e.row].dataItem.fjgChkStr.length + 1
          g.rows[e.row].dataItem.fjgEndDgt = 15
        } else {
          g.rows[e.row].dataItem.fjgEndDgt = g.rows[e.row].dataItem.fjgStartDgt + g.rows[e.row].dataItem.fjgChkStr.length - 1
        }
      }
      if (g.columns[e.col].binding === 'fjgChkStr' && k === 'fjgChkStr') {
        if (g.rows[e.row].dataItem.fjgSpcMet === '2')
          g.rows[e.row].dataItem.fjgEndDgt = g.rows[e.row].dataItem.fjgStartDgt + g.rows[e.row].dataItem.fjgChkStr.length - 1
      }
    })
    g.refresh()
  }
  
  const gridBeginningEdit = (g, e) => {
    if (!g.rows[e.row].dataItem.fjgAplFrtype) g.rows[e.row].dataItem.fjgAplFrtype = 'NOM'
    _.forEach(g.rows[e.row].dataItem, (v, k) => {
      if (g.columns[e.col].binding.endsWith('Dgt') && k.endsWith('Dgt')) {
        if (g.rows[e.row].dataItem.fjgSpcMet === '2') {
          g.rows[e.row].dataItem[k] = g.rows[e.row].dataItem[k]?g.rows[e.row].dataItem[k]:0
          g.columns[e.col].isRequired = true
        } else {
          g.columns[e.col].isRequired = false
          e.cancel = true
        }
      }
    })
  }

  const gridDraggingRow = (g, e) => {
    _.forEach(g.rows, (r, i) => {
      if (r.dataItem && !r.dataItem.isAddingNew) {
        if (!r.dataItem.original) {
          r.dataItem.original = {}
          _.forEach(LIST_ITEMS.fare, (col, index) => {
            r.dataItem.original[col.id] = r.dataItem[col.id]
          })
        }
        r.dataItem.seq = i
      }
    })
  }
  const gridDraggedRow = (g, e) => {
    g.deferUpdate(() => {
      const original = []
      _.forEach(_.sortBy(g.rows, (r, i) => {return r.dataItem && r.dataItem.seq}), (r, i) => {
        if (r.dataItem) original.push(r.dataItem.fjgRegNo)
      })
      const newRows = []
      _.forEach(g.rows, (r, i) => {
        if (r.dataItem) newRows.push(r.dataItem)
      })
      let skip = 0
      _.forEach(newRows, (r , i) => {
        if (r.isAddingNew) skip++
        _.forEach(r, (item, key) => {
          if (original[i] && !r.isAddingNew && key === 'fjgRegNo') newRows[i][key] = original[i - skip]
        })
      })
      g.collectionView.sourceCollection = newRows
      _.forEach(g.columns, (col, j) => {
        if (col.binding === 'fjgRegNo') {
          _.forEach(g.rows, (row, i) => {
            // if (row.dataItem) g.startEditing(false, i, j)
          })
        }
      })
    })
  }

  const gridFormatItem = (g, e) => {
    const item = e.getRow().dataItem
    const bind = e.getColumn().binding
    const isGray = (item && item.fjgSpcMet !== '2')
    if (bind === 'fjgStartDgt') {
      toggleClass(e.cell, 'cell-gray', isGray);
    }
  }

  return (
    <React.Fragment>
      <Card
        root='max-button'
        size='lg'
        hideClose={!props.isModal}
        onClose={props.isModal?props.onClose:null}
        header={TITLE.master[`${master.type}`].header}
        headerClass={props.isModal?'modal-header':''}
        subheader={TITLE.master[`${master.type}`].subheader}
        body={
          <React.Fragment>
            <div className='list-header fare'>
              <div className='d-inline-flex align-items-end'>
                <Common.MultiSelect
                  attrs={{
                    id: 'fjgAlCd',
                    label: '航空会社',
                    type: 'search',
                    helpertext: '',
                    maxLength: 3,
                    minLength: 1,
                    delay: 10,
                  }}
                  group={{
                    style: {
                      'width': 'auto',
                    },
                    className: 'mx-0 ml-3',
                  }}
                  maxSelectedItems={1}
                  minLength={1}
                  selectedValuePath='alnCd2'
                  displayMemberPath={'listName'}
                  displayName="alnNamej"
                  list={app.alList}
                  searchMemberPath={'alnCd2,alnCdn'}
                  setSelectedItems={setSelectedAls}
                  template={'<table><tr>' +
                            '<td class="width-5" title="コード 2桁">{alnCd2}</td>' +
                            '<td class="width-5" title="コード 数字">{alnCdn}</td>' +
                            '<td class="" title="名称">{alnNamej}</td>' +
                            '</tr></table>'}
                  isEditable={false}
                />
                <div className={`ml-3 mb-1 ${getEditable()?'':'d-none'}`}>
                  <span className='list-count ml-3'>
                    {master[`${master.type}List`]?master[`${master.type}List`].length:0}
                  </span>
                  <span>件 抽出しました</span>
                </div>
              </div>
              {/* <OverlayTrigger
                placement='right'
                delay={{ show: 0, hide: 400 }}
                overlay={<Tooltip id='refresh-tooltip'>表示内容を破棄し、データを再取得します</Tooltip>}
              >
                <button
                  className='refresh-button wj-btn-glyph'
                  title='データを再取得します'
                  style={{width: rowHeaderWidth}}
                  onClick={(e) => getData(master.type)}
                >
                  <span className="material-icons-outlined">refresh</span>
                </button>
              </OverlayTrigger> */}
            </div>
            <div className='master-list-body fare' >
              <Common.Grid
                thisGrid={grid}
                setGrid={setGrid}
                colDef={getColumn()}
                dataList={master[`${master.type}List`]}
                setRowHeaderWidth={setRowHeaderWidth}
                setColWidth={setColWidth}
                grid={{
                  isReadOnly: !getEditable(),
                  allowAddNew: true,
                  allowDragging: 'Rows',
                  editEnded: (g, e) => {gridEditEnded(g, e)},
                  beginningEdit: (g, e) => {gridBeginningEdit(g, e)},
                  draggingRow: (g, e) => {gridDraggingRow(g, e)},
                  draggedRow: (g, e) => {gridDraggedRow(g, e)},
                  gridFormatItem: (g, e) => {gridFormatItem(g, e)},
                }}
                setInput={masterAction.setInputMaster}
              />
            </div>
          </React.Fragment>
        }
      />
      <Card 
        size='lg'
        root='button'
        hideClose
        header={null}
        subheader={null}
        body={
          <div className='card-buttom-button'>
            <Button
              {...master.delete}
              className='button-warn mt-auto mr-auto'
              onClick={() => execDelete(master.isCode, master.type)}
              disabled={master.delete.disabled || !getEditable() || app.isProcessing}
            >
              {master.delete.label}
            </Button>
            <CopyToOtherPopup
              id='copy-popup'
              size='md'
              // className='button-gray mt-auto ml-auto'
              header='AIRLINE複写'
              subheader=''
              body={null}
              disabled={master.copyToOther.disabled || !(grid && grid.rows.length>0) || app.isProcessing}
              onClick={() => execCopyToOther(master.isCode, master.type)}
              selectedItems={selectedAls}
            />
            <Button
              {...master.copy}
              className='button-gray mt-auto mx-auto'
              onClick={() => execCopy()}
              disabled={master.copy.disabled || !getEditable() || master.editing || app.isProcessing}
            >
              {master.copy.label}
            </Button>
            <Button
              {...master.csv}
              className='button-gray mt-auto ml-auto'
              onClick={() => execCsv(master.isCode, master.type)}
              disabled={master.csv.disabled || !(grid && grid.rows.length>0) || app.isProcessing}
            >
              {master.csv.label}
            </Button>
            <Button
              {...master.save}
              className='button-blue mt-auto ml-5'
              onClick={() => execSave(master.isCode, master.type)}
              disabled={master.editing || !getEditable() || app.isProcessing}
            >
              {master.save.label}
            </Button>
          </div>
        }
      />
    </React.Fragment>
  )
}