import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { TreeNodeEventArgs } from '@grapecity/wijmo.nav';
import { TreeView } from '@grapecity/wijmo.react.nav';
import { toggleClass } from "@grapecity/wijmo";
import _ from 'lodash';
import { CARD_TITLE } from 'constants/title';
import { SEARCH, MAX_CONDITION, CHECKS } from 'constants/search';
import { MESSAGE } from 'constants/message';
import { Card } from 'components';
import { PatternNamePopup } from 'components/contents/search/modal/PatternNamePopup';
import { AppAction } from 'ducks/App';
import { getPattern, restoreCondition, makeCondition } from "lib";
// import './styles/searchList.scss'

export const SearchListComponent = (props) => {

  const app = useSelector(state => state.App);
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);

  const [treeView, setTreeView] = React.useState(null)
  const [searchSource, setSearchSource] = React.useState(SEARCH)
  const [popup, setPopup] = React.useState(null)
  const [popupData, setPopupData] = React.useState(null)

  React.useEffect(() => {
    if (treeView && searchSource) {
      // setTimeout(() => {
        _.forEach(searchSource, (top, i) => {
          if (treeView.getNode(top)) {
            treeView.getNode(top).element.childNodes[0].disabled = true;
            treeView.getNode(top).element.classList.remove('wj-state-empty');
            _.forEach(top.items, (scd, j) => {
              if (i === 1 && treeView.getNode(scd)) {
                treeView.getNode(scd).element.childNodes[0].disabled = true;
              }
              if (i === 2 && treeView.getNode(scd)) {
                // console.log(treeView.getNode(scd).element)
                // console.log(treeView.getNode(scd).element.childNodes[0])
                treeView.getNode(scd).element.childNodes[0].classList.add('d-none');
                treeView.getNode(scd).element.childNodes[0].disabled = true;
                treeView.getNode(scd).element.addEventListener('dblclick', (e) => {
                  // console.log(e.target.element)
                  const name = e.target.innerHTML
                  const id = e.target.getAttribute('key')
                  if (name && id) {
                    props.setInput({
                      checkedItems: [],
                      checkedItemsSetting: getPattern(props.search.patternMap, name),
                    })
                  }
                })
                treeView.getNode(scd).element.addEventListener('click', (e) => {
                  const id = e.target.id
                  if (id) {
                    setPopupData(id)
                    popup.show(true, (sender) => {
                      if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
                        // console.log('ok')
                      } else {
                        // console.log('cancel')
                      }
                      setPopupData('')
                    })
                  }
                })
              }
            })
          }
        })
        treeView.isCheckedChanging.addHandler(function (s, e) {

          // 上位ノードは無効
          if (e && e.node && e.node.element.childNodes[0].disabled)
            e.cancel = true
          // チェックしようとしている && 最大件数
          if (e && e.node && !e.node.element.childNodes[0].checked && _.uniqBy(s.checkedItems, 'key').length >= MAX_CONDITION)
            e.cancel = true
            
          if (s.selectedItem && _.filter(s.checkedItems, r => r.key === s.selectedItem.key).length > 0) {
            props.remove([s.selectedItem])
            e.cancel = true
          }
        })
        // treeView.isCheckedChanged.addHandler(function (s, e) {
        // })
        treeView.collapseToLevel(1);
      // }, 500)
    }
  }, [treeView, searchSource])

  React.useEffect(() => {
    if (treeView) {
      const maxSelected = (_.uniqBy(treeView.checkedItems, 'key').length >= MAX_CONDITION)
      _.forEach(searchSource, (top, i) => {
        if (treeView && treeView.getNode(top)) {
          _.forEach(top.items, (scd, j) => {
            if (i === 0 && treeView.getNode(scd) && !treeView.getNode(scd).element.childNodes[0].checked) {
              if (!_.find(treeView.checkedItems, r => r.key === scd.key)) {
                toggleClass(treeView.getNode(scd).element, 'wj-state-disabled', maxSelected);
              } else {
                treeView.getNode(scd).element.childNodes[0].checked = true
              }
            }
            if (i === 1 && treeView.getNode(scd)) {
              _.forEach(scd.items, (thi, k) => {
                if (treeView.getNode(thi) && !treeView.getNode(thi).element.childNodes[0].checked) {
                  if (!_.find(treeView.checkedItems, r => r.key === thi.key)) {
                    toggleClass(treeView.getNode(thi).element, 'wj-state-disabled', maxSelected);
                  } else {
                    treeView.getNode(thi).element.childNodes[0].checked = true
                  }
                }
              })
            }
          })
        }
      })
    }
  }, [treeView && _.uniqBy(treeView.checkedItems, 'key').length])

  React.useEffect(() => {
    const tmp = treeView && {...treeView.checkedItems}
    // console.log(tmp)
    setSearchSource([
      {
        ...SEARCH[0],
        items: props.search.recentlyCondition,
      },
      SEARCH[1],
      {
        ...SEARCH[2],
        items: props.search.patternList,
      },
    ])
    // console.log(props.checkedItems)
    // props.setInput({checkedItems:[]})
    setTimeout(() => {
      setCondition()
    })
    // _.forEach(props.checkedItems, (chk, i) => {
    //   setTimeout(() => {
    //     if (treeView && treeView.getNode(chk)) treeView.getNode(chk).element.childNodes[0].checked = true
    //     _.forEach(props.search.recentlyCondition, (rec, m) => {
    //       setTimeout(() => {
    //         if (chk.key === rec.key) {
    //           if (treeView && treeView.getNode(rec)) treeView.getNode(rec).element.childNodes[0].checked = true
    //         }
    //       }, (m + 1) * 50)
    //     })
    //   }, (i + 1) * 50)
    // })
    // treeView.getNode(item).element.childNodes[0].checked = true
  }, [SEARCH, props.search.recentlyCondition, props.search.patternList])

  const setCondition = (type) => {
    // console.log(props.checkedItems)
    // console.log(props.checkedItemsSetting)
    const checkItems = type==='setting'?props.checkedItemsSetting:props.checkedItems
    // appAction.showProcessing({isProcessing: true})
    // setTimeout(() => {
    //   appAction.showProcessing({isProcessing: false})
    // }, checkItems.length * 300)
    _.forEach(SEARCH[1].items, (scd, j) => {
      _.forEach(scd.items, (item, k) => {
        _.forEach(checkItems, (r, l) => {
          // setTimeout(() => {
            if (r.key === item.key && treeView && treeView._root && treeView.hostElement && treeView.getNode(item)) {
              // console.log('---------------------------------------------------------------------------------')
              // console.log(checkItems)
              // console.log(_.find(checkItems, r => r.key === item.key))
              // console.log(item)
              treeView.getNode(item).element.childNodes[0].checked = true
              if (!_.find(props.checkedItems, r => r.key === item.key)) {
                initCondition(item)
                // treeView.selectedItem = item
                // treeView.onCheckedItemsChanged()
              }
              _.forEach(props.search.recentlyCondition, (rec, m) => {
                // setTimeout(() => {
                  if (r.key === rec.key && treeView && treeView._root && treeView.hostElement  && treeView.getNode(rec)) {
                    treeView.getNode(rec).element.childNodes[0].checked = true
                  }
                // }, (l + 1) * 5)
              })
            }
          // }, (l + 1) * 10)
        })
      })
    })
  }

  const initCondition = (item) => {
    props.setInput(makeCondition(item, props.search))
    props.add([item])
  }

  React.useEffect(() => {
    if (treeView) {
      // console.log('パターン変更')
      // console.log(props.checkedItemsSetting)
      // props.setInput({checkedItems:[]})
      setTimeout(() => {
        setCondition('setting')
      })
    }
  }, [props.checkedItemsSetting])

  React.useEffect(() => {
    if (treeView) {
      // console.log(props.checkedItems)
      treeView.selectedItem = null
      // treeView.checkedItems = props.checkedItems
      // treeView.checkedItems = [{...props.checkedItems}]
      let checkedItems = []
      _.forEach(props.checkedItems, (chk, i) => {
        _.forEach(props.search.recentlyCondition, (r, j) => {
          if (r.key === chk.key) {
            checkedItems.push(r)
          }
        })
        _.forEach(SEARCH[1].items, (scd, j) => {
          _.forEach(scd.items, (item, k) => {
            if (item.key === chk.key) {
              checkedItems.push(item)
            }
          })
        })
        checkedItems.push(chk)
      })
      treeView.checkedItems = checkedItems
      treeView.refresh(true)
    }
  }, [props.checkedItems])

  return (
    <React.Fragment>
      <PatternNamePopup 
        popup={popup}
        setPopup={setPopup}
        popupData={popupData}
        setPopupData={setPopupData}
        header={<span><span className='material-icons-outlined md'>edit</span>検索条件パターン編集・削除</span>}
        search={props.search}
        onBlur={props.onBlur}
        setInput={props.setInput}
        searchApi={props.searchApi}
        tktSrchptPrivForm={props.tktSrchptPrivForm}
      />
      <Card
        root='max'
        size='md'
        hideClose
        header={props.title.header}
        subheader={props.title.subheader}
        body={
          <TreeView
            className='accordion'
            showCheckboxes    //clickで展開と両立しない
            checkOnClick={true}
            autoCollapse={false}
            collapseOnClick={true}
            expandOnClick={true}
            itemsSource={searchSource}
            displayMemberPath="header"
            childItemsPath="items"
            isContentHtml={true}
            isAnimated={false}
            isDisabled={props.search.listChanged}
            initialized={(control) => setTreeView(control)}
            checkedItemsChanged={(e) => props.checkedItemsChanged(e)}
            // isCheckedChanging = {(t, e) => props.isCheckedChanging(t, e)}
          />
        }
      />
    </React.Fragment>
  )
}

