import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CellRange, GroupRow, AllowSorting, ClipStringOptions } from '@grapecity/wijmo.grid';
import { DataType, SortDescription, CollectionViewGroup, PropertyGroupDescription, saveFile } from '@grapecity/wijmo';
import { Menu } from '@grapecity/wijmo.input';
import { MESSAGE } from 'constants/message';
import { AppAction } from 'ducks/App';
import _ from 'lodash';

export const ContextMenu = React.forwardRef((props, ref) => {

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const {grid} = props
  const [curRow, setCurRow] = React.useState(null)
  const [popupShow, setPopupShow] = React.useState(false)

  React.useEffect(() => {
    if (grid) {
      let host = grid.hostElement
      if (host) {
        host.removeEventListener('contextmenu', (e) => handleContextMenu)
        setTimeout(() => {
          host.addEventListener('contextmenu', (e) => handleContextMenu(e))
        })
      }
    }
  }, [grid, props.dispCheckedItems])

  const handleContextMenu = (e) => {
    let menu = _buildMenu(grid);
    let sel = grid.selection
    let ht = grid.hitTest(e)
    let row = ht.getRow();
    let col = ht.getColumn()
    if (ht.panel === grid.columnHeaders) {
      grid.select(sel.row, ht.col);
    } else if (ht.panel === grid.cells) {
      let colIndex = ht.col;
      if (row instanceof GroupRow && row.dataItem instanceof CollectionViewGroup) {
        let gd = row.dataItem.groupDescription;
        if (gd instanceof PropertyGroupDescription) {
          let col = grid.getColumn(gd.propertyName);
          if (col && col.index > -1) {
            colIndex = col.index;
          }
        }
      }
      grid.select(ht.row, colIndex);
    }
    if (grid.selection.col > -1) {
      if (props.dispCheckedItems) {
        const coldef = _.find(props.dispCheckedItems, r => r.id === col.binding)
        if (coldef && coldef.bulkEdit) {
          e.preventDefault();
          setCurRow(coldef)
          menu.show(e);
        }
      }
    }
  }

  const _buildMenu = (grid) => {
    let menu = new Menu(document.createElement('div'), {
        owner: grid.hostElement,
        displayMemberPath: 'header',
        subItemsPath: 'items',
        commandParameterPath: 'cmd',
        dropDownCssClass: 'ctx-menu',
        // openOnHover: true,
        // closeOnLeave: true,
        itemsSource: [
            { header: '一括修正', cmd: 'EDIT' },
        ],
        command: {
          executeCommand: (cmd) => {
            if (cmd === 'EDIT') {
              setPopupShow(true)
            }
          }
        }
    });
    // done
    return menu;
  }

  useEffect(() => {
    // console.log(popupShow)
    if (popupShow) {
      // console.log(grid.rows.filter(r => r.isSelected))
      if (grid.rows.filter(r => r.isSelected).length <= 0) {
        appAction.setMessage({message: [{
          id: MESSAGE.targetNoSelect().id,
          message: MESSAGE.targetNoSelect().text,
          type: MESSAGE.targetNoSelect().icon,
        }]})
        setPopupShow(false)
        return
      }
      // console.log(curRow)
      props.setPopupData(curRow)
      props.popup.show(true, (sender) => {
        // if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1)
        //   console.log('ok')
        // else console.log('cancel')
        props.setPopupData(null)
        setPopupShow(false)
      })
    }
  }, [popupShow])
})
