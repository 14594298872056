import { axiosInstance } from 'http/index'

export const LogoutApi = {
  logout : (data) => {
    const instance = axiosInstance;
    // console.log(instance)
    return instance.post('logout', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
}
