import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button, Form } from 'react-bootstrap';
import { Card } from 'components';
import _ from 'lodash';
import { AppAction } from 'ducks/App';
import { MasterAction } from 'ducks/Master';
import { PivotAction } from 'ducks/Pivot';
import { MasterApi } from 'webapi'
import { MIG_DOWNLOAD_FILE, MIG_DOWNLOAD_URL, CLIENT_DOWNLOAD_URL, EXTERNAL_URL } from 'constants/index';
import * as Common from 'components/common';
import { CHART_TYPE } from 'constants/pivot';
import { convert, entryCheck, alert, validation, getPatternList } from "lib";
import reactSplitPane from 'react-split-pane';
import { SummaryApi } from 'webapi'
import { Step } from './Step';

export const Step2 = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const pivot = useSelector(state => state.Pivot);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const masterAction = bindActionCreators(MasterAction, dispatch);
  const pivotAction = bindActionCreators(PivotAction, dispatch);
  const [installCheck, setInstallCheck] = React.useState('0')

  const execDownload = (type) => {
    // Download
    const linkEl = document.createElement('a');
    const now = convert.toDateTimeString(new Date(), 'yyyyMMddHHmmss')
    linkEl.href = (type==='client')?CLIENT_DOWNLOAD_URL:MIG_DOWNLOAD_URL+'?'+now
    document.body.appendChild(linkEl);
    appAction.setInputApp({isUnloadCheck: false})
    setTimeout(() => {
      linkEl.click();
      setTimeout(() => {
        appAction.setInputApp({isUnloadCheck: true})
        linkEl.parentNode.removeChild(linkEl);
      })
    })
  }

  return (
    <React.Fragment>
      <Card 
        root='popup'
        size='lg'
        hideClose
        header='データ移行　STEP2'
        headerClass='wj-dialog-header'
        body={
          <React.Fragment>
            <Step step={props.step} />
            <div className='py-2 px-5'>
              <div className={`step-error ${props.errorList.length>0?'':'d-none'}`}>
                {props.errorList.map((r, i) => {
                  return <div key={i}>{r.message}</div>
                })}
                {props.isReload &&
                  <Button
                    className='button-gray btn-sm width-12 mt-2 mx-auto'
                    onClick={() => props.execReload()}
                    disabled={app.isProcessing}
                  >
                    リロード
                  </Button>
                }
              </div>
              <div className='step-body mx-auto mt-4'>
                <div>①データ抽出ツール ダウンロード</div>
                <div>以下のボタンから、データ抽出ツールをダウンロードして、任意のフォルダに配置し、解凍してください。</div>
                <div className='d-flex width-p-100 mt-2'>
                  <div className='d-flex width-p-100 mt-2'>
                    <Button
                      className='button-blue width-30 mx-auto'
                      onClick={() => execDownload('tool')}
                      disabled={app.isProcessing}
                    >
                      <div className='d-flex flex-row align-items-center justify-content-center'>
                        <div className='d-inline-block'>ダウンロード</div>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
              <div className='step-body mx-auto mt-1'>
                <div>②移行データ アップロード</div>
                <div>ダウンロードしたツールで作成されたファイルをアップロードしてください。</div>
                <div className='d-flex mt-2'>
                  <label className='mb-0 mr-2'>
                    <span className='migration-upload-button py-2 px-5'>ファイル選択</span>
                    <input
                      id='abc-id'
                      type='file'
                      hidden
                      onChange={(e) => props.setFiles(e.currentTarget.files)}
                    />
                    <label className='migration-upload-filename'>
                      {props.files && props.files.length> 0 && props.files[0].name}
                    </label>
                  </label>
                </div>
              </div>
              {login.authCli === '1' &&
                <div className='step-body mx-auto mt-1'>
                  <div>③Plusクライアント インストール</div>
                  <div>以下のボタンをクリックし、『Plusクライアント』のインストールを行ってください。</div>
                  <div>インストール手順の詳細は<span>こちら</span>をご確認ください。</div>
                  <div className='d-flex width-p-100 mt-2'>
                    <Button
                      className='button-blue width-30 mx-auto'
                      onClick={() => execDownload('client')}
                      disabled={app.isProcessing}
                    >
                      <div className='d-flex flex-row align-items-center justify-content-center'>
                        <div className='d-inline-block'>Plusクライアントインストール</div>
                      </div>
                    </Button>
                  </div>
                  <div>インストールが完了しましたら、以下をチェックしてデータ移行に進んでください。</div>
                  <div className='d-flex width-p-100'>
                    <Common.Check
                      attrs={{
                        id: 'installCheck',
                        label: 'Plusクライアントのインストールが完了しました。',
                        text: installCheck
                      }}
                      className='mt-2 mr-auto'
                      onChange={(e) => setInstallCheck(e.installCheck.text)}
                    />
                  </div>
                </div>
              }
              <div className='step-body mx-auto mt-1'>
                <div>④データ移行</div>
                <div>ファイル選択後、次へボタンをクリックしてください。データ移行が開始されます。</div>
                <div className='d-flex mt-2'>
                  <Button
                    className='button-blue width-20 ml-auto'
                    onClick={props.action}
                    disabled={app.isProcessing || (login.authCli === '1' && installCheck==='0') || !props.files || (props.files && props.files.length<1)}
                  >
                    <div className='d-flex flex-row align-items-center justify-content-center'>
                      <div className='d-inline-block'>次へ</div>
                      <div className='material-icons-outlined md'>arrow_right</div>
                    </div>
                  </Button>
                  <div>
                    <div className='ml-3 mb-0'>お問い合わせ先　Amadeus Delivery担当</div>
                    <div className='ml-3 mb-0'>メールアドレス <Link to='#' onClick={(e) => {
                      appAction.setInputApp({isUnloadCheck: false})
                      setTimeout(() => {
                        window.location.href='mailto:uripro.migration.japan@amadeus.com';
                        e.preventDefault();
                        appAction.setInputApp({isUnloadCheck: true})
                      })
                    }}>uripro.migration.japan@amadeus.com</Link></div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
})