import { axiosInstance, axiosInstanceForBlob } from 'http/index'

export const ReportApi = {
  init: (data) => {
    const instance = axiosInstance;
    return instance.post('/report/init', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  print: (data) => {
    const instance = axiosInstance;
    return instance.post('/report/print', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  download: (data) => {
    const instance = axiosInstance;
    return instance.post('/report/download', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  edit: (data) => {
    const instance = axiosInstance;
    return instance.post('/report/edit', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  outputFile: (data) => {
    const instance = axiosInstance;
    return instance.post('/report/file', data, {responseType: 'blob'}).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },

}
