import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktEditAction } from 'ducks/TktEdit';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass, setSelectionRange } from "@grapecity/wijmo";
import { InputDate, InputTime, ComboBox, AutoComplete, MultiAutoComplete, InputColor, InputNumber } from '@grapecity/wijmo.input';
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, EDIT_PANE, LIST_ITEMS, TKT_KB_FLAG_MAP, REF_TYPE } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { propTypes } from 'react-bootstrap/esm/Image';

export const RefundPane = (props) => {

  const app = useSelector(state => state.App);
  const tktEdit = useSelector(state => state.TktEdit);
  
  const dispatch = useDispatch();
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);

  const [grid1, setGrid1] = React.useState(null)
  const [grid2, setGrid2] = React.useState(null)

  const getCouponColumn = () => {
    const column = [].concat(LIST_ITEMS.coupon)
    return column
  }
  
  const getTaxColumn = () => {
    const column = [].concat(LIST_ITEMS.refundTax)
    return column
  }

  const itemFormatter = (panel, r, c, cell) => {
    // 列ヘッダは中央
    if (panel.cellType == 2) {
      cell.style.textAlign = 'center';
    }
  }

  return (
    <React.Fragment>
      <div className='d-flex flex-row'>
        <div className='width-p-50'>
          <div className='d-block'>
            <Common.Select
              attrs={{...tktEdit.tktRefType}}
              group={{
                className: 'width-7 mr-2',
              }}
              minLength={1}
              list={REF_TYPE}
              onChange={(e) => tktEditAction.changeInputTktEdit(e)}
            />
          </div>
          <div className='d-block'>
            <Common.Number
              attrs={{
                ...tktEdit.tktGrsFarePd,
                labelclass: 'width-max-content'
              }}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-9 mr-0',
                textwidth: 'width-p-100'
              }}
            />
          </div>
          <div className='d-block'>
            <Common.Number
              attrs={{
                ...tktEdit.tktFrUsed,
                labelclass: 'width-max-content'
              }}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-9 mr-0',
                textwidth: 'width-p-100'
              }}
            />
          </div>
          <div className='d-block'>
            <Common.Number
              attrs={{
                ...tktEdit.tktGrsFareRef,
                labelclass: 'width-max-content'
              }}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-9 mr-0',
                textwidth: 'width-p-100'
              }}
            />
          </div>
          <div className='d-block'>
            <Common.Number
              attrs={{
                ...tktEdit.tktTaxRef,
                labelclass: 'width-max-content'
              }}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-9 mr-5',
                textwidth: 'width-p-100'
              }}
            />
            <Common.Number
              attrs={{
                ...tktEdit.tktNetFareRef,
                labelclass: 'width-max-content'
              }}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-9 ml-2 mr-0',
                textwidth: 'width-p-100'
              }}
            />
          </div>
          <div className='d-block'>
            <Common.Number
              attrs={{
                ...tktEdit.tktLesCntPen,
                labelclass: 'width-max-content'
              }}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-9 mr-5',
                textwidth: 'width-p-100'
              }}
            />
            <Common.Number
              attrs={{
                ...tktEdit.tktNetFare,
                labelclass: 'width-max-content'
              }}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-9 ml-2 mr-0',
                textwidth: 'width-p-100'
              }}
            />
          </div>
          <div className='d-block'>
            <Common.Number
              attrs={{
                ...tktEdit.tktTotalAmt,
                labelclass: 'width-max-content'
              }}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-9 mr-5',
                textwidth: 'width-p-100'
              }}
            />
            <Common.Number
              attrs={{
                ...tktEdit.tktTotalAmountNfa,
                labelclass: 'width-max-content'
              }}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-9 ml-2 mr-0',
                textwidth: 'width-p-100'
              }}
            />
          </div>
          <div className='d-block mt-4'>
            <Common.Number
              attrs={{
                ...tktEdit.tktCommRate,
                labelclass: 'width-max-content',
                visible: tktEdit.refCommRate.visible,
              }}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-7 mr-0',
                textwidth: 'min-width-5-5 width-5-5'
              }}
            />
          </div>
        </div>
        <div className='width-p-50'>
          <div className={`coupon-list-body ${tktEdit.tktNoCoupon.visible?'visible':'invisible'}`} >
            <label className='form-label'>TKT NO./COUPON</label>
            <Common.Grid
              thisGrid={grid1}
              setGrid={setGrid1}
              colDef={getCouponColumn()}
              // masterType={master.type}
              dataList={tktEdit.tktDataListDto}
              // setRowHeaderWidth={setRowHeaderWidth}
              // setColWidth={setColWidth}
              noSelector={true}
              grid={{
                isReadOnly: app.tktEditFormMode==='3',
                allowAddNew: false,
                // frozenColumns: 1,
                imeEnabled: false,
                itemFormatter: (panel, r, c, cell) => itemFormatter(panel, r, c, cell)
              }}
              setInput={tktEditAction.setInputTktEdit}
            />
          </div>
          <div className={`refund-list-body ${tktEdit.tktRefTax.visible?'visible':'invisible'}`} >
            <label className='form-label'>REFUND TAX</label>
            <Common.Grid
              thisGrid={grid2}
              setGrid={setGrid2}
              colDef={getTaxColumn()}
              // masterType={master.type}
              dataList={tktEdit.tktRefundTaxListDto}
              // setRowHeaderWidth={setRowHeaderWidth}
              // setColWidth={setColWidth}
              noSelector={true}
              grid={{
                isReadOnly: app.tktEditFormMode==='3',
                allowAddNew: false,
                // frozenColumns: 1,
                imeEnabled: false,
                itemFormatter: (panel, r, c, cell) => itemFormatter(panel, r, c, cell)
              }}
              setInput={tktEditAction.setInputTktEdit}
            />
          </div>
        </div>
      </div>
      <div className='d-flex flex-row'>
        <div className='width-p-100'>
          <div className='d-block'>
            <Common.Number
              attrs={{...tktEdit.refundCommAmount}}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-5-9 mr-5',
                textwidth: 'width-p-100'
              }}
            />
            <Common.Text
              attrs={{...tktEdit.tktWaiverCode}}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-19 ml-1 mr-2',
              }}
            />
            <Common.Text
              attrs={{...tktEdit.tktSac}}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-20 mr-0',
              }}
            />
          </div>
          <div className='d-block'>
            <Common.Number
              attrs={{...tktEdit.refundCommTax}}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-5-9 mr-5',
                textwidth: 'width-p-100'
              }}
            />
            <Common.Text
              attrs={{...tktEdit.tktRefMemo}}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-5-32 ml-1 mr-2',
              }}
            />
          </div>
          <div className='d-block'>
            <Common.Number
              attrs={{
                ...tktEdit.tktBalancePayable,
                labelclass: 'width-max-content'
              }}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-5-9 mr-5',
                textwidth: 'width-p-100'
              }}
            />
            <Common.Text
              attrs={{...tktEdit.tktRefPfc}}
              onBlur={(e) => tktEditAction.changeInputTktEdit(e)}
              group={{
                className: 'width-41 ml-1 mr-2',
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}