import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktExcelAction } from 'ducks/TktExcel';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TabPanel, Tab } from '@grapecity/wijmo.react.nav';
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { LIST_ITEMS, EXCEL_OUT_FORMAT, EXCEL_OUT_FORMAT_TIMER, EXCEL_MESSAGE, OUT_PEX, TKT_XLSINI_HEADER, CLIENT_OUT_FILE,
  CLIENT_OUT_FILE_PATTERN, S3_OUT_FILE, S3_OUT_FILE_PATTERN, TKT_XLSINI_CSMTAX, TKT_XLSINI_PRD, TKT_XLSINI_PLUS_TYPE,
  TKT_XLSINI_GROSS_NET_SAME, TKT_XLSINI_PCC, TKT_XLSINI_PRD_EX, TKT_XLSINI_PRD_KB0, TKT_XLSINI_TOTAL, TKT_EMD_MPD_FLAG,
  TKT_XLSINI_FILE_FLAG } from 'constants/ticket';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { TktEditAction } from 'ducks/TktEdit';

export const ExcelComponent = (props) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const tktExcel = useSelector(state => state.TktExcel);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const tktExcelAction = bindActionCreators(TktExcelAction, dispatch);

  const [grid, setGrid] = React.useState(null)

  const {tab, settingPopup, setSettingPopup, outItemList, setOutItemList,
    isEditOutList, setIsEditOutList, execExcel, changeInput, execPattern} = props;

    
  const itemsSourceChanged = (g, e) => {
    // console.log(g)
    g.rowHeaders.columns.defaultSize = 50;
    for (let r = 0; r < g.rowHeaders.rows.length; r++) {
      g.rowHeaders.setCellData(r, 0, r + 1);
    }
  }

  React.useEffect(() => {
    if (tktExcel.tkt.tktXlsiniClientOutFile_tkt.text === '3') {
      if (tktExcel.tkt.tktXlsiniClientOutFilePattern_tkt.text !== '3' && tktExcel.tkt.tktXlsiniClientOutFilePattern_tkt.text !== '4') {
        tktExcelAction.setInputTktExcel({
          tkt: {
            ...tktExcel.tkt,
            tktXlsiniClientOutFilePattern_tkt: {
              ...tktExcel.tkt.tktXlsiniClientOutFilePattern_tkt,
              value: '3',
              text: '3',
            }
          }
        })
      }
    }
    if (tktExcel.tkt.tktXlsiniS3OutFile_tkt.text === '3') {
      if (tktExcel.tkt.tktXlsiniS3OutFilePattern_tkt.text !== '3' && tktExcel.tkt.tktXlsiniS3OutFilePattern_tkt.text !== '4') {
        tktExcelAction.setInputTktExcel({
          tkt: {
            ...tktExcel.tkt,
            tktXlsiniS3OutFilePattern_tkt: {
              ...tktExcel.tkt.tktXlsiniS3OutFilePattern_tkt,
              value: '3',
              text: '3',
            }
          }
        })
      }
    }
  }, [tktExcel.tkt.tktXlsiniClientOutFile_tkt.text, tktExcel.tkt.tktXlsiniS3OutFile_tkt.text])

  React.useEffect(() => {
    tktExcelAction.setInputTktExcel({
      tkt: {
        ...tktExcel.tkt,
        tktXlsiniFile_tkt: {
          ...tktExcel.tkt.tktXlsiniFile_tkt,
          value: tktExcel.tkt.tktXlsiniFileFlag_tkt.text==='1'?'':tktExcel.tkt.tktXlsiniFile_tkt.value,
          text: tktExcel.tkt.tktXlsiniFileFlag_tkt.text==='1'?'':tktExcel.tkt.tktXlsiniFile_tkt.text,
          disabled: tktExcel.tkt.tktXlsiniFileFlag_tkt.text==='1',
        }
      }
    })
  }, [tktExcel.tkt.tktXlsiniFileFlag_tkt.text])

  return (
    <React.Fragment>
      <SplitPane split="vertical">
        <Pane initialSize='25%' minSize='25%' maxSize='25%'>
          <Card
            root={props.isModal?'max-button2':'max-button3'}
            size='lg'
            hideClose
            header={null}
            subheader={null}
            body={
              <React.Fragment>
                <Common.Select
                  attrs={{...tktExcel[tab][`tktXlsitemObjectId_${tab}`]}}
                  group={{
                    className: 'width-30 ml-2',
                    textwidth: 'width-30',
                  }}
                  minLength={1}
                  list={tab==='tkt'?tktExcel.tktPatternList:tktExcel.fltPatternList}
                  onChange={(e) => {
                    changeInput(e)
                  }}
                />
                <Card
                  root='max'
                  size='lg'
                  hideClose
                  header={null}
                  subheader={null}
                  body={
                    <React.Fragment>
                      <Card
                        root='max'
                        size='lg'
                        hideClose
                        header={TITLE.ticketExcel.item.header}
                        subheader={TITLE.ticketExcel.item.subheader}
                        body={
                          <React.Fragment>
                            <label sm="3" className='label-color mb-0 mr-0 text-left width-p-100'>
                              {tktExcel.label[0]}
                            </label>
                            <Common.Radio
                              btnSize='sm'
                              attrs={{...tktExcel[tab][`tktXlsiniFormat_${tab}`]}}
                              radios={props.isModal?EXCEL_OUT_FORMAT_TIMER:EXCEL_OUT_FORMAT}
                              onChange={(e) => changeInput(e)}
                            />
                            <div className={props.isModal?'excel-output-list-body':'excel-output-list-body-2'}>
                              <Common.Grid
                                thisGrid={grid}
                                setGrid={setGrid}
                                colDef={LIST_ITEMS.excel_output}
                                // masterType={master.type}
                                dataList={outItemList}
                                // setRowHeaderWidth={setRowHeaderWidth}
                                // setColWidth={setColWidth}
                                noSelector={true}
                                grid={{
                                  isReadOnly: true,
                                  allowAddNew: false,
                                  // frozenColumns: 1,
                                  imeEnabled: false,
                                  itemsSourceChanged: (g, e) => itemsSourceChanged(g, e)
                                }}
                                setInput={tktExcelAction.setInputTktExcel}
                              />
                            </div>
                          </React.Fragment>
                        }
                      />
                    </React.Fragment>
                  }
                />
              </React.Fragment>
            }
          />
          <Card 
            size='lg'
            root='button'
            hideClose
            header={null}
            subheader={null}
            body={
              <div className='card-buttom-button'>
                <Button
                  {...tktExcel.choice}
                  className='button-gray ml-auto mr-auto width-max-content'
                  disabled={app.isProcessing}
                  onClick={() => {
                    setOutItemList([])
                    settingPopup.show(true, (sender) => {
                      // if (document.getElementById(['uploadFile'])) document.getElementById(['uploadFile']).value = '';
                      // if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
                      //   console.log('ok')
                      // } else {
                      //   console.log('cancel')
                      // }
                      setIsEditOutList(true)
                    })
                  }}
                >
                  {tktExcel.choice.label}
                </Button>
              </div>
            }
          />
        </Pane>
        <Pane>
          <Card
            root={props.isModal?'max-button2':'max-button3'}
            size='lg'
            hideClose
            header={null}
            subheader={null}
            body={
              <React.Fragment>
                <div id='excel-output' className={`d-inline-block align-top width-p-35`}>
                  <div id='excel-output' className={`align-top`}>
                    <Card
                      root='max'
                      size='lg'
                      hideClose
                      header={TITLE.ticketExcel.setting.header}
                      subheader={TITLE.ticketExcel.setting.subheader}
                      body={
                        <React.Fragment>
                          <label sm="3" className='label-color mb-3 mr-0 text-left width-12'>
                            {tktExcel.label[1]}
                          </label>
                          <Common.Radio
                            btnSize='sm'
                            attrs={{...tktExcel[tab][`tktXlsiniHeader_${tab}`]}}
                            radios={TKT_XLSINI_HEADER}
                            onChange={(e) => {changeInput(e)}
                            }
                          />
                        </React.Fragment>
                      }
                    />
                  </div>
                  <div id='excel-output' className={`align-top`}>
                    <Card
                      root='max'
                      size='lg'
                      hideClose
                      header={TITLE.ticketExcel.sort.header}
                      subheader={TITLE.ticketExcel.sort.subheader}
                      body={
                        <React.Fragment>
                          <label sm="3" className='label-color mb-0 mr-0 text-left width-p-100'>
                            {tktExcel.label[2]}
                          </label>
                          <div className='d-flex flex-column'>
                            {[...Array(8).keys()].map(i =>
                              <Common.Select
                                attrs={{...tktExcel[tab][`tktXlsiniSort${++i}_${tab}`]}}
                                key={i}
                                group={{
                                  className: 'width-28 ml-2 mb-2',
                                  textwidth: 'width-28',
                                }}
                                minLength={1}
                                list={tab==='tkt'?tktExcel.tktSortList:tktExcel.fltSortList}
                                onChange={(e) => {
                                  changeInput(e)
                                }}
                              />
                            )}
                          </div>
                        </React.Fragment> 
                      }
                    />
                  </div>
                </div>
                
                <div id='excel-output' className={`d-inline-block align-top width-p-65`}>
                  <div id='excel-output' className={`align-top`}>
                    <Card
                      root='max'
                      size='lg'
                      hideClose
                      header={TITLE.ticketExcel.filename.header}
                      subheader={TITLE.ticketExcel.filename.subheader}
                      body={
                          <React.Fragment>
                            {!props.isModal && tab === 'tkt' &&
                              <div className='d-inline-flex ml-0 mt-0 mb-0 width-p-100'>
                                <div className='mt-2 mr-0'><label sm="3" className='label-color mb-0 mr-3 text-right width-12'>{tktExcel.label[17]}</label></div>
                                <div className='mr-0'>
                                  <Common.Radio
                                    btnSize='sm'
                                    attrs={{...tktExcel[tab][`tktXlsiniFileFlag_${tab}`], block: true}}
                                    className='mt-2 mb-4 mr-0'
                                    radios={TKT_XLSINI_FILE_FLAG}
                                    onChange={(e) => changeInput(e)}
                                  />
                                </div>
                                <div className='d-block width-p-100'>
                                  <div className='mt-0 mb-2' onClick={()=>{
                                    changeInput(convert.getTarget(tktExcel[tab][`tktXlsiniFileFlag_${tab}`], 'text', '0'))
                                  }}>
                                    <Common.Text
                                      attrs={{...tktExcel[tab][`tktXlsiniFile_${tab}`]}}
                                      onBlur={(e) => changeInput(e)}
                                      group={{
                                        className: 'width-p-80 mr-0 mb-2',
                                      }}
                                    />
                                  </div>
                                  <div className='d-inline-flex align-items-center' onClick={()=>{
                                    changeInput(convert.getTarget(tktExcel[tab][`tktXlsiniFileFlag_${tab}`], 'text', '1'))
                                  }}>
                                    <label className='label-color width-auto'>YYYYMMDDHHMMSS</label>
                                  </div>
                                </div>
                              </div>
                            }
                            {(props.isModal || tab === 'flt') &&
                              <div className='mb-2'>
                                <label sm="3" className='label-color mb-0 mr-3 text-right width-12'>
                                  {tktExcel.label[17]}
                                </label>
                                <Common.Text
                                  attrs={{...tktExcel[tab][`tktXlsiniFile_${tab}`]}}
                                  onBlur={(e) => changeInput(e)}
                                  group={{
                                    className: 'width-p-65 mr-0 mb-2',
                                  }}
                                />
                              </div>
                            }
                            {props.isModal &&
                              <React.Fragment>
                                {login.auth052==='1' &&
                                <div>
                                  <div className='mb-2'>
                                    <label sm="3" className='label-color mb-0 mr-3 text-right width-12'>
                                      {tktExcel.label[4]}
                                    </label>
                                    <Common.Check
                                      attrs={{...tktExcel.tkt.tktXlsiniClientSyncFlag_tkt}}
                                      onChange={(e) => changeInput(e)}
                                    />
                                  </div>
                                  <div className='mb-2'>
                                    <label sm="3" className='label-color mb-0 mr-3 text-right width-18'>
                                      {tktExcel.label[5]}
                                    </label>
                                    <Common.Text
                                      // btnSize='sm'
                                      attrs={{...tktExcel.tkt.tktXlsiniClientPath_tkt}}
                                      onBlur={(e) => changeInput(e)}
                                      group={{
                                        className: 'width-p-60 mr-0',
                                      }}
                                    />
                                  </div>
                                  <div className='mb-3'>
                                    <label sm="3" className='label-color mb-0 mr-3 text-right width-18'>
                                      {tktExcel.label[6]}
                                    </label>
                                    <Common.Radio
                                      btnSize='sm'
                                      attrs={{...tktExcel.tkt.tktXlsiniClientOutFile_tkt}}
                                      // className='mr-5'
                                      radios={CLIENT_OUT_FILE}
                                      onChange={(e) => changeInput(e)}
                                    />
                                    <div className='d-block'>
                                      <Common.Select
                                        attrs={{...tktExcel.tkt.tktXlsiniClientOutFilePattern_tkt}}
                                        group={{
                                          className: 'width-19 ml-320px',
                                          textwidth: 'width-19',

                                        }}
                                        minLength={1}
                                        list={CLIENT_OUT_FILE_PATTERN}
                                        onChange={(e) => changeInput(e)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                }
                                {login.authFtp==='1' &&
                                <div>
                                  <div className='mb-2'>
                                    <label sm="3" className='label-color mb-0 mr-3 text-right width-12'>
                                      {tktExcel.label[4]}
                                    </label>
                                    <Common.Check
                                      attrs={{...tktExcel.tkt.tktXlsiniS3Flag_tkt}}
                                      onChange={(e) => changeInput(e)}
                                    />
                                  </div>
                                  <div className='mb-3'>
                                    <label sm="3" className='label-color mb-0 mr-3 text-right width-18'>
                                      {tktExcel.label[5]}
                                    </label>
                                    <Common.Text
                                      // btnSize='sm'
                                      attrs={{...tktExcel.tkt.tktXlsiniS3Path_tkt}}
                                      onBlur={(e) => changeInput(e)}
                                      group={{
                                        className: 'width-p-60 mr-0',
                                      }}
                                    />
                                  </div>
                                  <div className='mb-3'>
                                    <label sm="3" className='label-color mb-0 mr-3 text-right width-18'>
                                      {tktExcel.label[6]}
                                    </label>
                                    <Common.Radio
                                      btnSize='sm'
                                      attrs={{...tktExcel.tkt.tktXlsiniS3OutFile_tkt}}
                                      // className='mr-5'
                                      radios={S3_OUT_FILE}
                                      onChange={(e) => changeInput(e)}
                                    />
                                    <div className='d-block'>
                                      <Common.Select
                                        attrs={{...tktExcel.tkt.tktXlsiniS3OutFilePattern_tkt}}
                                        group={{
                                          className: 'width-19 ml-320px',
                                          textwidth: 'width-19',

                                        }}
                                        minLength={1}
                                        list={S3_OUT_FILE_PATTERN}
                                        onChange={(e) => changeInput(e)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                }
                                <div className='mb-2'>
                                  <label sm="3" className='label-color mb-0 mr-3 text-right width-12'>
                                    {tktExcel.label[3]}
                                  </label>
                                  <Common.Check
                                    attrs={{...tktExcel.tkt.tktXlsiniPccdiv_tkt}}
                                    onChange={(e) => changeInput(e)}
                                  />
                                </div>
                              </React.Fragment>
                            }
                        </React.Fragment>
                      }
                    />
                  </div>
                  <div id='excel-output' className={`align-top`}>
                    <Card
                      root='max'
                      size='lg'
                      hideClose
                      header={TITLE.ticketExcel.option.header}
                      subheader={TITLE.ticketExcel.option.subheader}
                      body={
                        <React.Fragment>
                          <div className='mb-3'>
                            <label sm="3" className='label-color mb-0 ml-3 text-left width-20'>
                              {tktExcel.label[8]}
                            </label>
                            <Common.Radio
                              btnSize='sm'
                              attrs={{...tktExcel[tab][`tktXlsiniPex_${tab}`]}}
                              // className='mr-5'
                              radios={OUT_PEX}
                              className='width-5-14'
                              onChange={(e) => changeInput(e)}
                            />
                          </div>
                          <div className='mb-3'>
                            <label sm="3" className='label-color mb-0 ml-3 text-left width-20'>
                              {tktExcel.label[9]}
                            </label>
                            <Common.Radio
                              btnSize='sm'
                              attrs={{...tktExcel[tab][`tktXlsiniCsmtax_${tab}`]}}
                              // className='mr-5'
                              radios={TKT_XLSINI_CSMTAX}
                              className='width-5-14'
                              onChange={(e) => changeInput(e)}
                            />
                          </div>
                          <div className='mb-3'>
                            <label sm="3" className='label-color mb-0 ml-3 text-left width-20'>
                              {tktExcel.label[10]}
                            </label>
                            <Common.Radio
                              btnSize='sm'
                              attrs={{...tktExcel[tab][`tktXlsiniPrd_${tab}`]}}
                              // className='mr-5'
                              radios={TKT_XLSINI_PRD}
                              className='width-5-14'
                              onChange={(e) => changeInput(e)}
                            />
                          </div>
                          {tab === 'flt' &&
                            <React.Fragment>
                              <div className='mb-3'>
                                <label sm="3" className='label-color mb-0 mr-0 text-right width-24'/>
                                <label sm="3" className='label-color mb-0 mr-0 text-left width-13'>
                                  {tktExcel.label[14]}
                                </label>
                                <Common.Radio
                                  btnSize='sm'
                                  attrs={{
                                    ...tktExcel[tab][`tktXlsiniPrdEx_${tab}`],
                                    disabled: tktExcel[tab][`tktXlsiniPrdEx_${tab}`].disabled || tktExcel[tab][`tktXlsiniPrd_${tab}`].text !== '1'
                                  }}
                                  // className='mr-5'
                                  radios={TKT_XLSINI_PRD_EX}
                                  onChange={(e) => changeInput(e)}
                                />
                              </div>
                              <div className='mb-3'>
                                <label sm="3" className='label-color mb-0 mr-0 text-right width-24'/>
                                <label sm="3" className='label-color mb-0 mr-0 text-left width-13'>
                                  {tktExcel.label[15]}
                                </label>
                                <Common.Radio
                                  btnSize='sm'
                                  attrs={{
                                    ...tktExcel[tab][`tktXlsiniPrdKb0_${tab}`],
                                    disabled: tktExcel[tab][`tktXlsiniPrdKb0_${tab}`].disabled || tktExcel[tab][`tktXlsiniPrd_${tab}`].text !== '1'
                                  }}
                                  // className='mr-5'
                                  radios={TKT_XLSINI_PRD_KB0}
                                  onChange={(e) => changeInput(e)}
                                />
                              </div>
                              <div className='mb-3'>
                                <label sm="3" className='label-color mb-0 ml-3 text-left width-20'>
                                  {tktExcel.label[16]}
                                </label>
                                {[...Array(4).keys()].map(i =>
                                  <Common.Check
                                    attrs={{...tktExcel[tab][`tktXlsiniFltseg${i+1}_${tab}`]}}
                                    key={i}
                                    onChange={(e) => changeInput(e)}
                                  />
                                )}
                              </div>
                              <div className='mb-3 d-flex align-start'>
                                <label sm="3" className='label-color mb-0 ml-3 text-left width-20'>
                                  {tktExcel.label[18]}
                                </label>
                                <div className='d-inline-block'>
                                  <Common.Radio
                                    btnSize='sm'
                                    attrs={{...tktExcel[tab][`tktXlsiniTotal_${tab}`], block: true}}
                                    // className='mr-5'
                                    radios={TKT_XLSINI_TOTAL}
                                    onChange={(e) => changeInput(e)}
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          }
                          {tab === 'tkt' && login.auth051 === '1' &&
                            <div className='mb-3'>
                              <label sm="3" className='label-color mb-0 ml-3 text-left width-20'>
                                {tktExcel.label[11]}
                              </label>
                              <Common.Radio
                                btnSize='sm'
                                attrs={{...tktExcel[tab][`tktXlsiniPlusType_${tab}`]}}
                                // className='mr-5'
                                radios={TKT_XLSINI_PLUS_TYPE}
                                className='width-5-14'
                                onChange={(e) => changeInput(e)}
                              />
                            </div>
                          }
                          <div className='mb-3 d-flex align-start'>
                            <label sm="3" className='label-color mb-0 ml-3 text-left width-20'>
                              {tktExcel.label[12]}
                            </label>
                            <div className='d-inline-block'>
                              <Common.Radio
                                btnSize='sm'
                                attrs={{...tktExcel[tab][`tktXlsiniGrossNetSame_${tab}`], block: true,}}
                                // className='mr-5'
                                radios={TKT_XLSINI_GROSS_NET_SAME}
                                onChange={(e) => changeInput(e)}
                              />
                            </div>
                          </div>
                          <div className='mb-3'>
                            <label sm="3" className='label-color mb-0 ml-3 text-left width-20'>
                              {tktExcel.label[13]}
                            </label>
                            <Common.Radio
                              btnSize='sm'
                              attrs={{...tktExcel[tab][`tktXlsiniPcc_${tab}`]}}
                              // className='mr-5'
                              radios={TKT_XLSINI_PCC}
                              className='width-5-14'
                              onChange={(e) => changeInput(e)}
                            />
                          </div>
                          <div className='mb-5'>
                            <label sm="3" className='label-color mb-0 ml-3 text-left width-20'>
                              {tktExcel.label[19]}
                            </label>
                            <Common.Radio
                              btnSize='sm'
                              attrs={{...tktExcel[tab][`tktEmdMpdFlag_${tab}`]}}
                              // className='mr-5'
                              radios={TKT_EMD_MPD_FLAG}
                              className='width-5-14'
                              onChange={(e) => changeInput(e)}
                            />
                          </div>
                        </React.Fragment>
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            }
          />
          <Card 
            size='lg'
            root='button'
            hideClose
            header={null}
            subheader={null}
            body={
              <div className='card-buttom-button'>
                <Button
                  {...tktExcel.pattern}
                  className='button-gray mr-auto width-max-content'
                  disabled={app.isProcessing}
                  onClick={() => execPattern()}
                >
                  {tktExcel.pattern.label}
                </Button>
                {!props.isModal &&
                  <Button
                    {...tktExcel.outputExcel}
                    className='button-blue ml-auto width-max-content'
                    disabled={app.isProcessing}
                    onClick={() => execExcel()}
                  >
                    {tktExcel.outputExcel.label}
                  </Button>
                }
                {props.isModal &&
                  <React.Fragment>
                    <Button
                      {...tktExcel.cancel}
                      className='button-gray ml-auto mr-auto width-max-content'
                      disabled={app.isProcessing}
                      onClick={() => props.onClose()}
                    >
                      {tktExcel.cancel.label}
                    </Button>
                    <Button
                      {...tktExcel.setting}
                      className='button-blue ml-auto width-max-content'
                      disabled={app.isProcessing}
                      onClick={() => props.execSetting()}
                    >
                      {tktExcel.setting.label}
                    </Button>
                  </React.Fragment>
                }
              </div>
            }
          />
        </Pane>
      </SplitPane>
    </React.Fragment>
  )
}