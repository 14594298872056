import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktExportAction } from 'ducks/TktExport';
import * as Common from 'components/common';
import { Title, SearchList, Condition, SearchResult } from 'components';
import { 
  Col,
  Row,
  Container,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, makeCondition } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { TITLE } from 'constants/title';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import {TicketApi } from 'webapi'

export const TicketExportSearchComponent = () => {

  const app = useSelector(state => state.App);
  const tktExport = useSelector(state => state.TktExport);

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const tktExportAction = bindActionCreators(TktExportAction, dispatch);

  return (
    <React.Fragment>
      <Container fluid className='main'>
        <Title title={TITLE.ticketExportSearch.name} icon={TITLE.ticketExportSearch.icon}/>
        <SplitPane split="horizontal" className='split-pane-top'
          onChange={(size) => {tktExportAction.setInputTktExport({sizeHorizontal: size})}}>
          <SplitPane split="vertical" initialSize={tktExport.sizeHorizontal[0]}
            onChange={(size) => {tktExportAction.setInputTktExport({sizeVertical: size})}}>
            <Pane initialSize={tktExport.sizeVertical[0]}>
              <SearchList
                title={TITLE.ticketExportSearch.searchList}
                tktSrchptPrivForm='B0401'
                search={tktExport}
                onBlur={tktExportAction.changeInputTktExport}
                setInput={tktExportAction.setInputTktExport}
                remove={tktExportAction.removeTktExportItem}
                add={tktExportAction.addTktExportItem}
                searchApi={TicketApi}
                checkedItems={tktExport.checkedItems}
                checkedItemsSetting={tktExport.checkedItemsSetting}
                checkedItemsChanged={(e) => {
                  if (e.selectedItem) {
                    // if (_.findIndex(e.checkedItems, e.selectedItem) < 0) {
                    if (_.findIndex(e.checkedItems, o => o.key === e.selectedItem.key) < 0) {
                      tktExportAction.removeTktExportItem([e.selectedItem])
                    } else {
                      const selectedItem = {
                        key: e.selectedItem.key,
                        type: e.selectedItem.type,
                      }
                      tktExportAction.setInputTktExport(makeCondition(e.selectedItem, tktExport))
                      tktExportAction.addTktExportItem([e.selectedItem])
                      // focus
                      let element = null
                      setTimeout(() => {
                        if (selectedItem.type==='check') {
                          element = document.getElementById(
                            selectedItem.key + '_' +
                            CHECKS[selectedItem.key][0].id);
                        // } else if (e.selectedItem.type==='radio') {
                        //   element = document.getElementById(e.selectedItem.key + -0);
                        } else {
                          element = document.getElementById(selectedItem.key + '_array');
                          element = element?element:document.getElementById(selectedItem.key + '_0');
                          element = element?element:document.getElementById(selectedItem.key + '_from');
                        }
                        //
                        // console.log(element)
                        element && element.focus();
                      });
                    }
                  }
                  tktExportAction.setInputTktExport({listChanged: false})
                }}
              />
            </Pane>
            <Pane>
              <Condition
                search={tktExport}
                onBlur={tktExportAction.changeInputTktExport}
                setInput={tktExportAction.setInputTktExport}
                title={TITLE.ticketExportSearch.condition}
                onClose={tktExportAction.removeTktExportItem}
                // searchApi={TicketApi.findSearch}
                searchApi={TicketApi}
                tktSrchptPrivForm='B0401'
              />
            </Pane>
          </SplitPane>
          <Pane>
            <SearchResult
              search={tktExport}
              onBlur={tktExportAction.changeInputTktExport}
              setInput={tktExportAction.setInputTktExport}
              title={TITLE.ticketExportSearch.result}
              searchApi={TicketApi}
              tktSrchptPrivForm='B0401'
            />
          </Pane>
        </SplitPane>
      </Container>
      <Common.RouteLeavingGuard
        when={true}
        shouldBlockNavigation={()=>false}
      />
    </React.Fragment>
  )
}