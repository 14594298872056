import _ from 'lodash';

export const MAX_CONDITION = 16
export const MAX_CONDITION_MESSAGE = `件 / ${MAX_CONDITION}件`
export const TOOL_TIP = {
  wildcard: `ワイルドカードとは、任意の文字列を指定する際に使用する特殊文字のことです。
ここでは、「*」を任意の複数文字（0文字以上の文字）、「?」を任意の1文字として使用できます。`,
  codelist: `クーポンステータスコード一覧
AVBL/OPEN：AVAILABLE、OPEN FOR USE
ACON：AIRPORT CONTROL
CKIN：CHECKED IN
USED：USED
RFND：REFUNDED
EXCH：EXCHANGED
VOID：VOID
SUSP：SUSPENDED
SCRD：SECURED
CLRD：CLEARED
ACLS：AUTO-CLOSED COUPON
CNCL：CANCEL
INFO：INFO ONLY
LFTD：LIFTED/BOARDED
CLSD：CLOSED
IREG：IRREGULAR OPERATIONS
UNAV：UNAVAILABLE
PRTX：PRINT EXCHANGE
PRNT：PRINTED/REPRINT
PTKT：PAPER TICKET`,
}

export const SEARCH = [
  {
    header: '<span class="material-icons-outlined sm mr-1">timer</span>最近使った検索条件',
    // items:[{header: '<div><span>AIRLINEコード</span><button>aa</button></div>', subheader: <div />, type: 'code', key: 'tktTktNoAl', cnt: '16'},],
    // items:[],
  },
  {
    header: '<span class="material-icons-outlined sm mr-1">folder</span>検索項目',
    items:[
      {
        header: '<span class="material-icons-outlined sm mr-1">folder</span>TKT基本',
        items: [
          {header: 'AIRLINEコード', subheader: <div />, type: 'code', key: 'tktTktNoAl', cnt: '16'},
          {header: 'TKT NO.(S/N)', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_range', key: 'tktTktNo', cnt: '4'},
          {header: 'PAX NAME', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text', key: 'tktPaxName', cnt: '2'},
          {header: 'PAX TYPE', subheader: <div />, type: 'check', key: 'tktPaxType', cnt: '3'},
          {header: 'PTC（旅客タイプコード）', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_in', key: 'tktPtc', cnt: '10'},
          {header: '発券日', subheader: <div />, type: 'date', key: 'tktIsuDate', cnt: ''},
          {header: '処理日', subheader: <div />, type: 'date', key: 'tktProDate', cnt: ''},
          {header: '受信日時', subheader: <div />, type: 'datetime', key: 'tktRcvDate', cnt: ''},
          {header: '登録日時', subheader: <div />, type: 'datetime', key: 'tktRstDate', cnt: ''},
          {header: '出発日', subheader: <div />, type: 'date', key: 'tktDepDate', cnt: ''},
          {header: '国際線出発日', subheader: <div />, type: 'date', key: 'tktIntDepDate', cnt: ''},
          {header: 'CONJUNCTION TKT', subheader: <div />, type: 'radio', key: 'conjunctionTkt', cnt: '2'},
          {header: 'FARE CALCULATION MODE INDICATOR', subheader: <div />, type: 'radio', key: 'tktFareCalMode', cnt: '4'},
          {header: 'FARE CALCULATION PRICING INDICATOR', subheader: <div />, type: 'text_in_blank', key: 'tktFareCalPrice', cnt: ''},
          {header: 'COMMISSION RATE', subheader: <div />, type: 'text_in_range', key: 'tktCommRate', cnt: '7'},
          {header: 'ENDORSEMENTS/RESTRICTIONS', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text', key: 'tktEndRest', cnt: '1'},
          {header: 'FARE BASIS', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text', key: 'fltFareBasis', cnt: '4'},
          {header: 'NVB', subheader: <div />, type: 'date', key: 'fltNvb', cnt: ''},
          {header: 'NVA', subheader: <div />, type: 'date', key: 'fltNva', cnt: ''},
          {header: 'BAGGAGE ALLOWANCE', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_in', key: 'fltBagAlw', cnt: '6'},
          {header: '運賃種別', subheader: <div />, type: 'check', key: 'tktFareType', cnt: '6'},
          {header: 'TKTステータス', subheader: <div />, type: 'check', key: 'tktStatus', cnt: '7'},
          {header: '券種', subheader: <div />, type: 'check', key: 'tktTktType', cnt: '3'},
          {header: 'GDS区分', subheader: <div />, type: 'check', key: 'tktCrs', cnt: '7'},
          {header: 'PNR リファレンス', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text', key: 'tktPnr', cnt: '9'},
          {header: 'IATAコード', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_in', key: 'tktIata', cnt: '20'},
          {header: '予約OFFICE ID/PCC', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_in', key: 'tktBookingPcc', cnt: '8'},
          {header: '発券OFFICE ID/PCC', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_in', key: 'tktTktPcc', cnt: '8'},
        ]
      },
      {
        header: '<span class="material-icons-outlined sm mr-1">folder</span>アカウントコード',
        items: [
          {header: '受注コード（R）', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code_range', key: 'tkt7LOdrCd', cnt: '2'},
          {header: 'セクションコード（S）', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code_range', key: 'tkt7LSecCd', cnt: '4'},
          {header: '担当者コード（A）', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code_range', key: 'tkt7LMbrCd', cnt: '4'},
          {header: '法人コード（C）', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code_range', key: 'tkt7LComCd', cnt: '3'},
          {header: '法人区分', subheader: <div />, type: 'check', key: 'comKbn', cnt: '2'},
          {header: '方面コード（D）', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code_range', key: 'tkt7LAreaCd', cnt: '6'},
          {header: 'FREEコード（F）', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code_range', key: 'tkt7LFreeCd', cnt: '3'},
          {header: '入金コード（M）', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code_range', key: 'tkt7LPayCd', cnt: '8'},
          {header: 'ツアーコード（T）', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code_range', key: 'tkt7LTourCd', cnt: '2'},
          {header: 'KBコード1（K）', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code_range', key: 'tkt7LKb1Cd', cnt: '4'},
          {header: 'KBコード2（B）', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code_range', key: 'tkt7LKb2Cd', cnt: '4'},
          {header: 'E/Oコード（E）', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code_range', key: 'tkt7LEoCd', cnt: '4'},
    
        ]
      },
      {
        header: '<span class="material-icons-outlined sm mr-1">folder</span>運賃・精算',
        items: [
          {header: 'FORM OF PAYMENT', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text', key: 'tktFormPay', cnt: '1'},
          {header: 'TAX INDICATOR', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text', key: 'taxTaxInd', cnt: '10'},
          {header: 'NR-ID(NET REMITTANCE INDICATOR)', subheader: <div />, type: 'radio', key: 'tktNrid', cnt: '2'},
          {header: 'VC', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_multi', key: 'tktVc', cnt: '7'},
          {header: 'CAR', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_multi', key: 'tktCar', cnt: '4'},
          {header: 'TOUR CODE', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_multi', key: 'tktTourCd', cnt: '3'},
          // {header: '旅行会社コード（G）/OrderID', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_multi', key: 'tkt7K', cnt: '2'},
          {header: '月跨ぎフラグ', subheader: <div />, type: 'radio', key: 'tktMonthStepoverFlg', cnt: '2'},
        ]
      },
      {
        header: '<span class="material-icons-outlined sm mr-1">folder</span>固定帳票出力',
        items: [
          {header: 'チケット明細発行済フラグ', subheader: <div />, type: 'radio', key: 'tktDetailIssuedFlg', cnt: ''},
          {header: 'お客様用発行済フラグ', subheader: <div />, type: 'radio', key: 'tktCustomerOutputFlg', cnt: '2'},
          {header: 'AGENT COUPON発行済フラグ', subheader: <div />, type: 'radio', key: 'tktAgentCouponOutputFlg', cnt: '2'},
          {header: 'CCCF発行済フラグ', subheader: <div />, type: 'radio', key: 'tktCccfOutputFlg', cnt: '2'},
          {header: 'REFUND NOTICE発行済フラグ', subheader: <div />, type: 'radio', key: 'tktRefundNoticeOutputFlg', cnt: '2'},
        ]
      },
      {
        header: '<span class="material-icons-outlined sm mr-1">folder</span>フライト情報',
        items: [
          {header: 'FLIGHT AIRLINEコード', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code', key: 'fltAirlineCd', cnt: '16'},
          {header: '便名', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text', key: 'fltFltNum', cnt: '8'},
          {header: '搭乗クラス', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_in', key: 'fltClass', cnt: '10'},
          {header: '搭乗日時', subheader: <div />, type: 'datetime', key: 'fltBoardDateTime', cnt: ''},
          {header: '到着日時', subheader: <div />, type: 'datetime', key: 'fltArrDateTime', cnt: ''},
          {header: 'フライト区間種別', subheader: <div />, type: 'check_in', key: 'fltIndByteTkt', cnt: '4'},
          {header: '出発地', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code', key: 'fltFrom', cnt: '14'},
          {header: '到着地', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'code', key: 'fltTo', cnt: '14'},
        ]
      },
      {
        header: '<span class="material-icons-outlined sm mr-1">folder</span>VOID',
        items: [
          {header: 'VOID ID', subheader: <div />, type: 'radio_check', key: 'tktVoidId', cnt: '2'},
          {header: 'VOID処理日', subheader: <div />, type: 'date', key: 'tktVoidDate', cnt: ''},
        ]
      },
      {
        header: '<span class="material-icons-outlined sm mr-1">folder</span>REFUND',
        items: [
          {header: 'REFUND ID', subheader: <div />, type: 'radio_check', key: 'tktRefId', cnt: '2'},
          {header: 'REFUND区分（F/A/L/M）', subheader: <div />, type: 'check', key: 'tktRefType', cnt: '4'},
          // {header: 'REFUND種別（FULL/PARTIAL）', subheader: <div />, type: 'check', key: 'tktRefClass', cnt: '2'},
          {header: 'REFUND処理日', subheader: <div />, type: 'date', key: 'tktRefDate', cnt: ''},
          {header: 'WAIVER CODE', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_in_blank', key: 'tktWaiverCode', cnt: '6'},
          {header: 'SAC', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_multi', key: 'tktSac', cnt: '3'},
          {header: 'REFUND TAX INDICATOR', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text', key: 'rtxRfTaxNo', cnt: '10'},
        ]
      },
      {
        header: '<span class="material-icons-outlined sm mr-1">folder</span>REISSUE',
        items: [
          {header: 'REISSUE ID', subheader: <div />, type: 'radio', key: 'tktReissueId', cnt: '2'},
          {header: 'ORIGINAL ISSUE', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_blank', key: 'tktOrgIsu', cnt: '1'},
          {header: 'ISSUED IN EXCHANGE', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_blank', key: 'tktIsuEx', cnt: '1'},
        ]
      },
      {
        header: '<span class="material-icons-outlined sm mr-1">folder</span>CARD情報',
        items: [
          {header: 'クレジットカード会社コード', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_in_blank', key: 'tktCreditCom', cnt: '10'},
          {header: 'クレジットカードNO.', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text', key: 'tktCardNum', cnt: '5'},
          {header: 'CARD有効期限', subheader: <div />, type: 'mmyy', key: 'tktCaExDate', cnt: '4'},
          {header: 'CARD会員名', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text', key: 'tktCaMem', cnt: '2'},
          {header: 'CARD承認番号', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text', key: 'caAprNo', cnt: '8'},
          {header: '機能分類コード', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_in_blank', key: 'tktFuncCd', cnt: '10'},
          {header: 'CARD REMARKS1', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_in_blank', key: 'tktRmks1', cnt: '4'},
          {header: 'CARD REMARKS2', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_in_blank', key: 'tktRmks2', cnt: '4'},
          {header: 'CARD REMARKS3', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_in_blank', key: 'tktRmks3', cnt: '4'},
        ]
      },
      {
        header: '<span class="material-icons-outlined sm mr-1">folder</span>EMD情報',
        items: [
          {header: 'CONJUNCTION TICKET DATA', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_blank', key: 'conjunctionTicketData', cnt: '1'},
          {header: 'REASON FOR ISSUANCE CODE', subheader: <div />, type: 'text_in', key: 'reasonForIssuanceCd', cnt: '8'},
          {header: 'REASON FOR ISSUANCE SUB CODE', subheader: <div />, type: 'text_in', key: 'reasonForIssueanceSubcode', cnt: '20'},
          // {header: 'CONSUMED AT ISSUANCE INDICATOR', subheader: <div />, type: 'radio', key: 'emdConsumedIssuaranceInd', cnt: '2'},
        ]
      },
      {
        header: '<span class="material-icons-outlined sm mr-1">folder</span>NDC情報',
        items: [
          {header: 'NDCフラグ', subheader: <div />, type: 'radio', key: 'ndcFlg', cnt: '2'},
          {header: 'ORDER ID', isWildCard: true, subheader: <div className="tooltip-text" title={TOOL_TIP.wildcard}>ワイルドカードが使えます</div>, type: 'text_blank', key: 'orderId', cnt: '1'},
        ]
      },
    ]
  },
  {
    header: '<span class="material-icons-outlined sm mr-1">source</span>検索条件パターン',
    // items:[{header: '<div><span>AIRLINEコード</span><button>aa</button></div>', subheader: <div />, type: 'code', key: 'tktTktNoAl', cnt: '16'},],
    // items:[],
  },
];
export const NUMBER = {
  number: {
    id: 'number',
    label: '_none',
    suffix: '日前',
    required: false,
    disabled: false,
    min: 0,
    max: 10,
    step: 1,
    maxLength: 2,
    enter: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
}
export const RADIOS = {
  attr: {
    radio: {
      id: 'radio',
      label: '',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
    },
  },
  searchUnit: [
    {name: 'チケット単位', value: '0'},
    {name: 'フライト単位', value: '1'},
  ],
  condition: [
    {name: 'AND', value: '0'},
    {name: 'OR', value: '1'},
  ],
  clude: [
    {name: '指定', value: '0'},
    {name: '除外', value: '1'},
  ],
  choice: [
    {name: '範囲指定', value: '0'},
    {name: '複数指定', value: '1'},
  ],
  blank: [
    {name: 'ブランクデータ', value: '1'},
    {name: '指定', value: '0'},
  ],
  choiceBlank: [
    {name: 'ブランクデータ', value: '2'},
    {name: '範囲指定', value: '0'},
    {name: '複数指定', value: '1'},
  ],
  choiceDate: [
    {name: '任意', value: '0'},
    {name: '前月', value: '1'},
    {name: '今月', value: '2'},
    {name: '前日', value: '3'},
    {name: '本日', value: '4'},
  ],
  //
  conjunctionTkt: [
    {name: 'CONJ.TKTを含める', value: '1'},
    {name: 'CONJ.TKTを含めない', value: '0'},
  ],
  tktFareCalMode: [
    {name: '0(System auto priced)', value: '0'},
    {name: '1 (Manually Priced Fare)', value: '1'},
    {name: '2(System auto priced with manual manipulation of TAX and Baggage)', value: '2'},
    {name: 'ブランク', value: '4'},
  ],
  tktStfExp: [
    {name: '有り', value: '1'},
    {name: '無し', value: '0'},
  ],
  tktNrid: [
    {name: '無し', value: '0'},
    {name: '有り', value: '1'},
  ],
  tktMonthStepoverFlg: [
    {name: '無し', value: '0'},
    {name: '有り', value: '1'},
  ],
  tktDetailIssuedFlg: [
    {name: '未発行', value: '0'},
    {name: '発行済', value: '1'},
  ],
  tktCustomerOutputFlg: [
    {name: '未発行', value: '0'},
    {name: '発行済', value: '1'},
  ],
  tktAgentCouponOutputFlg: [
    {name: '未発行', value: '0'},
    {name: '発行済', value: '1'},
  ],
  tktCccfOutputFlg: [
    {name: '未発行', value: '0'},
    {name: '発行済', value: '1'},
  ],
  tktRefundNoticeOutputFlg: [
    {name: '未発行', value: '0'},
    {name: '発行済', value: '1'},
  ],
  ndcFlg: [
    {name: 'NDC', value: '1'},
    {name: 'NDC以外', value: '0'},
  ],
  tktReissueId: [
    {name: 'REISSUEデータ以外', value: '0'},
    {name: 'REISSUEデータ', value: '1'},
  ],
  emdConsumedIssuaranceInd: [
    {name: '無し', value: '0'},
    {name: '有り', value: '1'},
  ],
  tktVoidId: [
    {name: 'VOIDデータ以外', value: '0'},
    {name: 'VOIDデータ', value: '1'},
  ],
  tktRefId: [
    {name: 'REFUNDデータ以外', value: '0'},
    {name: 'REFUNDデータ', value: '1'},
  ],
  tktFareCalPrice: [
    {name: '0', value: '0'},
    {name: '1', value: '1'},
    {name: '2', value: '2'},
    {name: '3', value: '3'},
    {name: '4', value: '4'},
    {name: '5', value: '5'},
    {name: '6', value: '6'},
    {name: '7', value: '7'},
    {name: '8', value: '8'},
    {name: '9', value: '9'},
  ],
  windowOpen: [
    {name: '別タブで開く ', value: 'O'},
    {name: '同一タブで開く', value: 'I'},
  ],
}
export const CHECKS = {
  attr: {
    check: {
      id: 'check',
      label: 'CHECK1',
      required: false,
      disabled: false,
      original: '',
      value: '',
      text: '',
      dirty: '',
      isInvalid: false,
      helpertext: '',
    },
  },
  period: [
    {id: '1', label: '1P', },
    {id: '2', label: '2P', },
    {id: '3', label: '3P', },
    {id: '4', label: '4P', },
  ],
  tktPaxType: [
    {id: 'adultFlg', label: 'ADULT', },
    {id: 'childFlg', label: 'CHILD', },
    {id: 'infantFlg', label: 'INFANT', },
  ],
  tktFareType: [
    {id: 'nomFlg', label: 'NOM(NORMAL)', },
    {id: 'pexFlg', label: 'PEX(PEX)', },
    {id: 'gitFlg', label: 'GIT(GROUP IT)', },
    {id: 'iitFlg', label: 'IIT(INDIVIDUAL IT)', },
    {id: 'othFlg', label: 'OTH(OTHER)', },
    {id: 'chtFlg', label: 'CHT(CHARTER)', },
  ],
  tktStatus: [
    {id: 'normalFlg', label: '通常', },
    {id: 'onlineVoidFlg', label: 'オンラインVOID', },
    {id: 'manualVoidFlg', label: 'マニュアルVOID', },
    {id: 'autoRefundFlg', label: 'AUTO REFUND', },
    {id: 'manualRefundFlg', label: 'MANUAL REFUND', },
    {id: 'reissueFlg', label: 'REISSUE元券', },
    {id: 'conjunctionFlg', label: 'REISSUE新券', },
  ],
  tktTktType: [
    {id: 'etFlg', label: 'ET', },
    {id: 'emdFlg', label: 'EMD', },
    {id: 'mpdFlg', label: 'MPD', },
    {id: 'mcoFlg', label: 'MCO', },
    {id: 'opatb2Flg', label: 'OPATB2', },
  ],
  tktCrs: [
    {id: 'amadeusFlg', label: 'AMADEUS', },
    {id: 'infniFlg', label: 'INFINI', },
    {id: 'apolloFlg', label: 'TRAVELPORT', },
    {id: 'sabreFlg', label: 'SABRE', },
    {id: 'axessFlg', label: 'AXESS', },
    {id: 'worldspanFlg', label: 'WORLDSPAN', },
    {id: 'otherFlg', label: 'OTHER', },
  ],
  tktAgainId: [
    {id: 'U', label: '未再送データ', },
    {id: 'R', label: '再送データ', },
    {id: 'A', label: 'アカウントコード/7U再送データ', },
  ],
  tktIsuHost: [
    {id: 'U', label: 'Uホスト', },
    {id: 'O', label: 'オリジナルホスト', },
  ],
  tktRefType: [
    {id: 'frefFlg', label: 'F', },
    {id: 'arefFlg', label: 'A', },
    {id: 'lrefFlg', label: 'L', },
    {id: 'mrefFlg', label: 'M', },
  ],
  // tktRefClass: [
  //   {id: 'F', label: 'FULL REFUND', },
  //   {id: 'P', label: 'PARTIAL REFUND', },
  // ],
  comKbn: [
    {id: 'normalFlg', label: '一般', },
    {id: 'companyFlg', label: '旅行会社', },
  ],
  fltIndByteTkt: [
    {id: 'nation', label: '日本国内線区間', },
    {id: 'nationOther', label: '日本発着以外の区間', },
    {id: 'leaveInternational', label: '日本発国際線区間', },
    {id: 'arrivalInternational', label: '日本着国際線区間', },
  ],
  tktVoidId: [
    {id: 'manualVoidFlg', label: 'マニュアルVOID', },
    {id: 'onlineVoidFlg', label: 'オンラインVOID', },
  ],
  tktRefId: [
    {id: 'autoRefundFlg', label: 'AUTO REFUND', },
    {id: 'manualRefundFlg', label: 'MANUAL REFUND', },
  ],
}

export const COND_ITEMS = {
  tktTktNoAl:{
    id: 'tktTktNoAl',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 3,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  tktTktNo:{
    id: 'tktTktNo',
    label: '_none',
    placeholder: '１０桁の数字',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['num'],
    stringcase: '',
  },
  tktPaxName:{
    id: 'tktPaxName',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 68,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktPaxType:{
    id: 'tktPaxType',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktPtc:{
    id: 'tktPtc',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 3,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  tktIsuDate:{
    id: 'tktIsuDate',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date',
    format: 'yyyy/MM/dd',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktProDate:{
    id: 'tktProDate',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date',
    format: 'yyyy/MM/dd',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktRcvDate:{
    id: 'tktRcvDate',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'datetime',
    format: 'yyyy/MM/dd HH:mm',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktRstDate:{
    id: 'tktRstDate',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'datetime',
    format: 'yyyy/MM/dd HH:mm',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktDepDate:{
    id: 'tktDepDate',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date',
    format: 'yyyy/MM/dd',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktIntDepDate:{
    id: 'tktIntDepDate',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date',
    format: 'yyyy/MM/dd',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  conjunctionTkt:{
    id: 'conjunctionTkt',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktFareCalForm:{
    id: 'tktFareCalForm',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktFareCalMode:{
    id: 'tktFareCalMode',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktFareCalMode:{
    id: 'tktFareCalMode',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktCommRate:{
    id: 'tktCommRate',
    label: '_none',
    suffix: '%',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 8,
    min: 0,
    max: 99.99,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: 'n2',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['commrate'],
    stringcase: '',
  },
  tktEndRest:{
    id: 'tktEndRest',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 147,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum_sp'],
    stringcase: 'upper',
  },
  fltFareBasis:{
    id: 'fltFareBasis',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 15,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  fltNvb:{
    id: 'fltNvb',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date',
    format: 'yyyy/MM/dd',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  fltNva:{
    id: 'fltNva',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date',
    format: 'yyyy/MM/dd',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  fltBagAlw:{
    id: 'fltBagAlw',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 3,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  tktFareType:{
    id: 'tktFareType',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktStatus:{
    id: 'tktStatus',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktTktType:{
    id: 'tktTktType',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktCrs:{
    id: 'tktCrs',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktPnr:{
    id: 'tktPnr',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 6,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktIata:{
    id: 'tktIata',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 7,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['num'],
    stringcase: '',
  },
  tktBookingPcc:{
    id: 'tktBookingPcc',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 9,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  tktTktPcc:{
    id: 'tktTktPcc',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 9,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  tktOon:{
    id: 'tktOon',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktStfExp:{
    id: 'tktStfExp',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktAgainId:{
    id: 'tktAgainId',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktIsuHost:{
    id: 'tktIsuHost',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tkt7LOdrCd:{
    id: 'tkt7LOdrCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 12,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tkt7LSecCd:{
    id: 'tkt7LSecCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 6,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tkt7LMbrCd:{
    id: 'tkt7LMbrCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 6,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tkt7LAreaCd:{
    id: 'tkt7LAreaCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 3,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tkt7LComCd:{
    id: 'tkt7LComCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  comKbn:{
    id: 'comKbn',
    label: '',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: 'upper',
  },
  tkt7LPayCd:{
    id: 'tkt7LPayCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 1,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tkt7LFreeCd:{
    id: 'tkt7LFreeCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tkt7LTourCd:{
    id: 'tkt7LTourCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 15,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tkt7LKb1Cd:{
    id: 'tkt7LKb1Cd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 7,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tkt7LKb2Cd:{
    id: 'tkt7LKb2Cd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 7,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tkt7LEoCd:{
    id: 'tkt7LEoCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 7,
    width: '100px',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktFormPay:{
    id: 'tktFormPay',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 100,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum_bl'],
    stringcase: 'upper',
  },
  taxTaxInd:{
    id: 'taxTaxInd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 6,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  tktNrid:{
    id: 'tktNrid',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktVc:{
    id: 'tktVc',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 5,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktCar:{
    id: 'tktCar',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 9,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktTourCd:{
    id: 'tktTourCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 15,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tkt7K:{
    id: 'tkt7K',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 20,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tkt7RCd:{
    id: 'tkt7RCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktLocatCd:{
    id: 'tktLocatCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktTktNoAl:{
    id: 'tktTktNoAl',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 3,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: 'upper',
  },
  tktMonthStepoverFlg:{
    id: 'tktMonthStepoverFlg',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '1',
    value: '1',
    text: '1',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktDetailIssuedFlg:{
    id: 'tktDetailIssuedFlg',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktCustomerOutputFlg:{
    id: 'tktCustomerOutputFlg',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktAgentCouponOutputFlg:{
    id: 'tktAgentCouponOutputFlg',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktCccfOutputFlg:{
    id: 'tktCccfOutputFlg',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktRefundNoticeOutputFlg:{
    id: 'tktRefundNoticeOutputFlg',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  fltAirlineCd:{
    id: 'fltAirlineCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 3,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  fltFltNum:{
    id: 'fltFltNum',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 7,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  fltClass:{
    id: 'fltClass',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 2,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  fltBoardDateTime:{
    id: 'fltBoardDateTime',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'datetime',
    format: 'yyyy/MM/dd HH:mm',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  fltArrDateTime:{
    id: 'fltArrDateTime',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'datetime',
    format: 'yyyy/MM/dd HH:mm',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  fltIndByteTkt:{
    id: 'fltIndByteTkt',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  fltFrom:{
    id: 'fltFrom',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 3,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  fltTo:{
    id: 'fltTo',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 3,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  tktVoidId:{
    id: 'tktVoidId',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktVoidDate:{
    id: 'tktVoidDate',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date',
    format: 'yyyy/MM/dd',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktRefId:{
    id: 'tktRefId',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktRefType:{
    id: 'tktRefType',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  // tktRefClass:{
  //   id: 'tktRefClass',
  //   label: '_none',
  //   placeholder: '',
  //   required: false,
  //   disabled: false,
  //   minLength: 0,
  //   maxLength: -1,
  //   width: '100%',
  //   margin: '10px',
  //   enter: '',
  //   type: '',
  //   format: '',
  //   original: '',
  //   value: '',
  //   text: '',
  //   dirty: '',
  //   isInvalid: false,
  //   helpertext: '',
  //   validtype: [],
  //   stringcase: '',
  // },
  tktRefDate:{
    id: 'tktRefDate',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: 'date',
    format: 'yyyy/MM/dd',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isRequired: false,
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktFareCalPrice:{
    id: 'tktFareCalPrice',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktWaiverCode:{
    id: 'tktWaiverCode',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 14,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktSac:{
    id: 'tktSac',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 15,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktVcVccar:{
    id: 'tktVcVccar',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktAdVccar:{
    id: 'tktAdVccar',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  rtxRfTaxNo:{
    id: 'rtxRfTaxNo',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 2,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  tktReissueId:{
    id: 'tktReissueId',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: 'upper',
  },
  tktOrgIsu:{
    id: 'tktOrgIsu',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 37,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktIsuEx:{
    id: 'tktIsuEx',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 37,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktCreditCom:{
    id: 'tktCreditCom',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 2,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktCardNum:{
    id: 'tktCardNum',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 17,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['num'],
    stringcase: '',
  },
  tktCaExDate:{
    id: 'tktCaExDate',
    label: '_none',
    placeholder: 'MM/YY',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 5,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['mmyy'],
    stringcase: '',
    mask: '00/00',
  },
  tktCaMem:{
    id: 'tktCaMem',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 26,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  caAprNo:{
    id: 'caAprNo',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 7,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  tktFuncCd:{
    id: 'tktFuncCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 2,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktDvCnt:{
    id: 'tktDvCnt',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktPaingNo:{
    id: 'tktPaingNo',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  tktRmks1:{
    id: 'tktRmks1',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktRmks2:{
    id: 'tktRmks2',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  tktRmks3:{
    id: 'tktRmks3',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 10,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  fltBookingStat:{
    id: 'fltBookingStat',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
  conjunctionTicketData:{
    id: 'conjunctionTicketData',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 17,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['num_hy'],
    stringcase: '',
  },
  reasonForIssuanceCd:{
    id: 'reasonForIssuanceCd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  reasonForIssueanceSubcode:{
    id: 'reasonForIssueanceSubcode',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 3,
    maxLength: 3,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['alphanum'],
    stringcase: 'upper',
  },
  emdConsumedIssuaranceInd:{
    id: 'emdConsumedIssuaranceInd',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 3,
    maxLength: 3,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: 'upper',
  },
  orderId:{
    id: 'orderId',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: 20,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: ['halfWidth'],
    stringcase: 'upper',
  },
  ndcFlg:{
    id: 'ndcFlg',
    label: '_none',
    placeholder: '',
    required: false,
    disabled: false,
    minLength: 0,
    maxLength: -1,
    width: '100%',
    margin: '10px',
    enter: '',
    type: '',
    format: '',
    original: '',
    value: '',
    text: '',
    dirty: '',
    isInvalid: false,
    helpertext: '',
    validtype: [],
    stringcase: '',
  },
}


