import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktExcelAction } from 'ducks/TktExcel';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Badge,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert, getCondition, dataMapper, createDispSearch } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TabPanel, Tab } from '@grapecity/wijmo.react.nav';
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { SEARCH, COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { LIST_ITEMS, EXCEL_OUT_FORMAT, EXCEL_OUT_FORMAT_TIMER, EXCEL_MESSAGE, OUT_PEX, TKT_XLSINI_HEADER, CLIENT_OUT_FILE,
  CLIENT_OUT_FILE_PATTERN, TKT_XLSINI_CSMTAX, TKT_XLSINI_PRD, TKT_XLSINI_PLUS_TYPE,
  TKT_XLSINI_GROSS_NET_SAME, TKT_XLSINI_PCC, TKT_XLSINI_PRD_EX, TKT_XLSINI_PRD_KB0 } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { ExcelItemPopup } from './modal/ExcelItemPopup';
import { PatternNamePopup } from './modal/PatternNamePopup';
import { ExcelComponent } from './excel/ExcelComponent';
import { MasterAction } from 'ducks/Master';
import { TicketApi } from 'webapi'
import { TktEditAction } from 'ducks/TktEdit';

export const TicketExcelComponent = (props) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const tktExcel = useSelector(state => state.TktExcel);
  
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const tktExcelAction = bindActionCreators(TktExcelAction, dispatch);

  const [popup, setPopup] = React.useState(null)
  const [popupData, setPopupData] = React.useState(null)
  const [procPopup, setProcPopup] = React.useState(null)
  const [grid, setGrid] = React.useState(null)
  const [tab, setTab] = React.useState('tkt')
  const [settingPopup, setSettingPopup] = React.useState(null)

  const [tktOutItemList, setTktOutItemList] = React.useState([])
  const [fltOutItemList, setFltOutItemList] = React.useState([])
  const [isEditOutList, setIsEditOutList] = React.useState(false)

  React.useEffect(() => {
    
    if (location.state && location.state.caller && location.state.caller ==='search') {
      if (tktExcel.displayTab) {
        setTab(tktExcel.displayTab)
      }
      if (tktExcel.tktSortList) {
        setIsEditOutList(true)
        appAction.showProcessing({isProcessing: false})
        return
      }
    }

    if (props.isModal && !props.isOpen) return
    appAction.showProcessing({isProcessing: true})
    appAction.setMessage('')

    TicketApi.excelInit({
      isExecutionValue : false,
      isOutItemIni: false,
      isTimerAccess: props.isModal?true:false,
      isMenu: true,
    })
    .then(res => {
      // console.log(res)
      appAction.setBatchResult({processingSeq: ''})
      const obj = {}
      // 出力項目選択肢
      obj.tktIniList = _.sortBy(res.payload.tktSortList, ['itemFrmTktSort'])
      obj.fltIniList = _.sortBy(res.payload.fltSortList, ['itemFrmFltSort'])
      // 
      obj.tktIniDto = res.payload.tktIniDto
      obj.fltIniDto = res.payload.fltIniDto
      obj.tktPatternList = [{value:'', name:'　'}]
      _.forEach(res.payload.tktPatternList, r => {
        obj.tktPatternList.push({
          ...r,
          value: r.tktXlsitemObjectId,
          name: r.tktXlsiniPtName,
        })
      })
      obj.fltPatternList = [{value:'', name:'　'}]
      _.forEach(res.payload.fltPatternList, r => {
        obj.fltPatternList.push({
          ...r,
          value: r.tktXlsitemObjectId,
          name: r.tktXlsiniPtName,
        })
      })
      // 出力項目
      obj.tktOutItemList = []
      obj.tktSelectedList = []
      _.forEach(res.payload.tktOutItemList, r => {
        _.forEach(obj.tktIniList, item => {
          if (item.itemFrmItemId === r.tktXlsitemItem) {
            obj.tktOutItemList.push({
              ...item,
              value: item.itemFrmItemId,
              name: item.itemFrmDispName,
            })
            if (r.tktXlsitemObjectKbn !== '9') {
              obj.tktSelectedList.push({
                ...item,
                value: item.itemFrmItemId,
                name: item.itemFrmDispName,
              })
            }
          }
        })
      })
      obj.fltOutItemList = []
      obj.fltSelectedList = []
      _.forEach(res.payload.fltOutItemList, r => {
        _.forEach(obj.fltIniList, item => {
          if (item.itemFrmItemId === r.tktXlsitemItem) {
            obj.fltOutItemList.push({
              ...item,
              value: r.itemFrmItemId,
              name: r.itemFrmDispName,
            })
            if (r.tktXlsitemObjectKbn === '2') {
              obj.fltSelectedList.push({
                ...item,
                value: r.itemFrmItemId,
                name: r.itemFrmDispName,
              })
            }
          }
        })
      })
      if ((res.payload.tktIniDto && res.payload.tktIniDto.tktXlsiniDate?new Date(res.payload.tktIniDto.tktXlsiniDate):null) <
      (res.payload.fltIniDto && res.payload.fltIniDto.tktXlsiniDate?new Date(res.payload.fltIniDto.tktXlsiniDate):null)) setTab('flt')
      // ソート項目
      obj.tktSortList = _.concat([{value: '', name: '　'}], _.map(_.sortBy(res.payload.tktSortList, ['itemFrmTktSort']), 
        r => ({
          ...r,
          value: r.itemFrmItemId,
          name: r.itemFrmDispName,
        }))
      )
      obj.fltSortList = _.concat([{value: '', name: '　'}], _.map(_.sortBy(res.payload.fltSortList, ['itemFrmFltSort']), 
        r => ({
          ...r,
          value: r.itemFrmItemId,
          name: r.itemFrmDispName,
        }))
      )
      
      obj.tkt = {...tktExcel.tkt}
      _.forEach(tktExcel, (o, id) => {
        _.forEach(res.payload.tktIniDto, (v, k) => {
          if (id === k) {
            obj[id] = {
              ...tktExcel[id],
              isInvalid: false,
              helpertext: '',
              original: v?v:tktExcel[id].original,
              value: v?v:tktExcel[id].value,
              text: v?v:tktExcel[id].text,
            }
          }
        })
      })
      
      _.forEach(tktExcel.tkt, (o, id) => {
        _.forEach(res.payload.tktIniDto, (v, k) => {
          if (id === k || id === `${k}_tkt`) {
            obj.tkt[id] = {
              ...tktExcel.tkt[id],
              isInvalid: false,
              helpertext: '',
              original: v?v:tktExcel.tkt[id].original,
              value: v?v:tktExcel.tkt[id].value,
              text: v?v:tktExcel.tkt[id].text,
            }
            if (id === 'tktXlsiniClientOutFile_tkt') {
              obj.tkt[id] = {
                ...tktExcel.tkt[id],
                isInvalid: false,
                helpertext: '',
                original: v >= '3'?'3':v?v:tktExcel.tkt[id].original,
                value: v >= '3'?'3':v?v:tktExcel.tkt[id].original,
                text: v >= '3'?'3':v?v:tktExcel.tkt[id].original,
              }
              obj.tkt['tktXlsiniClientOutFilePattern_tkt'] = {
                ...tktExcel.tkt['tktXlsiniClientOutFilePattern_tkt'],
                isInvalid: false,
                helpertext: '',
                original: v?v:tktExcel.tkt[id].original,
                value: v?v:tktExcel.tkt[id].original,
                text: v?v:tktExcel.tkt[id].original,
              }
            }
            if (id === 'tktXlsiniS3OutFile_tkt') {
              obj.tkt[id] = {
                ...tktExcel.tkt[id],
                isInvalid: false,
                helpertext: '',
                original: v >= '3'?'3':v?v:tktExcel.tkt[id].original,
                value: v >= '3'?'3':v?v:tktExcel.tkt[id].original,
                text: v >= '3'?'3':v?v:tktExcel.tkt[id].original,
              }
              obj.tkt['tktXlsiniS3OutFilePattern_tkt'] = {
                ...tktExcel.tkt['tktXlsiniS3OutFilePattern_tkt'],
                isInvalid: false,
                helpertext: '',
                original: v?v:tktExcel.tkt[id].original,
                value: v?v:tktExcel.tkt[id].original,
                text: v?v:tktExcel.tkt[id].original,
              }
            }
          }
          if (id === 'tktXlsitemObjectId_tkt'){
            obj.tkt[id] = {
              ...tktExcel.tkt[id],
              isInvalid: false,
              helpertext: '',
              original: '',
              value: '',
              text: '',
            }
          }
        })
      })
      
      obj.flt = {...tktExcel.flt}
      _.forEach(tktExcel.flt, (o, id) => {
        _.forEach(res.payload.fltIniDto, (v, k) => {
          if (id === k || id === `${k}_flt`) {
            obj.flt[id] = {
              ...tktExcel.flt[id],
              isInvalid: false,
              helpertext: '',
              original: v?v:tktExcel.flt[id].original,
              value: v?v:tktExcel.flt[id].value,
              text: v?v:tktExcel.flt[id].text,
            }
          }
          if (k === 'tktXlsiniFltseg') {
            _.forEach(Array.from(v?v:'1111'), (text, idx) => {
              obj.flt[`${k}${idx+1}_flt`] = {
                ...tktExcel.flt[`${k}${idx+1}_flt`],
                isInvalid: false,
                helpertext: '',
                original: text,
                value: text,
                text: text,
              }
            })
          }
          if (id === 'tktXlsitemObjectId_flt') {
            obj.flt[id] = {
              ...tktExcel.flt[id],
              isInvalid: false,
              helpertext: '',
              original: '',
              value: '',
              text: '',
            }
          }
        })
      })
      
      if (!obj.tkt.tktXlsiniFormat_tkt.text) {
        // Timer -> CSV
        // Online -> XLSX
        obj.tkt.tktXlsiniFormat_tkt = {
          ...tktExcel.tkt.tktXlsiniFormat_tkt,
          ...obj.tkt.tktXlsiniFormat_tkt,
          isInvalid: false,
          helpertext: '',
          original: props.isModal?'2':'4',
          value: props.isModal?'2':'4',
          text: props.isModal?'2':'4',
        }
      }
      if (!obj.flt.tktXlsiniFormat_flt.text) {
        // Timer -> CSV
        // Online -> XLSX
        obj.flt.tktXlsiniFormat_flt = {
          ...tktExcel.flt.tktXlsiniFormat_flt,
          ...obj.flt.tktXlsiniFormat_flt,
          isInvalid: false,
          helpertext: '',
          original: '4',
          value: '4',
          text: '4',
        }
      }

      obj.searchJson = {}
      if (res.payload.outSearchDto && res.payload.outSearchDto.tktOutsrcSearch) {
        const searchJson = JSON.parse(res.payload.outSearchDto.tktOutsrcSearch)
        // AND OR
        // console.log(searchJson.condition)
        obj.condition = searchJson.condition
        // 
        // console.log(searchJson.conditionJson)
        obj.searchJson = searchJson.conditionJson
      }
      if (location.state && location.state.caller && tktExcel.searchSql) {
        obj.searchSql = tktExcel.searchSql
        obj.searchJson = tktExcel.searchJson
        obj.condition = tktExcel.condition
        // console.log(obj.searchSql)
      } else {
        obj.searchSql = res.payload.outSearchDto && res.payload.outSearchDto.tktOutsrcSql
        // console.log(obj.searchSql)
      }

      tktExcelAction.setInputTktExcel(obj)
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
      setIsEditOutList(true)
    })
  }, [props.isOpen])

  const execExcel = () => {

    if (!tktExcel.searchSql) {
      appAction.setMessage({message: [{id: MESSAGE.noSearch().id, message: MESSAGE.noSearch().text, type: MESSAGE.noSearch().icon}]})
      return
    }
    if (!tktExcel[tab][`tktXlsiniFile_${tab}`].text && tktExcel[tab][`tktXlsiniFileFlag_${tab}`].text === '0') {
      appAction.setMessage({message: [{id: MESSAGE.noFileName().id, message: MESSAGE.noFileName().text, type: MESSAGE.noFileName().icon}]})
      return
    }
    if (!tktExcel[`${tab}SelectedList`] || tktExcel[`${tab}SelectedList`].length <= 0) {
      appAction.setMessage({message: [{id: MESSAGE.noOutputItem().id, message: MESSAGE.noOutputItem().text, type: MESSAGE.noOutputItem().icon}]})
      return
    }
    let sortArray = []
    
    _.forEach([...Array(8).keys()], i => {
      const sortItem = tktExcel[tab][`tktXlsiniSort${++i}_${tab}`].text
      if (sortItem) {
        sortArray.push(sortItem)
      }
    })
    if (sortArray.length !== _.uniq(sortArray).length) {
      const errorItem = 'ソート順の指定項目'
      appAction.setMessage({message: [{id: MESSAGE.duplicateItem(errorItem).id, message: MESSAGE.duplicateItem(errorItem).text, type: MESSAGE.duplicateItem(errorItem).icon}]})
      return
    }
    if (tab === 'flt' &&
        tktExcel.flt.tktXlsiniFltseg1_flt.text !== '1' && 
        tktExcel.flt.tktXlsiniFltseg2_flt.text !== '1' && 
        tktExcel.flt.tktXlsiniFltseg3_flt.text !== '1' && 
        tktExcel.flt.tktXlsiniFltseg4_flt.text !== '1') {
        appAction.setMessage({message: [{id: MESSAGE.noFltseg().id, message: MESSAGE.noFltseg().text, type: MESSAGE.noFltseg().icon}]})
        return
      }

    let entry = {}
    let hasError = false
    entry[tab] = {...tktExcel[tab]}
    entry[tab][`tktXlsiniFile_${tab}`] = entryCheck(tktExcel[tab], convert.getTarget(tktExcel[tab][`tktXlsiniFile_${tab}`], 'text', tktExcel[tab][`tktXlsiniFile_${tab}`].text))[`tktXlsiniFile_${tab}`]
    hasError = hasError || entry[tab][`tktXlsiniFile_${tab}`].isInvalid
    tktExcelAction.setInputTktExcel(entry)
    if (hasError) return

    let list = []
    _.forEach(tktExcel[`${tab}SelectedList`], (r, i) => {
      list.push({
        tktXlsitemItem: r.itemFrmItemId,
        tktXlsitemSort: i + 1,
      })
    })

    appAction.showProcessing({isProcessing: true})

    TicketApi.findSearch({
      viewOption: tab.toUpperCase()==='TKT'?'0':'1',
      searchOption: tktExcel.condition.text,
      processId: 'B0501',
      isGetSql: true,
      ...tktExcel.searchJson,
    })
    .then(res => {
      if (res.payload.code === '000' || res.payload.code === '100') {
        if (!res.payload.searchSql) {
          appAction.setMessage({message: [{id: MESSAGE.noSearch().id, message: MESSAGE.noSearch().text, type: MESSAGE.noSearch().icon}]})
          appAction.showProcessing({isProcessing: false})
        } else {
          const tktOutsrcSearch = {
            condition: tktExcel.condition,
            conditionJson: tktExcel.searchJson,
          }
          const dto = {
            isTimerAccess: false,
            isTimerSetting: false,
            selectedPatternSeqNo: login.loginCd,
            outSearchDto: {
              tktOutsrcSql: res.payload.searchSql,
              tktOutsrcSearch: JSON.stringify(tktOutsrcSearch),
              tktOutsrcIniMbrcd: login.loginCd,
              cmnMbrcd: login.loginCd,
            },
            selectedTab: tab.toUpperCase(),
          }
          dto[`${tab}IniDto`] = {
            tktXlsiniFormat: tktExcel[tab][`tktXlsiniFormat_${tab}`].text,
            tktXlsiniHeader: tktExcel[tab][`tktXlsiniHeader_${tab}`].text,
            tktXlsiniSort1: tktExcel[tab][`tktXlsiniSort1_${tab}`].text,
            tktXlsiniSort2: tktExcel[tab][`tktXlsiniSort2_${tab}`].text,
            tktXlsiniSort3: tktExcel[tab][`tktXlsiniSort3_${tab}`].text,
            tktXlsiniSort4: tktExcel[tab][`tktXlsiniSort4_${tab}`].text,
            tktXlsiniSort5: tktExcel[tab][`tktXlsiniSort5_${tab}`].text,
            tktXlsiniSort6: tktExcel[tab][`tktXlsiniSort6_${tab}`].text,
            tktXlsiniSort7: tktExcel[tab][`tktXlsiniSort7_${tab}`].text,
            tktXlsiniSort8: tktExcel[tab][`tktXlsiniSort8_${tab}`].text,
            tktXlsiniFile: tktExcel[tab][`tktXlsiniFile_${tab}`].text,
            tktXlsiniPex: tktExcel[tab][`tktXlsiniPex_${tab}`].text,
            tktXlsiniCsmtax: tktExcel[tab][`tktXlsiniCsmtax_${tab}`].text,
            tktXlsiniPrd: tktExcel[tab][`tktXlsiniPrd_${tab}`].text,
            tktXlsiniGrossNetSame: tktExcel[tab][`tktXlsiniGrossNetSame_${tab}`].text,
            tktXlsiniPcc: tktExcel[tab][`tktXlsiniPcc_${tab}`].text,
            tktEmdMpdFlag: tktExcel[tab][`tktEmdMpdFlag_${tab}`].text,
            tktXlsiniFileFlag: tktExcel[tab][`tktXlsiniFileFlag_${tab}`].text,
            tktXlsiniMbrcd: login.loginCd,
            cmnMbrcd: login.loginCd,
          }
          if (tab === 'tkt') {
            dto.tktIniDto.tktXlsiniPlusType = tktExcel.tkt.tktXlsiniPlusType_tkt.text
          } else {
            dto.fltIniDto.tktXlsiniPrdEx = tktExcel.flt.tktXlsiniPrdEx_flt.text
            dto.fltIniDto.tktXlsiniPrdKb0 = tktExcel.flt.tktXlsiniPrdKb0_flt.text
            dto.fltIniDto.tktXlsiniFltseg = tktExcel.flt.tktXlsiniFltseg1_flt.text
            + tktExcel.flt.tktXlsiniFltseg2_flt.text
            + tktExcel.flt.tktXlsiniFltseg3_flt.text
            + tktExcel.flt.tktXlsiniFltseg4_flt.text
            dto.fltIniDto.tktXlsiniTotal = tktExcel.flt.tktXlsiniTotal_flt.text
          }
          
          dto[`${tab}OutItemList`] = list
          dto[`${tab}SelectedList`] = tktExcel[`${tab}SelectedList`]
          
          TicketApi.excelDownload(dto).then((res) => {
            // console.log(res)
            if (res.payload.code === '000') {
              appAction.setBatchResult({processingSeq: res.payload.processingSeq})
            
              procPopup.show(true, (sender) => {
                // if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
                //   console.log('ok')
                // } else {
                //   console.log('cancel')
                // }
              })
            } else {
              appAction.setMessage({message: res.payload.messageList})
            }
          })
          .catch(() =>{})
          .finally(() => {
            appAction.showProcessing({isProcessing: false})
          })
        }
      } else {
        appAction.showProcessing({isProcessing: false})
      }
    })
    .catch(err => {
      appAction.showProcessing({isProcessing: false})
    })
    .finally(() => {
    })

  }

  const execSetting = () => {

    // if (!tktExcel.searchSql) {
    //   appAction.setMessage({message: [{id: MESSAGE.noSearch().icon, message: MESSAGE.noSearch().text, type: MESSAGE.noSearch().icon}]})
    //   return
    // }
    
    if (!tktExcel[tab][`tktXlsiniFile_${tab}`].text && tktExcel[tab][`tktXlsiniFileFlag_${tab}`].text === '0') {
      appAction.setMessage({message: [{id: MESSAGE.noFileName().id, message: MESSAGE.noFileName().text, type: MESSAGE.noFileName().icon}]})
      return
    }
    if (login.auth052==='1' && tktExcel[tab][`tktXlsiniClientSyncFlag_${tab}`].text === '1' && !tktExcel[tab][`tktXlsiniClientPath_${tab}`].text) {
      appAction.setMessage({message: [{id: MESSAGE.noFilePath().id, message: MESSAGE.noFilePath().text, type: MESSAGE.noFilePath().icon}]})
      return
    }
    if (!tktExcel[`${tab}SelectedList`] || tktExcel[`${tab}SelectedList`].length <= 0) {
      appAction.setMessage({message: [{id: MESSAGE.noOutputItem().id, message: MESSAGE.noOutputItem().text, type: MESSAGE.noOutputItem().icon}]})
      return
    }
    let sortArray = []
    _.forEach([...Array(8).keys()], i => {
      const sortItem = tktExcel[tab][`tktXlsiniSort${++i}_${tab}`].text
      if (sortItem) {
        sortArray.push(sortItem)
      }
    })
    if (sortArray.length !== _.uniq(sortArray).length) {
      const errorItem = 'ソート順の指定項目'
      appAction.setMessage({message: [{id: MESSAGE.duplicateItem(errorItem).id, message: MESSAGE.duplicateItem(errorItem).text, type: MESSAGE.duplicateItem(errorItem).icon}]})
      return
    }
    let entry = {}
    let hasError = false
    entry[tab] = {...tktExcel[tab]}
    entry[tab][`tktXlsiniFile_${tab}`] = entryCheck(tktExcel[tab], convert.getTarget(tktExcel[tab][`tktXlsiniFile_${tab}`], 'text', tktExcel[tab][`tktXlsiniFile_${tab}`].text))[`tktXlsiniFile_${tab}`]
    hasError = hasError || entry[tab][`tktXlsiniFile_${tab}`].isInvalid
    entry[tab][`tktXlsiniClientPath_${tab}`] = entryCheck(tktExcel[tab], convert.getTarget(tktExcel[tab][`tktXlsiniClientPath_${tab}`], 'text', tktExcel[tab][`tktXlsiniClientPath_${tab}`].text))[`tktXlsiniClientPath_${tab}`]
    hasError = hasError || entry[tab][`tktXlsiniClientPath_${tab}`].isInvalid
    entry[tab][`tktXlsiniS3Path_${tab}`] = entryCheck(tktExcel[tab], convert.getTarget(tktExcel[tab][`tktXlsiniS3Path_${tab}`], 'text', tktExcel[tab][`tktXlsiniS3Path_${tab}`].text))[`tktXlsiniS3Path_${tab}`]
    hasError = hasError || entry[tab][`tktXlsiniS3Path_${tab}`].isInvalid
    tktExcelAction.setInputTktExcel(entry)
    if (hasError) return
    // if (tktExcel[tab][`tktXlsiniS3Flag_${tab}`].text === '1' && !tktExcel[tab][`tktXlsiniS3Path_${tab}`].text) {
    //   appAction.setMessage({message: [{id: MESSAGE.noFilePath().id, message: MESSAGE.noFilePath().text, type: MESSAGE.noFilePath().icon}]})
    //   return
    // }
    // if (tab === 'flt' &&
    //     tktExcel.flt.tktXlsiniFltseg1_flt.text !== '1' && 
    //     tktExcel.flt.tktXlsiniFltseg2_flt.text !== '1' && 
    //     tktExcel.flt.tktXlsiniFltseg3_flt.text !== '1' && 
    //     tktExcel.flt.tktXlsiniFltseg4_flt.text !== '1') {
    //     appAction.setMessage({message: [{id: MESSAGE.noFltseg().icon, message: MESSAGE.noFltseg().text, type: MESSAGE.noFltseg().icon}]})
    //     return
    //   }

    let list = []
    _.forEach(tktExcel[`${tab}SelectedList`], (r, i) => {
      list.push({
        tktXlsitemItem: r.itemFrmItemId,
        tktXlsitemSort: i + 1,
      })
    })

    const dto = {
      isTimerAccess: true,
      isTimerSetting: true,
      // selectedPatternSeqNo: login.loginCd,
      tktXlsitemObjectId: login.loginCd,
      outSearchDto: {
        // tktOutsrcSql: tktExcel.searchSql,
        // tktOutsrcSearch: txtExport.,
        tktOutsrcIniMbrcd: login.loginCd,
        cmnMbrcd: login.loginCd,
      },
      selectedTab: tab.toUpperCase(),
    }
    dto[`${tab}IniDto`] = {
      tktXlsiniFormat: tktExcel[tab][`tktXlsiniFormat_${tab}`].text,
      tktXlsiniHeader: tktExcel[tab][`tktXlsiniHeader_${tab}`].text,
      tktXlsiniSort1: tktExcel[tab][`tktXlsiniSort1_${tab}`].text,
      tktXlsiniSort2: tktExcel[tab][`tktXlsiniSort2_${tab}`].text,
      tktXlsiniSort3: tktExcel[tab][`tktXlsiniSort3_${tab}`].text,
      tktXlsiniSort4: tktExcel[tab][`tktXlsiniSort4_${tab}`].text,
      tktXlsiniSort5: tktExcel[tab][`tktXlsiniSort5_${tab}`].text,
      tktXlsiniSort6: tktExcel[tab][`tktXlsiniSort6_${tab}`].text,
      tktXlsiniSort7: tktExcel[tab][`tktXlsiniSort7_${tab}`].text,
      tktXlsiniSort8: tktExcel[tab][`tktXlsiniSort8_${tab}`].text,
      tktXlsiniFile: tktExcel[tab][`tktXlsiniFile_${tab}`].text,
      tktXlsiniClientSyncFlag: login.auth052==='1'?tktExcel[tab][`tktXlsiniClientSyncFlag_${tab}`].text:'0',
      tktXlsiniClientPath: tktExcel[tab][`tktXlsiniClientPath_${tab}`].text,
      tktXlsiniClientOutFile: tktExcel[tab][`tktXlsiniClientOutFile_${tab}`].text==='3'
        ?tktExcel[tab][`tktXlsiniClientOutFilePattern_${tab}`].text
        :tktExcel[tab][`tktXlsiniClientOutFile_${tab}`].text,
      tktXlsiniS3Flag: login.authFtp==='1'?tktExcel[tab][`tktXlsiniS3Flag_${tab}`].text:'0',
      tktXlsiniS3Path: tktExcel[tab][`tktXlsiniS3Path_${tab}`].text,
      tktXlsiniS3OutFile: tktExcel[tab][`tktXlsiniS3OutFile_${tab}`].text==='3'
        ?tktExcel[tab][`tktXlsiniS3OutFilePattern_${tab}`].text
        :tktExcel[tab][`tktXlsiniS3OutFile_${tab}`].text,
      tktXlsiniPccdiv: tktExcel[tab][`tktXlsiniPccdiv_${tab}`].text,
      tktXlsiniPex: tktExcel[tab][`tktXlsiniPex_${tab}`].text,
      tktXlsiniCsmtax: tktExcel[tab][`tktXlsiniCsmtax_${tab}`].text,
      tktXlsiniPrd: tktExcel[tab][`tktXlsiniPrd_${tab}`].text,
      tktXlsiniGrossNetSame: tktExcel[tab][`tktXlsiniGrossNetSame_${tab}`].text,
      tktXlsiniPcc: tktExcel[tab][`tktXlsiniPcc_${tab}`].text,
      tktEmdMpdFlag: tktExcel[tab][`tktEmdMpdFlag_${tab}`].text,
      tktXlsiniFileFlag: tktExcel[tab][`tktXlsiniFileFlag_${tab}`].text,
      tktXlsiniMbrcd: login.loginCd,
      cmnMbrcd: login.loginCd,
    }
    if (tab === 'tkt') {
      dto.tktIniDto.tktXlsiniPlusType = tktExcel.tkt.tktXlsiniPlusType_tkt.text
    // } else {
    //   dto.fltIniDto.tktXlsiniPrdEx = tktExcel.flt.tktXlsiniPrdEx_flt.text
    //   dto.fltIniDto.tktXlsiniPrdKbo = tktExcel.flt.tktXlsiniPrdKb0_flt.text
    //   dto.fltIniDto.tktXlsiniFltseg = tktExcel.flt.tktXlsiniFltseg1_flt.text
    //   + tktExcel.flt.tktXlsiniFltseg2_flt.text
    //   + tktExcel.flt.tktXlsiniFltseg3_flt.text
    //   + tktExcel.flt.tktXlsiniFltseg4_flt.text
    }
    
    dto[`${tab}OutItemList`] = list
    dto[`${tab}SelectedList`] = tktExcel[`${tab}SelectedList`]
    
    appAction.showProcessing({isProcessing: true})
    TicketApi.excelEdit(dto).then((res) => {
      // console.log(res)
      if (res.payload.code === '000') {
        appAction.setMessage({message: res.payload.messageList})
        props.onSuccess()
      } else {
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  const execPattern = () => {
    if (!tktExcel[`${tab}SelectedList`] || tktExcel[`${tab}SelectedList`].length <= 0) {
      appAction.setMessage({message: [{id: MESSAGE.noOutputItem().id, message: MESSAGE.noOutputItem().text, type: MESSAGE.noOutputItem().icon}]})
      return
    }
    if (tktExcel[tab][`tktXlsitemObjectId_${tab}`].text && tktExcel[tab][`tktXlsitemObjectId_${tab}`].text !== login.loginCd) {
      setPopupData(tktExcel[tab][`tktXlsitemObjectId_${tab}`].text)
    } else {
      setPopupData(null)
    }
    popup.show(true, (sender) => {
      // if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
      //   console.log('ok')
      // } else {
      //   console.log('cancel')
      // }
      setPopupData(null)
    })
  }

  React.useEffect(() => {
    if (isEditOutList) {
      setTktOutItemList(tktExcel.tktSelectedList)
      setFltOutItemList(tktExcel.fltSelectedList)
      setIsEditOutList(false)
    }
  }, [isEditOutList])

  React.useEffect(() => {
    if (tktExcel[tab][`tktXlsitemObjectId_${tab}`].text && tktExcel[tab][`tktXlsitemObjectId_${tab}`].text !== login.loginCd) {
      const selectedPattern = tab==='tkt' ?_.find(tktExcel.tktPatternList, r => r.tktXlsitemObjectId === tktExcel[tab][`tktXlsitemObjectId_${tab}`].text)
                                          :_.find(tktExcel.fltPatternList, r => r.tktXlsitemObjectId === tktExcel[tab][`tktXlsitemObjectId_${tab}`].text)
      // console.log(selectedPattern)
      if (!selectedPattern) return
      TicketApi.excelSearch({
        isExecutionValue : false,
        selectedTab: selectedPattern.tktXlsiniStd,
        selectedPatternSeqNo: tktExcel[tab][`tktXlsitemObjectId_${tab}`].text,
      })
      .then(res => {
        // console.log(res)
        let obj = {}
        // 出力項目
        obj.tktOutItemList = []
        obj.tktSelectedList = []
        _.forEach(res.payload.tktOutItemList, r => {
          _.forEach(tktExcel.tktIniList, item => {
            if (item.itemFrmItemId === r.tktXlsitemItem) {
              obj.tktOutItemList.push({
                ...item,
                value: item.itemFrmItemId,
                name: item.itemFrmDispName,
              })
              // console.log(r.tktXlsitemObjectKbn)
              if (r.tktXlsitemObjectKbn === '9') {
                obj.tktSelectedList.push({
                  ...item,
                  value: item.itemFrmItemId,
                  name: item.itemFrmDispName,
                })
              }
            }
          })
        })
  
        obj.fltOutItemList = []
        obj.fltSelectedList = []
        _.forEach(res.payload.fltOutItemList, r => {
          _.forEach(tktExcel.fltIniList, item => {
            if (item.itemFrmItemId === r.tktXlsitemItem) {
              obj.fltOutItemList.push({
                ...item,
                value: r.itemFrmItemId,
                name: r.itemFrmDispName,
              })
              if (r.tktXlsitemObjectKbn === '9') {
                obj.fltSelectedList.push({
                  ...item,
                  value: r.itemFrmItemId,
                  name: r.itemFrmDispName,
                })
              }
            }
          })
        })
        // if (obj.fltSelectedList.length > 0) setTab('flt')

        obj.tkt = {...tktExcel.tkt}
        _.forEach(tktExcel, (o, id) => {
          _.forEach(res.payload.tktIniDto, (v, k) => {
            if (id === k && id !== 'selDate') {
              obj.tkt[id] = {
                ...tktExcel.tkt[id],
                isInvalid: false,
                helpertext: '',
                original: res.payload.selectedTab==='TKT'?v:tktExcel.tkt[id].original,
                value: res.payload.selectedTab==='TKT'?v:tktExcel.tkt[id].original,
                text: res.payload.selectedTab==='TKT'?v:tktExcel.tkt[id].original,
              }
            }
          })
        })
        _.forEach(tktExcel.tkt, (o, id) => {
          _.forEach(res.payload.tktIniDto, (v, k) => {
            if (id === k || id === `${k}_tkt`) {
              obj.tkt[id] = {
                ...tktExcel.tkt[id],
                isInvalid: false,
                helpertext: '',
                original: res.payload.selectedTab==='TKT'?v:tktExcel.tkt[id].original,
                value: res.payload.selectedTab==='TKT'?v:tktExcel.tkt[id].original,
                text: res.payload.selectedTab==='TKT'?v:tktExcel.tkt[id].original,
              }
              // 値を保持
              if (id === 'tktXlsiniClientPath_tkt') {
                obj.tkt[id] = {
                  ...tktExcel.tkt[id],
                  isInvalid: false,
                  helpertext: '',
                  original: res.payload.selectedTab==='TKT'?o.original:tktExcel.tkt[id].original,
                  value: res.payload.selectedTab==='TKT'?o.value:tktExcel.tkt[id].original,
                  text: res.payload.selectedTab==='TKT'?o.text:tktExcel.tkt[id].original,
                } 
              }
              if (id === 'tktXlsiniClientOutFile_tkt') {
                obj.tkt[id] = {
                  ...tktExcel.tkt[id],
                  isInvalid: false,
                  helpertext: '',
                  original: res.payload.selectedTab==='TKT'?(v >= '3'?'3':v):tktExcel.tkt[id].original,
                  value: res.payload.selectedTab==='TKT'?(v >= '3'?'3':v):tktExcel.tkt[id].original,
                  text: res.payload.selectedTab==='TKT'?(v >= '3'?'3':v):tktExcel.tkt[id].original,
                }
                obj.tkt['tktXlsiniClientOutFilePattern_tkt'] = {
                  ...tktExcel.tkt['tktXlsiniClientOutFilePattern_tkt'],
                  isInvalid: false,
                  helpertext: '',
                  original: res.payload.selectedTab==='TKT'?v:tktExcel.tkt[id].original,
                  value: res.payload.selectedTab==='TKT'?v:tktExcel.tkt[id].original,
                  text: res.payload.selectedTab==='TKT'?v:tktExcel.tkt[id].original,
                }
              }
              if (id === 'tktXlsiniS3OutFile_tkt') {
                obj.tkt[id] = {
                  ...tktExcel.tkt[id],
                  isInvalid: false,
                  helpertext: '',
                  original: res.payload.selectedTab==='TKT'?(v >= '3'?'3':v):tktExcel.tkt[id].original,
                  value: res.payload.selectedTab==='TKT'?(v >= '3'?'3':v):tktExcel.tkt[id].original,
                  text: res.payload.selectedTab==='TKT'?(v >= '3'?'3':v):tktExcel.tkt[id].original,
                }
                obj.tkt['tktXlsiniS3OutFilePattern_tkt'] = {
                  ...tktExcel.tkt['tktXlsiniS3OutFilePattern_tkt'],
                  isInvalid: false,
                  helpertext: '',
                  original: res.payload.selectedTab==='TKT'?v:tktExcel.tkt[id].original,
                  value: res.payload.selectedTab==='TKT'?v:tktExcel.tkt[id].original,
                  text: res.payload.selectedTab==='TKT'?v:tktExcel.tkt[id].original,
                }
              }
            }
          })
        })
        obj.flt = {...tktExcel.flt}
        _.forEach(tktExcel.flt, (o, id) => {
          _.forEach(res.payload.fltIniDto, (v, k) => {
            if (id === k || id === `${k}_flt`) {
              obj.flt[id] = {
                ...tktExcel.flt[id],
                isInvalid: false,
                helpertext: '',
                original: res.payload.selectedTab!=='TKT'?v:tktExcel.flt[id].original,
                value: res.payload.selectedTab!=='TKT'?v:tktExcel.flt[id].original,
                text: res.payload.selectedTab!=='TKT'?v:tktExcel.flt[id].original,
              }
            }
            if (k === 'tktXlsiniFltseg') {
              _.forEach(Array.from(v?v:'1111'), (text, idx) => {
                obj.flt[`${k}${idx+1}_flt`] = {
                  ...tktExcel.flt[`${k}${idx+1}_flt`],
                  isInvalid: false,
                  helpertext: '',
                  original: res.payload.selectedTab!=='TKT'?text:tktExcel.flt[id].original,
                  value: res.payload.selectedTab!=='TKT'?text:tktExcel.flt[id].original,
                  text: res.payload.selectedTab!=='TKT'?text:tktExcel.flt[id].original,
                }
              })
            }
          })
        })
        if (props.isModal) {
          obj.tkt.tktXlsiniFormat_tkt = {
            ...obj.tkt.tktXlsiniFormat_tkt,
            isInvalid: false,
            helpertext: '',
            original: '2',
            value: '2',
            text: '2',
          }
          obj.tkt.tktXlsiniFileFlag_tkt = {
            ...obj.tkt.tktXlsiniFileFlag_tkt,
            original: '0',
            value: '0',
            text: '0',
          }
        }
        // console.log(obj)
        tktExcelAction.setInputTktExcel(obj)
      })
      .catch(() =>{})
      .finally(() => {
        setIsEditOutList(true)
      })
    }

  }, [tktExcel[tab][`tktXlsitemObjectId_${tab}`].text])

  return (
    <React.Fragment>
      <Container fluid className='main'>
        <Title
          title={TITLE.ticketExcel.name}
          icon={TITLE.ticketExcel.icon}
          button={
            <Button
              {...tktExcel.search}
              className={`button-blue ml-auto ${props.isModal?'d-none':''}`}
              onClick={(e) => {
                tktExcelAction.setInputTktExcel({displayTab: tab})
                history.push(TITLE.ticketExcelSearch.link, {func: 'excel'})
              }}
            >
              {tktExcel.search.label}
            </Button>
          }
        />
        <div>
          <ExcelItemPopup
            id='exel-item-settings'
            popup={settingPopup}
            setPopup={setSettingPopup}
            listItems={tab==='tkt'?tktExcel.tktIniList:tktExcel.fltIniList}
            setInput={tktExcelAction.setInputTktExcel}
            tab={tab}
            dispCheckedItems={tktExcel[`${tab}SelectedList`]}
          />
        </div>
        <div>
          <ProcessingPopup 
            popup={procPopup}
            setPopup={setProcPopup}
            header={EXCEL_MESSAGE.header}
            processingTitle={EXCEL_MESSAGE.processingTitle}
            processingMessage1={EXCEL_MESSAGE.processingMessage1}
            resultTitle={EXCEL_MESSAGE.resultTitle}
            resultMessage1={EXCEL_MESSAGE.resultMessage1}
          />
        </div>
        <div>
          <PatternNamePopup 
            popup={popup}
            setPopup={setPopup}
            popupData={popupData}
            setPopupData={setPopupData}
            header='パターン管理'
            tab={tab}
          />
        </div>
        {!props.isModal && 
          <Card
            root='condition'
            size='md'
            hideClose
            header='データ検索条件'
            subheader={null}
            bodyClass='height-5'
            body={
              <React.Fragment>
                {tktExcel.condition && tktExcel.condition.text &&
                  <Badge className='badge-color search mr-2'>{`(${dataMapper(RADIOS.condition, 'value', 'name', tktExcel.condition.text)}) 検索条件 : `}</Badge>
                }
                {createDispSearch(tktExcel.searchJson).map((r, i) =>
                  <Badge key={i} className='badge-color search mr-2'>{r}</Badge>
                )}
              </React.Fragment>
            }
          />
        }
        <TabPanel
          // selectedTab={tab}
          selectedIndex={tab==='flt'?1:0}
          selectedIndexChanged={(e) =>{
            if (e.selectedIndex===1) setTab('flt')
            else setTab('tkt')
          }}
          className="m-1 mt-2"
          id='excel-tab'
        >
          <Tab>
            <a>TKT</a>
            <div>
              <ExcelComponent
                tab='tkt'
                isModal={props.isModal}
                onClose={props.onClose}
                settingPopup={settingPopup}
                setSettingPopup={setSettingPopup}
                outItemList={tktOutItemList}
                setOutItemList={setTktOutItemList}
                isEditOutList={isEditOutList}
                setIsEditOutList={setIsEditOutList}
                execExcel={execExcel}
                execPattern={execPattern}
                execSetting={execSetting}
                changeInput={tktExcelAction.changeInputTktExcelTkt}
              />
            </div>
          </Tab>
          <Tab isVisible={props.isModal?false:true}>
            <a>FLT</a>
            <div>
              <ExcelComponent
                tab='flt'
                settingPopup={settingPopup}
                setSettingPopup={setSettingPopup}
                outItemList={fltOutItemList}
                setOutItemList={setFltOutItemList}
                isEditOutList={isEditOutList}
                setIsEditOutList={setIsEditOutList}
                execExcel={execExcel}
                execPattern={execPattern}
                execSetting={execSetting}
                changeInput={tktExcelAction.changeInputTktExcelFlt}
              />
            </div>
          </Tab>
        </TabPanel>
      </Container>
      <Common.RouteLeavingGuard
        when={true}
        shouldBlockNavigation={()=>false}
      />
    </React.Fragment>
  )
}