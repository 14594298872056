import React from 'react';
import { createAction, handleActions } from 'redux-actions'
import { onChangeInput, initState } from 'lib'
import _ from 'lodash';

// const
export const APP = {
  SET_TITLE : 'SET_TITLE',
  SET_MESSAGE : 'SET_MESSAGE',
  ADD_MESSAGE : 'ADD_MESSAGE',
  SHOW_PROCESSING : 'SHOW_PROCESSING',
  SET_PROCESSING_MESSAGE : 'SET_PROCESSING_MESSAGE',
  SHOW_INIT_PROCESSING : 'SHOW_INIT_PROCESSING',
  SET_MASTER_DATA: 'SET_MASTER_DATA',
  SET_BATCH_RESULT: 'SET_BATCH_RESULT',
  SET_INPUT_APP: 'SET_INPUT_APP',
  INIT_SESSION : 'INIT_SESSION',
  CLEAR_SESSION : 'CLEAR_SESSION',
}

// action
export const AppAction = {
  setTitle: createAction(APP.SET_TITLE, (args) => (args)),
  setMessage: createAction(APP.SET_MESSAGE, (args) => (args)),
  addMessage: createAction(APP.ADD_MESSAGE, (args) => (args)),
  showProcessing: createAction(APP.SHOW_PROCESSING, (args) => (args)),
  setProcessingMessage: createAction(APP.SET_PROCESSING_MESSAGE, (args) => (args)),
  showInitProcessing: createAction(APP.SHOW_INIT_PROCESSING, (args) => (args)),
  setMasterData: createAction(APP.SET_MASTER_DATA, (args) => (args)),
  setBatchResult: createAction(APP.SET_BATCH_RESULT, (args) => (args)),
  setInputApp: createAction(APP.SET_INPUT_APP, (args) => (args)),
  initSession: createAction(APP.INIT_SESSION),
  clearSession: createAction(APP.CLEAR_SESSION),
}
// initialState
const initialState = {
  title: '',
  messageObject: [],
  message: [],
  isProcessing: false,
  processingMessage: '',
  isInitProcessing: false,
  tktList: [],
  windowOpen: '',
  isHeaderShow: false,
  isFooterShow: false,
  tktEditFormMode: '',
  isUnloadCheck: true,
  gatewayBaseUri: '',
  limitMonth: 0,
}
// reducer
export const AppReducer = handleActions({
  [AppAction.setTitle]: (state, action) => ({...state, title:action.payload.title}),
  [AppAction.setMessage]: (state, action) => ({...state, 
    messageObject:action.payload.message, message:(shapeMessage(initialState.message, action.payload.message))
  }),
  [AppAction.addMessage]: (state, action) => ({...state, 
    messageObject:state.messageObject.concat(action.payload.message), 
    message:(shapeMessage(state.message, action.payload.message))
  }),
  [AppAction.showProcessing]: (state, action) => ({...state, isProcessing:action.payload.isProcessing}),
  [AppAction.setProcessingMessage]: (state, action) => ({...state, processingMessage:action.payload.processingMessage}),
  [AppAction.showInitProcessing]: (state, action) => ({...state, isInitProcessing:action.payload.isInitProcessing}),
  [AppAction.setMasterData]: (state, action) => ({...state, ...action.payload,}),
  [AppAction.setBatchResult]: (state, action) => ({...state, ...action.payload,}),
  [AppAction.setInputApp]: (state, action) => ({...state, ...action.payload,}),
  [AppAction.initSession]: (state, action) => ({...state, message:[]}),
  [AppAction.clearSession]: (state, action) => (initState(initialState)),
}, initialState)

/*
message=[{id:'', message:'メッセージ', type:'error'},{...}]
*/
const shapeMessage = (message, newMessage) => {
  let mes = [].concat(message);
  _.forEach(newMessage, (v, i) => {
    const id = (v.id && v.type && v.type === 'error')?`[${v.id}]`:'';
    mes.push((<div key={mes.length} className={v.type}>{id}{v.message}</div>))
  });
  return mes;
} 